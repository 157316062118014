import { useState, useEffect, useMemo } from 'react'

import { AppBar, Tab, Tabs, Tooltip } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'

import { AllocateCXMember } from 'components/bookings/AllocateCXMember'
import { AttachmentsTab } from 'components/bookings/Attachments'
import { BookingDetails } from 'components/bookings/BookingDetails'
import { MapRoute } from 'components/bookings/MapRoute'
import { PostLoadOrBookDirect } from 'components/bookings/PostLoadOrBookDirect'
import { CounterBadge, FplTab, TabPanel, TasksAndHistory } from 'components/common'
import { Mode, PARAM_NEW, QuotesTabId } from 'constants/index'
import {
  BookingAddressType,
  TaskStatus,
  useGetQuoteLazyQuery,
  useGetTasksCountLazyQuery,
  useGetUserQuoteLazyQuery,
} from 'generated/graphql'
import { CloneProps, isNumber, useHashToSelectTab } from 'helpers'
import { useQuoteAllTabs } from 'hooks/quoteTab'
import { Restricted, usePermission } from 'providers/PermissionProvider'

function commonProps(index: QuotesTabId) {
  return {
    id: `quote-tab-${index}`,
    'aria-controls': `quote-tabpanel-${index}`,
  }
}

const QuoteTab = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)
  const { id } = useParams<{ id?: string }>()

  const history = useHistory()

  const allTabs = useQuoteAllTabs()
  const arrayOfTabs = Object.values(allTabs)
  useHashToSelectTab(arrayOfTabs, setTabValue)

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')
  const [isAllowedToViewTasks] = usePermission('ViewTasks')

  // Queries
  const [getAdminQuote, { data: adminQuoteData }] = useGetQuoteLazyQuery()

  const [getUserQuote, { data: userQuoteData }] = useGetUserQuoteLazyQuery()

  let mode: number | undefined

  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const quoteId: string | null = mode === Mode.Update && id ? id : null

  const [getTasksCount, { data: tasksData }] = useGetTasksCountLazyQuery()

  useEffect(() => {
    if (isAllowedToViewTasks && quoteId) {
      getTasksCount({
        variables: {
          where: { bookingId: { eq: Number(quoteId) }, status: { eq: TaskStatus.New } },
        },
      })
    }
  }, [getTasksCount, isAllowedToViewTasks, quoteId])

  const quote = adminQuoteData?.booking || userQuoteData?.userBooking

  useEffect(() => {
    if (isAllowedToViewBookingFee) {
      quoteId && getAdminQuote({ variables: { quoteId: Number(quoteId) } })
    } else {
      quoteId && getUserQuote({ variables: { quoteId: Number(quoteId) } })
    }
  }, [quoteId, getAdminQuote, getUserQuote, isAllowedToViewBookingFee])

  const isBaseDisabled = useMemo(() => {
    if (!quote) {
      return true
    }

    const collectionAddress = quote?.addresses.filter(
      (address) => address.type === BookingAddressType.Collection,
    )
    const deliveryAddress = quote?.addresses.filter(
      (address) => address.type === BookingAddressType.Delivery,
    )

    return mode === Mode.Create || collectionAddress?.length === 0 || deliveryAddress?.length === 0
  }, [mode, quote])

  const isPostLoadDisabled = useMemo(() => !!quote?.courierExchangeLoadId, [quote])

  const isAllocateCxMemberDisabled = useMemo(
    () => !!quote?.courierExchangeLoadId && quote?.drivers?.length !== 0,
    [quote],
  )

  const tabsTooltipText = useMemo(() => {
    if (!quoteId) {
      return 'Available only for existing quotes'
    }

    const collectionAddress = quote?.addresses.filter(
      (address) => address.type === BookingAddressType.Collection,
    )
    const deliveryAddress = quote?.addresses.filter(
      (address) => address.type === BookingAddressType.Delivery,
    )

    if (collectionAddress?.length === 0 || deliveryAddress?.length === 0) {
      return 'Available only if addresses is selected'
    }

    if (quote?.drivers?.length === 0) {
      return 'Quote already have Post Load'
    }

    if (quote?.courierExchangeLoadId) {
      return 'CX Member was already allocated'
    }

    return ''
  }, [quoteId, quote])

  const handleTabChange = (_, newValue: QuotesTabId) => {
    const selectedTab = arrayOfTabs.find((tab) => tab.id === newValue)

    if (selectedTab) {
      history.push(selectedTab.hash)
    }
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='quote tabs'
          variant='scrollable'>
          <CloneProps>
            {(tabProps) => (
              <Restricted to={['ViewBookingDetails', 'ViewUserBookingDetails']}>
                <Tab
                  {...tabProps}
                  {...commonProps(allTabs.quoteDetails.id)}
                  label={allTabs.quoteDetails.label}
                  value={allTabs.quoteDetails.id}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewBookingMapRouteTab'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.mapRoute}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewBookingAttachmentsTab'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.attachments}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='CourierExchangePostLoad'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.postLoad}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled || isPostLoadDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewCourierExchangeQuoteList'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.allocateCXMember}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled || isAllocateCxMemberDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewTasks'>
                <Tab
                  {...tabProps}
                  {...commonProps(allTabs.tasks.id)}
                  sx={{ pointerEvents: 'auto !important' }}
                  value={allTabs.tasks.id}
                  disabled={isBaseDisabled}
                  label={
                    <Tooltip
                      title={tabsTooltipText}
                      disableHoverListener={!isBaseDisabled}
                      disableTouchListener={!isBaseDisabled}
                      disableFocusListener={!isBaseDisabled}>
                      <CounterBadge color='primary' badgeContent={tasksData?.tasks?.totalCount}>
                        {allTabs.tasks.label}
                      </CounterBadge>
                    </Tooltip>
                  }
                />
              </Restricted>
            )}
          </CloneProps>
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={QuotesTabId.Details}>
        <BookingDetails bookingId={quoteId} mode={mode} isQuote />
      </TabPanel>

      <TabPanel value={tabValue} index={QuotesTabId.MapRoute} padding={0}>
        <MapRoute bookingId={quoteId} isQuote />
      </TabPanel>

      <TabPanel value={tabValue} index={QuotesTabId.Attachments} paddingX={{ xs: 2, sm: 0 }}>
        <AttachmentsTab bookingId={quoteId} />
      </TabPanel>

      <TabPanel value={tabValue} index={QuotesTabId.PostLoad}>
        <PostLoadOrBookDirect bookingId={quoteId} isQuote />
      </TabPanel>

      <TabPanel value={tabValue} index={QuotesTabId.AllocateCXMember}>
        <AllocateCXMember bookingId={quoteId} isQuote />
      </TabPanel>

      <TabPanel value={tabValue} index={QuotesTabId.Tasks}>
        <TasksAndHistory bookingId={quoteId} />
      </TabPanel>
    </div>
  )
}

export default QuoteTab
