import { useState } from 'react'

import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'

import { VerticalTabPanel } from 'components/common'
import { usePermission } from 'providers'

import Attachments from './Attachments'
import { BookingAddressAttachments } from './BookingAddressAttachments'

enum TabId {
  BookingAttachments,
  AddressAttachments,
}

interface AttachmentsTabProps {
  bookingId: string | null
}

function commonProps(index: TabId) {
  return {
    id: `booking-attachments-tab-${index}`,
    'aria-controls': `booking-attachments-tabpanel-${index}`,
  }
}

const AttachmentsTab = (props: AttachmentsTabProps) => {
  const { bookingId } = props
  const [tabValue, setTabValue] = useState(0)

  const theme = useTheme()
  const isBreakpointUpSm = useMediaQuery(theme.breakpoints.up('sm'), { defaultMatches: true })

  const [isBookingAddressAttachmentsAllowed] = usePermission('ViewBookingAddressAttachments')
  const [isBookingAttachmentsAllowed] = usePermission('ViewBookingAttachments')

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box
      sx={{
        display: { sm: 'flex' },
        flexGrow: 1,
        backgroundColor: 'background.paper',
      }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation={isBreakpointUpSm ? 'vertical' : 'horizontal'}
        aria-label='booking attachments tabs'
        variant='scrollable'
        sx={{
          borderRight: { sm: 1 },
          borderBottom: { xs: 1, sm: 0 },
          borderColor: { xs: 'divider', sm: 'divider' },
        }}>
        {isBookingAttachmentsAllowed && (
          <Tab
            label='Attachments'
            key={TabId.BookingAttachments}
            {...commonProps(TabId.BookingAttachments)}
          />
        )}
        {isBookingAddressAttachmentsAllowed && (
          <Tab
            label='Address Attachments'
            key={TabId.AddressAttachments}
            {...commonProps(TabId.AddressAttachments)}
          />
        )}
      </Tabs>
      <VerticalTabPanel
        value={tabValue}
        index={TabId.BookingAttachments}
        px={{ sm: 2 }}
        py={{ xs: 2, sm: 0 }}>
        <Attachments bookingId={bookingId} />
      </VerticalTabPanel>
      <VerticalTabPanel
        value={tabValue}
        index={TabId.AddressAttachments}
        px={{ sm: 2 }}
        py={{ xs: 2, sm: 0 }}>
        <BookingAddressAttachments bookingId={bookingId} />
      </VerticalTabPanel>
    </Box>
  )
}

export default AttachmentsTab
