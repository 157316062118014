import { useEffect } from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledTextField } from 'components/common'
import { TariffCalculateCharge, CreateAndUpdateTariffDtoInput } from 'generated/graphql'

import CalculateChargeControl from './CalculateChargeControl'

const WaitingTimeCharge = () => {
  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { isSubmitted, errors },
  } = useFormContext<CreateAndUpdateTariffDtoInput>()

  const isOverwriteDefaultWaitingTime: boolean = watch('isOverwriteDefaultWaitingTime', false)

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === 'isOverwriteDefaultWaitingTime') {
        if (!data.isOverwriteDefaultWaitingTime) {
          setValue('collectionAllowanceMinutes', undefined)
          setValue('deliveryAllowanceMinutes', undefined)
          setValue('excessWaitingTimeCharge', undefined)
          setValue('excessWaitingTimeMinutesPer', undefined)
          setValue('calculateCharge', TariffCalculateCharge.ExpressWaitingFee)
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [setValue, watch])

  useEffect(() => {
    if (isSubmitted) {
      trigger('excessWaitingTimeMinutesPer')
      trigger('excessWaitingTimeCharge')
    }
  }, [isOverwriteDefaultWaitingTime, isSubmitted, trigger])

  return (
    <Grid container columnSpacing={3} rowSpacing={1.5}>
      <Grid item xs={12}>
        <ControlledCheckbox
          control={control}
          name='isOverwriteDefaultWaitingTime'
          label='Overwrite Default Charge'
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <ControlledTextField
          control={control}
          name='collectionAllowanceMinutes'
          label='Collection Allowance Mins'
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          min='0'
          disabled={!isOverwriteDefaultWaitingTime}
          error={!!errors.collectionAllowanceMinutes}
          helperText={errors.collectionAllowanceMinutes?.message}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <ControlledTextField
          control={control}
          name='deliveryAllowanceMinutes'
          label='Delivery Allowance Mins'
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          min='0'
          disabled={!isOverwriteDefaultWaitingTime}
          error={!!errors.deliveryAllowanceMinutes}
          helperText={errors.deliveryAllowanceMinutes?.message}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <ControlledTextField
          control={control}
          name='excessWaitingTimeCharge'
          label='Waiting Time Charge'
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          min='0'
          endAdornment='£'
          required={isOverwriteDefaultWaitingTime}
          disabled={!isOverwriteDefaultWaitingTime}
          error={!!errors.excessWaitingTimeCharge}
          helperText={errors.excessWaitingTimeCharge?.message}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <ControlledTextField
          control={control}
          name='excessWaitingTimeMinutesPer'
          label='Waiting Time'
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          min='0'
          endAdornment='mins'
          required={isOverwriteDefaultWaitingTime}
          disabled={!isOverwriteDefaultWaitingTime}
          error={!!errors.excessWaitingTimeMinutesPer}
          helperText={errors.excessWaitingTimeMinutesPer?.message}
        />
      </Grid>

      <Grid item xs={12}>
        <CalculateChargeControl control={control} disabled={!isOverwriteDefaultWaitingTime} />
      </Grid>
    </Grid>
  )
}

export default WaitingTimeCharge
