import { useOidc } from '@axa-fr/react-oidc-context'
import { Button } from '@mui/material'

import ExceptionMessage from 'components/common/ExceptionMessage'
import { Paths } from 'constants/index'

const SessionLost = () => {
  const { login } = useOidc()

  const callbackPath = location.search.replace('?path=', '')

  const handleHomeClick = () => window.location.assign(Paths.home)
  const onAuthenticate = () => {
    login(callbackPath)
  }

  return (
    <ExceptionMessage
      headerText='Session lost'
      bodyText=''
      imageUri='/static/firstpoint-logo-1.png'
      actions={
        <>
          <Button
            variant='contained'
            color='primary'
            style={{ marginRight: 10 }}
            onClick={handleHomeClick}>
            Home
          </Button>
          <Button variant='contained' color='primary' onClick={onAuthenticate}>
            Re-authenticate
          </Button>
        </>
      }
    />
  )
}

export default SessionLost
