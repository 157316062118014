import { ISelectorOptions } from "components/common";

  export enum AccountPayableRecordQueryReason{
    POD,
    Invoice,
    SupplementaryInvoice,
    CreditNote,
    Payment,
    BankAccount,
    Other,
}

export const AccountPayableRecordQueryReasonValues: ISelectorOptions = [
    { value: AccountPayableRecordQueryReason.POD, label: 'POD' },
    { value: AccountPayableRecordQueryReason.Invoice, label: 'Invoice' },
    { value: AccountPayableRecordQueryReason.SupplementaryInvoice, label: 'Supplementary Invoice' },
    { value: AccountPayableRecordQueryReason.CreditNote, label: 'Credit Note' },
    { value: AccountPayableRecordQueryReason.Payment, label: 'Payment' },
    { value: AccountPayableRecordQueryReason.BankAccount, label: 'Bank Account' },
    { value: AccountPayableRecordQueryReason.Other, label: 'Other (reason required)' },
  ]

 type LabelType ={
    [key: number]: string
 } 

export const AccountPayableRecordQueryReasonLabels: LabelType = {
   0: 'POD',
   1: 'Invoice',
   2: 'Supplementary Invoice',
   3: 'Credit Note',
   4: 'Payment',
   5: 'Bank Account',
   6: 'Query',
}