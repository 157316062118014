import React from 'react'

import { Box } from '@mui/material'

interface VerticalTabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  px?: any
  py?: any
  width?: any
}

const VerticalTabPanel = (props: VerticalTabPanelProps) => {
  const { index, value, children, px, py, width, ...other } = props

  return (
    <Box
      component={'div'}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tab-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ width: width ? width : '100%' }}
      {...other}>
      {value === index && (
        <Box px={px} py={py}>
          {children}
        </Box>
      )}
    </Box>
  )
}

export { VerticalTabPanel }
