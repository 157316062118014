import { useEffect, useMemo, useState } from 'react'

import RefreshIcon from '@mui/icons-material/Refresh'
import { LoadingButton } from '@mui/lab'
import { Grid, InputAdornment, TextField } from '@mui/material'
import Decimal from 'decimal.js'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ConfirmDistanceChangeDialog } from 'components/bookings/common'
import { ControlledTextField } from 'components/common'
import { DEBOUNCE_DELAY, Mode, PARAM_NEW } from 'constants/index'
import {
  GoogleDistanceStatus,
  useGetBookingTariffQuery,
  useGetDistanceCalculationLazyQuery,
} from 'generated/graphql'
import { calcBookingConsignmentFee, isNumber, getGoogleAddressInput } from 'helpers'
import { sanitizedNumberField } from 'helpers/sanitizeNumberInput.helpers'
import { usePermission } from 'providers'
import { TBookingForm } from 'types/form-types'

interface IProps {
  isQuickQuote: boolean
  disabled?: boolean
}

const DistanceInput = (props: IProps) => {
  const { isQuickQuote, disabled } = props
  const [isDistanceInputFocused, setIsDistanceInputFocused] = useState(false)
  const [openConfirmDistanceChangeDialog, setOpenConfirmDistanceChangeDialog] = useState(false)

  const { id: bookingId } = useParams<{ id?: string }>()

  const {
    control,
    getValues,
    setValue,
    formState: { dirtyFields, errors },
  } = useFormContext<TBookingForm>()

  const [isAllowedToShowConfirmationDialogWhenDockedIsRePriced] = usePermission(
    'ShowConfirmationDialogWhenDockedIsRePriced',
  )
  const [isAllowedToMakeBookingDistanceFieldReadonly] = usePermission(
    'MakeBookingDistanceFieldReadonly',
  )

  const [distanceValue, tariffIdValue] = useWatch({ name: ['distance', 'tariffId'] })
  const [distanceValueDebounced] = useDebounce(distanceValue, DEBOUNCE_DELAY)

  // queries
  const [getDistanceCalculation, { data: distanceData, loading: distanceLoading }] =
    useGetDistanceCalculationLazyQuery()

  const { data: tariffData } = useGetBookingTariffQuery({
    variables: { id: Number(tariffIdValue) },
    skip: !tariffIdValue || !dirtyFields.distance,
  })

  let mode: number | undefined

  if (bookingId === PARAM_NEW) {
    mode = Mode.Create
  } else if (bookingId && isNumber(bookingId)) {
    mode = Mode.Update
  }

  useEffect(() => {
    if (distanceData) {
      if (distanceData.distanceCalculation.status === GoogleDistanceStatus.Success) {
        const distance = new Decimal(distanceData.distanceCalculation.distance)
          .toDP(2, Decimal.ROUND_FLOOR)
          .toNumber()

        setValue('distance', distance, { shouldDirty: true, shouldValidate: true })
      } else if (distanceData.distanceCalculation.status === GoogleDistanceStatus.Error) {
        toast.warning(` ${distanceData.distanceCalculation.errorMessage}`)
      }
    }
  }, [distanceData, setValue])

  const consignmentFeeCalculated = useMemo(() => {
    if (isDistanceInputFocused === false) {
      if (tariffData?.tariffById && distanceValueDebounced !== undefined) {
        return calcBookingConsignmentFee(
          distanceValueDebounced,
          tariffData.tariffById.minMiles,
          tariffData.tariffById.minCharge,
          tariffData.tariffById.rate,
        )
      }
    }
  }, [distanceValueDebounced, isDistanceInputFocused, tariffData])

  useEffect(() => {
    const consignmentFeeValue = getValues('consignmentFee')

    if (consignmentFeeValue !== undefined && consignmentFeeCalculated !== undefined) {
      if (consignmentFeeCalculated !== Number(consignmentFeeValue)) {
        if (mode === Mode.Create) {
          setValue('consignmentFee', consignmentFeeCalculated, { shouldDirty: true })
          return
        }

        if (mode === Mode.Update) {
          if (isAllowedToShowConfirmationDialogWhenDockedIsRePriced) {
            setOpenConfirmDistanceChangeDialog(true)
          } else {
            setValue('consignmentFee', consignmentFeeCalculated, { shouldDirty: true })
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentFeeCalculated, getValues])

  const handleRefresh = () => {
    const collectionAddressesValue = getValues().collectionAddresses
    const deliveryAddressesValue = getValues().deliveryAddresses

    if (collectionAddressesValue && deliveryAddressesValue) {
      const googleAddressInput = getGoogleAddressInput(
        collectionAddressesValue,
        deliveryAddressesValue,
      )

      getDistanceCalculation({ variables: { input: googleAddressInput } })
    }
  }

  const handleConfirmChangeDistance = () => {
    consignmentFeeCalculated &&
      setValue('consignmentFee', consignmentFeeCalculated, { shouldDirty: true })
  }

  const handleRejectChangeDistance = () => {
    // * No action on reject
  }

  const handleFocusDistance = () => {
    setIsDistanceInputFocused(true)
  }

  const handleBlurDistance = () => {
    setIsDistanceInputFocused(false)
  }

  return (
    <>
      <Grid container columnSpacing={0.5} justifyContent='end' alignItems='center'>
        {isAllowedToMakeBookingDistanceFieldReadonly || isQuickQuote ? (
          <Grid item xs={7}>
            <ControlledTextField
              control={control}
              name='distance'
              label='Distance'
              disabled={disabled}
              readOnly
              size='small'
              endAdornment='mi'
            />
          </Grid>
        ) : (
          <Grid item xs={7}>
            <Controller
              control={control}
              name='distance'
              defaultValue={0}
              render={({ field: { onBlur, onChange, ...restField } }) => (
                <TextField
                  {...restField}
                  label='Distance'
                  disabled={disabled}
                  required
                  type='text'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>mi</InputAdornment>,
                  }}
                  error={!!errors.distance}
                  helperText={errors.distance?.message}
                  onBlur={() => {
                    handleBlurDistance()
                    onBlur()
                  }}
                  onFocus={handleFocusDistance}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(e) => sanitizedNumberField(e.target.value, onChange)}
                />
              )}
            />
          </Grid>
        )}

        <Grid item>
          <LoadingButton loading={distanceLoading} disabled={disabled} onClick={handleRefresh}>
            <RefreshIcon />
          </LoadingButton>
        </Grid>
      </Grid>

      <ConfirmDistanceChangeDialog
        open={openConfirmDistanceChangeDialog}
        setOpen={setOpenConfirmDistanceChangeDialog}
        onConfirm={handleConfirmChangeDistance}
        onReject={handleRejectChangeDistance}
      />
    </>
  )
}

export default DistanceInput
