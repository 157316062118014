import { useCallback } from 'react'

import { GridEventListener, GridRowHeightParams } from '@mui/x-data-grid'
import { useHistory } from 'react-router-dom'

import { FplDataGrid, IGridColumn } from 'components/common'
import { Paths } from 'constants/index'
import { GET_PAGED_SUB_CUSTOMERS } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'accountCode', headerName: 'Account Code', minWidth: 200, flex: 1 },
  { field: 'name', headerName: 'Name', minWidth: 230, flex: 1 },
  {
    field: 'addressLine1',
    headerName: 'Address',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      return (
        <div>
          <div>{row.addressLine1}</div>
          <div>{row.addressLine2}</div>
          <div>{`${row.city}, ${row.postcode}`}</div>
        </div>
      )
    },
  },
  { field: 'telephoneNumber', headerName: 'Tel No', width: 150 },
  { field: 'invoiceEmail', headerName: 'Invoice Email', width: 150 },
  {
    field: 'contacts',
    headerName: 'Contact Person',
    width: 150,
    sortable: false,
    valueGetter: ({ value }) => {
      if (value.length === 0) {
        return ''
      }

      if (value.length === 1) {
        return value[0]?.name
      } else if (value.length > 1) {
        return 'Multiple'
      }
    },
  },
  { field: 'isOnHold', headerName: 'On Hold', width: 100, type: 'boolean' },
  { field: 'customerAccountType', headerName: 'A/C Type', width: 100 },
]

interface IProps {
  customerId: number
}

const SubCustomers = (props: IProps) => {
  const { customerId } = props

  const history = useHistory()

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    history.push(Paths.customers.updateWithId(id))
  }

  const handleGetRowHeight = useCallback(({ model, densityFactor }: GridRowHeightParams) => {
    if (model.addressLine2) {
      return 80 * densityFactor
    } else {
      return 65 * densityFactor
    }
  }, [])

  return (
    <FplDataGrid
      query={GET_PAGED_SUB_CUSTOMERS}
      entityName='pagedSubCustomers'
      columns={columns}
      queryVariables={{ primaryCustomerId: customerId }}
      toolbar={{ caption: 'Sub Customers' }}
      getRowHeight={handleGetRowHeight}
      onRowClick={handleRowClick}
    />
  )
}

export default SubCustomers
