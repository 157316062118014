import { gql } from '@apollo/client'

export const ActivityDetailsFragment = gql`
  fragment ActivityDetails on Activity {
    id
    type
    note
    date
    bookingId
    booking {
      id
      ourReference
    }

    createdAt
    createdBy
    lastModifiedAt
    lastModifiedBy
  }
`
