import Joi from 'joi'
import { BookingChargeType } from 'generated/graphql'

export default function validationSchema() {
  return Joi.object({
    type: Joi.string().required(),
    name: Joi.string().when('type', {
      is: BookingChargeType.Custom,
      then: Joi.string().empty('').required(),
      otherwise: Joi.string().empty('').optional(),
    }),
    rate: Joi.any(),
    otherChargeId: Joi.string().when('type', {
      is: BookingChargeType.Other,
      then: Joi.string().empty('').required(),
      otherwise: Joi.string().empty('').optional(),
    }),
    specialZoneId: Joi.string().when('type', {
      is: BookingChargeType.SpecialZone,
      then: Joi.string().empty('').required(),
      otherwise: Joi.string().empty('').optional(),
    }),
    description: Joi.string().empty(''),
    vatCategoryId: Joi.string().empty(''),
    xeroServiceCode: Joi.string().empty(''),
  })
}
