import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'

import { Restricted } from 'providers'

interface IPodDetailsActionsProps {
  isDirty: boolean
  handleCancel: () => void
  uploadingFiles: boolean
  sendingPodEmail: boolean
  handleResendPODEmail: () => void
  disabled: boolean
  updatingPod: boolean
  handleSubmit: () => void
}

const PodDetailsActions = (props: IPodDetailsActionsProps) => {
  const {
    isDirty,
    handleCancel,
    uploadingFiles,
    sendingPodEmail,
    handleResendPODEmail,
    disabled,
    updatingPod,
    handleSubmit,
  } = props

  return (
    <>
      <Button variant='contained' color='grey' onClick={handleCancel}>
        {isDirty ? 'Cancel' : 'Close'}
      </Button>

      <Restricted to='SendProofOfDeliveryEmail'>
        <LoadingButton
          variant='contained'
          disabled={uploadingFiles}
          loading={sendingPodEmail}
          onClick={handleResendPODEmail}>
          Resend POD Email
        </LoadingButton>
      </Restricted>

      <Restricted to='UpdateBookingProofOfDelivery'>
        <LoadingButton
          variant='contained'
          disabled={disabled || uploadingFiles}
          loading={updatingPod}
          onClick={handleSubmit}>
          Update
        </LoadingButton>
      </Restricted>
    </>
  )
}

export default PodDetailsActions
