import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { Mode } from 'constants/index'
import {
  CreateAndUpdateQuoteLostReasonDtoInput,
  useCreateQuoteLostReasonMutation,
  useUpdateQuoteLostReasonMutation,
} from 'generated/graphql'
import { GET_QUOTE_LOST_REASONS } from 'graphql/queries'
import { isNumber } from 'helpers'

import QuoteLostReasonForm from './QuoteLostReasonForm'
import UpdateQuoteLostReason from './UpdateQuoteLostReason'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}

const QuoteLostReasonDialog = (props: IProps) => {
  const { id, openDialog, onCloseDialog } = props

  const methods = useForm<CreateAndUpdateQuoteLostReasonDtoInput>({
    defaultValues: {
      name: '',
    },
  })

  const { handleSubmit } = methods

  // mutations
  const [createQuoteLostReason, { loading: creatingQuoteLostReason }] =
    useCreateQuoteLostReasonMutation({
      refetchQueries: [{ query: GET_QUOTE_LOST_REASONS }],
      onCompleted: (data) => {
        if (data?.createQuoteLostReason) {
          toast.success(
            `Quote Lost Reason (${data.createQuoteLostReason?.name}) was created with success.`,
          )
          onCloseDialog()
        }
      },
    })

  const [updateQuoteLostReason, { loading: updatingQuoteLostReason }] =
    useUpdateQuoteLostReasonMutation({
      onCompleted: (data) => {
        if (data?.updateQuoteLostReason) {
          toast.success(
            `Quote Lost Reason (${data.updateQuoteLostReason?.name}) was updated with success.`,
          )
          onCloseDialog()
        }
      },
    })

  let mode: number | undefined

  if (id === null) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const handleSubmitQuoteLostReason: SubmitHandler<CreateAndUpdateQuoteLostReasonDtoInput> = (
    formData,
  ) => {
    if (mode === Mode.Create) {
      createQuoteLostReason({
        variables: {
          input: formData,
        },
      })
    } else {
      updateQuoteLostReason({
        variables: {
          input: {
            id,
            ...formData,
          },
        },
      })
    }
  }

  const handleClose = () => {
    onCloseDialog()
  }

  return (
    <Dialog fullWidth scroll='body' open={openDialog} aria-labelledby='quote-lost-reason-dialog'>
      <FplDialogTitle id='quote-lost-reason-dialog' onClose={handleClose}>
        <Typography paragraph variant='h4'>
          {mode === Mode.Create ? 'Create' : 'Update'} Quote Lost Reason
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? <QuoteLostReasonForm /> : <UpdateQuoteLostReason id={id!} />}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          loading={creatingQuoteLostReason || updatingQuoteLostReason}
          onClick={handleSubmit(handleSubmitQuoteLostReason)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default QuoteLostReasonDialog
