import React from 'react'

import DOMPurify from 'dompurify'

const defaultOptions = {
  ALLOWED_TAGS: [
    'a',
    'br',
    'center',
    'div',
    'h1',
    // 'img', // Enabling this means that styles need to be applied
    'p',
    'table',
    'td',
    'title',
    'tr',
  ],
  ALLOWED_ATTR: ['href', 'src'],
}

interface IProps {
  html: string
  options?: any
}

const sanitize = (dirty, options) => ({
  __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
})

// Based on: https://stackoverflow.com/a/60797348/1578975
export default function SanitizeHTML(props: IProps) {
  const { html, options } = props

  return <div dangerouslySetInnerHTML={sanitize(html, options)} />
}
