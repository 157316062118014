import { gql } from '@apollo/client'

export const UPDATE_BOOKING_ATTACHMENT_MUTATION = gql`
  mutation UpdateBookingAttachment($input: UpdateBookingAttachmentDtoInput!) {
    updateBookingAttachment(input: $input) {
      id
      fileId
      bookingId
      isAvailableForDriver
      isAvailableForCustomer
      isEnabled
    }
  }
`

export const DELETE_BOOKING_ATTACHMENT_MUTATION = gql`
  mutation DeleteBookingAttachment($bookingId: Long!, $fileId: Long!) {
    deleteBookingAttachment(bookingId: $bookingId, fileId: $fileId)
  }
`
