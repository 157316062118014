import { useMemo } from 'react'

import { Box, styled, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Controller } from 'react-hook-form'

const AutocompleteWrapper = ({
  value,
  onChange,
  options,
  children,
  readSelectedCurrency,
  ...otherProps
}) => {
  const selectedOption = useMemo(
    () => options.find((option) => Number(option.id) === Number(value)),
    [options, value],
  )

  return children({
    ...otherProps,
    onChange: (e, data) => {
      onChange(Number(data?.id))
      readSelectedCurrency && readSelectedCurrency(Number(data?.id))
    },
    value: selectedOption || {
      id: null,
      name: 'Loading',
      code: '',
    },
  })
}

interface ICurrencyOption {
  id: string
  name: string
  code: string
}

const StyledOptions = styled(Box)(({ theme }) => ({
  '& > span': {
    marginRight: '10px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

interface IProps {
  name?: string
  control: any
  options: Array<ICurrencyOption>
  disabled?: boolean
  loading?: boolean
  error?: boolean
  helperText?: any
  required?: boolean
  readSelectedCurrency?: (currencyId: any) => any
}

const ControlledCurrencyAutocomplete = (props: IProps) => {
  const {
    name = 'currencyId',
    control,
    options,
    disabled,
    loading,
    error,
    helperText,
    required,
    readSelectedCurrency,
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      render={({ field: { ref, ...restField } }) => (
        <AutocompleteWrapper
          {...restField}
          options={options}
          readSelectedCurrency={readSelectedCurrency}>
          {(props) => (
            <Autocomplete
              {...props}
              options={options}
              loading={loading}
              disabled={disabled}
              autoHighlight
              getOptionLabel={(option: any) => option?.code}
              isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
              renderOption={(props, option: any) => (
                // @ts-ignore
                <StyledOptions component='li' {...props}>
                  <span>{option?.code}</span>
                  {option?.name}
                </StyledOptions>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Currency'
                  variant='outlined'
                  required={required}
                  fullWidth
                  inputProps={{ ...params.inputProps }}
                  error={error}
                  helperText={helperText}
                />
              )}
            />
          )}
        </AutocompleteWrapper>
      )}
    />
  )
}

export default ControlledCurrencyAutocomplete
