import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import { Box, IconButton, Typography } from '@mui/material'
import { format } from 'date-fns'

import { DATE_TIME_FORMAT } from 'constants/index'
import { File } from 'generated/graphql'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadFile } from 'services'

interface AttachmentFileProps {
  file: File
}

const AttachmentFile = (props: AttachmentFileProps) => {
  const { file } = props

  const handleDownloadClick = () => {
    DownloadFile(Number(file.id))
      .then((response) => {
        let fileName = GetFileName(response)
        if (!fileName) {
          fileName = `file-${+new Date()}.dat`
        }

        SaveFile(response.data, fileName)
      })
      .catch((error) => {
        ShowRestError(error)
      })
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center' margin={1}>
      <IconButton size='large' aria-label='download' onClick={handleDownloadClick}>
        <AttachFileOutlinedIcon fontSize='large' />
      </IconButton>
      <Typography variant='body1'>{file.name}</Typography>
      <Typography variant='body2'>{file.category}</Typography>
      <Typography variant='body2'>{format(new Date(file.createdAt), DATE_TIME_FORMAT)}</Typography>
    </Box>
  )
}

export default AttachmentFile
