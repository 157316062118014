import { forwardRef } from 'react'

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'

interface MainCardProps {
  border: boolean
  boxShadow?: boolean
  contentSX?: SxProps<Theme> | undefined
  darkTitle?: boolean
  divider?: boolean
  elevation: number
  secondary: React.ReactNode
  shadow?: string
  sx?: SxProps<Theme> | undefined
  title: string
  codeHighlight?: boolean
  content: boolean
  children: React.ReactNode
}

const headerSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
}

// eslint-disable-next-line react/display-name
export const MainCard = forwardRef<HTMLDivElement, MainCardProps>((props, ref) => {
  const {
    border = true,
    boxShadow,
    children,
    content = true,
    contentSX = {},
    darkTitle,
    divider = true,
    elevation,
    secondary,
    shadow,
    sx = {},
    title,
    codeHighlight,
    ...others
  } = props
  const theme = useTheme()
  const newBoxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow

  return (
    <Card
      elevation={elevation || 0}
      ref={ref}
      {...others}
      sx={{
        ...sx,
        border: border ? '1px solid' : 'node',
        borderRadius: 2,
        borderColor:
          theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A700,
        ':hover': {
          boxShadow: newBoxShadow ? shadow || `0px 2px 8px ${theme.palette.grey[900]}` : 'inherit',
        },
        '& pre': {
          m: 0,
          p: '16px !important',
          fontFamily: theme.typography.fontFamily,
          fontSize: '0.75rem',
        },
      }}>
      {title && (
        <CardHeader
          sx={headerSX}
          title={<Typography variant='h5'>{title}</Typography>}
          action={secondary}
        />
      )}

      {title && divider && <Divider />}

      {content && <CardContent sx={contentSX}>{children}</CardContent>}
      {!content && children}

      {/* {codeHighlight && (
        <>
          <Divider sx={{borderStyle: 'dashed'}}
          <
        </>
      )} */}
    </Card>
  )
})
