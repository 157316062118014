import { ReactNode } from 'react'

import { TextField, InputAdornment, SxProps, Theme } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { sanitizedNumberField } from 'helpers/sanitizeNumberInput.helpers'

export interface ControlledTextFieldProps {
  control?: Control<any, any>
  label: string
  name: string
  defaultValue?: any
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  variant?: 'outlined' | 'standard' | 'filled'
  startAdornment?: string | ReactNode
  endAdornment?: string | ReactNode
  fullWidth?: boolean
  min?: string
  error?: boolean
  helperText?: any
  type?: string
  multiline?: boolean
  rows?: number
  autoComplete?: string
  size?: 'small' | 'medium'
  margin?: 'dense' | 'none' | 'normal'
  inputMode?: 'none' | 'text' | 'search' | 'email' | 'tel' | 'url' | 'numeric' | 'decimal'
  pattern?: string
  sanitizedNumber?: boolean
  sx?: SxProps<Theme>
}

const ControlledTextField = (props: ControlledTextFieldProps) => {
  const {
    control,
    label,
    name,
    defaultValue,
    readOnly,
    variant = 'outlined',
    startAdornment,
    endAdornment,
    fullWidth = true,
    min,
    inputMode,
    pattern,
    sanitizedNumber,
    ...otherProps
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, ...restField } }) => (
        <TextField
          {...restField}
          inputRef={ref}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          inputProps={{
            min,
            inputMode,
            pattern,
            autoComplete: otherProps.autoComplete ? otherProps.autoComplete : 'on',
          }}
          InputProps={{
            readOnly,
            startAdornment: startAdornment && (
              <InputAdornment position='start'>{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position='end'>{endAdornment}</InputAdornment>
            ),
          }}
          onChange={(e) =>
            sanitizedNumber
              ? sanitizedNumberField(e.target.value, onChange)
              : onChange(e.target.value)
          }
          {...otherProps}
        />
      )}
    />
  )
}

export default ControlledTextField
