import { useState, useEffect, useMemo } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import { AutoFillAddressDialog, FindAddressDialog } from 'components/common/Dialog'
import { DEFAULT_COUNTRY_ID } from 'constants/index'
import {
  BookingAddressType,
  useGetAddressLazyQuery,
  useGetLoqateRetrieveLazyQuery,
} from 'generated/graphql'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import { usePermission } from 'providers'
import { TBookingForm } from 'types/form-types'

import { BookingAddressForm } from './BookingAddressForm'

const getBookingAddressDefaultValues = (sequenceOrder: number) => ({
  id: '',
  at: '',
  by: '',
  name: '',
  city: '',
  addressLine1: '',
  addressLine2: '',
  postcode: '',
  countryId: DEFAULT_COUNTRY_ID,
  contact: '',
  email: '',
  telephoneNumber: '',
  note: '',
  isVerified: '',
  sequenceOrder,
  reasonCodeId: 0,
})

interface IProps {
  bookingAddressType: BookingAddressType
  isQuote?: boolean
  isQuickQuote: boolean
  isUpdate?: boolean
  disabled?: boolean
  item: any
  index: number
  onFindOrAf: () => void
}

const BookingAddress = (props: IProps) => {
  const {
    bookingAddressType,
    isQuote = false,
    isQuickQuote,
    isUpdate,
    disabled,
    item,
    index,
    onFindOrAf,
  } = props
  const [openAutoFillAddress, setOpenAutoFillAddress] = useState(false)
  const [openFindAddress, setOpenFindAddress] = useState(false)

  const { setValue, getValues } = useFormContext<TBookingForm>()

  const [isAllowedToMakeCollectionAndDeliveryContactDataRequired] = usePermission(
    'MakeBookingCollectionAndDeliveryContactDataRequired',
  )
  let addressType: 'collection' | 'delivery'
  let labelAt: 'Ready At' | 'Deliver From'
  let labelBy: 'Collect By' | 'Deliver By'
  let isAtRequired = false
  let isByRequired = false
  let isContactNameAndTelNumberRequired = false

  if (bookingAddressType === BookingAddressType.Collection) {
    addressType = 'collection'
    labelAt = 'Ready At'
    labelBy = 'Collect By'
    isAtRequired = false
    isByRequired = false
  } else {
    addressType = 'delivery'
    labelAt = 'Deliver From'
    labelBy = 'Deliver By'
    isAtRequired = false
    isByRequired = false
  }

  isContactNameAndTelNumberRequired = isQuote
    ? false
    : isAllowedToMakeCollectionAndDeliveryContactDataRequired

  const customerIdValue = useWatch({ name: 'customerId' })
  const isReasonCodeEnabled = useWatch({ name: 'isReasonCodeEnabled' })
  const postcodeValue = useWatch({ name: `${addressType}Addresses.${index}.postcode` })
  const countryIdValue = useWatch({ name: `${addressType}Addresses.${index}.countryId` })

  const [loadAddressById, { loading: loadAddressLoading }] = useGetAddressLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const address = data.address

      if (address) {
        const currentAddress = getValues(`${addressType}Addresses.${index}`)
        setValue(
          `${addressType}Addresses.${index}`,
          {
            ...currentAddress,
            ...(!isQuickQuote && {
              name: address.name,
              city: address.city,
              addressLine1: address.addressLine1,
              addressLine2: address.addressLine2 ?? '',
              contact: address.contact ?? '',
              email: address.email ?? '',
              telephoneNumber: address.telephoneNumber ?? '',
            }),
            postcode: address.postcode,
            countryId: address.countryId,
          },
          { shouldDirty: true },
        )
      }

      onFindOrAf()
    },
  })

  const [getLoqateRetrieve] = useGetLoqateRetrieveLazyQuery({
    onCompleted: (data) => {
      const address = data.loqateRetrieve
      if (address) {
        const currentAddress = getValues(`${addressType}Addresses.${index}`)
        setValue(
          `${addressType}Addresses.${index}`,
          {
            ...currentAddress,
            ...(!isQuickQuote && {
              name: address.name ?? '',
              city: address.city ?? '',
              addressLine1: address.addressLine1 ?? '',
              addressLine2: address.addressLine2 ?? '',
            }),
            postcode: address.postcode ?? '',
            countryId: address.countryId ?? '',
          },
          { shouldDirty: true },
        )
      }

      onFindOrAf()
    },
  })

  const isBookingInternational = useMemo(
    () => countryIdValue !== DEFAULT_COUNTRY_ID,
    [countryIdValue],
  )

  useEffect(() => {
    isPageLoadingVar(loadAddressLoading)
  }, [loadAddressLoading])

  const handleFindClick = () => {
    setOpenFindAddress(true)
  }

  const handleAutoFillClick = () => {
    setOpenAutoFillAddress(true)
  }

  const handleFindAddressSelect = (id: number) => {
    setOpenFindAddress(false)
    // ToDo: Create a query for User Address Details
    loadAddressById({ variables: { id } })
  }

  const handleAutoFillAddressSelect = (id: string) => {
    setOpenAutoFillAddress(false)
    getLoqateRetrieve({ variables: { id } })
  }

  return (
    <>
      <BookingAddressForm
        index={index}
        isQuickQuote={isQuickQuote}
        item={item}
        disabled={disabled}
        isUpdate={isUpdate}
        addressType={addressType}
        customerIdValue={customerIdValue}
        handleAutoFillClick={handleAutoFillClick}
        handleFindClick={handleFindClick}
        isAtRequired={isAtRequired}
        isBookingInternational={isBookingInternational}
        isByRequired={isByRequired}
        isContactNameAndTelNumberRequired={isContactNameAndTelNumberRequired}
        isReasonCodeEnabled={isReasonCodeEnabled}
        labelAt={labelAt}
        labelBy={labelBy}
      />

      {/* // ToDo: Create a query for User Addresses */}
      {openFindAddress && (
        <FindAddressDialog
          open={openFindAddress}
          setOpen={setOpenFindAddress}
          customerId={Number(customerIdValue)}
          postcode={postcodeValue}
          onSelectAddress={handleFindAddressSelect}
        />
      )}

      {/* // ToDo: Create a query for User LoqateFind */}
      {openAutoFillAddress && (
        <AutoFillAddressDialog
          open={openAutoFillAddress}
          setOpen={setOpenAutoFillAddress}
          postcode={postcodeValue}
          countryId={countryIdValue}
          onSelectAddress={handleAutoFillAddressSelect}
        />
      )}
    </>
  )
}

export { BookingAddress, getBookingAddressDefaultValues }
