import React from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import { createRoot } from 'react-dom/client'

import App from './components/App'

// CSS and Styling'
import 'react-toastify/dist/ReactToastify.min.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './fonts/Goldplay/Goldplay.css'
import './index.css'

function Root() {
  return (
    <React.StrictMode>
      <CssBaseline />
      <App />
    </React.StrictMode>
  )
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<Root />)
