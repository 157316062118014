import { gql } from '@apollo/client'

import { BookingAddressesFragment } from 'graphql/fragments'

export const CANCEL_BOOKING_MUTATION = gql`
  mutation CancelBooking($input: CancelBookingDtoInput!) {
    cancelBooking(input: $input) {
      id
      isCancelledOrQuoteLost
      cancellationReasonOrQuoteLostDetail
      statusLabel
    }
  }
`

export const MARK_QUOTE_LOST_MUTATION = gql`
  mutation MarkQuoteLost($input: QuoteLostDtoInput!) {
    markQuoteLost(input: $input) {
      id
      isCancelledOrQuoteLost
      quoteLostReasonId
      cancellationReasonOrQuoteLostDetail
      statusLabel
    }
  }
`

export const DELETE_BOOKING = gql`
  mutation DeleteBooking($input: DeleteOrDisableBookingDtoInput!) {
    deleteBooking(input: $input)
  }
`

export const ARCHIVE_BOOKING_MUTATION = gql`
  mutation ArchiveBooking($input: ArchiveBookingDtoInput!) {
    archiveBooking(input: $input) {
      id
      isArchived
      statusLabel
    }
  }
`

export const MAKE_BOOKING_ON_HOLD_MUTATION = gql`
  mutation MakeBookingOnHold($input: MakeBookingOnHoldDtoInput!) {
    makeBookingOnHold(input: $input) {
      id
      isOnHold
      statusLabel
    }
  }
`

export const CONFIRM_QUICK_QUOTE_MUTATION = gql`
  ${BookingAddressesFragment}
  mutation ConfirmQuickQuote($input: ConfirmQuickQuoteDtoInput!) {
    confirmQuickQuote(input: $input) {
      id
      isQuickQuote
      status
      statusLabel
      calculatedStatus
      isArchived
      isCancelledOrQuoteLost
      isOnHold
      isPreBooked
      isPending
      ...BookingAddresses
    }
  }
`

export const COPY_BOOKING_MUTATION = gql`
  mutation CopyBooking($bookingId: Long!) {
    copyBooking(bookingId: $bookingId)
  }
`

export const UPDATE_BOOKING_PROOF_OF_DELIVERY_MUTATION = gql`
  mutation UpdateBookingProofOfDelivery($input: UpdateProofOfDeliveryDtoInput!) {
    updateBookingProofOfDelivery(input: $input) {
      id
      bookingId
      booking {
        id
        status
        statusLabel
      }
      at
      by
      waitingTime
      waitingTimeCharge
      arrivedAt
      signedAt
      signedByFirstName
      signedByLastName
      driverTimeFrom
      driverTimeTo
      noteFromDriver
      eTA
      allocations {
        id
        packageId
        quantity
      }
    }
  }
`

export const UPDATE_BOOKING_PACKAGE_ON_BOARD_MUTATION = gql`
  mutation UpdateBookingPackageOnBoard($input: UpdatePackageOnBoardDtoInput!) {
    updateBookingPackageOnBoard(input: $input) {
      id
      bookingId
      booking {
        id
        status
        statusLabel
      }
      at
      by
      waitingTime
      waitingTimeCharge
      arrivedAt
      signedAt
      signedByFirstName
      signedByLastName
      driverTimeFrom
      driverTimeTo
      noteFromDriver
      eTA
      addressPackages {
        id
        packageId
        quantity
      }
    }
  }
`

export const CREATE_BOOKING_INVOICE = gql`
  mutation CreateBookingInvoice($input: CreateBookingInvoiceDtoInput!) {
    createBookingInvoice(input: $input) {
      id
      bookingId
    }
  }
`

export const UPDATE_BOOKING_STATUS = gql`
  mutation UpdateBookingStatus($bookingId: Long!, $status: BookingStatus!) {
    updateBookingStatus(bookingId: $bookingId, status: $status) {
      id
      status
      statusLabel
      calculatedStatus
      ourReference
    }
  }
`

export const UPDATE_SEQUENCE_ORDER = gql`
  mutation UpdateSequenceOrder($input: UpdateSequenceOrderDtoInput!) {
    updateSequenceOrder(input: $input) {
      id
      distance
      consignmentFee
      addresses {
        id
        sequenceOrder
      }
    }
  }
`

export const DELETE_BOOKING_ADDRESS_ATTACHMENT_MUTATION = gql`
  mutation DeleteBookingAddressAttachment($input: DeleteBookingAddressAttachmentDtoInput!) {
    deleteBookingAddressAttachment(input: $input)
  }
`

export const SEND_DOCKET_DETAILS_EMAILS_MUTATION = gql`
  mutation sendDocketDetails($bookingId: Long!) {
    sendDocketDetailsEmail(bookingId: $bookingId)
  }
`

export const UPDATE_ADDRESS_ETA = gql`
  mutation updateBookingAddressEta($input: UpdateAddressEtaDtoInput!) {
    updateBookingAddressEta(input: $input) {
      id
      eTA
    }
  }
`

export const SEND_PROOF_OF_DELIVERY_EMAIL = gql`
  mutation sendProofOfDeliveryEmail($bookingId: Long!, $bookingAddressId: Long) {
    sendProofOfDeliveryEmail(bookingId: $bookingId, bookingAddressId: $bookingAddressId)
  }
`

export const UPDATE_ALL_BOOKING_ETAS = gql`
  mutation updateAllBookingEtas {
    updateAllBookingEtas
  }
`

export const CREATE_QUOTE_LOST_REASON_MUTATION = gql`
  mutation CreateQuoteLostReason($input: CreateAndUpdateQuoteLostReasonDtoInput!) {
    createQuoteLostReason(input: $input) {
      id
      name
      code
    }
  }
`

export const UPDATE_QUOTE_LOST_REASON_MUTATION = gql`
  mutation UpdateQuoteLostReason($input: CreateAndUpdateQuoteLostReasonDtoInput!) {
    updateQuoteLostReason(input: $input) {
      id
      name
      code
    }
  }
`

export const PROCESS_POTENTIAL_BOOKING_FILE = gql`
  mutation ProcessPotentialBookingFile($input: ProcessPotentialBookingFileDtoInput!) {
    processPotentialBookingFile(input: $input)
  }
`
