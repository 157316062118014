import { gql } from '@apollo/client'

import { TaskSettingDetailsFragment } from 'graphql/fragments/TaskSettingFragments'

export const CREATE_TASK_SETTING = gql`
  mutation CreateTaskSetting($input: CreateUpdateTaskSettingDtoInput!){
    createSystemTaskSetting(input: $input){
      id
      ...TaskSettingDetails
    }
    ${TaskSettingDetailsFragment}
  }
`

export const UPDATE_TASK_SETTING = gql`
  mutation UpdateTaskSetting($input: CreateUpdateTaskSettingDtoInput!){
    updateSystemTaskSetting(input: $input){
      id
      ...TaskSettingDetails
    }
    ${TaskSettingDetailsFragment}
  }
`
