import { useEffect } from 'react'

import { Box, Button, FormHelperText, Grid, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { TextInput, ControlledCheckbox } from 'components/common'
import { Tariff } from 'generated/graphql'
import { emitter } from 'providers'
import { TBookingForm } from 'types'

interface IProps {
  tariffDetails?: Tariff
  disabled?: boolean
  onSelectTariffClick: () => void
}

const InternalTariffForm = (props: IProps) => {
  const { tariffDetails, disabled, onSelectTariffClick } = props
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext<TBookingForm>()

  useEffect(() => {
    setValue('serviceType', tariffDetails?.xeroCode?.serviceType)
    emitter.emit('xero-service-type-change')
  }, [tariffDetails?.xeroCode?.serviceType, setValue])

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={6}>
        <TextInput label='Name' value={tariffDetails?.name} disabled={disabled} readOnly />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Stack alignItems='center'>
          <Button
            variant='contained'
            color='grey'
            disabled={disabled}
            onClick={onSelectTariffClick}>
            Select Tariff
          </Button>

          {errors.tariffId && (
            <FormHelperText error required focused>
              {errors.tariffId.message}
            </FormHelperText>
          )}

          {/* //* Used to focus on form error */}
          <Box sx={{ width: 0, height: 0, overflow: 'hidden' }}>
            {/* // ToDo: Fix double setValue */}
            <input {...register('tariffId')} />
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          label='Category'
          value={tariffDetails?.tariffCategory.name}
          disabled={disabled}
          readOnly
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ControlledCheckbox
          control={control}
          label='Wait &amp; Return'
          name='isWaitAndReturn'
          defaultValue={false}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput label='Type' value={tariffDetails?.type} disabled={disabled} readOnly />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput label='Tariff Id' value={tariffDetails?.id} disabled={disabled} readOnly />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label='Min. Charge'
          value={tariffDetails?.minCharge}
          disabled={disabled}
          readOnly
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label='Min. Miles'
          value={tariffDetails?.minMiles}
          disabled={disabled}
          readOnly
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput label='Rate' value={tariffDetails?.rate} disabled={disabled} readOnly />
      </Grid>

      <Grid item xs={6}>
        <TextInput label='Base Unit' value={tariffDetails?.baseUnit} disabled={disabled} readOnly />
      </Grid>
    </Grid>
  )
}

export default InternalTariffForm
