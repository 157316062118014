import { Grid, Paper } from '@mui/material'

import { History } from './History'
import { Tasks } from './Tasks'

interface IProps {
  bookingId: string | null
}

const TasksAndHistory = (props: IProps) => {
  const { bookingId } = props

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={5}>
        <Paper variant='outlined'>
          <Tasks bookingId={bookingId} />
        </Paper>
      </Grid>
      <Grid item xs={7}>
        <Paper variant='outlined'>
          <History bookingId={bookingId} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TasksAndHistory
