import React from 'react'

import { Decimal } from 'decimal.js'

import { useGetBookingQuery, useLoadQuotesMutation } from 'generated/graphql'

import CxQuotesTable from './CxQuotesTable'

interface IProps {
  bookingId: string | null
  onSelectQuote: (id: string | undefined) => void
}

const AvailableQuotes = (props: IProps) => {
  const { bookingId, onSelectQuote } = props

  // Queries
  const { data: bookingData, loading: bookingLoading } = useGetBookingQuery({
    variables: { bookingId: Number(bookingId) },
  })

  // Mutations
  const [loadCXQuotes, { data: cxQuotesData, loading: cxQuotesLoading }] = useLoadQuotesMutation()

  const revenue: number = React.useMemo(() => {
    if (bookingData?.booking) {
      const consignmentFee = bookingData.booking.consignmentFee
      const chargesList = bookingData.booking.charges

      const otherCharges =
        chargesList?.reduce(
          (previousValue, currentValue) =>
            currentValue?.rate * currentValue.quantity + previousValue,
          0,
        ) || 0

      return new Decimal(consignmentFee).plus(otherCharges).toNumber()
    } else {
      return 0
    }
  }, [bookingData])

  const bookingLoadId = bookingData?.booking?.courierExchangeLoadId
  React.useEffect(() => {
    bookingLoadId && loadCXQuotes({ variables: { loadId: Number(bookingLoadId) } })
  }, [bookingLoadId, loadCXQuotes])

  const handleRefreshClick = () => {
    bookingLoadId && loadCXQuotes({ variables: { loadId: Number(bookingLoadId) } })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeleteQuote = (id: string | undefined) => {
    // ToDo: implement
  }

  // ToDo: use Data Grid here
  return (
    <CxQuotesTable
      quotes={cxQuotesData?.loadQuotes}
      revenue={revenue}
      loading={cxQuotesLoading || bookingLoading}
      onSelectQuote={onSelectQuote}
      onDeleteQuote={handleDeleteQuote}
      onRefreshClick={handleRefreshClick}
    />
  )
}

export default AvailableQuotes
