import { LoadingButton } from '@mui/lab'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { format, parseJSON } from 'date-fns'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CardTitle, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import {
  ExternalDriverInvoiceFilterInput,
  GetPagedExternalDriverInvoicesDocument,
  useMatchExternalDriverInvoiceMutation,
} from 'generated/graphql'
import { GET_PAGED_EXTERNAL_DRIVER_INVOICES_QUERY } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'Id', width: 70 },
  { field: 'reference', headerName: 'Reference', minWidth: 150, flex: 1 },
  { field: 'quantity', headerName: 'Quantity', width: 100, type: 'number' },
  { field: 'invoiceNumber', headerName: 'Invoice Number', minWidth: 150, flex: 1 },
  {
    field: 'matched',
    headerName: 'Matched',
    width: 100,
    type: 'boolean',
    sortable: false,
    valueGetter: ({ row }) => Boolean(row?.bookingId),
  },
  {
    field: 'bookingId',
    headerName: 'Booking id',
    sortPath: ['bookingInvoice', 'bookingId'],
    width: 120,
    valueGetter: ({ row }) => row?.bookingInvoice?.bookingId,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    minWidth: 150,
    flex: 1,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_TIME_FORMAT),
  },
  { field: 'description', headerName: 'Description', width: 1000 },
]

const ExternalDriverInvoices = () => {
  const { id } = useParams<{ id?: string }>()

  const filter: ExternalDriverInvoiceFilterInput = {
    fileId: { eq: Number(id) },
  }

  // mutations
  const [matchInvoices, { loading: matching }] = useMatchExternalDriverInvoiceMutation({
    refetchQueries: [
      {
        query: GetPagedExternalDriverInvoicesDocument,
        variables: {
          where: filter,
        },
      },
    ],
    onCompleted: (response) => {
      if (response.matchExternalDriverInvoices) {
        toast.success(`Matched ${response.matchExternalDriverInvoices.length} invoice(s)`)
      }
    },
  })

  // handlers
  const handleMatchInvoices = () => {
    matchInvoices({
      variables: {
        id: Number(id),
      },
    })
  }

  if (!Number(id)) {
    return <Typography variant='h4'>Invalid invoice number</Typography>
  }

  return (
    <Paper variant='outlined' elevation={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box p={2}>
            <CardTitle>External driver invoices</CardTitle>

            <LoadingButton variant='contained' loading={matching} onClick={handleMatchInvoices}>
              Match all
            </LoadingButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2}>
            <FplDataGrid
              query={GET_PAGED_EXTERNAL_DRIVER_INVOICES_QUERY}
              entityName='pagedExternalDriverInvoices'
              columns={columns}
              defaultOrder={{ field: 'createdAt', sort: 'desc' }}
              toolbar={{ caption: 'Invoices' }}
              filter={filter}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ExternalDriverInvoices
