import { useState } from 'react'

import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material'

import { HtmlEditor, IHtmlEditorProps } from './HtmlEditor'

const boxStyle = {
  padding: 2,
  border: 1,
  borderRadius: 1,
  bgcolor: 'background.paper',
}

interface IProps extends IHtmlEditorProps {
  label: string
  required?: boolean // TODO: add validation, while stripping HTML
  error?: boolean
  helperText?: any
}
export const WysiwygDraft = (props: IProps) => {
  const { label, required, error, helperText, ...otherProps } = props

  const [toolbarHidden, setToolbarHidden] = useState(true)

  return (
    <>
      <Box sx={{ ...boxStyle, borderColor: error ? 'error.main' : 'grey.dark' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={0}>
          <Typography variant='h6' gutterBottom>
            {label}
            {required && ' *'}
          </Typography>

          <FormControlLabel
            control={
              <Switch checked={!toolbarHidden} onChange={() => setToolbarHidden(!toolbarHidden)} />
            }
            label='Format text'
          />
        </Stack>

        <HtmlEditor toolbarHidden={toolbarHidden} {...otherProps} />
      </Box>

      <Box sx={{ paddingX: 2 }}>
        {error && (
          <Typography variant='body2' color='error.main'>
            {helperText}
          </Typography>
        )}
      </Box>
    </>
  )
}
