import React from 'react'

import { ConfirmDialog } from 'components/common/Dialog'
import { ConfirmDialogContext } from 'providers/ConfirmDialogProvider'

const ConfirmDialogContainer = () => {
  const context = React.useContext(ConfirmDialogContext)

  return (
    <ConfirmDialog
      open={context.confirmOpen}
      setOpen={context.setConfirmOpen}
      onConfirm={context.onConfirmApprove}
      title={context.options?.title}>
      {context.options?.content}
    </ConfirmDialog>
  )
}

export default ConfirmDialogContainer
