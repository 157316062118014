import {
  BookingPeriod,
  BookingPeriodValues,
  CompleteBookingStatusValues,
  InProgressBookingStatusValues,
  StatusCategories,
  QuoteStatusValues,
} from 'constants/index'

import { useEffect, useMemo, useRef } from 'react'

import { useReactiveVar } from '@apollo/client'
import { Box, Grid } from '@mui/material'
import {
  ControlledSelector,
  ControlledDatePicker,
  CustomersSelector,
  TariffsSelector,
  UserTariffsSelector,
  ControlledCheckbox,
  XeroCodesSelector,
  BookingUsersSelector,
  UserCustomersSelector,
} from 'components/common'
import { bookingsFilterFormVar, bookingsStatusCategoryVar } from 'graphql/reactiveVariables'
import { Restricted } from 'providers/PermissionProvider'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import ActionButtons from './ActionButtons'

const defaultValues = {
  customerId: '',
  tariffId: '',
  xeroCodeId: '',
  statusId: '',
  isOnlyMultidrop: false,
  docId: '',
  takenBy: '',
  period: BookingPeriod.AnyTime,
  dateFrom: null,
  dateTo: null,
}

const FilterCriteriaForm = () => {
  const didMount = useRef(false)
  const bookingStatusCategory = useReactiveVar(bookingsStatusCategoryVar)

  const methods = useForm({
    shouldUnregister: true,
    defaultValues: defaultValues,
  })
  const { control, watch, reset, handleSubmit } = methods

  const periodValue = watch('period')

  useEffect(() => {
    const bookingsFilterForm = bookingsFilterFormVar()
    reset({ ...defaultValues, ...bookingsFilterForm })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  useEffect(() => {
    if (didMount.current) {
      reset(defaultValues)
      bookingsFilterFormVar({})
    } else {
      didMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingStatusCategory, reset])

  const statusOptions = useMemo(() => {
    switch (bookingStatusCategory) {
      case StatusCategories.InProgress:
        return InProgressBookingStatusValues

      case StatusCategories.Complete:
        return CompleteBookingStatusValues

      case StatusCategories.Quote:
        return QuoteStatusValues

      default:
        return []
    }
  }, [bookingStatusCategory])

  const handleClearAll = () => {
    reset(defaultValues)
    bookingsFilterFormVar({})
  }

  const handleSubmitForm: SubmitHandler<any> = (data) => {
    bookingsFilterFormVar(data)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container columnSpacing={3} rowSpacing={2} justifyContent='space-between'>
          <Grid item xs={12} md>
            <Grid container rowSpacing={1.5}>
              <Grid item xs={12}>
                <Grid container columnSpacing={3} rowSpacing={1.5}>
                  <Restricted to='ViewBookingsList'>
                    <Grid item xs={12} sm={6} md={4} lg={2.5}>
                      <CustomersSelector control={control} />
                    </Grid>
                  </Restricted>

                  <Restricted to='ViewUserBookingsList'>
                    <Grid item xs={12} sm={6} md={4} lg={2.5}>
                      <UserCustomersSelector control={control} />
                    </Grid>
                  </Restricted>

                  <Grid item xs={12} sm={6} md={4} lg={2.5}>
                    <Restricted to='ViewTariffList'>
                      <TariffsSelector control={control} />
                    </Restricted>
                    <Restricted to='ViewUserTariffList'>
                      <UserTariffsSelector control={control} />
                    </Restricted>
                  </Grid>

                  <Restricted to='ViewXeroCodeList'>
                    <Grid item xs={12} sm={6} md={4} lg={2.5}>
                      <XeroCodesSelector control={control} />
                    </Grid>
                  </Restricted>

                  <Grid
                    hidden={
                      bookingStatusCategory === StatusCategories.PreBooked ||
                      bookingStatusCategory === StatusCategories.ScheduledTemplate
                    }
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.5}>
                    <ControlledSelector
                      control={control}
                      name='statusId'
                      label='Status'
                      options={statusOptions}
                      emptyValue={{ value: '', label: 'All Statuses' }}
                      displayEmpty
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box display='flex' alignItems='center' height='100%'>
                      <ControlledCheckbox
                        control={control}
                        name='isOnlyMultidrop'
                        label='Only Multidrop'
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container columnSpacing={3} rowSpacing={1.5}>
                  <Grid item xs={12} sm={6} md={4} lg={2.5}>
                    <ControlledSelector
                      control={control}
                      name='docId'
                      label='Docs'
                      options={[]}
                      emptyValue={{ value: '', label: 'All Docs' }}
                      displayEmpty
                    />
                  </Grid>

                  <Restricted to='ViewBookingUsers'>
                    <Grid item xs={12} sm={6} md={4} lg={2.5}>
                      <BookingUsersSelector control={control} />
                    </Grid>
                  </Restricted>

                  <Grid item xs={12} sm={6} md={4} lg={2.5}>
                    <ControlledSelector
                      control={control}
                      name='period'
                      label='Period'
                      options={BookingPeriodValues}
                      emptyValue={{ value: BookingPeriod.AnyTime, label: 'Any Time' }}
                      displayEmpty
                    />
                  </Grid>

                  {periodValue === BookingPeriod.CustomRange && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={2.5}>
                        <ControlledDatePicker control={control} name='dateFrom' label='From' />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2.5}>
                        <ControlledDatePicker control={control} name='dateTo' label='To' />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md='auto'>
            <ActionButtons onClearAll={handleClearAll} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default FilterCriteriaForm
