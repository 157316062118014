import { Accordion, AccordionSummary, Typography } from '@mui/material'

import { BookingAddress } from 'generated/graphql'

import AttachmentsAccordionDetails from './AttachmentsAccordionDetails'

interface AddressAttachmentsAccordionProps {
  address: BookingAddress
  expand: string | false
  onChange: any
}

const AddressAttachmentsAccordion = (props: AddressAttachmentsAccordionProps) => {
  const { address, expand, onChange } = props

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expand === address.address.name}
      onChange={onChange(address.address.name)}>
      <AccordionSummary>
        <Typography variant='h5'>
          {address.sequenceOrder + 1}. {address.address.name}{' '}
          <Typography>{address.type}</Typography>
        </Typography>
      </AccordionSummary>
      <AttachmentsAccordionDetails id={Number(address.id)} />
    </Accordion>
  )
}

export default AddressAttachmentsAccordion
