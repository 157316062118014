import React from 'react'

import { Box, Paper } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  elevation?: number
  padding?: any
  paddingY?: any
  paddingX?: any
}

const TabPanel = (props: TabPanelProps) => {
  const { index, value, children, elevation = 5, padding = 2, paddingX, paddingY, ...other } = props

  return (
    <Box
      position='relative'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tab-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Paper elevation={elevation} square>
          <Box p={padding} px={paddingX} py={paddingY}>
            {children}
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export { TabPanel }
