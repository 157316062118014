import React from 'react'

import { SearchOutlined } from '@mui/icons-material'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledSelector, ControlledTextField } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { PaymentTermValues } from 'constants/EntityConstants'
import { useGetDriverLazyQuery } from 'generated/graphql'

import SearchCxDriverDialog from './SearchCxDriverDialog'

interface IProps {
  disabled?: boolean
}

const CXMemberDetails = (props: IProps) => {
  const { disabled = false } = props
  const [openSearchCxDriverDialog, setOpenSearchCxDriverDialog] = React.useState(false)

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const hoverText = 'Available only for booking'

  // Queries
  const [getDriver] = useGetDriverLazyQuery({
    onCompleted: (data) => {
      if (data.driver) {
        setValue('orderSubcontractorId', data.driver.courierExchangeMemberId)
      }
    },
  })

  const handleCXMemberSearchClick = () => {
    setOpenSearchCxDriverDialog(true)
  }

  const handleSearchCxDriverDialogClose = () => {
    setOpenSearchCxDriverDialog(false)
  }

  const handleSelectCxDriver = (id: number) => {
    getDriver({ variables: { driverId: id } })
    setOpenSearchCxDriverDialog(false)
  }

  return (
    <>
      <Tooltip title={disabled ? hoverText : ''} followCursor>
        <div>
          <CardTitle disabled={disabled}>CX Member Details</CardTitle>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ControlledTextField
                control={control}
                label='CX Member ID'
                name='orderSubcontractorId'
                defaultValue=''
                endAdornment={
                  <IconButton
                    onClick={handleCXMemberSearchClick}
                    aria-label='search CX Member'
                    disabled={disabled}
                    size='large'>
                    <SearchOutlined />
                  </IconButton>
                }
                required
                disabled={disabled}
                error={!!errors.orderSubcontractorId}
                helperText={errors.orderSubcontractorId?.message}
              />
            </Grid>

            <Grid item xs={3}>
              <ControlledTextField
                control={control}
                label='Agreed Rate'
                name='orderAgreedRate'
                defaultValue=''
                type='text'
                inputMode='numeric'
                pattern='[0-9]*'
                sanitizedNumber
                min='0'
                startAdornment='£'
                required
                disabled={disabled}
                error={!!errors.orderAgreedRate}
                helperText={errors.orderAgreedRate?.message}
              />
            </Grid>

            <Grid item xs={3}>
              <ControlledSelector
                control={control}
                label='Payment Term'
                name='orderPaymentTerm'
                options={PaymentTermValues}
                required
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </div>
      </Tooltip>

      {openSearchCxDriverDialog && (
        <SearchCxDriverDialog
          openDialog={openSearchCxDriverDialog}
          handleCloseDialog={handleSearchCxDriverDialogClose}
          onSelectCxDriver={handleSelectCxDriver}
        />
      )}
    </>
  )
}

export default CXMemberDetails
