import { Close } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { Box, IconButton, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField } from 'components/common'
import { BookingChargeType } from 'generated/graphql'
import { usePermission } from 'providers/PermissionProvider'
import { TBookingForm } from 'types/form-types'

const ChargeNameTableCell = styled(TableCell)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  minWidth: 120,
}))

const EditableTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  width: theme.spacing(15),
  minWidth: 120,
}))

const ActionTableCell = styled(TableCell)({
  padding: 'unset',
})

interface IProps {
  row: Record<any, string>
  index: number
  disabled?: boolean
  isAllowedToViewVatCategories: boolean
  isAllowedToDeleteCharge: boolean
  onRemoveClick: (index: number, id: string) => void
  onInfoClick: (index: number) => void
}

const ChargeRow = (props: IProps) => {
  const {
    row,
    index,
    disabled,
    isAllowedToViewVatCategories,
    isAllowedToDeleteCharge,
    onRemoveClick,
    onInfoClick,
  } = props
  const { control } = useFormContext<TBookingForm>()
  const [isAllowedToEditCharges] = usePermission('EditBookingCharge')

  const handleRemoveClick = () => {
    onRemoveClick(index, row.id!)
  }

  const handleInfoClick = () => {
    onInfoClick(index)
  }

  return (
    <TableRow>
      <ChargeNameTableCell>{row?.name}</ChargeNameTableCell>

      <EditableTableCell>
        {isAllowedToEditCharges ? (
          <ControlledTextField
            control={control}
            label='Qty'
            name={`charges.${index}.quantity`}
            defaultValue={row?.quantity}
            disabled={disabled}
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            sanitizedNumber
            min='0'
            size='small'
          />
        ) : (
          row?.quantity
        )}
      </EditableTableCell>

      <EditableTableCell>
        {isAllowedToEditCharges && row?.type === BookingChargeType.Custom ? (
          <ControlledTextField
            control={control}
            label='Rate'
            name={`charges.${index}.rate`}
            defaultValue={row?.rate}
            disabled={disabled}
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            sanitizedNumber
            min='0'
            size='small'
          />
        ) : (
          row?.rate
        )}
      </EditableTableCell>

      {(isAllowedToViewVatCategories || isAllowedToDeleteCharge) && (
        <ActionTableCell align='right'>
          <Box display='flex' justifyContent='flex-end'>
            {isAllowedToViewVatCategories && (
              <IconButton disabled={disabled} onClick={handleInfoClick} size='large'>
                <InfoIcon />
              </IconButton>
            )}

            {isAllowedToDeleteCharge && (
              <IconButton disabled={disabled} onClick={handleRemoveClick} size='large'>
                <Close />
              </IconButton>
            )}
          </Box>
        </ActionTableCell>
      )}
    </TableRow>
  )
}

export default ChargeRow
