import { useEffect } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Paper, Tooltip } from '@mui/material'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { CardTitle, ControlledDateTimePicker } from 'components/common'
import { JoiValidationOptions } from 'constants/index'
import { useUpdateBookingAddressEtaMutation } from 'generated/graphql'
import { Restricted } from 'providers'

interface IProps {
  variables: {
    sequenceOrder: number
    addressId: number
    eTA?: Date
  }
  hoverText: string
  disabled?: boolean
  loading?: boolean
}

type EtaFormData = {
  eTA: Date | null
}

const validationSchema = Joi.object<EtaFormData>({
  eTA: Joi.date().empty(null).optional().label('ETA'),
})

const PoAddressEta = (props: IProps) => {
  const { variables, disabled, hoverText, loading } = props
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EtaFormData>({
    defaultValues: {
      eTA: null,
    },
    resolver: joiResolver(validationSchema, JoiValidationOptions),
    shouldUnregister: true,
  })
  // effects
  useEffect(() => {
    if (variables.eTA) {
      reset({ eTA: variables.eTA })
    }
  }, [variables.eTA, reset])

  // mutations
  const [updateEta, { loading: updatingEta }] = useUpdateBookingAddressEtaMutation({
    onCompleted: (response) => {
      if (!response.updateBookingAddressEta.eTA) {
        toast.error('Something went wrong')
        return
      }
      const newEta = new Date(response.updateBookingAddressEta.eTA)
      toast.success(`Eta updated successfully ${newEta.toUTCString()}`)
    },
  })

  // handlers
  const handleAutoCalculateClick = () => {
    updateEta({
      variables: {
        input: {
          auto: true,
          id: variables.addressId,
        },
      },
    })
  }

  const handleUpdateEta = (values: EtaFormData) => {
    updateEta({
      variables: {
        input: {
          id: variables.addressId,
          auto: false,
          eta: values.eTA || new Date(),
        },
      },
    })
  }

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <CardTitle>Estimated time of arrival {loading ? 'Loading ...' : ''}</CardTitle>

        <Tooltip title={disabled ? hoverText : ''} followCursor>
          <Grid container>
            <Grid item xs={12} sm={5}>
              <ControlledDateTimePicker
                control={control}
                name='eTA'
                defaultValue={null}
                label='ETA'
                disabled={disabled}
                error={!!errors.eTA}
                helperText={errors.eTA?.message}
              />
            </Grid>

            <Restricted to='UpdateEta'>
              <Grid xs={12} sm={3}>
                {variables.sequenceOrder !== 0 && (
                  <LoadingButton
                    loading={updatingEta}
                    disabled={loading}
                    onClick={handleSubmit(handleAutoCalculateClick)}
                    size='large'
                    variant='outlined'
                    style={{ height: '100%' }}>
                    Auto
                  </LoadingButton>
                )}
              </Grid>

              <Grid xs={12} sm={3}>
                <LoadingButton
                  loading={updatingEta}
                  disabled={loading}
                  onClick={handleSubmit(handleUpdateEta)}
                  size='large'
                  variant='outlined'
                  style={{ height: '100%' }}>
                  Save
                </LoadingButton>
              </Grid>
            </Restricted>
          </Grid>
        </Tooltip>
      </Box>
    </Paper>
  )
}

export default PoAddressEta
