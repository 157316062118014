import { addBusinessDays, format, isSameDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import Joi from 'joi'

import {
  CollectionInterval,
  DATE_FORMAT,
  DeliveryIntervals,
  Mode,
  TIME_FORMAT,
  UK_TIMEZONE,
} from 'constants/index'
import { XeroServiceType } from 'generated/graphql'
import {
  businessDaysBetween,
  daysBetween,
  getCutOffTimeInterval,
  ignoreServiceType,
  isMultiDrop,
  isWithinTimeInterval,
} from 'helpers'
import { TBookingForm, TransitInterval } from 'types'

type TransitDateValidation = {
  value: Date
  zonedCollection: Date
  zonedDelivery: Date
  deliveryType: TransitInterval
  deliveryNextDayError: string
  deliveryOutsideIntervalError: string
  isInternationalAirFreight?: boolean
}

const validateCutoffTime = (value: Date, serviceType?: XeroServiceType) => {
  const cutOffDays = getCutOffTimeInterval(serviceType)
  if (cutOffDays < 1) {
    return value
  }

  const requiredValue = addBusinessDays(CollectionInterval.start, cutOffDays).setHours(0, 0, 0, 0)

  if (value < new Date(requiredValue)) {
    throw new Error(`Collection date should be at least ${format(requiredValue, DATE_FORMAT)}`)
  }

  return value
}

export const processGoodsValidation = ({
  value,
  zonedCollection,
  zonedDelivery,
  deliveryType,
  deliveryNextDayError,
  deliveryOutsideIntervalError,
  isInternationalAirFreight = false,
}: TransitDateValidation): Date => {
  if (
    isInternationalAirFreight &&
    daysBetween({ start: zonedCollection, end: zonedDelivery }) !== deliveryType.days
  ) {
    throw new Error(deliveryNextDayError)
  }

  if (
    !isInternationalAirFreight &&
    businessDaysBetween({ start: zonedCollection, end: zonedDelivery }) !== deliveryType.days
  ) {
    throw new Error(deliveryNextDayError)
  }

  if (!isWithinTimeInterval(zonedDelivery, deliveryType)) {
    throw new Error(
      `${deliveryOutsideIntervalError} ${format(deliveryType.start, TIME_FORMAT)} - ${format(
        deliveryType.end,
        TIME_FORMAT,
      )}`,
    )
  }

  return value
}

const validateCollection = (
  value: Date | undefined,
  helpers: Joi.CustomHelpers<Date>,
  isCollectionBy = false,
) => {
  // 0 current object
  // 1 collection addresses
  // 2 booking details
  const bookingDetails: TBookingForm = helpers.state.ancestors[2]

  if (
    bookingDetails.isTransitIgnored ||
    ignoreServiceType(bookingDetails.serviceType) ||
    isMultiDrop(bookingDetails)
  ) {
    return value
  }

  if (!value) {
    return helpers.error('any.required')
  }

  if (!isWithinTimeInterval(value, CollectionInterval)) {
    throw new Error(
      `Collection hour should be between ${format(
        CollectionInterval.start,
        TIME_FORMAT,
      )} - ${format(CollectionInterval.end, TIME_FORMAT)}`,
    )
  }

  if (isCollectionBy && !isSameDay(value, new Date(bookingDetails.collectionAddresses[0].at))) {
    throw new Error(`At & By should be in same day`)
  }

  // cutoff-time
  if (helpers.prefs.context?.mode === Mode.Create) {
    return validateCutoffTime(value, bookingDetails.serviceType)
  }

  return value
}

const validateCollectionAt = (value: Date | undefined, helpers: Joi.CustomHelpers<Date>) => {
  return validateCollection(value, helpers)
}

const validateCollectionBy = (value: Date | undefined, helpers: Joi.CustomHelpers<Date>) => {
  return validateCollection(value, helpers, true)
}

const validateDelivery = (
  value: Date | undefined,
  helpers: Joi.CustomHelpers<Date>,
  isDeliveryBy = false,
) => {
  const bookingDetails: TBookingForm = helpers.state.ancestors[2]
  const collectionDateAndTime = isDeliveryBy
    ? bookingDetails.collectionAddresses[0].by
    : bookingDetails.collectionAddresses[0].at

  if (
    bookingDetails.isTransitIgnored ||
    ignoreServiceType(bookingDetails.serviceType) ||
    isMultiDrop(bookingDetails)
  ) {
    return value
  }

  if (!value) {
    return helpers.error('any.required')
  }

  if (!collectionDateAndTime) {
    return value
  }

  if (
    isDeliveryBy &&
    bookingDetails.deliveryAddresses[0].at &&
    !isSameDay(utcToZonedTime(new Date(bookingDetails.deliveryAddresses[0].at), UK_TIMEZONE), value)
  ) {
    throw new Error('Delivery At and Delivery By should be in the same day')
  }

  switch (bookingDetails.serviceType) {
    case XeroServiceType.DomesticParcels:
      return processGoodsValidation({
        value,
        zonedCollection: isDeliveryBy ? collectionDateAndTime : new Date(collectionDateAndTime),
        zonedDelivery: value,
        deliveryType: DeliveryIntervals.DOMESTIC_PARCELS,
        deliveryNextDayError: 'Delivery should be next working day',
        deliveryOutsideIntervalError: 'Delivery should be between',
      })

    case XeroServiceType.InternationalParcels:
      return processGoodsValidation({
        value,
        zonedCollection: isDeliveryBy ? collectionDateAndTime : new Date(collectionDateAndTime),
        zonedDelivery: value,
        deliveryType: DeliveryIntervals.INTERNATIONAL_PARCELS,
        deliveryNextDayError: `Delivery should be in ${DeliveryIntervals.INTERNATIONAL_PARCELS.days} working days`,
        deliveryOutsideIntervalError: 'Delivery should be between',
      })

    case XeroServiceType.DomesticPallets:
      return processGoodsValidation({
        value,
        zonedCollection: isDeliveryBy ? collectionDateAndTime : new Date(collectionDateAndTime),
        zonedDelivery: value,
        deliveryType: DeliveryIntervals.DOMESTIC_PALLETS,
        deliveryNextDayError: 'Delivery should be next working day',
        deliveryOutsideIntervalError: 'Delivery should be between',
      })

    case XeroServiceType.EuropeanGroupage:
      return processGoodsValidation({
        value,
        zonedCollection: isDeliveryBy ? collectionDateAndTime : new Date(collectionDateAndTime),
        zonedDelivery: value,
        deliveryType: DeliveryIntervals.EUROPEAN_GROUPAGE,
        deliveryNextDayError: `Delivery date should be ${DeliveryIntervals.EUROPEAN_GROUPAGE.days} working days after collection date`,
        deliveryOutsideIntervalError: 'Delivery hour should be between',
      })

    case XeroServiceType.InternationalAirFreight:
      return processGoodsValidation({
        value,
        zonedCollection: isDeliveryBy ? collectionDateAndTime : new Date(collectionDateAndTime),
        zonedDelivery: value,
        deliveryType: DeliveryIntervals.INTERNATIONAL_AIR_FREIGHT,
        deliveryNextDayError: `Delivery should be in ${DeliveryIntervals.INTERNATIONAL_AIR_FREIGHT.days} days`,
        deliveryOutsideIntervalError: 'Delivery time should be between',
        isInternationalAirFreight: true,
      })

    case XeroServiceType.SeaFreight:
      return processGoodsValidation({
        value,
        zonedCollection: isDeliveryBy ? collectionDateAndTime : new Date(collectionDateAndTime),
        zonedDelivery: value,
        deliveryType: DeliveryIntervals.SEA_FREIGHT,
        deliveryNextDayError: `Delivery should be in ${DeliveryIntervals.SEA_FREIGHT.days} working days after collection`,
        deliveryOutsideIntervalError: 'Delivery time should be between',
      })
  }

  return value
}

const validateDeliveryAt = (value: Date | undefined, helpers: Joi.CustomHelpers<Date>) => {
  return validateDelivery(value, helpers)
}

const validateDeliveryBy = (value: Date | undefined, helpers: Joi.CustomHelpers<Date>) => {
  return validateDelivery(value, helpers, true)
}

export { validateCollectionAt, validateCollectionBy, validateDeliveryAt, validateDeliveryBy }
