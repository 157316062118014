import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Controller } from 'react-hook-form'

interface Option {
  value: any
  label: string
}

interface IProps {
  control: any
  name: string
  options: Option[]
  row?: boolean
}

const ControlledRadioGroup = (props: IProps) => {
  const { control, name, options, row } = props

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup row={row} aria-label={name} {...field}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}

export default ControlledRadioGroup
