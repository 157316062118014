import { gql } from '@apollo/client'

export const NotificationDetailsFragment = gql`
  fragment NotificationDetails on Notification {
    name
    description
    username
    isRead
    createdAt
    createdBy
    taskId
    priority
    date
    type
  }
`
