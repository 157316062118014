import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Stepper, Step, StepButton, Button, Typography } from '@mui/material'

import ContactInformationStep from './ContactInformationStep'
import FinancialInformationStep from './FinancialInformationStep'
import GeneralInformationStep from './GeneralInformationStep'

const steps = ['General Information', 'Contact Information', 'Financial Information']

function getStepContent(step: number): JSX.Element {
  switch (step) {
    case 0:
      return <GeneralInformationStep />
    case 1:
      return <ContactInformationStep />
    case 2:
      return <FinancialInformationStep />
    default:
      return <Typography sx={{ mt: 2, mb: 1, py: 1 }}>Unknown step</Typography>
  }
}

interface Props {
  disabled?: boolean
  loading?: boolean
}

export default function OnboardingStepperForm({ disabled, loading }: Props) {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<{
    [k: number]: boolean
  }>({})

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color='inherit' onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant='caption' sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : completedSteps() === totalSteps() - 1 ? (
                  <LoadingButton type='submit' key='finish' loading={loading}>
                    Finish
                  </LoadingButton>
                ) : (
                  <Button onClick={handleComplete} key='complete_step' disabled={disabled}>
                    Complete Step
                  </Button>
                ))}
            </Box>
          </>
        )}
      </div>
    </Box>
  )
}
