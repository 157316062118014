import React from 'react'

import { toast } from 'react-toastify'

import { LoadingBackdrop } from 'components/common'
import ReferencesDetailsForm from 'components/customers/ReferencesAndNotes/ReferencesDetailsForm'
import {
  UpdateCustomerReferencesAndNotesDtoInput,
  useGetCustomerReferencesAndNotesQuery,
  useUpdateCustomerReferencesAndNotesMutation,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'

interface IProps {
  customerId: number | null
}

const ReferencesAndNotes = (props: IProps) => {
  const { customerId } = props
  const [referencesAndNotes, setReferencesAndNotes] =
    React.useState<UpdateCustomerReferencesAndNotesDtoInput>({
      id: '',
      customerReferenceLabel: '',
      isCustomerReferenceMandatory: false,
      noteForAccount: '',
      noteForBooking: '',
      isPaperworkRequired: false,
      noteForPaperwork: '',
      instructionsForCourierExchangeDriver: '',
      noteForPackage: '',
    })
  const { confirmOpen } = useConfirmDialog()

  const { loading: referencesAndNotesLoading } = useGetCustomerReferencesAndNotesQuery({
    variables: {
      customerId,
    },
    onCompleted: (data) => {
      if (data) {
        const { /* __typename, */ ...referencesAndNotes } = data.customer.customer
        setReferencesAndNotes({ ...referencesAndNotes } as UpdateCustomerReferencesAndNotesDtoInput)
      }
    },
  })

  const [
    updateReferencesAndNotes,
    { error: updatingReferencesAndNotesErrors, loading: updatingReferencesAndNotes },
  ] = useUpdateCustomerReferencesAndNotesMutation()

  const onSuccess = (data) => {
    updateReferencesAndNotes({
      variables: {
        input: {
          id: customerId,
          ...data,
        } as UpdateCustomerReferencesAndNotesDtoInput,
      },
    }).then(() => {
      toast.success(`References and Notes was successfully updated!`)
    })
  }
  const onCancel = (formIsDirty) => {
    if (formIsDirty) {
      confirmOpen()
    }
  }

  const isLoading = referencesAndNotesLoading
  const processing = updatingReferencesAndNotes
  const processingErrors = updatingReferencesAndNotesErrors

  if (processingErrors) {
    processingErrors?.graphQLErrors.map((e) => toast.error(e.message))
  }

  return (
    <>
      <ReferencesDetailsForm
        referenceAndNotes={referencesAndNotes}
        onCancel={onCancel}
        onSuccess={onSuccess}
        processing={processing}
      />
      <LoadingBackdrop loading={isLoading} />
    </>
  )
}

export default ReferencesAndNotes
