import { useMemo } from 'react'

import { ControlledSelector } from 'components/common'
import { useGetUserPagedTariffsQuery } from 'generated/graphql'

interface IProps {
  control: any
}

const UserTariffsSelector = (props: IProps) => {
  const { control } = props

  // Queries
  const { data: tariffsData } = useGetUserPagedTariffsQuery({
    fetchPolicy: 'network-only',
    variables: {
      first: 1000,
    },
  })

  const tariffsItems = useMemo(
    () =>
      tariffsData?.userPagedTariffs?.edges?.map((tariff) => ({
        value: tariff.node?.id,
        label: tariff.node?.name,
      })) || [],
    [tariffsData],
  )

  return (
    <ControlledSelector
      control={control}
      name='tariffId'
      label='Tariff'
      options={tariffsItems}
      emptyValue={{ value: '', label: 'All Tariffs' }}
      displayEmpty
    />
  )
}

export default UserTariffsSelector
