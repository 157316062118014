export const PhonePattern =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const DriverDetailsPhonePattern =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,4})[-. )]*(\d{1,4})[-. ]*(\d{1,4})[-. ]*(\d{1,4})[-. ]*(\d{1,4})(?: *x(\d+))?\s*$/
export const NamePattern = /^[a-zA-Z'.\s]+$/
export const UserNamePattern = /^[0-9A-Za-z._@]+$/
export const CompanyNamePattern = /^[0-9A-Za-z-._@]+$/
export const DigitAndWhitespacePattern = /^[0-9 ]+$/
export const DigitPattern = /^\d+$/

// console.log(isValidString("abc123"));         // true
// console.log(isValidString("123"));            // false
// console.log(isValidString("abc 123"));        // true
// console.log(isValidString("abc-123"));        // true
// console.log(isValidString("abc- 123"));       // true
// console.log(isValidString("12312 123123"));   // false
// console.log(isValidString("12312-12312"));    // false
export const AlphanumericAndSpacePattern = /^(?![\d\s-]+$)[a-zA-Z0-9\s-]+$/
export const NumberDigitWhitespacePattern = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/

export const PasswordRegExp = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
)

// The \b characters before and after the entire regular expression ensure that it only matches
// if it's a complete word, rather than a partial match.
// This will prevent the invalid value "BS1 6BX11212" from matching the pattern.
export const UK_POSTCODE_REGEX =
  /\b(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2}))\b/

export const ALPHABETIC_SPACE_HYPHEN_PATTERN_REGEX = /^[a-zA-Z]+(?:[ -][a-zA-Z]+)*\s*$/
