import { ReactNode } from 'react'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { Controller } from 'react-hook-form'

interface ISelectorOption {
  value: any
  label: ReactNode
  disabled?: boolean
}

type ISelectorOptions = Array<ISelectorOption>

interface IProps {
  control: any
  label?: string
  name: string
  options: ISelectorOptions
  emptyValue?: ISelectorOption
  defaultValue?: any
  displayEmpty?: boolean
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  variant?: 'outlined' | 'standard' | 'filled'
  size?: 'small' | 'medium'
  fullWidth?: boolean
  error?: boolean
  helperText?: any
}

const ControlledSelector = (props: IProps) => {
  const {
    control,
    defaultValue,
    name,
    label,
    options,
    emptyValue,
    displayEmpty,
    fullWidth = true,
    readOnly,
    variant = 'outlined',
    helperText,
    ...otherProps
  } = props

  const id = `${name}-selector`
  const labelId = `${name}-selector-label`

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...restField } }) => (
        <FormControl variant={variant} fullWidth={fullWidth} {...otherProps}>
          {displayEmpty ? (
            <InputLabel shrink id={labelId}>
              {label}
            </InputLabel>
          ) : (
            <InputLabel id={labelId}>{label}</InputLabel>
          )}

          <Select
            {...restField}
            inputRef={ref}
            labelId={labelId}
            id={id}
            label={label}
            displayEmpty={displayEmpty}
            readOnly={readOnly}
            input={
              displayEmpty && variant === 'outlined' ? (
                <OutlinedInput label={label} notched />
              ) : undefined
            }>
            {emptyValue && (
              <MenuItem value={emptyValue.value}>
                <em>{emptyValue.label}</em>
              </MenuItem>
            )}

            {options.map((option) => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export { ControlledSelector }
export type { ISelectorOption, ISelectorOptions }
