import React from 'react'

import { Typography } from '@mui/material'

import { FplDialogTitle } from 'components/common/Dialog'

interface IProps {
  id?: string
  notFound: boolean
  creating: boolean
  onClose: () => void
}
const getTitleText = (props: IProps) => {
  if (props.notFound) {
    return 'Special Zone not found'
  }

  return props.creating ? 'Create Special Zone' : 'Update Special Zone'
}

export default function CongestionZoneDialogTitle(props: IProps) {
  const [titleText, setTitleText] = React.useState('')

  React.useEffect(() => {
    setTitleText(getTitleText(props))
  }, [props])

  return (
    <FplDialogTitle id={props.id} onClose={props.onClose}>
      <Typography variant='h4'>{titleText}</Typography>
    </FplDialogTitle>
  )
}
