import { useState, useEffect, useCallback } from 'react'

import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { ConfirmDialog } from 'components/common'
import { TBookingForm } from 'types'

const BookingDiscardConfirmation = () => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [confirmationDetails, setConfirmationDetails] = useState({
    isConfirmed: false,
    path: '',
  })
  const history = useHistory()
  const {
    formState: { dirtyFields, isSubmitSuccessful },
  } = useFormContext<TBookingForm>()

  const handleGoToIntendedPage = useCallback((location) => history.push(location), [history])

  useEffect(() => {
    if (confirmationDetails.isConfirmed) {
      handleGoToIntendedPage(confirmationDetails.path)
    }

    const unblock = history.block((location) => {
      if (!!Object.keys(dirtyFields).length && !isSubmitSuccessful) {
        setOpenConfirmDialog(true)
      } else {
        return
      }

      setConfirmationDetails((prevState) => ({
        ...prevState,
        path: `${location.pathname}${location.hash}`,
      }))

      if (confirmationDetails.isConfirmed) {
        return
      }

      return false
    })

    return () => {
      unblock()
    }
  }, [
    handleGoToIntendedPage,
    history,
    dirtyFields,
    isSubmitSuccessful,
    confirmationDetails.isConfirmed,
    confirmationDetails.path,
  ])

  const handleConfirmDialog = () => {
    setConfirmationDetails((prevState) => ({
      ...prevState,
      isConfirmed: true,
    }))
  }

  return (
    <>
      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={handleConfirmDialog}
        />
      )}
    </>
  )
}

export default BookingDiscardConfirmation
