import { useRef, useState } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { ListOutlined } from '@mui/icons-material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import useSound from 'use-sound'

import { NavIconButton, NavPopper } from 'components/common'
import { getPaging } from 'components/common/FplDataGrid/helpers'
import { Paths, NOTIFICATION_SOUND_URL } from 'constants/index'
import {
  NotificationPriority,
  useGetNotificationSubscription,
  Notification,
  useGetNotificationsQuery,
  SortEnumType,
  useGetTasksLazyQuery,
  TaskStatus,
} from 'generated/graphql'
import { Restricted, SpecialTariffDialogOptions, TaskDialogOptions, useDialog } from 'providers'

import NotificationItem from './NotificationItem'

const ButtonNotifications = () => {
  const theme = useTheme()
  const history = useHistory()
  const { dialogOpen } = useDialog<TaskDialogOptions | SpecialTariffDialogOptions>()
  const [play] = useSound(NOTIFICATION_SOUND_URL)
  const { idTokenPayload } = useOidcIdToken()

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const notificationsQueryVariables = {
    where: {
      username: { eq: idTokenPayload.name },
      isRead: { eq: false },
    },
    order: {
      createdAt: SortEnumType.Desc,
    },
    first: 10,
  }

  // queries
  const {
    loading: loadingNotifications,
    data: notificationsData,
    refetch: refetchNotifications,
  } = useGetNotificationsQuery({
    variables: notificationsQueryVariables,
    fetchPolicy: 'network-only',
  })

  const notifications: Notification[] | undefined = notificationsData?.notifications?.edges?.map(
    ({ node }) => node as Notification,
  )

  const [getTasks] = useGetTasksLazyQuery({
    fetchPolicy: 'network-only',
  })

  // subscriptions
  useGetNotificationSubscription({
    variables: {
      username: idTokenPayload.name || '',
    },
    onData: (data) => {
      const newNotification = data.data?.data?.onNewNotification

      if (newNotification) {
        toast.info(`Notification: ${newNotification?.name}`)
        refetchNotifications({ ...notificationsQueryVariables })

        const playSound = [NotificationPriority.Medium, NotificationPriority.High].includes(
          newNotification.priority,
        )

        if (playSound && NOTIFICATION_SOUND_URL.length > 0) {
          play()
        }

        if (newNotification.taskId) {
          getTasks({
            ...getPaging({ first: 10 }),
            variables: {
              where: {
                status: { eq: TaskStatus.New },
                assignedUserName: { eq: idTokenPayload.name },
              },
              order: { endDate: SortEnumType.Asc },
            },
          })
        }
      }
    },
  })

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleAllNotifications = () => history.push(Paths.myNotifications)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setIsOpen(false)
  }

  const onItemPress = ({ taskId, name, description }: Notification) => {
    if (taskId) {
      dialogOpen({
        id: taskId,
      })
    } else if (name === 'Customer notification') {
      dialogOpen({
        id: null,
        description,
      })
    } else {
      history.push(Paths.dashboard.all)
    }
  }

  const renderSecondary = () => (
    <>
      <Restricted to='ViewAllNotifications'>
        <IconButton size='small' onClick={handleAllNotifications}>
          <ListOutlined />
        </IconButton>
      </Restricted>
      <IconButton size='small' onClick={handleToggle}>
        <CloseOutlinedIcon />
      </IconButton>
    </>
  )

  const content = (
    <Box sx={{ flexShrink: 0 }}>
      <NavIconButton
        isOpen={isOpen}
        onClick={handleToggle}
        badgeCount={notificationsData?.notifications?.totalCount ?? 0}
        ref={anchorRef}
        loading={loadingNotifications}
        ariaLabel='open profile notifications'>
        <NotificationsIcon />
      </NavIconButton>

      <NavPopper
        isOpen={isOpen}
        onClickAway={handleClose}
        title={`Notifications ${notificationsData?.notifications?.totalCount ?? 0}`}
        anchorEl={anchorRef.current}
        matchesXs={matchesXs}
        secondary={renderSecondary()}>
        {notifications?.map((n) => (
          <NotificationItem
            key={n.id}
            notificationDetails={n}
            onItemPress={onItemPress}
            queryVariables={notificationsQueryVariables}
          />
        ))}
      </NavPopper>
    </Box>
  )

  return content
}
export default ButtonNotifications
