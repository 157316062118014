import { useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Paper, Skeleton } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import {
  CardTitle,
  ControlledCheckbox,
  ControlledMultipleAutocomplete,
  ControlledTextField,
  ControlledWysiwygDraft,
} from 'components/common'
import { Mode, Paths } from 'constants/index'
import { Customer, SortEnumType, useGetCustomersQuery } from 'generated/graphql'
import { mapCustomerValues } from 'helpers'
import { TStandardOperatingProcedureForm } from 'types'

interface IProps {
  mode: number
  isProcessing: boolean
}

const SopFormControls = (props: IProps) => {
  const { mode, isProcessing } = props

  const history = useHistory()
  const {
    control,
    formState: { errors },
  } = useFormContext<TStandardOperatingProcedureForm>()

  const isAttachedToAllCustomers: boolean = useWatch({ name: 'isAttachedToAllCustomers' })

  const { data: customerData, loading: customerLoading } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
  })

  const customers = useMemo(
    () => mapCustomerValues(customerData?.customers as Customer[]),
    [customerData],
  )

  // handlers
  const handleBackClick = () => {
    history.push(Paths.standardOperatingProcedures.all)
  }

  return (
    <Paper variant='outlined'>
      <Box p={2}>
        <CardTitle>SOP Details</CardTitle>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ControlledTextField
                  control={control}
                  label='Name'
                  name='name'
                  required
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                />
              </Grid>

              <Grid item xs={6}>
                <Box display='flex' height='100%' alignItems='center'>
                  <ControlledCheckbox
                    control={control}
                    label='Is Active'
                    name='isActive'
                    disabled={mode !== Mode.Update}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label='Description'
                  name='description'
                  required
                  multiline
                  rows={3}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledWysiwygDraft
                  control={control}
                  label='Steps'
                  name='steps'
                  required
                  error={!!errors?.steps}
                  helperText={errors?.steps?.message}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ControlledCheckbox
                  control={control}
                  name='isAttachedToAllCustomers'
                  label='All Customers'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {customerLoading ? (
                  <Skeleton animation='wave' />
                ) : (
                  <ControlledMultipleAutocomplete
                    control={control}
                    name='customerIds'
                    label='Customers'
                    options={customers}
                    disabled={isAttachedToAllCustomers}
                    required={!isAttachedToAllCustomers}
                    limitTags={3}
                    defaultValue={[]}
                    error={!!errors.customerIds}
                    helperText={errors.customerIds?.message}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <ControlledWysiwygDraft
                  control={control}
                  label='FAQ'
                  name='frequentlyAskedQuestions'
                  error={!!errors?.frequentlyAskedQuestions}
                  helperText={errors?.frequentlyAskedQuestions?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledWysiwygDraft
                  control={control}
                  label='Notes'
                  name='notes'
                  error={!!errors?.notes}
                  helperText={errors?.notes?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label='Driver Instructions'
                  name='driverInstructions'
                  multiline
                  rows={10}
                  error={!!errors?.driverInstructions}
                  helperText={errors?.driverInstructions?.message}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1.5} justifyContent='end'>
              <Grid item>
                <Button variant='contained' color='grey' onClick={handleBackClick}>
                  Back
                </Button>
              </Grid>

              <Grid item>
                <LoadingButton
                  variant='contained'
                  type='submit'
                  form='sop-details-form'
                  loading={isProcessing}>
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default SopFormControls
