import { Dispatch, SetStateAction } from 'react'

import { Skeleton } from '@mui/material'
import { toast } from 'react-toastify'

import { useGetVehicleQuery, CreateAndUpdateVehicleDtoInput, Vehicle } from 'generated/graphql'

import { VehicleForm } from './VehicleForm'

interface IProps {
  driverId: number
  vehicleId: number
  setNotFound: Dispatch<SetStateAction<boolean>>
  setVehicle: Dispatch<SetStateAction<CreateAndUpdateVehicleDtoInput>>
}

function UpdateVehicle(props: IProps) {
  const { driverId, vehicleId, setNotFound, setVehicle } = props
  const { loading: vehicleLoading } = useGetVehicleQuery({
    variables: {
      driverId,
      vehicleId,
    },
    onCompleted: (data) => {
      if (!data.vehicle) {
        setNotFound(true)
      }
      const { __typename, vehicleTypeId, ...rest } = data.vehicle as Vehicle

      setVehicle({
        ...rest,
        vehicleTypeId: Number(vehicleTypeId),
      } as CreateAndUpdateVehicleDtoInput)
    },
    onError: (error) => {
      error.graphQLErrors.map((e) => toast.error(e.message))
    },
  })

  return vehicleLoading ? (
    <Skeleton>
      <VehicleForm />
    </Skeleton>
  ) : (
    <VehicleForm />
  )
}

export default UpdateVehicle
