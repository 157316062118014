import { useEffect, useRef } from 'react'

//* Taken from this page
//* https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application
const TrustpilotBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null)
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref}
      className='trustpilot-widget'
      data-locale='en-US'
      data-template-id='53aa8912dec7e10d38f59f36'
      data-businessunit-id='5fc7baab75ddc70001896d7c'
      data-style-height='140px'
      data-style-width='100%'
      data-theme='light'
      data-stars='1,2,3,4,5'
      data-review-languages='en'>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href='https://www.trustpilot.com/review/firstpointlogistics.co.uk'
        target='_blank'
        rel='noopener'>
        Trustpilot
      </a>
    </div>
  )
}
export default TrustpilotBox
