import { Box, Grid, Paper } from '@mui/material'

// import { CardTitle } from 'components/common'

import ExternalInvoiceTable from './ExternalInvoiceTable'

const ExternalInvoices = () => {
  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <UploadExternalInvoice />
          </Box>
        </Paper>
      </Grid> */}

      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            {/* <CardTitle>External Driver Invoices</CardTitle> */}
            <ExternalInvoiceTable />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ExternalInvoices
