import { useMemo } from 'react'

import { ControlledSelector } from 'components/common'
import { useGetBookingUsersQuery } from 'generated/graphql'

interface IProps {
  control: any
}

const BookingUsersSelector = (props: IProps) => {
  const { control } = props

  const { data: bookingUsersData } = useGetBookingUsersQuery()

  const bookingUserOptions = useMemo(
    () =>
      bookingUsersData?.bookingUsers.map((user) => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName}`,
      })) || [],
    [bookingUsersData],
  )

  return (
    <ControlledSelector
      control={control}
      name='takenBy'
      label='Booked By'
      options={bookingUserOptions}
      emptyValue={{ value: '', label: 'All Users' }}
      displayEmpty
    />
  )
}

export default BookingUsersSelector
