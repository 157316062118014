import { useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import { FplDialogTitle } from 'components/common'

import TaskTabs from './TaskTabs'

interface IProps {
  openDialog: boolean
  onClose: () => void
}

const ExchangeRateTasks = (props: IProps) => {
  const { openDialog, onClose } = props
  const [isFullScreen, setIsFullScreen] = useState(false)

  // handlers
  const handleFullScreenClick = () => setIsFullScreen(!isFullScreen)

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      fullScreen={isFullScreen}
      open={openDialog}
      onClose={onClose}
      aria-labelledby='form-rate-tasks-dialog-title'>
      <FplDialogTitle
        id='form-rate-tasks-dialog-title'
        onClose={onClose}
        isFullScreen={isFullScreen}
        onFullScreen={handleFullScreenClick}>
        <Typography paragraph variant='h4'>
          Exchange Rate Tasks
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <TaskTabs />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExchangeRateTasks
