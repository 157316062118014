import { gql } from '@apollo/client'

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($id: Long!) {
    readNotification(id: $id) {
      id
      isRead
    }
  }
`

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: Long!) {
    deleteNotification(id: $id)
  }
`

export const CREATE_OR_UPDATE_INSTALLATION = gql`
  mutation CreateOrUpdateInstallation($input: SetPushNotificationTokenDtoInput!) {
    createOrUpdateInstallation(input: $input)
  }
`

export const DELETE_INSTALLATION = gql`
  mutation DeleteInstallationById($installationId: Long!) {
    deleteInstallationById(installationId: $installationId)
  }
`
