import React from 'react'

import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { GridEventListener, GridPaginationModel, GridSortModel } from '@mui/x-data-grid'

import { IGridColumn, IPageState, ISortModel } from 'components/common/FplDataGrid/FplDataGrid'
import { getDefaultSortModel, getPaging, getSortModel } from 'components/common/FplDataGrid/helpers'
import { setDataSort, setPageNumber, setPageSize, setPagination } from 'helpers/localStorage'

interface IProps {
  onRowClick?: GridEventListener<'rowClick'>
  data: any
  entityName: string
  pageState: IPageState
  fetchMore: any
  sortModel: Array<ISortModel>
  filter: any
  queryVariables?: Record<string, any>
  setPageState: React.Dispatch<React.SetStateAction<IPageState>>
  id: string
  setSortModel: React.Dispatch<React.SetStateAction<ISortModel[]>>
  columns: IGridColumn[]
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
  defaultOrder: any
}

function useFplDataGridHandlers(props: IProps) {
  const {
    onRowClick,
    data,
    entityName,
    pageState,
    fetchMore,
    sortModel,
    filter,
    queryVariables,
    setPageState,
    id,
    setSortModel,
    columns,
    refetch,
    defaultOrder,
  } = props

  const handleRowClick: GridEventListener<'rowClick'> = (params, event, details) => {
    onRowClick?.(params, event, details)
  }

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length === 0) {
      setSortModel([])
      setDataSort([], id)
    } else {
      const { field, sort } = newModel[0]
      const newSortModel = getSortModel(field, sort, columns)

      if (newSortModel) {
        setDataSort(newSortModel, id)
        setSortModel([newSortModel])
      }
    }
  }

  const handleRefreshClick = () => {
    setPageState((prevState) => ({
      ...prevState,
      page: 0,
    }))

    refetch({
      ...getPaging({ first: pageState.pageSize }),
      order: sortModel[0]?.orderInput,
      where: filter,
      ...queryVariables,
    })
  }

  const handlePageChange = (model: GridPaginationModel) => {
    const totalCount = data?.[entityName]?.totalCount

    if (pageState.pageSize * pageState.page > totalCount) {
      return
    }
    const newPageSize = model.pageSize !== pageState.pageSize

    const paging = newPageSize
      ? getPaging({ before: null, after: null, first: model.pageSize })
      : pageState.page > model.page
      ? getPaging({ before: data?.[entityName]?.pageInfo.startCursor, last: model.pageSize })
      : getPaging({ after: data?.[entityName]?.pageInfo.endCursor, first: model.pageSize })

    fetchMore({
      variables: {
        ...paging,
        order:
          sortModel[0]?.orderInput ?? getDefaultSortModel(defaultOrder, columns)[0]?.orderInput,
        where: filter,
        ...queryVariables,
      },
    })
    setPageState(model)
    setPagination(paging, id)
    setPageNumber(newPageSize ? 0 : model.page, id)
    setPageSize(model.pageSize, id)
  }

  return { handleRowClick, handleSortModelChange, handleRefreshClick, handlePageChange }
}

export default useFplDataGridHandlers
