import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { Mode } from 'constants/index'
import {
  CreateAndUpdateXeroCodeDtoInput,
  useCreateXeroCodeMutation,
  useDeleteXeroCodeMutation,
  useUpdateXeroCodeMutation,
  XeroCodeType,
  XeroServiceType,
} from 'generated/graphql'
import { GET_XERO_CODES } from 'graphql/queries'
import { isNumber } from 'helpers'
import { Restricted } from 'providers'

import ServiceCodeForm from './ServiceCodeForm'
import UpdateXeroCode from './UpdateXeroCode'
import { ServiceCodeValidationSchema } from './ValidationSchema'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}

const ServiceCodeDialog = (props: IProps) => {
  const { id, openDialog, onCloseDialog } = props

  const methods = useForm<CreateAndUpdateXeroCodeDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(ServiceCodeValidationSchema),
    defaultValues: {
      name: '',
      code: '',
      description: '',
      type: XeroCodeType.Revenue,
      serviceType: XeroServiceType.DomesticDedicated,
      isInternational: false,
    },
  })

  const { handleSubmit } = methods

  // mutations
  const [createXeroCode, { loading: creatingXeroCode }] = useCreateXeroCodeMutation({
    refetchQueries: [{ query: GET_XERO_CODES }],
    onCompleted: (data) => {
      if (data?.createXeroCode) {
        toast.success(`Service Code (${data.createXeroCode?.name}) was created with success.`)
        onCloseDialog()
      }
    },
  })

  const [updateXeroCode, { loading: updatingXeroCode }] = useUpdateXeroCodeMutation({
    onCompleted: (data) => {
      if (data?.updateXeroCode) {
        toast.success(`Service Code (${data.updateXeroCode?.name}) was updated with success.`)
        onCloseDialog()
      }
    },
  })

  const [deleteXeroCode, { loading: deleting }] = useDeleteXeroCodeMutation({
    onCompleted: (data) => {
      if (data?.deleteXeroCode) {
        toast.success('Xero code successfully deleted')
        onCloseDialog()
      }
    },
  })

  let mode: number | undefined

  if (id === null) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const handleSubmitXeroCode: SubmitHandler<CreateAndUpdateXeroCodeDtoInput> = (formData) => {
    if (mode === Mode.Create) {
      createXeroCode({
        variables: {
          input: formData,
        },
      })
    } else {
      updateXeroCode({
        variables: {
          input: {
            id,
            ...formData,
          },
        },
      })
    }
  }

  const handleDeleteCode = () => {
    deleteXeroCode({
      variables: {
        id: Number(id),
      },
    })
  }

  const handleClose = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      scroll='body'
      open={openDialog}
      aria-labelledby='xero-code-dialog'>
      <FplDialogTitle id='xero-code-dialog' onClose={handleClose}>
        <Typography paragraph variant='h4'>
          {mode === Mode.Create ? 'Create' : 'Update'} Service Code
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? <ServiceCodeForm /> : <UpdateXeroCode id={id!} />}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Cancel
        </Button>

        {mode === Mode.Update && (
          <Restricted to='DeleteXeroCode'>
            <LoadingButton
              variant='contained'
              color='error'
              onClick={handleDeleteCode}
              loading={deleting}>
              Delete
            </LoadingButton>
          </Restricted>
        )}

        <LoadingButton
          variant='contained'
          loading={creatingXeroCode || updatingXeroCode}
          onClick={handleSubmit(handleSubmitXeroCode)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ServiceCodeDialog
