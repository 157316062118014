import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { Box, Grid, Paper } from '@mui/material'

import { UserNotifications } from 'components/common'

const PersonalNotifications = () => {
  const { idTokenPayload } = useOidcIdToken()

  return (
    <Paper elevation={3}>
      <Box padding={2}>
        <Grid container spacing={3} xs={12}>
          <Grid item xs={12}>
            <UserNotifications username={idTokenPayload.name} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default PersonalNotifications
