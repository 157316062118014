import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { CustomerAccountType } from 'generated/graphql'

interface IProps {
  control: Control<any, any>
  required?: boolean
}
const AccountType = (props: IProps) => {
  const { control, required } = props

  return (
    <Controller
      control={control}
      name='customerAccountType'
      render={({ field }) => (
        <FormControl component='fieldset' required={required}>
          <FormLabel component='label'>Account Type</FormLabel>
          <RadioGroup {...field} row aria-label='type'>
            <FormControlLabel value={CustomerAccountType.Cash} control={<Radio />} label='Cash' />
            <FormControlLabel
              value={CustomerAccountType.Credit}
              control={<Radio />}
              label='Credit'
            />
            <FormControlLabel value={CustomerAccountType.Card} control={<Radio />} label='Card' />
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}

export default AccountType
