import { Height, Room } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { IOrderableAddressListItem } from './MapWithReorder'

const getItemStyle = (isDragging, draggableStyle) => ({
  // Styles we need to apply on draggable
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
})

interface IProps {
  items: IOrderableAddressListItem[]
  disabled?: boolean
  readOnly?: boolean
  onDragEnd: any
}

const DraggableAddresses = (props: IProps) => {
  const { items, disabled = false, readOnly = false, onDragEnd } = props

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            <List style={getListStyle(snapshot.isDraggingOver)}>
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={disabled || readOnly}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      <ListItem
                        // @ts-ignore
                        ContainerComponent='li'
                        // ContainerProps={{ ref: provided.innerRef }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        disabled={disabled}>
                        <ListItemIcon>
                          <Room />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${item.name} (${item.letter})`}
                          secondary={item.description}
                        />
                        {!readOnly && (
                          <ListItemIcon style={{ minWidth: '10px' }}>
                            <Height />
                          </ListItemIcon>
                        )}
                      </ListItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableAddresses
