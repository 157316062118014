import React from 'react'

import { useOidc, useOidcUser } from '@axa-fr/react-oidc-context'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Paths } from 'constants/index'

import { HelperMenuItem } from '../navigation'

const label = 'Profile'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ButtonProfile = ({ isMenu = false, onClosed }: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const history = useHistory()
  const { login, logout } = useOidc()
  const { oidcUser } = useOidcUser()
  const isOpen = Boolean(anchorEl)

  // ToDo: use isAuthenticated from useOidc() hook
  const isAuthenticated = React.useMemo(() => !!oidcUser, [oidcUser])

  const handleOnClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
    if (onClosed) {
      onClosed()
    }
  }

  const handleGoToProfile = () => {
    history.push(Paths.profile)
  }

  const handleLogin = () => login()
  const handleLogOut = async () => {
    logout(`${process.env.REACT_APP_PUBLIC_URL}`)
  }

  const menuId = 'menu-profile'

  const content = (
    <div>
      {/* @ts-ignore // ToDo: Fix React v17 */}
      <IconButton
        color='inherit'
        onClick={isMenu ? null : handleOnClick}
        aria-label={label}
        aria-controls={menuId}
        aria-haspopup='true'>
        <AccountCircle />
      </IconButton>

      <Menu anchorEl={anchorEl} id={menuId} open={isOpen} onClose={handleClose} keepMounted>
        {isAuthenticated && <MenuItem onClick={handleGoToProfile}>Profile</MenuItem>}
        {isAuthenticated && <MenuItem onClick={handleLogOut}>Log out</MenuItem>}
        {!isAuthenticated && <MenuItem onClick={handleLogin}>Log in</MenuItem>}
      </Menu>
    </div>
  )

  const attrs = {
    content,
    handleOnClick,
    label,
    isMenu,
  }

  return <HelperMenuItem {...attrs} />
}

export default ButtonProfile
