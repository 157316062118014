import React from 'react'

import { addSeconds } from 'date-fns'

import {
  CreateAndUpdateCongestionZoneDtoInput,
  CreateAndUpdateCongestionZonePostcodeDtoInput,
  useGetCongestionZoneQuery,
  Weekday,
} from 'generated/graphql'

import CongestionZoneForm from './CongestionZoneForm'
import { IPostcodeActions } from './Postcodes'

interface IProps {
  chargeZoneId: string
  processing: boolean
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>
  setCongestionZone: React.Dispatch<React.SetStateAction<CreateAndUpdateCongestionZoneDtoInput>>
  selectedWeekdays: Array<Weekday>
  setSelectedWeekdays: React.Dispatch<React.SetStateAction<Array<Weekday>>>
  postcodes: Array<CreateAndUpdateCongestionZonePostcodeDtoInput>
  setPostcodes: React.Dispatch<
    React.SetStateAction<Array<CreateAndUpdateCongestionZonePostcodeDtoInput>>
  >
  postcodeActions: IPostcodeActions
}

export default function UpdateCongestionZone(props: IProps) {
  const {
    processing,
    chargeZoneId,
    setNotFound,
    setCongestionZone,
    selectedWeekdays,
    setSelectedWeekdays,
    postcodes,
    setPostcodes,
    postcodeActions,
  } = props

  const { loading: loadingCongestion } = useGetCongestionZoneQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: Number(chargeZoneId),
    },
    onError: () => {
      setNotFound(true)
    },
    onCompleted: (data) => {
      if (!data.congestionZone) {
        return
      }
      const {
        __typename,
        tariffs,
        id,
        weekdaysCollection,
        durationFrom,
        durationTo,
        durationFromTotalSeconds,
        postcodes,
        durationToTotalSeconds,
        ...congestionZone
      } = data.congestionZone
      const tariffIds: any = tariffs
        ? tariffs.map((t) => ({ value: t.tariff.id, label: t.tariff.name }))
        : []

      const initialDate = new Date(0)
      initialDate.setHours(0)
      initialDate.setMinutes(0)
      initialDate.setSeconds(0)

      setCongestionZone({
        tariffIds,
        id,
        weekdays: weekdaysCollection,
        durationFrom: addSeconds(initialDate, durationFromTotalSeconds).toString(),
        durationTo: addSeconds(initialDate, durationToTotalSeconds).toString(),
        durationFromHours: 0,
        durationFromMin: 0,
        durationToHours: 0,
        durationToMin: 0,
        ...congestionZone,
      })
      setSelectedWeekdays(weekdaysCollection)

      const codes = (postcodes ?? []).map(
        (x) =>
          ({
            id: x.id,
            name: x.name,
            congestionZoneId: x.congestionZoneId,
          } as CreateAndUpdateCongestionZonePostcodeDtoInput),
      )

      setPostcodes(codes)
    },
  })

  return (
    <CongestionZoneForm
      processing={processing || loadingCongestion}
      setSelectedWeekdays={setSelectedWeekdays}
      selectedWeekdays={selectedWeekdays}
      postcodes={postcodes}
      postcodeActions={postcodeActions}
    />
  )
}
