import { toast } from 'react-toastify'

import { GetPotentialBookingQuery, PotentialBookingAlgorithmType } from 'generated/graphql'
import { TBookingForm } from 'types/form-types'

import { TAdditionalData, TExtractedData, TradechoiceGeneral } from '.'
import { DeltaGroupApple } from './DeltaGroupApple'

function getBookingData(potentialBookingData: GetPotentialBookingQuery) {
  const potentialBooking = potentialBookingData?.potentialBooking

  // Parse JSON
  const extractedData = JSON.parse(
    potentialBooking?.potentialBookingFile?.extractedData ?? '',
  ) as TExtractedData

  // Get key and value
  const key = potentialBooking?.bookingIdentifierKey
  const value = potentialBooking?.bookingIdentifierValue

  // Filter data to find only necessary Booking
  return extractedData.filter((x) => x[key as string] === value)
}

function processPotentialBooking(
  potentialBookingData: GetPotentialBookingQuery,
  newBooking: TBookingForm,
  bookingData: { [key: string]: string }[],
  additionalData: TAdditionalData,
): TBookingForm {
  // Get type
  const type = potentialBookingData?.potentialBooking?.potentialBookingMapping?.type
  let processedBooking: TBookingForm

  // Process based on type
  switch (type) {
    case PotentialBookingAlgorithmType.TradechoiceGeneral:
      processedBooking = TradechoiceGeneral(bookingData, newBooking, additionalData)
      break

    case PotentialBookingAlgorithmType.DeltaGroupApple:
      processedBooking = DeltaGroupApple(bookingData, newBooking, additionalData)
      break

    default:
      toast.error("Can't proceed with Booking creation: unknown algorithm type")
      return newBooking
  }

  return processedBooking
}

// TODO: Rename function
export const GetBookingBasedOnPotentialBooking = (
  customerId: number,
  potentialBookingData: GetPotentialBookingQuery,
  additionalData: TAdditionalData,
): TBookingForm => {
  const bookingData = getBookingData(potentialBookingData)

  const newBooking = {
    customerId: customerId,
    potentialBookingId: Number(potentialBookingData?.potentialBooking?.id),
  } as unknown as TBookingForm

  return processPotentialBooking(potentialBookingData, newBooking, bookingData, additionalData)
}
