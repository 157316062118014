import { gql } from '@apollo/client'

const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods {
    paymentMethods {
      id
      name
    }
  }
`

export { GET_PAYMENT_METHODS }
