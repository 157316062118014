import { ISelectorOptions } from 'components/common'
import { BookingChargeType } from 'generated/graphql'

export const OtherChargeEntity = {
  MAX_DESCRIPTION_LENGTH: 200,
  MAX_NOMINAL_CODE_LENGTH: 8,
}

// ToDo: remove Custom charge type from BA
export const BookingChargeTypeValues: ISelectorOptions = [
  { value: BookingChargeType.SpecialZone, label: 'Special Zone' },
  { value: BookingChargeType.Other, label: 'Other' },
]
