import { gql } from '@apollo/client'

import {
  BookingDetailsFragment,
  BookingAddressesFragment,
  BookingChargesFragment,
  BookingPackagesFragment,
  BookingDriversFragment,
  BookingTariffFragment,
  UserBookingDetailsFragment,
} from 'graphql/fragments'

export const CREATE_BOOKING_COMBINED_MUTATION = gql`
  ${BookingDetailsFragment}
  ${BookingAddressesFragment}
  ${BookingChargesFragment}
  ${BookingPackagesFragment}
  mutation CreateBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
    createBookingCombined(input: $input) {
      id
      ...BookingDetails
      ...BookingAddresses
      ...BookingCharges
      ...BookingPackages
    }
  }
`

export const UPDATE_BOOKING_COMBINED_MUTATION = gql`
  ${BookingDetailsFragment}
  ${BookingAddressesFragment}
  ${BookingDriversFragment}
  ${BookingChargesFragment}
  ${BookingPackagesFragment}
  ${BookingTariffFragment}
  mutation UpdateBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
    updateBookingCombined(input: $input) {
      id
      ...BookingDetails
      ...BookingAddresses
      ...BookingDrivers
      ...BookingCharges
      ...BookingPackages
      ...BookingTariff
    }
  }
`

export const CREATE_USER_BOOKING_COMBINED_MUTATION = gql`
  ${UserBookingDetailsFragment}
  ${BookingAddressesFragment}
  ${BookingChargesFragment}
  ${BookingPackagesFragment}
  mutation CreateUserBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
    createBookingCombined(input: $input) {
      id
      ...UserBookingDetails
      ...BookingAddresses
      ...BookingCharges
      ...BookingPackages
    }
  }
`

export const UPDATE_USER_BOOKING_COMBINED_MUTATION = gql`
  ${UserBookingDetailsFragment}
  ${BookingAddressesFragment}
  ${BookingDriversFragment}
  ${BookingChargesFragment}
  ${BookingPackagesFragment}
  ${BookingTariffFragment}
  mutation UpdateUserBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
    updateBookingCombined(input: $input) {
      id
      ...UserBookingDetails
      ...BookingAddresses
      ...BookingDrivers
      ...BookingCharges
      ...BookingPackages
      ...BookingTariff
    }
  }
`

export const DELETE_BOOKING_ADDRESS = gql`
  mutation DeleteBookingAddress($bookingAddressId: Long!) {
    deleteBookingAddress(bookingAddressId: $bookingAddressId) {
      id
    }
  }
`

export const DELETE_BOOKING_CHARGE = gql`
  mutation DeleteBookingCharge($bookingChargeId: Long!) {
    deleteBookingCharge(bookingChargeId: $bookingChargeId) {
      id
    }
  }
`

export const DELETE_BOOKING_PACKAGE = gql`
  mutation DeleteBookingPackage($packageId: Long!) {
    deleteBookingPackage(packageId: $packageId) {
      id
    }
  }
`
