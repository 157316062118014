import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FplDialogTitle } from 'components/common'
import {
  CreateAndUpdateBookingDriverDtoInput,
  useGetBookingAllocatedDriverQuery,
  useUpdateBookingDriverMutation,
} from 'generated/graphql'
import {
  GET_PAGED_BOOKING_ALLOCATED_DRIVERS,
  GET_BOOKING_TOTAL_DRIVER_CHARGE,
} from 'graphql/queries'
import { flushSync } from 'react-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { EditDriverForm } from './EditDriverForm'

interface IProps {
  bookingDriverId: number
  openDialog: boolean
  handleCloseDialog: () => any
}

const EditDriverDialog = (props: IProps) => {
  const { bookingDriverId, openDialog, handleCloseDialog } = props

  const methods = useForm<CreateAndUpdateBookingDriverDtoInput>({
    shouldUnregister: true,
  })

  const { handleSubmit, reset } = methods

  // Queries
  const { data: bookingDriverData, loading: bookingDriverLoading } =
    useGetBookingAllocatedDriverQuery({
      variables: { bookingDriverId },
      onCompleted: (response) => {
        flushSync(() => {
          if (response) reset({ ...response.bookingAllocatedDriver })
        })
      },
    })
  const driverId = bookingDriverData?.bookingAllocatedDriver?.driverId

  // Mutations
  const [updateDriver, { loading: isUpdatingDriver }] = useUpdateBookingDriverMutation({
    refetchQueries: [
      {
        query: GET_PAGED_BOOKING_ALLOCATED_DRIVERS,
        variables: { bookingId: Number(bookingDriverData?.bookingAllocatedDriver?.bookingId) },
      },
      {
        query: GET_BOOKING_TOTAL_DRIVER_CHARGE,
        variables: { bookingId: Number(bookingDriverData?.bookingAllocatedDriver?.bookingId) },
      },
    ],
    onCompleted: (data) => {
      handleCloseDialog()

      toast.success(`Driver (${data.updateBookingDriver?.driver.name}) was updated with success.`)
    },
  })

  const handleOnSuccess: SubmitHandler<CreateAndUpdateBookingDriverDtoInput> = (formData) => {
    const { charge, extraPayment, xeroPurchaseCode, currencyId, isShownInstruction, instruction } =
      formData
    const driver = bookingDriverData?.bookingAllocatedDriver

    if (driver) {
      updateDriver({
        variables: {
          input: {
            id: driver.id,
            bookingId: driver.bookingId,
            driverId: driver.driverId,
            vehicleId: driver.vehicleId,
            charge: Number(charge),
            extraPayment: Number(extraPayment),
            xeroPurchaseCode,
            currencyId,
            distance: 0, // ToDo: add distance
            isShownInstruction,
            instruction,
          },
        },
      })
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openDialog}
      aria-labelledby='edit-allocated-driver-dialog-title'>
      <FplDialogTitle id='edit-allocated-driver-dialog-title' onClose={handleCloseDialog}>
        <Typography mb={0} paragraph variant='h4'>
          Edit Allocated Driver
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <form id='edit-allocated-driver-dialog-form' noValidate>
            <EditDriverForm loading={bookingDriverLoading} driverId={driverId!} />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          form='edit-allocated-driver-dialog-form'
          loading={isUpdatingDriver}
          onClick={handleSubmit(handleOnSuccess)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditDriverDialog
