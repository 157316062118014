import { gql } from '@apollo/client'

import {
  CustomerDetailsFragment,
  CustomerNotificationsFragment,
  CustomerReferenceAndNotesFragment,
  FileDetailsFragment,
} from 'graphql/fragments'

export const GET_PAGED_CUSTOMERS = gql`
  query GetPagedCustomers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [CustomerSortInput!]
    $where: CustomerFilterInput
  ) {
    pagedCustomers(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          accountCode
          name
          isOnHold
          addressLine1
          addressLine2
          city
          postcode
          telephoneNumber
          invoiceEmail
          contacts {
            id
            name
          }
          acquiredBy
          customerAccountType
          isEnabled
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_CUSTOMERS_QUERY = gql`
  query GetCustomers($order: [CustomerSortInput!]) {
    customers(order: $order) {
      id
      name
      isReferenceRequired
      isReasonCodeOptionEnabled
      isPackageDetailsOptional
      contacts {
        id
        name
        email
        telephoneNumber
        isDefault
        isEnabled
      }
      isEnabled
    }
  }
`

export const GET_CUSTOMER_QUERY = gql`
  query GetCustomer($customerId: Long!) {
    customer(id: $customerId) {
      id
      customer {
        ...CustomerDetails
      }
      
      logoId
      logo{
        id
        ...FileDetails
      }
    }
    ${CustomerDetailsFragment}
    ${FileDetailsFragment}
  }
`

export const GET_CUSTOMER_CONTACT = gql`
  query GetCustomerContact($id: Long!) {
    customerContact(id: $id) {
      id
      isDefault
      name
      telephoneNumber
      email
      additionalEmails
      customerId
      isEnabled
      isActive
    }
  }
`

export const GET_CUSTOMER_CONTACTS = gql`
  query GetCustomerContacts(
    $customerId: Long!
    $order: [ContactSortInput!]
    $where: ContactFilterInput
  ) {
    customerContacts(customerId: $customerId, order: $order, where: $where) {
      id
      isDefault
      name
      email
      telephoneNumber
      isActive
    }
  }
`

export const GET_PAGED_CUSTOMER_CONTACTS = gql`
  query GetPagedCustomerContacts(
    $customerId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ContactSortInput!]
    $where: ContactFilterInput
  ) {
    pagedCustomerContacts(
      customerId: $customerId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          telephoneNumber
          email
          additionalEmails
          isDefault
          isActive
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_CUSTOMER_NOTIFICATIONS = gql`
  query GetCustomerNotifications($customerId: Long!) {
    customer(id: $customerId) {
      id
      customer {
        id
        ...CustomerNotifications
      }
    }
    ${CustomerNotificationsFragment}
  }
`

export const GET_CUSTOMER_REFERENCES_AND_NOTES = gql`
  query GetCustomerReferencesAndNotes($customerId: Long!) {
    customer(id: $customerId) {
      id
      customer {
        id
        ...CustomerReferenceAndNotes
      }
      ${CustomerReferenceAndNotesFragment}
    }
  }
`

export const GET_SALES_NAMES = gql`
  query GetSalesNames {
    salesNames
  }
`

export const GET_CUSTOMER_PROFILE = gql`
  query GetCustomerProfile {
    customerProfile {
      id
      customerId
      contactId
      customerTradingName
      isReferenceRequired
      isReasonCodeOptionEnabled
      isBookingImportOptionEnabled
      isPackageDetailsOptional
      primaryCustomerId
    }
  }
`

export const GET_SUB_CUSTOMERS = gql`
  query GetSubCustomers(
    $primaryCustomerId: Long!
    $isPrimaryCustomerIncluded: Boolean
    $order: [CustomerSortInput!]
  ) {
    subCustomers(
      primaryCustomerId: $primaryCustomerId
      isPrimaryCustomerIncluded: $isPrimaryCustomerIncluded
      order: $order
    ) {
      id
      accountCode
      name
      isOnHold
      addressLine1
      addressLine2
      city
      postcode
      telephoneNumber
      invoiceEmail
      contacts {
        id
        name
        email
        telephoneNumber
        isDefault
        isEnabled
      }
      customerAccountType
      isEnabled
      isReferenceRequired
      isReasonCodeOptionEnabled
      isPackageDetailsOptional
    }
  }
`

export const GET_PAGED_SUB_CUSTOMERS = gql`
  query GetPagedSubCustomers(
    $primaryCustomerId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [CustomerSortInput!]
    $where: CustomerFilterInput
  ) {
    pagedSubCustomers(
      primaryCustomerId: $primaryCustomerId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          accountCode
          name
          isOnHold
          addressLine1
          addressLine2
          city
          postcode
          telephoneNumber
          invoiceEmail
          contacts {
            id
            name
          }
          customerAccountType
          isEnabled
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_CUSTOMER_INFO_FOR_SUB_CUSTOMER = gql`
  query GetCustomerInfoForSubCustomer($customerId: Long!) {
    customer(id: $customerId) {
      id
      customer {
        id
        invoiceEmail
        paymentMethodId
        customerAccountType
        invoiceTermId
        invoiceFrequency
        invoiceCompanyName
        isInvoiceGrouped
        currencyId
        isReferenceRequired
        isUlezCharge
      }
    }
  }
`

export const GET_CUSTOMERS_INSTRUCTIONS_FOR_DRIVER = gql`
  query GetCustomersInstructionsForDriver($customerId: Long!) {
    customersInstructionsForDriver(customerId: $customerId)
  }
`

export const GET_CUSTOMER_OPTIONS = gql`
  query GetCustomerOptions($customerId: Long!) {
    customerOptions(customerId: $customerId)
  }
`

export const GET_CURRENT_CUSTOMER_OPTIONS = gql`
  query GetCurrentCustomerOptions {
    currentCustomerOptions
  }
`
