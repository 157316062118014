import { Box, Stack, Container } from '@mui/material'
import { Redirect, Route, Switch } from 'react-router-dom'

import { TrustpilotBox } from 'components/common'
import TrackingPage from 'components/tracking/TrackingPage'
import { Paths } from 'constants/index'

import { AuthFooter, AuthHeader } from './common'
import { SingUpPage } from './SignUpPage'
import { WelcomePage } from './WelcomePage'

const Auth = () => {
  return (
    <Stack>
      <AuthHeader />

      <Switch>

        <Route path={Paths.trackingPage} component={TrackingPage} />
        <Route path={Paths.auth.welcome} component={WelcomePage} />

        <Route path={Paths.auth.signUp} component={SingUpPage} />

        <Route path='/' exact>
          <Redirect to={Paths.auth.welcome} />
        </Route>

        <Route path='' component={WelcomePage} />
      </Switch>

      <Container maxWidth='lg'>
        <Box marginY={6} marginX={3}>
          <TrustpilotBox />
        </Box>
      </Container>

      <AuthFooter />
    </Stack>
  )
}

export default Auth
