import { useMemo, useRef, useState } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { compareAsc } from 'date-fns'

import { NavIconButton, NavPopper, TaskRow } from 'components/common'
import { getPaging } from 'components/common/FplDataGrid/helpers'
import { SortEnumType, TaskStatus, useGetTasksQuery } from 'generated/graphql'
import { useDialog, usePermission, TaskDialogOptions, SpecialTariffDialogOptions } from 'providers'

const ButtonTasks = () => {
  const theme = useTheme()
  const { dialogOpen } = useDialog<TaskDialogOptions | SpecialTariffDialogOptions>()
  const { idTokenPayload } = useOidcIdToken()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [isAllowedToUpdateTask] = usePermission('UpdateTask')
  const [isAllowedToDeleteTask] = usePermission('DeleteTask')

  // operations

  const { data, loading } = useGetTasksQuery({
    variables: {
      ...getPaging({ first: 10 }),
      where: {
        status: { eq: TaskStatus.New },
        assignedUserName: { eq: idTokenPayload.name },
      },
      order: { endDate: SortEnumType.Asc },
    },
  })

  const tasksSorted = useMemo(
    () =>
      data?.tasks?.edges
        ?.slice()
        .sort((a, b) => compareAsc(new Date(a.node.endDate), new Date(b.node.endDate))),
    [data],
  )

  // handlers
  const handleToggle = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setIsOpen(false)
  }

  const handleItemPress = (id: any, bookingId?: string | null) => {
    dialogOpen({
      id: Number(id),
      bookingId,
    })
    setIsOpen(false)
  }

  const renderPopperSecondary = () => (
    <IconButton size='small' onClick={handleClose}>
      <CloseOutlinedIcon />
    </IconButton>
  )

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <NavIconButton
        ref={anchorRef}
        badgeCount={tasksSorted?.length || 0}
        loading={loading}
        isOpen={isOpen}
        onClick={handleToggle}>
        <AssignmentTurnedInIcon />
      </NavIconButton>

      <NavPopper
        isOpen={isOpen}
        anchorEl={anchorRef.current}
        title={'Tasks'}
        matchesXs={matchesXs}
        onClickAway={handleClose}
        secondary={renderPopperSecondary()}>
        {tasksSorted?.map((t) => (
          <TaskRow
            key={t.node.id}
            task={t.node}
            bookingId={t.node.bookingId}
            loggedUserName={idTokenPayload.name}
            isAllowedToUpdateTask={isAllowedToUpdateTask}
            isAllowedToDeleteTask={isAllowedToDeleteTask}
            onTaskClick={handleItemPress}
          />
        ))}
      </NavPopper>
    </Box>
  )
}

export default ButtonTasks
