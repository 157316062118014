import React from 'react'

import HelpIcon from '@mui/icons-material/Help'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'

import { CardTitle } from 'components/common'
import { ImportDataForm, ImportDataTypes } from 'components/settings/ImportExportData/common'

import PotentialBookingHelpContent from './PotentialBookingHelpContent'

const PotentialBookingUpload = () => {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CardTitle>
        Import Potential Bookings{' '}
        <IconButton size='small' onClick={handleOpen}>
          <HelpIcon />
        </IconButton>
      </CardTitle>

      <ImportDataForm type={ImportDataTypes.PotentialBooking} />

      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'>
        <DialogTitle id='scroll-dialog-title'>Booking Import Help</DialogTitle>
        <DialogContent>
          <PotentialBookingHelpContent />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PotentialBookingUpload
