import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common/Dialog'
import {
  CreateAndUpdateSubDriverDtoInput,
  DriverType,
  useCreateSubDriverMutation,
  useUpdateSubDriverMutation,
} from 'generated/graphql'

import SubDriverForm from './SubDriverForm/SubDriverForm'
import SubDriverFormHeader from './SubDriverFormHeader'
import UpdateSubDriver from './UpdateSubDriver'
import validationSchema from './ValidationSchema'

const schema = validationSchema()

interface IProps {
  subDriverId: number | null
  driverId: number
  openDriverDialog: boolean
  onCloseDialog: () => void
}

const CreateUpdateSubDrivers = (props: IProps) => {
  const { subDriverId, driverId, openDriverDialog, onCloseDialog } = props

  const methods = useForm<CreateAndUpdateSubDriverDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(schema),
    defaultValues: {
      // Sub-driver Details
      name: '',
      callSign: undefined,
      email: '',
      telephoneNumber: '',
      driverType: DriverType.Contract,
      xeroCodeId: '',
      xeroPurchaseCode: '',
      isActive: true,
      // Payment Details
      vatCategoryId: '',
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  // Mutations
  const [createSubDriver, { loading: creatingSubDriver }] = useCreateSubDriverMutation({
    onCompleted: (data) => {
      toast.success(`Sub-driver (${data?.createSubDriver?.id}) was created with success`)
      onCloseDialog()
    },
  })
  const [updateSubDriver, { loading: updatingSubDriver }] = useUpdateSubDriverMutation({
    onCompleted: (data) => {
      toast.success(`Sub-driver (${data?.updateSubDriver?.id}) was updated with success`)
      onCloseDialog()
    },
  })

  const handleSuccess: SubmitHandler<CreateAndUpdateSubDriverDtoInput> = (formData) => {
    const { xeroCodeId, ...restData } = formData

    const normalizedVars = {
      xeroCodeId: xeroCodeId ? Number(xeroCodeId) : null,
    }

    if (!subDriverId) {
      createSubDriver({
        variables: {
          input: {
            ...restData,
            ...normalizedVars,
            id: null,
            primaryDriverId: String(driverId),
          },
        },
      })
    } else {
      updateSubDriver({
        variables: {
          input: {
            ...restData,
            ...normalizedVars,
            id: String(subDriverId),
            primaryDriverId: String(driverId),
          },
        },
      })
    }
  }

  const handleCancel = () => {
    // Check if is dirty
    onCloseDialog()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openDriverDialog}
      aria-labelledby='form-sub-driver-dialog'>
      <FplDialogTitle id='form-sub-driver-dialog-title' onClose={handleCancel}>
        <SubDriverFormHeader subDriverId={subDriverId} />
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <form noValidate>
            {!subDriverId ? <SubDriverForm /> : <UpdateSubDriver subDriverId={subDriverId} />}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCancel}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          disabled={!isDirty}
          loading={creatingSubDriver || updatingSubDriver}
          onClick={handleSubmit(handleSuccess)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateSubDrivers
