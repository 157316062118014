import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

import { AccountPayableStatusCategories, Paths } from 'constants/index'
import { accountPayableStatusCategoryVar } from 'graphql/reactiveVariables'
import { numberFormat } from 'helpers'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'left',
  color: 'black',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

interface IProps {
  amountInEuro: number
  amountInPounds: number
  amountInDollars: number
  countOfInvoices: number
  loading: boolean
}

const OverviewHeader = ({
  amountInPounds,
  amountInDollars,
  amountInEuro,
  countOfInvoices,
  loading,
}: IProps) => {
  const history = useHistory()
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Item sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              {!loading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', md: 'row' },
                  }}>
                  <Typography variant='h5' style={{ padding: '5px', display: 'flex' }}>
                    <Box sx={{ marginRight: '10px' }}>
                      £{numberFormat(amountInPounds, 0, 2)} + ${numberFormat(amountInDollars, 0, 2)}
                      + €{numberFormat(amountInEuro, 0, 2)}
                    </Box>
                    <Divider orientation='vertical' flexItem />
                  </Typography>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: 'pointer',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                    onClick={() => {
                      accountPayableStatusCategoryVar(
                        AccountPayableStatusCategories.ScheduledPayment,
                      )
                      history.push(
                        Paths.accountsPayable.all + '/#accountsPayable-scheduled-payment',
                      )
                    }}>
                    {countOfInvoices} invoices to be paid
                  </Typography>
                </Box>
              ) : (
                'Loading...'
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default OverviewHeader
