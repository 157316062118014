import { Typography } from '@mui/material'

interface IProps {
  notFound: boolean
  fileId: number | null
}

const BookingAttachmentFormHeader = (props: IProps) => {
  const { notFound, fileId } = props
  let title: string

  if (notFound) {
    title = 'Booking Attachment Not Found'
  } else if (!fileId) {
    title = 'Upload Booking Attachment'
  } else {
    title = 'Update Booking Attachment'
  }

  return <Typography variant='h4'>{title}</Typography>
}

export default BookingAttachmentFormHeader
