import { Box, Container } from "@mui/material"

type ContainerProps = {
  children: React.ReactNode
}

const SignUpContainer = (props: ContainerProps) => {
  const { children } = props

  return <Box sx={{ backgroundColor: '' }}>
    <Container maxWidth='lg'>
      {children}
    </Container>
  </Box>
}

export default SignUpContainer
