import { FormProvider, useForm } from 'react-hook-form'

import { ReportTypeButtonsId, ReportFileType } from 'constants/EntityConstants'
import { DriverListReportInputDtoInput } from 'generated/graphql'

import FilterCriteriaForm from './FilterCriteriaForm'

interface IProps {
  defaultFilter: DriverListReportInputDtoInput
  isProcessing: boolean
  onViewReport: (filter: DriverListReportInputDtoInput) => void
  onDownloadReport: (type: ReportFileType, filter: DriverListReportInputDtoInput) => void
}

const FilterCriteria = (props: IProps) => {
  const { defaultFilter, isProcessing, onViewReport, onDownloadReport } = props

  const methods = useForm<DriverListReportInputDtoInput>({
    shouldUnregister: true,
    defaultValues: defaultFilter,
  })
  const { handleSubmit, reset } = methods

  const handleClearAll = () => {
    reset(defaultFilter)
  }

  const handleOnSubmit = (buttonId: ReportTypeButtonsId, formData) => {
    const { driverType } = formData

    const filter: DriverListReportInputDtoInput = {
      driverType: driverType || undefined,
    }

    switch (buttonId) {
      case ReportTypeButtonsId.ViewReport:
        onViewReport(filter)
        break

      case ReportTypeButtonsId.DownloadXlsx:
        onDownloadReport(ReportFileType.xlsx, filter)
        break

      case ReportTypeButtonsId.DownloadPdf:
        onDownloadReport(ReportFileType.pdf, filter)
        break

      case ReportTypeButtonsId.DownloadHtml:
        onDownloadReport(ReportFileType.html, filter)
        break
    }
  }

  const handleReportButtonClick = (buttonId: ReportTypeButtonsId) => {
    handleSubmit((formData) => handleOnSubmit(buttonId, formData))()
  }

  return (
    <FormProvider {...methods}>
      <FilterCriteriaForm
        isProcessing={isProcessing}
        onReportButtonClick={handleReportButtonClick}
        onClearAll={handleClearAll}
      />
    </FormProvider>
  )
}

export default FilterCriteria
