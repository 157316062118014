import { useState, useMemo, useCallback } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import DeleteIcon from '@mui/icons-material/Delete'
import GetAppIcon from '@mui/icons-material/GetApp'
import PublishIcon from '@mui/icons-material/Publish'
import { Button, Grid } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'

import { FplDataGrid, IGridColumn } from 'components/common'
import { Roles } from 'constants/index'
import { FileFilterInput, useDeleteBookingAttachmentMutation } from 'generated/graphql'
import { GET_BOOKING_ATTACHMENTS } from 'graphql/queries'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadFile } from 'services/RestClient'

import { CreateUpdateBookingAttachment } from './CreateUpdateBookingAttachment'

const CustomerAttachmentsFilter: FileFilterInput = {
  bookingAttachment: {
    isAvailableForCustomer: { eq: true },
  },
}

interface IProps {
  bookingId: string | null
}

const Attachments = (props: IProps) => {
  const { bookingId } = props
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null)
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState<boolean>(false)
  const { idTokenPayload } = useOidcIdToken()

  const [processingEntity, setProcessingEntity] = useState<{
    id: number | null
    processing: boolean
  }>({
    id: null,
    processing: false,
  })

  // mutations
  const [deleteAttachment, { loading: deleting }] = useDeleteBookingAttachmentMutation()

  // handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedFileId(Number(id))
    setOpenAttachmentDialog(true)
  }

  const handleUploadFile = () => {
    setSelectedFileId(null)
    setOpenAttachmentDialog(true)
  }

  const handleDownloadAttachment = useCallback(
    (id: GridRowId) => () => {
      setProcessingEntity({ id: Number(id), processing: true })

      DownloadFile(Number(id))
        .then((response) => {
          let fileName = GetFileName(response)
          if (!fileName) {
            fileName = `file-${+new Date()}.dat`
          }

          SaveFile(response.data, fileName)
        })
        .catch((error) => {
          ShowRestError(error)
        })
        .finally(() => {
          setProcessingEntity({ id: Number(id), processing: false })
        })
    },
    [],
  )

  // ToDo: add confirmation dialog
  const handleDeleteAttachment = useCallback(
    (id: GridRowId) => () => {
      deleteAttachment({
        variables: {
          bookingId: Number(bookingId),
          fileId: Number(id),
        },
      }).catch(ShowRestError)
    },
    [bookingId, deleteAttachment],
  )

  const handleCloseDialog = () => setOpenAttachmentDialog(false)

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'category', headerName: 'Category', minWidth: 200, flex: 1 },
      { field: 'description', headerName: 'Description', minWidth: 200, flex: 1 },
      { field: 'size', headerName: 'Size', minWidth: 200, flex: 1 },
      {
        field: 'bookingAttachment.isAvailableForDriver',
        headerName: 'Available for Driver',
        minWidth: 100,
        flex: 1,
        type: 'boolean',
        sortPath: ['bookingAttachment', 'isAvailableForDriver'],
        valueGetter: ({ row }) => row.bookingAttachment?.isAvailableForDriver,
      },
      {
        field: 'bookingAttachment.isAvailableForCustomer',
        headerName: 'Available For Customer',
        minWidth: 100,
        flex: 1,
        type: 'boolean',
        sortPath: ['bookingAttachment', 'isAvailableForCustomer'],
        valueGetter: ({ row }) => row.bookingAttachment?.isAvailableForCustomer,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleting}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteAttachment(id)}
          />,
          <GridActionsCellItem
            key={id}
            label='Download'
            size='large'
            disabled={processingEntity.processing}
            icon={
              <Tooltip title={'Download'} arrow>
                <GetAppIcon />
              </Tooltip>
            }
            onClick={handleDownloadAttachment(id)}
          />,
        ],
      },
    ],
    [deleting, handleDeleteAttachment, processingEntity.processing, handleDownloadAttachment],
  )

  return (
    <>
      <FplDataGrid
        query={GET_BOOKING_ATTACHMENTS}
        queryVariables={{ bookingId: Number(bookingId) }}
        entityName='bookingAttachments'
        columns={columns}
        filter={idTokenPayload.role === Roles.customer ? CustomerAttachmentsFilter : undefined}
        defaultOrder={{ field: 'name', sort: 'asc' }}
        toolbar={{
          caption: 'Attachments',
          leftSide: (
            <Grid item>
              <Button
                variant='outlined'
                size='small'
                startIcon={<PublishIcon />}
                onClick={handleUploadFile}>
                Upload
              </Button>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openAttachmentDialog && (
        <CreateUpdateBookingAttachment
          bookingId={Number(bookingId)}
          fileId={selectedFileId}
          openBookingAttachmentDialog={openAttachmentDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default Attachments
