import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { useGetStandardOperatingProcedureQuery } from 'generated/graphql'
import { normalizeStandardOperatingProcedureForm } from 'helpers'
import { TStandardOperatingProcedureForm } from 'types'

import SopFormControls from './SopFormControls'

interface IProps {
  sopId: string
  mode: number
  isProcessing: boolean
}

const UpdateSop = (props: IProps) => {
  const { sopId, mode, isProcessing } = props

  const { reset } = useFormContext<TStandardOperatingProcedureForm>()

  const { data: standardOperatingProcedureData, loading: standardOperatingProcedureLoading } =
    useGetStandardOperatingProcedureQuery({
      variables: { id: Number(sopId) },
      fetchPolicy: 'network-only'
    })

  useEffect(() => {
    if (standardOperatingProcedureData?.standardOperatingProcedure) {
      reset(
        normalizeStandardOperatingProcedureForm(
          standardOperatingProcedureData.standardOperatingProcedure,
        ),
      )
    }
  }, [standardOperatingProcedureData, reset])

  return (
    <>
      <SopFormControls mode={mode} isProcessing={isProcessing} />
      <LoadingBackdrop loading={standardOperatingProcedureLoading} />
    </>
  )
}

export default UpdateSop
