import { useCallback, useEffect, useMemo, useState } from 'react'

import { Delete, GetApp, Visibility } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { DropzoneArea } from 'react-mui-dropzone'
import { toast } from 'react-toastify'

import { FplDataGrid, FplDialog, IGridColumn, FilePreviewDialog } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { FileConstants } from 'constants/index'
import {
  FileFilterInput,
  useDeleteStandardOperatingProcedureAttachmentMutation,
} from 'generated/graphql'
import { GET_PAGED_FILES_QUERY } from 'graphql/queries'
import { ShowDropzoneAlert } from 'helpers'
import { useFileDownloader, useUploadSopAttachment } from 'hooks'

import SopAccordion from './SopAccordion'

interface SopAttachmentsProps {
  id: number
  readOnly?: boolean
}

const SopAttachments = (props: SopAttachmentsProps) => {
  const { id, readOnly } = props
  const [state, uploadFile] = useUploadSopAttachment()
  const [{ loading: downloading, error: downloadingError }, downloadFile] = useFileDownloader()
  const [files, setFiles] = useState<Array<File>>([])
  const [fileId, setFileId] = useState<number | null>(null)

  const [openUploadDialog, setOpenUploadDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

  const [deleteAttachment, { loading: deleting }] =
    useDeleteStandardOperatingProcedureAttachmentMutation({
      onCompleted: (response) => {
        if (response.deleteSopAttachment) {
          toast.success('Attachment deleted')
        } else {
          toast.error('Something went wrong')
        }
      },
    })
  useEffect(() => {
    if (state.error) {
      toast.error(state.error)
      setFiles([])
      setOpenUploadDialog(false)
    }
  }, [state.error])

  useEffect(() => {
    if (downloadingError) {
      toast.error(downloadingError)
    }
  }, [downloadingError])

  const handleDeleteAttachment = useCallback(
    (attachmentId: GridRowId) => async () => {
      deleteAttachment({
        variables: {
          input: {
            id,
            attachmentId: Number(attachmentId),
          },
        },
      })
    },
    [deleteAttachment, id],
  )

  const handleDownloadAttachment = useCallback(
    (attachmentId: GridRowId) => async () => {
      await downloadFile(Number(attachmentId))
    },
    [downloadFile],
  )

  const handlePreviewAttachment = useCallback(
    (attachmentId: GridRowId) => async () => {
      setFileId(Number(attachmentId))
      setOpenPreviewDialog(true)
    },
    [],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'id', headerName: 'Id', width: 100 },
      { field: 'originalFileName', headerName: 'File Name', minWidth: 150, flex: 2 },
      { field: 'category', headerName: 'Category', minWidth: 100, flex: 1 },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 100,
        flex: 1,
        valueFormatter: ({ value }) => format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      { field: 'createdBy', headerName: 'Created By', minWidth: 100, flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleting}
            icon={
              <Tooltip title={'Delete'} arrow>
                <Delete />
              </Tooltip>
            }
            onClick={handleDeleteAttachment(id)}
          />,
          <GridActionsCellItem
            key={id}
            label='Download'
            size='large'
            disabled={downloading}
            icon={
              <Tooltip title={'Download'} arrow>
                <GetApp />
              </Tooltip>
            }
            onClick={handleDownloadAttachment(id)}
          />,
          <GridActionsCellItem
            key={id}
            label='Preview'
            size='large'
            disabled={downloading}
            icon={
              <Tooltip title={'Preview'} arrow>
                <Visibility />
              </Tooltip>
            }
            onClick={handlePreviewAttachment(id)}
          />,
        ],
      },
    ],
    [
      deleting,
      downloading,
      handleDeleteAttachment,
      handleDownloadAttachment,
      handlePreviewAttachment,
    ],
  )

  const filter: FileFilterInput = {
    entityReference: { eq: 'StandardOperatingProcedure' },
    entityReferenceId: { eq: id },
  }

  const handleUploadClick = () => {
    setOpenUploadDialog(true)
  }

  const handleFilesChanged = (files: File[]): void => setFiles(files)

  const onCloseUploadDialog = (): void => {
    setOpenUploadDialog(false)
    setFiles([])
  }

  const onClosePreviewDialog = (): void => {
    setOpenPreviewDialog(false)
  }

  const onSaveClick = async (): Promise<void> => {
    if (files.length === 0) {
      toast.error('No files were selected')
      return
    }

    const formData = new FormData()
    files.forEach((f, i) => formData.append(`sop-attachment-${i + 1}`, f))

    const result = await uploadFile({ id }, formData)

    if (result) {
      toast.success('File(s) uploaded with succes')
      setOpenUploadDialog(false)
    }
  }

  return (
    <SopAccordion caption='Attachments'>
      <FplDataGrid
        query={GET_PAGED_FILES_QUERY}
        columns={columns}
        filter={filter}
        entityName={'files'}
        toolbar={{
          caption: ' ',
          leftSide: (
            <Grid item>
              <Button
                disabled={readOnly}
                variant='outlined'
                size='small'
                onClick={handleUploadClick}>
                Upload
              </Button>
            </Grid>
          ),
        }}
      />

      <FplDialog
        open={openUploadDialog}
        submitCaption='Upload'
        closeCaption={files.length > 0 ? 'Discard' : 'Cancel'}
        caption='Upload SOP Attachment'
        id='form-sop-attachment-dialog'
        onCloseDialog={onCloseUploadDialog}
        onSaveClick={onSaveClick}
        fullWidth
        loading={state.loading}>
        <DropzoneArea
          onChange={handleFilesChanged}
          filesLimit={5}
          showAlerts={false}
          onAlert={ShowDropzoneAlert}
          maxFileSize={FileConstants.maxFileSize}
        />
      </FplDialog>

      <FilePreviewDialog
        fileId={fileId}
        openDialog={openPreviewDialog}
        handleCloseDialog={onClosePreviewDialog}
      />
    </SopAccordion>
  )
}

export default SopAttachments
