import { toast } from 'react-toastify'

import { DEFAULT_COUNTRY_ID } from 'constants/index'
import { PackagingType } from 'generated/graphql'
import { TBookingAddressForm, TBookingForm, TBookingPackageForm } from 'types/form-types'

import { TRequiredAdditionalData, TAdditionalData, TExtractedData } from '.'

const collectionSequenceOrder = 0

export const TradechoiceGeneralRequiredAdditionalData: TRequiredAdditionalData = {
  isRequiredReasonCode: true,
  isRequiredCountriesList: false,
  isRequiredTariffsList: true,
}

export const TradechoiceGeneral = (
  bookingData: TExtractedData,
  newBooking: TBookingForm,
  additionalData: TAdditionalData,
): TBookingForm => {
  // Process Collection
  const collectionRow = bookingData.filter(
    (x) => x['Delivery/Pickup/Transfer/Flyby/Service'] === 'P',
  )[0]

  if (collectionRow) {
    const collectionAddress = {
      name: collectionRow['Customer Name'],
      city: collectionRow['City'],
      addressLine1: collectionRow['Address'],
      addressLine2: '',
      postcode: collectionRow['Zip'],
      countryId: DEFAULT_COUNTRY_ID,
      contact: '',
      email: '',
      telephoneNumber: '',
      note: '',
      sequenceOrder: collectionSequenceOrder,
    } as unknown as TBookingAddressForm

    newBooking.collectionAddresses = [collectionAddress]

    // Process Tariff
    const tariffFromRow = collectionRow['Reason code - Vehicle type']
    const tariffsFromDB = additionalData.tariffs?.filter(
      (x) => tariffFromRow === x.tariffCategory?.courierExchangeVehicleSize,
    )

    if (!tariffsFromDB || tariffsFromDB?.length === 0) {
      toast.warn('No suitable Tariff found.')
    } else {
      if (tariffsFromDB?.length > 1) {
        toast.warn('More than one suitable Tariffs found; using first one.')
      }

      newBooking.tariffId = tariffsFromDB[0].id
    }
  } else {
    toast.warn('Collection Row not found.')
  }

  // Process Deliveries
  const deliveryRows = bookingData.filter(
    (x) => x['Delivery/Pickup/Transfer/Flyby/Service'] === 'D',
  )

  const sequenceNumbers = deliveryRows.reduce(
    (set, row) => set.add(row['Sequence Number']),
    new Set<string>(),
  )

  newBooking.deliveryAddresses = []
  let sequenceOrder = collectionSequenceOrder + 1
  let isReasonCodeMissing = false

  sequenceNumbers.forEach((sequenceNumber) => {
    const deliveryRow = deliveryRows.filter((x) => x['Sequence Number'] === sequenceNumber)[0]
    const reasonCodeFromRow = deliveryRow['Reason code - Vehicle type']
    const reasonCodeFromDB = additionalData.reasonCodes?.filter((x) =>
      reasonCodeFromRow?.startsWith(x.code),
    )[0]
    isReasonCodeMissing = !reasonCodeFromRow

    const deliveryAddress = {
      name: deliveryRow['Customer Name'],
      city: deliveryRow['City'],
      addressLine1: deliveryRow['Address'],
      addressLine2: '',
      postcode: deliveryRow['Zip'],
      countryId: DEFAULT_COUNTRY_ID,
      contact: '',
      email: '',
      telephoneNumber: '',
      note: '',
      sequenceOrder: sequenceOrder,
      reasonCodeId: Number(reasonCodeFromDB?.id || 0),
    } as unknown as TBookingAddressForm

    newBooking.deliveryAddresses.push(deliveryAddress)
    sequenceOrder++
  })

  if (isReasonCodeMissing) {
    toast.warn(
      '"Reason code - Vehicle type" column missing; please make sure it is spelled exactly like that.',
    )
  }

  // Process Packages
  newBooking.packages = []
  let deliverySequenceOrder = collectionSequenceOrder + 1

  sequenceNumbers.forEach((sequenceNumber) => {
    const rows = deliveryRows.filter((x) => x['Sequence Number'] === sequenceNumber)

    rows.forEach((row) => {
      const pack = {
        quantity: 1,
        packageType: PackagingType.Palletsukstandard,
        collectionSequenceOrder: collectionSequenceOrder,
        deliverySequenceOrder: deliverySequenceOrder,
        length: 1,
        width: 1,
        height: 1,
        weight: Number(row['Weight']) || 0,
        reference: row['Order Number'],
        details: '',
      } as unknown as TBookingPackageForm

      newBooking.packages.push(pack)
    })

    deliverySequenceOrder++
  })

  return newBooking
}
