import React from 'react'

import { Grid, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledCheckbox,
  ControlledTelephoneNumber,
  ControlledTextField,
} from 'components/common'
import { Mode } from 'constants/index'
import { CreateAndUpdateContactDtoInput, useGetCustomerContactsQuery } from 'generated/graphql'

interface IProps {
  customerId: number | null
  mode: number
}

const ContactForm = (props: IProps) => {
  const { mode, customerId } = props
  const [isDefaultDisabled, setIsDefaultDisabled] = React.useState(false)

  const methods = useFormContext<CreateAndUpdateContactDtoInput>()
  const {
    control,
    setValue,
    formState: { errors },
  } = methods

  // Queries
  const { data: customerContacts } = useGetCustomerContactsQuery({ variables: { customerId } })
  const contactsCount = customerContacts?.customerContacts?.length

  React.useEffect(() => {
    if (mode === Mode.Create && contactsCount === 0) {
      setValue('isDefault', true)
      setIsDefaultDisabled(true)
    } else if (mode === Mode.Update && contactsCount === 1) {
      setValue('isDefault', true)
      setIsDefaultDisabled(true)
    }
  }, [contactsCount, mode, setValue])

  return (
    <Box pt='6px'>
      <Grid container columnSpacing={3} rowSpacing={1.5}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Name'
            name='name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTelephoneNumber
            control={control}
            label='Telephone Number'
            name='telephoneNumber'
            required
            error={!!errors.telephoneNumber}
            helperText={errors.telephoneNumber?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Email'
            name='email'
            type='email'
            required
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Additional Email(s)'
            name='additionalEmails'
            multiline
            error={!!errors.additionalEmails}
            helperText={
              errors.additionalEmails
                ? errors.additionalEmails?.message
                : 'Manage multiple emails separated by ;'
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mt={-2.5}>
            <ControlledCheckbox
              control={control}
              label='Mark as Default'
              name='isDefault'
              disabled={isDefaultDisabled}
            />
            <ControlledCheckbox control={control} name='isActive' label='Is Active' />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactForm
