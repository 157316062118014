import { gql } from '@apollo/client'

import { TemplateDetailsFragment } from '../fragments'

export const GET_SCHEDULED_BOOKING_TEMPLATE_BY_BOOKING_ID = gql`
  query getScheduledBookingTemplateByBookingId($bookingId: Long!) {
    scheduledBookingTemplateByBookingId(bookingId: $bookingId) {
      id
      ...TemplateDetails
    }
    ${TemplateDetailsFragment}
  }
`

export const GET_SCHEDULED_BOOKING_DATES = gql`
  query getScheduledBookingDates(
    $templateId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ScheduledBookingDateSortInput!]
  ) {
    scheduledBookingDates(
      templateId: $templateId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
    ) {
      edges {
        node {
          id
          templateId
          executeOnDate
          taskId
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_SCHEDULED_BOOKING_TASKS = gql`
  query getScheduledBookingTasks(
    $templateId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ScheduledBookingTaskSortInput!]
    $where: ScheduledBookingTaskFilterInput
  ) {
    scheduledBookingTasks(
      templateId: $templateId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          templateId
          dueByDateTime
          createdAt
          lastModifiedAt
          status
          bookingId
          errorMessage
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
