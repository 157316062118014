import { CheckOutlined, DeleteOutline } from '@mui/icons-material'
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { format, formatDistance } from 'date-fns'

import { TIME_FORMAT } from 'constants/index'
import {
  GetNotificationsDocument,
  Notification,
  useDeleteNotificationMutation,
} from 'generated/graphql'

interface NotificationItemProps {
  notificationDetails: Notification
  queryVariables: any
  onItemPress?: (notificationDetails: Notification) => void
}

const NotificationItem = ({
  notificationDetails,
  onItemPress,
  queryVariables,
}: NotificationItemProps) => {
  const [deleteNotification, { loading }] = useDeleteNotificationMutation({
    refetchQueries: [
      {
        query: GetNotificationsDocument,
        variables: {
          ...queryVariables,
        },
      },
    ],
  })

  const onClick = () => {
    if (onItemPress) {
      onItemPress(notificationDetails)
    }
  }

  const handleDeleteNotification = () => {
    deleteNotification({
      variables: {
        id: Number(notificationDetails.id),
      },
    })
  }

  return (
    <>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <ListItemButton onClick={onClick}>
          <ListItemAvatar>
            <Avatar
              sx={{
                color: 'white',
                bgcolor: 'grey',
              }}>
              <CheckOutlined />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant='h6'>
                {notificationDetails.name}:{' '}
                <Typography component='span' variant='subtitle1'>
                  {notificationDetails.description}
                </Typography>
              </Typography>
            }
            secondary={formatDistance(new Date(notificationDetails.date), new Date(), {
              addSuffix: true,
            })}
          />
          <ListItemSecondaryAction>
            <Typography variant='caption' noWrap>
              {format(new Date(notificationDetails.date), TIME_FORMAT)}
            </Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <Divider orientation='vertical' />
        <IconButton
          disabled={loading}
          aria-label='delete notification'
          onClick={handleDeleteNotification}>
          {loading ? <CircularProgress /> : <DeleteOutline />}
        </IconButton>
      </Box>
      <Divider />
    </>
  )
}

export default NotificationItem
