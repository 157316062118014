export enum FileType {
  MicrosoftOfficeGeneral = 0,
  MicrosoftOfficeExcel,
  MicrosoftOfficeDocx,
  Other,
}

export const getFileTypeFromMimeType = (mimeType: string): FileType => {
  switch (mimeType) {
    case 'application/msword':
    case 'application/octet-stream':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return FileType.MicrosoftOfficeGeneral

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return FileType.MicrosoftOfficeExcel

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return FileType.MicrosoftOfficeDocx

    default:
      return FileType.Other
  }
}
