import { useState } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'

import { ServiceCodes } from './ServiceCodes'
import { TariffCategories } from './TariffCategories'
import { TariffIcons } from './TariffIcons'
import { Tariffs } from './Tariffs'

function commonProps(index: TabId) {
  return {
    id: `setting-tariffs-tab-${index}`,
    'aria-controls': `setting-tariffs-tabpanel-${index}`,
  }
}

enum TabId {
  Tariffs = 0,
  TariffCategories,
  TariffIcons,
  ServiceCodes,
}

const TariffsTab = () => {
  const [tabValue, setTabValue] = useState(0)

  const tabs = {
    tariffs: { id: TabId.Tariffs, label: 'Tariffs' },
    tariffCategories: { id: TabId.TariffCategories, label: 'Tariff Categories' },
    tariffIcons: { id: TabId.TariffIcons, label: 'Tariff Icons' },
    serviceCodes: { id: TabId.ServiceCodes, label: 'Service Codes' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='settings tariffs tabs'
        variant='scrollable'
        sx={{
          boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
          minWidth: (theme) => theme.spacing(27),
        }}>
        <Tab label={tabs.tariffs.label} key={tabs.tariffs.id} {...commonProps(tabs.tariffs.id)} />
        <Tab
          label={tabs.tariffCategories.label}
          key={tabs.tariffCategories.id}
          {...commonProps(tabs.tariffCategories.id)}
        />
        <Tab
          label={tabs.tariffIcons.label}
          key={tabs.tariffIcons.id}
          {...commonProps(tabs.tariffIcons.id)}
        />
        <Tab
          label={tabs.serviceCodes.label}
          key={tabs.serviceCodes.id}
          {...commonProps(tabs.serviceCodes.id)}
        />
      </Tabs>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.tariffs.id}
        key={tabs.tariffs.id}
        px={3}
        width={(theme) => {
          return `calc(100% - ${theme.spacing(27)})`
        }}>
        <Tariffs />
      </VerticalTabPanel>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.tariffCategories.id}
        key={tabs.tariffCategories.id}
        px={3}
        width={(theme) => {
          return `calc(100% - ${theme.spacing(27)})`
        }}>
        <TariffCategories />
      </VerticalTabPanel>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.tariffIcons.id}
        key={tabs.tariffIcons.id}
        px={3}
        width={(theme) => {
          return `calc(100% - ${theme.spacing(27)})`
        }}>
        <TariffIcons />
      </VerticalTabPanel>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.serviceCodes.id}
        key={tabs.serviceCodes.id}
        px={3}
        width={(theme) => {
          return `calc(100% - ${theme.spacing(27)})`
        }}>
        <ServiceCodes />
      </VerticalTabPanel>
    </Box>
  )
}

export default TariffsTab
