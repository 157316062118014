import { useMemo } from 'react'

import { ControlledSelector } from 'components/common'
import { useGetXeroCodesQuery } from 'generated/graphql'

interface IProps {
  control: any
}

const XeroCodesSelector = (props: IProps) => {
  const { control } = props

  const { data: xeroCodesData } = useGetXeroCodesQuery({
    variables: {
      where: {
        isInternational: { eq: false },
      },
    },
  })

  const xeroCodeItems = useMemo(() => {
    const internationalId = 'International'

    return (
      xeroCodesData?.xeroCodes.map((xeroCode) => ({
        value: xeroCode.id,
        label: xeroCode.name,
      })) || []
    ).concat({ value: internationalId, label: 'International' })
  }, [xeroCodesData])

  return (
    <ControlledSelector
      control={control}
      name='xeroCodeId'
      label='Service Code'
      options={xeroCodeItems}
      emptyValue={{ value: '', label: 'All Service Codes' }}
      displayEmpty
    />
  )
}

export default XeroCodesSelector
