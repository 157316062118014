import { formatISO } from 'date-fns'

import {
  CourierExchangeLoadDtoInput,
  DeliverTimeRangeType,
  PickupTimeRangeType,
  StopTimeRangeType,
} from 'generated/graphql'

const normalizePostLoadForm = (data): Partial<CourierExchangeLoadDtoInput> => {
  let readyAt
  let collectBy
  let deliverFrom
  let deliverBy

  switch (data.pickupTimeRange) {
    case PickupTimeRangeType.At:
      readyAt = formatISO(data.readyAt)
      collectBy = null
      break

    case PickupTimeRangeType.Before:
      collectBy = formatISO(data.collectBy)
      readyAt = null
      break

    case PickupTimeRangeType.Between:
      readyAt = formatISO(data.readyAt)
      collectBy = formatISO(data.collectBy)
      break

    default:
      break
  }

  switch (data.deliverTimeRange) {
    case DeliverTimeRangeType.Asap:
      deliverFrom = null
      deliverBy = null
      break

    case DeliverTimeRangeType.At:
      deliverFrom = formatISO(data.deliverFrom)
      deliverBy = null
      break

    case DeliverTimeRangeType.By:
      deliverBy = formatISO(data.deliverBy)
      deliverFrom = null
      break

    case DeliverTimeRangeType.Between:
      deliverFrom = formatISO(data.deliverFrom)
      deliverBy = formatISO(data.deliverBy)
      break

    default:
      break
  }

  const stopTimeDetails = data.stopTimeDetails?.map((stopTimeDetail) => {
    switch (stopTimeDetail.timeRange) {
      case StopTimeRangeType.Asap:
        return {
          timeRange: stopTimeDetail.timeRange,
          timeFrom: null,
          timeTo: null,
        }

      case StopTimeRangeType.Between:
        return {
          timeRange: stopTimeDetail.timeRange,
          timeFrom: formatISO(stopTimeDetail.timeFrom),
          timeTo: formatISO(stopTimeDetail.timeTo),
        }
    }
  })

  return {
    readyAt,
    collectBy,
    stopTimeDetails,
    deliverFrom,
    deliverBy,
  }
}

export { normalizePostLoadForm }
