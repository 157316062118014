import { useMemo } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'

import { ControlledSelector, ControlledTextField, ISelectorOptions } from 'components/common'
import { FplDialogTitle } from 'components/common/Dialog'
import { JoiValidationOptions } from 'constants/index'
import { useGetQuoteLostReasonsQuery } from 'generated/graphql'
import { usePermission } from 'providers'
import { ConfirmQuoteCancellationDialogValidation } from 'validation-schemas'

interface IProps {
  open: boolean
  isQuote: boolean
  setOpen: any
  onConfirmCancellation: (
    cancellationReasonOrQuoteLostDetail: string,
    QuoteLostReasonId?: string,
  ) => void
}

const ConfirmCancellationDialog = (props: IProps) => {
  const { open, isQuote, setOpen, onConfirmCancellation } = props

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    context: { isQuote },
    resolver: joiResolver(ConfirmQuoteCancellationDialogValidation, JoiValidationOptions),
    defaultValues: {
      quoteLostReasonId: '',
      cancellationReasonOrQuoteLostDetail: '',
    },
  })

  const [isAllowedToViewQuoteLostReasonList] = usePermission('ViewQuoteLostReasonList')

  const { data: quoteLostReasonsData } = useGetQuoteLostReasonsQuery({
    skip: !isQuote || !isAllowedToViewQuoteLostReasonList,
  })

  const quoteLostReasonOptions: ISelectorOptions = useMemo(
    () =>
      quoteLostReasonsData?.quoteLostReasons.map((quoteLostReason) => ({
        value: quoteLostReason.id,
        label: quoteLostReason.name,
      })) || [],
    [quoteLostReasonsData],
  )

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm: SubmitHandler<any> = (data) => {
    const { quoteLostReasonId, cancellationReasonOrQuoteLostDetail } = data

    onConfirmCancellation(cancellationReasonOrQuoteLostDetail, quoteLostReasonId)
    setOpen(false)
  }

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='confirm-cancellation-dialog'>
      <FplDialogTitle id='confirm-cancellation-dialog' onClose={handleClose}>
        <Typography variant='h6'>Confirm Cancellation</Typography>
      </FplDialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Grid container spacing={2}>
            {isQuote && (
              <Grid item xs={12}>
                <ControlledSelector
                  control={control}
                  name='quoteLostReasonId'
                  label='Quote Lost Reason'
                  required
                  options={quoteLostReasonOptions}
                  error={!!errors.quoteLostReasonId}
                  helperText={errors.quoteLostReasonId?.message}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name='cancellationReasonOrQuoteLostDetail'
                label={isQuote ? 'Quote Lost Detail' : 'Cancellation Reason'}
                required
                multiline
                rows={3}
                error={!!errors.cancellationReasonOrQuoteLostDetail}
                helperText={errors.cancellationReasonOrQuoteLostDetail?.message}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit(handleConfirm)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmCancellationDialog
