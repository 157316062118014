import React from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'
import { EmailTemplate } from 'components/settings/CustomerEmails/EmailTemplate'
import { EmailTemplateType } from 'generated/graphql'

import { EmailBoxSx, EmailTabSx } from '../SettingsStyles'

enum TabId {
  EmailSignature = 0,
  Booking,
  BookingAmend,
  Quotation,
  BookingReadyForAllocation,
  DriverAllocated,
  PackageOnBoardArrival,
  PackageOnBoard,
  ProofOfDeliveryArrival,
  ProofOfDeliveryPartial,
  ProofOfDelivery,
  BookingCancellation,
  CustomerInvoice,
  CardedEmail,
  DeleteBooking,
}

function commonProps(index: TabId) {
  return {
    id: `customer-emails-tab-${index}`,
    'aria-controls': `customer-emails-tabpanel-${index}`,
  }
}

const CustomerEmails = () => {
  const [tabValue, setTabValue] = React.useState(0)

  const tabs = [
    {
      id: TabId.EmailSignature,
      label: 'Email Signature',
      type: EmailTemplateType.Signature,
    },
    { id: TabId.Booking, label: 'New Booking', type: EmailTemplateType.CustomerBooking },
    {
      id: TabId.BookingAmend,
      label: 'Amend Booking',
      type: EmailTemplateType.CustomerBookingAmend,
    },
    { id: TabId.Quotation, label: 'Quotation', type: EmailTemplateType.CustomerQuotation },
    {
      id: TabId.BookingReadyForAllocation,
      label: 'Booking ready for Allocation',
      type: EmailTemplateType.CustomerBookingReadyForAllocation,
    },
    {
      id: TabId.DriverAllocated,
      label: 'Driver was Allocated',
      type: EmailTemplateType.CustomerDriverAllocated,
    },
    {
      id: TabId.PackageOnBoardArrival,
      label: 'Package On Board Arrival',
      type: EmailTemplateType.CustomerPackageOnBoardArrival,
    },
    {
      id: TabId.PackageOnBoard,
      label: 'Package On Board',
      type: EmailTemplateType.CustomerPackageOnBoard,
    },
    {
      id: TabId.ProofOfDeliveryArrival,
      label: 'Proof Of Delivery Arrival',
      type: EmailTemplateType.CustomerProofOfDeliveryArrival,
    },
    {
      id: TabId.ProofOfDeliveryPartial,
      label: 'Proof Of Delivery Partial',
      type: EmailTemplateType.CustomerProofOfDeliveryPartial,
    },
    {
      id: TabId.ProofOfDelivery,
      label: 'Proof Of Delivery',
      type: EmailTemplateType.CustomerProofOfDelivery,
    },
    {
      id: TabId.BookingCancellation,
      label: 'Booking Cancellation',
      type: EmailTemplateType.CustomerBookingCancellation,
    },
    {
      id: TabId.CustomerInvoice,
      label: 'Customer Invoice',
      type: EmailTemplateType.CustomerInvoice,
    },
    { id: TabId.CardedEmail, label: 'Carded Email', type: EmailTemplateType.CustomerCarded },
    {
      id: TabId.DeleteBooking,
      label: 'Delete Booking',
      type: EmailTemplateType.CustomerDeleteBooking,
    },
  ]

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={EmailBoxSx}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='customer emails tabs'
        variant='scrollable'
        sx={EmailTabSx}>
        {tabs.map((tab) => (
          <Tab label={tab.label} key={tab.id} {...commonProps(tab.id)} />
        ))}
      </Tabs>

      {tabs.map((tab) => (
        <VerticalTabPanel value={tabValue} index={tab.id} key={tab.id} px={3}>
          <EmailTemplate type={tab.type} label={tab.label} />
        </VerticalTabPanel>
      ))}
    </Box>
  )
}

export default CustomerEmails
