export enum BookingsTabId {
  Details = 0,
  MapRoute,
  Attachments,
  PostLoad,  
  AllocateBids,
  AllocateCXMember,
  Logs,
  Audit,
  Tasks,
  ScheduledTemplate,
}

export enum QuotesTabId {
  Details = 0,
  MapRoute,
  Attachments,
  PostLoad,
  AllocateCXMember,
  Tasks,
}
