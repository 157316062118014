import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { StandardOperatingProcedureHistoryDtoFilterInput } from 'generated/graphql'
import { GET_STANDARD_OPERATING_PROCEDURE_HISTORY } from 'graphql/queries'

import SopAccordion from './SopAccordion'

interface IProps {
  id: string
}

const SopHistory = (props: IProps) => {
  const { id } = props

  const columns: Array<IGridColumn> = [
    { field: 'name', headerName: 'Name', minWidth: 150, flex: 0.4 },
    { field: 'description', headerName: 'Description', minWidth: 150, flex: 0.4 },
    { field: 'isActive', headerName: 'Is Active', type: 'boolean', minWidth: 100, flex: 0.5 },
    { field: 'isAttachedToAllCustomers', headerName: 'All Customers', type: 'boolean' },
    {
      field: 'lastModifiedAt',
      headerName: 'Modified At',
      minWidth: 100,
      flex: 0.6,
      valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_TIME_FORMAT),
    },
    { field: 'lastModifiedBy', headerName: 'Modified By', minWidth: 120, flex: 0.4 },
  ]

  const filter: StandardOperatingProcedureHistoryDtoFilterInput = {
    sopId: { eq: Number(id) },
  }

  return (
    <SopAccordion caption='History'>
      <FplDataGrid
        query={GET_STANDARD_OPERATING_PROCEDURE_HISTORY}
        columns={columns}
        filter={filter}
        entityName='standardOperatingProcedureHistory'
        disableSelectionOnClick
        defaultOrder={{ field: 'lastModifiedAt', sort: 'asc' }}
        toolbar={{
          caption: ' ',
        }}
      />
    </SopAccordion>
  )
}

export default SopHistory
