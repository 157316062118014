import { useEffect, useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { GridColDef, GridEventListener, GridRowModel } from '@mui/x-data-grid'

import { FplDialogTitle, ClientDataGrid } from 'components/common'
import { useGetLoqateFindLazyQuery } from 'generated/graphql'

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Text', minWidth: 200, flex: 1 },
  { field: 'description', headerName: 'Description', minWidth: 200, flex: 1 },
]

interface IProps {
  open: boolean
  setOpen: any
  postcode: string
  countryId: number
  onSelectAddress: (id: string) => void
}

const AutoFillAddressDialog = (props: IProps) => {
  const { open, setOpen, postcode, countryId, onSelectAddress } = props
  const [rows, setRows] = useState<Array<GridRowModel>>([])

  // queries
  const [getLoqateFind, { loading }] = useGetLoqateFindLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.loqateFind && data.loqateFind.length > 0) {
        const loqateFind = data.loqateFind[0]

        if (loqateFind?.isContainer && loqateFind?.type === 'Postcode') {
          getLoqateFind({
            variables: {
              text: postcode,
              countryId,
              container: loqateFind?.id,
            },
          })
        } else {
          const rows = data.loqateFind
            .filter((address) => address.isRetrievable)
            .map(
              (address) =>
              ({
                id: address.id,
                name: address.text,
                description: address.description,
              } as GridRowModel),
            )
          setRows(rows)
        }
      }
    },
  })

  useEffect(() => {
    postcode && getLoqateFind({ variables: { text: postcode, countryId } })
  }, [postcode, countryId, getLoqateFind])

  // handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectAddress(String(id))
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='af-address-dialog'>
      <FplDialogTitle id='af-address-dialog' onClose={handleClose}>
        <Typography variant='h6'>Auto Fill Address</Typography>
      </FplDialogTitle>
      <DialogContent>
        <ClientDataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          toolbar={{ caption: 'Address List' }}
          onRowClick={handleRowClick}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AutoFillAddressDialog
