import { useState, useEffect } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { Box, Button, Typography, Paper, Stack } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import validationSchema from 'components/settings/Tariffs/Tariffs/TariffDetails/ValidationSchema'
import { Paths, Mode, PARAM_NEW } from 'constants/index'
import {
  CreateAndUpdateTariffDtoInput,
  useCreateTariffMutation,
  useUpdateTariffMutation,
  TariffBaseUnit,
  TariffType,
  TariffCalculateCharge,
  TariffDetailsFragment,
} from 'generated/graphql'
import { isNumber } from 'helpers'

import TariffForm from './TariffForm'
import UpdateTariff from './UpdateTariff'

const schema = validationSchema()

const TariffDetails = () => {
  const { id } = useParams<{ id?: string }>()

  let mode = Mode.Create

  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const history = useHistory()
  const [notFound, setNotFound] = useState<boolean>((mode === Mode.Update && !id) || !mode)

  const [tariff, setTariff] = useState<CreateAndUpdateTariffDtoInput>({
    // Tariff Details
    id,
    name: '',
    xeroCodeId: '',
    xeroServiceCode: '',
    isAttachedToAllCustomers: false,
    customerIds: [],
    tariffCategoryId: '',
    vatCategoryId: '',
    baseUnit: TariffBaseUnit.Mile,
    minCharge: '',
    rate: '',
    minMiles: '',
    type: TariffType.Distance,

    // Waiting Time Charge Details
    isOverwriteDefaultWaitingTime: false,
    collectionAllowanceMinutes: '' as any,
    deliveryAllowanceMinutes: '' as any,
    excessWaitingTimeCharge: '' as any,
    excessWaitingTimeMinutesPer: '' as any,
    calculateCharge: TariffCalculateCharge.ExpressWaitingFee,

    // Drop Charge Details
    isDropChargeEnabled: false,
    isUseDefaultDropCharge: false,
    dropChargeRate: '',

    // Portal Details
    isExternal: false,
    visibilitySequenceNumber: '' as any,
    tariffIconId: '',
    capacity: '',
  })

  const methods = useForm<CreateAndUpdateTariffDtoInput>({
    shouldUnregister: true,
    defaultValues: { ...tariff },
    resolver: joiResolver(schema),
  })

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods

  useEffect(() => {
    reset({
      ...tariff,
    })
  }, [reset, tariff])

  const [createTariff, { loading: creatingTariff }] = useCreateTariffMutation({
    onCompleted: (data) => {
      toast.success(`Tariff ${data.createTariff?.name} was successfully created.`)
      history.push(Paths.tariffs.updateWithId(data.createTariff?.id))
    },
  })
  const [updateTariff, { loading: updatingTariff }] = useUpdateTariffMutation({
    onCompleted: (data) => {
      toast.success(`Tariff ${data.updateTariff?.name} was successfully updated.`)

      if (data.updateTariff?.id !== id) {
        // Got new ID.
        history.push(Paths.tariffs.updateWithId(data.updateTariff?.id))
      } else {
        const { /* __typename, */ id, customers, ...tariff } =
          data.updateTariff as TariffDetailsFragment
        // TODO: fix this hack
        const customerIds: any = customers
          ? customers.map((c) => ({ value: c.customer.id, label: c.customer.name }))
          : []

        setTariff({
          id,
          customerIds,
          ...tariff,
        } as CreateAndUpdateTariffDtoInput)
      }
    },
  })

  const onSubmit = (data: CreateAndUpdateTariffDtoInput) => {
    if (!isDirty) {
      return
    }

    const {
      rate,
      vatCategoryId,
      collectionAllowanceMinutes,
      tariffCategoryId,
      deliveryAllowanceMinutes,
      customerIds,
      excessWaitingTimeMinutesPer,
      dropChargeRate,
      minCharge,
      minMiles,
      excessWaitingTimeCharge,
      visibilitySequenceNumber,
      tariffIconId,
      xeroCodeId,
      xeroServiceCode,
      ...restData
    } = data

    const normalizedProps = () => ({
      rate: Number(rate),
      tariffCategoryId: tariffCategoryId ? String(tariffCategoryId) : undefined,
      vatCategoryId: vatCategoryId ?? null,
      collectionAllowanceMinutes: Number(collectionAllowanceMinutes) ?? null,
      deliveryAllowanceMinutes: Number(deliveryAllowanceMinutes) ?? null,
      excessWaitingTimeMinutesPer: Number(excessWaitingTimeMinutesPer) ?? null,
      dropChargeRate: Number(dropChargeRate) ?? null,
      minCharge: Number(minCharge) ?? null,
      minMiles: Number(minMiles) ?? null,
      excessWaitingTimeCharge: Number(excessWaitingTimeCharge) ?? null,
      visibilitySequenceNumber: visibilitySequenceNumber
        ? Number(visibilitySequenceNumber)
        : undefined,
      tariffIconId: tariffIconId ? Number(tariffIconId) : undefined,
      xeroCodeId: xeroCodeId ? String(xeroCodeId) : undefined,
      xeroServiceCode: xeroServiceCode ? String(xeroServiceCode) : undefined,
      customerIds:
        customerIds && customerIds.length > 0
          ? customerIds.filter((x: any) => x.value).map((x: any) => x.value)
          : [],
      // ToDo: remove from BA
      iconUrl: '',
    })

    if (mode === Mode.Create) {
      createTariff({
        variables: {
          input: {
            id: null,
            ...normalizedProps(),
            ...restData,
          },
        },
      })
    } else {
      updateTariff({
        variables: {
          input: {
            id,
            ...normalizedProps(),
            ...restData,
          },
        },
      })
    }
  }
  const handleCancel = () => history.push(Paths.settings.all)
  const isProcessing = creatingTariff || updatingTariff

  if (notFound) {
    return <Typography variant='h2'>Tariff not found.</Typography>
  }

  return (
    <Paper elevation={5}>
      <Box padding={3}>
        <FormProvider {...methods}>
          <form id='tariff-form' onSubmit={handleSubmit((data) => onSubmit(data))} noValidate>
            <Stack spacing={3}>
              <div>
                {id && isNumber(id) ? (
                  <UpdateTariff
                    id={id}
                    tariff={tariff}
                    setTariff={setTariff}
                    setNotFound={setNotFound}
                  />
                ) : (
                  <TariffForm />
                )}
              </div>

              <Stack direction='row' justifyContent='flex-end' spacing={3}>
                <Button
                  variant='contained'
                  color='grey'
                  disabled={isProcessing}
                  onClick={handleCancel}>
                  Cancel
                </Button>

                <Button
                  form='tariff-form'
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!isDirty || isProcessing}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Paper>
  )
}

export default TariffDetails
