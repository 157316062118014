import { useState, useEffect, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Stack } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridDensity,
  GridRowModel,
  GridSortItem,
  useGridApiRef,
} from '@mui/x-data-grid'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import {
  getColumnVisibility,
  getDataSort,
  getDensityModel,
  getPageNumber,
  getPageSize,
  getPagination,
  removePagination,
  setColumnVisibility,
  setDensityModel,
  setPageNumber,
} from 'helpers'
import useFplDataGridHandlers from 'hooks/fplDataGrid/useFplDataGridHandlers'

import CustomLoadingOverlay from './CustomLoadingOverlay'
import CustomToolbar from './CustomToolbar'
import { getDefaultSortModel, getPaging, getSortModel } from './helpers'
import { IFplDataGridProps } from './interfaces/IFplDataGridProps'

const defaultPageOptions = {
  pageSize: 10,
  rowsPerPage: [5, 10, 100],
}
interface IGridColumn extends Omit<GridColDef, 'sortPath'> {
  sortPath?: Array<string>
}

export interface IPageState {
  page: number
  pageSize: number
}

export interface ISortModel extends GridSortItem {
  orderInput: Record<string, any>
}

const FplDataGrid = (props: IFplDataGridProps) => {
  const {
    query,
    entityName,
    columns,
    queryVariables,
    skipQuery,
    filter,
    pollInterval,
    defaultOrder,
    variableSortOrder,
    rowModesModel,
    isRowSelectable,
    processRowUpdate,
    pageOptions = defaultPageOptions,
    toolbar,
    footer,
    checkboxSelection,
    selectionModel,
    onSelectionModelChange,
    hideToolbar,
    hideFooter,
    refetchWithDelay,
    showAppBarLoading,
    rootSx,
    getRowHeight,
    onRowClick,
    disableSelectionOnClick,
    fetchPolicy,
    isHideOldData,
    overwriteId,
    onDataLoadCompleteAction,
    setGridRef,
  } = props

  let id = entityName
  if (overwriteId !== undefined) id = overwriteId

  let pagination = getPagination(id)
  const pageRows = getPageSize(id)
  const pageNumber = getPageNumber(id)
  const dataSort = getDataSort(id)

  const [pageState, setPageState] = useState<IPageState>({
    page: Number(pageNumber),
    pageSize: pageRows ? Number(pageRows) : pageOptions.pageSize,
  })
  const [sortModel, setSortModel] = useState<Array<ISortModel>>(() =>
    getDefaultSortModel(dataSort ?? defaultOrder, columns),
  )
  const visibilityModel = getColumnVisibility(id)
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(visibilityModel)

  const densityModel = getDensityModel(id)
  const [density, setDensity] = useState<GridDensity>(densityModel)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pagination = getPaging({ before: null, after: null, first: pageRows })
    setPageNumber(0, id)
  }, [filter])

  // query
  const { data, loading, fetchMore, startPolling, stopPolling, refetch } = useQuery(query, {
    fetchPolicy: fetchPolicy,
    variables: {
      ...getPaging({ first: pageState.pageSize }),
      order: sortModel[0]?.orderInput,
      where: filter,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipQuery,
    onCompleted: (data) => {
      if (data?.[entityName]?.pageInfo.hasPreviousPage === false) removePagination(id)
      if (data?.[entityName]?.pageInfo.hasPreviousPage === false && pageState.page !== 0) {
        setPageState((prevState) => ({ ...prevState, page: pageNumber ? Number(pageNumber) : 0 }))
      }
    },
  })
  const rowCount = data?.[entityName]?.totalCount ?? 0

  useEffect(() => {
    if (!loading && data && onDataLoadCompleteAction) {
      onDataLoadCompleteAction(data, loading)
    }
  }, [data, loading, onDataLoadCompleteAction])

  useEffect(() => {
    if (variableSortOrder && !dataSort) {
      const sortModel = getSortModel(variableSortOrder.field, variableSortOrder.sort, columns)
      sortModel && setSortModel([sortModel])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variableSortOrder])

  // hooks
  useEffect(() => {
    // ToDo: Optimize this useEffect.
    // useDeepCompareEffect
    if (skipQuery) {
      return
    }

    const variables = {
      ...getPaging(pagination ? { ...pagination } : { first: pageState.pageSize }),
      ...(pagination?.first && { first: pageState.pageSize }),
      ...(pagination?.last && { last: pageState.pageSize }),
      order: sortModel[0]?.orderInput ?? getDefaultSortModel(defaultOrder, columns)[0]?.orderInput,
      where: filter,
      ...queryVariables,
    }

    if (refetchWithDelay) {
      // ToDo: Check in prod how it works and add some delay if needed
      const handler = setTimeout(() => {
        refetch(variables)
      })

      return () => {
        clearTimeout(handler)
      }
    } else {
      refetch(variables)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sortModel, pageState.pageSize, queryVariables, skipQuery, refetch])

  useEffect(() => {
    if (pollInterval && pollInterval !== 0) {
      startPolling(pollInterval)
    } else {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [pollInterval, startPolling, stopPolling])

  const rows: Array<GridRowModel> = useMemo(() => {
    if (data?.[entityName]?.edges) {
      const dataArray = data[entityName].edges.map((row: any) => row.node)

      return dataArray
    } else {
      return []
    }
  }, [data, entityName])

  // handlers
  const { handleRowClick, handleSortModelChange, handleRefreshClick, handlePageChange } =
    useFplDataGridHandlers({
      onRowClick,
      data,
      entityName,
      pageState,
      fetchMore,
      sortModel,
      filter,
      queryVariables,
      setPageState,
      id,
      setSortModel,
      columns,
      refetch,
      defaultOrder,
    })

  useEffect(() => {
    if (showAppBarLoading) {
      isPageLoadingVar(loading)
    }
  }, [loading, showAppBarLoading])

  const hideOldData = () => {
    return !loading ? rows : []
  }

  const gridApiRef = useGridApiRef()
  if (gridApiRef.current && setGridRef) {
    setGridRef(gridApiRef)
  }

  const gridStylesOnLoading = loading
    ? {
        '& .MuiDataGrid-virtualScroller': {
          minHeight: '100px !important',
          overflow: 'hidden !important',
        },
      }
    : {}

  return (
    <Box sx={{ width: 1, ...rootSx }} height={rows.length === pageState.pageSize ? 800 : '100%'}>
      <DataGrid
        sx={{ width: 1, ...gridStylesOnLoading }}
        apiRef={gridApiRef}
        rows={isHideOldData ? hideOldData() : rows}
        columns={columns}
        loading={loading}
        components={{
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              No results found
            </Stack>
          ),
          LoadingOverlay: CustomLoadingOverlay,
          Toolbar: !hideToolbar ? CustomToolbar : undefined,
          Footer: footer ? footer.component : undefined,
        }}
        componentsProps={{
          toolbar: {
            caption: toolbar?.caption,
            refreshButton: toolbar?.refreshButton,
            leftSide: toolbar?.leftSide,
            rightSide: toolbar?.rightSide,
            onRefreshClick: handleRefreshClick,
          },
          ...(footer && {
            footer: { ...footer.componentProps },
          }),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibility(newModel, id)
          setColumnVisibilityModel(newModel)
        }}
        autoHeight={rows.length < pageState.pageSize}
        onStateChange={(v) => {
          setDensity(v.density.value)
          setDensityModel(v.density.value, id)
        }}
        density={density}
        onRowClick={handleRowClick}
        getRowHeight={getRowHeight}
        filterMode='server'
        disableColumnFilter
        disableRowSelectionOnClick={disableSelectionOnClick}
        paginationMode='server'
        paginationModel={pageState}
        pageSizeOptions={pageOptions.rowsPerPage}
        rowCount={rowCount}
        onPaginationModelChange={handlePageChange}
        sortingMode='server'
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        isRowSelectable={isRowSelectable}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={onSelectionModelChange}
        editMode='row'
        rowModesModel={rowModesModel}
        processRowUpdate={processRowUpdate}
        hideFooter={hideFooter}
      />
    </Box>
  )
}

export { FplDataGrid }
export type { IGridColumn }
