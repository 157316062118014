import React from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledTextField } from 'components/common'
import { UpdateEmailTemplateInput } from 'generated/graphql'

const EmailFormControls = () => {
  const {
    control,
    watch,
    trigger,
    formState: { isSubmitted, errors },
  } = useFormContext<UpdateEmailTemplateInput>()

  const isBlindCarbonCopyEnabledValue = watch('isBlindCarbonCopyEnabled')

  React.useEffect(() => {
    if (isSubmitted) {
      trigger('blindCarbonCopy')
    }
  }, [isBlindCarbonCopyEnabledValue, isSubmitted, trigger])

  return (
    <Grid container rowSpacing={1.5} columnSpacing={3} sx={{ marginBottom: 'unset' }}>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label='Subject'
          name='subject'
          defaultValue=''
          required
          error={!!errors.subject}
          helperText={errors.subject?.message}
        />
      </Grid>

      <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
        <ControlledCheckbox
          control={control}
          label='Add BCC'
          name='isBlindCarbonCopyEnabled'
          defaultValue={false}
        />
      </Grid>

      <Grid item xs={12} md={9}>
        <ControlledTextField
          control={control}
          label='BCC Email'
          name='blindCarbonCopy'
          defaultValue=''
          type='email'
          required={isBlindCarbonCopyEnabledValue}
          disabled={!isBlindCarbonCopyEnabledValue}
          error={!!errors.blindCarbonCopy}
          helperText={
            errors.blindCarbonCopy?.message ||
            'You can specify more than one email address by separating them with a semicolon (";")'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label='Message'
          name='message'
          defaultValue=''
          multiline
          rows={12}
          required
          error={!!errors.message}
          helperText={errors.message?.message}
        />
      </Grid>
    </Grid>
  )
}

export default EmailFormControls
