import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { GET_SCHEDULED_EXCHANGE_RATE_TASKS_QUERY } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'Id', width: 100 },
  { field: 'status', headerName: 'Status', minWidth: 150, flex: 1 },
  {
    field: 'createdAt',
    headerName: 'Created at',
    minWidth: 150,
    flex: 1,
    valueFormatter: ({ value }) => value && format(parseJSON(value), DATE_TIME_FORMAT),
  },
  { field: 'createdBy', headerName: 'Created by', minWidth: 150, flex: 1 },
]

const TaskHistoryPanel = () => {
  return (
    <FplDataGrid
      query={GET_SCHEDULED_EXCHANGE_RATE_TASKS_QUERY}
      entityName='scheduledExchangeRateTasks'
      columns={columns}
      defaultOrder={{ field: 'createdAt', sort: 'desc' }}
      toolbar={{ caption: 'Task History' }}
    />
  )
}

export default TaskHistoryPanel
