import { gql } from '@apollo/client'

import { getIsThemeLight, setIsThemeLight } from '../../helpers/localStorage'

export function toggleTheme(_obj, _args, { cache }) {
  const isThemeLight = !getIsThemeLight()
  setIsThemeLight(isThemeLight)

  cache.modify({
    fields: {
      isThemeLight: () => isThemeLight,
    },
  })
  return null
}

const TOGGLE_THEME = gql`
  mutation ToggleTheme {
    toggleTheme @client
  }
`

export { TOGGLE_THEME }
