import { useState } from 'react'

import { Box, Paper, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'

import PotentialBookingFiles from './PotentialBookingFiles'
import PotentialBookings from './PotentialBookings'
import PotentialBookingUpload from './PotentialBookingUpload'

function commonProps(index: TabId) {
  return {
    id: `potential-bookings-tab-${index}`,
    'aria-controls': `potential-bookings-tabpanel-${index}`,
  }
}

enum TabId {
  Upload = 0,
  Files,
  PotentialBookings,
}

const PotentialBookingTab = () => {
  const [tabValue, setTabValue] = useState(TabId.PotentialBookings)

  const tabs = {
    upload: { id: TabId.Upload, label: '1. Upload File' },
    process: { id: TabId.Files, label: '2. Process File' },
    create: { id: TabId.PotentialBookings, label: '3. Create Booking' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Paper elevation={3}>
      <Box
        p={2}
        sx={{
          display: 'flex',
          flexGrow: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          orientation='vertical'
          aria-label='settings tariffs tabs'
          variant='scrollable'
          sx={{
            boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
            width: (theme) => theme.spacing(27),
          }}>
          <Tab label={tabs.upload.label} key={tabs.upload.id} {...commonProps(tabs.upload.id)} />
          <Tab label={tabs.process.label} key={tabs.process.id} {...commonProps(tabs.process.id)} />
          <Tab label={tabs.create.label} key={tabs.create.id} {...commonProps(tabs.create.id)} />
        </Tabs>

        <VerticalTabPanel value={tabValue} index={tabs.upload.id} key={tabs.upload.id} px={3}>
          <PotentialBookingUpload />
        </VerticalTabPanel>

        <VerticalTabPanel value={tabValue} index={tabs.process.id} key={tabs.process.id} px={3}>
          <PotentialBookingFiles />
        </VerticalTabPanel>

        <VerticalTabPanel value={tabValue} index={tabs.create.id} key={tabs.create.id} px={3}>
          <PotentialBookings />
        </VerticalTabPanel>
      </Box>
    </Paper>
  )
}

export default PotentialBookingTab
