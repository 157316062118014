import { ReactNode } from 'react'

import { Box, Paper, Stack } from '@mui/material'
import { format } from 'date-fns'

import { DATE_FORMAT } from 'constants/index'
import { parseDateWithoutTimeZone } from 'helpers'

interface IProps {
  documentName: string
  createdAt?: Date
  children: ReactNode
}

// TODO: implement functionality for button

function DocumentActionPanel({ documentName, createdAt, children }: IProps) {
  return (
    <Paper elevation={4} sx={{ p: 2, height: 70 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' height='100%'>
        <Box>
          {documentName}
          {createdAt && format(parseDateWithoutTimeZone(createdAt.toISOString()), DATE_FORMAT)}
        </Box>
        {children}
      </Stack>
    </Paper>
  )
}

export default DocumentActionPanel
