import React from 'react'

import { usePermission, PermissionType } from './usePermission'

interface IProps {
  to: PermissionType
  loadingComponent?: JSX.Element | string
  fallback?: JSX.Element | string
  children?: React.ReactNode
}

const Restricted = (props: IProps) => {
  const { to, loadingComponent, fallback, children } = props

  const [allowed, loading] = usePermission(to)

  if (loading) {
    return <>{loadingComponent}</>
  }

  if (allowed) {
    return <>{children}</>
  }

  return <>{fallback}</>
}

export default Restricted
