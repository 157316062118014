import { useState } from 'react'

import { AppBar, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

import { TabPanel } from 'components/common'
import { useHashToSelectTab } from 'helpers'
import { Restricted, usePermission } from 'providers/PermissionProvider'

import { Drivers } from './drivers'
import { StrategicPartners } from './strategicPartners'

enum TabId {
  Drivers = 0,
  StrategicPartners,
}

const tabsList = [
  { id: TabId.Drivers, label: 'Drivers', hash: '#drivers' },
  { id: TabId.StrategicPartners, label: 'Strategic Partners', hash: '#strategic-partners' },
]

function commonProps(index: TabId) {
  return {
    id: `suppliers-tab-${index}`,
    'aria-controls': `suppliers-tabpanel-${index}`,
  }
}

const SuppliersTab = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)

  const [isAllowedToViewViewStrategicPartnerList] = usePermission('ViewStrategicPartnerList')

  const filteredTabsList = tabsList.filter((tab) => {
    if (tab.id === TabId.StrategicPartners) {
      return isAllowedToViewViewStrategicPartnerList
    }
    return true
  })

  useHashToSelectTab(tabsList, setTabValue)

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          aria-label='driver tabs'
          TabIndicatorProps={{
            style: {
              transition: 'none',
            },
          }}>
          {filteredTabsList.map((tab) => (
            <Tab
              label={tab.label}
              key={tab.id}
              {...commonProps(tab.id)}
              component={Link}
              to={{ hash: tab.hash }}
            />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={TabId.Drivers}>
        <Drivers />
      </TabPanel>
      <Restricted to='ViewStrategicPartnerList'>
        <TabPanel value={tabValue} index={TabId.StrategicPartners}>
          <StrategicPartners />
        </TabPanel>
      </Restricted>
    </div>
  )
}

export default SuppliersTab
