import Joi from 'joi'

import { EntityConstants } from 'constants/EntityConstants'
import { DEFAULT_COUNTRY_ID } from 'constants/index'
import { BookingStatus } from 'generated/graphql'
import { TBookingForm } from 'types'

import { validatePostcode } from './postcode.validation'
import {
  validateCollectionAt,
  validateCollectionBy,
  validateDeliveryAt,
  validateDeliveryBy,
} from './transit-time.validation'

const BookingDetailsValidation = Joi.object<TBookingForm>({
  // Booking Details
  customerId: Joi.string().empty(''),
  contactId: Joi.string().empty(''),
  customerReference: Joi.string()
    .empty('')
    .optional()
    .when('$mode', { is: 1, then: Joi.optional() })
    .when('$isQuote', { is: true, then: Joi.optional() })
    .when('$isQuickQuote', { is: true, then: Joi.optional() })
    .max(EntityConstants.MAX_CUSTOMER_REFERENCE_LENGTH),
  overrideContactEmail: Joi.string()
    .email({ multiple: true, separator: ';', tlds: false })
    .optional()
    .empty('')
    .max(EntityConstants.MAX_EMAIL_LENGTH),
  overrideContactTelephoneNumber: Joi.string()
    .empty('')
    .optional()
    .max(EntityConstants.MAX_TELEPHONE_NUMBER_LENGTH),
  noteOrSpecialInstruction: Joi.string().empty('').optional().max(EntityConstants.MAX_NOTE_LENGTH),
  alternativeOurReference: Joi.string()
    .empty('')
    .optional()
    .max(EntityConstants.MAX_GENERIC_TEXT_LENGTH),
  distance: Joi.number().when('$isQuickQuote', {
    is: false,
    then: Joi.number().greater(0).message('Distance must be greater than 0'),
  }),
  consignmentFee: Joi.number().empty(''),
  // ToDo: add message 'Tariff is required'
  tariffId: Joi.string().empty('').required(),

  collectionAddresses: Joi.array().items(
    Joi.object({
      at: Joi.date().empty('').optional().custom(validateCollectionAt, 'Transit Time validation'),
      by: Joi.date()
        .empty('')
        .optional()
        .greater(Joi.ref('at'))
        .message(`Field must be greater then 'Ready At'`)
        .custom(validateCollectionBy, 'Transit Time validation'),
      name: Joi.string().empty('').when('$isQuickQuote', { is: true, then: Joi.optional() }),
      city: Joi.string().empty('').when('$isQuickQuote', { is: true, then: Joi.optional() }),
      addressLine1: Joi.string()
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() }),
      addressLine2: Joi.string().empty('').optional(),
      postcode: Joi.string()
        .empty('')
        .label('Postcode')
        .custom(validatePostcode, 'Postcode validation')
        .max(8),
      countryId: Joi.number(),
      contact: Joi.string()
        .empty('')
        .optional()
        .when('countryId', {
          is: DEFAULT_COUNTRY_ID,
          then: Joi.optional(),
          otherwise: Joi.required(),
        })
        .when('$isAllowedToMakeCollectionAndDeliveryContactDataRequired', {
          is: true,
          then: Joi.required(),
        }),
      email: Joi.string().email({ tlds: false }).empty('').when('countryId', {
        is: DEFAULT_COUNTRY_ID,
        then: Joi.optional(),
        otherwise: Joi.required(),
      }),
      telephoneNumber: Joi.string()
        .empty('')
        .optional()
        .when('countryId', {
          is: DEFAULT_COUNTRY_ID,
          then: Joi.optional(),
          otherwise: Joi.required(),
        })
        .when('$isAllowedToMakeCollectionAndDeliveryContactDataRequired', {
          is: true,
          then: Joi.required(),
        }),
    }),
  ),

  deliveryAddresses: Joi.array().items(
    Joi.object({
      at: Joi.date().empty('').optional().custom(validateDeliveryAt, 'Transit Time validation'),
      by: Joi.date()
        .empty('')
        .optional()
        .greater(Joi.ref('at'))
        .message(`Field must be greater then 'Deliver From'`)
        .custom(validateDeliveryBy, 'Transit Time validation'),
      name: Joi.string().empty('').when('$isQuickQuote', { is: true, then: Joi.optional() }),
      city: Joi.string().empty('').when('$isQuickQuote', { is: true, then: Joi.optional() }),
      addressLine1: Joi.string()
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() }),
      addressLine2: Joi.string().empty('').optional(),
      postcode: Joi.string()
        .empty('')
        .label('Postcode')
        .custom(validatePostcode, 'Postcode validation')
        .max(8),
      countryId: Joi.number(),
      contact: Joi.string()
        .empty('')
        .optional()
        .when('countryId', {
          is: DEFAULT_COUNTRY_ID,
          then: Joi.optional(),
          otherwise: Joi.required(),
        })
        .when('$isAllowedToMakeCollectionAndDeliveryContactDataRequired', {
          is: true,
          then: Joi.required(),
        }),
      email: Joi.string().email({ tlds: false }).empty('').when('countryId', {
        is: DEFAULT_COUNTRY_ID,
        then: Joi.optional(),
        otherwise: Joi.required(),
      }),
      telephoneNumber: Joi.string()
        .empty('')
        .optional()
        .when('countryId', {
          is: DEFAULT_COUNTRY_ID,
          then: Joi.optional(),
          otherwise: Joi.required(),
        })
        .when('$isAllowedToMakeCollectionAndDeliveryContactDataRequired', {
          is: true,
          then: Joi.required(),
        }),
      reasonCodeId: Joi.number().empty(0).optional().when('....isReasonCodeEnabled', {
        is: true,
        then: Joi.required(),
      }),
    }),
  ),

  packages: Joi.array().items(
    Joi.object({
      quantity: Joi.number()
        .label('Quantity')
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
      packageType: Joi.string()
        .label('Package Type')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
      collectionSequenceOrder: Joi.number()
        .label('Collection')
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
      deliverySequenceOrder: Joi.number()
        .label('Delivery')
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
      length: Joi.number()
        .label('Length')
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
      width: Joi.number()
        .label('Width')
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
      height: Joi.number()
        .label('Height')
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
      weight: Joi.number()
        .label('Weight')
        .empty('')
        .when('$isQuickQuote', { is: true, then: Joi.optional() })
        .when('....isPackageDetailsSectionOptional', { is: true, then: Joi.optional() }),
    })
      .label('Package')
      .and(
        'quantity',
        'collectionSequenceOrder',
        'deliverySequenceOrder',
        'length',
        'width',
        'height',
        'weight',
      ),
  ),

  // ToDo: add other fields
  charges: Joi.array()
    .optional()
    .items(
      Joi.object({
        quantity: Joi.number().optional(),
      }),
    ),
}).when(
  Joi.object({
    status: Joi.valid(
      BookingStatus.ProofOfDelivery,
      BookingStatus.Complete,
      BookingStatus.Invoice,
      BookingStatus.ScheduledTemplate,
    ),
    isReferenceRequired: Joi.valid(true),
  }).unknown(),
  {
    then: Joi.object({ customerReference: Joi.required() }),
    otherwise: Joi.object({ customerReference: Joi.optional() }),
  },
)

export { BookingDetailsValidation }
