import React from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'
import Joi from 'joi'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { CardTitle } from 'components/common/controls'
import CustomerSignatureFormControls from 'components/settings/CustomerEmails/CustomerSignatureFormControls'
import EmailFormControls from 'components/settings/CustomerEmails/EmailFormControls'
import EmailTokens from 'components/settings/CustomerEmails/EmailTokens'
import DriverSignatureFormControl from 'components/settings/DriverEmails/DriverSignatureFormControl'
import DriverTermsFormControl from 'components/settings/DriverEmails/DriverTermsFormControl'
import {
  EmailTemplateType,
  GetEmailTemplatesQuery,
  UpdateEmailTemplateInput,
  useGetEmailTemplatesQuery,
  useGetEmailTemplateTokensQuery,
  useUpdateEmailTemplateMutation,
} from 'generated/graphql'

const emailFormSchema = Joi.object({
  subject: Joi.string().required(),
  message: Joi.string().required(),
  isBlindCarbonCopyEnabled: Joi.boolean().required(),
  blindCarbonCopy: Joi.string()
    .label('BCC')
    .when('isBlindCarbonCopyEnabled', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().allow('', null).optional(),
    }),
})

const signatureEmailFormSchema = Joi.object({
  message: Joi.string().required(),
})

interface IProps {
  type: EmailTemplateType
  label: string
}

const EmailTemplate = (props: IProps) => {
  const { type, label } = props
  const [emailTemplate, setEmailTemplate] =
    React.useState<GetEmailTemplatesQuery['emailTemplates'][0]>()

  const spacing = 3

  const schema = React.useMemo(() => {
    switch (type) {
      case EmailTemplateType.Signature:
      case EmailTemplateType.SignatureDriver:
      case EmailTemplateType.TermsDriver:
        return signatureEmailFormSchema
      default:
        return emailFormSchema
    }
  }, [type])

  const methods = useForm<UpdateEmailTemplateInput>({
    shouldUnregister: true,
    resolver: joiResolver(schema),
    defaultValues: {
      type,
      subject: '',
      message: '',
      isBlindCarbonCopyEnabled: false,
      blindCarbonCopy: '',
      iconUri: '',
    },
  })
  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods

  // Queries
  const { loading: emailTemplatesLoading } = useGetEmailTemplatesQuery({
    variables: {
      where: { type: { eq: type } },
    },
    onCompleted: (response) => {
      setEmailTemplate(response.emailTemplates[0])
    },
  })

  const { data: emailTemplateTokens, loading: emailTemplateTokensLoading } =
    useGetEmailTemplateTokensQuery()

  // Mutation
  const [updateEmailTemplate, { loading: updatingEmailTemplate }] = useUpdateEmailTemplateMutation({
    onCompleted: (response) => {
      if (!response) {
        toast.error('Something went wrong')
        return
      }

      toast.success(`Email Template was successfully updated`)
    },
  })

  React.useEffect(() => {
    reset({
      ...emailTemplate,
    })
  }, [reset, emailTemplate])

  const emailTemplateTokensFiltered = React.useMemo(
    () =>
      emailTemplateTokens?.emailTemplateTokens.filter((token) =>
        emailTemplate?.tokens.includes(token.value),
      ),
    [emailTemplate, emailTemplateTokens],
  )

  const onSubmit = (formData: UpdateEmailTemplateInput) => {
    updateEmailTemplate({
      variables: {
        input: {
          id: emailTemplate?.id,
          type,
          subject: formData.subject ?? '',
          message: formData.message,
          isBlindCarbonCopyEnabled: formData.isBlindCarbonCopyEnabled ?? false,
          blindCarbonCopy: formData.blindCarbonCopy,
          iconUri: formData.iconUri,
        },
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={spacing}>
          <Grid item xs={12} md={8}>
            <Paper variant='outlined' sx={{ padding: 2 }}>
              <CardTitle>{`${label} Details`}</CardTitle>
              {emailTemplatesLoading ? (
                <>
                  <Typography variant='h2'>
                    <Skeleton animation='wave' />
                    <Skeleton animation='wave' />
                  </Typography>
                  <Typography variant='h1'>
                    <Skeleton animation='wave' />
                  </Typography>
                </>
              ) : (
                <Box py={1}>
                  {(() => {
                    switch (type) {
                      case EmailTemplateType.Signature:
                        return <CustomerSignatureFormControls />
                      case EmailTemplateType.SignatureDriver:
                        return <DriverSignatureFormControl />
                      case EmailTemplateType.TermsDriver:
                        return <DriverTermsFormControl />
                      default:
                        return <EmailFormControls />
                    }
                  })()}
                </Box>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper variant='outlined' sx={{ padding: 2, height: '100%' }}>
              <CardTitle>Email Tokens</CardTitle>
              {emailTemplateTokensLoading ? (
                <>
                  <Box py={0.5}>
                    <Typography variant='h3'>
                      <Skeleton animation='wave' />
                      <Skeleton animation='wave' />
                    </Typography>
                  </Box>
                </>
              ) : (
                <EmailTokens tokens={emailTemplateTokensFiltered} />
              )}
            </Paper>
          </Grid>

          <Grid item container justifyContent='flex-end'>
            <Grid item>
              <LoadingButton
                variant='contained'
                color='primary'
                type='submit'
                loading={updatingEmailTemplate}
                disabled={!isDirty}>
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export { EmailTemplate }
