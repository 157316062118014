import { gql } from '@apollo/client'

export const TaskDetailsFragment = gql`
  fragment TaskDetails on Task {
    id
    bookingId
    booking {
      id
      ourReference
      status
    }
    description
    status
    endDate
    time
    timeTotalSeconds
    categoryId
    category {
      id
      name
      colour
    }
    assignedUser
    assignedUserName
    additionalDetail
  }
`
