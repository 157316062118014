import { ChangeEvent } from 'react'

import { Add } from '@mui/icons-material'
import {
  Grid,
  Tooltip,
  Button,
  Skeleton,
  Stack,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { Control, FieldErrors } from 'react-hook-form'

import {
  ControlledTextField,
  ControlledSelector,
  ControlledCheckbox,
  ControlledMultipleAutocomplete,
  ISelectorOptions,
  IMultipleAutocompleteOptions,
} from 'components/common'
import { TariffTypeValues, TariffBaseUnitValues } from 'constants/index'
import { CreateAndUpdateTariffDtoInput } from 'generated/graphql'



interface IProps {
  control: Control<CreateAndUpdateTariffDtoInput, any>
  errors: FieldErrors<CreateAndUpdateTariffDtoInput>
  isGenerateEnable: boolean | undefined | null | string
  handleGenerateTariffName: () => void
  xeroCodeLoading: boolean
  xeroCodes: IMultipleAutocompleteOptions
  customersLoading: boolean
  customers: IMultipleAutocompleteOptions
  allCustomers: boolean
  tariffCategoriesLoading: boolean
  tariffCategories: ISelectorOptions
  handleAddTariffCategory: () => void
  vatCategoriesLoading: boolean
  vatCategories: ISelectorOptions
  autoCalcMinMiles: boolean
  handleAutoCalcMinMiles: (event: ChangeEvent<HTMLInputElement>) => void
}

const DetailsForm = ({
  control,
  errors,
  isGenerateEnable,
  handleGenerateTariffName,
  xeroCodeLoading,
  xeroCodes,
  customersLoading,
  customers,
  allCustomers,
  tariffCategoriesLoading,
  tariffCategories,
  handleAddTariffCategory,
  vatCategoriesLoading,
  vatCategories,
  autoCalcMinMiles,
  handleAutoCalcMinMiles,
}: IProps) => {
  return (
    <Grid container columnSpacing={3} rowSpacing={2}>
      <Grid container item xs={12} lg={6} columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='name'
            label='Name'
            defaultValue=''
            required
            endAdornment={
              <Tooltip
                title={
                  isGenerateEnable
                    ? 'Generate Name based on Customer and Tariff Category'
                    : 'Select only one Customer and Tariff Category'
                }>
                <span>
                  <Button
                    color='grey'
                    disabled={!isGenerateEnable}
                    size='medium'
                    onClick={handleGenerateTariffName}>
                    Generate
                  </Button>
                </span>
              </Tooltip>
            }
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {xeroCodeLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <ControlledSelector
              control={control}
              name='xeroCodeId'
              label='Service Code'
              options={xeroCodes}
              error={!!errors.xeroCodeId}
              helperText={errors.xeroCodeId?.message}
            />
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={6} columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            name='isAttachedToAllCustomers'
            label='All Customers'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {customersLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <ControlledMultipleAutocomplete
              control={control}
              name='customerIds'
              label='Customers'
              options={customers}
              disabled={allCustomers}
              required={!allCustomers}
              limitTags={3}
              defaultValue={[]}
              error={!!errors.customerIds}
              helperText={errors.customerIds?.message}
            />
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={6} columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          {tariffCategoriesLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <Stack direction='row' alignItems='center' spacing={1}>
              <ControlledSelector
                control={control}
                name='tariffCategoryId'
                label='Tariff Category'
                options={tariffCategories}
                error={!!errors.tariffCategoryId}
                helperText={errors.tariffCategoryId?.message}
                required
              />
              <div>
                <IconButton
                  color='secondary'
                  aria-label='Create Tariff'
                  onClick={handleAddTariffCategory}>
                  <Add />
                </IconButton>
              </div>
            </Stack>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {vatCategoriesLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <ControlledSelector
              control={control}
              name='vatCategoryId'
              label='Vat Category'
              defaultValue=''
              options={vatCategories}
              error={!!errors.vatCategoryId}
              helperText={errors.vatCategoryId?.message}
              required
            />
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={6} columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            name='baseUnit'
            label='Base Unit'
            options={TariffBaseUnitValues}
            required
            error={!!errors.baseUnit}
            helperText={errors.baseUnit?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='minCharge'
            label='Min Charge'
            defaultValue=''
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            sanitizedNumber
            min='0'
            endAdornment='£'
            required
            error={!!errors.minCharge}
            helperText={errors.minCharge?.message}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={6} columnSpacing={3}>
        <Grid item xs={12} sm={3}>
          <ControlledTextField
            control={control}
            name='rate'
            label='Rate'
            defaultValue=''
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            sanitizedNumber
            endAdornment='£/mile'
            min='0'
            required
            error={!!errors.rate}
            helperText={errors.rate?.message}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={autoCalcMinMiles}
                onChange={handleAutoCalcMinMiles}
              />
            }
            label='Auto-calculate Min Miles'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='xeroServiceCode'
            label='Secondary Service Code'
            defaultValue=''
            error={!!errors.xeroServiceCode}
            helperText={errors.xeroServiceCode?.message}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={6} columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='minMiles'
            label='Min Miles'
            defaultValue=''
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            sanitizedNumber
            min='0'
            required
            readOnly={autoCalcMinMiles}
            error={!!errors.minMiles}
            helperText={errors.minMiles?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            name='type'
            label='Type'
            options={TariffTypeValues}
            error={!!errors.type}
            helperText={errors.type?.message}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DetailsForm
