import { useMemo } from 'react'

import { Box } from '@mui/material'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { format } from 'date-fns'

import { DATE_FORMAT } from 'constants/index'
import { BookingExchangeRate } from 'generated/graphql'

interface IProps {
  exchangeRates: BookingExchangeRate[]
  loading?: boolean
}

const BookingRatesDataGrid = (props: IProps) => {
  const { exchangeRates, loading } = props

  const columns: GridColDef[] = [
    { field: 'sourceCode', headerName: 'Source Currency', width: 150 },
    { field: 'targetCode', headerName: 'Target Currency', width: 150 },
    { field: 'rate', headerName: 'Rate', width: 150 },
    { field: 'stage', headerName: 'Stage', width: 150 },
    { field: 'date', headerName: 'Exchange Date', width: 150 },
  ]

  const rows: GridRowsProp = useMemo(() => {
    if (exchangeRates.length > 0) {
      return exchangeRates.map((rate) => ({
        id: rate.id,
        sourceCode: rate.exchangeRate.sourceCurrency.code,
        targetCode: rate.exchangeRate.targetCurrency.code,
        rate: rate.exchangeRate.rate,
        stage: rate.stage,
        date: format(new Date(rate.exchangeRate.createdAt), DATE_FORMAT),
      }))
    }

    return []
  }, [exchangeRates])

  return (
    <Box sx={{ height: 250 }}>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={loading}
        hideFooterPagination
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default BookingRatesDataGrid
