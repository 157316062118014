import React, { useEffect, useState } from 'react'

import { CircularProgress, FormControlLabel, Switch } from '@mui/material'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  useDeleteOpenQueryByAccountPayableRecordIdMutation,
  useGetAccountPayableRecordQuery,
} from 'generated/graphql'

import QueryCreationDialog from './QueryCreationDialog'

const QueryStatusSwitcher = () => {
  const { id } = useParams<{ id?: string }>()

  const [openQueryCreationDialog, setOpenQueryCreationDialog] = useState(false)
  const [activeQuerySwitch, setActiveQuerySwitch] = useState(false)
  const [queryStatusString, setQueryStatusString] = useState('')

  // Queries
  const { data: accountPayableRecord, loading } = useGetAccountPayableRecordQuery({
    variables: { id: Number(id) },
    fetchPolicy: 'network-only',
  })

  // Mutations
  const [deleteOpenQueryByAccountPayableRecordId] =
    useDeleteOpenQueryByAccountPayableRecordIdMutation({
      onCompleted: (response) => {
        if (!response) {
          toast.error('Something went wrong.')
          return
        }

        setActiveQuerySwitch(false)
      },
    })

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!activeQuerySwitch) {
      setOpenQueryCreationDialog(event.target.checked)
      setActiveQuerySwitch(event.target.checked)
    } else {
      deleteOpenQueryByAccountPayableRecordId({
        variables: {
          accountPayableRecordId: Number(id),
        },
      })
    }
  }

  useEffect(() => {
    const openQueries = accountPayableRecord?.accountPayableRecord?.openQueries
    setActiveQuerySwitch(
      openQueries !== undefined && openQueries !== null ? openQueries.length > 0 : false,
    )
  }, [accountPayableRecord])

  useEffect(() => {
    if (!activeQuerySwitch) {
      setQueryStatusString('Query Status No Opened Query')
      return
    }
    setQueryStatusString('Query Status Active')
  }, [activeQuerySwitch])

  return (
    <>
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <FormControlLabel
          label={queryStatusString}
          control={
            <Switch onChange={(event) => handleSwitchChange(event)} checked={activeQuerySwitch} />
          }
        />
      )}

      {openQueryCreationDialog && (
        <QueryCreationDialog
          open={openQueryCreationDialog}
          setOpen={setOpenQueryCreationDialog}
          setActiveQuerySwitch={setActiveQuerySwitch}
        />
      )}
    </>
  )
}

export default QueryStatusSwitcher
