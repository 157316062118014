import { useState, useEffect, useMemo } from 'react'

import { Grid, Paper, Dialog, Typography, DialogContent, DialogActions, Box } from '@mui/material'

import { PoBookingDetails } from 'components/bookings/BookingDetails'
import PodDetailsActions from 'components/bookings/BookingDetails/PodDetails/PodDetailsActions'
import { CardTitle } from 'components/common/controls'
import { FplDialogTitle } from 'components/common/Dialog'
import { BookingAddressType, BookingAddress } from 'generated/graphql'
import usePodDetailsFunction from 'hooks/usePodDetailsFunctions'
import { usePermission } from 'providers'

import { PoAddressEta } from '../PoAddressEta'
import { PoAttachments } from '../PoAttachments'
import WaitingTimeChargeDetails from './WaitingTimeChargeDetails'
import WaitingTimeDetails from './WaitingTimeDetails'

interface IProps {
  bookingId: string
  openDialog: boolean
  hoverText: string
  disabled: boolean
  handleCloseDialog: () => void
}

const PodDetails = (props: IProps) => {
  const { bookingId, openDialog, hoverText, disabled, handleCloseDialog } = props
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)
  const [uploadingFiles, setUploadingFiles] = useState(false)
  const [dropzoneKey, setDropzoneKey] = useState<number>(0)
  const [newFiles, setNewFiles] = useState<File[]>([])

  const {
    reset,
    handleSubmit,
    control,
    watch,
    errors,
    isDirty,
    getAdminBooking,
    adminBookingData,
    getUserBooking,
    userBookingData,
    attachmentsLoading,
    attachmentsData,
    refetchAttachments,
    updatingPod,
    sendingPodEmail,
    handleSelectedAddressIdChange,
    handleResendPODEmail,
    handleOnUpdate,
    handleFullScreen,
    handleCancel,
    handleFilesChanged,
    handleUploadFiles,
  } = usePodDetailsFunction({
    selectedAddressId,
    bookingId,
    handleCloseDialog,
    setSelectedAddressId,
    setIsFullScreen,
    isFullScreen,
    setNewFiles,
    newFiles,
    setUploadingFiles,
    setDropzoneKey,
    dropzoneKey,
  })

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')
  useEffect(() => {
    if (isAllowedToViewBookingFee) {
      getAdminBooking({
        variables: { bookingId: Number(bookingId) },
      })
    } else {
      getUserBooking({
        variables: { bookingId: Number(bookingId) },
      })
    }
  }, [bookingId, isAllowedToViewBookingFee, getAdminBooking, getUserBooking])

  const booking = adminBookingData?.booking || userBookingData?.userBooking

  const deliveryAddresses = useMemo(
    () =>
      booking?.addresses
        ?.filter((address) => address.type === BookingAddressType.Delivery)
        .sort((a, b) => a.sequenceOrder - b.sequenceOrder),
    [booking],
  )

  const bookingPackages = useMemo(
    () => booking?.packages.slice().sort((a, b) => Number(a.id) - Number(b.id)),
    [booking],
  )

  useEffect(() => {
    if (selectedAddressId === null && deliveryAddresses) {
      setSelectedAddressId(deliveryAddresses[0].id)
    }
  }, [deliveryAddresses, selectedAddressId])

  const selectedAddress = useMemo(() => {
    const address = deliveryAddresses?.find((x) => x.id === selectedAddressId)
    if (address) {
      return address
    }
  }, [deliveryAddresses, selectedAddressId])

  useEffect(() => {
    const addressPackages = selectedAddress?.allocations?.map((allocation) => ({
      packageId: String(allocation.packageId),
      totalPackages: allocation.quantity < 0 ? 0 : allocation.quantity,
      quantity: allocation.package.quantity - allocation.quantity,
    }))

    reset({
      signedAt: selectedAddress?.signedAt,
      waitingTime: selectedAddress?.waitingTime ?? null,
      signedByFirstName: selectedAddress?.signedByFirstName ?? '',
      signedByLastName: selectedAddress?.signedByLastName ?? '',
      packages: addressPackages,
      arrivedAt: selectedAddress?.arrivedAt,
      driverTimeFrom: selectedAddress?.driverTimeFrom,
      driverTimeTo: selectedAddress?.driverTimeTo,
      noteFromDriver: selectedAddress?.noteFromDriver ?? '',
      waitingTimeCharge: selectedAddress?.waitingTimeCharge,
    })
  }, [bookingPackages, selectedAddress, reset])

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      fullScreen={isFullScreen}
      open={openDialog}
      aria-label='pod-dialog'>
      <FplDialogTitle
        id='pod-dialog'
        isFullScreen={isFullScreen}
        onClose={handleCloseDialog}
        onFullScreen={handleFullScreen}>
        <Typography paragraph variant='h4'>
          Proof Of Delivery (POD)
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <CardTitle disabled={disabled}>Booking Details</CardTitle>
                <PoBookingDetails
                  bookingDetails={booking}
                  hoverText={hoverText}
                  disabled={disabled}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <CardTitle disabled={disabled}>POD / Waiting Time Details</CardTitle>
                <WaitingTimeDetails
                  deliveryAddresses={deliveryAddresses}
                  bookingPackages={bookingPackages}
                  selectedAddress={selectedAddress}
                  onSelectedAddressIdChange={handleSelectedAddressIdChange}
                  control={control}
                  watch={watch}
                  errors={errors}
                  hoverText={hoverText}
                  disabled={disabled}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            {selectedAddress?.sequenceOrder && (
              <PoAddressEta
                variables={{
                  sequenceOrder: selectedAddress.sequenceOrder,
                  addressId: Number(selectedAddress.id),
                  eTA: selectedAddress.eTA,
                }}
                disabled={disabled || !selectedAddress?.sequenceOrder}
                hoverText={hoverText}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <CardTitle disabled={disabled}>Booking Waiting Time Charge Details</CardTitle>
                <WaitingTimeChargeDetails hoverText={hoverText} disabled={disabled} />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <PoAttachments
              dropzoneKey={dropzoneKey}
              data={attachmentsData?.bookingAddress as BookingAddress}
              refetch={refetchAttachments}
              disabled={disabled}
              loading={attachmentsLoading}
              hoverText={hoverText}
              onFilesChanged={handleFilesChanged}
              uploadButtonProps={{
                onClick: handleUploadFiles,
                disabled: newFiles.length === 0,
                uploading: uploadingFiles,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <PodDetailsActions
          isDirty={isDirty}
          handleCancel={handleCancel}
          uploadingFiles={uploadingFiles}
          sendingPodEmail={sendingPodEmail}
          handleResendPODEmail={handleResendPODEmail}
          disabled={disabled}
          updatingPod={updatingPod}
          handleSubmit={handleSubmit(handleOnUpdate)}
        />
      </DialogActions>
    </Dialog>
  )
}

export default PodDetails
