import { useState } from 'react'

import { AppBar, Stack, Tab, Tabs, Tooltip } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

import { TabPanel } from 'components/common'
import { Mode, PARAM_NEW } from 'constants/index'
import { isNumber, CloneProps, useHashToSelectTab } from 'helpers'
import { Restricted, usePermission } from 'providers/PermissionProvider'

import { Contacts } from './Contacts'
import { DriverAccountsPayable } from './DriverAccountsPayable'
import { DriverDetails } from './DriverDetails'
import { BookingDriverRatingsTab } from './DriverDetails'
import { DriverInvoices } from './DriverInvoices'
import { useGetCurrentDriver } from './hooks'
import { SubDrivers } from './SubDrivers'
import { Vehicles } from './Vehicles'

enum TabId {
  Details = 0,
  Vehicles,
  SubDrivers,
  Contacts,
  Invoices,
  AccountsPayable,
  Ratings,
}

const tabsList = [
  { id: TabId.Details, label: 'Driver Details', hash: '$driver-details' },
  { id: TabId.Vehicles, label: 'Vehicles', hash: '#vehicles' },
  { id: TabId.SubDrivers, label: 'Sub-drivers', hash: '#sub-drivers' },
  { id: TabId.Contacts, label: 'Contacts', hash: '#contacts' },
  { id: TabId.Invoices, label: 'Invoices', hash: '#invoices' },
  { id: TabId.AccountsPayable, label: 'Accounts Payable', hash: '#accountsPayable' },
  { id: TabId.Ratings, label: 'Ratings', hash: '#ratings' },
]

const primaryTab = tabsList[0]
const secondaryTabs = tabsList.filter((_, index) => index !== 0)

function commonProps(index: TabId) {
  return {
    id: `driver-tab-${index}`,
    'aria-controls': `driver-tabpanel-${index}`,
  }
}

function DriverTab() {
  const [tabValue, setTabValue] = useState<number | false>(false)
  const { id } = useParams<{ id?: string }>()

  const [isAllowedToViewDriverInvoices] = usePermission('ViewDriverInvoices')
  const [isAllowedToViewDriverRatings] = usePermission('ViewAccountsPayableRecordDocumentActions')

  const permissionsMap = {
    [TabId.Invoices]: isAllowedToViewDriverInvoices,
    [TabId.Ratings]: isAllowedToViewDriverRatings,
  }

  function getTabPermission(tabId) {
    if (permissionsMap[tabId] === undefined || permissionsMap[tabId]) {
      return 'block'
    } else {
      return 'none'
    }
  }

  useHashToSelectTab(tabsList, setTabValue)

  let mode: number | undefined

  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  let driverId: number | null = mode === Mode.Update ? Number(id) : null

  //if user role is driver
  const { data, isDriver } = useGetCurrentDriver()

  if (isDriver) {
    driverId = Number(data?.driverProfile.id)
    mode = Mode.Update
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          aria-label='driver tabs'
          variant='scrollable'
          TabIndicatorProps={{
            style: {
              transition: 'none',
            },
          }}>
          <Tab
            label={primaryTab.label}
            {...commonProps(primaryTab.id)}
            component={Link}
            to={{ hash: primaryTab.hash }}
            sx={{ display: 'block' }}
          />
          {mode === Mode.Create ? (
            <CloneProps>
              {(tabProps) => (
                <Tooltip title='Available only for existing driver.'>
                  <Stack direction='row'>
                    {secondaryTabs.map((tab) => (
                      <Tab
                        {...tabProps}
                        label={tab.label}
                        key={tab.id}
                        {...commonProps(tab.id)}
                        disabled
                      />
                    ))}
                  </Stack>
                </Tooltip>
              )}
            </CloneProps>
          ) : (
            secondaryTabs.map((tab) => (
              <Tab
                label={tab.label}
                key={tab.id}
                {...commonProps(tab.id)}
                component={Link}
                to={{ hash: tab.hash }}
                sx={{
                  display: getTabPermission(tab.id),
                }}
              />
            ))
          )}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={TabId.Details}>
        <DriverDetails driverId={driverId} mode={mode} />
      </TabPanel>
      {mode === Mode.Update && (
        <>
          <TabPanel value={tabValue} index={TabId.Vehicles}>
            <Vehicles driverId={driverId} />
          </TabPanel>
          <TabPanel value={tabValue} index={TabId.SubDrivers}>
            <SubDrivers driverId={driverId as number} />
          </TabPanel>
          <TabPanel value={tabValue} index={TabId.Contacts}>
            <Contacts driverId={driverId!} />
          </TabPanel>
          <Restricted to='ViewDriverInvoices'>
            <TabPanel value={tabValue} index={TabId.Invoices}>
              <DriverInvoices driverId={driverId as number} />
            </TabPanel>
          </Restricted>
          <TabPanel value={tabValue} index={TabId.AccountsPayable}>
            <DriverAccountsPayable driverId={driverId as number} />
          </TabPanel>
          <Restricted to='ViewUpdateBookingDriverRating'>
            <TabPanel value={tabValue} index={TabId.Ratings}>
              <BookingDriverRatingsTab />
            </TabPanel>
          </Restricted>
        </>
      )}
    </div>
  )
}

export default DriverTab
