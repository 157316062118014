import React from 'react'

import { LoadingBackdrop } from 'components/common'
import { CreateAndUpdateTariffDtoInput, Tariff, useGetTariffQuery } from 'generated/graphql'

import TariffForm from './TariffForm'

interface IProps {
  id: string
  tariff?: CreateAndUpdateTariffDtoInput
  setTariff: React.Dispatch<React.SetStateAction<any>>
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>
}

const UpdateTariff = (props: IProps) => {
  const { id, tariff, setTariff, setNotFound } = props

  const { loading: tariffLoading } = useGetTariffQuery({
    variables: {
      id: Number(id),
    },
    onError: () => {
      setNotFound(true)
    },
    onCompleted: (data) => {
      const { __typename, id, customers, ...tariff } = data.tariffById as Tariff
      const customerIds = customers
        ? customers.map((c) => ({ value: c.customer.id, label: c.customer.name }))
        : []

      setTariff({ customerIds, id, ...tariff })
    },
  })

  return (
    <>
      <TariffForm tariff={tariff} />
      <LoadingBackdrop loading={tariffLoading} />
    </>
  )
}

export default UpdateTariff
