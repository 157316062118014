import React, { useEffect, useMemo} from 'react'

import { Grid} from '@mui/material'
import { Skeleton } from '@mui/material'
import { endOfDay, startOfDay } from 'date-fns'
import { Controller, useFormContext } from 'react-hook-form'

import {
  LoadingBackdrop,
  ControlledCheckbox,
  ControlledTextField,
  ControlledMultipleAutocomplete,
  ControlledTimePicker,
  ControlledAutocomplete,
} from 'components/common'
import {
  CreateAndUpdateCongestionZoneDtoInput,
  CreateAndUpdateCongestionZonePostcodeDtoInput,
  Tariff,
  TimeZone,
  useGetTariffsQuery,
  useGetTimeZonesQuery,
  Weekday,
} from 'generated/graphql'

import Postcodes, { IPostcodeActions } from './Postcodes'
import WeekdaysGroup from './WeekdaysGroup'

interface IProps {
  processing: boolean
  selectedWeekdays: Array<Weekday>
  setSelectedWeekdays: React.Dispatch<React.SetStateAction<Array<Weekday>>>
  postcodes: Array<CreateAndUpdateCongestionZonePostcodeDtoInput>
  postcodeActions: IPostcodeActions
}

const getTariffValues = (tariffs: Tariff[] = []) => {
  return tariffs.map((c) => ({ value: c.id, label: c.name }))
}

const getTimeZoneValues = (timezones: TimeZone[] = []) => {
  return timezones?.map((c) => ({ value: c.id, label: c.description }))
}

export default function CongestionZoneForm(props: IProps) {
  const { processing, selectedWeekdays, setSelectedWeekdays, postcodes, postcodeActions } = props
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateCongestionZoneDtoInput>()

  useEffect(
    () => setValue('weekdays', selectedWeekdays, { shouldDirty: true }),
    [setValue, selectedWeekdays],
  )

  const { loading: tariffLoading, data: tariffData } = useGetTariffsQuery()
  const { loading: timeZonesLoading, data: timeZonesData } = useGetTimeZonesQuery()

  const tariffs = useMemo(() => getTariffValues(tariffData?.tariffs as Tariff[]), [tariffData])
  const timeZones = useMemo(
    () => getTimeZoneValues(timeZonesData?.timeZones as TimeZone[]),
    [timeZonesData],
  )

  const allTariffs: boolean = watch('isAttachedToAllTariffs', false)
  const twentyFourHours = watch('is24Hours')

  useEffect(() => {
    if (twentyFourHours) {
      setValue('durationFrom', startOfDay(new Date()), { shouldDirty: true })
      setValue('durationTo', endOfDay(new Date()), { shouldDirty: true })
    }
  }, [setValue, twentyFourHours])
  return (
    <>
      <LoadingBackdrop loading={processing} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name='name'
            label='Name'
            control={control}
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name='customerCharge'
            label='Customer Charge'
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            sanitizedNumber
            control={control}
            required
            error={!!errors.customerCharge}
            helperText={errors.customerCharge?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledCheckbox control={control} name='is24Hours' label='24 Hours' />
        </Grid>
        <Grid item xs={12} sm={6}>
          {timeZonesLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <ControlledAutocomplete
              control={control}
              label='Time Zone'
              name='timeZoneId'
              options={timeZones}
              required
              loading={timeZonesLoading}
              disabled={false}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTimePicker
            control={control}
            name='durationFrom'
            label='Duration From'
            disabled={twentyFourHours}
            required={!twentyFourHours}
            error={!!errors.durationFrom}
            helperText={errors.durationFrom?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTimePicker
            control={control}
            name='durationTo'
            label='Duration To'
            disabled={twentyFourHours}
            required={!twentyFourHours}
            error={!!errors.durationTo}
            helperText={errors.durationTo?.message}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ControlledCheckbox control={control} name='isAttachedToAllTariffs' label='All Tariffs' />
        </Grid>
        <Grid item xs={12} sm={3}>
          {tariffLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <ControlledMultipleAutocomplete
              control={control}
              name='tariffIds'
              label='Tariffs'
              options={tariffs}
              disabled={allTariffs}
              limitTags={3}
              required={!allTariffs}
              defaultValue={[]}
            />
          )}
        </Grid>
        <Grid item xs={6} sm={3}>
          <ControlledCheckbox
            control={control}
            name='isAppliedAutomatically'
            label='Apply Automatically'
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ControlledTextField
            control={control}
            name='xeroServiceCode'
            label='Service Code'
            defaultValue=''
            error={!!errors.xeroServiceCode}
            helperText={errors.xeroServiceCode?.message}
          />
        </Grid>
        <Grid item container direction='row' justifyContent='center' alignItems='center'>
          <Grid item>
            <Controller
              control={control}
              name='weekdays'
              render={() => (
                <WeekdaysGroup
                  selectedWeekdays={selectedWeekdays}
                  setSelectedWeekdays={setSelectedWeekdays}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Postcodes postcodes={postcodes} postcodeActions={postcodeActions} />
        </Grid>
      </Grid>
    </>
  )
}
