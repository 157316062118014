import { useCallback, useMemo } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledDatePicker, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT, JoiValidationOptions } from 'constants/index'
import {
  CreateScheduledBookingDateDtoInput,
  SortEnumType,
  useCreateScheduledBookingDateMutation,
  useDeleteScheduledBookingDateMutation,
} from 'generated/graphql'
import { GET_SCHEDULED_BOOKING_DATES } from 'graphql/queries'
import { ScheduledDatesValidation } from 'validation-schemas/scheduledbooking/scheduleddates.validation'

interface IProps {
  templateId: string | null
}

const ScheduledDates = (props: IProps) => {
  const { templateId } = props

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateScheduledBookingDateDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(ScheduledDatesValidation, JoiValidationOptions),
    defaultValues: {
      executeOnDate: new Date(),
    },
  })

  const [createScheduledBookingDate] = useCreateScheduledBookingDateMutation({
    refetchQueries: [
      {
        query: GET_SCHEDULED_BOOKING_DATES,
        variables: {
          templateId: Number(templateId),
          after: null,
          before: null,
          first: null, // This was 10
          last: null,
          order: { executeOnDate: SortEnumType.Desc },
        },
      },
    ],
    onCompleted: (data) => {
      if (data.createScheduledBookingDate) {
        toast.success(`Date(s) was created with success`)
      }
    },
  })

  const onSubmit = (data: CreateScheduledBookingDateDtoInput) => {
    createScheduledBookingDate({
      variables: {
        input: { ...data, templateId: Number(templateId) },
      },
    })
  }

  const [deleteDate, { loading: deleting }] = useDeleteScheduledBookingDateMutation()

  // ToDo: add confirmation dialog
  const handleDeleteDate = useCallback(
    (id: GridRowId) => () => {
      deleteDate({
        variables: {
          dateId: Number(id),
        },
      }).then((response) => {
        if (response.data) {
          toast.success(`Date ${id} was deleted with success!`)
        }
      })
    },
    [deleteDate],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      {
        field: 'executeOnDate',
        headerName: 'Execute On',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
      },
      { field: 'taskId', headerName: 'Task ID', minWidth: 150, flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleting}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteDate(id)}
          />,
        ],
      },
    ],
    [deleting, handleDeleteDate],
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={2}>
            <ControlledDatePicker
              control={control}
              name='executeOnDate'
              label='Select Date'
              error={Boolean(errors.executeOnDate)}
              helperText={errors.executeOnDate?.message}
              required
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Box display='flex' alignItems='center' height='100%'>
              <Button type='submit' variant='contained' color='primary'>
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      <Box mt={2}>
        <FplDataGrid
          query={GET_SCHEDULED_BOOKING_DATES}
          queryVariables={{ templateId: Number(templateId) }}
          entityName='scheduledBookingDates'
          columns={columns}
          defaultOrder={{ field: 'executeOnDate', sort: 'desc' }}
          toolbar={{ caption: 'Dates' }}
        />
      </Box>
    </>
  )
}

export default ScheduledDates
