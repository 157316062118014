import { LoadingButton } from '@mui/lab'
import { Stack, Box, Grid, FormControlLabel, Switch } from '@mui/material'
import { FormProvider, SubmitHandler } from 'react-hook-form'

import { Restricted } from 'providers'

import DistanceAndConsignmentFee from './DistanceAndConsignmentFee'
import DraggableAddresses from './DraggableAddresses'
import { IOrderableAddressListItem } from './MapWithReorder'

interface IProps {
  methods: any
  bookingDetails: any
  isOptimizeWaypoints: boolean
  optimizedAddresses: IOrderableAddressListItem[]
  addressItems: IOrderableAddressListItem[]
  isQuote: boolean
  updatingSequenceOrder: boolean
  handleOnDragEnd: (result) => void
  handleOptimizeWaypointsChange: (event) => void
  handleOnSaveClick: SubmitHandler<any>
}

const MapAddressesForm = ({
  methods,
  bookingDetails,
  isOptimizeWaypoints,
  optimizedAddresses,
  addressItems,
  isQuote,
  updatingSequenceOrder,
  handleOnDragEnd,
  handleOptimizeWaypointsChange,
  handleOnSaveClick,
}: IProps) => {
  const { handleSubmit } = methods
  return (
    <Stack justifyContent='space-between' sx={{ height: { lg: '100%' } }}>
      <FormProvider {...methods}>
        <Stack>
          <Box pt={3} px={3}>
            <DistanceAndConsignmentFee bookingDetails={bookingDetails} />
          </Box>

          <Box p={1}>
            <Restricted
              to='ChangeBookingMapRouteAddressesOrder'
              fallback={
                <DraggableAddresses
                  items={isOptimizeWaypoints ? optimizedAddresses : addressItems}
                  readOnly={!isQuote}
                  disabled={isOptimizeWaypoints}
                  onDragEnd={handleOnDragEnd}
                />
              }>
              <DraggableAddresses
                items={isOptimizeWaypoints ? optimizedAddresses : addressItems}
                disabled={isOptimizeWaypoints}
                onDragEnd={handleOnDragEnd}
              />
            </Restricted>
          </Box>
        </Stack>

        <Box p={3}>
          <Grid container justifyContent='space-between' spacing={1.5}>
            <Grid item>
              <Restricted
                to='ChangeBookingMapRouteAddressesOrder'
                fallback={
                  isQuote ? (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isOptimizeWaypoints}
                          onChange={handleOptimizeWaypointsChange}
                          color='primary'
                        />
                      }
                      label='Optimize Waypoints'
                    />
                  ) : undefined
                }>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isOptimizeWaypoints}
                      onChange={handleOptimizeWaypointsChange}
                      color='primary'
                    />
                  }
                  label='Optimize Waypoints'
                />
              </Restricted>
            </Grid>

            <Grid item ml='auto'>
              <Restricted
                to='UpdateBookingMapRouteAddressesOrder'
                fallback={
                  isQuote ? (
                    <LoadingButton
                      variant='contained'
                      loading={updatingSequenceOrder}
                      onClick={handleSubmit(handleOnSaveClick)}>
                      Save
                    </LoadingButton>
                  ) : undefined
                }>
                <LoadingButton
                  variant='contained'
                  loading={updatingSequenceOrder}
                  onClick={handleSubmit(handleOnSaveClick)}>
                  Save
                </LoadingButton>
              </Restricted>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Stack>
  )
}

export default MapAddressesForm
