import { Box, Grid } from '@mui/material'
import {
  ControlledCheckbox,
  ControlledCurrencyAutocomplete,
  ControlledTextField,
  LoadingBackdrop,
} from 'components/common'
import {
  CreateAndUpdateBookingDriverDtoInput,
  useGetCurrenciesQuery,
  useGetDriverCurrencyQuery,
} from 'generated/graphql'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

interface IProps {
  loading: boolean
  driverId: number
}

export const EditDriverForm = (props: IProps) => {
  const { loading, driverId } = props
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateBookingDriverDtoInput>()

  // Queries
  const { data: currenciesData, loading: currenciesLoading } = useGetCurrenciesQuery()

  const { data: driverCurrency } = useGetDriverCurrencyQuery({
    variables: { driverId: driverId },
    skip: !driverId,
  })

  const currencyId = driverCurrency?.driver?.currencyId

  const readSelectedCurrency = (id: number) => {
    if (currencyId && id && currencyId !== id) {
      toast.warning(
        'The currency is already defined for the Supplier. Altering it could result in payment failure.',
      )
    }
  }

  return (
    <>
      <Box pt='6px'>
        <Grid container columnSpacing={3} rowSpacing={1.5}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Call Sign'
              name='driver.callSign'
              readOnly
              defaultValue=''
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Driver'
              name='driver.name'
              readOnly
              defaultValue=''
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Vehicle'
              name='vehicle.vehicleType.name'
              readOnly
              defaultValue=''
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Contact'
              name='driverContact.name'
              readOnly
              defaultValue=''
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Charge'
              name='charge'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              defaultValue=''
              error={!!errors.charge}
              helperText={errors.charge?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Extra Pay'
              name='extraPayment'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              defaultValue=''
              error={!!errors.extraPayment}
              helperText={errors.extraPayment?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Purch. Code'
              name='xeroPurchaseCode'
              defaultValue=''
              error={!!errors.xeroPurchaseCode}
              helperText={errors.xeroPurchaseCode?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledCurrencyAutocomplete
              control={control}
              options={currenciesData?.currencies || []}
              loading={currenciesLoading}
              error={!!errors.currencyId}
              helperText={errors.currencyId?.message}
              readSelectedCurrency={readSelectedCurrency}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledCheckbox
              control={control}
              label='Show Instruction to Driver'
              name='isShownInstruction'
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Instruction'
              name='instruction'
              multiline
              rows={7}
              error={!!errors.instruction}
              helperText={errors.instruction?.message}
            />
          </Grid>
        </Grid>
      </Box>

      <LoadingBackdrop loading={loading} />
    </>
  )
}
