import { ApolloProvider } from '@apollo/client'
import { OidcProvider, useOidc, useOidcAccessToken } from '@axa-fr/react-oidc-context'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import gbLocale from 'date-fns/locale/en-GB'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Auth, AuthenticatingPage, SessionLost } from 'components/auth'
import { AppTheme } from 'constants/index'
import { DialogProvider, ConfirmDialogProvider, PermissionProvider } from 'providers'
import { reactPlugin } from 'services/ApplicationInsights'

import client from '../apolloClient'
import { LoadingBackdrop } from './common'
import { ConfirmDialogContainer, TaskDialogContainer } from './common/Dialog'
import { CookiePolicySnackbar, Nav } from './navigation'

const Home = () => {
  const { accessToken } = useOidcAccessToken()
  const { isAuthenticated } = useOidc()

  return (
    <ApolloProvider client={client(accessToken ?? '')}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={gbLocale}>
        <ConfirmDialogProvider>
          {isAuthenticated ? (
            <PermissionProvider>
              <DialogProvider>
                <Nav />
                <TaskDialogContainer />
              </DialogProvider>
              <CookiePolicySnackbar />
            </PermissionProvider>
          ) : (
            <Auth />
          )}
          <ToastContainer theme='colored' limit={10} />
          <ConfirmDialogContainer />
        </ConfirmDialogProvider>
      </LocalizationProvider>
    </ApolloProvider>
  )
}

const CallbackSuccessComponent = () => {
  return <LoadingBackdrop loading={true} />
}

const App = () => {
  const commonAuthComponent = () => <LoadingBackdrop loading={true} />

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AppTheme}>
          <OidcProvider
            configuration={{
              storage: localStorage,
              authority: process.env.REACT_APP_AUTH_URL || '',
              client_id: process.env.REACT_APP_CLIENT_ID || '',
              redirect_uri: `${window.location.origin}/authentication/callback`,
              scope: 'openid profile roles email ssp-api offline_access',
              silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
            }}
            authenticatingComponent={AuthenticatingPage}
            loadingComponent={commonAuthComponent}
            callbackSuccessComponent={CallbackSuccessComponent}
            sessionLostComponent={SessionLost}>
            <AppInsightsContext.Provider value={reactPlugin}>
              <Home />
            </AppInsightsContext.Provider>
          </OidcProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  )
}

export default App
