import { gql } from '@apollo/client'

export const CREATE_SCHEDULED_EXCHANGE_RATE_TEMPLATE_MUTATION = gql`
  mutation createScheduledExchangeRateTemplate($currencyId: Long!, $date: DateTime!) {
    createScheduledExchangeRateTemplate(currencyId: $currencyId, date: $date) {
      id
      currencyId

      createdBy
      createdAt
    }
  }
`

export const DELETE_SCHEDULED_EXCHANGE_RATE_TEMPLATE_MUTATION = gql`
  mutation deleteScheduledExchangeRateTemplate($id: Long!) {
    deleteScheduledExchangeRateTemplate(id: $id)
  }
`
