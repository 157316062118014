import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'

type CustomEditSelectProps = {
  items: { value: string; label: string }[]
  value: string
  handleChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
}

export const CustomEditSelect = ({ items, value, handleChange }: CustomEditSelectProps) => {
  return (
    <Select value={value || ''} onChange={handleChange} IconComponent={ArrowDropDownIcon} fullWidth>
      {items.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}
