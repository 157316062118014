import { Box, Paper } from '@mui/material'

import AvailableBids from './AvailableBids'

interface IProps {
  bookingId: string | null
  courierExchangeLoadId?: string | null
  isQuote?: boolean
}

const AllocateBids = (props: IProps) => {
  const { bookingId } = props

  return (
    <Box>
      <Paper variant='outlined'>
        <AvailableBids
          bookingId={bookingId} />
      </Paper>
    </Box>
  )
}

export default AllocateBids

