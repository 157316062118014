interface IProps {
  value: any
  paths?: Array<string | number>
}

const CellContent = (props: IProps) => {
  const { value, paths } = props

  let valueToRender = value
  if (paths) {
    valueToRender = paths.slice(1).reduce((object, path) => {
      return (object || {})[path]
    }, value)
  }

  if (!valueToRender) {
    return <span style={{ fontStyle: 'italic' }}>No data</span>
  }
  return <>{valueToRender}</>
}

export default CellContent
