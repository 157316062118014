import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import { IconButton, InputAdornment } from '@mui/material'
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input'
import { Controller } from 'react-hook-form'

import { ControlledTextFieldProps } from './ControlledTextField'

export const ControlledTelephoneNumWithCountryCode = (props: ControlledTextFieldProps) => {
  const {
    control,
    name,
    label,
    defaultValue = '',
    disabled,
    readOnly,
    fullWidth = true,
    min,
    startAdornment,
    variant,
    ...otherProps
  } = props

  const handleOnCallClick = (telephoneNumber: string) => window.open(`tel:${telephoneNumber}`)

  const checkTelephoneNumberFieldIsEmpty = (telephoneNumber: string, countryCode: string) => {
    return telephoneNumber.replace('+', '') === countryCode
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, ...restField } }) => (
        <MuiTelInput
          {...restField}
          inputRef={ref}
          label={label}
          variant={variant}
          inputProps={{ min }}
          fullWidth={fullWidth}
          disabled={disabled}
          defaultCountry={'GB'}
          forceCallingCode={true}
          focusOnSelectCountry={true}
          flagSize='medium'
          onChange={(value: string, info: MuiTelInputInfo) => {
            if (checkTelephoneNumberFieldIsEmpty(value, info.countryCallingCode ?? '')) {
              onChange('')
            } else {
              onChange(value)
            }
          }}
          InputProps={{
            readOnly,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='call' onClick={() => handleOnCallClick(restField.value)}>
                  <PhoneOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...otherProps}
        />
      )}
    />
  )
}
