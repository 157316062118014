import React from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useForm, useFormContext } from 'react-hook-form'

import {
  ControlledCheckbox,
  ControlledDatePicker,
  SplitButton,
  ControlledMultipleAutocomplete,
} from 'components/common'
import { ReportTypeButtonsId, ReportTypeButtonValues } from 'constants/EntityConstants'
import { GetSubCustomersQuery, GetUserReasonCodesQuery } from 'generated/graphql'

interface IProps {
  customersLoading: boolean
  subCustomers: GetSubCustomersQuery['subCustomers'] | undefined
  isReasonCodeOptionEnabled: boolean
  reasonCodesLoading: boolean
  reasonCodes: GetUserReasonCodesQuery['userReasonCodes'] | undefined
  isProcessing: boolean
  onReportButtonClick: (buttonId: ReportTypeButtonsId) => void
  onClearAll: () => void
}

const FilterCriteriaForm = (props: IProps) => {
  const {
    customersLoading,
    subCustomers,
    isReasonCodeOptionEnabled,
    reasonCodesLoading,
    reasonCodes,
    isProcessing,
    onReportButtonClick,
    onClearAll,
  } = props

  const { control, setValue } = useFormContext()
  const { control: showAllCustomersControl, watch } = useForm({
    shouldUnregister: true,
    defaultValues: { isShowAllCustomers: true, isShowAllReasonCodes: true },
  })

  const isShowAllCustomers = watch('isShowAllCustomers')
  const isShowAllReasonCodes = watch('isShowAllReasonCodes')

  React.useEffect(() => {
    isShowAllCustomers && setValue('customerIds', [])
  }, [isShowAllCustomers, setValue])

  React.useEffect(() => {
    isShowAllReasonCodes && setValue('reasonCodes', [])
  }, [isShowAllReasonCodes, setValue])

  const customersItems = React.useMemo(
    () =>
      subCustomers?.map((subCustomer) => ({
        value: subCustomer.id,
        label: subCustomer.name,
      })) || [],
    [subCustomers],
  )

  const reasonCodesItems = React.useMemo(() => {
    const codes = reasonCodes?.map((reasonCode) => reasonCode.code) ?? []
    const distinctCodes = [...new Set(codes)]
    return distinctCodes.map((code) => ({ value: code, label: code }))
  }, [reasonCodes])

  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
      <Grid item xs={6} sm>
        <ControlledDatePicker control={control} name='start' label='From' />
      </Grid>

      <Grid item xs={6} sm>
        <ControlledDatePicker control={control} name='end' label='To' />
      </Grid>

      <Grid item xs={4} sm='auto'>
        <ControlledCheckbox
          name='isShowAllCustomers'
          label='All Customers'
          control={showAllCustomersControl}
        />
      </Grid>

      <Grid item xs={8} sm>
        {customersLoading ? (
          <Box mt={-1.25} mb={-1}>
            <Typography variant='h2'>
              <Skeleton animation='wave' />
            </Typography>
          </Box>
        ) : (
          <ControlledMultipleAutocomplete
            control={control}
            name='customerIds'
            label='Customers'
            options={customersItems}
            disabled={isShowAllCustomers}
            limitTags={3}
            defaultValue={[]}
          />
        )}
      </Grid>

      {isReasonCodeOptionEnabled && (
        <>
          <Grid item xs={4} sm='auto'>
            <ControlledCheckbox
              name='isShowAllReasonCodes'
              label='All Reason Codes'
              control={showAllCustomersControl}
            />
          </Grid>

          <Grid item xs={8} sm>
            {reasonCodesLoading ? (
              <Box mt={-1.25} mb={-1}>
                <Typography variant='h2'>
                  <Skeleton animation='wave' />
                </Typography>
              </Box>
            ) : (
              <ControlledMultipleAutocomplete
                control={control}
                name='reasonCodes'
                label='Reason Codes'
                options={reasonCodesItems}
                disabled={isShowAllReasonCodes}
                limitTags={3}
                defaultValue={[]}
              />
            )}
          </Grid>
        </>
      )}

      <Grid item xs={6} sm='auto'>
        <Button variant='contained' color='grey' onClick={onClearAll}>
          Clear All
        </Button>
      </Grid>

      <Grid item xs={6} sm='auto'>
        <SplitButton
          options={ReportTypeButtonValues}
          loading={isProcessing}
          onButtonClick={onReportButtonClick}
        />
      </Grid>
    </Grid>
  )
}

export default FilterCriteriaForm
