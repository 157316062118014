import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { JoiValidationOptions, Mode } from 'constants/index'
import {
  BannerType,
  SortEnumType,
  useCreateBannerMutation,
  useDeleteBannerMutation,
  useUpdateBannerMutation,
} from 'generated/graphql'
import { GET_PAGED_BANNERS } from 'graphql/queries'
import { isNumber, ShowAxiosError } from 'helpers'
import { UploadBanner } from 'services'
import { TBannerDialogForm } from 'types'
import { BannerDialogValidation } from 'validation-schemas'

import BannerForm from './BannerForm'
import UpdateBanner from './UpdateBanner'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}

const BannerDialog = (props: IProps) => {
  const { id, openDialog, onCloseDialog } = props
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [currentBannerId, setCurrentBannerId] = useState<number | null>(null)

  const methods = useForm<TBannerDialogForm>({
    resolver: joiResolver(BannerDialogValidation, JoiValidationOptions),
    defaultValues: { name: '', type: BannerType.MainPageWide, isActive: true },
  })

  const { handleSubmit } = methods

  const [createBanner, { loading: creatingBanner }] = useCreateBannerMutation({
    refetchQueries: [
      {
        query: GET_PAGED_BANNERS,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { createdAt: SortEnumType.Asc },
        },
      },
    ],
  })

  const [updateBanner, { loading: updatingBanner }] = useUpdateBannerMutation()

  const [deleteBanner, { loading: deletingBanner }] = useDeleteBannerMutation()

  let mode: number | undefined

  if (id === null) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const handleFilesChanged = (files: File[]) => {
    if (files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const handleBannerLoaded = (banner) => {
    setCurrentBannerId(banner.fileId)
  }

  const handleSubmitBanner: SubmitHandler<TBannerDialogForm> = async ({ name, type, isActive }) => {
    if (mode === Mode.Create) {
      if (selectedFile) {
        const fileData = new FormData()
        const date = new Date().toISOString()
        fileData.append(`banner-${date}`, selectedFile)

        const createBannerResponse = await createBanner({
          variables: { input: { name, type, isActive } },
        })

        const bannerId = createBannerResponse.data?.createBanner?.id
        if (bannerId) {
          try {
            const uploadBannerResponse = await UploadBanner(Number(bannerId), fileData)

            if (uploadBannerResponse?.data?.[0]?.id) {
              const updateBannerResponse = await updateBanner({
                variables: {
                  input: {
                    id: Number(bannerId),
                    name,
                    type,
                    isActive,
                    fileId: uploadBannerResponse.data[0].id,
                  },
                },
              })

              if (updateBannerResponse.data?.updateBanner) {
                toast.success('Banner was created with success')
                onCloseDialog()
              }
            }
          } catch (error: any) {
            deleteBanner({ variables: { id: Number(bannerId) } })
            ShowAxiosError(error)
          }
        }
      }
    } else {
      if (selectedFile) {
        const fileData = new FormData()
        const date = new Date().toISOString()
        fileData.append(`banner-${date}`, selectedFile)

        try {
          const uploadBannerResponse = await UploadBanner(id!, fileData)

          if (uploadBannerResponse.statusText === 'OK' && uploadBannerResponse.data[0].id) {
            const updateBannerResponse = await updateBanner({
              variables: {
                input: {
                  id,
                  name,
                  type,
                  isActive,
                  fileId: uploadBannerResponse.data[0].id,
                },
              },
            })

            if (updateBannerResponse.data?.updateBanner) {
              toast.success('Banner was updated with success')
              onCloseDialog()
            }
          }
        } catch (error: any) {
          ShowAxiosError(error)
        }
      } else {
        if (currentBannerId) {
          const updateBannerResponse = await updateBanner({
            variables: {
              input: {
                id,
                name,
                type,
                isActive,
                fileId: currentBannerId,
              },
            },
          })

          if (updateBannerResponse.data?.updateBanner) {
            toast.success('Banner was updated with success')
            onCloseDialog()
          }
        }
      }
    }
  }

  const handleClose = () => {
    onCloseDialog()
  }

  return (
    <Dialog fullWidth maxWidth='sm' scroll='body' open={openDialog} aria-labelledby='banner-dialog'>
      <FplDialogTitle id='banner-dialog' onClose={handleClose}>
        {mode === Mode.Create ? 'Create' : 'Update'} Banner
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? (
            <BannerForm mode={Mode.Create} onFilesChanged={handleFilesChanged} />
          ) : (
            <UpdateBanner
              id={id!}
              onBannerLoaded={handleBannerLoaded}
              onFilesChanged={handleFilesChanged}
            />
          )}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          loading={creatingBanner || updatingBanner || deletingBanner}
          onClick={handleSubmit(handleSubmitBanner)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default BannerDialog
