import { useEffect } from 'react'

import {
  DriverLocation,
  useGetBookingLazyQuery,
  useGetDriversLocationsQuery,
  useGetLoadCurrentLocationQuery,
  useGetUserBookingLazyQuery,
} from 'generated/graphql'
import { usePermission } from 'providers/PermissionProvider'

import { MapWithReorder } from './MapWithReorder'

interface IProps {
  bookingId: string | null
  isQuote?: boolean
  courierExchangeLoadId?: string | null
}

const MapRoute = (props: IProps) => {
  const { bookingId, isQuote = false, courierExchangeLoadId = null } = props

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')

  // queries
  const [getAdminBooking, { data: adminBookingData }] = useGetBookingLazyQuery({
    variables: {
      bookingId: Number(bookingId),
    },
  })

  const [getUserBooking, { data: userBookingData }] = useGetUserBookingLazyQuery({
    variables: {
      bookingId: Number(bookingId),
    },
  })

  const booking = adminBookingData?.booking || userBookingData?.userBooking

  const {
    data: ourDriversLocationsData,
    startPolling: startPollingOurDrivers,
    stopPolling: stopPollingOurDrivers,
  } = useGetDriversLocationsQuery({
    fetchPolicy: 'network-only',
    variables: { bookingId: Number(bookingId) },
  })

  const {
    data: cxDriverLocationsData,
    startPolling: startPollingCxDriver,
    stopPolling: stopPollingCxDriver,
  } = useGetLoadCurrentLocationQuery({
    fetchPolicy: 'network-only',
    variables: { loadId: Number(courierExchangeLoadId) },
    skip: !courierExchangeLoadId,
  })

  //* GetDriversLocations query can return [null]
  const driversLocations =
    ourDriversLocationsData?.driversLocations &&
      ourDriversLocationsData?.driversLocations?.filter((x) => x)?.length > 0
      ? (ourDriversLocationsData?.driversLocations as Array<DriverLocation>)
      : []

  if (courierExchangeLoadId && cxDriverLocationsData?.loadCurrentLocation)
    driversLocations.push(cxDriverLocationsData.loadCurrentLocation as DriverLocation)

  useEffect(() => {
    if (isAllowedToViewBookingFee) {
      getAdminBooking({
        variables: { bookingId: Number(bookingId) },
      })
    } else {
      getUserBooking({
        variables: { bookingId: Number(bookingId) },
      })
    }
  }, [bookingId, isAllowedToViewBookingFee, getAdminBooking, getUserBooking])

  // Poll only when component is visible
  useEffect(() => {
    startPollingOurDrivers(2000)
    startPollingCxDriver(15000) // CX API Limitation: "Please don't send more than one request every 15 seconds."
    return () => {
      stopPollingOurDrivers()
      stopPollingCxDriver()
    }
  }, [startPollingOurDrivers, stopPollingOurDrivers, startPollingCxDriver, stopPollingCxDriver])

  return (
    <MapWithReorder
      bookingId={bookingId}
      isQuote={isQuote}
      bookingDetails={booking}
      driverLocations={driversLocations}
    />
  )
}

export default MapRoute
