import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import {
  DriverLocation,
  useGetBookingByTrackingNumberLazyQuery,
  useGetDriversLocationsByTrackingNumberQuery,
} from 'generated/graphql'

import { TrackingMap } from './TrackingMap'
const TrackingPage = () => {

  const { trackingNumber } = useParams<{ trackingNumber: 'uuid' }>()
  
  // queries
  const [getBookingByTrackingNumber, { data: bookingData }] =
    useGetBookingByTrackingNumberLazyQuery({
      variables: {
        trackingGuid: trackingNumber,
      },
    })

  const {
    data: ourDriversLocationsData,
    startPolling: startPollingOurDrivers,
    stopPolling: stopPollingOurDrivers,
  } = useGetDriversLocationsByTrackingNumberQuery({
    fetchPolicy: 'network-only',
    variables: { trackingGuid: trackingNumber },
  })

  //* GetDriversLocations query can return [null]
  const driversLocations =
    ourDriversLocationsData?.driversLocationsByTrackingNumber &&
    ourDriversLocationsData?.driversLocationsByTrackingNumber?.filter((x) => x)?.length > 0
      ? (ourDriversLocationsData?.driversLocationsByTrackingNumber as Array<DriverLocation>)
      : []

  useEffect(() => {
    getBookingByTrackingNumber({
      variables: { trackingGuid: trackingNumber },
    })
  }, [getBookingByTrackingNumber, trackingNumber])

  // Poll only when component is visible
  useEffect(() => {
    startPollingOurDrivers(2000)
    return () => {
      stopPollingOurDrivers()
    }
  }, [startPollingOurDrivers, stopPollingOurDrivers])
  return (
    <>
      <TrackingMap
        bookingDetails={bookingData?.bookingByTrackingNumber}
        driverLocations={driversLocations}
      />
    </>
  )
}

export default TrackingPage