import { gql } from '@apollo/client'

import { EmailTemplateDetailsFragment } from '../fragments'

export const GET_EMAIL_TEMPLATES_QUERY = gql`
    query GetEmailTemplates(
        $order: [EmailTemplateSortInput!]
        $where: EmailTemplateFilterInput
    ) {
        emailTemplates(
            order: $order
            where: $where
        ) {
            ...EmailTemplateDetails
        }
        ${EmailTemplateDetailsFragment}
    }
`

export const GET_EMAIL_TEMPLATE_TOKENS_QUERY = gql`
  query GetEmailTemplateTokens {
    emailTemplateTokens {
      value
      token
      description
      numericValue
    }
  }
`

export const GET_SENT_EMAILS_QUERY = gql`
  query GetSentEmails(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [SentEmailSortInput!]
    $where: SentEmailFilterInput
  ) {
    sentEmails(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          createdAt
          createdBy
          to
          subject
          hasAttachments
          isSuccessful
          hasErrors
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_SENT_EMAIL_QUERY = gql`
  query GetSentEmail($sentEmailId: Long!) {
    sentEmail(sentEmailId: $sentEmailId) {
      id
      createdAt
      to
      cc
      bcc
      subject
      body
      hasAttachments
      isSuccessful
      hasErrors
      errorMessages
      sendGridMessageId
    }
  }
`
