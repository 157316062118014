import React from 'react'

import { Box, Grid, Paper } from '@mui/material'
import { endOfDay, startOfMonth } from 'date-fns'

import { CardTitle } from 'components/common/controls'
import { ReportFileType, ReportFileTypeValues } from 'constants/EntityConstants'
import {
  SalesCommissionReportInputDtoInput,
  useGetSalesCommissionReportQuery,
} from 'generated/graphql'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadSalesCommissionReport } from 'services/RestClient'

import { ReportTable } from '../common/ReportTable'
import FilterCriteria from './FilterCriteria'

const defaultFilter = {
  start: startOfMonth(new Date()),
  end: endOfDay(new Date()),
  salesName: null,
}

const SalesCommission = () => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [filter, setFilter] = React.useState<SalesCommissionReportInputDtoInput>(defaultFilter)

  const { data: salesCommissionReportData, fetchMore } = useGetSalesCommissionReportQuery({
    variables: { input: filter },
  })

  React.useEffect(() => {
    fetchMore({ variables: { input: filter } })
  }, [filter, fetchMore])

  const headerRow = React.useMemo(
    () => salesCommissionReportData?.salesCommissionReport.rows[0],
    [salesCommissionReportData],
  )

  const bodyRows = React.useMemo(
    () =>
      salesCommissionReportData?.salesCommissionReport.rows.filter((_, index) => {
        if (index !== 0) {
          return true
        }
      }),
    [salesCommissionReportData],
  )

  const handleViewReport = (filter: SalesCommissionReportInputDtoInput) => {
    setFilter(filter)
  }

  const handleDownloadReport = (
    type: ReportFileType,
    filter: SalesCommissionReportInputDtoInput,
  ) => {
    setIsProcessing(true)

    DownloadSalesCommissionReport(type, filter)
      .then((response) => {
        setIsProcessing(false)

        let fileName = GetFileName(response)
        if (!fileName) {
          const format = ReportFileTypeValues.find((reportType) => reportType.id === type)?.name
          fileName = `sales-commission-report.${format}`
        }

        SaveFile(response.data, fileName)
      })
      .catch((error) => {
        ShowRestError(error)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>Filter Criteria</CardTitle>
            <FilterCriteria
              defaultFilter={defaultFilter}
              isProcessing={isProcessing}
              onViewReport={handleViewReport}
              onDownloadReport={handleDownloadReport}
            />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>Report Table</CardTitle>
            <ReportTable headerRow={headerRow} bodyRows={bodyRows} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SalesCommission
