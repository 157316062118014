import { gql } from '@apollo/client'

export const GET_BIDS_BY_BOOKING_QUERY = gql`
  query GetDriverBids($where: DriverBidFilterInput!) {
    driverBids(where: $where) {
      id
      price
      currency {
        id
        name
        symbol
      }
      eta
      notes
      driverId
      driver {
        id
        name
        averageRating
      }
      bookingId
      vehicleId
      vehicle {
        id
        vehicleType {
          id
          name
        }
      }
      createdAt
      isAllocated
    }
  }
`

export const GET_BIDS_BY_BOOKING_QUERY_PAGED = gql`
  query GetDriverBidsPaged(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [DriverBidSortInput!]
    $where: DriverBidFilterInput) {
    driverBidsPaged(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where) {
    edges {
      node {
        id
        price
        currency {
          id
          name
          symbol
        }
        eta
        notes
        driverId
        driver {
          name
          averageRating
        }
        bookingId
        vehicleId
        vehicle {
          vehicleType {
            name
          }
        }
        createdAt
        isAllocated
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
  }
`
