import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Mode,
  Paths,
  VatCategoryType,
  DEFAULT_COUNTRY_ID,
  JoiValidationOptions,
} from 'constants/index'
import {
  CreateAndUpdateCustomerDtoInput,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  CustomerAccountType,
  LegalAccountType,
  useGetCustomerLazyQuery,
  InvoiceFrequency,
  useDeleteCustomerLogoMutation,
} from 'generated/graphql'
import { GET_CUSTOMERS_QUERY } from 'graphql/queries'
import { ShowRestError } from 'helpers'
import { UploadCustomerLogo } from 'services/RestClient'
import { CustomerDetailsValidations } from 'validation-schemas'

import CustomerFormControls from './CustomerFormControls'
import UpdateCustomer from './UpdateCustomer'

interface IProps {
  customerId: number | null
  mode: number
}

// ToDo: add validation schema
const CustomerDetails = (props: IProps) => {
  const { mode, customerId } = props
  const history = useHistory()
  const [newLogo, setNewLogo] = useState<any>(null)
  const [uploadingLogo, setUploadingLogo] = useState<boolean>(false)

  const methods = useForm<CreateAndUpdateCustomerDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(CustomerDetailsValidations, JoiValidationOptions),
    defaultValues: {
      name: '',
      addressLine1: '',
      addressLine2: '',
      legalAccountType: LegalAccountType.Business,
      customerAccountType: CustomerAccountType.Cash,
      city: '',
      postcode: '',
      countryId: DEFAULT_COUNTRY_ID,
      invoiceEmail: '',
      contactId: '',
      paymentMethodId: '',
      vatCategoryId: VatCategoryType.t1,
      accountCode: '',
      companyRegistrationNumber: '',
      vatIdNumber: '',
      eoriNumber: '',
      invoiceCompanyName: '',
      acquiredBy: '',
      customerAcquisitionSourceId: '',
      notes: '',
      invoiceTermId: '',
      invoiceFrequency: InvoiceFrequency.Daily,
      isInvoiceGrouped: false,
      currencyId: '',
      isReferenceRequired: false,
      isUlezCharge: false,
      isOnHold: false,
      startDate: new Date(),
      isApplyVatToOtherCharges: false,
      isEnabled: true,
      isReasonCodeOptionEnabled: false,
      isBookingImportOptionEnabled: false,
      isPackageDetailsOptional: false,
    },
  })
  const { handleSubmit } = methods

  // Queries
  const [getCustomer, { loading: customerLoading }] = useGetCustomerLazyQuery({
    fetchPolicy: 'network-only',
  })

  // Mutations
  const [deleteCustomerLogo, { loading: deletingCustomerLogo }] = useDeleteCustomerLogoMutation({
    refetchQueries: [{ query: GET_CUSTOMERS_QUERY }],
    onCompleted: async () => {
      if (mode === Mode.Update) {
        getCustomer({ variables: { customerId } })
      }
      setNewLogo(null)
      setUploadingLogo(false)
      toast.success(`Customer Logo was successfully deleted.`)
    },
  })

  const handleUploadCustomerLogo = async (customerId: number, file) => {
    const fileData = new FormData()
    fileData.append('booking-attachment', file as any)
    await UploadCustomerLogo(Number(customerId), fileData)
      .then(() => {
        if (mode === Mode.Update) {
          getCustomer({ variables: { customerId } })
        }
      })
      .catch(ShowRestError)
      .finally(() => {
        setUploadingLogo(false)
        setNewLogo(null)
      })
  }

  const [createCustomer, { loading: creatingCustomer }] = useCreateCustomerMutation({
    refetchQueries: [{ query: GET_CUSTOMERS_QUERY }],
    onCompleted: async (data) => {
      toast.success(`Customer '${data?.createCustomer.name}' was successfully created.`)

      if (newLogo) {
        setUploadingLogo(true)
        await handleUploadCustomerLogo(Number(data?.createCustomer.id), newLogo)
      }

      history.push(Paths.customers.updateWithId(data?.createCustomer.id))
    },
  })

  const [updateCustomer, { loading: updatingCustomer }] = useUpdateCustomerMutation({
    onCompleted: async (data) => {
      toast.success(`Customer '${data?.updateCustomer.name}' was successfully updated.`)

      if (newLogo) {
        setUploadingLogo(true)
        await handleUploadCustomerLogo(Number(data.updateCustomer.id), newLogo)
      }
    },
  })

  // handlers
  const onSubmit: SubmitHandler<CreateAndUpdateCustomerDtoInput> = (data) => {
    const {
      countryId,
      contactId,
      vatCategoryId,
      paymentMethodId,
      customerAcquisitionSourceId,
      invoiceTermId,
      primaryCustomerId,
      ...rest
    } = data

    const normalizedVars = () => ({
      countryId: countryId ? Number(countryId) : null,
      vatCategoryId: vatCategoryId ? Number(vatCategoryId) : null,
      paymentMethodId: paymentMethodId ? Number(paymentMethodId) : null,
      customerAcquisitionSourceId: customerAcquisitionSourceId
        ? Number(customerAcquisitionSourceId)
        : null,
      contactId: contactId ? Number(contactId) : null,
      invoiceTermId: invoiceTermId ? Number(invoiceTermId) : null,
      primaryCustomerId: primaryCustomerId ? primaryCustomerId : null,
    })

    if (mode === Mode.Create) {
      createCustomer({
        variables: {
          input: {
            id: null,
            ...normalizedVars(),
            ...rest,
            countryId: Number(countryId),
          },
        },
      })
    } else {
      updateCustomer({
        variables: {
          input: {
            id: customerId,
            ...normalizedVars(),
            ...rest,
          } as CreateAndUpdateCustomerDtoInput,
        },
      })
    }
  }

  const onUploadCustomerLogo = (file) => {
    handleUploadCustomerLogo(Number(customerId), file)
  }

  const handleRemoveCustomerLogo = async () => {
    setUploadingLogo(true)
    await deleteCustomerLogo({
      variables: {
        customerId: Number(customerId),
      },
    })
  }

  const isProcessing =
    creatingCustomer || updatingCustomer || uploadingLogo || customerLoading || deletingCustomerLogo

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {mode === Mode.Create ? (
          <CustomerFormControls
            mode={mode}
            newLogo={newLogo}
            isProcessing={isProcessing}
            customerLogo={{
              onUploadCustomerLogo,
              onRemoveCustomerLogo: handleRemoveCustomerLogo,
              setNewLogo,
              uploadingLogo,
            }}
          />
        ) : (
          <UpdateCustomer
            customerId={customerId!}
            newLogo={newLogo}
            setNewLogo={setNewLogo}
            isProcessing={isProcessing}
            onUploadCustomerLogo={onUploadCustomerLogo}
            onRemoveCustomerLogo={handleRemoveCustomerLogo}
          />
        )}
      </form>
    </FormProvider>
  )
}

export default CustomerDetails
