import React from 'react'

import { ApolloError } from '@apollo/client'
import { joiResolver } from '@hookform/resolvers/joi'
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import Joi from 'joi'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common/Dialog'
import { EntityConstants, VehicleEntity } from 'constants/index'
import {
  CreateAndUpdateVehicleDtoInput,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'

import UpdateVehicle from './UpdateVehicle'
import { VehicleForm } from './VehicleForm'
import VehicleFormHeader from './VehicleFormHeader'

const schema = Joi.object({
  registrationNumber: Joi.string().required().max(VehicleEntity.MAX_REGISTRATION_NUMBER_LENGTH),
  dateOfManufacture: Joi.date().allow(null).max(new Date()).optional().label('Date of Manufacture'),
  vehicleTypeId: Joi.number().required().label('Vehicle Type ID'),
  manufacturer: Joi.string().allow('').optional().max(VehicleEntity.MAX_MANUFACTURER_LENGTH),
  model: Joi.string().max(VehicleEntity.MAX_MODEL_LENGTH).optional().allow(''),
  colour: Joi.string().max(VehicleEntity.MAX_COLOUR_LENGTH).optional().allow(''),
  insuranceCompany: Joi.string()
    .max(VehicleEntity.MAX_INSURANCE_COMPANY_LENGTH)
    .optional()
    .allow(''),
  policyOrCoverNote: Joi.string()
    .max(EntityConstants.MAX_NOTE_LENGTH)
    .optional()
    .allow('')
    .label('Policy or Cover Note'),
  goodsInTransitExpiryDate: Joi.date()
    .allow(null)
    .min(new Date())
    .optional()
    .label('Goods in Transit Expiry Date'),
  insuranceExpiryDate: Joi.date()
    .allow(null)
    .min(new Date())
    .optional()
    .label('Insurance Expiry Date'),
  motTestExpiryDate: Joi.date()
    .allow(null)
    .min(new Date())
    .optional()
    .label('MOT Test Expiry Date'),
  isRenewalReminder: Joi.boolean().allow(null, '').optional(),
})

interface IProps {
  driverId: number
  vehicleId: number | null
  openDriverDialog: boolean
  handleCloseDialog: () => void
}

const CreateUpdateVehicle = (props: IProps) => {
  const { driverId, vehicleId, openDriverDialog, handleCloseDialog } = props

  const [notFound, setNotFound] = React.useState(false)
  const [vehicle, setVehicle] = React.useState<CreateAndUpdateVehicleDtoInput>({
    driverId: String(driverId),
    id: vehicleId ? String(vehicleId) : null,
    isDefault: false,
    isRenewalReminder: false,
    registrationNumber: '',
    vehicleTypeId: 1,
    colour: '',
    dateOfManufacture: null,
    goodsInTransitExpiryDate: null,
    insuranceCompany: '',
    insuranceExpiryDate: null,
    manufacturer: '',
    model: '',
    motTestExpiryDate: null,
    policyOrCoverNote: '',
  })

  const methods = useForm<CreateAndUpdateVehicleDtoInput>({
    shouldUnregister: true,
    defaultValues: { ...vehicle },
    resolver: joiResolver(schema),
  })

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods

  React.useEffect(() => {
    reset({ ...vehicle })
  }, [reset, vehicle])

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  const [createVehicle, { loading: creatingVehicle }] = useCreateVehicleMutation()
  const [updateVehicle, { loading: updatingVehicle }] = useUpdateVehicleMutation()

  const handleSuccess = (data: CreateAndUpdateVehicleDtoInput) => {
    if (!vehicleId) {
      createVehicle({
        variables: {
          input: {
            ...data,
            timeZoneOffset: new Date().getTimezoneOffset(),
            driverId: String(driverId),
            isDefault: false,
            vehicleTypeId: Number(data.vehicleTypeId),
          },
        },
      })
        .then(() => {
          toast.success('Vehicle was successfully created.')
          handleCloseDialog()
        })
        .catch((reason: ApolloError) => reason.graphQLErrors.map((e) => toast.error(e.message)))
    } else if (vehicleId && !notFound) {
      updateVehicle({
        variables: {
          input: {
            ...data,
            timeZoneOffset: new Date().getTimezoneOffset(),
            id: String(vehicleId),
            driverId: String(driverId),
            isDefault: vehicle.isDefault,
            vehicleTypeId: Number(data.vehicleTypeId),
          },
        },
      })
        .then(() => {
          toast.success('Vehicle was successfully updated.')
          handleCloseDialog()
        })
        .catch((reason: ApolloError) => reason.graphQLErrors.map((e) => toast.error(e.message)))
    }
  }

  const handleCancel = () => {
    if (isDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  const isProcessing = creatingVehicle || updatingVehicle

  return (
    <Dialog fullWidth maxWidth='md' open={openDriverDialog} aria-labelledby='form-vehicle-dialog'>
      <FplDialogTitle id='form-vehicle-dialog-title' onClose={handleCancel}>
        <VehicleFormHeader notFound={notFound} vehicleId={vehicleId} />
      </FplDialogTitle>

      {!notFound && (
        <DialogContent>
          <FormProvider {...methods}>
            <form id='create-update-vehicle-form' onSubmit={handleSubmit(handleSuccess)} noValidate>
              {!vehicleId ? (
                <VehicleForm />
              ) : (
                <UpdateVehicle
                  driverId={driverId}
                  vehicleId={vehicleId as number}
                  setNotFound={setNotFound}
                  setVehicle={setVehicle}
                />
              )}
            </form>
          </FormProvider>
        </DialogContent>
      )}

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCancel} disabled={isProcessing}>
          Cancel
        </Button>
        <Button
          form='create-update-vehicle-form'
          color='primary'
          variant='contained'
          disabled={isProcessing || !isDirty}
          type='submit'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateVehicle
