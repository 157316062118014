import { Typography } from '@mui/material'

interface IProps {
  notFound: boolean
  createCharge: boolean
}

export default function OtherChargeTitle(props: IProps) {
  const { notFound, createCharge } = props

  if (notFound) {
    return <Typography variant='h4'>Charge not found.</Typography>
  }

  return <Typography variant='h4'>{createCharge ? 'Create Charge' : 'Update Charge'}</Typography>
}
