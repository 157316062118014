import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ConfirmDistanceChangeDialog } from 'components/bookings/common'
import { ControlledTextField } from 'components/common'
import { GetBookingByTrackingNumberQuery, GetBookingQuery, GetUserBookingQuery } from 'generated/graphql'
import { calcBookingConsignmentFee } from 'helpers'
import { usePermission } from 'providers'

interface IProps {
  bookingDetails: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking'] | GetBookingByTrackingNumberQuery["bookingByTrackingNumber"]
}

const DistanceAndConsignmentFee = (props: IProps) => {
  const { bookingDetails } = props
  const [consignmentFeeCalculated, setConsignmentFeeCalculated] = useState<any>(null)
  const [openConfirmDistanceChangeDialog, setOpenConfirmDistanceChangeDialog] = useState(false)

  const { control, reset, watch, getValues, setValue } = useFormContext()

  const [isAllowedToShowConfirmationDialogWhenDockedIsRePriced] = usePermission(
    'ShowConfirmationDialogWhenDockedIsRePriced',
  )

  useEffect(() => {
    if (bookingDetails) {
      reset({
        distance: bookingDetails.distance,
        consignmentFee: bookingDetails.consignmentFee,
      })
    }
  }, [bookingDetails, reset])

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === 'distance' && data.distance) {
        if (bookingDetails?.tariff && data.distance !== undefined) {
          const consignmentFee = calcBookingConsignmentFee(
            Number(data.distance),
            bookingDetails.tariff.minMiles,
            bookingDetails.tariff.minCharge,
            bookingDetails.tariff.rate,
          )

          setConsignmentFeeCalculated(consignmentFee)
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [bookingDetails?.tariff, watch])

  useEffect(() => {
    const consignmentFeeValue = getValues('consignmentFee')

    if (consignmentFeeCalculated && consignmentFeeCalculated !== Number(consignmentFeeValue)) {
      if (isAllowedToShowConfirmationDialogWhenDockedIsRePriced) {
        setOpenConfirmDistanceChangeDialog(true)
      } else {
        setValue('consignmentFee', consignmentFeeCalculated)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentFeeCalculated, getValues])

  const handleConfirmChangeDistance = () => {
    if (consignmentFeeCalculated) {
      setValue('consignmentFee', consignmentFeeCalculated)
    }
  }

  const handleRejectChangeDistance = () => {
    // * No action on reject
  }

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            control={control}
            name='distance'
            label='Distance'
            readOnly
            endAdornment='mi'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ControlledTextField
            control={control}
            name='consignmentFee'
            label='Consignment Fee'
            readOnly
          />
        </Grid>
      </Grid>

      <ConfirmDistanceChangeDialog
        open={openConfirmDistanceChangeDialog}
        setOpen={setOpenConfirmDistanceChangeDialog}
        onConfirm={handleConfirmChangeDistance}
        onReject={handleRejectChangeDistance}
      />
    </>
  )
}

export default DistanceAndConsignmentFee
