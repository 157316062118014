import { Typography } from '@mui/material'

interface IProps {
  bookingAddress: any
}

const AddressDescription = ({ bookingAddress }: IProps) => {
  return (
    <>
      <Typography component='span' variant='body2' color='textPrimary' sx={{ display: 'inline' }}>
        {bookingAddress?.address.name}
        {!bookingAddress?.address.isVerified ? (
          <Typography component='span' variant='body2' color='error' sx={{ display: 'inline' }}>
            {' '}
            [Unverified]
          </Typography>
        ) : (
          <></>
        )}
      </Typography>
      {` — ${bookingAddress?.address.addressLine1}, ` +
        `${bookingAddress?.address.addressLine2 ? bookingAddress?.address.addressLine2 : ' - '}, ${
          bookingAddress?.address.city
        }, ${bookingAddress?.address.postcode}`}
    </>
  )
}

export default AddressDescription
