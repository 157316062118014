import { useEffect, useMemo, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledCheckbox,
  ControlledSelector,
  ControlledTextField,
  ISelectorOptions,
} from 'components/common'
import { XeroCodeTypeValues } from 'constants/EntityConstants'
import { ServiceTypeDescriptions } from 'constants/index'
import {
  CreateAndUpdateXeroCodeDtoInput,
  useGetXeroServiceTypesQuery,
  XeroServiceType,
} from 'generated/graphql'

const ServiceCodeForm = () => {
  const [serviceDetails, setServiceDetails] = useState('')

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CreateAndUpdateXeroCodeDtoInput>()
  const serviceType = watch('serviceType')

  useEffect(() => {
    switch (serviceType) {
      case XeroServiceType.DomesticDedicated:
      case XeroServiceType.DomesticParcels:
      case XeroServiceType.InternationalParcels:
      case XeroServiceType.DomesticPallets:
      case XeroServiceType.EuropeanDedicated:
      case XeroServiceType.EuropeanGroupage:
      case XeroServiceType.InternationalAirFreight:
      case XeroServiceType.SeaFreight:
        setServiceDetails(ServiceTypeDescriptions[serviceType])
        break

      default:
        setServiceDetails('')
        break
    }
  }, [serviceType, setServiceDetails])

  // queries
  const { loading: serviceTypeLoading, data: serviceTypeData } = useGetXeroServiceTypesQuery()

  const serviceItems: ISelectorOptions = useMemo(() => {
    if (!serviceTypeLoading && serviceTypeData?.xeroServiceTypes) {
      return serviceTypeData.xeroServiceTypes.map((s) => ({
        value: s.serviceType,
        label: s.description,
      }))
    }

    return []
  }, [serviceTypeLoading, serviceTypeData?.xeroServiceTypes])

  return (
    <Box pt={1}>
      <Grid container columnSpacing={3} rowSpacing={1.5}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Name'
            name='name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Code'
            name='code'
            required
            error={!!errors.code}
            helperText={errors.code?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Description'
            name='description'
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            label='Type'
            name='type'
            options={XeroCodeTypeValues}
            required
            error={!!errors.type}
            helperText={errors.type?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledCheckbox control={control} label='Is International' name='isInternational' />
        </Grid>
        <Grid item xs={12}>
          <ControlledSelector
            control={control}
            label='Service type'
            name='serviceType'
            options={serviceItems}
            emptyValue={{ value: null, label: 'None' }}
            displayEmpty
            required
            error={!!errors.serviceType}
            helperText={errors.serviceType?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>{serviceDetails}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ServiceCodeForm
