import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox } from 'components/common'
import { TBookingForm } from 'types/form-types'

const OtherDetailsFallback = () => {
  const { control } = useFormContext<TBookingForm>()

  return (
    <Box hidden>
      <ControlledCheckbox
        control={control}
        name='isEmailNotification'
        label='Send email notification'
        defaultValue={true}
      />
    </Box>
  )
}

export default OtherDetailsFallback
