import { Control, Controller } from 'react-hook-form'

import { WysiwygDraft } from 'components/common'

interface IProps {
  control?: Control<any, any>
  label: string
  name: string
  required?: boolean
  readOnly?: boolean
  error?: boolean
  helperText?: any
  spellCheck?: boolean
}

const ControlledWysiwygDraft = (props: IProps) => {
  const { control, label, name, ...otherProps } = props

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <WysiwygDraft label={label} content={value} onChange={onChange} {...otherProps} />
      )}
    />
  )
}

export default ControlledWysiwygDraft
