import Joi from 'joi'

import { TBannerDialogForm } from 'types/form-types'

const BannerDialogValidation = Joi.object<TBannerDialogForm>({
  name: Joi.string().empty(''),
  type: Joi.any(),
})

export { BannerDialogValidation }
