import { useEffect, useState } from 'react'

import { AppBar, Tab, Tabs, Tooltip } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'

import { BookingDetails } from 'components/bookings/BookingDetails'
import { MapRoute } from 'components/bookings/MapRoute'
import { CounterBadge, FplTab, TabPanel, TasksAndHistory } from 'components/common'
import { Mode, PARAM_NEW } from 'constants/index'
import { TaskStatus, useGetTasksCountLazyQuery } from 'generated/graphql'
import { CloneProps, isNumber, useHashToSelectTab } from 'helpers'
import { Restricted, usePermission } from 'providers'

enum TabId {
  Details = 0,
  MapRoute,
  Tasks,
}

const allTabs = {
  quoteDetails: {
    id: TabId.Details,
    label: 'Quote Details',
    hash: '#quote-details',
  },
  mapRoute: {
    id: TabId.MapRoute,
    label: 'Map Route',
    hash: '#map-route',
  },
  tasks: {
    id: TabId.Tasks,
    label: 'Tasks',
    hash: '#tasks',
  },
}

function commonProps(index: TabId) {
  return {
    id: `quick-quote-tab-${index}`,
    'aria-controls': `quick-quote-tabpanel-${index}`,
  }
}

const QuickQuoteTab = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)
  const { id } = useParams<{ id?: string }>()

  const history = useHistory()

  const arrayOfTabs = Object.values(allTabs)
  useHashToSelectTab(arrayOfTabs, setTabValue)

  const [isAllowedToViewTasks] = usePermission('ViewTasks')

  let mode: number | undefined

  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const quickQuoteId: string | null = mode === Mode.Update && id ? id : null

  const [getTasksCount, { data: tasksData }] = useGetTasksCountLazyQuery()

  useEffect(() => {
    if (isAllowedToViewTasks && quickQuoteId) {
      getTasksCount({
        variables: {
          where: { bookingId: { eq: Number(quickQuoteId) }, status: { eq: TaskStatus.New } },
        },
      })
    }
  }, [getTasksCount, isAllowedToViewTasks, quickQuoteId])

  const isBaseDisabled = !quickQuoteId || mode === Mode.Create

  const tabsTooltipText = !quickQuoteId ? 'Available only for existing quotes' : ''

  const handleTabChange = (_, newValue: TabId) => {
    const selectedTab = arrayOfTabs.find((tab) => tab.id === newValue)

    if (selectedTab) {
      history.push(selectedTab.hash)
    }
  }

  return (
    <>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='quote tabs'
          variant='scrollable'>
          <CloneProps>
            {(tabProps) => (
              <Restricted to={['ViewBookingDetails', 'ViewUserBookingDetails']}>
                <Tab
                  {...tabProps}
                  {...commonProps(allTabs.quoteDetails.id)}
                  label={allTabs.quoteDetails.label}
                  value={allTabs.quoteDetails.id}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewBookingMapRouteTab'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.mapRoute}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewTasks'>
                <Tab
                  {...tabProps}
                  {...commonProps(allTabs.tasks.id)}
                  sx={{ pointerEvents: 'auto !important' }}
                  value={allTabs.tasks.id}
                  disabled={isBaseDisabled}
                  label={
                    <Tooltip
                      title={tabsTooltipText}
                      disableHoverListener={!isBaseDisabled}
                      disableTouchListener={!isBaseDisabled}
                      disableFocusListener={!isBaseDisabled}>
                      <CounterBadge color='primary' badgeContent={tasksData?.tasks?.totalCount}>
                        {allTabs.tasks.label}
                      </CounterBadge>
                    </Tooltip>
                  }
                />
              </Restricted>
            )}
          </CloneProps>
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={TabId.Details}>
        <BookingDetails bookingId={quickQuoteId} mode={mode} isQuote isQuickQuote />
      </TabPanel>

      <TabPanel value={tabValue} index={TabId.MapRoute} padding={0}>
        <MapRoute bookingId={quickQuoteId} isQuote />
      </TabPanel>

      <TabPanel value={tabValue} index={TabId.Tasks}>
        <TasksAndHistory bookingId={quickQuoteId} />
      </TabPanel>
    </>
  )
}

export default QuickQuoteTab
