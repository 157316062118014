import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { GET_PAGED_REASON_CODES } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
  { field: 'code', headerName: 'Code', minWidth: 200, flex: 1 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 200,
    flex: 1,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_TIME_FORMAT),
  },
  { field: 'createdBy', headerName: 'Created By', minWidth: 200, flex: 1 },
]

interface IProps {
  customerId: number
}
const ReasonCodes = (props: IProps) => {
  const { customerId } = props

  return (
    <FplDataGrid
      query={GET_PAGED_REASON_CODES}
      entityName='pagedReasonCodes'
      columns={columns}
      queryVariables={{ customerId }}
      toolbar={{ caption: 'Reason Codes' }}
    />
  )
}

export default ReasonCodes
