import { useEffect, useState } from 'react'

import { Button, Snackbar, SnackbarContent } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Paths } from 'constants/Paths'
import { getIsCookiesAccepted, setIsCookiesAccepted } from 'helpers/localStorage'

const CookiePolicySnackbar = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const history = useHistory()

  useEffect(() => {
    // ToDo: try to use Apollo reactive vars here
    const isCookiesAccepted = getIsCookiesAccepted()
    setIsSnackbarOpen(!isCookiesAccepted)
  }, [])

  const handleAccept = () => {
    setIsCookiesAccepted(true)
    setIsSnackbarOpen(false)
  }

  const handleMoreDetails = () => {
    history.push(`${Paths.help.all}#cookie-policy`)
  }

  return (
    <Snackbar open={isSnackbarOpen}>
      <SnackbarContent
        message='By using our site you agree to our Cookie Policy. We use only strictly necessary cookies.'
        action={
          <>
            <Button color='grey' onClick={handleMoreDetails}>
              More Details
            </Button>
            <Button onClick={handleAccept}>Ok, got it</Button>
          </>
        }
        sx={{ backgroundColor: 'background.paper', color: 'text.primary', maxWidth: '430px' }}
      />
    </Snackbar>
  )
}

export default CookiePolicySnackbar
