import { useEffect, Dispatch, SetStateAction } from 'react'

import { useLocation } from 'react-router-dom'

function useHashToSelectTab(
  tabs: Array<{ id: any; hash: string }>,
  setTabValue: Dispatch<SetStateAction<number | false>>,
) {
  const { hash } = useLocation()

  useEffect(() => {
    const selectedTabId = tabs.find((tab) => tab.hash === hash)?.id
    setTabValue(selectedTabId || tabs[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])
}

export { useHashToSelectTab }
