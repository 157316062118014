import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Paths, StatusCategories } from 'constants/index'
import {
  useCancelBookingMutation,
  useMarkQuoteLostMutation,
  useUpdateBookingStatusMutation,
  useCreateBookingInvoiceMutation,
  GetBookingDocument,
  useArchiveBookingMutation,
  useMakeBookingOnHoldMutation,
  useCopyBookingMutation,
  useSendDocketDetailsMutation,
  useCreateScheduledBookingTemplateMutation,
  useConfirmQuickQuoteMutation,
  BookingStatus,
} from 'generated/graphql'
import { bookingsStatusCategoryVar } from 'graphql/reactiveVariables'
import { usePermission } from 'providers'
import { TBookingForm } from 'types'

const useBookingButtonActions = (bookingId: string | undefined, isQuote: boolean) => {
  const history = useHistory()
  const { trigger } = useFormContext<TBookingForm>()

  const [isAllowedToSetBookingsListToInProgressAfterQuoteIsBooked] = usePermission(
    'SetBookingsListToInProgressAfterQuoteIsBooked',
  )

  const [cancelBookingMutation, { loading: cancelingBooking }] = useCancelBookingMutation({
    onCompleted: (response) => {
      if (!response) {
        toast.error('Something went wrong')
        return
      }

      toast.success('Booking was successfully canceled')
      history.push(Paths.bookings.all)
    },
  })

  const [markQuoteLostMutation, { loading: markingQuoteLost }] = useMarkQuoteLostMutation()

  const [updateBookingStatusMutation, { loading: updatingBookingStatus }] =
    useUpdateBookingStatusMutation()

  const [createBookingInvoiceMutation, { loading: creatingBookingInvoice }] =
    useCreateBookingInvoiceMutation({
      refetchQueries: [
        {
          query: GetBookingDocument,
          variables: { bookingId: Number(bookingId) },
        },
      ],
      onCompleted: (response) => {
        if (!response) {
          toast.error('Something went wrong')
          return
        }

        toast.success(`Booking was successfully moved to Invoice`)
      },
    })

  const [archiveBookingMutation, { loading: archivingBooking }] = useArchiveBookingMutation({
    onCompleted: (response) => {
      if (!response) {
        toast.error('Something went wrong')
        return
      }

      toast.success('Booking was successfully archived')
    },
  })

  const [makeBookingOnHoldMutation, { loading: makingBookingOnHold }] =
    useMakeBookingOnHoldMutation()

  const [copyBookingMutation, { loading: copingBooking }] = useCopyBookingMutation()

  const [sendDocketDetails, { loading: sendingDocketDetails }] = useSendDocketDetailsMutation()

  const [createScheduledBookingTemplateMutation] = useCreateScheduledBookingTemplateMutation({
    refetchQueries: [{ query: GetBookingDocument, variables: { bookingId: Number(bookingId) } }],
  })

  const [confirmQuickQuoteMutation, { loading: confirmQuickQuoteLoading }] =
    useConfirmQuickQuoteMutation({
      onCompleted: (data) => {
        if (data.confirmQuickQuote) {
          history.push(Paths.quotes.updateWithId(data.confirmQuickQuote.id))
          toast.success('Quick Quote was successfully confirmed')
        }
      },
    })

  function bookQuote() {
    if (bookingId) {
      trigger(undefined, { shouldFocus: true }).then((result) => {
        if (result === true) {
          updateBookingStatusMutation({
            variables: {
              bookingId: Number(bookingId),
              status: BookingStatus.Waiting,
            },
          }).then((response) => {
            if (!response) {
              toast.error('Something went wrong')
              return
            }
            toast.success('Quote was successfully booked')
            if (isAllowedToSetBookingsListToInProgressAfterQuoteIsBooked) {
              bookingsStatusCategoryVar(StatusCategories.InProgress)
            }
            history.push(Paths.bookings.updateWithId(response?.data?.updateBookingStatus.id))
          })
        } else {
          toast.warn('The form contains some errors. Please fix them.')
        }
      })
    }
  }

  function confirmQuickQuote() {
    if (bookingId) {
      confirmQuickQuoteMutation({ variables: { input: { id: bookingId } } })
    }
  }

  function completeBooking() {
    if (bookingId) {
      updateBookingStatusMutation({
        variables: {
          bookingId: Number(bookingId),
          status: BookingStatus.Complete,
        },
      }).then((response) => {
        if (response.data) {
          toast.success(
            `Booking (${response?.data?.updateBookingStatus.id}) was successfully moved to Compete`,
          )
        }
      })
    }
  }

  function moveBookingToInvoice() {
    if (bookingId) {
      createBookingInvoiceMutation({
        variables: {
          input: { bookingIds: [Number(bookingId)] },
        },
      })
    }
  }

  function archiveBooking() {
    if (bookingId) {
      archiveBookingMutation({
        variables: {
          input: {
            id: bookingId,
            isArchived: true,
          },
        },
      })
    }
  }

  function copyBooking() {
    if (bookingId) {
      trigger(undefined, { shouldFocus: true }).then((result) => {
        if (result === true) {
          copyBookingMutation({
            variables: {
              bookingId: Number(bookingId),
            },
          }).then((response) => {
            if (!response) {
              toast.error('Something went wrong')
              return
            }
            if (!isQuote) {
              toast.success('Booking was successfully copied')
              history.push(Paths.bookings.updateWithId(response?.data?.copyBooking))
            } else {
              toast.success('Quote was successfully copied')
              history.push(Paths.quotes.updateWithId(response?.data?.copyBooking))
            }
          })
        } else {
          toast.warn('The form contains some errors. Please fix them.')
        }
      })
    }
  }

  function makeBookingOnHold(isOnHold: boolean) {
    if (bookingId) {
      makeBookingOnHoldMutation({
        variables: {
          input: {
            id: bookingId,
            isOnHold,
          },
        },
      }).then((response) => {
        if (!response) {
          toast.error('Something went wrong')
          return
        }

        toast.success(`Booking was successfully marked as ${isOnHold ? 'On Hold' : 'Active'}`)
      })
    }
  }

  function convertToScheduledTemplate() {
    if (bookingId) {
      createScheduledBookingTemplateMutation({
        variables: {
          bookingId: Number(bookingId),
        },
      }).then((response) => {
        if (!response) {
          toast.error('Something went wrong')
          return
        }

        toast.success('Booking was successfully converted to a Scheduled Booking Template')
      })
    }
  }

  function sendDocketEmails() {
    if (bookingId) {
      // DownloadRunsheet(bookingId)
      //   .then((response) => {
      //     let fileName = GetFileName(response)
      //     if (!fileName) {
      //       fileName = `runsheet-${bookingId}.pdf`
      //     }

      //     SaveFile(response.data, fileName)
      //   })
      //   .catch(ShowRestError)
      sendDocketDetails({
        variables: {
          bookingId: Number(bookingId),
        },
      }).then((response) => {
        if (!response) {
          toast.error('Something went wrong')
          return
        }

        toast.info(response.data?.sendDocketDetailsEmail)
      })
    }
  }

  const handleConfirmCancellation = (cancellationReason: string) => {
    if (bookingId) {
      cancelBookingMutation({
        variables: {
          input: {
            id: bookingId,
            isCancelled: true,
            cancellationReason,
          },
        },
      })
    }
  }

  const handleConfirmQuoteLost = (quoteLostDetail: string, quoteLostReasonId?: string) => {
    if (bookingId && quoteLostReasonId) {
      trigger(undefined, { shouldFocus: true }).then((result) => {
        if (result === true) {
          markQuoteLostMutation({
            variables: {
              input: {
                bookingId,
                quoteLostDetail,
                quoteLostReasonId,
              },
            },
          }).then((response) => {
            if (!response) {
              toast.error('Something went wrong')
              return
            }
            toast.success('Quote was successfully marked as lost')
            history.push(Paths.bookings.all)
          })
        } else {
          toast.warn('The form contains some errors. Please fix them.')
        }
      })
    }
  }

  const handleConfirmCompletion = () => completeBooking()

  const isSplitBookingProcessing =
    cancelingBooking ||
    markingQuoteLost ||
    archivingBooking ||
    updatingBookingStatus ||
    creatingBookingInvoice ||
    makingBookingOnHold ||
    copingBooking ||
    sendingDocketDetails ||
    confirmQuickQuoteLoading

  return {
    bookQuote,
    confirmQuickQuote,
    completeBooking,
    moveBookingToInvoice,
    archiveBooking,
    copyBooking,
    makeBookingOnHold,
    convertToScheduledTemplate,
    sendDocketEmails,
    handleConfirmCancellation,
    handleConfirmQuoteLost,
    handleConfirmCompletion,
    isSplitBookingProcessing,
  }
}

export { useBookingButtonActions }
