import { useMemo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY, Paths } from 'constants/index'
import { TariffPagedDtoFilterInput } from 'generated/graphql'
import { GET_PAGED_TARIFFS } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
  { field: 'type', headerName: 'Type', minWidth: 150 },
  { field: 'category', headerName: 'Category', minWidth: 200, flex: 1 },
  { field: 'baseUnit', headerName: 'Base Unit', minWidth: 150 },
  { field: 'minMiles', headerName: 'Min Miles', minWidth: 150, type: 'number' },
  { field: 'minCharge', headerName: 'Min Charge', minWidth: 150, type: 'number' },
  { field: 'rate', headerName: 'Rate', minWidth: 150, type: 'number' },
  { field: 'customer', headerName: 'Customer', minWidth: 200, flex: 1 },
  {
    field: 'isExternal',
    headerName: 'External',
    width: 100,
    type: 'boolean',
  },
]

const Tariffs = () => {
  const history = useHistory()

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    history.push(Paths.tariffs.updateWithId(id))
  }

  const handleCreateTariff = () => {
    history.push(Paths.tariffs.create)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const filter: TariffPagedDtoFilterInput = useMemo(
    () => ({
      or: [{ name: { contains: searchValue } }],
    }),
    [searchValue],
  )

  return (
    <FplDataGrid
      query={GET_PAGED_TARIFFS}
      entityName='pagedTariffs'
      columns={columns}
      filter={filter}
      defaultOrder={{ field: 'name', sort: 'asc' }}
      toolbar={{
        caption: 'Tariffs',
        leftSide: (
          <Grid item>
            <Button variant='outlined' size='small' onClick={handleCreateTariff}>
              New Tariff
            </Button>
          </Grid>
        ),
        rightSide: (
          <Grid item>
            <Box width='210px'>
              <ControlledTextField
                control={control}
                name='search'
                label='Search'
                defaultValue=''
                size='small'
                endAdornment={
                  searchInput && (
                    <IconButton size='small' onClick={handleSearchClearClick}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  )
                }
              />
            </Box>
          </Grid>
        ),
      }}
      onRowClick={handleRowClick}
    />
  )
}

export default Tariffs
