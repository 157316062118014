import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { styled } from '@mui/material'

const WIDTH = 33
const HEIGHT = 33

const StyledLocalShippingIcon = styled(LocalShippingIcon)(({ theme }) => ({
  position: 'absolute',
  width: WIDTH,
  height: HEIGHT,
  left: -WIDTH / 2,
  top: -HEIGHT / 2,

  borderRadius: HEIGHT,
  backgroundColor: '#ffffffba',
  padding: theme.spacing(0.75),
  boxShadow:
    '0px 2px 4px -1px rgb(0 0 0 / 20%), ' +
    '0px 4px 5px 0px rgb(0 0 0 / 14%), ' +
    '0px 1px 10px 0px rgb(0 0 0 / 12%)',
}))

interface IProps {
  lat: number
  lng: number
}

const DriverIcon = (props: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { lat, lng } = props
  return <StyledLocalShippingIcon color='primary' />
}

export default DriverIcon
