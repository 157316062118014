import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import { FilePreview } from 'components/common'
import { FplDialogTitle } from 'components/common/Dialog'

interface IProps {
  fileId: number | null
  openDialog: boolean
  handleCloseDialog: () => any
}
const FilePreviewDialog = (props: IProps) => {
  const { fileId, openDialog, handleCloseDialog } = props

  const handleClose = () => {
    handleCloseDialog()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby='file-preview-dialog-title'>
      <FplDialogTitle id='file-preview-dialog-title' onClose={handleClose}>
        <Typography paragraph variant='h4'>
          Preview File
        </Typography>
      </FplDialogTitle>

      <DialogContent>{openDialog && <FilePreview fileId={fileId} />}</DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FilePreviewDialog
