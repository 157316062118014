import { useState, useEffect, useMemo, ChangeEvent } from 'react'

import { useFormContext } from 'react-hook-form'

import {
  CreateAndUpdateTariffDtoInput,
  useGetTariffCategoriesQuery,
  TariffCategory,
  useGetVatCategoriesQuery,
  VatCategory,
  useGetCustomersQuery,
  Customer,
  SortEnumType,
  XeroCode,
  useGetXeroCodesQuery,
} from 'generated/graphql'
import {
  getTariffCategoryValues,
  getVatCategoryValues,
  getXeroCodeValuesToLabelName,
  mapCustomerValues,
} from 'helpers'

import CreateTariffCategoryDialog from './CreateTariffCategoryDialog'
import DetailsForm from './DetailsForm'
import GenerateTariffNameDialog from './GenerateTariffNameDialog'

interface IProps {
  tariff?: CreateAndUpdateTariffDtoInput
}

const Details = (props: IProps) => {
  const { tariff } = props
  const [openTariffCategoryDialog, setOpenTariffCategoryDialog] = useState(false)
  const [openGenerateTariffNameDialog, setOpenGenerateTariffNameDialog] = useState(false)
  const [autoCalcMinMiles, setAutoCalcMinMiles] = useState(true)

  const {
    control,
    setValue,
    watch,
    trigger,

    formState: { isSubmitted, isDirty, errors },
  } = useFormContext<CreateAndUpdateTariffDtoInput>()

  // queries
  const {
    data: tariffCategoriesData,
    loading: tariffCategoriesLoading,
    refetch,
  } = useGetTariffCategoriesQuery()

  const { data: vatCategoriesData, loading: vatCategoriesLoading } = useGetVatCategoriesQuery()

  const { data: customersData, loading: customersLoading } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
  })

  const { data: xeroCodesData, loading: xeroCodeLoading } = useGetXeroCodesQuery()

  useEffect(() => {
    if (!tariff?.id) {
      setAutoCalcMinMiles(true)
      return
    }

    const tariffMinMiles = tariff?.minMiles
    const calcMinMiles = Math.round((tariff?.minCharge / tariff?.rate) * 100) / 100
    if (tariffMinMiles === calcMinMiles) {
      setAutoCalcMinMiles(true)
    } else {
      setAutoCalcMinMiles(false)
    }
  }, [tariff])

  const minChargeValue = watch('minCharge')
  const rateValue = watch('rate')
  const isAttachedToAllCustomersValue = watch('isAttachedToAllCustomers')
  const customerIdsValue = watch('customerIds')
  const tariffCategoryIdValue = watch('tariffCategoryId')

  useEffect(() => {
    if (autoCalcMinMiles) {
      const minCharge = Number(minChargeValue)
      const rate = Number(rateValue)
      let minMiles: number

      if (minCharge === 0 || rate === 0) {
        minMiles = 0
      } else {
        minMiles = Math.round((minCharge / rate) * 100) / 100
      }

      if (isDirty) {
        setValue('minMiles', minMiles, { shouldDirty: true, shouldValidate: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minChargeValue, rateValue, autoCalcMinMiles, setValue])

  const allCustomers: boolean = watch('isAttachedToAllCustomers', false)
  useEffect(() => {
    if (isSubmitted) {
      trigger('customerIds')
    }
  }, [allCustomers, trigger, isSubmitted])

  const tariffCategories = useMemo(
    () => getTariffCategoryValues(tariffCategoriesData?.tariffCategories as TariffCategory[]),
    [tariffCategoriesData],
  )

  const vatCategories = useMemo(
    () => getVatCategoryValues(vatCategoriesData?.vatCategories as VatCategory[]),
    [vatCategoriesData],
  )

  const customers = useMemo(
    () => mapCustomerValues(customersData?.customers as Customer[]),
    [customersData],
  )

  const xeroCodes = useMemo(
    () => getXeroCodeValuesToLabelName(xeroCodesData?.xeroCodes as XeroCode[]),
    [xeroCodesData],
  )

  const isGenerateEnable =
    !isAttachedToAllCustomersValue &&
    customerIdsValue &&
    customerIdsValue.length === 1 &&
    tariffCategoryIdValue

  const handleAutoCalcMinMiles = (event: ChangeEvent<HTMLInputElement>) => {
    setAutoCalcMinMiles(event.target.checked)
  }

  const handleAddTariffCategory = () => setOpenTariffCategoryDialog(true)

  const handleCloseTariffCategoryDialog = () => {
    setOpenTariffCategoryDialog(false)
    refetch()
  }

  const handleGenerateTariffName = () => {
    setOpenGenerateTariffNameDialog(true)
  }

  return (
    <>
      <DetailsForm
        allCustomers={allCustomers}
        autoCalcMinMiles={autoCalcMinMiles}
        customers={customers}
        customersLoading={customersLoading}
        control={control}
        errors={errors}
        handleAddTariffCategory={handleAddTariffCategory}
        handleAutoCalcMinMiles={handleAutoCalcMinMiles}
        handleGenerateTariffName={handleGenerateTariffName}
        isGenerateEnable={isGenerateEnable}
        tariffCategories={tariffCategories}
        tariffCategoriesLoading={tariffCategoriesLoading}
        vatCategories={vatCategories}
        vatCategoriesLoading={vatCategoriesLoading}
        xeroCodeLoading={xeroCodeLoading}
        xeroCodes={xeroCodes}
      />
      {openTariffCategoryDialog && (
        <CreateTariffCategoryDialog
          handleCloseDialog={handleCloseTariffCategoryDialog}
          openDialog={openTariffCategoryDialog}
        />
      )}

      {openGenerateTariffNameDialog && (
        <GenerateTariffNameDialog
          open={openGenerateTariffNameDialog}
          setOpen={setOpenGenerateTariffNameDialog}
        />
      )}
    </>
  )
}

export default Details
