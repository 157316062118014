import { PackagingTypeSizePair } from 'types'

export const PackagingSizes: PackagingTypeSizePair = {
  PALLETSUKSTANDARD: {
    width: 100,
    length: 120,
  },
  PALLETSEURO: {
    width: 80,
    length: 120,
  },
}
