import React from 'react'

import { Button, Grid } from '@mui/material'
import { Skeleton } from '@mui/material'
import { DropzoneDialog } from 'react-mui-dropzone'
import { toast } from 'react-toastify'

import { FileConstants } from 'constants/index'
import { ShowDropzoneAlert } from 'helpers'
import { Restricted } from 'providers'
interface IProps {
  loadingFile?: boolean
  imageUri?: string
  onNewLogoSubmitted: (file: File) => void
  onOldLogoRemoved: () => void
}

const CustomerLogo = (props: IProps) => {
  const { imageUri, onNewLogoSubmitted, onOldLogoRemoved, loadingFile } = props
  const [openDialog, setOpenDialog] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  // handlers
  const onImageLoaded = () => setLoading(false)

  const onChangeLogoClick = () => setOpenDialog(true)
  const onRemoveLogoClick = () => {
    onOldLogoRemoved()
  }

  const onSave = (files: File[]) => {
    if (files.length > 0) {
      onNewLogoSubmitted(files[0])
    } else {
      toast.error('Something went wrong!')
    }

    setOpenDialog(false)
  }
  const onClose = () => setOpenDialog(false)

  // components
  const displayLoadingSkeleton = () => <Skeleton variant='rectangular' height='200px' />

  if (loadingFile) {
    return displayLoadingSkeleton()
  }

  return (
    <>
      {loading && displayLoadingSkeleton()}

      <div style={{ display: loading ? 'none' : 'block' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <img src={imageUri} onLoad={onImageLoaded} width='100%' />
          </Grid>

          <Grid item xs={6}>
            <Button variant='outlined' onClick={onChangeLogoClick}>
              Change logo
            </Button>
          </Grid>

          <Restricted to="DeleteCustomerLogo">
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Button variant='outlined' onClick={onRemoveLogoClick}>
                Remove logo
              </Button>
            </Grid>
          </Restricted>
        </Grid>

        <DropzoneDialog
          open={openDialog}
          acceptedFiles={FileConstants.images}
          cancelButtonText={'cancel'}
          submitButtonText={'submit'}
          onSave={onSave}
          showAlerts={false}
          onClose={onClose}
          onAlert={ShowDropzoneAlert}
          maxFileSize={FileConstants.maxFileSize}
          showPreviews
        />
      </div>
    </>
  )
}

export default CustomerLogo
