import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

import { FileCategory } from 'enums'
import { InvoiceType } from 'generated/graphql'
import { getOidcStorage } from 'helpers'

const commonHeaders = () => {
  const storage = getOidcStorage()

  return {
    Authorization: storage?.tokens?.accessToken ? `Bearer ${storage.tokens.accessToken}` : '',
  }
}

const baseUrl = process.env.REACT_APP_GRAPHQL_URL

export function DownloadBookingInvoice(bookingInvoiceId): AxiosPromise<any> {
  const options = {
    method: 'get',
    url: `${baseUrl}/api/reports/DownloadBookingInvoiceForXero`,
    params: {
      id: bookingInvoiceId,
    },
    headers: { ...commonHeaders(), 'Content-Type': 'text/csv' },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadInvoiceByInvoiceNumber(invoiceNumber): AxiosPromise<any> {
  const options = {
    method: 'get',
    url: `${baseUrl}/api/reports/DownloadInvoiceByInvoiceNumber`,
    params: {
      invoiceNumber: invoiceNumber,
    },
    headers: { ...commonHeaders(), 'Content-Type': 'text/csv' },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadRunsheet(bookingId): AxiosPromise<any> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/reports/DownloadRunsheet`,
    params: {
      bookingId: Number(bookingId),
    },
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadBookingInvoices(
  startDate: Date,
  endDate: Date,
  type: InvoiceType,
): AxiosPromise<any> {
  const options = {
    method: 'get',
    url: `${baseUrl}/api/reports/DownloadBookingInvoicesForXero`,
    params: { startDate, endDate, type },
    headers: { ...commonHeaders(), 'Content-Type': 'text/csv' },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadDriverInvoice(driverInvoiceId): AxiosPromise<any> {
  const options = {
    method: 'get',
    url: `${baseUrl}/api/reports/DownloadDriverInvoiceForXero`,
    params: { id: driverInvoiceId },
    headers: { ...commonHeaders(), 'Content-Type': 'text/csv' },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadCustomerIncomeReport(type, data): AxiosPromise<any> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/reports/DownloadCustomerIncomeReport`,
    params: { type },
    data,
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadMyIncomeReport(type, data): AxiosPromise<any> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/reports/DownloadMyIncomeReport`,
    params: { type },
    data,
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadCustomerContactListReport(type, data): AxiosPromise<any> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/reports/DownloadCustomerContactListReport`,
    params: { type },
    data,
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadDriverListReport(type, data): AxiosPromise<any> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/reports/DownloadDriverListReport`,
    params: { type },
    data,
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadSalesCommissionReport(type, data): AxiosPromise<any> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/reports/DownloadSalesCommissionReport`,
    params: { type },
    data,
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadProofOfDeliveryReport(type, data): AxiosPromise<any> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/reports/DownloadProofOfDeliveryReport`,
    params: { type },
    data,
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadFile(fileId): AxiosPromise<any> {
  const options = {
    method: 'get',
    url: `${baseUrl}/api/files/download`,
    params: { fileId },
    headers: { ...commonHeaders() },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function DownloadFileAsArrayBuffer(fileId): AxiosPromise<any> {
  const options = {
    method: 'get',
    url: `${baseUrl}/api/files/download`,
    params: { fileId },
    headers: { ...commonHeaders() },
    responseType: 'arraybuffer',
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadBookingAttachment(bookingId: number, data: any): AxiosPromise<Array<number>> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/files/UploadBookingAttachment`,
    params: { bookingId },
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function ImportDriversFile(data: any): AxiosPromise<string[]> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/files/UploadDriverListImport`,
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function ImportBookingsFile(data: any): AxiosPromise<string[]> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/files/UploadBookingListImport`,
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function ImportDriversCodeFile(data: any): AxiosPromise<string[]> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/files/UploadDriversXeroCode`,
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function ExportDriversCodeFile(): AxiosPromise<string[]> {
  const options = {
    method: 'get',
    url: `${baseUrl}/api/Reports/DownloadDriversXeroCode`,
    headers: { ...commonHeaders(), 'Content-Type': 'text/csv' },
    responseType: 'blob',
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadDriverInvoices(fileData): AxiosPromise<Array<any>> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/files/UploadExternalDriverInvoice`,
    data: fileData,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadBookingAddressAttachment(
  bookingAddressId: number,
  data: any,
  category: FileCategory | null | undefined = FileCategory.BookingDocument,
): AxiosPromise<Array<number>> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/files/UploadBookingAddressAttachment`,
    params: { id: bookingAddressId, category: category },
    data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig
  return axios(options)
}

export function UploadCustomerLogo(customerId: number, data: any): AxiosPromise<Array<any>> {
  const options = {
    method: 'POST',
    url: `${baseUrl}/api/files/UploadCustomerLogo`,
    params: { customerId },
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadUserAvatar(userId: number, data: any): AxiosPromise<Array<number>> {
  const options = {
    method: 'POST',
    url: `${baseUrl}/api/files/UploadUserAvatar`,
    params: { userId },
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadProfileAvatar(data: any): AxiosPromise<Array<any>> {
  const options = {
    method: 'POST',
    url: `${baseUrl}/api/files/UploadProfileAvatar`,
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadTariffIcon(tariffIconId: number, data: any): AxiosPromise<Array<any>> {
  const options = {
    method: 'POST',
    url: `${baseUrl}/api/files/UploadTariffIcon`,
    params: { tariffIconId },
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadBanner(bannerId: number, data: any): AxiosPromise<Array<any>> {
  const options = {
    method: 'POST',
    url: `${baseUrl}/api/files/UploadPromotion`,
    params: { promotionId: bannerId },
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}

export function UploadPotentialBookingFile(data: any): AxiosPromise<string[]> {
  const options = {
    method: 'post',
    url: `${baseUrl}/api/files/UploadPotentialBookingFile`,
    data: data,
    headers: { ...commonHeaders() },
  } as AxiosRequestConfig

  return axios(options)
}
