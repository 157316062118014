// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  Driver,
  EntityConstants,
  DriverDetailsPhonePattern,
  DigitPattern,
  AlphanumericAndSpacePattern,
} from 'constants/index'

import { DriverType, PaymentTerm } from 'generated/graphql'
import Joi from 'joi'

const messages = {
  'string.base': 'Field must be a string',
  'string.email': 'Invalid email format',
  'string.max': `Field must be at most {#limit} characters`,
  'string.pattern.base': 'Please enter a valid phone number',
  'number.base': 'Field must be a number',
  'number.min': 'Value must be greater than or equal to {#limit}',
}

function validationSchema() {
  return Joi.object({
    name: Joi.string()
      .required()
      .max(EntityConstants.MAX_NAME_LENGTH)
      .messages(messages)
      .label('Name'),
    firstName: Joi.string()
      .required()
      .max(EntityConstants.MAX_FIRST_NAME_LENGTH)
      .messages(messages)
      .label('First Name'),
    lastName: Joi.string()
      .required()
      .max(EntityConstants.MAX_FIRST_NAME_LENGTH)
      .messages(messages)
      .label('Last Name'),
    swift: Joi.string().allow(null, '').optional(),
    xeroCodeId: Joi.number().allow(null, '').optional(),
    xeroPurchaseCode: Joi.string().required().pattern(new RegExp(DigitPattern)).messages({
      'string.base': 'Purchase Code is a mandatory field',
      'string.empty': 'Purchase Code is a mandatory field',
      'string.pattern.base': 'Only digits are allowed',
    }),
    addressLine1: Joi.string()
      .required()
      .max(EntityConstants.MAX_ADDRESS_LENGTH)
      .messages(messages)
      .label('Address 1'),
    addressLine2: Joi.string()
      .max(EntityConstants.MAX_ADDRESS_LENGTH)
      .allow(null, '')
      .messages(messages),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .max(EntityConstants.MAX_ADDRESS_LENGTH)
      .messages(messages)
      .label('Email'),
    invoiceEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .optional()
      .allow('', null)
      .max(EntityConstants.MAX_ADDRESS_LENGTH)
      .messages(messages),
    driverType: Joi.string()
      .valid(...Object.values(DriverType))
      .required()
      .messages(messages),
    city: Joi.string()
      .required()
      .max(EntityConstants.MAX_CITY_LENGTH)
      .messages(messages)
      .label('City'),
    postcode: Joi.string()
      .required()
      .max(EntityConstants.MAX_POSTCODE_LENGTH)
      .messages(messages)
      .label('Post Code'),
    telephoneNumber: Joi.string()
      .required()
      .pattern(new RegExp(DriverDetailsPhonePattern))
      .messages(messages)
      .label('Telephone Number'),
    startDate: Joi.date().required().messages(messages),

    currencyId: Joi.number().allow(null, '').min(0).messages(messages).label('Currency'),
    alternativeTelephoneNumber: Joi.string()
      .allow(null, '')
      .optional()
      .pattern(new RegExp(DriverDetailsPhonePattern))
      .messages(messages)
      .label('Alternative Telephone Number'),
    startDate: Joi.date().required(),
    countryId: Joi.number().allow(null, '').min(1).required().messages({
      'number.min': 'Country is a required field',
      'any.required': 'Country is a required field',
    }),
    paymentTerm: Joi.string()
      .valid(...Object.values(PaymentTerm))
      .required(),
    callSign: Joi.string().max(Driver.MAX_CALL_SIGN_LENGTH).allow(null, ''),
    courierExchangeMemberId: Joi.string().max(Driver.MAX_CALL_SIGN_LENGTH).allow(null, ''),
    postCode: Joi.string().max(EntityConstants.MAX_POSTCODE_LENGTH),
    isOnboardingCompleted: Joi.boolean().optional().allow(null),
    bankName: Joi.string()
      .max(Driver.MAX_BANK_NAME_LENGTH)
      .optional()
      .allow('', null)
      .messages({
        'string.max': `Bank Name must be at most ${Driver.MAX_BANK_NAME_LENGTH} characters`,
      }),
    bankAccountNumber: Joi.string()
      .length(Driver.MAX_BANK_ACCOUNT_NUMBER_LENGTH)
      .optional()
      .allow('', null)
      .pattern(new RegExp(DigitPattern))
      .messages({
        'string.length': `Bank Account Number must contain ${Driver.MAX_BANK_ACCOUNT_NUMBER_LENGTH} characters`,
        'string.pattern.base': 'Only digits are allowed.',
      }),
    bankAccountHolderName: Joi.string()
      .max(Driver.MAX_BANK_ACCOUNT_HOLDER_NAME_LENGTH)
      .optional()
      .allow('', null)
      .pattern(new RegExp(AlphanumericAndSpacePattern))
      .messages({
        'string.max': `Bank Account Holder Name must be at most ${Driver.MAX_BANK_ACCOUNT_HOLDER_NAME_LENGTH} characters`,
        'string.pattern.base':
          'Only alphanumeric characters, spaces and dashes are allowed. Numeric characters are forbidden.',
      }),
    internationalBankAccountNumber: Joi.string()
      .max(Driver.MAX_BANK_INTERNATIONAL_ACCOUNT_NUMBER_LENGTH)
      .optional()
      .allow('', null)
      .messages({
        'string.max': `IBAN must be at most ${Driver.MAX_BANK_INTERNATIONAL_ACCOUNT_NUMBER_LENGTH} characters`,
      }),
    bankSortCode: Joi.string()
      .length(EntityConstants.MAX_BANK_SORT_CODE_LENGTH)
      .optional()
      .allow('', null)
      .pattern(new RegExp(DigitPattern))
      .messages({
        'string.length': `Bank sort code must contain ${EntityConstants.MAX_BANK_SORT_CODE_LENGTH} characters`,
        'string.pattern.base': 'Only digits are allowed',
      }),
    vatCategoryId: Joi.string().required().label('Vat category'),

    vatIdNumber: Joi.string()
      .max(EntityConstants.MAX_VAT_ID_NUMBER_LENGTH)
      .optional()
      .allow('', null)
      .messages({
        'string.max': `Vat Reg. Num must be at most ${EntityConstants.MAX_VAT_ID_NUMBER_LENGTH} characters`,
      }),
    isActive: Joi.boolean().allow('', null).optional(),
    isDropCharge: Joi.boolean().allow('', null).optional(),
    eoriNumber: Joi.string().allow('', null).optional(),

    publicLiabilityExpirationDate: Joi.date().allow('', null).optional().messages(messages),

    companyRegistrationNumber: Joi.string()
      .max(EntityConstants.MAX_GENERIC_TEXT_LENGTH)
      .optional()
      .allow('', null)
      .messages({
        'string.max': `Company Registration Number must be at most ${EntityConstants.MAX_GENERIC_TEXT_LENGTH} characters`,
      }),
  })
}

export default validationSchema
