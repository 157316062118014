import { useEffect } from 'react'

import { useOidc } from '@axa-fr/react-oidc-context'
import { Container, Stack, Typography, Box, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Paths } from 'constants/index'

import { PrimaryButton } from '../common'
import SignInActions from './SignInActions'

const WelcomePage = () => {
  const history = useHistory()
  const { login, isAuthenticated } = useOidc()

  useEffect(() => {
    if (!isAuthenticated && window.location.href.indexOf(Paths.auth.welcome) === -1) {
      const url = new URL(window.location.href)

      login(`${url.pathname}${url.hash}`)
    }
  }, [login, isAuthenticated])

  const handleRegisterAccount = () => {
    window.location.replace('https://firstpointlogistics.co.uk/register-for-an-account/')
  }

  const handleRequestOnlineAccess = () => {
    history.push(Paths.auth.signUp)
  }

  return (
    <>
      <Container maxWidth='lg'>
        <Stack alignItems='center' textAlign='center' marginTop={5} marginBottom={8}>
          <Typography fontSize='32px' fontFamily='Goldplay-SemiBold, sans-serif'>
            <Box component='span'>Welcome to your Firstpoint&nbsp;</Box>
            <Box component='span' color='#c6017e'>
              online account sign in page
            </Box>
          </Typography>

          <Box marginTop={2.5} marginBottom={7}>
            <Typography fontSize='20px' fontFamily='Goldplay-SemiBold, sans-serif'>
              Existing users, sign in below:
            </Typography>
          </Box>

          <Box width='350px'>
            <SignInActions />
          </Box>
        </Stack>
      </Container>

      <Box sx={{ backgroundColor: '#f2f2f2' }}>
        <Container maxWidth='lg'>
          <Box marginY='12px' sx={{ textAlign: 'center' }}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  paddingRight: { xs: 0, md: 4 },
                  paddingY: { xs: 2.5, md: 0 },
                  marginY: { xs: 0, md: 2.5 },
                  borderRight: { xs: 'none', md: '#d9d9d9 1px solid' },
                  borderBottom: { xs: '#d9d9d9 1px solid', md: 'none' },
                }}>
                <Stack justifyContent='space-between' alignItems='center' height='100%'>
                  <Box display='flex' alignItems='center' minHeight='60px'>
                    <Typography
                      fontSize='20px'
                      fontFamily='Goldplay-SemiBold, sans-serif'
                      sx={{
                        background: 'linear-gradient(to right, #471d7c, #c6007e)',
                        backgroundClip: 'text',
                        color: 'transparent',
                      }}>
                      Don&apos;t have an account with us?
                    </Typography>
                  </Box>

                  <Box marginTop={1} marginBottom={4}>
                    <Typography fontSize='16px' fontFamily='Goldplay-Regular, sans-serif'>
                      Its easy to sign up with Firstpoint. Leave your details via this link and one
                      of our friendly team will be in touch to set you up. You can rely on
                      Firstpoint to deliver more than just your consignments.
                    </Typography>
                  </Box>

                  <Box width='350px'>
                    <PrimaryButton onClick={handleRegisterAccount}>
                      Register for an account
                    </PrimaryButton>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} sx={{ paddingLeft: { sx: 0, md: 4 }, marginY: 2.5 }}>
                <Stack justifyContent='space-between' alignItems='center' height='100%'>
                  <Box display='flex' alignItems='center' minHeight='60px'>
                    <Typography
                      fontSize='20px'
                      fontFamily='Goldplay-SemiBold, sans-serif'
                      sx={{
                        background: 'linear-gradient(to right, #471d7c, #c6007e)',
                        backgroundClip: 'text',
                        color: 'transparent',
                      }}>
                      Want to get your online access set up for an existing account?
                    </Typography>
                  </Box>

                  <Box marginTop={1} marginBottom={4}>
                    <Typography fontSize='16px' fontFamily='Goldplay-Regular, sans-serif'>
                      Existing Firstpoint customers can activate their online account by clicking
                      the button below. One of our team will have you set up in a jiffy and guide
                      you through the entire process with ease.
                    </Typography>
                  </Box>

                  <Box width='350px'>
                    <PrimaryButton onClick={handleRequestOnlineAccess}>
                      Request online access
                    </PrimaryButton>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default WelcomePage
