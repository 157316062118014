import { Typography } from '@mui/material'
import { GridSortItem } from '@mui/x-data-grid'

import {
  BookingInvoiceBatchLineFilterInput,
  useGetBookingInvoiceBatchQuery,
} from 'generated/graphql'
import { GET_BOOKING_INVOICE_BATCH_LINES } from 'graphql/queries/BookingInvoiceBatchedQueries'
import { useGetInvoiceBatchLinesColummns } from 'hooks/accountsPayable/useGetInvoiceBatchLinesColumns'
import ExpandableGrid from '../common/ExpandableDataGrid/ExpandanbleGrid'
import AccountPayableCompactedGrid from './AccountPayableCompactedGrid'
import { ExtractValidationErrorMessages } from 'helpers'

const descendingOrderById: GridSortItem = {
  field: 'id',
  sort: 'desc',
}

interface IProps {
  batchId: number
}

function BatchDetailsGrid({ batchId }: IProps) {
  const columns = useGetInvoiceBatchLinesColummns()

  const filterByBatchId: BookingInvoiceBatchLineFilterInput = {
    batchId: { eq: Number(batchId) },
  }

  const { data: bookingInvoiceBatchData } = useGetBookingInvoiceBatchQuery({
    variables: { id: Number(batchId) },
  })
  const bookingInvoiceBatch = bookingInvoiceBatchData?.bookingInvoiceBatch
  const isSentToXero = bookingInvoiceBatch?.isSentToXero
  const isSuccess = bookingInvoiceBatch?.isSuccess
  const errorMessage = bookingInvoiceBatch?.errorMessage

  const formattedErrorMessage = errorMessage && ExtractValidationErrorMessages(errorMessage)

  return (
    <>
      <Typography variant='h6' component='h6' marginBottom={2}>
        Invoice Batches Details - Batch ID {batchId}
      </Typography>
      <Typography variant='h6' component='h6' marginBottom={2}>
        Sent to Xero? - {isSentToXero ? 'Yes' : 'No'}
      </Typography>
      <Typography variant='h6' component='h6' marginBottom={2}>
        Is successful? - {isSuccess ? 'Yes' : 'No'}
      </Typography>
      {errorMessage && (
        <Typography variant='h6' component='h6' marginBottom={2}>
          Error Message - {formattedErrorMessage || errorMessage}
        </Typography>
      )}
      <ExpandableGrid
        entityName='bookingInvoiceBatchLines'
        query={GET_BOOKING_INVOICE_BATCH_LINES}
        filter={filterByBatchId}
        columns={columns}
        expandedItem={<AccountPayableCompactedGrid />}
        defaultOrder={descendingOrderById}
      />
    </>
  )
}

export default BatchDetailsGrid
