import { useState } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'

import OtherMiscellaneous from './OtherMiscellaneous'
import { QuoteLostReasons } from './QuoteLostReasons'

function commonProps(index: TabId) {
  return {
    id: `setting-miscellaneous-tab-${index}`,
    'aria-controls': `setting-miscellaneous-tabpanel-${index}`,
  }
}

enum TabId {
  QuoteLostReasons = 0,
  OtherMiscellaneous,
}

const MiscellaneousTab = () => {
  const [tabValue, setTabValue] = useState(0)

  const tabs = {
    quoteLostReaons: { id: TabId.QuoteLostReasons, label: 'Quote Lost Reasons' },
    otherMiscellaneous: { id: TabId.OtherMiscellaneous, label: 'Other Miscellaneous' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='settings miscellaneous tabs'
        indicatorColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
          width: (theme) => theme.spacing(27),
        }}>
        <Tab
          label={tabs.quoteLostReaons.label}
          key={tabs.quoteLostReaons.id}
          {...commonProps(tabs.quoteLostReaons.id)}
        />
        <Tab
          label={tabs.otherMiscellaneous.label}
          key={tabs.otherMiscellaneous.id}
          {...commonProps(tabs.otherMiscellaneous.id)}
        />
      </Tabs>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.quoteLostReaons.id}
        key={tabs.quoteLostReaons.id}
        px={3}>
        <QuoteLostReasons />
      </VerticalTabPanel>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.otherMiscellaneous.id}
        key={tabs.otherMiscellaneous.id}
        px={3}>
        <OtherMiscellaneous />
      </VerticalTabPanel>
    </Box>
  )
}

export default MiscellaneousTab
