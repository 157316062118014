import React from 'react'

import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledPostCode, ControlledTextField } from 'components/common'
import { ControlledCountryAutocomplete } from 'components/common/controller-wrapper'
import { AutoFillAddressDialog } from 'components/common/Dialog'
import {
  Address,
  CreateAndUpdateAddressDtoInput,
  useGetLoqateRetrieveLazyQuery,
} from 'generated/graphql'

const AddressForm = () => {
  const [openAutoFillAddress, setOpenAutoFillAddress] = React.useState(false)

  const methods = useFormContext<CreateAndUpdateAddressDtoInput>()
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods
  const postcode = watch('postcode')
  const countryId = watch('countryId')

  // ToDo: add loading
  const [getLoqateRetrieve] = useGetLoqateRetrieveLazyQuery({
    onCompleted: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetAddressDetails(data.loqateRetrieve as any)
    },
  })

  const handleAutoFillClick = () => {
    setOpenAutoFillAddress(true)
  }

  const handleAutoFillAddressSelect = (id: string) => {
    setOpenAutoFillAddress(false)
    getLoqateRetrieve({ variables: { id } })
  }

  const resetAddressDetails = React.useCallback(
    (address: Address) => {
      setValue('name', address.name)
      setValue('city', address.city)
      setValue('addressLine1', address.addressLine1)
      setValue('addressLine2', address.addressLine2)
      setValue('postcode', address.postcode)
      address.email && setValue('telephoneNumber', address.email)
      setValue('countryId', address.countryId)
      address.telephoneNumber && setValue('telephoneNumber', address.telephoneNumber)
      address.contact && setValue('telephoneNumber', address.contact)
    },
    [setValue],
  )

  return (
    <>
      <Box paddingTop={1}>
        <Grid container columnSpacing={3} rowSpacing={1.5}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Title/Company'
              name='name'
              required
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Town/City'
              name='city'
              required
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Address Line 1'
              name='addressLine1'
              required
              error={!!errors.addressLine1}
              helperText={errors.addressLine1?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Address Line 2'
              name='addressLine2'
              error={!!errors.addressLine2}
              helperText={errors.addressLine2?.message}
            />
          </Grid>

          <Grid item xs={6}>
            <ControlledPostCode
              control={control}
              name='postcode'
              onAutoFillClick={handleAutoFillClick}
              required
              error={!!errors.postcode}
              helperText={errors.postcode?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Email'
              name='email'
              type='email'
              defaultValue=''
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledCountryAutocomplete
              control={control}
              required
              error={!!errors.countryId}
              helperText={errors.countryId?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Telephone Number'
              name='telephoneNumber'
              error={!!errors.telephoneNumber}
              helperText={errors.telephoneNumber?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Contact'
              name='contact'
              error={!!errors.contact}
              helperText={errors.contact?.message}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledCheckbox control={control} label='Mark as Default' name='isDefault' />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledCheckbox control={control} label='Auto Fill' name='isAutoFill' />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledCheckbox
              control={control}
              label='Disable notification for this location'
              name='isNotification'
            />
          </Grid>
        </Grid>
      </Box>

      {openAutoFillAddress && (
        <AutoFillAddressDialog
          open={openAutoFillAddress}
          setOpen={setOpenAutoFillAddress}
          postcode={postcode}
          countryId={countryId}
          onSelectAddress={handleAutoFillAddressSelect}
        />
      )}
    </>
  )
}

export default AddressForm
