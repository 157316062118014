import { useEffect, useState } from 'react'

import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { ControlledDateTimePicker } from 'components/common'
import { DATE_TIME_FORMAT, Mode, PARAM_NEW } from 'constants/index'
import { isNumber } from 'helpers'
import { Restricted } from 'providers'
import { TBookingForm } from 'types/form-types'

const AcceptancePeriod = () => {
  const [showDatePicker, setShowDatePicker] = useState(false)

  const { id } = useParams<{ id?: string }>()
  const { control, watch } = useFormContext<TBookingForm>()

  const validUntilValue = watch('validUntil')

  // ToDo: move to custom hook
  let mode: number | undefined

  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  useEffect(() => {
    if (mode === Mode.Create) {
      setShowDatePicker(true)
    }
  }, [mode])

  const handleEditClick = () => {
    setShowDatePicker(true)
  }

  const handleDoneClick = () => {
    setShowDatePicker(false)
  }

  return (
    <Grid container justifyContent={{ xs: 'start', sm: 'end' }} alignItems='center'>
      <Restricted
        to={'EditQuoteAcceptancePeriod'}
        fallback={
          mode === Mode.Update ? (
            <>
              <Grid item>
                <Typography variant='h6' color='secondary'>
                  Valid until:&nbsp;
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant='h6' color='secondary'>
                  {!showDatePicker &&
                    validUntilValue &&
                    format(new Date(validUntilValue), DATE_TIME_FORMAT)}
                </Typography>
              </Grid>
            </>
          ) : undefined
        }>
        <Grid item>
          <Typography variant='h6' color='secondary'>
            Valid until:&nbsp;
          </Typography>
        </Grid>

        <Grid item>
          <Stack direction='row' alignItems='center' height='40px'>
            <Typography variant='h6' color='secondary'>
              {!showDatePicker &&
                validUntilValue &&
                format(new Date(validUntilValue), DATE_TIME_FORMAT)}
            </Typography>

            <Box hidden={!showDatePicker} maxWidth='200px'>
              <ControlledDateTimePicker
                control={control}
                name='validUntil'
                defaultValue=''
                size='small'
              />
            </Box>

            <Box ml={1}>
              {mode === Mode.Update &&
                (!showDatePicker ? (
                  <IconButton size='small' onClick={handleEditClick}>
                    <EditIcon />
                  </IconButton>
                ) : (
                  <IconButton size='small' onClick={handleDoneClick}>
                    <DoneIcon />
                  </IconButton>
                ))}
            </Box>
          </Stack>
        </Grid>
      </Restricted>
    </Grid>
  )
}

export default AcceptancePeriod
