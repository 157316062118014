import { useEffect, useMemo } from 'react'

import { Grid, Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledCurrencyAutocomplete,
  ControlledSelector,
  ControlledTextField,
  ISelectorOptions,
} from 'components/common'
import {
  CreateAndUpdateDriverDtoInput,
  useGetCurrenciesQuery,
  useGetPaymentTermsQuery,
} from 'generated/graphql'

function FinancialInformationStep() {
  const spacing = 2
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()

  const { data: currenciesData, loading: currenciesLoading } = useGetCurrenciesQuery()
  const { data: paymentTermsData, loading: paymentTermsDataLoading } = useGetPaymentTermsQuery()

  const paymentTermsItems: ISelectorOptions = useMemo(
    () =>
      paymentTermsData?.paymentTerms.map((t) => ({
        value: t.paymentTerm,
        label: t.description,
      })) || [],
    [paymentTermsData],
  )

  useEffect(() => {
    if (currenciesData) {
      const baseCurrency = currenciesData.currencies.find((c) => c.isBaseCurrency)
      baseCurrency && setValue('currencyId', baseCurrency.id)
    }
  }, [currenciesData, setValue])

  return (
    <Grid container spacing={spacing} justifyContent='center'>
      <Grid container spacing={spacing} item xs={12} sm={6}>
        <Grid item xs={12} sm={6}>
          <ControlledCurrencyAutocomplete
            control={control}
            options={currenciesData?.currencies || []}
            loading={currenciesLoading}
            error={!!errors.currencyId}
            helperText={errors.currencyId?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankName'
            label='Bank Name'
            error={!!errors.bankName}
            helperText={errors.bankName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankAccountNumber'
            label='Bank Acc No'
            error={!!errors.bankAccountNumber}
            helperText={errors.bankAccountNumber?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankAccountHolderName'
            label='Bank Account Holder Name'
            error={!!errors.bankAccountHolderName}
            helperText={errors.bankAccountHolderName?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankSortCode'
            label='Bank Sort Code'
            error={!!errors.bankSortCode}
            helperText={errors.bankSortCode?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='internationalBankAccountNumber'
            label='Bank IBAN'
            error={!!errors.internationalBankAccountNumber}
            helperText={errors.internationalBankAccountNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='swift'
            label='SWIFT/BIC'
            error={!!errors.swift}
            helperText={errors.swift?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='companyRegistrationNumber'
            label='Company Registration Number'
            error={!!errors.companyRegistrationNumber}
            helperText={errors.companyRegistrationNumber?.message}
          />
        </Grid>
        <Grid item xs={12}>
          {paymentTermsDataLoading ? (
            <Skeleton variant='rounded' animation='wave' height={22} />
          ) : (
            <ControlledSelector
              control={control}
              label='Payment Terms'
              name='paymentTerm'
              required
              readOnly
              options={paymentTermsItems}
              error={!!errors.paymentTerm}
              helperText={errors.paymentTerm?.message}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FinancialInformationStep
