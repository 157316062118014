import { gql } from '@apollo/client'

import {
  StrategicPartnerContactDetailsFragment,
  StrategicPartnerDetailsFragment,
} from 'graphql/fragments'

export const GET_STRATEGIC_PARTNERS = gql`
  query GetStrategicPartners(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [StrategicPartnerSortInput!]
    $where: StrategicPartnerFilterInput
  ) {
    strategicPartners(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          isEnabled
          name
          addressLine1
          addressLine2
          city
          postcode
          email
          country {
            id
            name
          }
          telephoneNumber
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_STRATEGIC_PARTNER = gql`
  query GetStrategicPartner($strategicPartnerId: Long!) {
    strategicPartner(strategicPartnerId: $strategicPartnerId) {
      ...StrategicPartnerDetails
    }

    ${StrategicPartnerDetailsFragment}
  }
`

export const GET_STRATEGIC_PARTNER_CONTACTS = gql`
  query GetStrategicPartnerContacts(
    $strategicPartnerId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [StrategicPartnerContactSortInput!]
    $where: StrategicPartnerContactFilterInput
  ) {
    strategicPartnerContacts(
      strategicPartnerId: $strategicPartnerId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          department
          telephoneNumber
          email
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_STRATEGIC_PARTNER_CONTACT = gql`
  query GetStrategicPartnerContact($strategicPartnerContactId: Long!) {
    strategicPartnerContact(strategicPartnerContactId: $strategicPartnerContactId) {
      ...StrategicPartnerContactDetails
    }

    ${StrategicPartnerContactDetailsFragment}
  }
`
