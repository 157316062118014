import { gql } from '@apollo/client'

export const UserProfileFragment = gql`
  fragment UserProfile on UserProfileDto {
    country
    countryId
    city
    email
    lastName
    firstName
    phoneNumber
    userName
  }
`
