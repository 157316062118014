import { gql } from '@apollo/client'

import { UserProfileFragment } from 'graphql/fragments'

export const GET_PAGED_USERS_QUERY = gql`
  query GetUsers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [IdentityUserDtoSortInput!]
    $where: IdentityUserDtoFilterInput
  ) {
    users(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          userName
          firstName
          lastName
          role
          address
          email
          emailConfirmed
          isEnabled
          lastLoggedIn
          createdDate
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_USER_DETAILS_QUERY = gql`
  query getUserDetails($id: Long!) {
    userDetails(userId: $id) {
      id
      userName
      role
      firstName
      lastName
      countryId
      city
      email
      postcode
      dob
      phoneNumber
      driverId
      customerId
      companyDetails {
        companyName
        customerId
        contactId
      }
    }
  }
`

export const GET_APPROVE_DECLINE_USER_DETAILS_QUERY = gql`
  query getApproveDeclineUserDetails($id: Long!) {
    userDetails(userId: $id) {
      id
      email
      userName
      firstName
      lastName
      phoneNumber
      role
      emailConfirmed
      suggestedCompany
      driverId
    }
  }
`

export const GET_PROFILE_AVATAR_QUERY = gql`
  query getProfileAvatar {
    myProfile {
      id
      firstName
      lastName
      avatarUri
      city
      country
    }
  }
`

export const GET_MY_PROFILE_QUERY = gql`
  query getMyProfile{
    myProfile {
      id
      ...UserProfile
    }
    ${UserProfileFragment}
  }
`
