import { Typography } from '@mui/material'
import { styled, Box } from '@mui/material'

interface IProps {
  urls: Array<string>
}

const StyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

function Photos({ urls }: IProps) {
  if (!urls || urls.length === 0) {
    return <Typography>No images to be displayed...</Typography>
  }

  return (
    <StyledBox>
      {urls.map((x) => (
          <img
            style={{ width: '500px', marginBottom: '15px' }}
            key={x}
            src={x}
            alt='Image Alt'
            loading='lazy'
          />
        ))}
    </StyledBox>
  )
}

export default Photos
