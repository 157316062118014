import React from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
import { colorBrewer } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import { FplDialogTitle } from 'components/common/Dialog'
import { useGetAuditRecordQuery } from 'generated/graphql'

interface IProps {
  selectedAuditRecordId: number | null
  openDialog: boolean
  handleCloseDialog: () => any
}

const ViewAuditRecord = (props: IProps) => {
  const { selectedAuditRecordId, openDialog, handleCloseDialog } = props
  const [title, setTitle] = React.useState<string>('')
  const [data, setData] = React.useState<string>('')

  const { loading } = useGetAuditRecordQuery({
    variables: {
      auditRecordId: selectedAuditRecordId,
    },
    onCompleted: (data) => {
      setTitle(`Changes to ${data?.auditRecord?.name} after ${data?.auditRecord?.action}`)

      const json = JSON.parse(data?.auditRecord?.columnValues ?? '{}')
      const text = JSON.stringify(json, null, 2)
      setData(text)
    },
  })

  const handleClose = () => {
    handleCloseDialog()
  }

  SyntaxHighlighter.registerLanguage('json', json)

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby='form-contact-dialog-title'>
      <FplDialogTitle id='form-contact-dialog-title' onClose={handleClose}>
        <Typography paragraph variant='h4'>
          {title}
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <Box>
          <Typography>Audit Record ID: {selectedAuditRecordId}</Typography>
        </Box>
        {loading ? (
          <Box component='div' height={200} width='100%' display='flex' justifyContent='center'>
            <CircularProgress size={150} style={{ marginTop: 10 }} variant='indeterminate' />
          </Box>
        ) : (
          <SyntaxHighlighter language='json' style={colorBrewer}>
            {data}
          </SyntaxHighlighter>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewAuditRecord
