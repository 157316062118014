import { AddBox } from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { CreateAndUpdateCongestionZonePostcodeDtoInput } from 'generated/graphql'

import PostcodeRow from './PostcodeRow'

export interface IPostcodeActions {
  onAdd: (name) => void
  onEdit: (id, name) => void
  onDelete: (id) => void
}

interface IProps {
  postcodes: Array<CreateAndUpdateCongestionZonePostcodeDtoInput>
  postcodeActions: IPostcodeActions
}

export default function Postcodes(props: IProps) {
  const { postcodes, postcodeActions } = props

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      filter: '',
    },
  })
  const [filter] = useDebounce(watch('filter', ''), DEBOUNCE_DELAY)
  const filterValue: string | null = filter.length >= 2 ? filter : null

  const handleAddPostcodeClick = () => {
    postcodeActions.onAdd(filterValue?.toUpperCase())

    setValue('filter', '')
  }

  return (
    <>
      <Toolbar>
        <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography
              sx={{
                marginRight: 3,
                width: '100',
                maxWidth: 150,
              }}
              variant='h6'
              id='tableCaption'
              component='div'
              noWrap>
              Postcodes
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', flex: '1 1', justifyContent: 'flex-end' }}>
              <ControlledTextField
                control={control}
                label='Filter/Add'
                name='filter'
                endAdornment={
                  <IconButton disabled={!filterValue} size='large' onClick={handleAddPostcodeClick}>
                    <AddBox />
                  </IconButton>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer style={{ height: 250, width: '100%' }}>
        <Table stickyHeader aria-label='post-code table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {postcodes
              .filter((p) => !filterValue || p.name.includes(filterValue.toUpperCase()))
              .map((row, index) => (
                <PostcodeRow
                  key={`postcode-row-${row.id}-${index}`}
                  id={row.id ?? ''}
                  name={row.name}
                  onEdit={postcodeActions.onEdit}
                  onDelete={postcodeActions.onDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
