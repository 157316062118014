import { useState } from 'react'

import { AppBar, Tab, Tabs } from '@mui/material'

import { TabPanel } from 'components/common'

import Photos from './tabs/Photos'
import PodDocument from './tabs/PodDocument'

enum TabId {
  Pod = 0,
  Signature = 1,
  DeliveryPhoto = 2,
  CollectionPhoto = 3,
}
const tabsList = [
  { id: TabId.Pod, label: 'Pod', hash: '#pod' },
  { id: TabId.Signature, label: 'Signature', hash: '#pod-signature' },
  {
    id: TabId.DeliveryPhoto,
    label: 'Delivery Photo',
    hash: '#pod-delivery-photo',
  },
  {
    id: TabId.CollectionPhoto,
    label: 'Collection Photo',
    hash: '#pod-delivery-photo',
  },
]
function commonProps(index: any) {
  return {
    id: `dashboard-tab-${index}`,
    'aria-controls': `dashboard-tabpanel-${index}`,
  }
}

interface IProps {
  htmlString: string
  signatureUrls: Array<string>
  proofOfDeliveryPhotoUrls: Array<string>
  proofOfCollectionPhotoUrls: Array<string>
}

const PodPanelTabs = ({
  htmlString,
  signatureUrls,
  proofOfDeliveryPhotoUrls,
  proofOfCollectionPhotoUrls,
}: IProps) => {
  const [tabValue, setTabValue] = useState<number>(0)

  const handleTabChange = (id: number) => setTabValue(id)

  const renderTabs = () =>
    tabsList.map((tab) => (
      <Tab
        label={tab.label}
        key={tab.id}
        {...commonProps(tab.id)}
        onClick={() => handleTabChange(tab.id)}
      />
    ))

  return (
    <>
      <AppBar position='sticky' color='default'>
        <Tabs
          value={tabValue}
          aria-label='aacounts payable tabs'
          variant='scrollable'
          TabIndicatorProps={{ style: { transition: 'none' } }}>
          {renderTabs()}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={TabId.Pod}>
        <PodDocument html={htmlString} />
      </TabPanel>
      <TabPanel elevation={0} value={tabValue} index={TabId.Signature}>
        <Photos urls={signatureUrls} />
      </TabPanel>
      <TabPanel elevation={0} value={tabValue} index={TabId.DeliveryPhoto}>
        <Photos urls={proofOfDeliveryPhotoUrls} />
      </TabPanel>
      <TabPanel elevation={0} value={tabValue} index={TabId.CollectionPhoto}>
        <Photos urls={proofOfCollectionPhotoUrls} />
      </TabPanel>
    </>
  )
}
export default PodPanelTabs
