import { gql } from '@apollo/client'

export const GET_PAGED_EXCHANGE_RATE_TEMPLATES_QUERY = gql`
  query getScheduledExchangeRateTemplates(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ScheduledExchangeRateTemplateSortInput!]
    $where: ScheduledExchangeRateTemplateFilterInput
  ) {
    scheduledExchangeRateTemplates(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          currencyId
          currency {
            id
            code
          }

          date

          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_SCHEDULED_EXCHANGE_RATE_TASKS_QUERY = gql`
  query getScheduledExchangeRateTasks(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ScheduledExchangeRateTaskSortInput!]
    $where: ScheduledExchangeRateTaskFilterInput
  ) {
    scheduledExchangeRateTasks(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          status
          templateId
          template {
            id
            currencyId
            currency {
              id
              code
            }
          }
          dueByDateTime
          createdBy
          createdAt
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
