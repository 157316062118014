import React from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledCheckbox,
  ControlledCurrencyAutocomplete,
  ControlledSelector,
  ControlledTextField,
  CardTitle,
  ISelectorOptions,
} from 'components/common'
import { InvoiceFrequencyValues, Mode } from 'constants/index'
import { InvoiceFrequency, useGetCurrenciesQuery, useGetInvoiceTermsQuery } from 'generated/graphql'

interface IProps {
  mode: number
}

const OtherDetails = (props: IProps) => {
  const { mode } = props
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const spacing = 2

  // Queries
  const { data: invoiceTermData } = useGetInvoiceTermsQuery()

  const { data: currenciesData, loading: currenciesLoading } = useGetCurrenciesQuery()

  const invoiceTermsOptions: ISelectorOptions = React.useMemo(
    () => invoiceTermData?.invoiceTerms.map((i) => ({ value: i.id, label: i.name })) || [],
    [invoiceTermData],
  )

  React.useEffect(() => {
    if (mode === Mode.Create && currenciesData) {
      const baseCurrency = currenciesData.currencies.find((c) => c.isBaseCurrency)
      baseCurrency && setValue('currencyId', baseCurrency.id)
    }
  }, [mode, currenciesData, setValue])

  return (
    <>
      <CardTitle>Other Details</CardTitle>
      <Grid container spacing={spacing} alignItems='center'>
        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            label='Invoice Term'
            name='invoiceTermId'
            options={invoiceTermsOptions}
            defaultValue=''
            emptyValue={{ value: '', label: 'None' }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            label='Invoice Frequency'
            name='invoiceFrequency'
            options={InvoiceFrequencyValues}
            defaultValue={InvoiceFrequency.Daily}
            error={!!errors.invoiceFrequency}
            helperText={errors.invoiceFrequency?.message}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Invoice Company Name'
            name='invoiceCompanyName'
            defaultValue=''
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            control={control}
            label='Invoice Grouped'
            name='isInvoiceGrouped'
            defaultValue={false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCurrencyAutocomplete
            control={control}
            options={currenciesData?.currencies || []}
            loading={currenciesLoading}
            error={!!errors.currencyId}
            helperText={errors.currencyId?.message}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            control={control}
            label='Booking Reference Required'
            name='isReferenceRequired'
            defaultValue={false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            control={control}
            label='ULEZ charge'
            name='isUlezCharge'
            defaultValue={false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            control={control}
            label='Reason Code option enabled'
            name='isReasonCodeOptionEnabled'
            defaultValue={false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            control={control}
            label='Booking Import option enabled'
            name='isBookingImportOptionEnabled'
            defaultValue={false}
            disabled={true} // Temporary disabling, until implemented and tested
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            control={control}
            label='Package Details section is optional'
            name='isPackageDetailsOptional'
            defaultValue={false}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OtherDetails
