import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { TariffCalculateCharge } from 'generated/graphql'

interface IProps {
  control: Control<any, any>
  disabled: boolean
}

export default function CalculateChargeControl(props: IProps) {
  const { control, disabled } = props

  return (
    <Controller
      control={control}
      name='calculateCharge'
      render={({ field }) => (
        <FormControl component='fieldset' disabled={disabled}>
          <FormLabel component='label'>Calculate Charge as</FormLabel>
          <RadioGroup {...field} row aria-label='calculate-charge-mode'>
            <FormControlLabel
              value={TariffCalculateCharge.ExpressWaitingFee}
              control={<Radio />}
              label='Excess Waiting Fee'
            />
            <FormControlLabel
              value={TariffCalculateCharge.FlatFee}
              control={<Radio />}
              label='Flat Fee'
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}
