import React from 'react'

import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { ControlledCheckbox, ControlledTextField } from 'components/common'

interface IProps {
  referenceAndNotes: any
  onCancel: any
  onSuccess: any
  processing: boolean
}

const ReferencesDetailsForm = (props: IProps) => {
  const { referenceAndNotes, onCancel, onSuccess, processing = false } = props

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty: formIsDirty },
    reset,
  } = useForm({
    shouldUnregister: true,
    defaultValues: { ...referenceAndNotes },
  })

  React.useEffect(() => {
    reset({
      ...referenceAndNotes,
    })
  }, [reset, referenceAndNotes])

  const onSubmit = (referenceAndNotesForm) => {
    onSuccess(referenceAndNotesForm)
  }

  const handleCancel = () => onCancel(formIsDirty)

  return (
    <Paper elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper variant='outlined' sx={{ marginBottom: 4 }}>
          <Box p={2}>
            <Typography paragraph variant='h5' color='primary'>
              Customer Reference Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3} alignItems='center'>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextField
                      control={control}
                      label='Label'
                      name='customerReferenceLabel'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ControlledCheckbox
                      control={control}
                      label='Mandatory on Booking Screen'
                      name='isCustomerReferenceMandatory'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Paper variant='outlined' sx={{ marginBottom: 4 }}>
          <Box p={3}>
            <Typography paragraph variant='h5' color='primary'>
              Notes
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  rows={4}
                  label='Account Notes'
                  name='noteForAccount'
                  helperText='It will appear as Popup Notes in Booking Screen'
                  multiline
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  rows={4}
                  label='Booking Notes'
                  name='noteForBooking'
                  helperText='It will appear as Popup Notes in Booking Screen'
                  multiline
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ControlledCheckbox
                  control={control}
                  label='Paperwork is Required'
                  name='isPaperworkRequired'
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  rows={4}
                  disabled={!watch('isPaperworkRequired')}
                  label='Paperwork Note'
                  name='noteForPaperwork'
                  helperText='It will appear as "Notes/Special Instructions" in Booking Screen'
                  multiline
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  rows={4}
                  label='Package Notes'
                  name='noteForPackage'
                  helperText='It will appear as "Package Notes" in Booking Screen'
                  multiline
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  rows={4}
                  label='Instructions for Courier Exchange Driver'
                  name='instructionsForCourierExchangeDriver'
                  helperText='It will appear as "Load Notes and Instructions" in Courier Exchange Screen'
                  multiline
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box display='flex' justifyContent='flex-end' alignItems='center' p={1}>
          <Button variant='contained' color='grey' onClick={handleCancel}>
            Cancel
          </Button>
          <Box ml={1}>
            <Button color='primary' variant='contained' type='submit' disabled={processing}>
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Paper>
  )
}

export default ReferencesDetailsForm
