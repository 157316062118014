import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import validationSchema from 'components/bookings/BookingDetails/PodDetails/ValidationSchema'
import { JoiValidationOptions } from 'constants/index'
import {
  EmailSentStatus,
  GetBookingDocument,
  UpdateProofOfDeliveryDtoInput,
  useGetBookingAddressAttachmentsQuery,
  useGetBookingLazyQuery,
  useGetUserBookingLazyQuery,
  useSendProofOfDeliveryEmailMutation,
  useUpdateBookingProofOfDeliveryMutation,
} from 'generated/graphql'
import { UploadBookingAddressAttachment } from 'services'

const usePodDetailsFunction = ({
  selectedAddressId,
  bookingId,
  handleCloseDialog,
  setSelectedAddressId,
  setIsFullScreen,
  isFullScreen,
  setNewFiles,
  newFiles,
  setUploadingFiles,
  setDropzoneKey,
  dropzoneKey,
}) => {
  const methods = useForm<UpdateProofOfDeliveryDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(validationSchema, JoiValidationOptions),
    defaultValues: {
      signedAt: null,
      waitingTime: null,
      signedByFirstName: '',
      signedByLastName: '',
      packages: [],
      arrivedAt: null,
      driverTimeFrom: null,
      driverTimeTo: null,
      waitingTimeCharge: 0,
      noteFromDriver: '',
    },
  })

  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = methods

  // Queries
  const [getAdminBooking, { data: adminBookingData }] = useGetBookingLazyQuery()

  const [getUserBooking, { data: userBookingData }] = useGetUserBookingLazyQuery()

  const {
    loading: attachmentsLoading,
    data: attachmentsData,
    refetch: refetchAttachments,
  } = useGetBookingAddressAttachmentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: Number(selectedAddressId),
    },
  })

  // Mutations
  const [updatePod, { loading: updatingPod }] = useUpdateBookingProofOfDeliveryMutation({
    refetchQueries: [{ query: GetBookingDocument, variables: { bookingId: Number(bookingId) } }],
    onCompleted: () => {
      toast.success('POD was updated with success.')
      handleCloseDialog()
    },
  })

  const [sendPodEmail, { loading: sendingPodEmail }] = useSendProofOfDeliveryEmailMutation({
    onCompleted: ({ sendProofOfDeliveryEmail }) => {
      if (sendProofOfDeliveryEmail === EmailSentStatus.Success) {
        toast.success('POD mail was resent with success.')
      } else {
        toast.warn('POD mail not sent, error: ' + sendProofOfDeliveryEmail)
      }
    },
  })

  //Handlers
  const handleSelectedAddressIdChange = (addressId: any) => {
    setSelectedAddressId(addressId)
  }

  const handleResendPODEmail = () => {
    sendPodEmail({
      variables: { bookingId: Number(bookingId), bookingAddressId: Number(selectedAddressId) },
    })
  }

  const handleOnUpdate = (formData: UpdateProofOfDeliveryDtoInput) => {
    const { waitingTime, packages, ...otherFormData } = formData
    const normalizedPackages = packages?.map((pack) => ({
      packageId: Number(pack.packageId),
      quantity: Number(pack.quantity),
    }))

    if (!selectedAddressId) {
      return
    }
    updatePod({
      variables: {
        input: {
          ...otherFormData,
          id: selectedAddressId,
          packages: normalizedPackages,
          waitingTime: waitingTime ? Number(waitingTime) : null,
          bookingId,
        },
      },
    })
  }

  const handleFullScreen = () => setIsFullScreen(!isFullScreen)
  const handleCancel = () => handleCloseDialog()
  const handleFilesChanged = (files: File[]) => setNewFiles(files)
  const handleUploadFiles = () => {
    if (newFiles.length > 0) {
      const attachmentsData = new FormData()

      newFiles.forEach((file) => {
        attachmentsData.append('attachment', file)
      })

      setUploadingFiles(true)
      UploadBookingAddressAttachment(Number(selectedAddressId), attachmentsData)
        .then(() => {
          refetchAttachments()
          setNewFiles([])

          // NOTE: this will rerender the dropzone area & clear selected files
          // this is the way o_o
          setDropzoneKey(dropzoneKey + 1)
        })
        .finally(() => {
          setUploadingFiles(false)
        })
    }
  }

  return {
    reset,
    handleSubmit,
    control,
    watch,
    errors,
    isDirty,
    getAdminBooking,
    adminBookingData,
    getUserBooking,
    userBookingData,
    attachmentsLoading,
    attachmentsData,
    refetchAttachments,
    updatingPod,
    sendingPodEmail,
    handleSelectedAddressIdChange,
    handleResendPODEmail,
    handleOnUpdate,
    handleFullScreen,
    handleCancel,
    handleFilesChanged,
    handleUploadFiles,
  }
}

export default usePodDetailsFunction
