import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Paper } from '@mui/material'
import { useHistory } from 'react-router-dom'

import MainDetails from './MainDetails'
import OtherDetails from './OtherDetails'

interface IProps {
  isProcessing: boolean
}

const StrategicPartnerFormControls = (props: IProps) => {
  const { isProcessing } = props
  const history = useHistory()
  const spacing = 3

  const handleBack = () => history.goBack()

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={6}>
        <Paper variant='outlined'>
          <Box padding={2}>
            <MainDetails />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper variant='outlined'>
          <Box padding={2}>
            <OtherDetails />
          </Box>
        </Paper>
      </Grid>
      <Grid item container xs={12} justifyContent='flex-end'>
        <Grid item>
          <Box paddingRight={3}>
            <Button variant='contained' color='grey' onClick={handleBack}>
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <LoadingButton variant='contained' type='submit' loading={isProcessing}>
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StrategicPartnerFormControls
