import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

export interface DialogOptions {
  id: number | null
}

export interface TaskDialogOptions extends DialogOptions {
  bookingId?: string | null
}

export interface SpecialTariffDialogOptions extends DialogOptions {
  description?: string | null
}

interface DialogContextProps<T extends DialogOptions> {
  openDialog: boolean
  setOpenDialog: Dispatch<SetStateAction<boolean>>
  options: T
  setOptions: Dispatch<SetStateAction<T>>
}

export const DialogContext = createContext<DialogContextProps<any>>({
  openDialog: false,
  setOpenDialog: () => false,
  setOptions: () => null,
  options: { id: null },
})

export const useDialog = <T extends DialogOptions>() => {
  const context = useContext(DialogContext)

  if (!context) {
    throw new Error('useTaskDialog must be used withing a TaskDialogProvide')
  }

  return {
    dialogOpen: (options: T) => {
      context.setOpenDialog(true), context.setOptions(options)
    },
  }
}

export const DialogProvider = (props) => {
  const [openDialog, setOpenDialog] = useState(false)

  const [options, setOptions] = useState<TaskDialogOptions>({
    id: null,
  })

  return (
    <DialogContext.Provider
      value={{
        openDialog,
        options,
        setOpenDialog,
        setOptions,
      }}
      {...props}
    />
  )
}
