import { useState, useEffect, useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Paper } from '@mui/material'

import { PoBookingDetails } from 'components/bookings/BookingDetails'
import { CardTitle } from 'components/common/controls'
import { FplDialogTitle } from 'components/common/Dialog'
import { BookingAddressType, BookingAddress } from 'generated/graphql'
import usePobDetailsFunctions from 'hooks/usePobDetailsFunctions'
import { Restricted, usePermission } from 'providers'

import { PoAddressEta } from '../PoAddressEta'
import { PoAttachments } from '../PoAttachments'
import PobFormHeader from './PobFormHeader'
import WaitingTimeDetails from './WaitingTimeDetails'

interface IProps {
  bookingId: string
  openDialog: boolean
  hoverText: string
  disabled: boolean
  handleCloseDialog: () => void
}

const PobDetails = (props: IProps) => {
  const { bookingId, openDialog, hoverText, disabled, handleCloseDialog } = props
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [uploadingFiles, setUploadingFiles] = useState(false)
  const [dropzoneKey, setDropzoneKey] = useState<number>(0)
  const [newFiles, setNewFiles] = useState<Array<File>>([])
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)

  const {
    reset,
    handleSubmit,
    control,
    errors,
    isDirty,
    getAdminBooking,
    adminBookingData,
    getUserBooking,
    userBookingData,
    attachmentsLoading,
    attachmentsData,
    refetchAttachments,
    updatingPob,
    handleOnUpdate,
    handleFullScreen,
    handleFilesChanged,
    handleCancel,
    handleUploadFiles,
    handleSelectedAddressIdChange,
  } = usePobDetailsFunctions({
    setSelectedAddressId,
    selectedAddressId,
    handleCloseDialog,
    bookingId,
    setIsFullScreen,
    isFullScreen,
    setNewFiles,
    newFiles,
    setUploadingFiles,
    setDropzoneKey,
    dropzoneKey,
  })

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')
  useEffect(() => {
    if (isAllowedToViewBookingFee) {
      getAdminBooking({
        variables: { bookingId: Number(bookingId) },
      })
    } else {
      getUserBooking({
        variables: { bookingId: Number(bookingId) },
      })
    }
  }, [bookingId, isAllowedToViewBookingFee, getAdminBooking, getUserBooking])

  const booking = adminBookingData?.booking || userBookingData?.userBooking

  const collectionAddresses = useMemo(
    () =>
      booking?.addresses
        ?.filter((address) => address.type === BookingAddressType.Collection)
        .sort((a, b) => a.sequenceOrder - b.sequenceOrder),
    [booking],
  )

  const bookingPackages = useMemo(
    () => booking?.packages.slice().sort((a, b) => Number(a.id) - Number(b.id)),
    [booking],
  )

  useEffect(() => {
    if (selectedAddressId === null && collectionAddresses) {
      setSelectedAddressId(collectionAddresses[0].id)
    }
  }, [collectionAddresses, selectedAddressId])

  const selectedAddress = useMemo(() => {
    const address = collectionAddresses?.find((x) => x.id === selectedAddressId)
    if (address) {
      return address
    }
  }, [collectionAddresses, selectedAddressId])

  useEffect(() => {
    const addressPackages = selectedAddress?.addressPackages?.map((addressPackage) => ({
      packageId: String(addressPackage.packageId),
      remainingPackages: addressPackage.quantity < 0 ? 0 : addressPackage.quantity,
      quantity: addressPackage.package.quantity - addressPackage.quantity,
    }))

    reset({
      signedAt: selectedAddress?.signedAt,
      waitingTime: selectedAddress?.waitingTime,
      signedByFirstName: selectedAddress?.signedByFirstName ?? '',
      signedByLastName: selectedAddress?.signedByLastName ?? '',
      packages: addressPackages,
      arrivedAt: selectedAddress?.arrivedAt,
      noteFromDriver: selectedAddress?.noteFromDriver ?? '',
    })
  }, [bookingPackages, selectedAddress, reset])

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      fullScreen={isFullScreen}
      open={openDialog}
      aria-labelledby='pob-dialog'>
      <FplDialogTitle
        id='pob-dialog'
        isFullScreen={isFullScreen}
        onClose={handleCloseDialog}
        onFullScreen={handleFullScreen}>
        <PobFormHeader />
      </FplDialogTitle>

      <DialogContent>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <CardTitle disabled={disabled}>Booking Details</CardTitle>
                <PoBookingDetails
                  bookingDetails={booking}
                  hoverText={hoverText}
                  disabled={disabled}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <CardTitle disabled={disabled}>POB / Waiting Time Details</CardTitle>
                <WaitingTimeDetails
                  collectionAddresses={collectionAddresses}
                  bookingPackages={bookingPackages}
                  selectedAddress={selectedAddress}
                  onSelectedAddressIdChange={handleSelectedAddressIdChange}
                  control={control}
                  errors={errors}
                  hoverText={hoverText}
                  disabled={disabled}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <PoAddressEta
              variables={{
                sequenceOrder: selectedAddress?.sequenceOrder || 0,
                addressId: Number(selectedAddress?.id),
                eTA: selectedAddress?.eTA,
              }}
              disabled={disabled || !selectedAddress}
              hoverText={hoverText}
            />
          </Grid>

          <Grid item xs={12}>
            <PoAttachments
              dropzoneKey={dropzoneKey}
              data={attachmentsData?.bookingAddress as BookingAddress}
              refetch={refetchAttachments}
              disabled={disabled}
              loading={attachmentsLoading}
              hoverText={hoverText}
              onFilesChanged={handleFilesChanged}
              uploadButtonProps={{
                onClick: handleUploadFiles,
                disabled: newFiles.length === 0,
                uploading: uploadingFiles,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCancel}>
          {isDirty ? 'Cancel' : 'Close'}
        </Button>

        <Restricted to='UpdateBookingPackageOnBoard'>
          <LoadingButton
            variant='contained'
            disabled={disabled}
            loading={updatingPob}
            onClick={handleSubmit(handleOnUpdate)}>
            Update
          </LoadingButton>
        </Restricted>
      </DialogActions>
    </Dialog>
  )
}

export default PobDetails
