import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField, ControlledDatePicker, ControlledSelector } from 'components/common'
import { ActivityTypeValues } from 'constants/EntityConstants'
import { CreateUpdateActivityDtoInput } from 'generated/graphql'

const ActivityForm = () => {
  const { control } = useFormContext<CreateUpdateActivityDtoInput>()

  return (
    <Grid container columnSpacing={3} rowSpacing={1.5} pt={1}>
      <Grid item xs={12} sm={6}>
        <ControlledSelector
          control={control}
          label='Activity type'
          name='type'
          options={ActivityTypeValues}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledDatePicker control={control} name='date' label='Date' required />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label='Note'
          name='note'
          multiline
          rows={4}
          required
        />
      </Grid>
    </Grid>
  )
}

export default ActivityForm
