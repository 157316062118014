import { Mode } from 'constants/index'

import React from 'react'

import { LoadingBackdrop } from 'components/common'
import { useGetDriverQuery, CreateAndUpdateDriverDtoInput } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

import DriverFormControls from './DriverFormControls'

interface IProps {
  driverId: number
  isProcessing: boolean
}

const UpdateDriver = (props: IProps) => {
  const { driverId, isProcessing } = props
  const { reset } = useFormContext<CreateAndUpdateDriverDtoInput>()

  // Queries
  const { data: driverData, loading: driverLoading } = useGetDriverQuery({
    variables: { driverId },
    skip: !driverId,
  })

  React.useEffect(() => {
    if (driverData?.driver) {
      reset({ ...driverData.driver, vatCategoryId: driverData.driver.vatCategoryId ?? '' })
    }
  }, [driverData, reset])

  return (
    <>
      <DriverFormControls mode={Mode.Update} isProcessing={isProcessing} />
      <LoadingBackdrop loading={driverLoading} />
    </>
  )
}

export default UpdateDriver
