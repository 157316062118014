import { useEffect } from 'react'

import { Button, Grid } from '@mui/material'
import { ControlledDatePicker, ControlledRadioGroup } from 'components/common'
import XeroPurchaseCodesMultipleAutocomplete from 'components/common/custom-queries/XeroCodesMultipleAutocomplete'
import { AccountsPayableFilterCriteria } from 'generated/graphql'
import { accountPayableFiltersFormVar } from 'graphql/reactiveVariables'
import { removePagination } from 'helpers'
import { FormProvider, useForm } from 'react-hook-form'

import MultipleSearchAutocomplete from './MultipleSearchAutocomplete'

const defaultFormValues = {
  multipleSearch: '',
  xeroPurchaseCodes: [],
  driverName: null,
  driverNumber: null,
  courierExchangeLoadId: null,
  ourReference: null,
  invoiceAmount: null,
  dueInStartDate: null,
  dueInEndDate: null,
  dateSelection: AccountsPayableFilterCriteria.InvoiceDate,
}

const FilterCriteriaForm = () => {
  const methods = useForm<any>({
    defaultValues: defaultFormValues,
  })

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = methods

  const onSubmit = (data: any) => {
    accountPayableFiltersFormVar(data)
  }

  const clearAll = () => {
    reset(defaultFormValues)
    accountPayableFiltersFormVar(defaultFormValues)
    removePagination('accountPayableRecords')
    removePagination('groupedAccountPayableRecords')
  }

  useEffect(() => {
    const accountPayableFilterForm = accountPayableFiltersFormVar()
    reset(accountPayableFilterForm)
  }, [reset])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={1}>
          <Grid item container spacing={2} xs={12} md={10}>
            <Grid item container xs={12} md alignItems='center' justifyContent='center' spacing={2}>
              <Grid item xs={12} md={3}>
                <MultipleSearchAutocomplete />
              </Grid>
              <Grid item xs={12} md={3}>
                <XeroPurchaseCodesMultipleAutocomplete control={control} />
              </Grid>
              <Grid item xs={12} md={3}>
                <ControlledDatePicker
                  control={control}
                  name='dueInStartDate'
                  label='Start Date'
                  error={!!errors.startDate}
                  helperText={errors.startDate?.message}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ControlledDatePicker
                  control={control}
                  name='dueInEndDate'
                  label='End Date'
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent='flex-end' alignItems='center'>
              <Grid item xs={12} md='auto'>
                <ControlledRadioGroup
                  control={control}
                  name='dateSelection'
                  row
                  options={[
                    { value: AccountsPayableFilterCriteria.InvoiceDate, label: 'Invoice Date' },
                    { value: AccountsPayableFilterCriteria.PaymentDate, label: 'Payment Date' },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={2} spacing={2}>
            <Grid item>
              <Button variant='contained' type='submit'>
                Search
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='grey' onClick={clearAll}>
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default FilterCriteriaForm
