import React from 'react'

import { toast } from 'react-toastify'

import { LoadingBackdrop } from 'components/common'
import {
  UpdateCustomerNotificationsDtoInput,
  useGetCustomerNotificationsQuery,
  useUpdateCustomerNotificationsMutation,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'

import CustomerNotificationsForm from './CustomerNotificationsForm'

interface IProps {
  customerId: number | null
}

const CustomerNotifications = (props: IProps) => {
  const { customerId } = props
  const [notifications, setNotifications] = React.useState<UpdateCustomerNotificationsDtoInput>({
    id: customerId?.toString() ?? '',
    isNotificationForQuote: false,
    isNotificationForReadyForAllocation: false,
    isNotificationForBooking: false,
    isNotificationForPobArrival: false,
    isNotificationForPOB: false,
    isNotificationForPodArrival: false,
    isNotificationForPodPartial: false,
    isNotificationForPOD: false,
    isNotificationForCancellation: false,
    isNotificationForInvoice: false,
    isNotificationForCarded: false,
    isNotificationForAmendment: false,
    isNotificationForDriverAllocated: false,
    isNotificationForInvoiceDue: false,
  })

  const { confirmOpen } = useConfirmDialog()

  // Queries
  const { loading: notificationsLoading } = useGetCustomerNotificationsQuery({
    variables: {
      customerId,
    },
    onCompleted: (data) => {
      if (data) {
        const { /* __typename, */ ...notifications } = data.customer.customer
        setNotifications({ ...notifications } as UpdateCustomerNotificationsDtoInput)
      }
    },
  })

  // Mutations
  const [
    updateNotifications,
    { error: updatingNotificationsErrors, loading: updatingNotifications },
  ] = useUpdateCustomerNotificationsMutation()

  const onSubmit = (data) => {
    updateNotifications({
      variables: {
        input: {
          id: customerId,
          ...data,
        } as UpdateCustomerNotificationsDtoInput,
      },
    }).then(() => toast.success('Notifications was successfully updated!'))
  }

  const onCancel = (formIsDirty) => {
    if (formIsDirty) {
      confirmOpen()
    }
  }

  const isLoading = notificationsLoading
  const processing = updatingNotifications
  const processingErrors = () => updatingNotificationsErrors

  if (processingErrors()) {
    processingErrors()?.graphQLErrors.map((e) => toast.error(e.message))
  }

  return (
    <>
      <CustomerNotificationsForm
        notifications={notifications}
        onCancel={onCancel}
        onSuccess={onSubmit}
        processing={processing}
      />
      <LoadingBackdrop loading={isLoading} />
    </>
  )
}

export default CustomerNotifications
