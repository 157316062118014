import { useContext, useEffect, useMemo } from 'react'

import { Button, Grid, Stack, Tooltip } from '@mui/material'
import { addBusinessDays } from 'date-fns'
import Decimal from 'decimal.js'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { CardTitle, FplDynamicTabs, TabPanelBootstrap } from 'components/common'
import { CollectionInterval, IgnoredXeroServiceCodes } from 'constants/index'
import {
  BookingAddressType,
  GoogleDistanceStatus,
  useDeleteBookingAddressMutation,
  useGetDistanceCalculationLazyQuery,
} from 'generated/graphql'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import { getCutOffTimeInterval, getGoogleAddressInput } from 'helpers'
import usePrevious from 'helpers/usePrevious'
import { emitter, usePermission } from 'providers'
import { TBookingForm } from 'types'

import { AddressContext } from './AddressProvider'
import { BookingAddress, getBookingAddressDefaultValues } from './BookingAddress'

interface IProps {
  isQuote: boolean
  isQuickQuote: boolean
  hoverText: string
  disabled?: boolean
}

const CollectionDetails = (props: IProps) => {
  const { isQuote, isQuickQuote, hoverText, disabled } = props

  const { collectionTabValue, setCollectionTabValue, deliveryTabValue } = useContext(AddressContext)

  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<TBookingForm>()
  const { fields, append, remove } = useFieldArray({
    name: 'collectionAddresses',
    keyName: 'tabsKey',
  })

  const [isAllowedToUpdateBookingAfterQuoteIsBooked] = usePermission(
    'UpdateBookingAfterQuoteIsBooked',
  )
  const showAddAndRemoveButtons =
    !isQuote && !isAllowedToUpdateBookingAfterQuoteIsBooked ? false : true

  // Queries
  const [
    getDistanceCalculation,
    { data: distanceCalculationData, loading: distanceCalculationLoading },
  ] = useGetDistanceCalculationLazyQuery()

  // Mutations
  const [deleteBookingAddress] = useDeleteBookingAddressMutation()

  const fieldsLength = fields.length
  const prevFieldsLength = usePrevious<number>(fields.length)

  const collectionAddressesErrors = errors?.collectionAddresses

  const errorTabs = useMemo(
    () =>
      (collectionAddressesErrors as any)?.map((value, index) =>
        value !== undefined ? index : null,
      ),
    [collectionAddressesErrors],
  )

  useEffect(() => {
    if (fieldsLength < Number(prevFieldsLength)) {
      const collectionAddressesValue = getValues('collectionAddresses')
      const deliveryAddressesValue = getValues('deliveryAddresses')

      if (collectionAddressesValue && deliveryAddressesValue) {
        const googleAddressInput = getGoogleAddressInput(
          collectionAddressesValue,
          deliveryAddressesValue,
        )

        getDistanceCalculation({ variables: { input: googleAddressInput } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsLength, getDistanceCalculation, getValues])

  useEffect(() => {
    if (distanceCalculationData) {
      if (distanceCalculationData.distanceCalculation.status === GoogleDistanceStatus.Success) {
        const distance = new Decimal(distanceCalculationData.distanceCalculation.distance)
          .toDP(2, Decimal.ROUND_FLOOR)
          .toNumber()

        setValue('distance', distance, { shouldDirty: true })
      } else if (
        distanceCalculationData.distanceCalculation.status === GoogleDistanceStatus.Error
      ) {
        toast.warning(` ${distanceCalculationData.distanceCalculation.errorMessage}`)
      }
    }
  }, [distanceCalculationData, setValue])

  useEffect(() => {
    isPageLoadingVar(distanceCalculationLoading)
  }, [distanceCalculationLoading])

  useEffect(() => {
    emitter.on('xero-service-type-change', () => {
      const serviceType = getValues('serviceType')

      // check if service type is valid for default values
      if (!serviceType || IgnoredXeroServiceCodes.includes(serviceType)) {
        return
      }

      const collectionAddressesValue = getValues('collectionAddresses')
      const deliveryAddressesValue = getValues('deliveryAddresses')

      // is not multi-drop
      if (collectionAddressesValue.length + deliveryAddressesValue.length !== 2) {
        return
      }

      // al the cases are almost the same for each service type

      if (!collectionAddressesValue[0].at) {
        setValue(
          'collectionAddresses.0.at',
          addBusinessDays(CollectionInterval.start, getCutOffTimeInterval(serviceType)),
        )
      }

      if (!collectionAddressesValue[0].by) {
        setValue(
          'collectionAddresses.0.by',
          addBusinessDays(CollectionInterval.end, getCutOffTimeInterval(serviceType)),
        )
      }
    })

    return () => emitter.off('xero-service-type-change')
  }, [getValues, setValue])

  const handleTabChange = (_, newValue) => {
    setCollectionTabValue(newValue)
  }

  const handleAddClick = () => {
    const newTabValue = fields.length
    const collectionAddressesValue = getValues('collectionAddresses')
    const deliveryAddressesValue = getValues('deliveryAddresses')
    const newSequenceOrder =
      [...collectionAddressesValue, ...deliveryAddressesValue].reduce(
        (max, address) => (address.sequenceOrder > max ? address.sequenceOrder : max),
        0,
      ) + 1

    append(getBookingAddressDefaultValues(newSequenceOrder))
    setCollectionTabValue(newTabValue)

    emitter.emit('add-drop-charge')
  }

  const handleRemoveClick = (e, index: number, id?: string) => {
    e.stopPropagation()

    if (id) {
      deleteBookingAddress({ variables: { bookingAddressId: Number(id) } }).then((result) => {
        if (!result.data?.deleteBookingAddress) {
          return
        }

        if (collectionTabValue === fields.length - 1) {
          setCollectionTabValue(fields.length - 2)
        }
        remove(index)
        toast.success('Address was deleted with success')

        emitter.emit('add-drop-charge')
      })
    } else {
      if (collectionTabValue === fields.length - 1) {
        setCollectionTabValue(fields.length - 2)
      }
      remove(index)

      emitter.emit('add-drop-charge')
    }
  }

  const swapAddressValues = (sourceAddress, targetAddress, setValue) => {
    setValue(`${targetAddress}.name`, sourceAddress.name, { shouldDirty: true })
    setValue(`${targetAddress}.city`, sourceAddress.city, { shouldDirty: true })
    setValue(`${targetAddress}.addressLine1`, sourceAddress.addressLine1, { shouldDirty: true })
    setValue(`${targetAddress}.addressLine2`, sourceAddress.addressLine2, { shouldDirty: true })
    setValue(`${targetAddress}.postcode`, sourceAddress.postcode, { shouldDirty: true })
    setValue(`${targetAddress}.countryId`, sourceAddress.countryId, { shouldDirty: true })
    setValue(`${targetAddress}.contact`, sourceAddress.contact, { shouldDirty: true })
    setValue(`${targetAddress}.email`, sourceAddress.email, { shouldDirty: true })
    setValue(`${targetAddress}.telephoneNumber`, sourceAddress.telephoneNumber, {
      shouldDirty: true,
    })
    setValue(`${targetAddress}.isVerified`, sourceAddress.isVerified, { shouldDirty: true })
    setValue(`${targetAddress}.note`, sourceAddress.note, { shouldDirty: true })
  }

  const handleFlipAddresses = () => {
    const collectionAddress = { ...getValues(`collectionAddresses.${collectionTabValue}`) }
    const deliveryAddress = { ...getValues(`deliveryAddresses.${deliveryTabValue}`) }

    swapAddressValues(collectionAddress, `deliveryAddresses.${deliveryTabValue}`, setValue)

    swapAddressValues(deliveryAddress, `collectionAddresses.${collectionTabValue}`, setValue)
  }

  const handleFindOrAf = () => {
    const collectionAddressesValue = getValues('collectionAddresses')
    const deliveryAddressesValue = getValues('deliveryAddresses')

    if (collectionAddressesValue && deliveryAddressesValue) {
      const googleAddressInput = getGoogleAddressInput(
        collectionAddressesValue,
        deliveryAddressesValue,
      )

      if (googleAddressInput.length >= 2) {
        getDistanceCalculation({ variables: { input: googleAddressInput } })
      }
    }
  }

  return (
    <Stack spacing={1.5}>
      <Grid container columnSpacing={1.5} justifyContent='space-between'>
        <Grid item>
          <CardTitle marginBottom={0} disabled={disabled}>
            Collection
          </CardTitle>
        </Grid>

        <Grid item ml='auto'>
          <Button
            variant='contained'
            color='grey'
            disabled={disabled}
            onClick={handleFlipAddresses}>
            Flip Addresses
          </Button>
        </Grid>
      </Grid>

      <Stack>
        <FplDynamicTabs
          tabValue={collectionTabValue}
          fields={fields}
          name='collection-address'
          label='Add'
          disabled={disabled}
          showAddButton={showAddAndRemoveButtons}
          showRemoveButton={showAddAndRemoveButtons}
          errorTabs={errorTabs}
          onTabChange={handleTabChange}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
        />

        <Tooltip title={disabled ? hoverText : ''} followCursor>
          <div>
            {fields.map((item, index) => (
              <TabPanelBootstrap value={collectionTabValue} index={index} key={item.tabsKey}>
                <BookingAddress
                  bookingAddressType={BookingAddressType.Collection}
                  disabled={disabled}
                  isQuote={isQuote}
                  isQuickQuote={isQuickQuote}
                  item={item}
                  index={index}
                  key={item.tabsKey}
                  onFindOrAf={handleFindOrAf}
                />
              </TabPanelBootstrap>
            ))}
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default CollectionDetails
