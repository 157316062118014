import { Box } from '@mui/material'

import DriverPanel from './DriverPanel'
import TabsPanel from './TabsPanel'

function AccountPayableDocuments() {
  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <DriverPanel />
      <TabsPanel />
    </Box>
  )
}

export default AccountPayableDocuments
