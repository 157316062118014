import { Grid } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  ControlledSelector,
  ControlledTextField,
  ControlledDatePicker,
  CardTitle,
  ISelectorOptions,
} from 'components/common'
import { useGetVehicleTypesQuery, CreateAndUpdateVehicleDtoInput } from 'generated/graphql'

const DriverVehicleDetails = () => {
  const spacing = 3
  const { loading: vehicleTypeLoading, data: vehicleTypeData } = useGetVehicleTypesQuery({
    onError: (error) => {
      error.graphQLErrors.map((e) => toast.error(e.message))
    },
  })
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateVehicleDtoInput>()

  let vehicleTypesValues: ISelectorOptions = []

  if (!vehicleTypeLoading && !!vehicleTypeData) {
    vehicleTypesValues = vehicleTypeData?.vehicleTypes.map((vt) => ({
      value: Number(vt.id),
      label: vt.name,
    }))
  }

  return (
    <>
      <CardTitle>Driver Vehicle Details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='registrationNumber'
            label='Registration No'
            required
            error={!!errors.registrationNumber}
            helperText={errors.registrationNumber?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {vehicleTypeLoading ? (
            <Skeleton animation='wave'>
              <ControlledSelector
                control={control}
                name='vehicleTypeId'
                label='Vehicle Type'
                options={vehicleTypesValues}
              />
            </Skeleton>
          ) : (
            <ControlledSelector
              name='vehicleTypeId'
              label='Vehicle Type'
              options={vehicleTypesValues}
              control={control}
              required
              error={!!errors.vehicleTypeId}
              helperText={errors.vehicleTypeId?.message}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='manufacturer'
            label='Manufacturer'
            error={!!errors.manufacturer}
            helperText={errors.manufacturer?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='model'
            label='Model'
            error={!!errors.model}
            helperText={errors.model?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name='colour'
            label='Colour'
            control={control}
            error={!!errors.colour}
            helperText={errors.colour?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            control={control}
            name='dateOfManufacture'
            label='Date of Manufacture'
            error={!!errors.dateOfManufacture}
            helperText={errors.dateOfManufacture?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DriverVehicleDetails
