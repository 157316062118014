import { CardTitle } from 'components/common'

import { ImportDataForm, ImportDataTypes } from './common'

const ImportLegacyBookings = () => {
  return (
    <>
      <CardTitle>Import Legacy Bookings</CardTitle>
      <ImportDataForm type={ImportDataTypes.LegacyBookings} />
    </>
  )
}

export default ImportLegacyBookings
