import { gql } from '@apollo/client'

export const GET_PAGED_VEHICLES = gql`
  query GetPagedVehicles(
    $driverId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [VehicleSortInput!]
    $where: VehicleFilterInput
  ) {
    vehicles(
      driverId: $driverId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          colour
          registrationNumber
          manufacturer
          model
          dateOfManufacture
          vehicleType {
            name
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_VEHICLE_QUERY = gql`
  query GetVehicle($driverId: Long!, $vehicleId: Long!) {
    vehicle(driverId: $driverId, vehicleId: $vehicleId) {
      id
      isDefault
      driverId
      vehicleTypeId
      vehicleType {
        id
        name
      }
      manufacturer
      dateOfManufacture
      model
      colour
      insuranceCompany
      insuranceExpiryDate
      motTestExpiryDate
      isRenewalReminder
      registrationNumber
      policyOrCoverNote
      goodsInTransitExpiryDate
    }
  }
`

export const GET_VEHICLE_TYPES_QUERY = gql`
  query GetVehicleTypes {
    vehicleTypes {
      id
      name
      isEnabled
    }
  }
`
