import { gql } from '@apollo/client'

import { FileDetailsFragment } from 'graphql/fragments'

export const GET_PAGED_BANNERS = gql`
  query GetPagedBanners(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BannerSortInput!]
    $where: BannerFilterInput
  ) {
    pagedBanners(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          isActive
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_BANNERS = gql`
  query GetBanners($where: BannerFilterInput) {
    banners(where: $where) {
      id
      name
      isActive
      file {
        id
        ...FileDetails
      }
    }

    ${FileDetailsFragment}
  }
`
export const GET_BANNER = gql`
  query GetBanner($id: Long!) {
    banner(id: $id) {
      id
      name
      type
      isActive
      fileId
      file {
        id
        ...FileDetails
      }
    }

    ${FileDetailsFragment}
  }
`
