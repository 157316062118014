import { gql } from '@apollo/client'

import { CongestionZoneDetailsFragment, CongestionZonePostcodeFragment } from 'graphql/fragments'

export const CREATE_CONGESTION_ZONE_MUTATION = gql`
  mutation CreateCongestionZone($input: CreateAndUpdateCongestionZoneDtoInput!) {
    createCongestionZone(input: $input) {
      id
      ...CongestionZoneDetails
    }
    ${CongestionZoneDetailsFragment}
  }
`

export const UPDATE_CONGESTION_ZONE_MUTATION = gql`
  mutation UpdateCongestionZone($input: CreateAndUpdateCongestionZoneDtoInput!) {
    updateCongestionZone(input: $input) {
      id
      ...CongestionZoneDetails
    }
    ${CongestionZoneDetailsFragment}
  }
`

export const CREATE_CONGESTION_ZONE_POST_CODE_MUTATION = gql`
  mutation CreateCongestionZonePostcode($input: CreateAndUpdateCongestionZonePostcodeDtoInput!) {
    createCongestionZonePostcode(input: $input) {
      id
      ...CongestionZonePostcodeFragment
    }
    ${CongestionZonePostcodeFragment}
  }
`

export const UPDATE_CONGESTION_ZONE_POST_CODE_MUTATION = gql`
  mutation UpdateCongestionZonePostcode($input: CreateAndUpdateCongestionZonePostcodeDtoInput!) {
    updateCongestionZonePostcode(input: $input) {
      id
      ...CongestionZonePostcodeFragment
    }
    ${CongestionZonePostcodeFragment}
  }
`

export const DELETE_CONGESTION_ZONE_POST_CODE_MUTATION = gql`
  mutation DeleteCongestionZonePostcode($id: Long!) {
    deleteCongestionZonePostcode(postcodeId: $id)
  }
`
