import { useState } from 'react'

import { Add } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { ControlledTextField, ConfirmDialog } from 'components/common'
import { Paths } from 'constants/index'
import { GetBookingQuery, GetUserBookingQuery } from 'generated/graphql'
import { Restricted } from 'providers/PermissionProvider'
import { TBookingForm } from 'types/form-types'

import { BookingSaveButtons, QuoteSaveButtons } from '../common'
import ActionButtons from './ActionButtons/ActionButtons'
import BookingOptions from './BookingOptions'
import ContactEmailForm from './ContactEmailForm'
import ContactsAutocomplete from './ContactsAutocomplete'
import ContactTelephoneNumberForm from './ContactTelephoneNumberForm'
import CreateContactDialog from './CreateContactDialog'
import CustomerReference from './CustomerReference'
import CustomersAutocomplete from './CustomersAutocomplete'
import PobPodButtons from './PobPodButtons'
import SopsButton from './SopsButton'

interface IProps {
  bookingDetails?: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  isQuote: boolean
  isQuickQuote: boolean
  hoverText: string
  disabled: boolean
  mode: number | undefined
}

const MainBookingDetailsForm = (props: IProps) => {
  const { bookingDetails, isQuote, isQuickQuote, hoverText, disabled, mode } = props
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const {
    control,
    formState: { isDirty, errors },
  } = useFormContext<TBookingForm>()

  const history = useHistory()
  const customerIdValue = useWatch({ name: 'customerId' })

  const handleAddCustomerClick = () => {
    if (isDirty) {
      setConfirmOpen(true)
    } else {
      history.push(Paths.customers.create)
    }
  }

  const handleAddContactClick = () => {
    setOpenContactDialog(true)
  }

  const handleCloseContactDialog = () => {
    setOpenContactDialog(false)
  }

  const handleConfirmSuccess = () => {
    history.push(Paths.customers.create)
  }

  return (
    <>
      <Grid container spacing={1.5}>
        {/* Save Buttons */}
        <Grid item xs={12} md='auto' alignSelf='center' sx={{ order: { xs: 1, md: 3 } }}>
          <Grid container spacing={1.5}>
            <Grid item>
              <SopsButton disabled={disabled} />
            </Grid>

            <Grid item>
              {!isQuote ? (
                <BookingSaveButtons disabled={disabled && !bookingDetails?.isOnHold} />
              ) : (
                <QuoteSaveButtons disabled={disabled && !bookingDetails?.isOnHold} />
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12} md={true} alignSelf='center' sx={{ order: { xs: 2 } }}>
          <ActionButtons
            booking={bookingDetails as GetBookingQuery['booking']}
            isQuote={isQuote}
            hoverText={hoverText}
            disabled={disabled}
            isFormDirty={isDirty}
          />
        </Grid>

        {/* Pob and Pod Buttons */}
        {!isQuote && (
          <Grid
            item
            xs={12}
            md='auto'
            alignSelf='center'
            ml={{ lg: 'auto' }}
            sx={{ order: { xs: 3, md: 1, lg: 6 } }}>
            <PobPodButtons
              booking={bookingDetails as GetBookingQuery['booking']}
              hoverText={hoverText}
              disabled={disabled}
            />
          </Grid>
        )}

        {/* Customer  */}
        <Grid item xs={12} sm={6} md={12} lg={6.7} xl={7} sx={{ order: { xs: 4, lg: 1 } }}>
          <Grid container spacing={1.5}>
            <Grid item xs zeroMinWidth>
              <CustomersAutocomplete
                bookingDetails={bookingDetails}
                isQuickQuote={isQuickQuote}
                disabled={disabled}
                mode={mode}
              />
            </Grid>

            <Grid item pl='6px !important' mt={1}>
              <Restricted to='ViewCustomerList'>
                <IconButton disabled={disabled} color='secondary' onClick={handleAddCustomerClick}>
                  <Add />
                </IconButton>
              </Restricted>
            </Grid>

            <Grid item xs={12} md pl={{ md: '6px !important' }}>
              <CustomerReference disabled={disabled} />
            </Grid>

            <Grid item xs={12} md>
              <ControlledTextField
                control={control}
                label='Our. Ref'
                name='ourReference'
                readOnly
                disabled={disabled}
                error={!!errors.ourReference}
                helperText={errors.ourReference?.message}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Contact */}
        <Grid item xs={12} sm={6} md={12} lg={6.7} xl={7} sx={{ order: { xs: 4 } }}>
          <Grid container spacing={1.5}>
            <Grid item xs zeroMinWidth>
              <ContactsAutocomplete disabled={disabled} />
            </Grid>

            <Grid item pl='6px !important' mt={1}>
              <IconButton
                color='secondary'
                disabled={!customerIdValue || disabled}
                onClick={handleAddContactClick}>
                <Add />
              </IconButton>
            </Grid>

            <Grid item xs={12} md pl={{ md: '6px !important' }}>
              <ContactEmailForm bookingDetails={bookingDetails} disabled={disabled} />
            </Grid>

            <Grid item xs={12} md>
              <ContactTelephoneNumberForm bookingDetails={bookingDetails} disabled={disabled} />
            </Grid>
          </Grid>
        </Grid>

        {/* Booking Options */}
        <Grid item sx={{ order: { xs: 6, lg: 5 } }} alignSelf='center'>
          <BookingOptions />
        </Grid>
      </Grid>

      {openContactDialog && (
        <CreateContactDialog
          customerId={Number(customerIdValue)}
          openDialog={openContactDialog}
          handleCloseDialog={handleCloseContactDialog}
        />
      )}

      {confirmOpen && (
        <ConfirmDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmSuccess}
        />
      )}
    </>
  )
}

export default MainBookingDetailsForm
