import React from 'react'

import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_OFFSET_FORMAT } from 'constants/index'
import { GET_BOOKING_LOGS } from 'graphql/queries'

import ViewLogRecord from './ViewLogRecord'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'createdAt',
    headerName: 'Date/Time',
    width: 200,
    valueFormatter: ({ value }) => value && format(parseJSON(value), DATE_TIME_OFFSET_FORMAT),
  },
  { field: 'createdBy', headerName: 'User', width: 250 },
  { field: 'type', headerName: 'Type', minWidth: 150 },
  { field: 'description', headerName: 'Description', minWidth: 150, flex: 1 },
]

interface IProps {
  bookingId: string | null
}

const Logs = (props: IProps) => {
  const { bookingId } = props
  const [selectedLogRecordId, setSelectedLogRecordId] = React.useState<number | null>(null)
  const [openColumnValuesDialog, setOpenColumnValuesDialog] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedLogRecordId(Number(id))
    setOpenColumnValuesDialog(true)
  }

  const handleCloseColumnValuesDialog = () => {
    setOpenColumnValuesDialog(false)
  }

  return (
    <>
      <FplDataGrid
        query={GET_BOOKING_LOGS}
        queryVariables={{ bookingId: Number(bookingId) }}
        entityName='bookingLogs'
        columns={columns}
        defaultOrder={{ field: 'id', sort: 'desc' }}
        toolbar={{ caption: 'Booking Logs' }}
        // onRowClick={handleRowClick} // ToDo: Uncomment when 'BookingLog.Details' are populated
      />

      {openColumnValuesDialog && (
        <ViewLogRecord
          selectedLogRecordId={selectedLogRecordId}
          openDialog={openColumnValuesDialog}
          handleCloseDialog={handleCloseColumnValuesDialog}
        />
      )}
    </>
  )
}

export default Logs
