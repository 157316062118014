import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { FplDialogTitle } from 'components/common/Dialog'
import { DEBOUNCE_DELAY } from 'constants/index'
import { TariffPagedDtoFilterInput } from 'generated/graphql'
import { GET_PAGED_TARIFFS } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'name', headerName: 'Name', minWidth: 180, flex: 1 },
  { field: 'type', headerName: 'Type', minWidth: 100 },
  { field: 'category', headerName: 'Category', minWidth: 170, flex: 1 },
  { field: 'baseUnit', headerName: 'Base Unit', minWidth: 100 },
  { field: 'minMiles', headerName: 'Min Miles', minWidth: 100, type: 'number' },
  { field: 'minCharge', headerName: 'Min Charge', minWidth: 100, type: 'number' },
  { field: 'rate', headerName: 'Rate', minWidth: 100, type: 'number' },
]

interface IProps {
  open: boolean
  onClose: () => void
  customerId: number | null
  onSelectTariff: (id: number) => void
}

const SelectInternalTariffDialog = (props: IProps) => {
  const { open, onClose, customerId, onSelectTariff } = props

  const { control, watch, setValue } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectTariff(Number(id))
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const filter: TariffPagedDtoFilterInput | undefined = searchValue
    ? {
        or: [{ name: { contains: searchValue } }],
      }
    : undefined

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby='select-tariff-dialog'>
      <FplDialogTitle id='select-tariff-dialog' onClose={onClose}>
        Select Tariff
      </FplDialogTitle>

      <DialogContent>
        <FplDataGrid
          query={GET_PAGED_TARIFFS}
          entityName='pagedTariffs'
          queryVariables={{ customerId }}
          columns={columns}
          filter={filter}
          defaultOrder={{ field: 'name', sort: 'asc' }}
          toolbar={{
            caption: 'Tariff List',
            rightSide: (
              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            ),
          }}
          onRowClick={handleRowClick}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectInternalTariffDialog
