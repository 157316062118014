import { useEffect, useMemo } from 'react'

import { Grid, Tooltip } from '@mui/material'
import { format } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { CardTitle, ControlledCheckbox, TextInput } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import {
  BookingStatus,
  GetUserBookingQuery,
  useGetBookingInvoiceLazyQuery,
} from 'generated/graphql'
import { TBookingForm } from 'types/form-types'
import { boolean } from 'joi'

interface IProps {
  booking: GetUserBookingQuery['userBooking']
  disabled?: boolean
  hoverText?: string
  isShareWithOurNetwork?: boolean
}

const OtherDetails = (props: IProps) => {
  const { booking, disabled, isShareWithOurNetwork = true, hoverText = '' } = props

  const { control } = useFormContext<TBookingForm>()

  // Queries
  const [getBookingInvoices, { data: bookingInvoicesData }] = useGetBookingInvoiceLazyQuery()

  useEffect(() => {
    if (booking && booking.status === BookingStatus.Invoice) {
      getBookingInvoices({
        variables: {
          customerId: booking.customerId,
          where: { bookingId: { eq: Number(booking.id) } },
        },
      })
    }
  }, [booking, getBookingInvoices])

  const invoiceId = useMemo(
    () => bookingInvoicesData?.bookingInvoice?.edges?.[0].node.id,
    [bookingInvoicesData],
  )

  const takenOn = useMemo(
    () =>
      booking?.takenOn && booking.takenOn
        ? format(new Date(booking.takenOn), DATE_TIME_FORMAT)
        : '',
    [booking?.takenOn],
  )

  const createdAt = useMemo(
    () =>
      booking?.createdAt && booking.createdAt
        ? format(new Date(booking.createdAt), DATE_TIME_FORMAT)
        : '',
    [booking?.createdAt],
  )

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <CardTitle disabled={disabled}>Other Details</CardTitle>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <TextInput label='Assigned To' value={booking?.takenBy} disabled={disabled} readOnly />
          </Grid>
          <Grid item xs={12}>
            <TextInput label='Assigned On' value={takenOn} disabled={disabled} readOnly />
          </Grid>
          <Grid item xs={12}>
            <TextInput label='Created By' value={booking?.createdBy} disabled={disabled} readOnly />
          </Grid>
          <Grid item xs={12}>
            <TextInput label='Created At' value={createdAt} disabled={disabled} readOnly />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name='invoiceId'
              label='Invoice Id'
              value={invoiceId}
              disabled={disabled}
              readOnly
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledCheckbox
              control={control}
              name='isEmailNotification'
              label='Send email notification'
              defaultValue={true}
              disabled={disabled}
            />
          </Grid>

          {isShareWithOurNetwork && (
            <Grid item xs={12}>
              <ControlledCheckbox
                control={control}
                name='isBiddingEnabled'
                label='Share with Our Network'
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Tooltip>
  )
}

export default OtherDetails
