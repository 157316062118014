import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { MinVehicleSizeValues } from 'constants/index'

const PotentialBookingHelpContent = () => {
  return (
    <>
      <Box my={2}>
        <Typography variant='body1' component='div'>
          <strong>How to import Tariffs</strong>
        </Typography>

        <Typography variant='body2' component='div'>
          <ol>
            <li>
              Make sure that Tariff Category&apos;s <em>CX Vehicle Size</em> is set to the correct
              value.
            </li>
            <li>Choose a CX Vehicle Size from the table below.</li>
            <li>Find the &quot;Reason code - Vehicle type&quot; column in the XLSX file.</li>
            <li>
              Find the pickup (collection) row inidcated by the letter &quot;P&quot; in the
              &quot;Delivery/Pickup/Transfer/Flyby/Service&quot; column.
            </li>
            <li>
              Copy the <em>Value</em> to the cell for that column and row.
            </li>
          </ol>
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>CX Vehicle Size</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {MinVehicleSizeValues.map((size) => (
              <TableRow key={size.value} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{size.label}</TableCell>
                <TableCell>{size.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* 
      <List>
        {MinVehicleSizeValues.map((size) => (
          <ListItem key={size.value}>
            <ListItemText primary={`${size.label}: ${size.value}`} />
          </ListItem>
        ))}
      </List> */}
    </>
  )
}

export default PotentialBookingHelpContent
