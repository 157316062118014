import { gql } from '@apollo/client'

import { BookingAddressesFragment, BookingDetailsFragment } from '../fragments'

export const POST_LOAD_MUTATION = gql`
  mutation PostLoad($input: CourierExchangeLoadDtoInput!) {
    postLoad(input: $input) {
      id
      bookingId
      loadId
      rawRequest
    }
  }
`

export const LOAD_QUOTES_MUTATION = gql`
  mutation LoadQuotes($loadId: Long!) {
    loadQuotes(loadId: $loadId) {
      id
      loadId
      quoteId
      price
      currency
      quoteOwnerCompanyId
      quoteOwnerCompanyName
      quoteOwnerDriverName
      totalPrice
      createdOn
      status
      notes
      isDriverInSystem
      isDriverUsingOurApp
    }
  }
`

export const BOOK_LOAD = gql`
  mutation BookLoad($input: CourierExchangeBookLoadDtoInput!) {
    bookLoad(input: $input) {
      id
      bookingId
      booking {
        id
        takenOn
        takenBy
      }
    }
  }
`

export const UPDATE_BOOKING_STATUS_FROM_COURIER_EXCHANGES_MUTATION = gql`
  mutation UpdateBookingStatusFromCourierExchange($loadId: Long!) {
    updateBookingStatusFromCourierExchange(loadId: $loadId) {
      id
      ...BookingDetails
      ...BookingAddresses
    }
    ${BookingDetailsFragment}
    ${BookingAddressesFragment}
  }
`

export const UPDATE_BOOKING_LIST_STATUS_FROM_COURIER_EXCHANGE_MUTATION = gql`
  mutation UpdateBookingListStatusFromCourierExchange {
    updateBookingListStatusFromCourierExchange
  }
`
