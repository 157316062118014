import Joi from 'joi'

import { EntityConstants } from 'constants/EntityConstants'

function validationSchema() {
  return Joi.object({
    description: Joi.string()
      .required()
      .label('Description')
      .max(EntityConstants.MAX_SHORT_NOTE_LENGTH)
      .messages({
        'string.base': 'Description must be a string',
        'string.empty': 'Description is required',
        'any.required': 'Description is required',
      }),
    endDate: Joi.date().required().label('End date'),
    time: Joi.date().allow(null),
    categoryId: Joi.number().allow(null),
    assignedUserName: Joi.string().required().label('User'),
    additionalDetail: Joi.string().allow('', null).optional(),
  })
}

export default validationSchema
