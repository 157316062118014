import { LoadingBackdrop } from 'components/common'
import ContactForm from 'components/customers/Contacts/createUpdateContact/ContactForm'
import { Mode } from 'constants/index'
import {
  Contact,
  CreateAndUpdateContactDtoInput,
  useGetCustomerContactQuery,
} from 'generated/graphql'

interface IProps {
  contactId: number
  contact: Contact
  setContact: any
  customerId: number | null
}

const UpdateContact = (props: IProps) => {
  const { contactId, contact, setContact, customerId } = props

  const { loading: contactLoading } = useGetCustomerContactQuery({
    variables: {
      id: contactId,
    },
    onCompleted: (data) => {
      if (data) {
        const { /* __typename, */ ...contact } = data.customerContact[0]
        setContact(contact as CreateAndUpdateContactDtoInput)
      }
    },
  })

  const isLoading = contactLoading || !contact.id

  return (
    <>
      <ContactForm customerId={customerId} mode={Mode.Update} />
      <LoadingBackdrop loading={isLoading} />
    </>
  )
}

export default UpdateContact
