import { joiResolver } from '@hookform/resolvers/joi'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { LoadingBackdrop } from 'components/common'
import { Mode, Paths, DEFAULT_COUNTRY_ID } from 'constants/index'
import {
  CreateAndUpdateDriverDtoInput,
  DriverLicenseType,
  DriverType,
  PaymentTerm,
  useCreateDriverMutation,
  useUpdateDriverMutation,
} from 'generated/graphql'

import DriverFormControls from './DriverFormControls'
import UpdateDriver from './UpdateDriver'
import validationSchema from './ValidationSchema'

interface IProps {
  driverId: number | null
  mode: number | undefined
}

const schema = validationSchema()

const DriverDetails = (props: IProps) => {
  const { driverId, mode } = props
  const history = useHistory()

  const methods = useForm<CreateAndUpdateDriverDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(schema),
    defaultValues: {
      name: '',
      driverType: DriverType.Contract,
      xeroCodeId: '',
      courierExchangeMemberId: '',
      xeroPurchaseCode: '',
      postcode: '',
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      countryId: DEFAULT_COUNTRY_ID,
      email: '',
      telephoneNumber: '',
      alternativeTelephoneNumber: '',
      startDate: new Date(),
      paymentTerm: PaymentTerm.MonthFromEndOfInvoiceMoth,
      invoiceEmail: '',
      eoriNumber: '',
      currencyId: '',
      licenseType: DriverLicenseType.Standard,
      endorsements: '',
      lastPaid: null,
      vatCategoryId: '',
      bankName: '',
      bankAccountNumber: '',
      bankAccountHolderName: '',
      internationalBankAccountNumber: '',
      vatIdNumber: '',
      bankSortCode: '',
      employeeReference: '',
      isActive: true,
      isOnboardingCompleted: false,
      swift: '',
      companyRegistrationNumber: '',
    },
  })

  const { handleSubmit } = methods

  // Mutations
  const [createDriver, { loading: creatingDriver }] = useCreateDriverMutation({
    onCompleted: (data) => {
      if (!data?.createDriver?.id) {
        toast.error('Driver was not created. Please try again later.')
        return
      }

      toast.success(`Driver ${data?.createDriver.name} was successfully created.`)
      history.push(Paths.drivers.updateWithId(data?.createDriver.id))
    },
  })
  const [updateDriver, { loading: updatingDriver }] = useUpdateDriverMutation({
    onCompleted: (data) => {
      if (!data?.updateDriver?.id) {
        toast.error('Driver was not updated. Please try again later.')
        return
      }
      toast.success(`Driver ${data?.updateDriver.name} was successfully updated.`)
    },
  })

  const onSubmit: SubmitHandler<CreateAndUpdateDriverDtoInput> = (data) => {
    const {
      countryId,
      licenseType,
      xeroCodeId,
      courierExchangeMemberId,
      publicLiabilityExpirationDate,
      ...restData
    } = data

    const normalizedVars = {
      countryId: countryId ? Number(countryId) : null,
      licenseType: licenseType || DriverLicenseType.Standard, // TODO: License type nullable
      xeroCodeId: xeroCodeId ? Number(xeroCodeId) : null,
      courierExchangeMemberId: courierExchangeMemberId || null,
      publicLiabilityExpirationDate: publicLiabilityExpirationDate
        ? publicLiabilityExpirationDate
        : undefined,
    }

    if (mode === Mode.Create) {
      createDriver({
        variables: {
          input: {
            id: null,
            ...restData,
            ...normalizedVars,
          },
        },
      })
    } else {
      updateDriver({
        variables: {
          input: {
            id: String(driverId!),
            ...restData,
            ...normalizedVars,
          },
        },
      })
    }
  }

  const isProcessing = creatingDriver || updatingDriver

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {mode === Mode.Create ? (
            <DriverFormControls mode={Mode.Create} isProcessing={isProcessing} />
          ) : (
            <UpdateDriver driverId={driverId!} isProcessing={isProcessing} />
          )}
        </form>
      </FormProvider>
      <LoadingBackdrop loading={isProcessing} />
    </div>
  )
}

export default DriverDetails
