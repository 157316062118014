import { Typography } from '@mui/material'

import { Mode } from 'constants/index'

const ContactFormHeader = ({ mode }: { mode: number }) => {
  let title

  switch (mode) {
    case Mode.Create: {
      title = 'New Contact'
      break
    }
    case Mode.Update: {
      title = 'Update Contact'
      break
    }
    default: {
      title = 'Wrong url'
      break
    }
  }
  return (
    <Typography paragraph variant='h4'>
      {title}
    </Typography>
  )
}

export default ContactFormHeader
