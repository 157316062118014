import { useMemo } from 'react'

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'

import { FplDataGrid, FplDialogTitle, IGridColumn } from 'components/common'
import { GET_PAGED_VEHICLES } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'registrationNumber', headerName: 'Registration No.', minWidth: 200, flex: 1 },
  {
    field: 'vehicleType',
    headerName: 'Vehicle Type',
    minWidth: 200,
    flex: 1,
    valueGetter: ({ value }) => value?.name,
  },
  { field: 'vehicleTypeId', headerName: 'Type', width: 100 },
]

interface IProps {
  openDialog: boolean
  selectedDriverId: string | null
  onSelectVehicle: (id: number) => void
  onCloseDialog: () => void
}

const SelectVehicleDialog = (props: IProps) => {
  const { openDialog, selectedDriverId, onSelectVehicle, onCloseDialog } = props

  const queryVariables = useMemo(() => ({ driverId: Number(selectedDriverId) }), [selectedDriverId])

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectVehicle(Number(id))
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='md'
      scroll='body'
      aria-labelledby='select-vehicle-dialog'>
      <FplDialogTitle id='select-vehicle-dialog' onClose={handleCloseDialog}>
        Select Vehicle
      </FplDialogTitle>

      <DialogContent>
        <FplDataGrid
          query={GET_PAGED_VEHICLES}
          entityName='vehicles'
          queryVariables={queryVariables}
          columns={columns}
          defaultOrder={{ field: 'name', sort: 'asc' }}
          toolbar={{ caption: 'Vehicles' }}
          onRowClick={handleRowClick}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectVehicleDialog
