import { useState } from 'react'

import { Box, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

import AccountPayableDocumentsHistoryTab from 'components/accountsPayable/AccountPayableDocuments/AccountPayableDocumentsHistoryTab'
import AccountPayableDocumentsJobDetailsTab from 'components/accountsPayable/AccountPayableDocuments/AccountPayableDocumentsJobDetailsTab'
import { TabPanel } from 'components/common'
import { useHashToSelectTab } from 'helpers'

enum TabId {
  JobDetails = 0,
  History = 1,
}

const tabsList = [
  { id: TabId.JobDetails, label: 'Job Details', hash: '#job-details' },
  { id: TabId.History, label: 'History', hash: '#history' },
]

function TabsPanel() {
  const [tabValue, setTabValue] = useState<number | false>(false)

  useHashToSelectTab(tabsList, setTabValue)

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: 2 }}>
        <Tabs value={tabValue} aria-label='account-payable-documents'>
          {tabsList.map((tab) => (
            <Tab label={tab.label} key={tab.id} component={Link} to={{ hash: tab.hash }} />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={TabId.JobDetails} elevation={0}>
        <AccountPayableDocumentsJobDetailsTab />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.History} elevation={0}>
        <AccountPayableDocumentsHistoryTab />
      </TabPanel>
    </>
  )
}

export default TabsPanel
