import { Box, Container, styled, Typography } from '@mui/material'

const StyledLogo = styled('img')({
  marginTop: 50,
  display: 'inline-block',
  maxWidth: '100%',
  // todo: width
})

interface IExceptionMessageProps {
  headerText: string
  bodyText: string
  imageUri?: string
  actions?: any
}

const ExceptionMessage = (props: IExceptionMessageProps) => {
  const { headerText, bodyText, imageUri, actions } = props
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
      <Container maxWidth='md'>
        <Typography align='center' color='textPrimary' variant='h1'>
          {headerText}
        </Typography>
        <Typography align='center' color='textPrimary' variant='subtitle2'>
          {bodyText}
        </Typography>
        {imageUri && (
          <Box sx={{ textAlign: 'center' }}>
            <StyledLogo alt='under development' src={imageUri} />
          </Box>
        )}
        {actions && <Box sx={{ textAlign: 'center' }}>{actions}</Box>}
      </Container>
    </Box>
  )
}

export default ExceptionMessage
