import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material'

import ChargeRow from 'components/bookings/BookingDetails/OtherCharges/ChargeTable/ChargeRow'
import { usePermission } from 'providers/PermissionProvider'

interface IProps {
  fields: Record<any, string>[]
  disabled: boolean
  onRemoveClick: (index: number, id: string) => void
  onInfoClick: (index: number) => void
}

const ChargeTable = (props: IProps) => {
  const { fields, disabled, onRemoveClick, onInfoClick } = props

  const [isAllowedToViewVatCategories] = usePermission('ViewVatCategoryList')
  const [isAllowedToDeleteCharge] = usePermission('DeleteBookingCharge')

  return (
    <TableContainer sx={{ height: '300px' }}>
      <Table stickyHeader size='small' aria-label='charge table'>
        <TableHead>
          <TableRow>
            <TableCell>Charge</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Rate (£)</TableCell>
            {(isAllowedToViewVatCategories || isAllowedToDeleteCharge) && (
              <TableCell align='right'>Actions</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields?.map((item, index) => (
            <ChargeRow
              row={item}
              index={index}
              key={item.chargeKey}
              disabled={disabled}
              isAllowedToViewVatCategories={isAllowedToViewVatCategories}
              isAllowedToDeleteCharge={isAllowedToDeleteCharge}
              onRemoveClick={onRemoveClick}
              onInfoClick={onInfoClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ChargeTable
