import { gql } from '@apollo/client'

export const TemplateDetailsFragment = gql`
  fragment TemplateDetails on ScheduledBookingTemplate {
    id
    bookingId
    weekdaysCollection
    processOn
    processOnOnwards
    processOnOnwardsTotalSeconds
  }
`
