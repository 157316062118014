import Joi from 'joi'

function validationSchema() {
  return Joi.object({
    isAvailableForDriver: Joi.boolean().optional().allow('').label('Available for driver'),
    isAvailableForCustomer: Joi.boolean().optional().allow('').label('Available for customer'),
    changeToPod: Joi.boolean().optional().allow('').label('Change attachment category'),
    bookingAddressId: Joi.when('changeToPod', {
      is: true,
      then: Joi.number().required(),
      otherwise: Joi.optional().allow(''),
    }).label('Delivery Address'),
  })
}

export default validationSchema
