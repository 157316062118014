import { gql } from '@apollo/client'

export const ReportRowsFragment = gql`
  fragment ReportRows on GenericReportOutputDto {
    rows {
      cells {
        value
        type
        isBold
        hyperlink
      }
    }
  }
`
