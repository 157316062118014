import { Box, Tooltip } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { Restricted } from 'providers/PermissionProvider'
import { TBookingForm } from 'types'

import { ExternalTariff } from './ExternalTariff'
import { InternalTariff } from './InternalTariff'

interface IProps {
  bookingId?: string
  isQuote: boolean
  hoverText?: string
  disabled?: boolean
}

const TariffDetails = (props: IProps) => {
  const { bookingId, isQuote, hoverText = '', disabled } = props
  const { control } = useFormContext<TBookingForm>()

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <CardTitle disabled={disabled}>Tariff Details</CardTitle>
        <Restricted
          to='ViewBookingInternalTariffDetails'
          fallback={<ExternalTariff bookingId={bookingId} isQuote={isQuote} />}>
          <InternalTariff bookingId={bookingId} />
        </Restricted>

        <Box hidden>
          <ControlledTextField control={control} name='serviceType' label='' />
        </Box>
      </div>
    </Tooltip>
  )
}

export default TariffDetails
