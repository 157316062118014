import { Box, Paper, useTheme } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

import { useGetBannersQuery } from 'generated/graphql'
import { Restricted } from 'providers'

const BookingsCarousel = () => {
  const theme = useTheme()

  const { data: bannersData } = useGetBannersQuery({
    variables: { where: { isActive: { eq: true } } },
  })

  return bannersData?.banners.length ? (
    <Restricted to='ViewTrustpilotWidgetOnBookingsPage'>
      <Box mb={2} display='flex' justifyContent='center'>
        <Paper elevation={3} sx={{ width: 1000, overflow: 'hidden' }}>
          <Carousel
            height={200}
            swipe={false}
            indicatorContainerProps={{
              style: { position: 'absolute', bottom: '1px', zIndex: 100, marginTop: 0 },
            }}
            activeIndicatorIconButtonProps={{ style: { color: theme.palette.primary.main } }}>
            {bannersData?.banners.map((item) => (
              <Box
                key={item.id}
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${item.file?.absoluteUri})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: { xs: 'left -160px center', md: 'left center' },
                }}
              />
            ))}
          </Carousel>
        </Paper>
      </Box>
    </Restricted>
  ) : null
}

export default BookingsCarousel
