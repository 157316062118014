import React from 'react'

import { Grid } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  LoadingBackdrop,
  ControlledSelector,
  ControlledTextField,
  ISelectorOptions,
} from 'components/common'
import {
  CreateAndUpdateOtherChargeDtoInput,
  useGetVatCategoriesQuery,
  VatCategory,
} from 'generated/graphql'

interface IProps {
  processing: boolean
}

export default function OtherChargeForm(props: IProps) {
  const { processing } = props
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateOtherChargeDtoInput>()
  const [vatCategories, setVatCategories] = React.useState<Array<VatCategory>>([])

  const { loading: vatCategoriesLoading } = useGetVatCategoriesQuery({
    onCompleted: (data) => {
      setVatCategories([...data.vatCategories] as Array<VatCategory>)
    },
  })

  const vatCategoriesItems: ISelectorOptions = React.useMemo(() => {
    return vatCategories.map((vc) => ({ value: vc.id, label: vc.name }))
  }, [vatCategories])

  return (
    <>
      <LoadingBackdrop loading={processing} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name='name'
            label='Name'
            control={control}
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name='rate'
            label='Rate'
            control={control}
            error={!!errors.rate}
            helperText={errors.rate?.message}
            required
            min='0'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name='xeroServiceCode'
            label='Service Code'
            control={control}
            error={!!errors.xeroServiceCode}
            helperText={errors.xeroServiceCode?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {vatCategoriesLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <ControlledSelector
              name='vatCategoryId'
              label='Vat Category'
              control={control}
              options={vatCategoriesItems}
              required
              error={!!errors.vatCategoryId}
              helperText={errors.vatCategoryId?.message}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name='description'
            label='Description'
            control={control}
            multiline
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}
