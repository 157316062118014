import { useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { GET_ACCOUNT_PAYABLE_POD } from 'graphql/queries/AccountPayableRecordQueries'

import DocumentActionPanel from '../DocumentActionPanel/DocumentActionPanel'
import PaperPanel from '../PaperPanel'
import PodPanelTabs from './PodPanelTabs'

function PodPanel() {
  const { id } = useParams<{ id?: string }>()
  const { data, loading } = useQuery(GET_ACCOUNT_PAYABLE_POD, {
    variables: { accountPayableRecordId: Number(id) },
    skip: !id,
  })
  if (!id || loading) {
    return <LoadingButton loading={loading} />
  }
  return (
    <Stack spacing={2} height='100%'>
      <Typography variant='h6'>POD</Typography>
      <PaperPanel>
        <PodPanelTabs
          htmlString={data?.accountPayablePod?.htmlString}
          signatureUrls={data?.accountPayablePod?.signatureUrls}
          proofOfDeliveryPhotoUrls={data?.accountPayablePod?.proofOfDeliveryPhotoUrls}
          proofOfCollectionPhotoUrls={data?.accountPayablePod?.proofOfCollectionPhotoUrls}
        />
      </PaperPanel>
      <DocumentActionPanel
        createdAt={new Date(data?.accountPayablePod?.createdAt)}
        documentName='POD'>
        <Button variant='outlined' disabled>
          Upload
        </Button>
      </DocumentActionPanel>
    </Stack>
  )
}

export default PodPanel
