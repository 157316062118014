import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { Mode } from 'constants/index'
import {
  CreateAndUpdateTariffCategoryDtoInput,
  SortEnumType,
  useCreateTariffCategoryMutation,
  useUpdateTariffCategoryMutation,
} from 'generated/graphql'
import { GET_PAGED_TARIFF_CATEGORIES } from 'graphql/queries'
import { isNumber } from 'helpers'

import TariffCategoryForm from './TariffCategoryForm'
import UpdateTariffCategory from './UpdateTariffCategory'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}

const TariffCategoryDialog = (props: IProps) => {
  const { id, openDialog, onCloseDialog } = props

  const methods = useForm<CreateAndUpdateTariffCategoryDtoInput>({
    shouldUnregister: true,
    defaultValues: {
      name: '',
      courierExchangeVehicleSize: '' as any,
      hasSpotRate: false,
    },
  })

  const { handleSubmit } = methods

  // Mutations
  const [createTariffCategory, { loading: creatingTariffCategory }] =
    useCreateTariffCategoryMutation({
      refetchQueries: [
        {
          query: GET_PAGED_TARIFF_CATEGORIES,
          variables: {
            after: null,
            before: null,
            first: 10,
            last: null,
            order: { createdAt: SortEnumType.Asc },
          },
        },
      ],
      onCompleted: (data) => {
        if (data.createTariffCategory) {
          toast.success(
            `Tariff Category (${data.createTariffCategory?.id}) was created with success`,
          )
          onCloseDialog()
        }
      },
    })

  const [updateTariffCategory, { loading: updatingTariffCategory }] =
    useUpdateTariffCategoryMutation({
      onCompleted: (data) => {
        if (data.updateTariffCategory) {
          toast.success(
            `Tariff Category (${data.updateTariffCategory?.id}) was updated with success`,
          )
          onCloseDialog()
        }
      },
    })

  let mode: number | undefined

  if (id === null) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const onSubmitTariffCategory: SubmitHandler<CreateAndUpdateTariffCategoryDtoInput> = ({
    name,
    courierExchangeVehicleSize,
    hasSpotRate,
  }) => {
    if (mode === Mode.Create) {
      createTariffCategory({
        variables: {
          input: {
            name,
            courierExchangeVehicleSize: courierExchangeVehicleSize || undefined,
            hasSpotRate,
          },
        },
      })
    } else {
      updateTariffCategory({
        variables: {
          input: {
            id: Number(id),
            name,
            courierExchangeVehicleSize: courierExchangeVehicleSize || undefined,
            hasSpotRate,
          },
        },
      })
    }
  }

  const handleClose = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      scroll='body'
      open={openDialog}
      aria-labelledby='tariff-category-dialog'>
      <FplDialogTitle id='tariff-category-dialog' onClose={handleClose}>
        <Typography paragraph variant='h4'>
          {mode === Mode.Create ? 'Create' : 'Update'} Tariff Category
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? <TariffCategoryForm /> : <UpdateTariffCategory id={id!} />}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          loading={creatingTariffCategory || updatingTariffCategory}
          onClick={handleSubmit(onSubmitTariffCategory)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TariffCategoryDialog
