import { gql } from '@apollo/client'

export const GET_PAGED_EXCHANGE_RATES_QUERY = gql`
  query getExchangeRates(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ExchangeRateSortInput!]
    $where: ExchangeRateFilterInput
  ) {
    exchangeRates(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          sourceCurrencyId
          sourceCurrency {
            id
            name
            code
          }

          targetCurrencyId
          targetCurrency {
            id
            name
            code
          }

          rate

          createdAt
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
