import { AsyncValidationOptions } from 'joi'

const JoiValidationOptions: AsyncValidationOptions = {
  abortEarly: false,
  allowUnknown: true,
  presence: 'required',
  messages: {
    'any.required': 'Field is required',
    'any.custom': '{{#error}}',
    'string.email': 'Invalid email',
    'string.base': 'Field must be a string',
    'number.greater': 'Field must be greater than {{#limit}}',
    'date.base': 'Invalid date',
    'string.pattern.base': 'Invalid format for {{#label}}',
    'object.and': `{{#label}} contains {{#presentWithLabels}} without its required fields {{#missingWithLabels}}`,
  },
}

export { JoiValidationOptions }
