import { MenuItem } from '@mui/material'

// eslint-disable-next-line react/prop-types
const HelperMenuItem = ({ content, handleOnClick, label, isMenu = false }) =>
  isMenu ? (
    <MenuItem onClick={handleOnClick}>
      {content}
      <p>{label}</p>
    </MenuItem>
  ) : (
    content
  )

export default HelperMenuItem
