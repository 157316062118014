import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import { toast } from 'react-toastify'

import { useUpdateBookingListStatusFromCourierExchangeMutation } from 'generated/graphql'
import { Restricted } from 'providers/PermissionProvider'

interface IProps {
  onClearAll: () => void
}

const ActionButtons = (props: IProps) => {
  const { onClearAll } = props

  // Mutations
  const [updateBookingListStatusFromCourierExchange, { loading }] =
    useUpdateBookingListStatusFromCourierExchangeMutation({
      onCompleted: (data) => {
        if (!data) {
          toast.error('Something went wrong.')
          return
        }

        if (data.updateBookingListStatusFromCourierExchange.length > 0) {
          toast.success(
            `${data.updateBookingListStatusFromCourierExchange.length} Bookings was successfully updated from CX.`,
          )
        } else if (data.updateBookingListStatusFromCourierExchange.length === 0) {
          toast.success('All Bookings is up-to-date.')
        }
      },
    })

  const handleUpdateFromCX = () => {
    updateBookingListStatusFromCourierExchange()
  }

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={2}
      direction={{ xs: 'column', sm: 'row', md: 'column' }}
      justifyContent='end'
      alignItems='end'>
      <Grid item order={{ sm: 2, md: 1 }}>
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          direction={{ md: 'column', lg: 'row' }}
          justifyContent='end'
          alignItems='end'>
          <Grid item order={{ md: 2, lg: 1 }}>
            <Button variant='contained' color='grey' onClick={onClearAll}>
              Clear All
            </Button>
          </Grid>

          <Grid item order={{ md: 1, lg: 2 }}>
            {/* // Todo: Add disabled on !isDirty */}
            <Button variant='contained' type='submit'>
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Restricted to='CourierExchangeUpdateBookingListStatus'>
        <Grid item order={{ sm: 1, md: 2 }} display='flex' justifyContent='end'>
          <LoadingButton
            color='grey'
            variant='contained'
            loading={loading}
            onClick={handleUpdateFromCX}>
            Update From CX
          </LoadingButton>
        </Grid>
      </Restricted>
    </Grid>
  )
}

export default ActionButtons
