import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Grid, Paper } from '@mui/material'
import { Box } from '@mui/system'
import Joi from 'joi'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { CardTitle, ControlledTextField } from 'components/common'
import {
  CreateUpdateTaskSettingDtoInput,
  TaskRelatedTo,
  useGetTaskSettingsQuery,
  useUpdateTaskSettingMutation,
} from 'generated/graphql'
import { Restricted } from 'providers'
interface TaskSettingsProps {
  relatedTo: TaskRelatedTo
  caption: string
}

const validationSchema = Joi.object({
  id: Joi.optional(),
  taskRelatedTo: Joi.optional(),
  taskCaption: Joi.string().label('Task caption').required(),
  interval: Joi.number().label('Interval').min(1),
})

export const TaskSettings = (props: TaskSettingsProps) => {
  const [notFound, setNotFound] = useState(false)
  // TODO: add validation
  const methods = useForm<CreateUpdateTaskSettingDtoInput>({
    defaultValues: {
      taskRelatedTo: props.relatedTo,
    },
    resolver: joiResolver(validationSchema),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = methods

  // queries
  const { loading: loadingTaskSettings } = useGetTaskSettingsQuery({
    variables: {
      where: {
        taskRelatedTo: { eq: props.relatedTo },
      },
    },
    onCompleted: (response) => {
      if (response.taskSettings.length === 0) {
        toast.error('Task settings not found')
        setNotFound(true)
        return
      }

      const taskSetting = response.taskSettings[0]

      reset({
        id: taskSetting.id,
        taskRelatedTo: taskSetting.taskRelatedTo,
        taskCaption: taskSetting.taskCaption,
        interval: taskSetting.interval,
      })
    },
  })

  // mutation
  const [updateTaskSettings, { loading: updatingTask }] = useUpdateTaskSettingMutation({
    onCompleted: (response) => {
      reset({ ...response.updateSystemTaskSetting })
    },
  })

  // handlers
  const handleSubmitTaskSettings = (input: CreateUpdateTaskSettingDtoInput) => {
    updateTaskSettings({
      variables: {
        input: {
          ...input,
          interval: Number(input.interval),
        },
      },
    })
  }

  return (
    <Paper variant='outlined'>
      <Box p={2}>
        <CardTitle>{props.caption}</CardTitle>
        <Grid container spacing={3}>
          <FormProvider {...methods}>
            <Box hidden>
              <ControlledTextField control={control} name='id' label='' />
              <ControlledTextField control={control} name='taskRelatedTo' label='' />
            </Box>
            {!loadingTaskSettings && (
              <>
                <Grid item xs={12}>
                  <ControlledTextField
                    name='interval'
                    label='Interval'
                    control={control}
                    endAdornment='Hours'
                    type='text'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    sanitizedNumber
                    disabled={notFound}
                    required
                    error={!!errors.interval}
                    helperText={errors.interval?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextField
                    name='taskCaption'
                    label='Task caption'
                    control={control}
                    error={!!errors.taskCaption}
                    helperText={errors.taskCaption?.message}
                    required
                    disabled={notFound}
                  />
                </Grid>
              </>
            )}
          </FormProvider>
          <Restricted to='UpdateTaskSettings'>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                variant='contained'
                onClick={handleSubmit(handleSubmitTaskSettings)}
                loading={updatingTask}
                disabled={!isValid || !isDirty || notFound}>
                Save
              </LoadingButton>
            </Grid>
          </Restricted>
        </Grid>
      </Box>
    </Paper>
  )
}
