import { useEffect, useState } from 'react'

import { ShowRestError } from 'helpers'
import { DownloadFileAsArrayBuffer } from 'services'

import { FileForPreview } from './FileForPreview'
import { FileType, getFileTypeFromMimeType } from './FileType'
import { DocViewerWrapper } from './Wrappers/DocViewerWrapper'
import { MammothWrapper } from './Wrappers/MammothWrapper'
import { XlsxWrapper } from './Wrappers/XlsxWrapper'

interface IProps {
  fileId: number | null
}
export const FilePreview = (props: IProps) => {
  const { fileId } = props

  const [file, setFile] = useState<FileForPreview | null>(null)
  const [type, setType] = useState<FileType | null>(null)

  useEffect(() => {
    DownloadFileAsArrayBuffer(Number(fileId))
      .then((response) => {
        const mimeType = response.headers['content-type']
        setType(getFileTypeFromMimeType(mimeType))
        setFile(new FileForPreview(mimeType, response.data))
      })
      .catch((error) => {
        ShowRestError(error)
      })
  }, [fileId])

  return (
    <>
      {file &&
        (() => {
          switch (type) {
            case FileType.MicrosoftOfficeGeneral:
              return <div>Sorry, but this Microsoft Office file type is not yet supported.</div>
            case FileType.MicrosoftOfficeExcel:
              return <XlsxWrapper file={file} />
            case FileType.MicrosoftOfficeDocx:
              return <MammothWrapper file={file} />
            case FileType.Other:
              return <DocViewerWrapper file={file} />
            default:
              return <div>Sorry, but this file type is not yet supported.</div>
          }
        })()}
    </>
  )
}
