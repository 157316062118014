import { ISelectorOptions } from 'components/common'
import { TariffType, TariffBaseUnit, XeroCodeType } from 'generated/graphql'

export const TariffTypeValues: ISelectorOptions = [
  { value: TariffType.Distance, label: 'Distance' },
  { value: TariffType.Other, label: 'Other' },
]

export const TariffBaseUnitValues: ISelectorOptions = [
  { value: TariffBaseUnit.Kilogram, label: 'Kilogram' },
  { value: TariffBaseUnit.Kilometer, label: 'Kilometer' },
  { value: TariffBaseUnit.Mile, label: 'Mile' },
  { value: TariffBaseUnit.Ton, label: 'Ton' },
]

export const XeroCodeTypeValues: ISelectorOptions = [
  { value: XeroCodeType.Revenue, label: 'Revenue' },
  { value: XeroCodeType.Sales, label: 'Sales' },
]

export const Tariff = {
  MAX_SERVICE_CODE_LENGTH: 20,
  MAX_SIZE_DESCRIPTION_LENGTH: 100,
}
