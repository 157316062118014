import { useState } from 'react'

import { Tabs, Tab, Box } from '@mui/material'

import { VerticalTabPanel } from 'components/common'
import { EmailTemplateType } from 'generated/graphql'

import { EmailTemplate } from '../CustomerEmails/EmailTemplate'
import { EmailBoxSx, EmailTabSx } from '../SettingsStyles'

function commonProps(index: any) {
  return {
    id: `internal-emails-tab-${index}`,
    'aria-controls': `internal-emails-tabpanel-${index}`,
  }
}

enum TabId {
  DriverIsLate = 0,
}

const InternalEmails = () => {
  const [tabValue, setTabValue] = useState(0)

  // handlers
  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  const tabs = [
    { id: TabId.DriverIsLate, label: 'Driver is late', type: EmailTemplateType.DriverIsLate },
  ]

  return (
    <Box sx={EmailBoxSx}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='internal email tabs'
        indicatorColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        sx={EmailTabSx}>
        {tabs.map((tab) => (
          <Tab label={tab.label} key={tab.id} {...commonProps(tab.id)} />
        ))}
      </Tabs>
      {tabs.map((tab) => (
        <VerticalTabPanel value={tabValue} index={tab.id} key={tab.id} px={3}>
          <EmailTemplate type={tab.type} label={tab.label} />
        </VerticalTabPanel>
      ))}
    </Box>
  )
}

export default InternalEmails
