import { Buffer } from 'buffer'

export class FileForPreview {
  private mimeType: string
  private data: ArrayBuffer

  public constructor(mimeType: string, data: ArrayBuffer) {
    this.mimeType = mimeType
    this.data = data
  }

  public getMimeType = (): string => this.mimeType

  public getDataAsBase64(): string {
    return Buffer.from(this.data as any, 'binary').toString('base64')
  }

  public getDataAsArrayBuffer(): ArrayBuffer {
    return this.data
  }
}
