import { useState, useEffect } from 'react'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import GoogleMapReact from 'google-map-react'
import { Link } from 'react-router-dom'

import { DraggableAddresses, DriverIcon, UnverifiedAddressInfo} from 'components/bookings/MapRoute'
import { ALPHABET, BRISTOL_COORDINATES, API_KEY } from 'constants/TrackingMap'
import {
  BookingAddressType,
  DriverLocation,
  GetBookingByTrackingNumberQuery,
} from 'generated/graphql'

interface IOrderableAddressListItem {
  id: string
  name: React.ReactNode
  description: React.ReactNode
  letter: string
  placeId: string | null | undefined
  isVerified: boolean
}

interface IProps {
  bookingDetails: GetBookingByTrackingNumberQuery["bookingByTrackingNumber"]
  driverLocations: Array<DriverLocation>
}

const TrackingMap = (props: IProps) => {
  const {bookingDetails, driverLocations } = props
  const [addressItems, setAddressItems] = useState<IOrderableAddressListItem[]>([])

  const [isMapEnabled, setIsMapEnabled] = useState(true)
  const [key, setKey] = useState(Math.random())

  const bookingAddresses = bookingDetails?.addresses

  useEffect(() => {
    if (!bookingAddresses) {
      return;
    }
    if (bookingAddresses.some(x => !x?.address.isVerified)) {
      setIsMapEnabled(false)
    }

    const defaultItems: IOrderableAddressListItem[] =
      bookingAddresses
        .slice()
        .sort((a, b) => a.sequenceOrder - b.sequenceOrder)
        .map((bookingAddress, index) => {
          const name =
            bookingAddress.type === BookingAddressType.Collection ? 'Collection' : 'Delivery'
          const description = (
            <>
              <Typography
                component='span'
                variant='body2'
                color='textPrimary'
                sx={{ display: 'inline' }}>
                {bookingAddress?.address.name}
                {!bookingAddress?.address.isVerified ? (
                  <Typography
                    component='span'
                    variant='body2'
                    color='error'
                    sx={{ display: 'inline' }}>
                    {' '}
                    [Unverified]
                  </Typography>
                ) : (
                  <></>
                )}
              </Typography>
              {` — ${bookingAddress?.address.addressLine1}, ` +
                `${
                  bookingAddress?.address.addressLine2
                    ? bookingAddress?.address.addressLine2
                    : ' - '
                }, ${bookingAddress?.address.city}, ${bookingAddress?.address.postcode}`}
            </>
          )

          return {
            id: bookingAddress.id,
            name,
            description,
            letter: ALPHABET[index] ?? 'n/a',
            placeId: bookingAddress.address.googleMapsPlaceId,
            isVerified: bookingAddress.address.isVerified,
          }
        }) || []

    setAddressItems(defaultItems)
  }, [bookingAddresses, isMapEnabled])

  useEffect(() => {
    if (bookingAddresses) {
      setKey(Math.random())
    }
  }, [bookingAddresses])

  const handleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService()
    const directionsRenderer = new maps.DirectionsRenderer()
    directionsRenderer.setMap(map)

    let origin: { placeId: string | null | undefined } = { placeId: '' }
    let destination: { placeId: string | null | undefined } = { placeId: '' }
    const waypoints = [] as any // TODO: Type is google.maps.DirectionsWaypoint[]

    addressItems.forEach((address, index) => {
      if (index === 0) {
        // First
        origin = { placeId: address.placeId }
      } else if (index === addressItems.length - 1) {
        // Last
        destination = { placeId: address.placeId }
      } else {
        // In between
        waypoints.push({
          location: { placeId: address.placeId },
          stopover: true,
        })
      }
    })

    // Example: https://developers.google.com/maps/documentation/javascript/examples/directions-waypoints
    const request = {
      origin,
      destination,

      waypoints,
      provideRouteAlternatives: true,
      travelMode: 'DRIVING',
      drivingOptions: {
        departureTime: new Date(),
      },
      unitSystem: maps.UnitSystem.IMPERIAL,
    }

    directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(response)
      }
    })
  }

  return (
    <Grid container>
      <Grid item xs={12} lg={3}>
        <Stack justifyContent='space-between' sx={{ height: { lg: '100%' } }}>

            <Stack>

              <Box p={1}>
                <DraggableAddresses
                  items={addressItems}
                  disabled={false}
                  onDragEnd={null}
                />
              </Box>
            </Stack>

            <Box p={3}>
              <Grid container justifyContent='space-between' spacing={1.5}>
                <Grid item ml='auto'>
                  <Link to='/'>
                    <Button variant='contained'>
                      TO SEE MORE INFORMATION ABOUT THIS BOOKING, PLEASE CREATE AN ACCOUNT IN
                      ONEPOINT
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>

        </Stack>
      </Grid>

      <Grid item xs={12} lg={9}>
        {isMapEnabled ? (
          <Box height={{ xs: 500, lg: '80vh' }}>
            <GoogleMapReact
              key={key}
              bootstrapURLKeys={{ key: API_KEY }}
              defaultCenter={BRISTOL_COORDINATES}
              defaultZoom={11}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
              {driverLocations.map((l) => (
                <DriverIcon key={l.driverId} lat={Number(l.latitude)} lng={Number(l.longitude)} />
              ))}
            </GoogleMapReact>
          </Box>
        ) : (
          <UnverifiedAddressInfo/>
        )}
      </Grid>
    </Grid>
  )
}

export { TrackingMap }
export type { IOrderableAddressListItem }
