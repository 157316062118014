import Decimal from 'decimal.js'

import { IgnoredXeroServiceCodes } from 'constants/index'
import { GoogleAddressDtoInput, XeroServiceType } from 'generated/graphql'
import { TBookingForm } from 'types/form-types'

interface IDriver {
  charge: number
  extraPayment: number
}

export const getTotalDriverCharge = (drivers: Array<IDriver> | undefined) => {
  if (!drivers) {
    return 0
  }

  if (drivers.length < 1) {
    return 0
  }

  return drivers.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.charge + currentValue.extraPayment,
    0,
  )
}

interface ICharge {
  rate: number
  quantity: number
}

export const getOtherChargesValue = (charges: Array<ICharge> | undefined | null) => {
  if (!charges) {
    return 0
  }
  return charges.reduce(
    (previousValue, currentValue) => currentValue.rate * currentValue.quantity + previousValue,
    0,
  )
}

export const getBookingRevenue = (consignmentFee: number, otherCharges: number) =>
  new Decimal(consignmentFee).plus(otherCharges).toNumber()

export const getBookingMargin = (revenue: number, totalDriverCharge: number) => {
  if (revenue === 0 && totalDriverCharge === 0) {
    return 0
  }

  return new Decimal(revenue).minus(totalDriverCharge).div(revenue).toNumber()
}

export const getGoogleAddressInput = (
  collection: Array<any>,
  delivery: Array<any>,
): Array<GoogleAddressDtoInput> =>
  [...collection, ...delivery]
    .map((address) => {
      if (address.name || address.city || address.addressLine1 || address.postcode) {
        return {
          sequenceOrder: address.sequenceOrder,
          addressLine1: address.addressLine1 || '',
          ...(address.addressLine2 && {
            addressLine2: address.addressLine2,
          }),
          postcode: address.postcode,
          city: address.city || '',
          countryId: address.countryId,
        }
      }
    })
    .filter((address): address is GoogleAddressDtoInput => !!address)

export const isMultiDrop = (bookingDetails: TBookingForm) =>
  bookingDetails.collectionAddresses.length + bookingDetails.deliveryAddresses.length !== 2
export const ignoreServiceType = (serviceType: XeroServiceType | undefined) =>
  !serviceType || IgnoredXeroServiceCodes.includes(serviceType)
