import { DocumentNode } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { lightGreen } from '@mui/material/colors'
import { GridEventListener, GridSortItem } from '@mui/x-data-grid'
import { Control } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { ControlledSwitch, ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { Paths } from 'constants/Paths'
import { CalculatedStatus } from 'generated/graphql'
import { Restricted } from 'providers'

interface IProps {
  query: DocumentNode
  entityName: string
  columns: IGridColumn[]
  filter: any
  pollInterval?: number
  variableSortOrder?: GridSortItem
  refetchWithDelay?: boolean
  showAppBarLoading?: boolean
  control: Control<
    {
      autoUpdate: any
      search: string
    },
    any
  >
  searchInput: string
  handleSearchClearClick: () => void
}

function BookingsDataTable(props: IProps) {
  const {
    query,
    entityName,
    columns,
    filter,
    pollInterval,
    variableSortOrder,
    refetchWithDelay,
    showAppBarLoading,
    control,
    searchInput,
    handleSearchClearClick,
  } = props

  const history = useHistory()

  const handleCreateBooking = () => {
    history.push(Paths.bookings.create)
  }

  const handleCreateQuote = () => {
    history.push(Paths.quotes.create)
  }

  const handleCreateQuickQuote = () => {
    history.push(Paths.quickQuotes.create)
  }

  const handleRowClick: GridEventListener<'rowClick'> = ({ id, row: { status, isQuickQuote } }) => {
    if (status !== CalculatedStatus.Quotation) {
      history.push(Paths.bookings.updateWithId(id))
    } else if (status === CalculatedStatus.Quotation) {
      if (isQuickQuote) {
        history.push(Paths.quickQuotes.updateWithId(id))
      } else {
        history.push(Paths.quotes.updateWithId(id))
      }
    }
  }

  return (
    <FplDataGrid
      query={query}
      entityName={entityName}
      columns={columns}
      filter={filter}
      pollInterval={pollInterval}
      variableSortOrder={variableSortOrder}
      refetchWithDelay={refetchWithDelay}
      pageOptions={{
        pageSize: 100,
        rowsPerPage: [10, 50, 100],
      }}
      toolbar={{
        caption: 'Bookings',
        leftSide: (
          <>
            <Restricted to='ViewNewBookingButtonOnBookingsTable'>
              <Grid item>
                <Button variant='outlined' size='small' onClick={handleCreateBooking}>
                  New Booking
                </Button>
              </Grid>
            </Restricted>

            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateQuote}>
                New Quote
              </Button>
            </Grid>

            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateQuickQuote}>
                New Quick Quote
              </Button>
            </Grid>
          </>
        ),
        rightSide: (
          <>
            <Grid item>
              <ControlledSwitch
                control={control}
                label='Auto Update'
                name='autoUpdate'
                defaultValue={true}
                size='small'
              />
            </Grid>

            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          </>
        ),
      }}
      showAppBarLoading={showAppBarLoading}
      rootSx={{ '& .allocated': { backgroundColor: lightGreen[100] } }}
      onRowClick={handleRowClick}
    />
  )
}

export default BookingsDataTable
