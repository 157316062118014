import { DigitPattern } from 'constants/index'

import { useEffect, useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, Stack } from '@mui/material'
import { ControlledTextField, TextInput } from 'components/common'
import {
  AccountPayableRecordStatus,
  UpdateDriverPurchaseCodeDtoInput,
  useGetDriverPurchaseCodeQuery,
  useUpdateDriverPurchaseCodeMutation,
} from 'generated/graphql'
import Joi from 'joi'
import { useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'

function PurchaseCodeFormValidationSchema() {
  return Joi.object({
    xeroPurchaseCode: Joi.string().required().pattern(new RegExp(DigitPattern)).messages({
      'string.base': 'Mandatory field',
      'string.empty': 'Mandatory field',
      'string.pattern.base': 'Only digits are allowed',
    }),
  })
}
const schema = PurchaseCodeFormValidationSchema()

type PurchaseCodeFormProps = {
  accountPayableRecordId: number
  accountPayableRecordStatus?: AccountPayableRecordStatus
  driverId?: string
}

const PurchaseCodeForm = ({
  accountPayableRecordId,
  accountPayableRecordStatus,
  driverId,
}: PurchaseCodeFormProps) => {
  const [isPurchaseCodeEditable, setPurchaseCodeEditable] = useState(false)

  const disabledEditStatus =
    accountPayableRecordStatus !== AccountPayableRecordStatus.Flagged &&
    accountPayableRecordStatus !== AccountPayableRecordStatus.UnderReview &&
    accountPayableRecordStatus !== AccountPayableRecordStatus.ScheduledPayment &&
    accountPayableRecordStatus !== AccountPayableRecordStatus.PaymentFailed

  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
  } = useForm<UpdateDriverPurchaseCodeDtoInput>({
    resolver: joiResolver(schema),
    defaultValues: {
      xeroPurchaseCode: '',
    },
    mode: 'onChange',
  })

  const xeroPurchaseCodeValue = useWatch({ control, name: 'xeroPurchaseCode' })

  // Queries
  const { data: driverPurchaseCode, refetch: refetchPurchaseCode } = useGetDriverPurchaseCodeQuery({
    variables: {
      driverId: Number(driverId),
    },
    skip: !driverId,
  })
  const purchaseCode = driverPurchaseCode?.driver?.xeroPurchaseCode

  // Mutations
  const [updatePurchaseCode, { loading: updatingPurchaseCode }] =
    useUpdateDriverPurchaseCodeMutation({
      onError: () => {
        toast.error('Something went wrong. Please try again')
      },
      onCompleted: () => {
        toast.success('Changes saved successfully')
        refetchPurchaseCode()
        setPurchaseCodeEditable(false)
      },
    })

  // Methods
  const onSubmit = (values: UpdateDriverPurchaseCodeDtoInput) => {
    updatePurchaseCode({
      variables: {
        input: {
          id: accountPayableRecordId,
          xeroPurchaseCode: values.xeroPurchaseCode,
        },
      },
    })
  }

  const handleEditPurchaseCode = () => {
    setPurchaseCodeEditable(true)
    purchaseCode &&
      reset({
        xeroPurchaseCode: purchaseCode,
      })
  }

  const handleCancelEditPurchaseCode = () => {
    setPurchaseCodeEditable(false)
    purchaseCode
      ? reset(
          {
            xeroPurchaseCode: purchaseCode,
          },
          { keepDirty: false, keepIsValid: false },
        )
      : reset({
          xeroPurchaseCode: '',
        })
  }

  useEffect(() => {
    if (driverPurchaseCode?.driver) {
      const { __typename, ...rest } = driverPurchaseCode.driver
      reset({
        xeroPurchaseCode: rest.xeroPurchaseCode,
      })
    }
  }, [driverPurchaseCode, reset])

  return (
    <Stack direction='row' spacing={1} alignItems={'center'}>
      <Box sx={{ opacity: 0.5, whiteSpace: 'nowrap' }}>Purchase Code:</Box>
      <Box hidden={isPurchaseCodeEditable}>
        <TextInput
          name='xeroPurchaseCode'
          label=''
          variant='standard'
          value={xeroPurchaseCodeValue}
          sx={{ background: 'transparent', width: '80%' }}
          readOnly
          endAdornment={
            <IconButton size='small' disabled={disabledEditStatus} onClick={handleEditPurchaseCode}>
              <EditIcon />
            </IconButton>
          }
        />
      </Box>

      <Box hidden={!isPurchaseCodeEditable}>
        <ControlledTextField
          control={control}
          name='xeroPurchaseCode'
          label=''
          variant='standard'
          sx={{ width: '80%' }}
          endAdornment={
            <IconButton size='small' onClick={handleCancelEditPurchaseCode}>
              <CloseIcon />
            </IconButton>
          }
          error={!!errors.xeroPurchaseCode}
          helperText={errors.xeroPurchaseCode?.message}
        />
      </Box>

      <LoadingButton
        variant='contained'
        disabled={!isValid || !isDirty || updatingPurchaseCode}
        onClick={handleSubmit(onSubmit)}>
        Save
      </LoadingButton>
    </Stack>
  )
}

export default PurchaseCodeForm
