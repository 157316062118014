import { gql } from '@apollo/client'

export const FileDetailsFragment = gql`
  fragment FileDetails on File {
    name
    category
    absoluteUri

    originalFileName
    fileNameInStorage

    entityReferenceId
    entityReference

    createdBy
    createdAt
  }
`
