import { useCallback, useMemo } from 'react'

import LaunchIcon from '@mui/icons-material/Launch'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, DATE_TIME_OFFSET_FORMAT, Paths } from 'constants/index'
import { ScheduledTaskStatus } from 'generated/graphql'
import { GET_SCHEDULED_BOOKING_TASKS } from 'graphql/queries'

interface IProps {
  templateId: string | null
}

const ScheduledHistory = (props: IProps) => {
  const { templateId } = props
  const history = useHistory()

  const handleOpenCreatedBooking = useCallback(
    (bookingId) => () => {
      if (bookingId) {
        const path = Paths.bookings.updateWithId(String(bookingId))
        history.push(path)
      }
    },
    [history],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      {
        field: 'dueByDateTime',
        headerName: 'Due By',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_OFFSET_FORMAT),
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      {
        field: 'lastModifiedAt',
        headerName: 'Last Modified At',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      { field: 'status', headerName: 'Status', minWidth: 150, flex: 1 },
      { field: 'bookingId', headerName: 'Booking ID', minWidth: 150, flex: 1 },
      { field: 'errorMessage', headerName: 'Error Message', minWidth: 300, flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id, row: { status, bookingId } }) =>
          status === ScheduledTaskStatus.Processed
            ? [
                <GridActionsCellItem
                  key={id}
                  label='Delete'
                  size='large'
                  icon={
                    <Tooltip title={'Delete'} arrow>
                      <LaunchIcon />
                    </Tooltip>
                  }
                  onClick={handleOpenCreatedBooking(bookingId)}
                />,
              ]
            : [],
      },
    ],
    [handleOpenCreatedBooking],
  )

  return (
    <FplDataGrid
      query={GET_SCHEDULED_BOOKING_TASKS}
      queryVariables={{ templateId: Number(templateId) }}
      entityName='scheduledBookingTasks'
      columns={columns}
      defaultOrder={{ field: 'dueByDateTime', sort: 'desc' }}
      toolbar={{ caption: 'Tasks History' }}
    />
  )
}

export default ScheduledHistory
