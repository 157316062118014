import { useContext, useState } from 'react'

import { AppBar, Tab, Tabs } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { TabPanel } from 'components/common'
import { useHashToSelectTab } from 'helpers'
import { PermissionContext, PermissionType, Restricted } from 'providers'

import { CustomersContact } from './CustomersContact'
import { CustomersIncome } from './CustomersIncome'
import { Drivers } from './Drivers'
import { ExternalInvoices } from './ExternalInvoices'
import { MyIncome } from './MyIncome'
import { ProofOfDelivery } from './ProofOfDelivery'
import { Purchases } from './Purchases'
import { Sales } from './Sales'
import { SalesCommission } from './SalesCommission'

enum TabId {
  SalesCommission = 0,
  Sales,
  Purchases,
  ExternalInvoices,
  CustomersIncome,
  CustomersContact,
  Drivers,
  ProofOfDelivery,
  MyIncome,
}

const tabsList = [
  {
    id: TabId.SalesCommission,
    label: 'Sales Commission',
    hash: '#sales-commission',
    policy: 'ViewSalesCommissionReport' as PermissionType,
  },
  {
    id: TabId.Sales,
    label: 'Sales',
    hash: '#sales',
    policy: 'ViewBookingInvoice' as PermissionType,
  },
  {
    id: TabId.Purchases,
    label: 'Purchases',
    hash: '#purchases',
    policy: 'ViewBookingInvoice' as PermissionType,
  },
  {
    id: TabId.ExternalInvoices,
    label: 'External Invoices',
    hash: '#external-invoices',
    policy: 'ViewExternalInvoiceReport' as PermissionType,
  },
  {
    id: TabId.CustomersIncome,
    label: 'Customers Income',
    hash: '#customers-income',
    policy: 'ViewCustomerIncomeReport' as PermissionType,
  },
  {
    id: TabId.CustomersContact,
    label: 'Customers Contact',
    hash: '#customers-contact',
    policy: 'ViewCustomerContactListReport' as PermissionType,
  },
  {
    id: TabId.Drivers,
    label: 'Drivers',
    hash: '#drivers',
    policy: 'ViewDriverListReport' as PermissionType,
  },
  {
    id: TabId.ProofOfDelivery,
    label: 'Proof of Delivery',
    hash: '#proof-of-delivery',
    policy: 'ViewProofOfDeliveryReport' as PermissionType,
  },
  {
    id: TabId.MyIncome,
    label: 'My Income',
    hash: '#my-income',
    policy: 'ViewMyIncomeReport' as PermissionType,
  },
]

function commonProps(index: any) {
  return {
    id: `reports-tab-${index}`,
    'aria-controls': `reports-tabpanel-${index}`,
  }
}

const Reports = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)

  const { isAllowedTo } = useContext(PermissionContext)

  const history = useHistory()

  const allowedTabs = tabsList.filter((tab) => isAllowedTo(tab.policy))

  const primaryTab = allowedTabs[0]
  const secondaryTabs = allowedTabs.filter((_, index) => index !== 0)

  useHashToSelectTab(allowedTabs, setTabValue)

  const handleTabChange = (_, newValue: TabId) => {
    const selectedTab = allowedTabs.find((tab) => tab.id === newValue)

    if (selectedTab) {
      history.push(selectedTab.hash)
    }
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='reports tabs'
          variant='scrollable'>
          <Tab {...commonProps(primaryTab.id)} label={primaryTab.label} value={primaryTab.id} />

          {secondaryTabs.map((tab) => (
            <Tab key={tab.id} {...commonProps(tab.id)} label={tab.label} value={tab.id} />
          ))}
        </Tabs>
      </AppBar>

      <Restricted to='ViewBookingInvoice'>
        <TabPanel value={tabValue} index={TabId.Sales}>
          <Sales />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewBookingInvoice'>
        <TabPanel value={tabValue} index={TabId.Purchases}>
          <Purchases />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewExternalInvoiceReport'>
        <TabPanel value={tabValue} index={TabId.ExternalInvoices}>
          <ExternalInvoices />
        </TabPanel>
      </Restricted>

      <TabPanel value={tabValue} index={TabId.CustomersIncome}>
        <CustomersIncome />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.CustomersContact}>
        <CustomersContact />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.Drivers}>
        <Drivers />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.SalesCommission}>
        <SalesCommission />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.ProofOfDelivery}>
        <ProofOfDelivery />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.MyIncome}>
        <MyIncome />
      </TabPanel>
    </div>
  )
}

export default Reports
