import { useEffect, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledTextField, TextInput } from 'components/common'
import {
  GetBookingQuery,
  GetUserBookingQuery,
  useGetCustomerContactsLazyQuery,
} from 'generated/graphql'
import { TBookingForm } from 'types/form-types'

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

interface IProps {
  bookingDetails: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  disabled: boolean
}

const ContactEmailForm = (props: IProps) => {
  const { bookingDetails, disabled } = props
  const [isEmailEditable, setEmailEditable] = useState(false)

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const [getCustomerContacts, { data: customerContactsData }] = useGetCustomerContactsLazyQuery({
    fetchPolicy: 'network-only',
  })

  const customerIdValue = useWatch({ control, name: 'customerId' })
  const contactIdValue = useWatch({ control, name: 'contactId' })
  const overrideContactEmailValue = useWatch({ control, name: 'overrideContactEmail' })

  useEffect(() => {
    if (customerIdValue && customerIdValue !== '') {
      getCustomerContacts({
        variables: { customerId: Number(customerIdValue) },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerIdValue])

  const customerContacts = customerContactsData?.customerContacts
  const contactEmailValue = useMemo(
    () =>
      customerContacts?.find((contact) => contact.id === String(contactIdValue) && contact.isActive)
        ?.email,
    [contactIdValue, customerContacts],
  )

  const tooltipMessage = isEmailEditable ? overrideContactEmailValue : contactEmailValue ?? ''

  useEffect(() => {
    if (bookingDetails?.overrideContactEmail) {
      setEmailEditable(true)
    }
  }, [bookingDetails?.overrideContactEmail])

  const handleEditEmail = () => {
    setEmailEditable(true)

    if (bookingDetails?.overrideContactEmail) {
      setValue('overrideContactEmail', bookingDetails?.overrideContactEmail)
    } else if (contactEmailValue) {
      setValue('overrideContactEmail', contactEmailValue)
    }
  }

  const handleCancelEditEmail = () => {
    setEmailEditable(false)
    setValue('overrideContactEmail', '')
  }

  return (
    <>
      <NoMaxWidthTooltip title={tooltipMessage}>
        <div>
          <Box hidden={isEmailEditable}>
            <TextInput
              name='contactEmail'
              label='Email'
              value={contactEmailValue}
              disabled={disabled}
              readOnly
              endAdornment={
                <IconButton
                  size='small'
                  disabled={!contactIdValue || disabled}
                  title='Override Contact Email'
                  onClick={handleEditEmail}>
                  <EditIcon />
                </IconButton>
              }
            />
          </Box>

          <Box hidden={!isEmailEditable}>
            <ControlledTextField
              control={control}
              name='overrideContactEmail'
              label='Email'
              defaultValue=''
              disabled={disabled}
              endAdornment={
                <IconButton
                  size='small'
                  title='Use Default Contact Email'
                  onClick={handleCancelEditEmail}>
                  <CloseIcon />
                </IconButton>
              }
              error={!!errors.overrideContactEmail}
              helperText={`${
                errors.overrideContactEmail?.message || ''
              } Use a semicolon between email addresses`}
            />
          </Box>
        </div>
      </NoMaxWidthTooltip>
    </>
  )
}

export default ContactEmailForm
