import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { DropzoneArea } from 'react-mui-dropzone'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { ShowAxiosError, ShowDropzoneAlert } from 'helpers'
import { UploadDriverInvoices } from 'services'

interface IProps {
  open: boolean
  onCloseDialog: () => void
}

const UploadExternalInvoice = (props: IProps) => {
  const { open, onCloseDialog } = props
  const [files, setFiles] = useState<Array<File>>([])
  const [loading, setLoading] = useState<boolean>(false)

  // handlers
  const handleFilesChanged = (files: File[]) => setFiles(files)

  const handleUploadFilesClick = async () => {
    setLoading(true)
    try {
      if (files.length === 0) {
        toast.error('No files were selected')
        return
      }

      const formData = new FormData()

      files.forEach((file, index) => {
        formData.append(`attachment-${index + 1}`, file)
      })

      await UploadDriverInvoices(formData)
        .then(() => {
          toast.success(`Uploaded ${files.length} invoice(s)`)
          onCloseDialog()
        })
        .catch(ShowAxiosError)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog maxWidth='md' open={open} aria-label='form-external-invoice-dialog' fullWidth>
      <FplDialogTitle id='form-external-invoice-dialog' onClose={onCloseDialog}>
        <Typography variant='h4'>Upload external invoice</Typography>
      </FplDialogTitle>

      <DialogContent>
        <DropzoneArea
          onChange={handleFilesChanged}
          filesLimit={5}
          showAlerts={false}
          dropzoneText='Drag and drop a file here or click (.csv only)'
          // acceptedFiles={FileConstants.csv}
          onAlert={ShowDropzoneAlert}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={onCloseDialog}>
          {files.length > 0 ? 'Discard' : 'Cancel'}
        </Button>
        <LoadingButton
          variant='contained'
          loading={loading}
          disabled={files.length === 0}
          onClick={handleUploadFilesClick}>
          Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default UploadExternalInvoice
