import { Paper, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"

const UnverifiedAddressInfo = () => {

  return (    
    <Box p={1} sx={{ height: '100%' }}>
      <Paper variant='outlined' square sx={{ height: 'inherit' }}>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          sx={{ height: 'inherit' }}>
          <Grid item>
            <Typography align='center' color='error' variant='body1'>
              Cannot display map, because one or more Addresses are Unverified.
              <br />
              To fix this, update the Unverified Addresses until they become Verified.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default UnverifiedAddressInfo