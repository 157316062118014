import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField } from 'components/common'
import { CreateAndUpdateQuoteLostReasonDtoInput } from 'generated/graphql'

const QuoteLostReasonForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateQuoteLostReasonDtoInput>()

  return (
    <Box pt={1}>
      <Grid container columnSpacing={3} rowSpacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label='Name'
            name='name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label='Code'
            name='code'
            required
            error={!!errors.code}
            helperText={errors.code?.message}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default QuoteLostReasonForm
