import React from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common/Dialog'
import { JoiValidationOptions, Mode } from 'constants/index'
import {
  Contact,
  CreateAndUpdateContactDtoInput,
  useCreateContactMutation,
  useUpdateContactMutation,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'
import { CreateUpdateCustomerContactValidation } from 'validation-schemas'

import ContactForm from './ContactForm'
import ContactFormHeader from './ContactFormHeader'
import UpdateContact from './UpdateContact'
import { GET_CUSTOMER_CONTACTS } from 'graphql/queries'

interface IProps {
  customerId: number | null
  selectedContactId: number | null
  openDialog: boolean
  handleCloseDialog: () => any
}

const CreateUpdateContact = (props: IProps) => {
  const { customerId, selectedContactId, openDialog, handleCloseDialog } = props

  const [contact, setContact] = React.useState<CreateAndUpdateContactDtoInput>({
    customerId,
    isDefault: false,
    name: '',
    telephoneNumber: '',
    email: '',
    additionalEmails: '',
    isEnabled: true,
    isActive: false,
  })

  const methods = useForm<CreateAndUpdateContactDtoInput>({
    shouldUnregister: true,
    defaultValues: { ...contact },
    resolver: joiResolver(CreateUpdateCustomerContactValidation, JoiValidationOptions),
  })

  const {
    handleSubmit,
    formState: { isDirty: formIsDirty },
    reset,
  } = methods

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  // Mutations
  const [createContact, { loading: creatingContact }] = useCreateContactMutation({
    refetchQueries: [
      {
        query: GET_CUSTOMER_CONTACTS,
        variables: {
          customerId,
        },
      },
    ],
  })

  const [updateContact, { loading: updatingContact }] = useUpdateContactMutation()

  const mode = selectedContactId ? Mode.Update : Mode.Create

  React.useEffect(() => {
    if (mode === Mode.Update) {
      reset({ ...contact })
    }
  }, [contact, mode, reset])

  const handleOnSuccess: SubmitHandler<CreateAndUpdateContactDtoInput> = (data) => {
    if (mode === Mode.Create) {
      createContact({
        variables: {
          input: {
            ...data,
            customerId,
            isEnabled: true,
          } as CreateAndUpdateContactDtoInput,
        },
      }).then((result) => {
        toast.success(`Contact '${result.data?.createContact.name}' was successfully added.`)
        handleCloseDialog()
      })
    } else {
      updateContact({
        variables: {
          input: {
            id: selectedContactId,
            ...data,
            customerId,
            isEnabled: true,
          } as CreateAndUpdateContactDtoInput,
        },
      }).then((result) => {
        toast.success(`Contact '${result.data?.updateContact.name}' was successfully updated.`)
        handleCloseDialog()
      })
    }
  }

  const handleOnCancel = () => {
    if (formIsDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  const isProcessing = creatingContact || updatingContact

  return (
    <Dialog fullWidth maxWidth='md' open={openDialog} aria-labelledby='form-contact-dialog-title'>
      <FplDialogTitle id='form-contact-dialog-title' onClose={handleOnCancel}>
        <ContactFormHeader mode={mode} />
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <form id='create-update-contact-form' onSubmit={handleSubmit(handleOnSuccess)} noValidate>
            {mode === Mode.Create ? (
              <ContactForm customerId={customerId} mode={mode} />
            ) : (
              <UpdateContact
                contactId={selectedContactId as number}
                contact={contact as Contact}
                customerId={customerId}
                setContact={setContact}
              />
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleOnCancel}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          form='create-update-contact-form'
          type='submit'
          loading={isProcessing}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateContact
