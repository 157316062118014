import Joi from 'joi'

import { NamePattern, UserNamePattern, CompanyNamePattern, PhonePattern } from 'constants/index'

const validationSchema = Joi.object({
  userName: Joi.string().required().label('Username').pattern(UserNamePattern).messages({
    'string.base': 'Username must be a string',
    'string.empty': 'Username is required',
    'any.required': 'Username is required',
    'string.pattern.base': 'Username can contain only letters, numbers and these symbols: . _ @',
  }),
  firstName: Joi.string().required().label('First name').pattern(NamePattern).max(40).messages({
    'string.base': 'First name must be a string',
    'string.empty': 'First name is required',
    'any.required': 'First name is required',
    'string.pattern.base': 'First name can contain only letters',
    'string.max': 'First name can have a maximum of 40 characters',
  }),
  lastName: Joi.string().required().label('Last name').pattern(NamePattern).max(40).messages({
    'string.base': 'Last name must be a string',
    'string.empty': 'Last name is required',
    'any.required': 'Last name is required',
    'string.pattern.base': 'Last name can contain only letters',
    'string.max': 'Last name can have a maximum of 40 characters',
  }),
  companyName: Joi.string()
    .required()
    .label('Company name')
    .pattern(CompanyNamePattern)
    .max(40)
    .messages({
      'string.base': 'Company name must be a string',
      'string.empty': 'Company name is required',
      'any.required': 'Company name is required',
      'string.pattern.base': 'Company name can contain only letters and numbers',
      'string.max': 'Company name can have a maximum of 40 characters',
    }),
  contactEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Contact email')
    .messages({
      'string.base': 'Contact email must be a string',
      'string.empty': 'Contact email is required',
      'any.required': 'Contact email is required',
      'string.email': 'Please enter a valid email address',
    }),
  phoneNumber: Joi.string().required().label('Phone number').pattern(PhonePattern).messages({
    'string.base': 'Phone number must be a string',
    'string.empty': 'Phone number is required',
    'any.required': 'Phone number is required',
    'string.pattern.base': 'Please enter a valid phone number',
  }),
  allowExtraEmails: Joi.boolean().optional(),
})

export default validationSchema
