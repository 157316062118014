import React from 'react'

import { Delete, Edit, Save } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { useForm } from 'react-hook-form'

import { ControlledTextField } from 'components/common'

interface IProps {
  id: string
  name: string
  onEdit: (id: string, name: string) => void
  onDelete: (id: string) => void
}

export default function PostcodeRow(props: IProps) {
  const { id, name } = props
  const [editing, setEditing] = React.useState(false)
  const { control, getValues, reset } = useForm({
    shouldUnregister: true,
    defaultValues: {
      postcode: name,
    },
  })

  React.useEffect(() => {
    reset({ postcode: name })
  }, [name, reset])

  const handleDelete = () => props.onDelete(id)

  const handleOnEditClick = () => {
    const postcode = getValues('postcode')
    if (editing && postcode.length >= 3) {
      props.onEdit(id, postcode.toUpperCase())
      setEditing(false)
    } else {
      setEditing(true)
    }
  }

  return (
    <TableRow>
      <TableCell key={`postcode-name-${id}`}>
        {editing ? (
          <form autoComplete='off'>
            <ControlledTextField control={control} label='Postcode' name='postcode' />
          </form>
        ) : (
          name
        )}
      </TableCell>
      <TableCell key={`postcode-actions-${id}`} align='right'>
        <IconButton key={`postcode-onEdit-${id}`} onClick={handleOnEditClick} size='large'>
          {editing ? <Save /> : <Edit />}
        </IconButton>
        <IconButton key={`postcode-onDelete-${id}`} onClick={handleDelete} size='large'>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
