import { Box } from '@mui/material'
import { DropzoneArea } from 'react-mui-dropzone'

interface IProps {
  dropzoneText: string
  onFilesChanged?: (files: File[]) => void
  filesLimit?: number
  logoUri?: string
}

const DropZoneIcon = (props: IProps) => {
  const { dropzoneText, onFilesChanged, filesLimit = 1 } = props

  return (
    <Box width='100%'>
      <DropzoneArea
        acceptedFiles={['image/*']}
        dropzoneText={dropzoneText}
        filesLimit={filesLimit}
        showAlerts={false}
        onChange={onFilesChanged}
      />
    </Box>
  )
}

export default DropZoneIcon
