import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { GetBookingQuery, GetUserBookingQuery } from 'generated/graphql'
import { Restricted } from 'providers/PermissionProvider'
import { TBookingForm } from 'types'

import { BookingSaveButtons, QuoteSaveButtons } from '../common'
import AdditionalButtons from './AdditionalButtons'
import BookingInfoBar from './BookingInfoBar'

interface IProps {
  booking: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  isQuote: boolean
  disabled: boolean
}

const BookingFooter = (props: IProps) => {
  const { booking, isQuote, disabled } = props

  const {
    formState: { isDirty },
  } = useFormContext<TBookingForm>()

  return (
    <Grid container justifyContent={{ xs: 'end', sm: 'space-between' }} spacing={1.5}>
      <Grid item sm md='auto' lg>
        <AdditionalButtons booking={booking as GetBookingQuery['booking']} />
      </Grid>

      <Restricted to='ViewBookingFeeInfoMargin'>
        <Grid item xs={12} xl='auto' sx={{ order: { xs: 3, xl: 2 } }}>
          <BookingInfoBar booking={booking as GetBookingQuery['booking']} />
        </Grid>
      </Restricted>

      <Grid item sm md='auto' lg sx={{ order: { xs: 2, xl: 3 } }}>
        <Grid container justifyContent='end'>
          <Grid item>
            {!isQuote ? (
              <BookingSaveButtons disabled={!isDirty || (disabled && !booking?.isOnHold)} />
            ) : (
              <QuoteSaveButtons disabled={!isDirty || (disabled && !booking?.isOnHold)} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BookingFooter
