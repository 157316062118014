import { LoadingButton } from '@mui/lab'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { useGetAccountPayableRecordQuery, useGetPaymentRecordQuery } from 'generated/graphql'
import { Restricted } from 'providers'
import { useParams } from 'react-router'

import DocumentActionPanel from '../DocumentActionPanel/DocumentActionPanel'
import PaperPanel from '../PaperPanel'
import OrderDetailsItems from './OrderDetailsItems'
import OrderDetailsStepper from './OrderDetailsStepper'

function OrderDetailsPanel() {
  const { id } = useParams<{ id?: string }>()

  const { data: orderDetails, loading } = useGetAccountPayableRecordQuery({
    variables: { id: Number(id) },
  })

  const bookingInvoiceId =
    orderDetails && Number(orderDetails?.accountPayableRecord?.bookingInvoice.id)

  const { data: payment, loading: paymentLoading } = useGetPaymentRecordQuery({
    variables: { bookingInvoiceId },
    skip: !bookingInvoiceId,
  })

  if (loading || paymentLoading) {
    return <LoadingButton loading={loading || paymentLoading} />
  }

  return (
    <Stack spacing={2}>
      <Typography variant='h6'> Order Details</Typography>
      <PaperPanel>
        <Stack spacing={2} p={8}>
          <Typography variant='h6'>
            {orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.name}(
            {orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id ?? 'N/A'})
          </Typography>
          <Divider role='presentation' />
          <OrderDetailsItems
            orderDetails={{
              fplRef: orderDetails?.accountPayableRecord?.bookingInvoice.booking.ourReference,
              customerRef:
                orderDetails?.accountPayableRecord?.bookingInvoice.booking.customerReference,
              loadId:
                orderDetails?.accountPayableRecord?.bookingInvoice.booking.courierExchangeLoadId,
              transactionId: payment?.paymentRecord?.transactionId,
              totalPrice: {
                symbol: orderDetails?.accountPayableRecord?.currency?.symbol ?? '',
                price: orderDetails?.accountPayableRecord?.gross,
              },
              tariff: orderDetails?.accountPayableRecord?.bookingInvoice.booking.tariff?.name,
              tariffCategory:
                orderDetails?.accountPayableRecord?.bookingInvoice.booking.tariff?.tariffCategory
                  .name,
            }}
            accountPayableRecordId={Number(id)}
            accountPayableRecordStatus={orderDetails?.accountPayableRecord?.status}
            driverId={orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id}
          />

          <OrderDetailsStepper
            adresses={orderDetails?.accountPayableRecord?.bookingInvoice.booking.addresses.map(
              (address) => ({
                id: address.id,
                at: address?.at ?? '',
                by: address?.by ?? '',
                type: address.type,
                adress: {
                  id: address.address.id,
                  name: address.address.name,
                  adressLine1: address.address.addressLine1,
                  adressLine2: address.address.addressLine2,
                  postcode: address.address.postcode,
                  city: address.address.city,
                  countryName: address.address.country.name,
                },
              }),
            )}
          />
        </Stack>
      </PaperPanel>
      <DocumentActionPanel
        documentName='Order Details'
        createdAt={new Date(orderDetails?.accountPayableRecord?.bookingInvoice.invoiceDate)}>
        <Restricted to='ViewAccountsPayableRecordDocumentActions'>
          <Button variant='outlined' disabled>
            Approve
          </Button>
        </Restricted>
      </DocumentActionPanel>
    </Stack>
  )
}

export default OrderDetailsPanel
