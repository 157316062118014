import { useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT, DEBOUNCE_DELAY } from 'constants/index'
import { TariffCategoryFilterInput } from 'generated/graphql'
import { GET_PAGED_TARIFF_CATEGORIES } from 'graphql/queries'

import { TariffCategoryDialog } from './TariffCategoryDialog'

const columns: IGridColumn[] = [
  { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
  { field: 'courierExchangeVehicleSize', headerName: 'CXVehicleSize', minWidth: 200, flex: 1 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 200,
    flex: 1,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
  },
  { field: 'createdBy', headerName: 'Created By', minWidth: 200, flex: 1 },
]

const TariffCategories = () => {
  const [selectedTariffCategoryId, setSelectedTariffCategoryId] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedTariffCategoryId(Number(id))
    setOpenDialog(true)
  }

  const handleAddTariffCategory = () => {
    setSelectedTariffCategoryId(null)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const filter: TariffCategoryFilterInput = useMemo(
    () => ({
      or: [{ name: { contains: searchValue } }],
    }),
    [searchValue],
  )
  return (
    <>
      <FplDataGrid
        query={GET_PAGED_TARIFF_CATEGORIES}
        entityName='pagedTariffCategories'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'createdAt', sort: 'asc' }}
        toolbar={{
          caption: 'Tariff Categories',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddTariffCategory}>
                New Tariff Category
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <TariffCategoryDialog
          id={selectedTariffCategoryId}
          openDialog={openDialog}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default TariffCategories
