// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  Driver,
  EntityConstants,
  DriverDetailsPhonePattern,
  DigitPattern,
  AlphanumericAndSpacePattern,
} from 'constants/index'

import { PaymentTerm } from 'generated/graphql'
import * as yup from 'yup'

function DriverOnboardingDialogValidation() {
  return yup.object().shape({
    name: yup.string().required().max(EntityConstants.MAX_NAME_LENGTH),
    vatIdNumber: yup
      .string()
      .nullable()
      .max(
        EntityConstants.MAX_VAT_ID_NUMBER_LENGTH,
        `Vat Reg. Num must be at most ${EntityConstants.MAX_VAT_ID_NUMBER_LENGTH} characters`,
      ),
    eoriNumber: yup
      .string()
      .nullable()
      .max(
        EntityConstants.MAX_EORI_NUMBER_LENGTH,
        `EORI number must be at most ${EntityConstants.MAX_EORI_NUMBER_LENGTH} characters`,
      ),
    startDate: yup.date().typeError('Start date is a required field').required(),
    email: yup.string().email().required().max(EntityConstants.MAX_EMAIL_LENGTH),
    invoiceEmail: yup.string().email().nullable().max(EntityConstants.MAX_EMAIL_LENGTH),
    endorsements: yup
      .string()
      .nullable()
      .max(
        Driver.MAX_NOTE_LENGTH,
        `Endorsements must be at most ${Driver.MAX_NOTE_LENGTH} characters`,
      ),
    addressLine1: yup.string().required().max(EntityConstants.MAX_ADDRESS_LENGTH),
    addressLine2: yup.string().nullable().max(EntityConstants.MAX_ADDRESS_LENGTH),
    city: yup.string().required().max(EntityConstants.MAX_CITY_LENGTH),
    countryId: yup.number().required().min(1, 'Country is a required field'),
    postcode: yup.string().required().max(EntityConstants.MAX_POSTCODE_LENGTH),
    telephoneNumber: yup
      .string()
      .required()
      .matches(DriverDetailsPhonePattern, 'Please enter a valid phone number'),
    alternativeTelephoneNumber: yup
      .string()
      .optional()
      .transform((originalValue) => {
        const countryCodeRegex = /^\+(\d{1,3})$/
        if (originalValue && countryCodeRegex.test(originalValue.trim())) {
          return null
        }
        return originalValue
      })
      .matches(DriverDetailsPhonePattern, {
        message: 'Please enter a valid phone number',
        excludeEmptyString: true,
      })
      .nullable(),
    currencyId: yup.string().required(),
    bankName: yup
      .string()
      .nullable()
      .max(
        Driver.MAX_BANK_NAME_LENGTH,
        `Bank Name must be at most ${Driver.MAX_BANK_NAME_LENGTH} characters`,
      ),
    bankAccountNumber: yup
      .string()
      .required()
      .length(
        Driver.MAX_BANK_ACCOUNT_NUMBER_LENGTH,
        `The Account Number is an ${Driver.MAX_BANK_ACCOUNT_NUMBER_LENGTH} digits long code with no dashes or spaces.`,
      )
      .matches(
        DigitPattern,
        `The Account Number is an ${Driver.MAX_BANK_ACCOUNT_NUMBER_LENGTH} digits long code with no dashes or spaces.`,
      ),
    bankAccountHolderName: yup
      .string()
      .required()
      .max(
        Driver.MAX_BANK_ACCOUNT_HOLDER_NAME_LENGTH,
        `Bank Account Holder Name must be at most ${Driver.MAX_BANK_ACCOUNT_HOLDER_NAME_LENGTH} characters`,
      )
      .matches(
        AlphanumericAndSpacePattern,
        'Only alphanumeric characters, spaces and dashes are allowed. Numeric characters are forbidden.',
      ),
    bankSortCode: yup
      .string()
      .required()
      .length(
        EntityConstants.MAX_BANK_SORT_CODE_LENGTH,
        `The Sort Code is a ${EntityConstants.MAX_BANK_SORT_CODE_LENGTH} digits long code with no dashes or spaces.`,
      )
      .matches(
        DigitPattern,
        `The Sort Code is a ${EntityConstants.MAX_BANK_SORT_CODE_LENGTH} digits long code with no dashes or spaces.`,
      ),
    internationalBankAccountNumber: yup
      .string()
      .nullable()
      .max(
        Driver.MAX_BANK_INTERNATIONAL_ACCOUNT_NUMBER_LENGTH,
        `Bank International Account Number must be at most ${Driver.MAX_BANK_INTERNATIONAL_ACCOUNT_NUMBER_LENGTH} characters`,
      ),
    paymentTerm: yup.string().oneOf(Object.values(PaymentTerm)).required(),
    vatCategoryId: yup.string().required(),
    swift: yup.string().nullable(),
    companyRegistrationNumber: yup
      .string()
      .nullable()
      .max(
        EntityConstants.MAX_GENERIC_TEXT_LENGTH,
        `Company Registration Number must be at most ${EntityConstants.MAX_GENERIC_TEXT_LENGTH} characters`,
      ),
  })
}

export default DriverOnboardingDialogValidation
