import { useMemo, useState } from 'react'

import { Tooltip } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { TabPanelBootstrap, CardTitle, FplDynamicTabs } from 'components/common'
import { PackagingType, useDeleteBookingPackageMutation } from 'generated/graphql'
import { usePermission } from 'providers'
import { TBookingForm } from 'types/form-types'

import PackageForm from './PackageForm'

const BookingPackageDefaultValues = {
  quantity: '',
  packageType: PackagingType.Packages,
  collectionSequenceOrder: '',
  deliverySequenceOrder: '',
  length: '',
  width: '',
  height: '',
  weight: '',
  reference: '',
  details: '',
}

interface IProps {
  isQuote: boolean
  isQuickQuote: boolean
  disabled?: boolean
  hoverText: string
}

const PackageDetails = (props: IProps) => {
  const { isQuote, isQuickQuote, disabled, hoverText } = props
  const [tabValue, setTabValue] = useState(0)

  const {
    control,
    formState: { errors },
  } = useFormContext<TBookingForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packages',
    keyName: 'tabsKey',
  })

  const [isAllowedToUpdateBookingAfterQuoteIsBooked] = usePermission(
    'UpdateBookingAfterQuoteIsBooked',
  )
  const showAddAndRemoveButtons =
    !isQuote && !isAllowedToUpdateBookingAfterQuoteIsBooked ? false : true

  // Mutations
  const [deleteBookingPackage] = useDeleteBookingPackageMutation()

  const packagesErrors = errors?.packages
  const errorTabs: any = useMemo(
    () => (packagesErrors as any)?.map((value, index) => (value !== undefined ? index : null)),
    [packagesErrors],
  )

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue)
  }

  const handleAddClick = () => {
    const newTabValue = fields.length
    append(BookingPackageDefaultValues)
    setTabValue(newTabValue)
  }

  const handleRemoveClick = (e, index: number, id?: number) => {
    e.stopPropagation()

    if (id) {
      deleteBookingPackage({ variables: { packageId: id } }).then((result) => {
        if (!result?.data?.deleteBookingPackage) {
          toast.error('Something went wrong')
          return
        }

        if (tabValue === fields.length - 1) {
          setTabValue(fields.length - 2)
        }
        remove(index)
        toast.success('Package was deleted with success')
      })
    } else {
      if (tabValue === fields.length - 1) {
        setTabValue(fields.length - 2)
      }
      remove(index)
    }
  }

  return (
    <>
      <CardTitle disabled={disabled}>Package Details</CardTitle>

      <FplDynamicTabs
        tabValue={tabValue}
        fields={fields}
        name='package'
        label='Consignment'
        disabled={disabled}
        showAddButton={showAddAndRemoveButtons}
        showRemoveButton={showAddAndRemoveButtons}
        errorTabs={errorTabs}
        onTabChange={handleTabChange}
        onAddClick={handleAddClick}
        onRemoveClick={handleRemoveClick}
      />

      <Tooltip title={disabled ? hoverText : ''} followCursor>
        <div>
          {fields?.map((item, index) => (
            <TabPanelBootstrap value={tabValue} index={index} key={item.tabsKey}>
              <PackageForm index={index} isQuickQuote={isQuickQuote} disabled={disabled} />
            </TabPanelBootstrap>
          ))}
        </div>
      </Tooltip>
    </>
  )
}

export { PackageDetails, BookingPackageDefaultValues }
