import { useCallback, useMemo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton, Paper } from '@mui/material'
import { Tooltip } from '@mui/material'
import {
  GridActionsCellItem,
  GridEventListener,
  GridRowHeightParams,
  GridRowId,
} from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField } from 'components/common'
import { FplDataGrid, IGridColumn } from 'components/common/FplDataGrid'
import { DEBOUNCE_DELAY, Paths } from 'constants/index'
import { CustomerFilterInput, SortEnumType, useDeleteCustomerMutation } from 'generated/graphql'
import { GET_PAGED_CUSTOMERS } from 'graphql/queries'

const Customers = () => {
  const history = useHistory()

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  // mutations
  const [deleteCustomer, { loading: deleteCustomerProcessing }] = useDeleteCustomerMutation({
    refetchQueries: [
      {
        query: GET_PAGED_CUSTOMERS,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { accountCode: SortEnumType.Asc },
        },
      },
    ],
  })

  // handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) =>
    history.push(Paths.customers.updateWithId(id))

  const handleCreateCustomer = () => {
    history.push(Paths.customers.create)
  }

  // ToDo: add confirmation dialog
  const handleDeleteCustomer = useCallback(
    (id: GridRowId) => () => {
      deleteCustomer({
        variables: {
          customerId: Number(id),
        },
      }).then((response) => {
        if (response.data) {
          toast.success(`Customer ${id} was deleted with success!`)
        }
      })
    },
    [deleteCustomer],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleGetRowHeight = useCallback(({ model, densityFactor }: GridRowHeightParams) => {
    if (model.addressLine2) {
      return 80 * densityFactor
    } else {
      return 65 * densityFactor
    }
  }, [])

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'accountCode', headerName: 'Account Code', minWidth: 200, flex: 1 },
      { field: 'name', headerName: 'Name', minWidth: 230, flex: 1 },
      {
        field: 'addressLine1',
        headerName: 'Address',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <div>
              <div>{row.addressLine1}</div>
              <div>{row.addressLine2}</div>
              <div>{`${row.city}, ${row.postcode}`}</div>
            </div>
          )
        },
      },
      { field: 'telephoneNumber', headerName: 'Tel No', width: 150 },
      { field: 'invoiceEmail', headerName: 'Invoice Email', width: 150 },
      {
        field: 'contacts',
        headerName: 'Contact Person',
        width: 150,
        sortable: false,
        valueGetter: ({ value }) => {
          if (value.length === 0) {
            return ''
          }

          if (value.length === 1) {
            return value[0]?.name
          } else if (value.length > 1) {
            return 'Multiple'
          }
        },
      },
      { field: 'isOnHold', headerName: 'On Hold', width: 100, type: 'boolean' },
      { field: 'customerAccountType', headerName: 'A/C Type', width: 100 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleteCustomerProcessing}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteCustomer(id)}
          />,
        ],
      },
    ],
    [deleteCustomerProcessing, handleDeleteCustomer],
  )

  const filter: CustomerFilterInput = {
    or: [
      { name: { contains: searchValue } },
      { accountCode: { contains: searchValue } },
      { addressLine1: { contains: searchValue } },
    ],
  }

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <FplDataGrid
          query={GET_PAGED_CUSTOMERS}
          entityName='pagedCustomers'
          columns={columns}
          filter={filter}
          defaultOrder={{ field: 'accountCode', sort: 'asc' }}
          toolbar={{
            caption: 'Customers',
            leftSide: (
              <Grid item>
                <Button variant='outlined' size='small' onClick={handleCreateCustomer}>
                  New Customer
                </Button>
              </Grid>
            ),
            rightSide: (
              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            ),
          }}
          getRowHeight={handleGetRowHeight}
          onRowClick={handleRowClick}
        />
      </Box>
    </Paper>
  )
}

export default Customers
