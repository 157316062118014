import { gql } from '@apollo/client'

import * as LocalStorage from '../helpers'

function initClientData(cache) {
  cache.writeQuery({
    query: gql`
      query GetClientData {
        isThemeLight @client
      }
    `,
    data: {
      isThemeLight: LocalStorage.getIsThemeLight(),
    },
  })
}

export default initClientData
