import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
} from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ControlledSelector, ControlledTextField } from 'components/common'
import {
  AccountPayableRecordQueryReasonValues,
  AccountPayableRecordQueryReasonLabels,
  Roles,
} from 'constants/index'
import { useGetAccountPayableDriverNameQuery } from 'generated/graphql'
import { CREATE_OPEN_QUERY_MUTATION } from 'graphql/mutations'
import { useUserRole } from 'hooks'

interface IProps {
  title?: string
  message?: string
  open: boolean
  setOpen: any
  setActiveQuerySwitch: any
}

const QueryCreationDialog = (props: IProps) => {
  const {
    title = 'Open Query',
    message = 'Please specify the reason for your query.',
    open,
    setOpen,
    setActiveQuerySwitch,
  } = props

  const { id } = useParams<{ id?: string }>()
  const userRole = useUserRole()
  const isAdmin = userRole === Roles.admin
  const [emailChecked, setEmailChecked] = useState(isAdmin ? false : true)

  // Queries
  const { data: driver, loading: loading } = useGetAccountPayableDriverNameQuery({
    variables: { id: Number(id) },
    skip: !id,
  })

  // Mutations
  const [createOpenQuery] = useMutation(CREATE_OPEN_QUERY_MUTATION, {
    onCompleted: (response) => {
      if (!response) {
        toast.error('Something went wrong.')
        return
      }

      setOpen(false)
      toast.success('Query successfully created')
    },
  })

  const { control, setValue, handleSubmit } = useForm({
    shouldUnregister: true,
  })

  const reasonId = useWatch({ name: 'reasonId', control: control })

  const bookingInvoiceId = driver?.accountPayableRecord?.bookingInvoice.booking.ourReference
  const bookingInvoiceDriverName =
    driver?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.name
  const bookingInvoiceDriverEmail =
    driver?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.email

  useEffect(() => {
    const emailSupplierMessage =
      reasonId || reasonId === 0 ? AccountPayableRecordQueryReasonLabels[reasonId] : ''
    const reasonMessage = `A query for ${bookingInvoiceId} has been raised concerning the ${emailSupplierMessage}. Please consider reviewing it.`

    if (emailChecked) {
      setValue('reasonText', reasonMessage)
    }
  }, [bookingInvoiceId, reasonId, emailChecked, setValue, bookingInvoiceDriverName])

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailChecked(event.target.checked)
  }

  const handleClose = () => {
    setOpen(false)
    setActiveQuerySwitch(false)
  }

  const onSubmit = (formData) => {
    createOpenQuery({
      variables: {
        input: {
          accountPayableRecordId: Number(id),
          reason: reasonId,
        },
        emailOptions: {
          emailMessage: formData.reasonText ?? '',
          sendEmail: emailChecked,
          supplierEmail: bookingInvoiceDriverEmail,
        },
      },
    })
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth='xs'
      open={open}
      onClose={handleClose}
      aria-labelledby='query-creation-dialog'>
      <DialogTitle id='query-creation-dialog'>{title}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <br />
        <FormControl fullWidth>
          <ControlledSelector
            control={control}
            name='reasonId'
            options={AccountPayableRecordQueryReasonValues}
            label='Please select a reason'
          />
          <FormControlLabel
            control={<Checkbox checked={emailChecked} onChange={handleEmailChange} />}
            label={'Email Support Team'}
          />
          {emailChecked && (
            <ControlledTextField
              control={control}
              name='reasonText'
              label=''
              fullWidth
              multiline
              readOnly={!isAdmin}
            />
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose}>
          CANCEL
        </Button>
        <LoadingButton
          variant='contained'
          onClick={handleSubmit(onSubmit)}
          color='primary'
          loading={loading}
          disabled={!reasonId && reasonId !== 0}>
          OPEN QUERY
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default QueryCreationDialog
