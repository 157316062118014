import { ISelectorOptions } from 'components/common'
import {
  DeliverTimeRangeType,
  FixedJobDescriptionType,
  FixedLoadDtoMinVehicleSize,
  FixedOrderDtoPaymentTerm,
  LoadDtoBodyType,
  LoadDtoFreightType,
  PickupTimeRangeType,
  StopTimeRangeType,
  VolumeUnitType,
  WeightUnitType,
} from 'generated/graphql'

export const FreightTypeValues: ISelectorOptions = [
  { value: LoadDtoFreightType.Na, label: 'N/A' },
  { value: LoadDtoFreightType.Adr, label: 'ADR' },
  { value: LoadDtoFreightType.ForsBronze, label: 'FORS Bronze' },
  { value: LoadDtoFreightType.ForsSilver, label: 'FORS Silver' },
  { value: LoadDtoFreightType.ForsGold, label: 'FORS Gold' },
  { value: LoadDtoFreightType.Fragile, label: 'Fragile' },
]

export const BodyTypeValues: ISelectorOptions = [
  { value: LoadDtoBodyType.Na, label: 'N/A' },
  { value: LoadDtoBodyType.Box, label: 'Box' },
  { value: LoadDtoBodyType.Curtain, label: 'Curtain Side' },
  { value: LoadDtoBodyType.Dbldeck, label: 'Double Deck' },
  { value: LoadDtoBodyType.Dropside, label: 'Dropside' },
  { value: LoadDtoBodyType.Flatbed, label: 'Flatbed' },
]

export const PaymentTermValues: ISelectorOptions = [
  { value: FixedOrderDtoPaymentTerm.Cod, label: 'Cash On Delivery' },
  {
    value: FixedOrderDtoPaymentTerm.Value_07Fi,
    label: '7 Days (From Invoice)',
  },
  {
    value: FixedOrderDtoPaymentTerm.Value_14Fi,
    label: '14 Days (From Invoice)',
  },
  {
    value: FixedOrderDtoPaymentTerm.Value_21Fi,
    label: '21 Days (From Invoice)',
  },
  {
    value: FixedOrderDtoPaymentTerm.Value_30Fi,
    label: '30 Days (From Invoice)',
  },
  {
    value: FixedOrderDtoPaymentTerm.Value_30Eom,
    label: '30 Days (End Of Month)',
  },
  {
    value: FixedOrderDtoPaymentTerm.Value_45Fi,
    label: '45 Days (From Invoice)',
  },
  {
    value: FixedOrderDtoPaymentTerm.Value_45Eom,
    label: '45 Days (End Of Month)',
  },
  {
    value: FixedOrderDtoPaymentTerm.Value_60Fi,
    label: '60 Days (From Invoice)',
  },
]

export const MinVehicleSizeValues: ISelectorOptions = [
  { value: FixedLoadDtoMinVehicleSize.Car, label: 'Car' },
  { value: FixedLoadDtoMinVehicleSize.SVan, label: 'Small Van' },
  { value: FixedLoadDtoMinVehicleSize.Swb, label: 'SWB up to 2.4m' },
  { value: FixedLoadDtoMinVehicleSize.Transit, label: 'MWB up to 3m' },
  { value: FixedLoadDtoMinVehicleSize.Lwb, label: 'LWB up to 4m' },
  { value: FixedLoadDtoMinVehicleSize.Xlwb, label: 'XLWB 4m+' },
  { value: FixedLoadDtoMinVehicleSize.Luton, label: 'Luton' },
  { value: FixedLoadDtoMinVehicleSize.Value_7_5T, label: '7.5T' },
  { value: FixedLoadDtoMinVehicleSize.Value_12T, label: '12T' },
  { value: FixedLoadDtoMinVehicleSize.Value_18T, label: '18T' },
  { value: FixedLoadDtoMinVehicleSize.Value_26T, label: '26T' },
  {
    value: FixedLoadDtoMinVehicleSize.Value_13_6M,
    label: '13.6M (Artic)',
  },
  {
    value: FixedLoadDtoMinVehicleSize.Flatbed,
    label: 'Flatbed',
  },
  // { value: FixedLoadDtoMinVehicleSize.Bike, label: 'Bike' },
  // { value: FixedLoadDtoMinVehicleSize.MVan, label: 'Midi Van' },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Skel_1X20,
  //   label: 'Skellie 1x20ft',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Skel_2X20,
  //   label: 'Skellie 2x20ft',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Skel_1X40,
  //   label: 'Skellie 1x40ft',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Skel_1X44,
  //   label: 'Skellie 1x44ft',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Skel_1X44,
  //   label: 'Skellie 1x44ft',
  // },

  // {
  //   value: FixedLoadDtoMinVehicleSize.Value_4AxTrac,
  //   label: '4AX Trac',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Value_6AxTrac,
  //   label: '6AX Trac',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.CargoVla,
  //   label: 'Cargo VLA',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Cargo,
  //   label: 'Cargo',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.CarPup,
  //   label: 'Car Pup',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.CargoVsm,
  //   label: 'Cargo Vsm',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Sprinter,
  //   label: 'Sprinter',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Stakebed,
  //   label: 'Stakebed',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.SStraight,
  //   label: 'S Straight',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.LStraight,
  //   label: 'L Straight',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Tractor48,
  //   label: 'Tractor 48',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Tractor53,
  //   label: 'Tractor 53',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Superb,
  //   label: 'Superb',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.LowBoy,
  //   label: 'Low Boy',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Tank,
  //   label: 'Tank',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.CargoV,
  //   label: 'Cargo V',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.SprinterC,
  //   label: 'Sprinter C',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.StrTruck,
  //   label: 'Str Truck',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.BikeOther,
  //   label: 'Other Bike',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.BikeOther,
  //   label: 'Bike Other',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.CarOther,
  //   label: 'Car Other',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.CargoSm,
  //   label: 'Cargo Sm',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.CargoLa,
  //   label: 'Cargo La',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.PupOp,
  //   label: 'Pup Op',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.PupCl,
  //   label: 'Pup Cl',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Suv,
  //   label: 'SUV',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Flat,
  //   label: 'Flat',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Stake,
  //   label: 'Stake',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box10,
  //   label: 'Box 10',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box12,
  //   label: 'Box 12',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box12,
  //   label: 'Box 12',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box14,
  //   label: 'Box 14',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box16,
  //   label: 'Box 16',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box18,
  //   label: 'Box 18',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box20,
  //   label: 'Box 20',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box22,
  //   label: 'Box 22',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box24,
  //   label: 'Box 24',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box26,
  //   label: 'Box 26',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box28,
  //   label: 'Box 28',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box30,
  //   label: 'Box 30',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box40,
  //   label: 'Box 40',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box48,
  //   label: 'Box 48',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Box53,
  //   label: 'Box 53',
  // },
  // {
  //   value: FixedLoadDtoMinVehicleSize.Other,
  //   label: 'Other',
  // },
]

export const JobDescriptionTypeValues: ISelectorOptions = [
  {
    value: FixedJobDescriptionType.SameDayTimed,
    label: 'Same Day - Timed',
  },
  {
    value: FixedJobDescriptionType.SameDayNonTimed,
    label: 'Same Day - Non Timed',
  },
  {
    value: FixedJobDescriptionType.NextDayTimed,
    label: 'Next Day - Timed',
  },
  {
    value: FixedJobDescriptionType.NextDayNonTimed,
    label: 'Next Day - Non Timed',
  },
  { value: FixedJobDescriptionType.Value_3_5Days, label: '3 - 5 Days' },
  { value: FixedJobDescriptionType.MultiDrop, label: 'Multi-Drop' },
  {
    value: FixedJobDescriptionType.DeliverDirect,
    label: 'Deliver Direct',
  },
  { value: FixedJobDescriptionType.Other, label: 'Other' },
]

export const PickupTimeRangeTypeValues: ISelectorOptions = [
  { value: PickupTimeRangeType.At, label: 'At' },
  { value: PickupTimeRangeType.Before, label: 'Before' },
  { value: PickupTimeRangeType.Between, label: 'Between' },
]

export const DeliverTimeRangeTypeValues: ISelectorOptions = [
  { value: DeliverTimeRangeType.Asap, label: 'ASAP' },
  { value: DeliverTimeRangeType.At, label: 'At' },
  { value: DeliverTimeRangeType.By, label: 'By' },
  { value: DeliverTimeRangeType.Between, label: 'Between' },
]

export const StopsTimeRangeTypeValues: ISelectorOptions = [
  { value: StopTimeRangeType.Asap, label: 'ASAP' },
  { value: StopTimeRangeType.Between, label: 'Between' },
]

export const VolumeUnitTypeValues: ISelectorOptions = [
  { value: VolumeUnitType.M, label: 'm' },
  { value: VolumeUnitType.Cm, label: 'cm' },
  { value: VolumeUnitType.In, label: 'in' },
  { value: VolumeUnitType.Ft, label: 'ft' },
]

export const WeightUnitTypeValues: ISelectorOptions = [
  { value: WeightUnitType.Kg, label: 'kg' },
  { value: WeightUnitType.Lb, label: 'lbs' },
  { value: WeightUnitType.T, label: 't' },
]
