import { useEffect } from 'react'

import { useMutation } from '@apollo/client'
import { DevTool } from '@hookform/devtools'
import { joiResolver } from '@hookform/resolvers/joi'
import Decimal from 'decimal.js'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getBookingAddressDefaultValues } from 'components/bookings/BookingDetails/AddressDetails'
import { BookingPackageDefaultValues } from 'components/bookings/BookingDetails/PackageDetails'
import { DefaultAcceptancePeriod, JoiValidationOptions, Mode, Paths } from 'constants/index'
import {
  CreateAndUpdateBookingCombinedDtoInput,
  GoogleDistanceStatus,
  useDeleteBookingPackageMutation,
  useGetBookingTariffLazyQuery,
  useGetDistanceCalculationLazyQuery,
} from 'generated/graphql'
import {
  CREATE_BOOKING_COMBINED_MUTATION,
  CREATE_USER_BOOKING_COMBINED_MUTATION,
  UPDATE_BOOKING_COMBINED_MUTATION,
  UPDATE_USER_BOOKING_COMBINED_MUTATION,
} from 'graphql/mutations'
import { isBookingProcessingVar } from 'graphql/reactiveVariables'
import {
  normalizeBookingAddressesDto,
  normalizeBookingChargesDto,
  normalizeBookingPackagesDto,
  getGoogleAddressInput,
  calcBookingConsignmentFee,
} from 'helpers'
import { usePermission } from 'providers/PermissionProvider'
import { TBookingForm } from 'types/form-types'
import { BookingDetailsValidation } from 'validation-schemas'

import BookingDiscardConfirmation from './BookingDiscardConfirmation'
import BookingFormControls from './BookingFormControls'
import UpdateBooking from './UpdateBooking'

interface IProps {
  bookingId: string | null
  mode: number | undefined
  preFilledFields?: TBookingForm
  isQuote?: boolean
  isQuickQuote?: boolean
}

const BookingDetails = (props: IProps) => {
  const { bookingId, mode, preFilledFields, isQuote = false, isQuickQuote = false } = props

  const history = useHistory()

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')
  const [isAllowedToMakeCollectionAndDeliveryContactDataRequired] = usePermission(
    'MakeBookingCollectionAndDeliveryContactDataRequired',
  )
  const [isAllowedToMakeCollectionDateTimeRequired] = usePermission(
    'MakeBookingCollectionDateTimeRequired', // TODO: Check if deprecated
  )

  const isCollectionAndDeliveryContactDataRequired = isQuickQuote
    ? false
    : isAllowedToMakeCollectionAndDeliveryContactDataRequired

  const methods = useForm<TBookingForm>({
    context: {
      isQuote,
      isQuickQuote,
      isCollectionAndDeliveryContactDataRequired,
      isAllowedToMakeCollectionDateTimeRequired,
      mode,
    },
    resolver: joiResolver(BookingDetailsValidation, JoiValidationOptions),
    defaultValues: {
      customerId: '',
      customerReference: '',
      ourReference: '',
      contactId: '',
      overrideContactEmail: '',
      overrideContactTelephoneNumber: '',
      validUntil: isQuote ? DefaultAcceptancePeriod : '',
      sendQuoteEmail: false,

      tariffId: '',
      noteOrSpecialInstruction: '',
      alternativeOurReference: '',
      isEmailNotification: true,
      consignmentFee: 0,
      distance: 0,
      isWaitAndReturn: false,
      isTransitIgnored: false,
      isBiddingEnabled: false,

      collectionAddresses: [getBookingAddressDefaultValues(0)],
      deliveryAddresses: [getBookingAddressDefaultValues(1)],
      packages: [BookingPackageDefaultValues],
      charges: [],
      potentialBookingId: undefined,

      //validation fields
      isReferenceRequired: false,
      isReasonCodeEnabled: false,
      isPackageDetailsSectionOptional: false,
      ...(preFilledFields && preFilledFields),
    },
  })

  const {
    handleSubmit,
    control,
    formState: { dirtyFields },
  } = methods

  let createBookingMutation
  let updateBookingMutation
  if (isAllowedToViewBookingFee) {
    createBookingMutation = CREATE_BOOKING_COMBINED_MUTATION
    updateBookingMutation = UPDATE_BOOKING_COMBINED_MUTATION
  } else {
    createBookingMutation = CREATE_USER_BOOKING_COMBINED_MUTATION
    updateBookingMutation = UPDATE_USER_BOOKING_COMBINED_MUTATION
  }

  // Mutations
  const [createBookingCombined, { loading: creatingBookingCombined }] = useMutation(
    createBookingMutation,
    {
      onCompleted: (data) => {
        if (!isQuote) {
          history.push(Paths.bookings.updateWithId(data?.createBookingCombined?.id))
          toast.success(`Booking (${data?.createBookingCombined?.id}) was created with success`)
        } else if (isQuickQuote) {
          history.push(Paths.quickQuotes.updateWithId(data?.createBookingCombined?.id))
          toast.success(`Quick Quote (${data?.createBookingCombined?.id}) was created with success`)
        } else {
          history.push(Paths.quotes.updateWithId(data?.createBookingCombined?.id))
          toast.success(`Quote (${data?.createBookingCombined?.id}) was created with success`)
        }
      },
    },
  )

  const [updateBookingCombined, { loading: updatingBookingCombined }] = useMutation(
    updateBookingMutation,
    {
      onCompleted: (data) => {
        if (!isQuote) {
          toast.success(`Booking (${data?.updateBookingCombined?.id}) was updated with success`)
        } else if (isQuickQuote) {
          toast.success(`Quick Quote (${data?.updateBookingCombined?.id}) was updated with success`)
        } else {
          toast.success(`Quote (${data?.updateBookingCombined?.id}) was updated with success`)
        }
      },
    },
  )

  const [getDistanceCalculation, { loading: distanceLoading }] =
    useGetDistanceCalculationLazyQuery()

  const [getBookingTariff, { loading: bookingTariffLoading }] = useGetBookingTariffLazyQuery()

  const [deleteBookingPackage, { loading: deletingBookingPackageLoading }] =
    useDeleteBookingPackageMutation()

  useEffect(() => {
    isBookingProcessingVar(
      creatingBookingCombined ||
      updatingBookingCombined ||
      distanceLoading ||
      bookingTariffLoading ||
      deletingBookingPackageLoading,
    )
  }, [
    bookingTariffLoading,
    creatingBookingCombined,
    distanceLoading,
    updatingBookingCombined,
    deletingBookingPackageLoading,
  ])

  const handleSubmitBookingCombined: SubmitHandler<TBookingForm> = async (formData) => {
    const {
      collectionAddresses,
      deliveryAddresses,
      packages,
      charges,
      validUntil,
      serviceType,
      distance,
      consignmentFee,
      tariffId,
      isReferenceRequired, // Hidden
      isReasonCodeEnabled, // Hidden
      isPackageDetailsSectionOptional, // Hidden
      ...restFormData
    } = formData

    const normalizedProps: Partial<CreateAndUpdateBookingCombinedDtoInput> = {
      isQuote,
      isQuickQuote,
      addressesList: [],
      chargesList: [],
      packagesList: [],
      validUntil: validUntil ? validUntil : undefined,
      distance,
      consignmentFee,
      tariffId,
      // ToDo: maybe to remove this field from BA
      isCalculateDistanceAutomatically: true,
    }

    if (isQuickQuote) {
      const googleAddressInput = getGoogleAddressInput(collectionAddresses, deliveryAddresses)

      const { data: distanceData } = await getDistanceCalculation({
        variables: { input: googleAddressInput },
      })

      if (distanceData) {
        if (distanceData.distanceCalculation.status === GoogleDistanceStatus.Success) {
          const distance = new Decimal(distanceData.distanceCalculation.distance)
            .toDP(2, Decimal.ROUND_FLOOR)
            .toNumber()

          const { data: tariffData } = await getBookingTariff({
            variables: { id: Number(tariffId) },
          })

          if (tariffData?.tariffById) {
            const { minMiles, minCharge, rate } = tariffData.tariffById

            const consignmentFee = calcBookingConsignmentFee(distance, minMiles, minCharge, rate)
            normalizedProps.consignmentFee = consignmentFee
          }

          normalizedProps.distance = distance
        } else if (distanceData.distanceCalculation.status === GoogleDistanceStatus.Error) {
          toast.warning(`Distance calculation: ${distanceData.distanceCalculation.errorMessage}`)
        }
      }
    }

    // Addresses
    normalizedProps.addressesList = normalizeBookingAddressesDto(
      formData,
      bookingId,
      mode === Mode.Create ? formData : dirtyFields,
      isQuickQuote,
    )

    // Packages
    const emptyPackages = packages.filter((p) => !p.quantity && p.id)

    for (const p of emptyPackages) {
      await deleteBookingPackage({ variables: { packageId: Number(p.id) } })
    }

    normalizedProps.packagesList = normalizeBookingPackagesDto(packages, bookingId)

    // Charges
    normalizedProps.chargesList = charges ? normalizeBookingChargesDto(charges, bookingId) : []

    delete restFormData['status']

    if (mode === Mode.Create) {
      createBookingCombined({
        variables: {
          input: {
            ...restFormData,
            ...normalizedProps,
          },
        },
      })

      // TODO: Discuss if we need to "mode = Mode.Update" here
    } else {
      updateBookingCombined({
        variables: {
          input: {
            id: bookingId,
            ...restFormData,
            ...normalizedProps,
          },
        },
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          id='booking-details-form'
          noValidate
          onSubmit={handleSubmit(handleSubmitBookingCombined)}>
          {mode === Mode.Create ? (
            <BookingFormControls isQuote={isQuote} isQuickQuote={isQuickQuote} mode={mode} />
          ) : (
            <UpdateBooking bookingId={bookingId || ''} isQuickQuote={isQuickQuote} mode={mode} />
          )}
        </form>
        <BookingDiscardConfirmation />
      </FormProvider>

      {process.env.NODE_ENV && process.env.NODE_ENV === 'development' && (
        <DevTool control={control} />
      )}
    </>
  )
}

export default BookingDetails
