import { useMemo } from 'react'

import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledAutocomplete,
  ControlledTextField,
  IAutocompleteOptions,
} from 'components/common'
import {
  CreateAndUpdateReasonCodeDtoInput,
  SortEnumType,
  useGetCustomersQuery,
} from 'generated/graphql'
import { usePermission } from 'providers'

interface IProps {
  isUpdate?: boolean
}
export default function ReasonCodeForm(props: IProps) {
  const { isUpdate = false } = props

  const spacing = 3

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateReasonCodeDtoInput>()

  const [isAllowedToViewReasonCodeList] = usePermission('ViewReasonCodeList')

  const { data: customersData, loading: customersLoading } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
    skip: !isAllowedToViewReasonCodeList,
  })

  const customersOptions: IAutocompleteOptions = useMemo(
    () =>
      customersData?.customers.map((customer) => ({
        value: customer.id,
        label: customer.name,
      })) || [],
    [customersData?.customers],
  )

  return (
    <Box pt={1}>
      <Grid container spacing={spacing}>
        {isAllowedToViewReasonCodeList && (
          <Grid item xs={12}>
            <ControlledAutocomplete
              control={control}
              label='Customer'
              name='customerId'
              options={customersOptions}
              defaultValue=''
              required
              loading={customersLoading}
              disabled={isUpdate}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='name'
            label='Name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='code'
            label='Code'
            required
            error={!!errors.code}
            helperText={errors.code?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='description'
            label='Description'
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
