import { useReactiveVar } from '@apollo/client'
import { Stack, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material'

import { StatusCategories } from 'constants/index'
import { bookingsStatusCategoryVar } from 'graphql/reactiveVariables'
import { usePermission } from 'providers/PermissionProvider'

const StatusCategoryFilter = () => {
  const bookingStatusCategory = useReactiveVar(bookingsStatusCategoryVar)

  const [isAllowedToScheduled] = usePermission('ViewBookingsList')

  const theme = useTheme()
  const isBreakpointUpSm = useMediaQuery(theme.breakpoints.up('sm'))
  const isBreakpointUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const handleStatusCategory = (_, newStatus: StatusCategories) => {
    if (newStatus !== null) {
      bookingsStatusCategoryVar(newStatus)
    }
  }

  return (
    <Stack alignItems='center'>
      {isBreakpointUpSm ? (
        <ToggleButtonGroup
          value={bookingStatusCategory}
          onChange={handleStatusCategory}
          color='primary'
          size='small'
          orientation={isBreakpointUpMd ? 'vertical' : 'horizontal'}
          exclusive>
          <ToggleButton value={StatusCategories.PreBooked}>Pre Booked</ToggleButton>
          <ToggleButton value={StatusCategories.InProgress}>In Progress</ToggleButton>
          <ToggleButton value={StatusCategories.Complete}>Complete</ToggleButton>
          <ToggleButton value={StatusCategories.Quote}>Quote</ToggleButton>
          {isAllowedToScheduled && (
            <ToggleButton value={StatusCategories.ScheduledTemplate}>Scheduled</ToggleButton>
          )}
        </ToggleButtonGroup>
      ) : (
        <>
          <ToggleButtonGroup
            value={bookingStatusCategory}
            onChange={handleStatusCategory}
            color='primary'
            size='small'
            orientation='horizontal'
            exclusive>
            <ToggleButton value={StatusCategories.PreBooked}>Pre Booked</ToggleButton>
            <ToggleButton value={StatusCategories.InProgress}>In Progress</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            value={bookingStatusCategory}
            onChange={handleStatusCategory}
            color='primary'
            size='small'
            orientation='horizontal'
            exclusive>
            <ToggleButton value={StatusCategories.Complete}>Complete</ToggleButton>
            <ToggleButton value={StatusCategories.Quote}>Quote</ToggleButton>
            {isAllowedToScheduled && (
              <ToggleButton value={StatusCategories.ScheduledTemplate}>Scheduled</ToggleButton>
            )}
          </ToggleButtonGroup>
        </>
      )}
    </Stack>
  )
}

export default StatusCategoryFilter
