import { Typography } from '@mui/material'

interface IProps {
  vehicleId: number | null
  notFound: boolean
}

const VehicleFormHeader = (props: IProps) => {
  const { vehicleId, notFound } = props
  let title: string

  if (notFound) {
    title = 'Vehicle Not Found'
  } else if (!vehicleId) {
    title = 'Create Vehicle'
  } else {
    title = 'Update Vehicle'
  }

  return (
    <Typography paragraph variant='h4'>
      {title}
    </Typography>
  )
}

export default VehicleFormHeader
