import { Box, Grid, Paper, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledSelector } from 'components/common'
import { useGetPagedTariffsQuery } from 'generated/graphql'

interface IProps {
  customerId: number
}

const TariffDetails = (props: IProps) => {
  const { loading: tariffsLoading, data: tariffs } = useGetPagedTariffsQuery({
    variables: { customerId: props.customerId },
  })

  const { control } = useFormContext()

  return (
    <Paper variant='outlined'>
      <Box p={2}>
        <Typography paragraph variant='h5' color='primary'>
          Tariff Details
        </Typography>
        <Box p={1}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              {tariffsLoading ? (
                <Skeleton>
                  <ControlledSelector
                    control={control}
                    label='Default Tariff'
                    name='defaultTariff'
                    options={[]}
                  />
                </Skeleton>
              ) : (
                <ControlledSelector
                  control={control}
                  label='Default Tariff'
                  name='defaultTariff'
                  options={
                    tariffs?.pagedTariffs?.edges?.map((tariff) => {
                      return {
                        value: tariff.node?.id,
                        label: tariff.node?.name,
                      }
                    }) ?? []
                  }
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  )
}

export default TariffDetails
