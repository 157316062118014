import { gql } from '@apollo/client'

import { CommentaryDetailsFragment } from 'graphql/fragments'

export const CREATE_COMMENTARY_MUTATION = gql`
  mutation createCommentary($input: CreateUpdateCommentaryDtoInput!){
    createCommentary(input: $input) {
      id
      ...CommentaryDetails
    }
    ${CommentaryDetailsFragment}
  }
`

export const UPDATE_COMMENTARY_MUTATION = gql`
  mutation updateCommentary($input: CreateUpdateCommentaryDtoInput!){
    updateCommentary(input: $input) {
      id
      ...CommentaryDetails
    }
    ${CommentaryDetailsFragment}
  }
`

export const DELETE_COMMENTARY_MUTATION = gql`
  mutation deleteCommentary($id: Long!) {
    deleteCommentary(id: $id)
  }
`
