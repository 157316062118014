import { Roles } from 'constants/EntityConstants'
import { CreateUpdateUserDtoInput, GetUserDetailsQuery } from 'generated/graphql'
import { TUserForm } from 'types'

export const normalizeUserForm = (
  user: NonNullable<GetUserDetailsQuery['userDetails']>,
): TUserForm => {
  const companyDetails = user.companyDetails
    ? {
        companyName: user.companyDetails.companyName,
        customerId: user.companyDetails.customerId,
        contactId: user.companyDetails.contactId,
      }
    : undefined

  return {
    username: user.userName,
    role: user.role,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    city: user.city ?? '',
    postcode: user.postcode ?? '',
    dob: user.dob,
    countryId: user.countryId,
    phoneNumber: user.phoneNumber,
    driverId: user.driverId,
    companyDetails,
  }
}

export const normalizeUserDto = (formData: TUserForm): CreateUpdateUserDtoInput => {
  const companyDetails =
    formData.role === Roles.customer && formData.companyDetails
      ? {
          companyName: formData.companyDetails.companyName,
          customerId: formData.companyDetails.customerId,
          contactId: formData.companyDetails?.contactId
            ? Number(formData.companyDetails?.contactId)
            : undefined,
        }
      : undefined

  return {
    username: formData.username,
    password: formData.password,
    role: formData.role,
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    city: formData.city,
    postcode: formData.postcode,
    dob: formData.dob,
    countryId: String(formData.countryId),
    phoneNumber: formData.phoneNumber,
    companyDetails,
  }
}
