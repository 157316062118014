import { AccordionSummary, Typography } from '@mui/material'

interface AccordionHeaderProps {
  children: string
}

const AccordionHeader = ({ children }: AccordionHeaderProps) => {
  return (
    <AccordionSummary>
      <Typography variant='h5'>{children}</Typography>
    </AccordionSummary>
  )
}

export default AccordionHeader
