import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { OtherChargeFilterInput, useDeleteOtherChargeMutation } from 'generated/graphql'
import { GET_OTHER_CHARGE_PAGED_QUERY } from 'graphql/queries'

import CreateUpdateOtherChargeDialog from './CreateUpdateOtherChargeDialog'

export default function OtherCharges() {
  const [selectedOtherChargeId, setSelectedOtherChargeId] = React.useState<number | null>(null)
  const [openDialog, setOpeDialog] = React.useState<boolean>(false)

  const { control, setValue, watch } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  // mutation
  const [deleteOtherCharge, { loading: deletingOtherCharge }] = useDeleteOtherChargeMutation()

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedOtherChargeId(Number(id))
    setOpeDialog(true)
  }

  const handleAddNewOtherCharge = () => {
    setSelectedOtherChargeId(null)
    setOpeDialog(true)
  }

  const handleCloseOtherCharge = () => {
    setOpeDialog(false)
  }

  // ToDo: add confirmation dialog
  const handleDeleteOtherCharge = React.useCallback(
    (id: GridRowId) => () => {
      deleteOtherCharge({
        variables: { id: Number(id) },
      }).then((response) => {
        if (response.data) {
          toast.success(`Other Charge ${id} was deleted with success!`)
        }
      })
    },
    [deleteOtherCharge],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const columns: IGridColumn[] = React.useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'description', headerName: 'Description', minWidth: 200, flex: 1 },
      { field: 'rate', headerName: 'Rate', minWidth: 200, flex: 1 },
      {
        field: 'xeroServiceCode',
        headerName: 'Service Code',
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deletingOtherCharge}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteOtherCharge(id)}
          />,
        ],
      },
    ],
    [deletingOtherCharge, handleDeleteOtherCharge],
  )

  const filter = {
    or: [{ xeroServiceCode: { contains: searchValue } }, { name: { contains: searchValue } }],
  } as OtherChargeFilterInput

  return (
    <>
      <FplDataGrid
        query={GET_OTHER_CHARGE_PAGED_QUERY}
        entityName='pagedCharges'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'name', sort: 'asc' }}
        toolbar={{
          caption: 'Other Charges',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddNewOtherCharge}>
                Add New
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <CreateUpdateOtherChargeDialog
          otherChargeId={selectedOtherChargeId}
          openDialog={openDialog}
          handleCloseDialog={handleCloseOtherCharge}
        />
      )}
    </>
  )
}
