import { gql } from '@apollo/client'

import { DriverDetailsFragment } from 'graphql/fragments'

export const CREATE_DRIVER = gql`
  mutation CreateDriver($input: CreateAndUpdateDriverDtoInput!) {
    createDriver(input: $input) {
      ...DriverDetails
    }
    
    ${DriverDetailsFragment}
  }
`

export const UPDATE_DRIVER = gql`
  mutation UpdateDriver($input: CreateAndUpdateDriverDtoInput!) {
    updateDriver(input: $input) {
      ...DriverDetails
    }

    ${DriverDetailsFragment}
  }
`

export const DELETE_DRIVER_MUTATION = gql`
  mutation DeleteDriver($driverId: Long!) {
    deleteDriver(driverId: $driverId)
  }
`

export const CREATE_SUB_DRIVER = gql`
  mutation CreateSubDriver($input: CreateAndUpdateSubDriverDtoInput!) {
    createSubDriver(input: $input) {
      id
      primaryDriverId
      callSign
      driverType
      email
      isActive
      xeroCodeId
      name
      telephoneNumber
      xeroPurchaseCode
    }
  }
`

export const UPDATE_SUB_DRIVER = gql`
  mutation UpdateSubDriver($input: CreateAndUpdateSubDriverDtoInput!) {
    updateSubDriver(input: $input) {
      id
      primaryDriverId
      callSign
      driverType
      email
      isActive
      xeroCodeId
      name
      telephoneNumber
      xeroPurchaseCode
    }
  }
`

export const CREATE_DRIVER_CONTACT = gql`
  mutation CreateDriverContact($input: CreateAndUpdateDriverContactDtoInput!) {
    createDriverContact(input: $input) {
      id
      driverId
      name
      isDefault
      telephoneNumber
      email
      additionalEmails
      department
      jobRole
    }
  }
`

export const UPDATE_DRIVER_CONTACT = gql`
  mutation UpdateDriverContact($input: CreateAndUpdateDriverContactDtoInput!) {
    updateDriverContact(input: $input) {
      id
      driverId
      name
      isDefault
      telephoneNumber
      email
      additionalEmails
      department
      jobRole
    }
  }
`

export const DELETE_DRIVER_CONTACT = gql`
  mutation DeleteDriverContact($driverContactId: Long!) {
    deleteDriverContact(driverContactId: $driverContactId)
  }
`

export const SEND_TEST_NOTIFICATION_MUTATION = gql`
  mutation SendTestNotification(
    $driverId: Long!
    $title: String!
    $message: String!
    $subtitle: String!
    $accessToken: String
  ) {
    testPushNotification(
      driverId: $driverId
      title: $title
      message: $message
      subtitle: $subtitle
      accessToken: $accessToken
    )
  }
`

export const UPDATE_BOOKING_DRIVER_RATING = gql`
  mutation updateBookingDriverRating($input: BookingDriverRatingDtoInput!) {
    updateDriverRating(input: $input) {
      id
      bookingId
      rating
      ratingComment
      driver {
        id
        averageRating
      }
    }
  }
`
