import { AxiosResponse } from 'axios'
import { AlertType } from 'react-mui-dropzone'
import { toast } from 'react-toastify'

// import { AuthErrorCodes } from 'constants/index'

export const getOidcStorage = (): any => {
  const oidcStorage = JSON.parse(localStorage.getItem(`oidc.default`) as string)

  return oidcStorage
}

export const isNumber = (value: unknown): boolean => !Number.isNaN(Number(value))
export const CloneProps = (props: any) => {
  const { children, ...otherProps } = props

  return children(otherProps)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function nameof<T>(key: keyof T, instance?: T): keyof T {
  return key
}

export const ShowDropzoneAlert = (message: string, variant: AlertType) => {
  switch (variant) {
    // 🛑
    case 'error':
      toast.error(message)
      break
    // ✅
    case 'success':
      toast.success(message)
      break
    // ℹ️
    case 'info':
      toast.info(message)
      break
  }
}

export const ShowRestError = (error) => {
  toast.error('Something went wrong.')

  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.error(error.request)
  } else {
    // Something happened in setting up the request and triggered an Error
    console.error('Error', error.message)
  }
  console.error(error.config)
}

export const SaveFile = (data: any, fileName: string) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = downloadUrl

  link.setAttribute('download', fileName)

  document.body.appendChild(link)

  link.click()

  link.remove()
}

export function ToArray(enumType) {
  return Object.keys(enumType)
    .filter((x) => !isNumber(x))
    .map((key) => enumType[key])
}

export const GetFileName = (response: AxiosResponse): string | undefined => {
  const contentDisposition = response.headers['content-disposition']
  const match = contentDisposition?.match(/filename\s*=\s*"?(.+[^"])"?;/i)
  return match?.[1]
}

export const GetErrorMessage = (error: unknown): string => {
  if (typeof error === 'string') {
    return error
  } else if (error instanceof Error) {
    return error.message
  }
  return 'Unknown error occured'
}

export const ExtractValidationErrorMessages = (errorMessage: any): string | null => {
  const startIndex = errorMessage?.indexOf('{')
  if (startIndex === -1) return null

  try {
    const jsonString = errorMessage.substring(startIndex)
    const jsonObject = JSON.parse(jsonString)

    if (!jsonObject.Elements || !Array.isArray(jsonObject.Elements)) return null

    const validationErrorMessages = jsonObject.Elements.flatMap(
      (element: any) => element.ValidationErrors || [],
    ).map((error: any) => error.Message)

    return validationErrorMessages.length > 0 ? validationErrorMessages.join(', ') : null
  } catch (e) {
    console.error('Failed to parse JSON:', e)
    return null
  }
}

export * from './localStorage'
export * from './useHashToSelectTab'
export * from './numberFormat'
export * from './calcBookingConsignmentFee'
export * from './form.helpers'
export * from './axios.helpers'
export * from './bookingsFilter'
export * from './numberFormat.helpers'
export * from './bookingDetails.helpers'
export * from './bookingDetails.normalize.helpers'
export * from './user.helpers'
export * from './date.helpers'
export * from './transitTime.helpers'
export * from './sopDetails.helpers'
export * from './customer.helpers'
export * from './potential-booking.helpers'
export * from './tariff.helpers'
export * from './xeroCode.helpers'
