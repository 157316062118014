import React from 'react'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { TableCell, TableRow } from '@mui/material'

import { IconLoadingButton } from 'components/common'
import { File, useDeleteBookingAddressAttachmentMutation } from 'generated/graphql'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadFile } from 'services'

interface AttachmentRowProps {
  type: string
  data: File
  disabled?: boolean
  onFilePreviewed: (id: number) => void
  onFileRemoved?: () => void
}

const AttachmentRow = (props: AttachmentRowProps) => {
  const { data, onFileRemoved, onFilePreviewed, type, disabled } = props
  const [downloading, setDownloading] = React.useState(false)

  // mutations

  const [deleteAttachment, { loading: deleting }] = useDeleteBookingAddressAttachmentMutation({
    onCompleted: () => {
      if (onFileRemoved) {
        onFileRemoved()
      }
    },
  })

  // handlers
  const handlePreview = () => {
    onFilePreviewed(Number(data.id))
  }

  const handleDelete = () => {
    deleteAttachment({
      variables: {
        input: {
          addressId: Number(data.entityReferenceId),
          fileId: Number(data.id),
        },
      },
    })
  }

  const handleDownload = () => {
    setDownloading(true)
    DownloadFile(data.id)
      .then((response) => {
        let fileName = GetFileName(response)
        if (!fileName) {
          fileName = data.fileNameInStorage
        }

        SaveFile(response.data, fileName)
      })
      .catch(ShowRestError)
      .finally(() => setDownloading(false))
  }

  return (
    <TableRow>
      <TableCell>{data.originalFileName}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{new Date(data.createdAt).toDateString()}</TableCell>
      <TableCell>{data.createdBy}</TableCell>
      <TableCell style={{ display: 'flex', flexDirection: 'row' }}>
        <IconLoadingButton loading={false} onClick={handlePreview}>
          <VisibilityIcon />
        </IconLoadingButton>
        <IconLoadingButton loading={downloading} onClick={handleDownload}>
          <CloudDownloadIcon />
        </IconLoadingButton>
        <IconLoadingButton disabled={disabled} loading={deleting} onClick={handleDelete}>
          <DeleteForeverOutlinedIcon />
        </IconLoadingButton>
      </TableCell>
    </TableRow>
  )
}

export default AttachmentRow
