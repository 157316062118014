import { addBusinessDays, addDays, set } from 'date-fns'

import { CutOffTimeDeadlines } from 'constants/index'
import { XeroServiceType } from 'generated/graphql'
import { FillIntervalParams, TimeSetProps } from 'types'

import { dateIsGreater } from './date.helpers'

const getTimeSetProps = (defaultHour: number, date?: Date): TimeSetProps => {
  return date
    ? {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: 0,
        milliseconds: 0,
      }
    : {
        hours: defaultHour,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }
}

export const calculateTransitInterval = ({
  transitInterval,
  collectionAt,
  collectionBy,
  deliveryAddress,
  onDeliveryAtSet,
  onDeliveryBySet,
}: FillIntervalParams) => {
  if (collectionAt) {
    const setValue = getTimeSetProps(
      transitInterval.start.getHours(),
      deliveryAddress.at ? new Date(deliveryAddress.at) : undefined,
    )
    const deliveryAt = set(addDays(collectionAt, transitInterval.days), setValue)

    onDeliveryAtSet(deliveryAt)
  }

  if (collectionBy) {
    const setValue = getTimeSetProps(
      transitInterval.end.getHours(),
      deliveryAddress.by ? new Date(deliveryAddress.by) : undefined,
    )

    const deliveryBy = set(addDays(collectionBy, transitInterval.days), setValue)

    onDeliveryBySet(deliveryBy)
  }
}

export const calculateBusinessTransitInterval = ({
  transitInterval,
  collectionAt,
  collectionBy,
  deliveryAddress,
  onDeliveryAtSet,
  onDeliveryBySet,
}: FillIntervalParams) => {
  if (collectionAt) {
    const setValue = getTimeSetProps(
      transitInterval.start.getHours(),
      deliveryAddress.at ? new Date(deliveryAddress.at) : undefined,
    )
    const deliveryAt = set(addBusinessDays(collectionAt, transitInterval.days), setValue)

    onDeliveryAtSet(deliveryAt)
  }

  if (collectionBy) {
    const setValue = getTimeSetProps(
      transitInterval.end.getHours(),
      deliveryAddress.by ? new Date(deliveryAddress.by) : undefined,
    )

    const deliveryBy = set(addBusinessDays(collectionBy, transitInterval.days), setValue)

    onDeliveryBySet(deliveryBy)
  }
}

export const getCutOffTimeInterval = (serviceType?: XeroServiceType): number => {
  switch (serviceType) {
    case XeroServiceType.DomesticParcels:
    case XeroServiceType.InternationalParcels:
    case XeroServiceType.DomesticPallets:
      return dateIsGreater(Date.now(), CutOffTimeDeadlines[serviceType]) ? 1 : 0

    case XeroServiceType.EuropeanGroupage:
    case XeroServiceType.InternationalAirFreight:
      return 1

    default:
      return 0
  }
}
