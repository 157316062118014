import { DriverInstruction } from 'components/bookings/BookingDetails/DriverDetails/AllocateDrivers/SelectDriverDetails/types'

import {
  SelectContactDialog,
  SelectInstructionDialog,
  SelectSubDriverDialog,
  SelectVehicleDialog,
} from '.'

interface ISelectDialogProps {
  selectedDrivers: {
    selectVehicle?: boolean
    selectSubDriver?: boolean
    selectContact?: boolean
    selectInstruction?: boolean
    focusedDriverId: string | null
  }
  onSelectVehicle: (selectedDriverId: number) => void
  onCloseVehicleDialog: () => void
  onSelectSubDriver: (selectedDriverId: number) => void
  onCloseSubDriverDialog: () => void
  onSelectContact: (selectedDriverId: number) => void
  onCloseDriverContactDialog: () => void
  customerId: string
  onSelectInstruction: (data: DriverInstruction) => void
  onCloseInstructionDialog: () => void
}

const SelectDialogComponent = (props: ISelectDialogProps) => {
  const {
    selectedDrivers,
    onSelectVehicle,
    onCloseVehicleDialog,
    onSelectSubDriver,
    onCloseSubDriverDialog,
    onSelectContact,
    onCloseDriverContactDialog,
    customerId,
    onSelectInstruction,
    onCloseInstructionDialog,
  } = props

  return (
    <>
      {selectedDrivers.selectVehicle && (
        <SelectVehicleDialog
          openDialog={selectedDrivers.selectVehicle}
          selectedDriverId={selectedDrivers.focusedDriverId}
          onSelectVehicle={onSelectVehicle}
          onCloseDialog={onCloseVehicleDialog}
        />
      )}
      {selectedDrivers.selectSubDriver && (
        <SelectSubDriverDialog
          openDialog={selectedDrivers.selectSubDriver}
          selectedDriverId={selectedDrivers.focusedDriverId}
          onSelectSubDriver={onSelectSubDriver}
          onCloseDialog={onCloseSubDriverDialog}
        />
      )}
      {selectedDrivers.selectContact && (
        <SelectContactDialog
          openDialog={selectedDrivers.selectContact}
          selectedDriverId={selectedDrivers.focusedDriverId}
          onSelectContact={onSelectContact}
          onCloseDialog={onCloseDriverContactDialog}
        />
      )}
      {selectedDrivers.selectInstruction && (
        <SelectInstructionDialog
          openDialog={selectedDrivers.selectInstruction}
          selectedDrivers={selectedDrivers}
          customerId={customerId}
          onSelectInstruction={onSelectInstruction}
          onCloseDialog={onCloseInstructionDialog}
        />
      )}
    </>
  )
}

export default SelectDialogComponent
