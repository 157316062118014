import { useFormContext } from 'react-hook-form'

import { ControlledDatePicker } from 'components/common'
import { CardTitle } from 'components/common/controls'

const InsuranceDetails = () => {
  const { control } = useFormContext()

  return (
    <>
      <CardTitle>Insurance Details</CardTitle>
      <ControlledDatePicker
        control={control}
        name='publicLiabilityExpirationDate'
        label='Public Liability Exp. Date'
      />
    </>
  )
}

export default InsuranceDetails
