import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { DEFAULT_COUNTRY_ID, Mode, Paths, Roles } from 'constants/index'
import { useCreateUserMutation, useUpdateUserMutation } from 'generated/graphql'
import { normalizeUserDto } from 'helpers'
import { ResetPassword } from 'services/IdentityApiClient'
import { TUserForm } from 'types'

import UpdateUser from './UpdateUser'
import UserForm from './UserForm'
import ValidationSchema from './ValidationSchema'

interface IProps {
  userId: number | null
  openDialog: boolean
  onCloseDialog: () => any
}

const CreateUpdateUser = (props: IProps) => {
  const { userId, openDialog, onCloseDialog } = props
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [resettingPassword, setResettingPassword] = useState(false)

  const history = useHistory()
  const mode = userId ? Mode.Update : Mode.Create

  const methods = useForm<TUserForm>({
    context: { mode },
    resolver: joiResolver(ValidationSchema),
    defaultValues: {
      email: '',
      username: '',
      firstName: '',
      lastName: '',
      city: '',
      phoneNumber: '',
      password: '',
      postcode: '',
      role: Roles.sales,
      countryId: DEFAULT_COUNTRY_ID,
      dob: new Date(),

      companyDetails: {
        companyName: '',
        customerId: null,
        contactId: null,
      },
    },
  })
  const {
    watch,
    getValues,
    handleSubmit,
    formState: { isDirty },
  } = methods

  const [driverIdValue, customerIdValue] = watch(['driverId', 'companyDetails.customerId'])

  // mutations
  const [createUser, { loading: creatingUser }] = useCreateUserMutation({
    onCompleted: (response) => {
      if (response.createApplicationUser) {
        toast.success('User was created with success')
        onCloseDialog()
      } else {
        toast.error('Something went wrong')
      }
    },
  })

  const [updateUser, { loading: updatingUser }] = useUpdateUserMutation({
    onCompleted: (response) => {
      if (response.updateApplicationUser.id) {
        toast.success('User was updated with success')
        onCloseDialog()
      } else {
        toast.error('Something went wrong')
      }
    },
  })

  // handlers
  const handleResetPassword = () => {
    const { email } = getValues()
    setResettingPassword(true)
    ResetPassword(email)
      .then(() => toast.success('Email with reset password request was sent.'))
      .catch(() => toast.error('Something went wrong.'))
      .finally(() => setResettingPassword(false))
  }

  const handleOpenDriver = () => {
    history.push(Paths.drivers.updateWithId(Number(driverIdValue)))
  }
  const handleOpenCustomer = () => {
    history.push(Paths.customers.updateWithId(Number(customerIdValue)))
  }

  const handleSubmitForm: SubmitHandler<TUserForm> = (formData) => {
    const normalizedProps = normalizeUserDto(formData)

    if (mode === Mode.Create) {
      createUser({
        variables: {
          input: {
            ...normalizedProps,
          },
        },
      })
    } else {
      updateUser({
        variables: {
          input: {
            id: String(userId),
            ...normalizedProps,
          },
        },
      })
    }
  }

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const handleOnClose = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openDialog}
      fullScreen={isFullScreen}
      aria-labelledby='form-user-dialog-title'>
      <FplDialogTitle
        id='form-user-dialog-title'
        onClose={handleOnClose}
        onFullScreen={handleFullScreen}>
        <Typography variant='h4' component='p'>
          User Details
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? (
            <UserForm mode={Mode.Create} />
          ) : (
            <UpdateUser userId={Number(userId)} />
          )}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleOnClose}>
          Cancel
        </Button>

        {driverIdValue && (
          <Button onClick={handleOpenDriver} variant='contained' color='grey'>
            Driver Details
          </Button>
        )}

        {customerIdValue && (
          <Button onClick={handleOpenCustomer} variant='contained' color='grey'>
            Customer Details
          </Button>
        )}

        {mode === Mode.Update && (
          <LoadingButton
            variant='contained'
            loading={resettingPassword}
            onClick={handleResetPassword}>
            Reset password
          </LoadingButton>
        )}

        <LoadingButton
          variant='contained'
          loading={creatingUser || updatingUser}
          disabled={!isDirty}
          onClick={handleSubmit(handleSubmitForm)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateUser
