import { useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { Tariff, useGetBookingLazyQuery, useGetBookingTariffLazyQuery } from 'generated/graphql'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import { emitter } from 'providers'
import { TBookingForm } from 'types/form-types'

import InternalTariffForm from './InternalTariffForm'
import SelectInternalTariffDialog from './SelectInternalTariffDialog'

interface IProps {
  bookingId: string | undefined
  disabled?: boolean
}

const InternalTariff = (props: IProps) => {
  const { bookingId, disabled } = props
  const [selectTariffDialogParams, setSelectTariffDialogParams] = useState<{
    openDialog: boolean
    customerId: number | null
  }>({
    openDialog: false,
    customerId: null,
  })

  const { getValues, setValue } = useFormContext<TBookingForm>()

  const [getAdminBooking, { data: adminBookingData }] = useGetBookingLazyQuery()

  const [
    getTariffDetails,
    {
      data: tariffDetailsData,
      previousData: tariffDetailsPerviousData,
      loading: tariffDetailsLoading,
    },
  ] = useGetBookingTariffLazyQuery({
    notifyOnNetworkStatusChange: true,
  })

  const tariffDetails = tariffDetailsData?.tariffById?.id
    ? tariffDetailsData
    : tariffDetailsPerviousData

  useEffect(() => {
    if (bookingId) {
      getAdminBooking({
        variables: { bookingId: Number(bookingId) },
      })
    }
  }, [bookingId, getAdminBooking])

  const bookingTariffId = Number(adminBookingData?.booking?.tariffId ?? getValues('tariffId'))
  useEffect(() => {
    if (bookingTariffId) {
      getTariffDetails({ variables: { id: bookingTariffId } })
    }
  }, [getTariffDetails, bookingTariffId])

  useEffect(() => {
    isPageLoadingVar(tariffDetailsLoading)
  }, [tariffDetailsLoading])

  const handleSelectTariffClick = () => {
    const customerIdValue = getValues('customerId')

    setSelectTariffDialogParams({
      openDialog: true,
      customerId: customerIdValue ? Number(customerIdValue) : null,
    })
  }

  const handleSelectTariff = (id: number) => {
    getTariffDetails({ variables: { id } })
    setValue('tariffId', String(id), { shouldDirty: true, shouldValidate: true })

    setSelectTariffDialogParams({
      openDialog: false,
      customerId: null,
    })

    emitter.emit('add-drop-charge')
  }

  const handleCloseDialog = () => {
    setSelectTariffDialogParams({
      openDialog: false,
      customerId: null,
    })
  }

  return (
    <>
      <InternalTariffForm
        tariffDetails={tariffDetails?.tariffById as Tariff}
        disabled={disabled}
        onSelectTariffClick={handleSelectTariffClick}
      />

      {selectTariffDialogParams.openDialog && (
        <SelectInternalTariffDialog
          open={selectTariffDialogParams.openDialog}
          onClose={handleCloseDialog}
          customerId={selectTariffDialogParams.customerId}
          onSelectTariff={handleSelectTariff}
        />
      )}
    </>
  )
}

export default InternalTariff
