import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material'

import { FplDialogTitle } from 'components/common'

interface DriverNoteDialogProps {
  note: string
  onClose: () => void
  open: boolean
}

const DriverNoteDialog = (props: DriverNoteDialogProps) => {
  const { note, onClose, open } = props

  return (
    <Dialog maxWidth='xs' open={open} aria-labelledby='driver-note-dialog-title' fullWidth>
      <FplDialogTitle onClose={onClose} id='driver-note-dialog-title'>
        <Typography mb={0} paragraph variant='h4'>
          Driver note
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <DialogContentText>{note}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DriverNoteDialog
