import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { Box, Button, Grid } from '@mui/material'
import { toast } from 'react-toastify'

import { CardTitle } from 'components/common'
import {
  GetNotificationsDocument,
  SortEnumType,
  TaskStatus,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from 'generated/graphql'
import { GET_PAGED_NOTIFICATIONS, GET_TASKS_QUERY } from 'graphql/queries'
import { TaskDialogOptions, useDialog } from 'providers'
import { Restricted } from 'providers/PermissionProvider'

import TaskList from './TaskList'

interface IProps {
  bookingId: string | null
}

const Tasks = (props: IProps) => {
  const { bookingId } = props

  const { dialogOpen } = useDialog<TaskDialogOptions>()
  const { idTokenPayload } = useOidcIdToken()

  // mutations
  const [updateTask] = useUpdateTaskMutation({
    onCompleted: (response) => {
      toast.success(`Task (${response.updateTask?.id}) was marked as Completed.`)
    },
    update(cache, { data }) {
      if (data?.updateTask) {
        cache.updateQuery(
          {
            query: GET_PAGED_NOTIFICATIONS,
            variables: {
              where: {
                username: { eq: idTokenPayload.name },
                isRead: { eq: false },
              },
              order: {
                createdAt: SortEnumType.Desc,
              },
              first: 10,
            },
          },
          (queryData) => {
            const taskId = Number(data.updateTask?.id)
            const edges = queryData.notifications.edges.filter((e) => e.node.taskId !== taskId)
            const totalCount =
              queryData.notifications.totalCount -
              queryData.notifications.edges.filter((e) => Number(e.node.taskId) === taskId).length

            return {
              notifications: { ...queryData.notifications, edges, totalCount },
            }
          },
        )
      }
    },
  })

  const [deleteTask] = useDeleteTaskMutation({
    onCompleted: () => toast.success('Task was deleted with success.'),
    // update function for cache don't work :(
    refetchQueries: [
      {
        query: GetNotificationsDocument,
        variables: {
          where: {
            username: { eq: idTokenPayload.name },
            isRead: { eq: false },
          },
          order: {
            createdAt: SortEnumType.Desc,
          },
          first: 10,
        },
      },
    ],
  })

  const handleAddTask = () => {
    dialogOpen({ id: null, bookingId })
  }

  const handleTaskClick = (id) => {
    dialogOpen({ id: Number(id) })
  }

  const handleCheckboxChange = (value: boolean, task) => {
    if (value) {
      updateTask({
        variables: {
          input: {
            id: Number(task.id),
            bookingId: task.bookingId,
            description: task.description,
            assignedUserName: task.assignedUserName,
            endDate: task.endDate,
            status: TaskStatus.Completed,
          },
        },
      })
    }
  }

  const handleDeleteClick = (id) => {
    deleteTask({
      variables: { id: Number(id) },
      update(cache) {
        if (bookingId) {
          cache.updateQuery(
            {
              query: GET_TASKS_QUERY,
              variables: { where: { bookingId: { eq: Number(bookingId) } } },
            },
            (queryData) => {
              const edges = queryData.tasks.edges.filter((taskEdge) => id !== taskEdge.node.id)
              const totalCount = queryData.tasks.totalCount - 1

              return {
                tasks: { ...queryData.tasks, edges, totalCount },
              }
            },
          )
        }

        cache.updateQuery(
          {
            query: GET_TASKS_QUERY,
            variables: { where: { assignedUserName: { eq: idTokenPayload.name } } },
          },
          (queryData) => {
            const edges = queryData.tasks.edges.filter((taskEdge) => id !== taskEdge.node.id)
            const totalCount = queryData.tasks.totalCount - 1

            return {
              tasks: { ...queryData.tasks, edges, totalCount },
            }
          },
        )
      },
    })
  }

  return (
    <>
      <Box paddingX={2} paddingTop={2}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <CardTitle>Tasks</CardTitle>
          </Grid>
          <Restricted to='CreateTask'>
            <Grid item>
              <Button variant='outlined' onClick={handleAddTask}>
                Add Task
              </Button>
            </Grid>
          </Restricted>
        </Grid>
      </Box>

      <TaskList
        bookingId={bookingId}
        onTaskClick={handleTaskClick}
        onCheckboxChange={handleCheckboxChange}
        onDeleteClick={handleDeleteClick}
      />
    </>
  )
}

export default Tasks
