import { gql } from '@apollo/client'

import { FileDetailsFragment, TariffDetailsFragment } from 'graphql/fragments'

export const CREATE_TARIFF = gql`
  mutation CreateTariff($input: CreateAndUpdateTariffDtoInput!) {
    createTariff(input: $input) {
      ...TariffDetails
    }
    ${TariffDetailsFragment}
  }
`

export const UPDATE_TARIFF = gql`
  mutation UpdateTariff($input: CreateAndUpdateTariffDtoInput!) {
    updateTariff(input: $input) {
      ...TariffDetails
    }
    ${TariffDetailsFragment}
  }
`

export const DELETE_TARIFF = gql`
  mutation DeleteTariff($id: Long!) {
    deleteTariff(id: $id)
  }
`

export const CREATE_TARIFF_CATEGORY_MUTATION = gql`
  mutation CreateTariffCategory($input: CreateAndUpdateTariffCategoryDtoInput!) {
    createTariffCategory(input: $input) {
      id
      name
      courierExchangeVehicleSize
      hasSpotRate
    }
  }
`

export const UPDATE_TARIFF_CATEGORY_MUTATION = gql`
  mutation UpdateTariffCategory($input: CreateAndUpdateTariffCategoryDtoInput!) {
    updateTariffCategory(input: $input) {
      id
      name
      courierExchangeVehicleSize
      hasSpotRate
    }
  }
`

export const CREATE_TARIFF_ICON = gql`
  mutation CreateTariffIcon($input: CreateAndUpdateTariffIconDtoInput!) {
    createTariffIcon(input: $input) {
      id
      name
      vehicleCapacity
      fileId
      file {
        id
        ...FileDetails
      }
    }

    ${FileDetailsFragment}
  }
`

export const UPDATE_TARIFF_ICON = gql`
  mutation UpdateTariffIcon($input: CreateAndUpdateTariffIconDtoInput!) {
    updateTariffIcon(input: $input) {
      id
      name
      vehicleCapacity
      fileId
      file {
        id
        ...FileDetails
      }
    }

    ${FileDetailsFragment}
  }
`

export const DELETE_TARIFF_ICON = gql`
  mutation DeleteTariffIcon($id: Long!) {
    deleteTariffIcon(id: $id)
  }
`
