import { useQuery, useMutation } from '@apollo/client'
import { Brightness4, Brightness7 } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import PropTypes from 'prop-types'

import { TOGGLE_THEME } from '../../graphql/mutations'
import { GET_THEME_IS_LIGHT } from '../../graphql/queries'
import HelperMenuItem from './HelperMenuItem'

const label = 'Change Theme'

const ButtonChangeTheme = ({ isMenu = false }) => {
  const {
    data: { isThemeLight },
  } = useQuery(GET_THEME_IS_LIGHT)
  const [toggleTheme] = useMutation(TOGGLE_THEME)

  const handleOnClick = () => {
    toggleTheme()
  }

  const content = (
    <IconButton
      color='inherit'
      onClick={isMenu ? undefined : handleOnClick}
      aria-label={label}
      size='large'>
      {isThemeLight ? <Brightness4 /> : <Brightness7 />}
    </IconButton>
  )

  const attrs = {
    content,
    handleOnClick,
    label,
    isMenu,
  }

  return <HelperMenuItem {...attrs} />
}

ButtonChangeTheme.propTypes = {
  isMenu: PropTypes.bool,
}

ButtonChangeTheme.defaultProps = {
  isMenu: false,
}

export default ButtonChangeTheme
