import React from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'

import CongestionZones from './CongestionZones'
import OtherCharges from './OtherCharges'

enum TabId {
  OtherCharges = 0,
  CongestionZones,
}

function commonProps(index: TabId) {
  return {
    id: `setting-surcharges-tab-${index}`,
    'aria-controls': `setting-surcharges-tabpanel-${index}`,
  }
}

const SurchargesTab = () => {
  const [tabValue, setTabValue] = React.useState(0)

  const tabs = {
    otherCharges: { id: TabId.OtherCharges, label: 'Other Charges' },
    congestionZones: { id: TabId.CongestionZones, label: 'Special Zones' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='settings surcharges tabs'
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
          width: (theme) => theme.spacing(27),
        }}>
        <Tab
          label={tabs.otherCharges.label}
          key={tabs.otherCharges.id}
          {...commonProps(tabs.otherCharges.id)}
        />
        <Tab
          label={tabs.congestionZones.label}
          key={tabs.congestionZones.id}
          {...commonProps(tabs.congestionZones.id)}
        />
      </Tabs>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.otherCharges.id}
        key={tabs.otherCharges.id}
        px={3}>
        <OtherCharges />
      </VerticalTabPanel>
      <VerticalTabPanel
        value={tabValue}
        index={tabs.congestionZones.id}
        key={tabs.congestionZones.id}
        px={3}>
        <CongestionZones />
      </VerticalTabPanel>
    </Box>
  )
}

export default SurchargesTab
