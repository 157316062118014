import { AutocompleteFreeSoloValueMapping, TextField, Tooltip } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Controller } from 'react-hook-form'

export interface IMultipleAutocompleteOption {
  value: any
  label: string
}

export type IMultipleAutocompleteOptions = Array<IMultipleAutocompleteOption>

interface IProps {
  control: any
  label: string
  name: string
  options: IMultipleAutocompleteOptions
  defaultValue?: IMultipleAutocompleteOptions
  limitTags?: number
  required?: boolean
  disabled?: boolean
  placeholder?: string
  error?: boolean
  helperText?: any
  tooltipText?: string
  getOptionKey?: (option: IMultipleAutocompleteOption) => string | number
}

// ToDo: refactor
const ControlledMultipleAutocomplete = (props: IProps) => {
  const {
    control,
    label,
    name,
    options,
    defaultValue,
    limitTags,
    required,
    disabled,
    placeholder,
    error,
    helperText,
    tooltipText,
    getOptionKey,
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...restField } }) => (
        <Autocomplete
          {...restField}
          getOptionKey={getOptionKey}
          onChange={(e, data) => onChange(data)}
          multiple
          options={options}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, optionValue) => option.value === optionValue.value}
          limitTags={limitTags}
          disabled={disabled}
          filterSelectedOptions
          renderInput={(params) => (
            <Tooltip title={tooltipText ?? ''} placement='top'>
              <TextField
                {...params}
                variant='outlined'
                label={label}
                required={required}
                placeholder={placeholder}
                error={error}
                helperText={helperText}
              />
            </Tooltip>
          )}
        />
      )}
    />
  )
}

export { ControlledMultipleAutocomplete }
