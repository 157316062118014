import { useMemo, useState } from 'react'

import { Button, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Paths } from 'constants/Paths'
import { BookingStatus, GetBookingQuery } from 'generated/graphql'
import { Restricted } from 'providers/PermissionProvider'

import BookingExchangeRates from '../BookingExchangeRates'

interface IProps {
  booking: GetBookingQuery['booking']
}

const AdditionalButtons = (props: IProps) => {
  const { booking } = props
  const [openExchangeDialog, setOpenExchangeDialog] = useState<boolean>(false)

  const history = useHistory()

  const isViewInvoiceDisable = useMemo(() => booking?.status !== BookingStatus.Invoice, [booking])

  const handleViewExchangeRates = () => {
    if (!booking?.id) {
      toast.error('Invalid Booking Id.')
      return
    }

    setOpenExchangeDialog(true)
  }

  const handleViewCustomerInvoices = () => {
    if (booking) {
      history.push(`${Paths.customers.updateWithId(booking.customerId)}#invoices`)
    }
  }

  const handleViewDriverInvoices = () => {
    if (booking) {
      const driverId = booking.drivers ? booking.drivers[0].driverId : 0
      history.push(`${Paths.drivers.updateWithId(driverId)}#invoices`)
    }
  }

  const handleCloseExchangeRates = () => setOpenExchangeDialog(false)

  return (
    <>
      <Grid container justifyContent={{ xs: 'end', sm: 'start' }} spacing={1.5}>
        <Restricted to='ViewBookingExchangeRates'>
          <Grid item>
            <Button variant='contained' color='grey' onClick={handleViewExchangeRates}>
              Exchange Rates
            </Button>
          </Grid>
        </Restricted>

        <Restricted to='ViewBookingInvoice'>
          <Grid item>
            <Button
              variant='contained'
              color='grey'
              disabled={isViewInvoiceDisable}
              onClick={handleViewCustomerInvoices}>
              Customer Inv.
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant='contained'
              color='grey'
              disabled={isViewInvoiceDisable}
              onClick={handleViewDriverInvoices}>
              Driver Inv.
            </Button>
          </Grid>
        </Restricted>
      </Grid>

      {openExchangeDialog && booking && (
        <BookingExchangeRates
          bookingId={Number(booking.id)}
          open={openExchangeDialog}
          handleCloseDialog={handleCloseExchangeRates}
        />
      )}
    </>
  )
}

export default AdditionalButtons
