import { FormControl, FormLabel, Radio, FormControlLabel, RadioGroup } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { LegalAccountType } from 'generated/graphql'

interface IProps {
  control: Control<any, any>
  required?: boolean
}

const LegalAccountTypeControl = (props: IProps) => {
  const { control, required } = props

  return (
    <Controller
      control={control}
      name='legalAccountType'
      render={({ field }) => (
        <FormControl component='fieldset' required={required}>
          <FormLabel component='label'>Legal Account Type</FormLabel>
          <RadioGroup {...field} row aria-label='legal-account-type'>
            <FormControlLabel
              value={LegalAccountType.Business}
              control={<Radio />}
              label='Business'
            />
            <FormControlLabel
              value={LegalAccountType.Personal}
              control={<Radio />}
              label='Personal'
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}

export default LegalAccountTypeControl
