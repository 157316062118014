import { Grid, Paper, Box } from '@mui/material'

import { CardTitle } from 'components/common/controls'
import { CreateAndUpdateTariffDtoInput } from 'generated/graphql'

import { Details } from './Details'
import DropChargeDetails from './DropChargeDetails'
import ExternalTariffDetails from './ExternalTariffDetails'
import { WaitingTimeCharge } from './WaitingTimeCharge'

interface IProps {
  tariff?: CreateAndUpdateTariffDtoInput
}

const TariffForm = (props: IProps) => {
  const { tariff } = props

  return (
    <Grid container columnSpacing={3} rowSpacing={2}>
      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box padding={2}>
            <CardTitle>Tariff Details</CardTitle>
            <Details tariff={tariff} />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper variant='outlined'>
          <Box padding={2}>
            <CardTitle>Waiting Time Charge Details</CardTitle>
            <WaitingTimeCharge />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper variant='outlined'>
          <Box padding={2}>
            <CardTitle>Drop Charge Details</CardTitle>
            <DropChargeDetails />
          </Box>
        </Paper>

        <Box pt={2}>
          <Paper variant='outlined'>
            <Box padding={2}>
              <CardTitle>External Tariff Details</CardTitle>
              <ExternalTariffDetails />
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TariffForm
