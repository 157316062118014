import { CardTitle } from 'components/common'

import { ImportDataForm, ImportDataTypes } from './common'

const ImportDriversCode = () => {
  return (
    <>
      <CardTitle>Import Drivers Code</CardTitle>
      <ImportDataForm type={ImportDataTypes.DriversCode} />
    </>
  )
}

export default ImportDriversCode
