import { Grid } from '@mui/material'

import { PaymentDetails } from 'components/suppliers/drivers/DriverDetails/PaymentDetails'

import SubDriverDetails from './SubDriverDetails'

const SubDriverForm = () => {
  // ToDo: add <Paper variant='outlined'> to each section */
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SubDriverDetails />
      </Grid>

      <Grid item xs={12}>
        <PaymentDetails />
      </Grid>
    </Grid>
  )
}

export default SubDriverForm
