import { gql } from '@apollo/client'

export const CREATE_PAYMENT_MUTATION = gql`
  mutation CreatePayment($accountPayableId: ID!) {
    createPayment(input: { accountPayableId: $accountPayableId }) {
      id
      status
    }
  }
`

export const CREATE_SMART_APPROVAL_PAYMENT_MUTATION = gql`
  mutation CreateSmartApprovalPayment($accountPayableIds: [Long!]) {
    createSmartApprovalPayment(input: { accountPayableIds: $accountPayableIds }) {
      id
      status
    }
  }
`
export const UPDATE_FAILED_PAYMENT_STATUS_MUTATION = gql`
  mutation updateFailedPaymentStatus($accountPayableIds: [Long!]) {
    updateFailedPaymentStatus(inputs: { accountPayableIds: $accountPayableIds })
  }
`
