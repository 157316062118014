import { Box, Container } from '@mui/material'
import { Link } from 'react-router-dom'

const AuthHeader = () => {
  return (
    <Box>
      <Container maxWidth='lg'>
        <Box paddingTop={2} sx={{ display: 'flex' }}>
          <Link to='/' style={{ display: 'flex' }}>
            <img height={57} src={'/logo.png'} alt='FirstPoint Logistics logo' />
          </Link>
        </Box>
      </Container>

      <hr color='#e6e6e6' style={{ margin: 0 }} />
    </Box>
  )
}

export default AuthHeader
