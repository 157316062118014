import { useEffect, useMemo } from 'react'

import { Box, Grid, Paper } from '@mui/material'
import { Skeleton } from '@mui/material'
import { endOfYesterday } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import {
  CardTitle,
  ControlledDatePicker,
  ControlledSelector,
  ISelectorOptions,
} from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { useGetCurrenciesQuery } from 'generated/graphql'

interface IProps {
  onFilterChange: (criteria: {
    sourceCurrencyId: number | null
    targetCurrencyId: number | null
    startDate: Date
    endDate: Date
  }) => void
}

const ExchangeRatesFilter = (props: IProps) => {
  const { onFilterChange } = props

  const { control, watch } = useForm({
    shouldUnregister: true,
    defaultValues: {
      sourceCurrencyId: '',
      targetCurrencyId: '',
      startDate: endOfYesterday(),
      endDate: new Date(),
    },
  })

  const [{ startDate, endDate, sourceCurrencyId, targetCurrencyId }] = useDebounce(
    watch(),
    DEBOUNCE_DELAY,
  )

  // operations
  const { loading: currenciesLoading, data: currenciesData } = useGetCurrenciesQuery()

  useEffect(() => {
    onFilterChange({
      startDate: startDate,
      endDate: endDate,
      sourceCurrencyId: sourceCurrencyId?.length > 0 ? Number(sourceCurrencyId) : null,
      targetCurrencyId: targetCurrencyId?.length > 0 ? Number(targetCurrencyId) : null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, sourceCurrencyId, targetCurrencyId])

  const currencies: ISelectorOptions = useMemo(() => {
    if (!currenciesLoading && currenciesData) {
      return currenciesData.currencies.map((c) => ({ value: c.id, label: c.code }))
    } else {
      return []
    }
  }, [currenciesLoading, currenciesData])

  return (
    <Paper variant='outlined'>
      <Box p={2}>
        <CardTitle>Filter criteria</CardTitle>
        <Grid container rowSpacing={1.5} columnSpacing={3}>
          <Grid item sm={3}>
            {currenciesLoading ? (
              <Skeleton height={22} width={50} />
            ) : (
              <ControlledSelector
                control={control}
                name='sourceCurrencyId'
                label='Source Currency'
                options={currencies}
                emptyValue={{ value: '', label: 'All' }}
                displayEmpty
              />
            )}
          </Grid>

          <Grid item sm={3}>
            {currenciesLoading ? (
              <Skeleton height={22} width={50} />
            ) : (
              <ControlledSelector
                control={control}
                name='targetCurrencyId'
                label='Targe Currency'
                options={currencies}
                emptyValue={{ value: '', label: 'All' }}
                displayEmpty
              />
            )}
          </Grid>

          <Grid item sm={3}>
            <ControlledDatePicker control={control} name='startDate' label='Start Date' />
          </Grid>

          <Grid item sm={3}>
            <ControlledDatePicker control={control} name='endDate' label='End Date' />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default ExchangeRatesFilter
