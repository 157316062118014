import {
  AlternateEmail,
  ContentCopy,
  Email,
  Error,
  GetApp,
  VisibilityOff,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { saveAs } from 'file-saver'

import { FplDialogTitle } from 'components/common/Dialog'
import { useGetSentEmailQuery } from 'generated/graphql'
import SanitizeHTML from 'helpers/sanitizeHTML'

interface IProps {
  selectedSentEmailId: number | null
  openDialog: boolean
  handleCloseDialog: () => any
}

const ViewSentEmail = (props: IProps) => {
  const { selectedSentEmailId, openDialog, handleCloseDialog } = props

  const { data, loading } = useGetSentEmailQuery({
    variables: {
      sentEmailId: selectedSentEmailId,
    },
  })

  const handleClose = () => {
    handleCloseDialog()
  }

  const handleBodyDownload = () => {
    const blob = new Blob([data?.sentEmail?.body ?? ''], { type: 'text/html;charset=utf-8' })
    saveAs(blob, `email-${data?.sentEmail?.id ?? 0}-body.html`)
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby='form-contact-dialog-title'>
      <FplDialogTitle id='form-contact-dialog-title' onClose={handleClose}>
        <Typography paragraph variant='h4'>
          Details about email ID {data?.sentEmail?.id}
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        {loading ? (
          <Box component='div' height={200} width='100%' display='flex' justifyContent='center'>
            <CircularProgress size={150} style={{ marginTop: 10 }} variant='indeterminate' />
          </Box>
        ) : (
          <>
            {data?.sentEmail?.hasErrors && (
              <>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Error />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={data?.sentEmail?.errorMessages}
                    secondary='Error(s)'
                    primaryTypographyProps={{ color: 'error' }}
                  />
                </ListItem>
                <Divider />
              </>
            )}

            <List component='nav' aria-label='secondary mailbox folders'>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AlternateEmail />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={data?.sentEmail?.to} secondary='To' />
              </ListItem>
              <Divider />

              {data?.sentEmail?.cc && (
                <>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ContentCopy />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={data?.sentEmail?.cc} secondary='CC' />
                  </ListItem>
                  <Divider />
                </>
              )}

              {data?.sentEmail?.bcc && (
                <>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <VisibilityOff />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={data?.sentEmail?.bcc} secondary='BCC' />
                  </ListItem>
                  <Divider />
                </>
              )}

              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={data?.sentEmail?.subject} secondary='Subject' />
              </ListItem>
              <Divider />
            </List>

            <ListItem button onClick={handleBodyDownload}>
              <ListItemAvatar>
                <Avatar>
                  <GetApp />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Body preview'
                secondary='Styling is disabled for security. Click to download full email body.'
              />
            </ListItem>
            <Divider />

            <Box mt={2}>
              <SanitizeHTML html={data?.sentEmail?.body ?? ''} />
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewSentEmail
