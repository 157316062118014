import { useReactiveVar } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { SplitButton } from 'components/common'
import { Paths } from 'constants/Paths'
import { isBookingProcessingVar, isPageLoadingVar } from 'graphql/reactiveVariables'
import { Restricted } from 'providers/PermissionProvider'
import { TBookingForm } from 'types/form-types'

enum ButtonsId {
  Save = 0,
  SaveAndSendEmail,
}

const ButtonList = [
  { id: ButtonsId.Save, label: 'Save' },
  { id: ButtonsId.SaveAndSendEmail, label: 'Save & Send Email' },
]

interface IProps {
  disabled?: boolean
}

const QuoteSaveButtons = (props: IProps) => {
  const { disabled } = props

  const isPageLoading = useReactiveVar(isPageLoadingVar)
  const isBookingProcessing = useReactiveVar(isBookingProcessingVar)
  const history = useHistory()

  const {
    setValue,
    formState: { isDirty },
  } = useFormContext<TBookingForm>()

  const handleSave = () => {
    const form = document.forms['booking-details-form']
    const submitEvent = new SubmitEvent('submit', { bubbles: true, cancelable: true })
    form?.dispatchEvent(submitEvent)
  }

  const handleSaveAndSendEmail = () => {
    setValue('sendQuoteEmail', true)

    const form = document.forms['booking-details-form']
    const submitEvent = new SubmitEvent('submit', { bubbles: true, cancelable: true })
    form?.dispatchEvent(submitEvent)
  }

  const handleSaveClick = (buttonId: ButtonsId) => {
    switch (buttonId) {
      case ButtonsId.Save:
        handleSave()
        break

      case ButtonsId.SaveAndSendEmail:
        handleSaveAndSendEmail()
        break
    }
  }

  const handleBackClick = () => {
    history.push(Paths.bookings.all)
  }

  return (
    <Grid container spacing={1.5} justifyContent='end' wrap='wrap-reverse'>
      <Grid item>
        <Button variant='contained' color='grey' onClick={handleBackClick}>
          Back
        </Button>
      </Grid>

      <Restricted
        to='ViewQuoteSaveButtonsAsSplitButtons'
        fallback={
          <>
            <Grid item>
              <LoadingButton
                variant='contained'
                disabled={!isDirty || disabled || isPageLoading}
                loading={isBookingProcessing}
                onClick={handleSave}>
                Save
              </LoadingButton>
            </Grid>

            <Grid item>
              <LoadingButton
                variant='contained'
                disabled={!isDirty || disabled || isPageLoading}
                loading={isBookingProcessing}
                onClick={handleSaveAndSendEmail}>
                Save &amp; Send Quote
              </LoadingButton>
            </Grid>
          </>
        }>
        <Grid item>
          <SplitButton
            options={ButtonList}
            loading={isBookingProcessing}
            disabled={!isDirty || disabled || isPageLoading}
            onButtonClick={handleSaveClick}
          />
        </Grid>
      </Restricted>
    </Grid>
  )
}

export default QuoteSaveButtons
