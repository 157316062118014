import { useState } from 'react'

import { Add } from '@mui/icons-material'
import { AppBar, Box, IconButton, Tabs } from '@mui/material'

import { ConfirmDialog } from '../Dialog'
import FplDynamicTab from './FplDynamicTab'

const elevation3 = `
  0px 3px 3px -2px rgba(0,0,0,0.2),
  0px 3px 4px 0px rgba(0,0,0,0.14),
  0px 1px 8px 0px rgba(0,0,0,0.12)
`

interface IProps {
  tabValue: any
  fields: any
  name: string
  label: string
  disabled: any
  showAddButton?: boolean
  showRemoveButton?: boolean
  errorTabs?: Array<number> | undefined
  onTabChange: any
  onAddClick: any
  onRemoveClick: any
}

interface IDeletionState {
  confirmDialog: boolean
  e: any
  index: number
  itemId: number
}

const FplDynamicTabs = (props: IProps) => {
  const {
    tabValue,
    fields,
    name,
    label,
    disabled,
    showAddButton = true,
    showRemoveButton,
    errorTabs,
    onTabChange,
    onAddClick,
    onRemoveClick,
  } = props

  const [confirmDeletionPopup, setConfirmDeletionPopup] = useState<IDeletionState>({
    confirmDialog: false,
    e: null,
    index: -1,
    itemId: -1,
  })

  function handleDelete() {
    const { e, index, itemId } = confirmDeletionPopup
    onRemoveClick(e, index, itemId)
  }

  const handleCloseClick = (e, index, itemId) => {
    setConfirmDeletionPopup({ confirmDialog: true, e, index, itemId })
  }

  return (
    <AppBar
      sx={{ flexDirection: 'row', boxShadow: { xs: 'none', sm: elevation3 } }}
      position='static'
      color='default'>
      <Tabs value={tabValue} onChange={onTabChange} variant='scrollable'>
        {fields?.map((item, index) => (
          <FplDynamicTab
            key={item.tabsKey}
            value={index}
            label={`${label} ${index + 1}`}
            name={name}
            closeIconDisabled={disabled || fields?.length === 1}
            showRemoveButton={showRemoveButton}
            errorBadge={errorTabs?.includes(index)}
            onCloseClick={(e) => {
              handleCloseClick(e, index, Number(item.id))
            }}
          />
        ))}
      </Tabs>
      {showAddButton && (
        <Box display='flex' alignItems='center'>
          <IconButton
            color='secondary'
            size='large'
            aria-label='add-tab'
            disabled={disabled}
            onClick={onAddClick}>
            <Add />
          </IconButton>
        </Box>
      )}
      <ConfirmDialog
        open={confirmDeletionPopup.confirmDialog}
        onConfirm={handleDelete}
        setOpen={setConfirmDeletionPopup}
        title='Confirmation'
        message={'Are you sure you want to delete this?'}
        option1='No'
        option2='Yes'
      />
    </AppBar>
  )
}

export default FplDynamicTabs
