import { IGridColumn } from 'components/common'

export function useGetInvoiceBatchLinesColummns() {
  const columns: IGridColumn[] = [
    {
      field: 'id',
      headerName: 'Batch line (ID)',
      flex: 0.2,
    },
    {
      field: 'accountCode',
      headerName: 'Account Code',
      flex: 0.2,
    },
    {
      field: 'taxType',
      headerName: 'Tax Type',
      flex: 0.2,
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 0.2,
    },
  ]

  return columns
}
