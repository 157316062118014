import { useState, useMemo, useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import {
  DriverContactFilterInput,
  SortEnumType,
  useDeleteDriverContactMutation,
} from 'generated/graphql'
import { GET_DRIVER_CONTACTS } from 'graphql/queries'

import { CreateUpdateContact } from './CreateUpdateContact'

interface IProps {
  driverId: number
}

const Contacts = (props: IProps) => {
  const { driverId } = props
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [selectedContactId, setSelectedContactId] = useState<number | null>(null)

  const { control, watch, setValue } = useForm({
    defaultValues: { search: '' },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  const [deleteDriverContact, { loading: deletingDriverContact }] = useDeleteDriverContactMutation({
    refetchQueries: [
      {
        query: GET_DRIVER_CONTACTS,
        variables: {
          driverId,
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { name: SortEnumType.Asc },
        },
      },
    ],
  })

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedContactId(Number(id))
    setOpenContactDialog(true)
  }

  const handleAddContact = () => {
    setSelectedContactId(null)
    setOpenContactDialog(true)
  }

  // ToDo: add confirmation dialog
  const handleDeleteDriverContact = useCallback(
    (id: GridRowId) => () => {
      deleteDriverContact({
        variables: {
          driverContactId: Number(id),
        },
      }).then((response) => {
        if (response.data) {
          toast.success(`Contact ${id} was deleted with success!`)
        }
      })
    },
    [deleteDriverContact],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleCloseContactDialog = () => {
    setOpenContactDialog(false)
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'department', headerName: 'Department', minWidth: 200, flex: 1 },
      { field: 'telephoneNumber', headerName: 'Mobile No', minWidth: 200, flex: 1 },
      { field: 'email', headerName: 'Email', minWidth: 200, flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deletingDriverContact}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteDriverContact(id)}
          />,
        ],
      },
    ],
    [deletingDriverContact, handleDeleteDriverContact],
  )

  const filter: DriverContactFilterInput | null = useMemo(() => {
    return {
      or: [{ name: { contains: searchValue } }],
    }
  }, [searchValue])

  return (
    <>
      <FplDataGrid
        query={GET_DRIVER_CONTACTS}
        queryVariables={{ driverId }}
        skipQuery={!driverId}
        entityName='driverContacts'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'name', sort: 'asc' }}
        toolbar={{
          caption: 'Contacts',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddContact}>
                New Contact
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openContactDialog && (
        <CreateUpdateContact
          contactId={selectedContactId}
          driverId={driverId}
          openDialog={openContactDialog}
          handleCloseDialog={handleCloseContactDialog}
        />
      )}
    </>
  )
}

export default Contacts
