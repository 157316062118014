import { Box, Container, Grid, Typography, styled, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Paths } from 'constants/Paths'

import PrimaryButton from './PrimaryButton'

const ContactUsButton = styled(Button)({
  textTransform: 'none',
  boxShadow: 'none',
  backgroundColor: '#333333',
  fontFamily: 'Goldplay-Medium, sans-serif',
  fontSize: '15px',
  padding: '8px 16px',
  borderRadius: '25px',
  color: 'white',
  borderColor: '#a9a9a9',
  '&:hover': {
    borderColor: 'white',
    backgroundColor: '#333333',
  },
})

const GetQuoteButton = styled(PrimaryButton)({
  fontSize: '15px',
  padding: '8px 16px',
})

const AuthFooter = () => {
  const history = useHistory()

  const handleContactUs = () => {
    window.location.replace('https://firstpointlogistics.co.uk/contact/')
  }

  const handleGetQuote = () => {
    history.push(Paths.bookings.all)
  }

  return (
    <Box sx={{ backgroundColor: '#333333' }}>
      <Container maxWidth='lg'>
        <Box paddingY={3}>
          <Grid container rowSpacing={3} justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography fontFamily='Goldplay-Medium, sans-serif' fontSize='20px'>
                <Box component='span' color='#b6297b'>
                  Contact us today&nbsp;
                </Box>
                <Box component='span' color='white'>
                  to see how we can help you and your company.
                </Box>
              </Typography>
            </Grid>

            <Grid item>
              <Grid container columnSpacing={{ xs: 1, md: 4 }} rowSpacing={2} alignItems='center'>
                <Grid item>
                  <Box width='180px'>
                    <ContactUsButton onClick={handleContactUs} variant='outlined' fullWidth>
                      Contact us
                    </ContactUsButton>
                  </Box>
                </Grid>

                <Grid item>
                  <Box width='180px'>
                    <GetQuoteButton onClick={handleGetQuote}>Get quote</GetQuoteButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default AuthFooter
