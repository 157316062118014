import { Box, Button, Divider, Stack } from '@mui/material'

interface IProps {
  invoiceTotal: number
  statusLabel: string
  onClick?: () => void
}

function OverviewTableItem({ invoiceTotal, statusLabel, onClick }: IProps) {
  return (
    <Box width='100%'>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Box
            width={{ xs: '15px', md: '25px' }}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {invoiceTotal}
          </Box>
          <Divider orientation='vertical' />
          <Box width={{ xs: '155px', md: '100%' }}>{statusLabel}</Box>
        </Stack>
        <Button variant='outlined' disabled={invoiceTotal === 0} onClick={onClick}>
          View
        </Button>
      </Stack>
    </Box>
  )
}

export default OverviewTableItem
