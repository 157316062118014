import { Typography } from '@mui/material'

interface IProps {
  subDriverId: number | null
}

const SubDriverFormHeader = (props: IProps) => {
  const { subDriverId } = props
  let title: string

  if (!subDriverId) {
    title = 'Create Sub-driver'
  } else {
    title = 'Update Sub-driver'
  }

  return (
    <Typography paragraph variant='h4'>
      {title}
    </Typography>
  )
}

export default SubDriverFormHeader
