import { useState, createContext, ReactNode, useMemo } from 'react'

// ToDo: add types
export const AddressContext = createContext({} as any)

interface IProps {
  children: ReactNode
}

export const AddressProvider = (props: IProps) => {
  const { children } = props
  const [collectionTabValue, setCollectionTabValue] = useState(0)
  const [deliveryTabValue, setDeliveryTabValue] = useState(0)

  const contextValue = useMemo(
    () => ({
      collectionTabValue,
      setCollectionTabValue,
      deliveryTabValue,
      setDeliveryTabValue,
    }),
    [collectionTabValue, deliveryTabValue],
  )

  return <AddressContext.Provider value={contextValue}>{children}</AddressContext.Provider>
}
