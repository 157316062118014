import { useEffect } from 'react'

import { Grid, Tooltip, Typography } from '@mui/material'

import { CardTitle } from 'components/common/controls'
import { useGetBookingLazyQuery, useGetUserBookingLazyQuery } from 'generated/graphql'
import { usePermission } from 'providers/PermissionProvider'

import AcceptancePeriod from './AcceptancePeriod'
import MainBookingDetailsForm from './MainBookingDetailsForm'

interface IProps {
  bookingId?: string
  isQuote: boolean
  isQuickQuote: boolean
  hoverText?: string
  disabled?: boolean
  mode: number | undefined
}

const MainBookingDetails = (props: IProps) => {
  const { bookingId, isQuote, isQuickQuote, hoverText = '', disabled = false, mode } = props

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')

  // Queries
  const [getAdminBooking, { data: adminBookingData }] = useGetBookingLazyQuery()

  const [getUserBooking, { data: userBookingData }] = useGetUserBookingLazyQuery()

  const bookingDetails = adminBookingData?.booking || userBookingData?.userBooking

  // ToDo: create a new component
  const bookingStatus = bookingDetails?.statusLabel
    ? bookingDetails?.statusLabel
    : isQuote
      ? isQuickQuote
        ? 'New Quick Quotation'
        : 'New Quotation'
      : bookingId
        ? ''
        : 'New Booking'

  useEffect(() => {
    if (bookingId) {
      if (isAllowedToViewBookingFee) {
        getAdminBooking({
          variables: { bookingId: Number(bookingId) },
        })
      } else {
        getUserBooking({
          variables: { bookingId: Number(bookingId) },
        })
      }
    }
  }, [bookingId, isAllowedToViewBookingFee, getAdminBooking, getUserBooking])

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <CardTitle marginBottom={isQuote ? 0 : 1} disabled={disabled}>
              Main Details
            </CardTitle>
          </Grid>

          <Grid item>
            {bookingStatus && (
              <Typography variant='h5' color='secondary' textAlign='end'>
                {bookingStatus}
              </Typography>
            )}
          </Grid>

          {isQuote && (
            <Grid item xs={12}>
              <AcceptancePeriod />
            </Grid>
          )}
        </Grid>

        <MainBookingDetailsForm
          bookingDetails={bookingDetails}
          isQuote={isQuote}
          isQuickQuote={isQuickQuote}
          hoverText={hoverText}
          disabled={disabled}
          mode={mode}
        />
      </div>
    </Tooltip>
  )
}

export { MainBookingDetails }
