import { useState } from 'react'

import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { getOidcStorage } from 'helpers'

interface FileUploaderProps {
  url: string
}

export interface UploadState {
  loading: boolean
  error: string
}

export const useFileUploader = ({
  url,
}: FileUploaderProps): [UploadState, (params: any, data: any) => Promise<boolean>] => {
  const [state, setState] = useState<UploadState>({
    loading: false,
    error: '',
  })

  const uploadFile = async (params: any, data: any): Promise<boolean> => {
    setState({ loading: true, error: '' })
    try {
      const storage = getOidcStorage()

      const options = {
        method: 'POST',
        url: url,
        params: params,
        data: data,
        headers: {
          Authorization: storage?.tokens?.accessToken ? `Bearer ${storage.tokens.accessToken}` : '',
        },
      } as AxiosRequestConfig

      await axios(options)
      setState({ ...state, loading: false })
      return true
    } catch (e) {
      let error = ''
      if (typeof e === 'string') {
        error = e.toLocaleUpperCase()
      } else if (e instanceof Error) {
        error = (e as AxiosError).message
      }
      setState({ error: error, loading: false })
      return false
    }
  }

  return [state, uploadFile]
}
