import { joiResolver } from '@hookform/resolvers/joi'
import { Box, Paper } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { JoiValidationOptions, Mode, PARAM_NEW, Paths } from 'constants/index'
import {
  useCreateStandardOperatingProcedureMutation,
  useUpdateStandardOperatingProcedureMutation,
} from 'generated/graphql'
import { isNumber } from 'helpers'
import { TStandardOperatingProcedureForm } from 'types'
import { StandardOperatingProcedureDetailsValidation } from 'validation-schemas'

import SopAttachments from './SopAttachments'
import SopFormControls from './SopFormControls'
import SopHistory from './SopHistory'
import UpdateSop from './UpdateSop'

const SopDetails = () => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()

  const methods = useForm<TStandardOperatingProcedureForm>({
    resolver: joiResolver(StandardOperatingProcedureDetailsValidation, JoiValidationOptions),
    defaultValues: {
      name: '',
      isActive: true,
      description: '',
      steps: '',
      frequentlyAskedQuestions: '',
      notes: '',
      driverInstructions: '',
      isAttachedToAllCustomers: false,
      customerIds: [],
    },
  })
  const { handleSubmit } = methods

  let mode = Mode.Create
  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const sopId: string | null = mode === Mode.Update && id ? id : null

  const [createCreateStandardOperatingProcedure, { loading: creatingStandardOperatingProcedure }] =
    useCreateStandardOperatingProcedureMutation({
      onCompleted: (data) => {
        if (data.createStandardOperatingProcedure) {
          toast.success(
            `Standard Operating Procedure '${data?.createStandardOperatingProcedure?.name}' was successfully created.`,
          )
          history.push(
            Paths.standardOperatingProcedures.updateWithId(
              data?.createStandardOperatingProcedure.id,
            ),
          )
        }
      },
    })

  const [updateCreateStandardOperatingProcedure, { loading: updatingStandardOperatingProcedure }] =
    useUpdateStandardOperatingProcedureMutation({
      onCompleted: (data) => {
        if (data.updateStandardOperatingProcedure) {
          toast.success(
            `Standard Operating Procedure '${data?.updateStandardOperatingProcedure?.name}' was successfully updated.`,
          )
        }
      },
    })

  const handleSubmitForm: SubmitHandler<TStandardOperatingProcedureForm> = (formData) => {
    const normalizedProps = {
      ...formData,
      customerIds:
        formData.customerIds && formData.customerIds.length > 0
          ? formData.customerIds.filter((c) => c.value).map((c) => c.value)
          : [],
      id: mode === Mode.Update ? Number(sopId) : undefined,
    }
    if (mode === Mode.Create) {
      createCreateStandardOperatingProcedure({
        variables: {
          // input: { ...formData, isAttachedToAllCustomers: false, customerIds: formData.customerIds?.map() },
          input: normalizedProps,
        },
      })
    } else {
      updateCreateStandardOperatingProcedure({
        variables: {
          input: normalizedProps,
          // input: { ...formData, id: Number(sopId), isAttachedToAllCustomers: false },
        },
      })
    }
  }

  const isProcessing = creatingStandardOperatingProcedure || updatingStandardOperatingProcedure

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <FormProvider {...methods}>
          <form id='sop-details-form' noValidate onSubmit={handleSubmit(handleSubmitForm)}>
            {mode === Mode.Create ? (
              <SopFormControls mode={mode} isProcessing={isProcessing} />
            ) : (
              <UpdateSop sopId={sopId!} mode={mode} isProcessing={isProcessing} />
            )}
          </form>
        </FormProvider>
      </Box>
      {sopId && <SopAttachments id={Number(sopId)} />}
      {sopId && <SopHistory id={sopId} />}
    </Paper>
  )
}

export default SopDetails
