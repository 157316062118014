import { useQuery } from '@apollo/client'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'

import { GET_DRIVER_AVERAGE_RATING } from 'graphql/queries/DriverQueries'
import { numberFormat } from 'helpers/numberFormat'

import DriverRating from './DriverRating'

interface Params {
  id: string
}

function DriverAverageRating() {
  const { id } = useParams<Params>()

  const { data, loading } = useQuery(GET_DRIVER_AVERAGE_RATING, {
    variables: { driverId: Number(id) },
    skip: id === 'new',
  })

  return (
    <>
      {!loading && data?.driver?.averageRating != null && (
        <Grid item xs={12} display={'flex'} alignItems={'center'} gap={2}>
          <DriverRating ratingValue={parseFloat(numberFormat(data?.driver?.averageRating, 0, 1))} />
        </Grid>
      )}
    </>
  )
}

export default DriverAverageRating
