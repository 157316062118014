import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField } from 'components/common/controller-wrapper'
import { CardTitle } from 'components/common/controls'
import { ICreateDriverUserForm } from 'types/form-types'

export const CreateDriverUserForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ICreateDriverUserForm>()

  const spacing = 3
  return (
    <>
      <CardTitle>User details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField control={control} name='name' label='Driver name' readOnly />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField control={control} name='email' label='Email' readOnly />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='userName'
            label='Username'
            required
            error={!!errors.username}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField control={control} name='firstName' required label='First name' />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField control={control} name='lastName' required label='Last name' />
        </Grid>
      </Grid>
    </>
  )
}
