import React from 'react'

import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { GET_SENT_EMAILS_QUERY } from 'graphql/queries'

import ViewSentEmail from './ViewSentEmail'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'createdAt',
    headerName: 'Sent At',
    width: 150,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_TIME_FORMAT),
  },
  { field: 'createdBy', headerName: 'By', minWidth: 150, flex: 0.5 },
  { field: 'to', headerName: 'To', minWidth: 150, flex: 0.5 },
  { field: 'subject', headerName: 'Subject', minWidth: 150, flex: 1 },
  {
    field: 'hasAttachments',
    headerName: 'Has Attachments?',
    width: 120,
    type: 'boolean',
  },
  { field: 'isSuccessful', headerName: 'Successful?', width: 100, type: 'boolean' },
  { field: 'hasErrors', headerName: 'Has Errors?', width: 100, type: 'boolean' },
]

const SentEmails = () => {
  const [selectedSentEmailId, setSelectedSentEmailId] = React.useState<number | null>(null)
  const [openColumnValuesDialog, setOpenColumnValuesDialog] = React.useState(false)

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedSentEmailId(Number(id))
    setOpenColumnValuesDialog(true)
  }

  const handleCloseColumnValuesDialog = () => {
    setOpenColumnValuesDialog(false)
  }

  return (
    <>
      <FplDataGrid
        query={GET_SENT_EMAILS_QUERY}
        entityName='sentEmails'
        columns={columns}
        defaultOrder={{ field: 'id', sort: 'desc' }}
        toolbar={{ caption: 'Sent Emails' }}
        onRowClick={handleRowClick}
      />

      {openColumnValuesDialog && (
        <ViewSentEmail
          selectedSentEmailId={selectedSentEmailId}
          openDialog={openColumnValuesDialog}
          handleCloseDialog={handleCloseColumnValuesDialog}
        />
      )}
    </>
  )
}

export default SentEmails
