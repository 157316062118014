import { useState } from 'react'

import { AppBar, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

import { TabPanel, TasksAndHistory } from 'components/common'
import { useHashToSelectTab } from 'helpers'
import { PermissionType, Restricted } from 'providers'

import BookingsTracking from './BookingsTracking/BookingsTracking'
import DriversTracking from './DriversTracking/DriversTracking'
import { TaskBoard } from './TaskBoard'

enum TabId {
  Tasks = 0,
  TaskBoard = 1,
  BookingsTracking = 2,
  DriversTracking = 3,
}
const tabsList = [
  {
    id: TabId.Tasks,
    label: 'Tasks',
    hash: '#dashboard-tasks',
  },
  {
    id: TabId.TaskBoard,
    label: 'Board',
    hash: '#dashboard-task-board',
  },
  {
    id: TabId.BookingsTracking,
    label: 'Bookings Tracking',
    hash: '#dashboard-bookings-tracking',
    policy: 'ViewBookingsTrackingDashboard' as PermissionType,
  },
  {
    id: TabId.DriversTracking,
    label: 'Drivers Tracking',
    hash: '#dashboard-drivers-tracking',
  },
]

function commonProps(index: any) {
  return {
    id: `dashboard-tab-${index}`,
    'aria-controls': `dashboard-tabpanel-${index}`,
  }
}

const Dashboard = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)

  useHashToSelectTab(tabsList, setTabValue)

  const renderTabs = () =>
    tabsList.map((tab) => (
      <Tab
        label={tab.label}
        key={tab.id}
        {...commonProps(tab.id)}
        component={Link}
        to={{ hash: tab.hash }}
      />
    ))

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          aria-label='dashboard tabs'
          variant='scrollable'
          TabIndicatorProps={{ style: { transition: 'none' } }}>
          {renderTabs()}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={TabId.Tasks}>
        <TasksAndHistory bookingId={null} />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.TaskBoard}>
        <TaskBoard />
      </TabPanel>
      <Restricted to='ViewBookingsTrackingDashboard'>
        <TabPanel value={tabValue} index={TabId.BookingsTracking}>
          <BookingsTracking />
        </TabPanel>
      </Restricted>
      <Restricted to='ViewDriverTrackingDashboard'>
        <TabPanel value={tabValue} index={TabId.DriversTracking}>
          <DriversTracking />
        </TabPanel>
      </Restricted>
    </div>
  )
}

export default Dashboard
