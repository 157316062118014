import { ReactNode } from 'react'

import { Paper, styled } from '@mui/material'

interface IProps {
  children?: ReactNode
}

const StyledPaper = styled(Paper)({
  width: '100%',
  height: 600,
  overflowY: 'auto',
})

function PaperPanel({ children }: IProps) {
  return <StyledPaper elevation={4}>{children}</StyledPaper>
}

export default PaperPanel
