import { gql } from '@apollo/client'

const GET_VAT_CATEGORIES_QUERY = gql`
  query GetVatCategories {
    vatCategories {
      id
      name
    }
  }
`

export { GET_VAT_CATEGORIES_QUERY }
