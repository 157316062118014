import { endOfDay, startOfDay } from 'date-fns'
import { FormProvider, useForm } from 'react-hook-form'

import { ViewDownloadButtonsId } from 'constants/EntityConstants'

import FilterCriteriaForm from './FilterCriteriaForm'
import { ISalesFilter } from './Sales'

interface IProps {
  defaultFilter: ISalesFilter
  isProcessing: boolean
  onViewReport: (filter: ISalesFilter) => void
  onDownloadReport: (filter: ISalesFilter) => void
}

const FilterCriteria = (props: IProps) => {
  const { defaultFilter, isProcessing, onViewReport, onDownloadReport } = props

  const methods = useForm({
    shouldUnregister: true,
    defaultValues: defaultFilter,
  })
  const { handleSubmit, reset } = methods

  const handleClearAll = () => {
    reset(defaultFilter)
  }

  const handleOnSubmit = (buttonId: ViewDownloadButtonsId, formData: ISalesFilter) => {
    const { start, end } = formData

    const filter = {
      start: start ? startOfDay(start) : undefined,
      end: end ? endOfDay(end) : undefined,
    }

    switch (buttonId) {
      case ViewDownloadButtonsId.ViewReport:
        onViewReport(filter)
        break

      case ViewDownloadButtonsId.DownloadReport:
        onDownloadReport(filter)
        break
    }
  }

  const handleReportButtonClick = (buttonId: ViewDownloadButtonsId) => {
    handleSubmit((formData) => handleOnSubmit(buttonId, formData))()
  }

  return (
    <FormProvider {...methods}>
      <FilterCriteriaForm
        isProcessing={isProcessing}
        onReportButtonClick={handleReportButtonClick}
        onClearAll={handleClearAll}
      />
    </FormProvider>
  )
}

export default FilterCriteria
