import { GetStandardOperatingProcedureQuery } from 'generated/graphql'
import { TStandardOperatingProcedureForm } from 'types'

export const normalizeStandardOperatingProcedureForm = (
  standardOperatingProcedure: NonNullable<
    GetStandardOperatingProcedureQuery['standardOperatingProcedure']
  >,
): TStandardOperatingProcedureForm => ({
  name: standardOperatingProcedure.name,
  isActive: standardOperatingProcedure.isActive,
  description: standardOperatingProcedure.description,
  steps: standardOperatingProcedure.steps,
  frequentlyAskedQuestions: standardOperatingProcedure.frequentlyAskedQuestions ?? undefined,
  notes: standardOperatingProcedure.notes ?? undefined,
  driverInstructions: standardOperatingProcedure.driverInstructions ?? undefined,
  isAttachedToAllCustomers: standardOperatingProcedure.isAttachedToAllCustomers,
  customerIds: standardOperatingProcedure.customers.map((c) => ({
    value: c.customer.id,
    label: c.customer.name,
  })),
})
