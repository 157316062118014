import React from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { CreateAndUpdateReasonCodeDtoInput, useGetReasonCodeQuery } from 'generated/graphql'

import ReasonCodeForm from './ReasonCodeForm'

interface IProps {
  id: number
}
export default function UpdateReasonCode(props: IProps) {
  const { id } = props

  const { reset } = useFormContext<CreateAndUpdateReasonCodeDtoInput>()

  const { data, loading } = useGetReasonCodeQuery({
    variables: { id },
  })

  React.useEffect(() => {
    if (data) {
      reset({ ...data.reasonCode })
    }
  }, [data, reset])

  return (
    <>
      <ReasonCodeForm isUpdate={true} />
      <LoadingBackdrop loading={loading} />
    </>
  )
}
