import { useReactiveVar } from '@apollo/client'
import { Box, Paper, Stack } from '@mui/material'
import { useAccountPayableOverviewQuery } from 'generated/graphql'
import { accountPayableFiltersFormVar } from 'graphql/reactiveVariables'
import { dateTimeZoneFormat } from 'helpers/date.helpers'

import OverviewHeader from './common/OverviewHeader'
import OverviewTable from './common/OverviewTable/OverviewTable'

const OverviewTab = () => {
  const accountPayableFilters = useReactiveVar(accountPayableFiltersFormVar)

  const { data: overviewData, loading } = useAccountPayableOverviewQuery({
    variables: {
      startDate: dateTimeZoneFormat(accountPayableFilters?.dueInStartDate, 'start'),
      endDate: dateTimeZoneFormat(accountPayableFilters?.dueInEndDate, 'end'),
      supplierCodes: accountPayableFilters?.xeroPurchaseCodes?.map((x) => x.label) ?? [],
    },
    fetchPolicy: 'no-cache',
  })

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Stack spacing={2}>
          <OverviewHeader
            amountInPounds={overviewData?.accountPayableOverview.poundsAmount}
            amountInDollars={overviewData?.accountPayableOverview.dollarsAmount}
            amountInEuro={overviewData?.accountPayableOverview.eurosAmount}
            countOfInvoices={overviewData?.accountPayableOverview.scheduledInvoicesCount ?? 0}
            loading={loading}
          />
          <OverviewTable
            countOfBookingsWithCompletedDocuments={
              overviewData?.accountPayableOverview.bookingsWithCompletedDocumentsCount
            }
            countOfBookingsWithOpenQueries={
              overviewData?.accountPayableOverview.bookingsWithOpenQueriesCount
            }
            countOfBookingsWithSupplementaryInvoiceReceived={
              overviewData?.accountPayableOverview.bookingsWithSupplementaryInvoiceReceivedCount
            }
            countOfBookingsWithCreditNoteReceived={
              overviewData?.accountPayableOverview.bookingsWithCreditNoteReceivedCount
            }
            countOfBookingsWithApprovedDocuments={
              overviewData?.accountPayableOverview.bookingsWithApprovedDocumentsCount
            }
            countOfBookingsWithPaymentSent={overviewData?.accountPayableOverview.sentPaymentsCount}
            countOfOverduesForApproval={
              overviewData?.accountPayableOverview.overduesForApprovalCount
            }
            countOfFailedPayments={overviewData?.accountPayableOverview.failedPaymentsCount}
            loading={loading}
          />
        </Stack>
      </Box>
    </Paper>
  )
}

export default OverviewTab
