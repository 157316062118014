import { gql } from '@apollo/client'

export const GET_PAGED_FILES_QUERY = gql`
  query getPagedFiles(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [FileSortInput!]
    $where: FileFilterInput
  ) {
    files(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          originalFileName
          category
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
