import Joi from 'joi'

import { CreateScheduledBookingDateDtoInput } from 'generated/graphql'

const ScheduledDatesValidation = Joi.object<CreateScheduledBookingDateDtoInput>({
    executeOnDate : Joi.date().required().min("now").message("The date must be greater than today"),
})

export { ScheduledDatesValidation }

