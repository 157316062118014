import { useState } from 'react'

import { Box, Menu } from '@mui/material'

import { ButtonProfile } from 'components/auth'
import { Restricted } from 'providers/PermissionProvider'

import ButtonNotifications from './ButtonNotifications'
import ButtonTasks from './ButtonTasks'

const Buttons = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuId = 'menu-mobile'

  return (
    <div>
      <Box display={{ xs: 'flex', md: 'flex' }}>
        {/* Add buttons below: */}
        <Restricted to='ViewTasks'>
          <ButtonTasks />
        </Restricted>

        <Restricted to='ViewYourNotifications'>
          <ButtonNotifications />
        </Restricted>

        <ButtonProfile />
      </Box>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}>
        {/* Good explanation why div needs to be the child of Menu: https://stackoverflow.com/a/56309771/1578975 */}
        <div>
          {/* Add buttons below: */}
          {/* <ButtonNotifications isMenu /> */}
          <ButtonProfile isMenu onClosed={handleClose} />
        </div>
      </Menu>
    </div>
  )
}

export default Buttons
