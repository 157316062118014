import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledSelector, ControlledTextField } from 'components/common'
import { MinVehicleSizeValues } from 'constants/index'
import { CreateAndUpdateTariffCategoryDtoInput } from 'generated/graphql'

const TariffCategoryForm = () => {
  const spacing = 3

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateTariffCategoryDtoInput>()

  return (
    <Box pt={1}>
      <Grid container spacing={spacing}>
        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            name='name'
            label='Name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledSelector
            control={control}
            name='courierExchangeVehicleSize'
            label='CX Vehicle Size'
            options={MinVehicleSizeValues}
            emptyValue={{ value: '', label: 'None' }}
            defaultValue=''
            error={!!errors.courierExchangeVehicleSize}
            helperText={errors.courierExchangeVehicleSize?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledCheckbox control={control} name='hasSpotRate' label='Has Spot-Rate' />
        </Grid>
      </Grid>
    </Box>
  )
}

export default TariffCategoryForm
