import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'

import { CardTitle } from 'components/common'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { ExportDriversCodeFile } from 'services'

const ExportDriversCode = () => {
  const [isProcessing, setIsProcessing] = useState(false)

  const handleExportClick = () => {
    setIsProcessing(true)

    ExportDriversCodeFile()
      .then((response) => {
        setIsProcessing(false)

        const fileName = GetFileName(response)

        if (fileName) {
          SaveFile(response.data, fileName)
        }
      })
      .catch((error) => {
        ShowRestError(error)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  return (
    <>
      <CardTitle>Export Drivers Code</CardTitle>
      <Stack spacing={3}>
        <Typography variant='body1'>
          After clicking on EXPORT button a CSV file will be downloaded. You can edit that file (for
          example, in Microsoft Excel) and then re-upload it to Import Drivers Code.
          <br />
          Note that the &quot;Name&quot; column is provided only for convenience and any changes to
          it will be ignored.
        </Typography>

        <div>
          <LoadingButton loading={isProcessing} onClick={handleExportClick} variant='contained'>
            Export
          </LoadingButton>
        </div>
      </Stack>
    </>
  )
}

export default ExportDriversCode
