import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Dialog, Typography, DialogContent, DialogActions, Button } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { JoiValidationOptions, Mode } from 'constants/index'
import {
  CreateAndUpdateDriverContactDtoInput,
  useCreateDriverContactMutation,
  useUpdateDriverContactMutation,
} from 'generated/graphql'
import { SuppliersContactValidation } from 'validation-schemas'

import ContactForm from './ContactForm'
import UpdateContact from './UpdateContact'

interface IProps {
  contactId: number | null
  driverId: number
  openDialog: boolean
  handleCloseDialog: () => any
}

const CreateUpdateContact = (props: IProps) => {
  const { contactId, driverId, openDialog, handleCloseDialog } = props

  const methods = useForm<CreateAndUpdateDriverContactDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(SuppliersContactValidation, JoiValidationOptions),
    defaultValues: {
      name: '',
      isDefault: false,
      telephoneNumber: '',
      email: '',
      additionalEmails: '',
      department: '',
      jobRole: '',
      driverId,
    },
  })

  const { handleSubmit } = methods

  // Mutations
  const [createContact, { loading: creatingContact }] = useCreateDriverContactMutation({
    onCompleted: (data) => {
      if (!data?.createDriverContact) {
        toast.error('Something went wrong. Please try again.')
        return
      } else {
        toast.success(`Contact ${data?.createDriverContact.name} was successfully created.`)
      }
      handleCloseDialog()
    },
  })

  const [updateContact, { loading: updatingContact }] = useUpdateDriverContactMutation({
    onCompleted: (data) => {
      if (!data?.updateDriverContact) {
        toast.error('Something went wrong. Please try again.')
        return
      } else {
        toast.success(`Contact ${data?.updateDriverContact.name} was successfully updated.`)
      }
      handleCloseDialog()
    },
  })

  const mode = contactId ? Mode.Update : Mode.Create

  const handleSubmitForm: SubmitHandler<CreateAndUpdateDriverContactDtoInput> = (formData) => {
    if (mode === Mode.Create) {
      createContact({
        variables: {
          input: { ...formData, driverId, isDefault: false },
        },
      })
    } else {
      updateContact({
        variables: {
          input: {
            ...formData,
            id: contactId,
            driverId,
            isDefault: false,
          },
        },
      })
    }
  }

  const handleOnCancel = () => {
    // ToDo: check if form is dirty
    handleCloseDialog()
  }

  const isProcessing = creatingContact || updatingContact

  return (
    <Dialog open={openDialog} fullWidth maxWidth='md' aria-labelledby='driver-contact-dialog-title'>
      <FplDialogTitle id='driver-contact-dialog-title' onClose={handleOnCancel}>
        <Typography paragraph variant='h4'>
          {mode === Mode.Create ? 'New Contact' : 'Update Contact'}
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? <ContactForm /> : <UpdateContact contactId={contactId!} />}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleOnCancel}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          loading={isProcessing}
          onClick={handleSubmit(handleSubmitForm)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateContact
