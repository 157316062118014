import { gql } from '@apollo/client'

export const CommentaryDetailsFragment = gql`
  fragment CommentaryDetails on Commentary {
    message
    taskId

    createdAt
    createdBy
    lastModifiedAt
    lastModifiedBy
  }
`
