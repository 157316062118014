import { useMemo } from 'react'

import { Grid, Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledSelector,
  ControlledTextField,
  CardTitle,
  ISelectorOptions,
} from 'components/common'
import { CreateAndUpdateDriverDtoInput, useGetVatCategoriesQuery } from 'generated/graphql'

const PaymentDetails = () => {
  const {
    control,

    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()
  const spacing = 2

  // Queries
  const { data: vatCategoriesData, loading: vatCategoriesLoading } = useGetVatCategoriesQuery()

  const vatCategoryItems: ISelectorOptions = useMemo(() => {
    if (!vatCategoriesLoading && vatCategoriesData?.vatCategories) {
      return vatCategoriesData.vatCategories.map((vc) => ({ value: vc.id, label: vc.name }))
    }

    return []
  }, [vatCategoriesData, vatCategoriesLoading])

  return (
    <>
      <CardTitle>Payment Details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={6}>
          {vatCategoriesLoading ? (
            <Skeleton height={22} animation='wave' />
          ) : (
            <ControlledSelector
              control={control}
              name='vatCategoryId'
              label='VAT Category'
              options={vatCategoryItems}
              required
              error={!!errors.vatCategoryId}
              helperText={errors.vatCategoryId?.message}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankName'
            label='Bank Name'
            error={!!errors.bankName}
            helperText={errors.bankName?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankSortCode'
            label='Bank Sort Code'
            error={!!errors.bankSortCode}
            helperText={errors.bankSortCode?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankAccountNumber'
            label='Bank Acc No'
            error={!!errors.bankAccountNumber}
            helperText={errors.bankAccountNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='bankAccountHolderName'
            label='Bank Acc Holder Name'
            error={!!errors.bankAccountHolderName}
            helperText={errors.bankAccountHolderName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='internationalBankAccountNumber'
            label='IBAN'
            error={!!errors.internationalBankAccountNumber}
            helperText={errors.internationalBankAccountNumber?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='vatIdNumber'
            label='VAT Reg. No'
            error={!!errors.vatIdNumber}
            helperText={errors.vatIdNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='swift'
            label='SWIFT/BIC'
            error={!!errors.swift}
            helperText={errors.swift?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentDetails
