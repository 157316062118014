import React from 'react'

import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'

import { ControlledCheckbox } from 'components/common'

interface IProps {
  notifications: any
  onCancel: any
  onSuccess: any
  processing: boolean
}

const CustomerNotificationsForm = (props: IProps) => {
  const { notifications, onCancel, onSuccess, processing = false } = props

  const {
    handleSubmit,
    control,
    formState: { isDirty: formIsDirty },
    reset,
  } = useForm({
    shouldUnregister: true,
    defaultValues: { ...notifications },
  })

  React.useEffect(() => {
    reset({
      ...notifications,
    })
  }, [reset, notifications])

  const onSubmit = (notificationForm) => {
    onSuccess(notificationForm)
  }

  const handleCancel = () => onCancel(formIsDirty)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper variant='outlined' sx={{ marginBottom: 4 }}>
        <Box p={2}>
          <Typography paragraph variant='h5' color='primary'>
            Notification Details
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box m={1}>
                <FormControl>
                  <FormLabel>Docket</FormLabel>
                  <FormGroup>
                    <Grid container>
                      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Quote' Notification"
                          name='isNotificationForQuote'
                        />
                        <ControlledCheckbox
                          control={control}
                          label='Enable Booking ready for Allocation Notification'
                          name='isNotificationForReadyForAllocation'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Booked' Notification"
                          name='isNotificationForBooking'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Collection arrival' Notification"
                          name='isNotificationForPobArrival'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Collected' notification"
                          name='isNotificationForPOB'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Delivery arrival' notification"
                          name='isNotificationForPodArrival'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Part delivered'"
                          name='isNotificationForPodPartial'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Delivered' notification"
                          name='isNotificationForPOD'
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Booking Cancellation' notification"
                          name='isNotificationForCancellation'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Carded' notification"
                          name='isNotificationForCarded'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Booking Amendment' notification"
                          name='isNotificationForAmendment'
                        />
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Driver Allocated' notification"
                          name='isNotificationForDriverAllocated'
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container direction='column'>
                <Grid item>
                  <Box m={3}>
                    <FormControl>
                      <FormLabel>Invoice</FormLabel>
                      <FormGroup>
                        <ControlledCheckbox
                          control={control}
                          label="Enable 'Invoice' Notification"
                          name='isNotificationForInvoice'
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl>
                <FormLabel>Invoice Due</FormLabel>
                <FormGroup>
                  <ControlledCheckbox
                    control={control}
                    label="Enable 'Invoice Due' Notification"
                    name='isNotificationForInvoiceDue'
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box display='flex' justifyContent='flex-end' alignItems='center' p={1}>
        <Button variant='contained' color='grey' onClick={handleCancel}>
          Cancel
        </Button>
        <Box ml={1}>
          <Button color='primary' variant='contained' type='submit' disabled={processing}>
            Save
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default CustomerNotificationsForm
