import { useMemo, useState } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { LoadingButton } from '@mui/lab'
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material'
import { compareAsc } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { getPaging } from 'components/common/FplDataGrid/helpers'
import { Paths } from 'constants/Paths'
import { BookingStatus, SortEnumType, TaskStatus, useGetTasksQuery } from 'generated/graphql'
import { usePermission } from 'providers/PermissionProvider'

import TaskRow from './TaskRow'

interface IProps {
  bookingId: string | null
  onTaskClick: (id) => void
  onCheckboxChange: (value: boolean, task) => void
  onDeleteClick: (id) => void
}

const TaskList = (props: IProps) => {
  const { bookingId, onTaskClick, onCheckboxChange, onDeleteClick } = props
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const history = useHistory()
  const { idTokenPayload } = useOidcIdToken()

  const [isAllowedToUpdateTask] = usePermission('UpdateTask')
  const [isAllowedToDeleteTask] = usePermission('DeleteTask')

  const { data, loading, fetchMore } = useGetTasksQuery({
    variables: {
      ...getPaging({ first: 10 }),
      where: {
        status: { eq: TaskStatus.New },
        ...(bookingId
          ? { bookingId: { eq: Number(bookingId) } }
          : { assignedUserName: { eq: idTokenPayload.name } }),
      },
      order: { endDate: SortEnumType.Asc },
    },
  })

  const tasksSorted = useMemo(
    () =>
      data?.tasks?.edges
        ?.slice()
        .sort((a, b) => compareAsc(new Date(a.node.endDate), new Date(b.node.endDate))),
    [data],
  )

  const handleRedirectClick = (bookingId, status: BookingStatus) => {
    const url =
      status === BookingStatus.Quotation
        ? Paths.quotes.updateWithId(bookingId)
        : Paths.bookings.updateWithId(bookingId)

    history.push(`${url}#tasks`)
  }

  const handleLoadMore = () => {
    const paging = getPaging({ after: data?.tasks?.pageInfo.endCursor, first: 10 })

    setFetchMoreLoading(true)

    fetchMore({
      variables: {
        ...paging,
        where: {
          status: { eq: TaskStatus.New },
          ...(bookingId
            ? { bookingId: { eq: Number(bookingId) } }
            : { assignedUserName: { eq: idTokenPayload.name } }),
        },
      },
      updateQuery: (previousResult: any, { fetchMoreResult }) => {
        const newTasks = fetchMoreResult?.tasks?.edges ?? []
        const previousEdges = previousResult.tasks?.edges ?? []

        if (fetchMoreResult?.tasks) {
          return {
            tasks: {
              pageInfo: fetchMoreResult.tasks.pageInfo,
              totalCount: fetchMoreResult.tasks.totalCount,
              __typename: fetchMoreResult.tasks.__typename,
              edges: [...previousEdges, ...newTasks],
            },
          }
        } else {
          return previousResult
        }
      },
    }).finally(() => setFetchMoreLoading(false))
  }

  return (
    <>
      <Stack divider={<Divider orientation='horizontal' flexItem />}>
        {loading
          ? [...Array(5)].map((_, index) => (
            <Box px={1} py={0.5} key={index}>
              <Typography variant='h6'>
                <Skeleton animation='wave' />
              </Typography>
            </Box>
          ))
          : tasksSorted?.map((task) => (
            <TaskRow
              key={task.node.id}
              task={task.node}
              bookingId={bookingId}
              loggedUserName={idTokenPayload.name}
              isAllowedToUpdateTask={isAllowedToUpdateTask}
              isAllowedToDeleteTask={isAllowedToDeleteTask}
              onTaskClick={onTaskClick}
              onCheckboxChange={onCheckboxChange}
              onDeleteClick={onDeleteClick}
              onRedirectClick={handleRedirectClick}
            />
          ))}
      </Stack>

      <Divider orientation='horizontal' flexItem />

      <Stack direction='row' justifyContent='center' paddingY={1}>
        <LoadingButton
          loading={fetchMoreLoading}
          disabled={!data?.tasks?.pageInfo.hasNextPage}
          onClick={handleLoadMore}>
          Load more
        </LoadingButton>
      </Stack>
    </>
  )
}

export default TaskList
