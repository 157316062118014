import {
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  subMonths,
  subWeeks,
} from 'date-fns'

import { BookingPeriod } from 'constants/EntityConstants'
import {
  BookingStatus,
  CalculatedStatus,
  DateTimeOperationFilterInput,
} from 'generated/graphql'

export function getIntervalByPeriod(
  period: BookingPeriod,
  dateFrom,
  dateTo,
): DateTimeOperationFilterInput | undefined {
  switch (period) {
    case BookingPeriod.Today:
      return {
        gte: startOfToday(),
      }

    case BookingPeriod.Yesterday:
      return {
        gte: startOfYesterday(),
        lte: startOfToday(),
      }

    case BookingPeriod.Last7Days:
      return {
        gte: subWeeks(new Date(), 1),
      }

    case BookingPeriod.Last30Days:
      return {
        gte: subMonths(new Date(), 1),
      }

    case BookingPeriod.ThisMonth:
      return {
        gte: startOfMonth(new Date()),
      }

    case BookingPeriod.LastMonth:
      return {
        gte: startOfMonth(subMonths(new Date(), 1)),
        lte: startOfMonth(new Date()),
      }

    case BookingPeriod.CustomRange:
      if (dateFrom || dateTo) {
        return {
          gte: dateFrom ? startOfDay(dateFrom) : undefined,
          lte: dateTo ? startOfDay(dateTo) : undefined,
        }
      }
      break

    case BookingPeriod.AnyTime:
      return undefined
  }
}

export function getCalculatedStatusByStatusId(statusId: CalculatedStatus) {
  switch (statusId) {
    case CalculatedStatus.OnHold:
      return {
        isOnHold: { eq: true },
      }

    case CalculatedStatus.IsPending:
      return {
        isPending: { eq: true },
      }

    case CalculatedStatus.Complete:
      return {
        status: { eq: BookingStatus.Complete },
        or: undefined,
      }

    case CalculatedStatus.IsArchived:
      return {
        isArchived: { eq: true },
        or: undefined,
      }

    case CalculatedStatus.IsCancelledOrQuoteLost:
      return {
        isCancelledOrQuoteLost: { eq: true },
        or: undefined,
      }

    case CalculatedStatus.Quotation:
      return {
        isQuickQuote: { eq: false },
      }

    case CalculatedStatus.IsQuickQuote:
      return {
        isQuickQuote: { eq: true },
      }

    default:
      return {
        status: { eq: statusId },
        isOnHold: { eq: false },
        isPending: { eq: false },
      }
  }
}
