import { Container, Typography } from '@mui/material'

const TermsAndConditions = () => {
  return (
    <Container sx={{ '>p': { margin: 0 } }}>
      <Typography variant='h6'>
        Terms and Conditions of Carriage – FirstPoint Logistics Ltd
      </Typography>
      <br />
      <p>
        <strong>FirstPoint Logistics Ltd</strong> (hereinafter referred to as “the Carrier “) is not
        a common carrier and accepts goods for carriage only upon that condition and the Conditions
        set out below. No servant or agent of the Carrier is permitted to alter or vary these
        Conditions in any way unless expressly authorised in writing to do so by a duly authorised
        person. If any part of these Conditions is incompatible with applicable legislation, such
        part shall, as regards the Contract, be overridden to that extent and no further.
      </p>
      <br />

      <Typography>
        <strong>1. Definitions – In these Conditions:</strong>
      </Typography>
      <p>
        “Customer” means the person, firm or company who contracts for the services of the Carrier.
      </p>
      <p>“Contract” means the contract of carriage between the Customer and the Carrier.</p>
      <p>
        “Consignee” means the person or company to whom the Carrier contracts to deliver the
        Consignment to.
      </p>
      <p>
        “Consignment” means goods, whether a single item or in bulk or contained in one parcel,
        package or container, as the case may be, or any number of separate items, parcels, packages
        or containers sent at one time in one load by or for the Customer to the Consignee from one
        address to another address.
      </p>
      <p>
        “Dangerous Goods” means goods set out in the Carriage of Dangerous Goods and Use of
        Transportable Pressure Equipment Regulations (CDG) and the European agreement “Accord
        européen relatif au transport international des marchandises dangereuses par route” (ADR),
        which together regulate the carriage of dangerous goods by road including, explosives,
        radioactive material, and any other goods presenting a similar hazard.
      </p>
      <p>
        “Delivery Address” means the address for delivery of the Consignment notified to the Courier
        at the time of booking. “Demurrage” means any cost or expense the Carrier suffers as a
        result of the improper, excessive or unreasonable detention of any vehicle, trailer,
        container or other equipment belonging to or under the control of the Carrier.
      </p>
      <p>“Force Majeure Event” shall have the meaning set out in Condition 14(3)(b).</p>
      <p>
        “In writing” includes, unless otherwise agreed, the transmission of information by
        electronic, optical or similar means of communication, including, but not limited to,
        facsimile, electronic mail or electronic data interchange (EDI), provided that the
        information is readily accessible and durable so as to be usable for subsequent reference.
      </p>
      <br />

      <Typography>
        <strong>2. Application of Conditions</strong>
      </Typography>
      <p>(1) These conditions shall:</p>
      <p>
        (a) apply to and be incorporated into the contract between the Customer and the Carrier;
      </p>
      <p>
        (b) prevail over any inconsistent terms or conditions contained, or referred to, in the
        Customer’s purchase order, confirmation of order, acceptance of a quotation or
        specification, or implied by law, trade custom, practice or course of dealing.
      </p>
      <p>
        (2) The Customer’s purchase order, or the Customer’s acceptance of a quotation for services
        by the Carrier, constitutes an offer by the Customer to purchase the services specified in
        it on these No offer placed by the Customer shall be accepted by the Carrier other than:
      </p>
      <p>(a) by a written or verbal acknowledgement of the quote issued by the Carrier; or</p>
      <p>
        (b) (if earlier) by the Carrier starting to provide services, when a contract for the supply
        and purchase of those services on these Conditions will be established. The Customer’s
        standard terms and conditions (if any) attached to, enclosed with or referred to in any
        purchase order or other document shall not prevail.
      </p>
      <br />

      <Typography>
        <strong>3. Bookings, commencement, and duration</strong>
      </Typography>
      <p>
        (1) Each booking by the Customer shall be submitted by the Customer to the Carrier by
        telephone or email.
      </p>
      <p>
        (2) The Carrier shall provide a quotation for the delivery of the Consignment, such a
        quotation shall be valid for a period of 7 days or such other period as the Carrier may
        specify.
      </p>
      <p>(3) The Carrier reserves the right to refuse to accept any bookings.</p>
      <p>
        (4) The services supplied under this agreement shall be provided by the Carrier to the
        Customer from the date of acceptance by the Carrier of the Customer’s offer in accordance
        with condition 2.2. above.
      </p>
      <p>
        (5) The services supplied shall continue to be supplied until the Consignment is delivered
        to the delivery address or the agreement is terminated.
      </p>
      <br />

      <Typography>
        <strong>4. Parties and Sub-Contracting</strong>
      </Typography>
      <p>
        (1) The Customer warrants that he is either the owner of the Consignment or is authorised by
        such owner to accept these Conditions on such owner’s behalf.
      </p>
      <p>
        (2) The Carrier, and any other carrier employed by the Carrier, may employ the services of
        any other carrier for the purpose of fulfilling the Contract in whole or in part and the
        name of every other such carrier shall be provided to the Customer upon request. The Carrier
        may at any time assign, mortgage, charge, delegate, declare a trust over or deal in any
        other manner with any or all of its rights and obligations under the Contract, to the extent
        permitted by law.
      </p>
      <p>
        (3) The Carrier contracts for itself and as agent for its servants and agents and all other
        carriers referred to in (2) above and such other carriers ‘ servants and agents and every
        reference in these Conditions to “the Carrier ” shall be deemed to include every other such
        carrier, servant and agent with the intention that they shall have the benefit of the
        Contract and collectively and together with the Carrier be under no greater liability to the
        Customer or any other party than is the Carrier hereunder.
      </p>
      <p>
        (4) Notwithstanding Condition 2(3) the carriage of any Consignment by rail, sea, inland
        waterway or air is arranged by the Carrier as agent of the Customer to be carried out by a
        third party and shall be subject to the conditions of the third party rail, shipping, inland
        waterway or air carrier contracted to carry the Consignment. The Carrier shall be under no
        liability whatsoever to whomsoever and howsoever arising in respect of such carriage:
        provided that where the Consignment is carried partly by road and partly by such other means
        of transport any loss, damage or delay shall be deemed to have occurred while the
        Consignment was being carried by road unless the contrary is proved by the Carrier.
      </p>
      <br />

      <Typography>
        <strong>5. Dangerous Goods</strong>
      </Typography>
      <p>(1) The Carrier shall not be obliged to carry any Dangerous Goods or Consignments.</p>
      <p>
        (2) The Customer is responsible for ascertaining if the contents of any Consignment are
        Dangerous Goods, are prohibited or are subject to restrictions or specific requirements
        either within the UK or the country of destination for international deliveries.
      </p>
      <p>
        (3) The Customer must not send or attempt to send a Consignment containing any Dangerous
        Goods, prohibited or restricted goods via the Carrier without disclosing this information to
        the Carrier.
      </p>
      <p>
        (4) If the Customer does send or attempt to send Dangerous Goods, prohibited or restricted
        goods the Customer may be liable to prosecution and shall indemnify and keep indemnified the
        Carrier and its employees, contractors, subcontractors and agents, against any loss or
        damage suffered or liability incurred as a result of such actions.
      </p>
      <p>
        (5) If a Consignment containing any Dangerous Goods, prohibited or restricted goods is sent
        by the Customer, the Carrier may deal with the Consignment in its sole and absolute
        discretion (without incurring any liability whatsoever to the Customer or Recipient)
        including destroying or otherwise disposing of such Parcel or Consignment in whole or in
        part or returning the Consignment to the Customer, and shall be entitled to charge the
        Customer the cost of disposal and all other costs reasonably incurred and additionally the
        sum of £50, (or such sum as specified by the Carrier on its website) if it chooses to return
        the Consignment or any part of it.
      </p>
      <p>
        (6) The Carrier may, acting reasonably, add or remove items from the definition of
        prohibited goods or restricted goods (and may vary any applicable restrictions) without
        notice, by making the details of any such additions or deletions available on its website).
      </p>
      <p>
        (7) The Customer shall be liable to the Carrier its employees, subcontractors and agents for
        all loss, damage or injury arising out of the carriage of Dangerous Goods, prohibited or
        restricted goods, whether declared as such or not and all goods not properly packed and duly
        labelled (or not in compliance with any other specific requirements) to the extent that such
        loss, damage or injury is caused by the nature of those goods.
      </p>
      <p>
        (8) Dangerous Goods (prohibited and restricted goods) must be declared by the Customer and
        if the Carrier agrees to accept them for carriage they must be classified, packed, marked,
        labelled and documented in accordance with the statutory regulations for the carriage by
        road of the substance declared.
      </p>
      <p>
        (9) Transport Emergency Cards (Tremcards) or information in writing in the manner required
        by the relevant statutory provisions must be provided by the Customer in respect of each
        substance and must accompany the Consignment. The Carrier will ensure that the cards are
        appropriate to the load.
      </p>
      <p>(10) The Carrier reserves the right to open and inspect any Consignment.</p>
      <br />

      <Typography>
        <strong>6. Delivery</strong>
      </Typography>
      <p>
        (1) The Customer will ensure that the Consignment is properly and safely packed and secure
        and safe to be carried, stored and transported.
      </p>
      <p>
        (2) The Carrier will use all reasonable efforts to ensure Consignments are delivered in
        accordance with the time notified to the Customer, however, it is agreed that such times are
        estimates only and time shall not be of the essence for the purpose of this agreement.{' '}
      </p>
      <p>
        (3) When Consignments are to be collected from a Customer’s address the Customer will
        provide appropriate equipment and labour for loading the Consignment.
      </p>
      <p>
        (4) Delivery shall be deemed to be completed when the Carrier completes unloading of the
        Consignment to the Delivery Address, and a proof of delivery is obtained and a signature is
        obtained (“POD “). The driver who delivers the Consignment shall scan the POD and/or provide
        upload photographic evidence through his mobile app as required by the Carrier.
      </p>
      <br />

      <Typography>
        <strong>7. Loading and Unloading</strong>
      </Typography>
      <p>(1) Unless the Carrier has agreed in writing to the contrary with the Customer:</p>
      <p>
        (a) The Carrier shall not be under any obligation to provide any plant, power or labour,
        other than that carried by the vehicle, required for loading or unloading the Consignment.
      </p>
      <p>
        (b) The Customer warrants that any special appliances or instructions required for loading
        or unloading the Consignment which are not carried by the vehicle will be provided by the
        Customer or on the Customer’s behalf.
      </p>
      <p>
        (c) The Customer shall ensure that any cranes, fork lift trucks, slings, chains or other
        equipment used in loading or unloading the vehicle are suitable for that purpose and will
        indemnify the Carrier against any and all consequences of failure of or unsuitability of
        such equipment.
      </p>
      <p>
        (d) The Customer shall ensure that there is adequate access to the loading and the unloading
        points and that the roadways to and from the public highway are of suitable material and
        that unloading will take place on good sound hardstanding, where there will be sufficient
        space to load or unload the vehicle in safety.
      </p>
      <p>
        (e) The Carrier shall be under no liability whatsoever to the Customer for any damage
        whatsoever, howsoever caused, if the Carrier is instructed or provided with special
        appliances to load or unload any Consignment which, in breach of the warranty in (b) above,
        have not been provided by the Customer or on the Customer’s behalf.
      </p>
      <p>
        (f) The Carrier shall not be required to provide service beyond the usual place of
        collection or delivery but if any such service is provided by the Carrier it shall be at the
        sole risk of the Customer and subject to 7(3) below.
      </p>
      <p>
        (2) The Customer shall indemnify the Carrier against all claims and demands which could not
        have been made if such instructions as are referred to in (1)(c) of this Condition and such
        service as is referred to in (1)(d) of this Condition had not been given.
      </p>
      <p>
        (3) The Customer shall indemnify the Carrier against all costs, expenses, injuries, losses,
        liability damages, claims, proceedings or legal costs which the Carrier may suffer as a
        result of the provision of assistance with loading or unloading.
      </p>
      <p>
        (4) The Customer shall indemnify the Carrier against all liability or loss or damage
        suffered or incurred (including but not limited to damage to the Carrier’s vehicle) as a
        result of the Carrier’s personnel complying with the instructions of the Customer or the
        Consignee or their servants or agents.
      </p>
      <p>
        (5) The Customer shall make available to the Carrier upon request details of any risk
        assessments which may have been carried out at the collection and/or delivery The
        responsibility for carrying out such risk assessments shall be that of the Customer and not
        of the Carrier.
      </p>
      <br />

      <Typography>
        <strong>8. Obligations of the Customer</strong>
      </Typography>
      <p>The Customer warrants that:</p>
      <p>
        (1) the Consignment does not and will not cause pollution of the environment or harm to
        human health; require any official consent or licence to handle, possess, deal with or
        carry; at any time whilst in the care or control of the Carrier constitute waste (unless the
        Carrier has been previously advised otherwise); and that the Consignment is of a nature that
        can be legally transported in the United Kingdom;
      </p>
      <p>
        (2) it will comply, and will procure that all of its agents, employees and sub-contractors
        also comply, with any reasonable regulations of the Carrier relating to handling, health and
        safety, and security, of which they are notified or have been notified; and
      </p>
      <p>
        (3) it will provide the Carrier with such information and materials as the Carrier may
        reasonably require in order to comply with its obligations under the Contract, and will
        ensure that such information is complete and accurate in all material respects.
      </p>
      <p>
        (4) If the Carrier’s performance of any of its obligations under the Contract is prevented,
        hindered or delayed by any act or omission of the Customer or by any failure by the Customer
        to perform any relevant obligation (<strong>“Customer Default”</strong>), then
      </p>
      <p>
        (a) without limiting or affecting any other right or remedy available to it, the Carrier
        shall have the right to suspend performance of its obligations until the Customer remedies
        the Customer Default, and may rely on the Customer Default to relieve it from the
        performance of any of its obligations in each case to the extent the Customer Default
        prevents, hinders or delays the Supplier’s performance of any of its obligations;
      </p>
      <p>
        (b) the Carrier shall not be liable for any costs or losses sustained or incurred by the
        Customer arising directly or indirectly from the Carrier’s failure to perform or delay in
        performing any of its obligations as set out in this Condition 8(4); and
      </p>
      <p>
        (c) the Customer shall on written demand reimburse the Carrier for any costs or losses
        sustained or incurred by the Carrier arising directly or indirectly from the Customer
        Default.
      </p>
      <br />

      <Typography>
        <strong>9. Consignment Notes and Signed Receipts</strong>
      </Typography>
      <p>
        (1) The Carrier shall, if so required, sign a document prepared by the sender acknowledging
        the receipt of the Consignment but no such document shall be evidence of the condition or of
        the correctness of the declared nature, quantity, or weight of the Consignment at the time
        it is received by the Carrier and the burden of proving the condition of the Consignment on
        receipt by the Carrier and that the Consignment was of the nature, quantity or weight
        declared in the relevant document shall rest with the Customer.
      </p>
      <p>
        (2) The Customer shall, prior to or upon the completion of loading the Consignment, sign and
        forthwith deliver to the Carrier a consignment note stating:
      </p>
      <p>(a) The Collection address, the Consignee and the Delivery Address;</p>
      <p>
        (b) A complete and accurate description of the nature of the goods within the Consignment
        and the method of packing;
      </p>
      <p>(c) The number of items, parcels, packages and/or containers in the Consignment;</p>
      <p>
        (d) The gross weight of the goods within the Consignment or their quality otherwise
        expressed and
      </p>
      <p>(e) Any other information the Carrier may reasonably require.</p>
      <br />

      <Typography>
        <strong>10. Transit</strong>
      </Typography>
      <p>
        (1) Unless otherwise agreed expressly between the parties, transit shall commence when the
        Carrier takes possession of the Consignment whether at the point of collection or at the
        Carrier’s premises.
      </p>
      <p>
        (2) Transit shall (unless otherwise previously determined) end when the Consignment is
        tendered at the Delivery Address provided that;
      </p>
      <p>
        (a) if no safe and adequate access or no adequate unloading facilities there exist and the
        Consignment cannot be delivered then transit shall be deemed to end at the expiry of one
        clear day after notice in writing (or by telephone) of the arrival of the Consignment at the
        Carrier ‘s premises has been sent to the Consignee; and
      </p>
      <p>
        (b) when for any other reason whatever a Consignment cannot be delivered or when a
        Consignment is held by the Carrier ‘to await order ‘ or to be ‘kept till called for ‘or upon
        any like instructions and such instructions are not given or the Consignment is not called
        for and removed within a reasonable time, then transit shall be deemed to end at the expiry
        of that reasonable time.
      </p>
      <p>
        (3) The Customer shall ensure that the Consignment is securely and properly packed and
        labelled in accordance with any relevant legislation and in such a condition that it is not
        likely to cause injury or damage to person or property.
      </p>
      <p>
        (4) The Customer shall ensure that no loss or damage to any of the Carrier’s vehicles or
        trailers occurs whilst at the collection or delivery premises and shall be liable for any
        such damage.
      </p>
      <p>
        (5) The Consignment shall be at the sole risk of the Customer at all times when the
        Consignment is not in transit.
      </p>
      <br />

      <Typography>
        <strong>11. Undelivered or Unclaimed Consignments</strong>
      </Typography>
      <p>
        (1) When the Carrier is unable to effect delivery the Carrier shall use its reasonable
        endeavours to notify the Customer and the Consignee of any undelivered or unclaimed
        Consignment. The Consignment will be removed from the Carrier’s vehicle the next day and
        stored at a location of the Carrier’s choosing (additional charges may be incurred for
        storage and charged to the Customer). Unless the Consignment is collected by the Customer or
        instructions are provided for return of further delivery (for which there will be an
        additional charge) within 10 business days of notice being given to the Customer, the
        provisions in clause 19 (Lien) will prevail over the Consignment and the Carrier may sell or
        destroy the Consignment as if it were the original owner. Any charges incurred by the
        Carrier in selling or destroying the Consignment may be charged to the Customer.
      </p>
      <p>
        (2) The Carrier shall use its reasonable endeavours to obtain what is in its view a
        reasonable price for the Consignment and shall use the proceeds of sale to discharge the
        Carrier’s expenses incurred in relation to the carriage, storage and sale or disposal of the
        Consignment. Any remaining amounts will be charged to the Customer and any proceeds will be
        paid over to the Customer upon its written request, upon which the Carrier shall be
        discharged from all liability in respect of the Consignment.
      </p>
      <br />

      <Typography>
        <strong>12. Carrier’s Charges</strong>
      </Typography>
      <p>
        (1) The Carrier’s charges shall be payable by the Customer without prejudice to the
        Carrier’s rights against the Consignee or any other person provided that when any
        Consignment is consigned ‘carriage forward’ the Customer shall not be required to pay such
        charges unless the Consignee fails to pay after a reasonable demand has been made by the
        Carrier for payment thereof.
      </p>
      <p>
        (2) Charges shall be payable when due without deduction or deferment on account of any
        claim, counterclaim or set-off. The Customer shall pay the Carrier within 30 days month end,
        date of invoice and the Carrier shall be entitled, without prejudice to any other right, to
        interest at 8% above Bank of England base rate.
      </p>
      <p>
        (3) Unless otherwise agreed with the Customer at the time of the booking, the Carrier shall
        not be required to obtain a signed or any other type of proof of delivery (including
        photographic proof) of the Consignment from the Consignee. Where the Carrier does agree at
        the time of booking to obtain such proof of delivery no payment shall however be withheld by
        the Customer where the Carrier is unable to provide a proof of delivery unless notification
        of non-delivery is received by the Carrier no more than 48 hours after the expected time of
        delivery of the Consignment and the Carrier is subsequently unable to evidence proof of
        delivery.
      </p>
      <p>
        (4) The Customer shall pay to the Carrier any storage charges incurred as a result of it
        exercising its lien in accordance with clause 19 below.
      </p>
      <p>
        (5) The Customer is entitled to cancel the collection of a Consignment at any time before
        the agreed collection time. If the Customer cancels the collection less than an hour before
        the agreed time the Carrier reserves the right to charge a cancellation fee equivalent to
        100% of the total agreed charge.
      </p>
      <p>
        (6) If the Consignment is not ready at the agreed collection time the Carrier reserves the
        right to charge such fees as notified in the quotation provided, for every 15 minutes or
        part thereof up until the time the Consignment is ready for loading after the period agreed
        in the quotation.
      </p>
      <p>
        (7) If the Consignee has not completed the loading or unloading of a Consignment within 15
        minutes of arrival of the Carrier at either the loading address or the delivery address then
        the Carrier reserves the right to charge such fees as notified in the quotation provided for
        every 15 minutes after the agreed period within the quote until the Consignment unloading is
        complete.
      </p>
      <p>
        (8) The Carrier ‘s charges are subject to adjustment by the Carrier to account for any
        variation in its costs including (but not limited to) variations in wages, cost of
        materials, fuel cost, exchange rate fluctuations, alteration of duties, Local Authority
        rates, National Insurance and other employment costs, VAT and other costs.
      </p>
      <p>
        (9) In the event that the Carrier provides services in addition to those originally agreed
        including (without limitation) providing services outside working hours, making deliveries
        to locations other than the Delivery Address or outside the time at which the Carrier is to
        collect or deliver the Consignment, the Carrier shall be entitled to be paid by the Customer
        such additional amount as represents the additional cost incurred together with a management
        charge (up to £100).
      </p>
      <p>
        (10) The Customer shall not be entitled to withhold, deduct or set off against any amount
        due to the Carrier any sum which it alleges is due to it from the Carrier.
      </p>
      <p>
        (11) The Customer is responsible for ensuring that it has paid the appropriate If at any
        time the Carrier determines that the Customer has not paid the appropriate charges then the
        Customer shall be liable to the Carrier for the difference between what the Customer
        initially paid and the amount which the Customer should have paid. The Carrier may at is
        discretion suspend any account that the Customer has with the Carrier until any unpaid
        amount is repaid, as well as take any other legal action the Carrier is entitled to in order
        to recover any unpaid amounts.
      </p>
      <br />

      <Typography>
        <strong>13. Cancellations</strong>
      </Typography>
      <p>
        (1) The Customer may cancel the order up to 24 hours before and receive a full refund by
        contacting the Carrier and providing the relevant order number connected to the Consignment.
      </p>
      <p>
        (2) If an order is cancelled within 24 hours of collection, the Carrier will endeavour to
        cancel the order and issue a refund where possible. If however, the Carrier is unable to
        cancel delivery without incurring costs, those costs shall be passed on to the Customer and
        in any case the Customer shall be unable to cancel the order and shall not be entitled to a
        refund if the Carrier has collected the Consignment or it has been dropped off/the label has
        been used.
      </p>
      <br />

      <Typography>
        <strong>14. Liability for Loss and Damage</strong>
      </Typography>
      <p>
        (1) The Customer shall be deemed to have elected to accept the terms set out in sub-clauses
        (2) and (3) unless, before the transit commences, the Customer has agreed in writing that
        the Carrier shall be under no liability for loss of, or mis-delivery of or damage to or in
        connection with the Consignment, howsoever or whensoever caused.
      </p>
      <p>
        (2) Subject to this clause 14, the Carrier shall only be liable for loss or damage to or in
        connection with the Consignment howsoever or whensoever caused and whether or not caused or
        contributed to directly or indirectly by any act, omission, neglect, default or other
        wrongdoing on the part of the Carrier, its employees, contractors, subcontractors or agents
        if and to the extent that the Carrier has been negligent.
      </p>
      <p>(3) Subject to these Conditions the Carrier shall be liable for:</p>
      <p>
        (a) physical loss, mis-delivery of or damage to living creatures, bullion, money,
        securities, stamps, precious metals or precious stones comprising the Consignment only if:
      </p>
      <p>(i) the Carrier has specifically agreed in writing to carry any such items; and</p>
      <p>
        (ii) the Customer has agreed in writing to reimburse the Carrier in respect of all
        additional costs which result from the carriage of the said items; and
      </p>
      <p>
        (iii) the loss, mis-delivery or damage is occasioned during transit and is proved to be due
        to the negligence of the Carrier, its employees, contractors, subcontractors or agents;
      </p>
      <p>
        (b) physical loss, mis-delivery of or damage to any other goods comprising the Consignment
        unless the same has arisen from a “Force Majeure Event” which shall mean any act(s),
        event(s), circumstances(s) or cause(s) the occurrence of which is beyond the reasonable
        control of the Carrier, including but not limited to:
      </p>
      <p>
        (i) any consequences of war, invasion, act of foreign enemy, hostilities (whether war or
        not), civil war, rebellion, insurrection, terrorist act, military or usurped power or
        confiscation, requisition, or destruction or damage by or under the order of any government
        or public or local authority;
      </p>
      <p>(ii) seizure or forfeiture under legal process;</p>
      <p>
        (iii) error, act, omission, mis-statement or misrepresentation by the Customer or other
        owner of the Consignment or by servants or agents of either of them;
      </p>
      <p>
        (iv) inherent liability to wastage in bulk or weight, faulty design, latent defect or
        inherent defect, vice or natural deterioration of the Consignment;
      </p>
      <p>
        (v) any special handling requirements in respect of the Consignment which have not been
        notified to the Carrier;
      </p>
      <p>(vi) insufficient or improper packing, labelling or addressing;</p>
      <p>
        (vii) riot, civil commotion, strike, lockout, general or partial stoppage or restraint of
        labour from whatever cause;
      </p>
      <p>(viii) fire, flood, storm, earthquake, pandemic, or epidemic;</p>
      <p>
        (ix) road congestion, road accidents, delays incurred at any delivery location or lack of
        delivery instructions from the Customer, vehicle breakdown;
      </p>
      <p>
        (x) Consignee not taking or accepting delivery within a reasonable time after the
        Consignment has been tendered.
      </p>
      <p>
        (4) The Carrier shall not in any circumstances be liable for loss or damage arising after
        transit is deemed to have ended within the meaning of these conditions, whether or not
        caused or contributed to directly or indirectly by any act, omission, neglect, default or
        other wrongdoing on the part of the Carrier, its servants, agents or sub-contractors.
      </p>
      <p>
        (5) The Carrier shall not be liable for any loss or deterioration of, or damage to, or
        non-delivery, mis-delivery of any property (including the Consignment) or any other claim in
        any circumstances whatsoever, howsoever caused save to the extent that the same is caused by
        its wilful default or negligence.
      </p>
      <p>
        (6) Unless agreed otherwise, the Carrier shall not be obliged to insure the Consignment, and
        where it does so, will insure on the conditions set out by BIFA (British International
        Freight Association) and CMR. The Customer is responsible for insuring against all risks for
        the full insurable value unless cost price cover of the item agreed with carrier.
      </p>
      <br />

      <Typography>
        <strong>15. Fraud</strong>
      </Typography>
      <p>
        The Carrier shall not in any circumstances be liable in respect of a Consignment where there
        has been fraud on the part of the Customer or the owner, or the servants or agents of
        either, in respect of that Consignment, unless the fraud has been contributed to by the
        complicity of the Carrier or of any servant of the Carrier acting in the course of his
        employment.
      </p>
    </Container>
  )
}

export default TermsAndConditions
