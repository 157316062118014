import { useMemo } from 'react'

import { useReactiveVar } from '@apollo/client'
import { GridCellParams, GridValidRowModel, GridValueGetterParams } from '@mui/x-data-grid'
import clsx from 'clsx'
import { format, parseJSON } from 'date-fns'

import { IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, StatusCategories } from 'constants/index'
import { BookingAddressType, BookingStatus } from 'generated/graphql'
import { bookingsStatusCategoryVar } from 'graphql/reactiveVariables'
import { numberFormat } from 'helpers'
import { usePermission } from 'providers'

function useGetBookingsTableColumns() {
  const bookingsStatusCategory = useReactiveVar(bookingsStatusCategoryVar)
  const [isAllowedToViewBookings] = usePermission('ViewBookingsList')

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'ourReference', headerName: 'Booking Ref', width: 110, sortPath: ['id'] },
      {
        field: 'salesInvoiceNumber',
        headerName: 'Invoice #',
        width: 100,
        sortable: false,
        // ! Deprecated
        hide: bookingsStatusCategory !== StatusCategories.Complete,
        valueGetter: ({ value }) => (value === 0 ? 'N/A' : value),
      },
      {
        field: 'statusLabel',
        headerName: 'Status',
        width: 180,
        sortPath: ['status'],
        cellClassName: (params: GridCellParams<GridValidRowModel>) =>
          clsx({ allocated: params.row.status === BookingStatus.Allocated }),
      },
      ...(isAllowedToViewBookings
        ? [
            {
              field: 'courierServicesStatusLabel',
              headerName: 'Courier Services',
              width: 140,
              sortable: false,
            },
          ]
        : []),
      {
        field: 'collectionEstimatedTimeOfArrival',
        headerName: 'Collection ETA',
        minWidth: 150,
        valueFormatter: ({ value }) =>
          value ? format(parseJSON(value as string), DATE_TIME_FORMAT) : '',
      },
      {
        field: 'customer',
        headerName: 'Customer',
        width: 300,
        sortPath: ['customer', 'name'],
        valueGetter: ({ value }) => value?.name,
      },
      { field: 'customerReference', headerName: 'Customer Ref', width: 150 },
      {
        field: 'collectionFrom',
        headerName: 'Collection From',
        width: 210,
        sortable: false,
        valueGetter: ({ row }) => {
          if (row.addresses.length === 0) {
            return ''
          }

          const collectionAddresses = row.addresses.filter(
            (address) => address.type === BookingAddressType.Collection,
          )

          if (collectionAddresses) {
            if (collectionAddresses.length === 1) {
              return `${collectionAddresses[0].address?.city}, ${collectionAddresses[0].address?.postcode}`
            } else if (collectionAddresses.length > 1) {
              return `Multiple (${collectionAddresses.length})`
            }
          }
        },
      },
      {
        field: 'deliveryTo',
        headerName: 'Delivery To',
        flex: 1,
        minWidth: 210,
        sortable: false,
        valueGetter: ({ row }) => {
          if (row.addresses.length === 0) {
            return ''
          }

          const deliveryAddresses = row.addresses.filter(
            (address) => address.type === BookingAddressType.Delivery,
          )

          if (deliveryAddresses) {
            if (deliveryAddresses.length === 1) {
              return `${deliveryAddresses[0].address?.city}, ${deliveryAddresses[0].address?.postcode}`
            } else if (deliveryAddresses.length > 1) {
              return `Multiple (${deliveryAddresses.length})`
            }
          }
        },
      },
      {
        field: 'deliveryEstimatedTimeOfArrival',
        headerName: 'Delivery ETA',
        type: 'dateTime',
        minWidth: 150,
        valueFormatter: ({ value }) =>
          value ? format(parseJSON(value as string), DATE_TIME_FORMAT) : '',
      },
      {
        field: 'xeroCode',
        headerName: 'Service Code',
        width: 160,
        sortPath: ['tariff', 'xeroCode', 'name'],
        valueGetter: ({ row }) => row?.tariff?.xeroCode?.name,
      },
      {
        field: 'tariff',
        headerName: 'Tariff',
        width: 150,
        sortPath: ['tariff', 'name'],
        hide: true,
        valueGetter: ({ value }) => value?.name,
      },
      {
        field: 'tariffCategory',
        headerName: 'Tariff Category',
        width: 160,
        sortPath: ['tariff', 'tariffCategory', 'name'],
        valueGetter: ({ row }) => row?.tariff?.tariffCategory?.name,
      },
      ...(isAllowedToViewBookings
        ? [
            {
              field: 'drivers',
              headerName: 'Driver',
              width: 150,
              sortable: false,
              valueGetter: ({ value }: GridValueGetterParams<any, any>) => {
                if (value.length === 0) {
                  return ''
                }

                if (value.length === 1) {
                  return value[0]?.driver.name
                } else if (value.length > 1) {
                  return `Multiple (${value.length})`
                }
              },
            },
            {
              field: 'isUsingInternalApp',
              headerName: 'Using MobileApp',
              type: 'boolean',
              width: 140,
              sortable: false,
            },
            {
              field: 'revenue',
              headerName: 'Revenue',
              type: 'number',
              width: 100,
              valueFormatter: ({ value }) => (value ? numberFormat(value, 0) : ''),
            },
          ]
        : []),
      {
        field: 'docs',
        headerName: 'Docs',
        type: 'number',
        width: 100,
      },
      {
        field: 'hasTasks',
        headerName: 'Tasks',
        type: 'boolean',
        width: 80,
        sortable: false,
      },
      {
        field: 'hasNotes',
        headerName: 'Notes',
        type: 'boolean',
        width: 80,
        sortable: false,
      },
      {
        field: 'takenBy',
        headerName: 'Booked By',
        width: 150,
      },
      {
        field: 'takenOn',
        headerName: 'Booked On',
        minWidth: 150,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
    ],
    [isAllowedToViewBookings, bookingsStatusCategory],
  )

  return columns
}

export default useGetBookingsTableColumns
