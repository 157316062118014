import { Box, Grid } from '@mui/material'

import DriverVehicleDetails from './DriverVehicleDetails'
import InsuranceDetails from './InsuranceDetails'

const VehicleForm = () => {
  return (
    <Box sx={{ padding: '5px 0' }}>
      <Grid container spacing={3}>
        <Grid item>
          <DriverVehicleDetails />
        </Grid>
        <Grid item>
          <InsuranceDetails />
        </Grid>
      </Grid>
    </Box>
  )
}

export default VehicleForm
