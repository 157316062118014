import { useContext } from 'react'

import { DialogContext } from 'providers'

import SpecialTariffDialog from '../Notifications/SpecialTariffDialog'
import { CreateUpdateTask } from '../TasksAndHistory/Tasks/CreateUpdateTask'

const TaskDialogContainer = () => {
  const context = useContext(DialogContext)
  const handleOnDialogClose = () => {
    context.setOpenDialog(false)
  }

  if (context.options && 'description' in context.options)
    return (
      <SpecialTariffDialog
        openDialog={context.openDialog}
        onCloseDialog={handleOnDialogClose}
        description={context.options.description}
      />
    )

  return (
    <CreateUpdateTask
      {...context.options}
      openDialog={context.openDialog}
      onCloseDialog={handleOnDialogClose}
    />
  )
}

export default TaskDialogContainer
