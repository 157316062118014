import { format, parseJSON } from 'date-fns'

import { IGridColumn } from 'components/common'
import { DATE_FORMAT } from 'constants/index'

const formateDate = (date: string) => {
  return date ? format(parseJSON(date as string), DATE_FORMAT) : '-'
}

export function useGetInvoiceBatchesColummns() {
  const columns: IGridColumn[] = [
    {
      field: 'id',
      headerName: 'Batches (ID)',
      flex: 0.2,
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      flex: 0.2,
      valueGetter: ({ row }) => formateDate(row?.paymentDate),
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      flex: 0.2,
      valueGetter: ({ row }) => formateDate(row?.createdAt),
    },
    {
      field: 'lastModifiedAt',
      headerName: 'Last modified Date',
      flex: 0.2,
      valueGetter: ({ row }) => formateDate(row?.lastModifiedAt),
    },
    {
      field: 'currency',
      headerName: 'Currency',
      sortPath: ['currency', 'code'],
      flex: 0.2,
      valueGetter(params) {
        return params.row.currency.code
      },
    },
    {
      field: 'isSentToXero',
      headerName: 'Sent to Xero?',
      flex: 0.2,
      valueGetter(params) {
        const isSentToXero = params.row.isSentToXero
        return isSentToXero ? 'Yes' : 'No'
      },
    },
    {
      field: 'isSuccess',
      headerName: 'Is successful?',
      flex: 0.2,
      valueGetter(params) {
        const isSuccess = params.row.isSuccess
        return isSuccess ? 'Yes' : 'No'
      },
    },
  ]

  return columns
}
