import { Typography } from '@mui/material'

const PobFormHeader = () => {
  return (
    <Typography paragraph variant='h4'>
      Package On Board (POB)
    </Typography>
  )
}

export default PobFormHeader
