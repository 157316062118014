import { useMemo } from 'react'

import { BookingsTabId } from 'constants/Tabs'

interface Tab {
  id: BookingsTabId
  label: string
  hash: string
}

interface Tabs {
  [key: string]: Tab
}

const allTabs: Tabs = {
  bookingDetails: {
    id: BookingsTabId.Details,
    label: 'Booking Details',
    hash: '#booking-details',
  },
  mapRoute: {
    id: BookingsTabId.MapRoute,
    label: 'Map Route',
    hash: '#map-route',
  },
  attachments: {
    id: BookingsTabId.Attachments,
    label: 'Attachments',
    hash: '#attachments',
  },
  postLoad: {
    id: BookingsTabId.PostLoad,
    label: 'Post Load or Book Direct',
    hash: '#post-load',
  },  
  allocateBids: {
    id: BookingsTabId.AllocateBids,
    label: 'Allocate Bids',
    hash: '#allocate-bids',
  },
  allocateCXMember: {
    id: BookingsTabId.AllocateCXMember,
    label: 'Allocate CX Member',
    hash: '#allocate-cx-member',
  },
  logs: {
    id: BookingsTabId.Logs,
    label: 'Logs',
    hash: '#logs',
  },
  audit: {
    id: BookingsTabId.Audit,
    label: 'Audit',
    hash: '#audit',
  },
  tasks: {
    id: BookingsTabId.Tasks,
    label: 'Tasks',
    hash: '#tasks',
  },
  scheduledTemplate: {
    id: BookingsTabId.ScheduledTemplate,
    label: 'Scheduled Template',
    hash: '#scheduled-template',
  },
}

const useBookingAllTabs = () => {
  return useMemo(() => allTabs, [])
}

export { useBookingAllTabs }
