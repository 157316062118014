import { gql } from '@apollo/client'

import { TaskSettingDetailsFragment } from 'graphql/fragments/TaskSettingFragments'

export const GET_TASK_SETTINGS = gql`
  query GetTaskSettings($where: SystemTaskSettingFilterInput){
    taskSettings(where:$where){
      id
      ...TaskSettingDetails
    }
    ${TaskSettingDetailsFragment}
  }
`
