import { useCallback, useState } from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledPostCode, ControlledTextField } from 'components/common'
import {
  ControlledCountryAutocomplete,
  ControlledTelephoneNumWithCountryCode,
} from 'components/common/controller-wrapper'
import { CardTitle } from 'components/common/controls'
import { AutoFillAddressDialog } from 'components/common/Dialog'
import {
  Address,
  CreateAndUpdateDriverDtoInput,
  useGetLoqateRetrieveLazyQuery,
} from 'generated/graphql'

const ContactDetails = () => {
  const [openAutoFillAddress, setOpenAutoFillAddress] = useState(false)
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()
  const postcode = watch('postcode')
  const countryId = watch('countryId')
  const spacing = 2
  //Queries
  // ToDo: add loading
  const [getLoqateRetrieve] = useGetLoqateRetrieveLazyQuery({
    onCompleted: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetContactDetails(data.loqateRetrieve as any)
    },
  })

  const handleAutoFillClick = () => {
    setOpenAutoFillAddress(true)
  }

  const handleAutoFillAddressSelect = (id: string) => {
    setOpenAutoFillAddress(false)
    getLoqateRetrieve({ variables: { id } })
  }

  const resetContactDetails = useCallback(
    (address: Address) => {
      setValue('addressLine1', address.addressLine1)
      setValue('addressLine2', address.addressLine2)
      setValue('city', address.city)
      setValue('countryId', address.countryId)
      setValue('postcode', address.postcode)
      address.telephoneNumber && setValue('telephoneNumber', address.telephoneNumber)
      address.email && setValue('email', address.email)
    },
    [setValue],
  )

  return (
    <>
      <CardTitle>Contact Details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid container item spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='First Name'
              required
              name='firstName'
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Last Name'
              required
              name='lastName'
              error={!!errors.lastName?.message}
              helperText={errors.lastName?.message}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Address 1'
              required
              name='addressLine1'
              error={!!errors.addressLine1}
              helperText={errors.addressLine1?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Address 2'
              name='addressLine2'
              error={!!errors.addressLine2?.message}
              helperText={errors.addressLine2?.message}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              required
              label='Town/City'
              name='city'
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledCountryAutocomplete
              control={control}
              error={!!errors.countryId}
              helperText={errors.countryId?.message}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <ControlledPostCode
              control={control}
              name='postcode'
              onAutoFillClick={handleAutoFillClick}
              required
              error={!!errors.postcode}
              helperText={errors.postcode?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTelephoneNumWithCountryCode
              control={control}
              name='telephoneNumber'
              required
              label='Telephone Number'
              error={!!errors.telephoneNumber}
              helperText={errors.telephoneNumber?.message}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              name='email'
              label='Email'
              type='email'
              required
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTelephoneNumWithCountryCode
              control={control}
              name='alternativeTelephoneNumber'
              label='Alternative Telephone Number'
              error={!!errors.alternativeTelephoneNumber}
              helperText={errors.alternativeTelephoneNumber?.message}
            />
          </Grid>
        </Grid>
      </Grid>

      {openAutoFillAddress && (
        <AutoFillAddressDialog
          open={openAutoFillAddress}
          setOpen={setOpenAutoFillAddress}
          postcode={postcode}
          countryId={countryId}
          onSelectAddress={handleAutoFillAddressSelect}
        />
      )}
    </>
  )
}

export default ContactDetails
