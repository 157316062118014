import { gql } from '@apollo/client'

import { TemplateDetailsFragment } from 'graphql/fragments/ScheduledBookingFragments'

export const CREATE_SCHEDULED_BOOKING_TEMPLATE_MUTATION = gql`
  mutation CreateScheduledBookingTemplate($bookingId: Long!) {
    createScheduledBookingTemplate(bookingId: $bookingId) {
      id
      ...TemplateDetails
    }
    ${TemplateDetailsFragment}
  }
`

export const UPDATE_SCHEDULED_BOOKING_TEMPLATE_MUTATION = gql`
  mutation UpdateScheduledBookingTemplate($input: UpdateScheduledBookingTemplateDtoInput!) {
    updateScheduledBookingTemplate(input: $input) {
      id
      ...TemplateDetails
    }
    ${TemplateDetailsFragment}
  }
`

export const CREATE_SCHEDULED_BOOKING_DATE_MUTATION = gql`
  mutation CreateScheduledBookingDate($input: [CreateScheduledBookingDateDtoInput!]!) {
    createScheduledBookingDate(input: $input) {
      id
      executeOnDate
      taskId
    }
  }
`

export const DELETE_SCHEDULED_BOOKING_DATE_MUTATION = gql`
  mutation DeleteScheduledBookingDate($dateId: Long!) {
    deleteScheduledBookingDate(dateId: $dateId)
  }
`
