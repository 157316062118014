import { ReactElement } from 'react'

import { Box } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRowsProp } from '@mui/x-data-grid'

import CustomLoadingOverlay from 'components/common/FplDataGrid/CustomLoadingOverlay'
import CustomToolbar from 'components/common/FplDataGrid/CustomToolbar'

interface IProps {
  rows: GridRowsProp
  columns: GridColDef[]
  loading?: boolean
  toolbar?: {
    caption?: string
    refreshButton?: boolean
    leftSide?: ReactElement
    rightSide?: ReactElement
  }
  onRowClick?: GridEventListener<'rowClick'>
}

const ClientDataGrid = (props: IProps) => {
  const { rows, columns, loading, toolbar, onRowClick } = props

  return (
    <Box sx={{ width: 1 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        loading={loading}
        disableColumnFilter
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
        }}
        componentsProps={{
          toolbar: {
            caption: toolbar?.caption,
            refreshButton: toolbar?.refreshButton,
            leftSide: toolbar?.leftSide,
            rightSide: toolbar?.rightSide,
          },
        }}
        onRowClick={onRowClick}
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 50]}
      />
    </Box>
  )
}

export default ClientDataGrid
