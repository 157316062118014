import { useState } from 'react'

import { AppBar, Box, Tab, Tabs, Tooltip } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

import { TabPanel } from 'components/common'
import { PARAM_NEW, Mode } from 'constants/index'
import { CloneProps, isNumber, useHashToSelectTab } from 'helpers'

import { Contacts } from './Contacts'
import { StrategicPartnerDetails } from './StrategicPartnerDetails'

enum TabId {
  Details = 0,
  Contacts,
}

const tabsList = [
  { id: TabId.Details, label: 'Strategic Partner Details', hash: '#strategic-partner-details' },
  { id: TabId.Contacts, label: 'Contacts', hash: '#contacts' },
]

const primaryTab = tabsList[0]
const secondaryTabs = tabsList.filter((_, index) => index !== 0)

function commonProps(index: TabId) {
  return {
    id: `strategic-partner-tab-${index}`,
    'aria-controls': `strategic-partner-tabpanel-${index}`,
  }
}

const StrategicPartnerTab = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)
  const { id } = useParams<{ id?: string }>()

  useHashToSelectTab(tabsList, setTabValue)

  let mode: number | undefined

  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const strategicPartnerId = mode === Mode.Update && id ? Number(id) : null

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          aria-label='strategic partner tabs'
          TabIndicatorProps={{
            style: {
              transition: 'none',
            },
          }}>
          <Tab
            label={primaryTab.label}
            key={primaryTab.id}
            {...commonProps(primaryTab.id)}
            component={Link}
            to={{ hash: primaryTab.hash }}
          />
          {mode === Mode.Create ? (
            <CloneProps>
              {(tabProps) => (
                <Tooltip title='Available only for existing strategic partner.'>
                  <Box component='span'>
                    {secondaryTabs.map((tab) => (
                      <Tab
                        {...tabProps}
                        label={tab.label}
                        key={tab.id}
                        {...commonProps(tab.id)}
                        disabled
                      />
                    ))}
                  </Box>
                </Tooltip>
              )}
            </CloneProps>
          ) : (
            secondaryTabs.map((tab) => (
              <Tab
                label={tab.label}
                key={tab.id}
                {...commonProps(tab.id)}
                component={Link}
                to={{ hash: tab.hash }}
              />
            ))
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={TabId.Details}>
        <StrategicPartnerDetails strategicPartnerId={strategicPartnerId} mode={mode} />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.Contacts}>
        <Contacts strategicPartnerId={strategicPartnerId!} />
      </TabPanel>
    </div>
  )
}

export default StrategicPartnerTab
