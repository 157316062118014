import { useState } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { Tabs, Tab, AppBar } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { TabPanel } from 'components/common'
import { Roles } from 'constants/index'
import { CustomerOption, useGetCurrentCustomerOptionsQuery } from 'generated/graphql'
import { CloneProps, useHashToSelectTab } from 'helpers'
import { PermissionType, Restricted, usePermission } from 'providers'

import { PotentialBookingTab } from './PotentialBookings'
import { ReasonCodes } from './ReasonCodes'

enum TabId {
  ReasonCodes = 0,
  BookingImport,
  Blank = 999,
}

const tabsList = [
  {
    id: TabId.ReasonCodes,
    label: 'Reason Codes',
    hash: '#reason-codes',
    policy: 'ViewReasonCodesTab' as PermissionType,
    option: CustomerOption.ReasonCode,
  },
  {
    id: TabId.BookingImport,
    label: 'Booking Import',
    hash: '#booking-import',
    policy: 'ViewBookingImportTab' as PermissionType,
    option: CustomerOption.BookingImport,
  },
  {
    id: TabId.Blank,
    label: '',
    hash: '#_',
    policy: 'ViewOptions' as PermissionType,
    option: CustomerOption.None,
  },
]

function commonProps(index: TabId) {
  return {
    id: `options-tab-${index}`,
    'aria-controls': `options-tabpanel-${index}`,
  }
}

const Options = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)

  const history = useHistory()
  useHashToSelectTab(tabsList, setTabValue)

  const handleTabChange = (_, newValue: TabId) => {
    const selectedTab = tabsList.find((tab) => tab.id === newValue)

    if (selectedTab) {
      history.push(selectedTab.hash)
    }
  }

  const { idTokenPayload } = useOidcIdToken()
  const isCustomer = idTokenPayload.role === Roles.customer

  const [isAllowedToViewCurrentCustomerOptions] = usePermission('ViewCurrentCustomerOptions')

  const { data } = useGetCurrentCustomerOptionsQuery({
    skip: !isAllowedToViewCurrentCustomerOptions,
    onCompleted: (response) => {
      if (!response) {
        toast.error('Something went wrong.')
        return
      }

      const options = response.currentCustomerOptions
      const tabs = tabsList.filter((tab) => options.includes(tab.option))

      handleTabChange(null, tabs[0].id)
    },
  })

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='options tabs'
          variant='scrollable'>
          {tabsList.map((tab) => (
            <CloneProps key={tab.id}>
              {(tabProps) => (
                <Restricted to={tab.policy}>
                  {(!isCustomer || data?.currentCustomerOptions.includes(tab.option)) && (
                    <Tab {...tabProps} {...commonProps(tab.id)} label={tab.label} value={tab.id} />
                  )}
                </Restricted>
              )}
            </CloneProps>
          ))}
        </Tabs>
      </AppBar>

      <Restricted to='ViewReasonCodesTab'>
        {(!isCustomer || data?.currentCustomerOptions.includes(CustomerOption.ReasonCode)) && (
          <TabPanel value={tabValue} index={TabId.ReasonCodes} paddingX={0}>
            <ReasonCodes />
          </TabPanel>
        )}
      </Restricted>

      <Restricted to='ViewBookingImportTab'>
        {(!isCustomer || data?.currentCustomerOptions.includes(CustomerOption.BookingImport)) && (
          <TabPanel value={tabValue} index={TabId.BookingImport} paddingX={0}>
            <PotentialBookingTab />
          </TabPanel>
        )}
      </Restricted>

      <Restricted to='ViewOptions'>
        {(!isCustomer || data?.currentCustomerOptions.includes(CustomerOption.None)) && (
          <TabPanel value={tabValue} index={TabId.Blank} paddingX={0}>
            {/* Intentionally left blank */}
          </TabPanel>
        )}
      </Restricted>
    </div>
  )
}

export default Options
