import { useEffect, useState } from 'react'

import {
  AccountBox,
  Dashboard,
  FactCheck,
  Help,
  InsertChart,
  LocalShipping,
  PeopleAlt,
  Settings,
  SettingsApplications,
  ShoppingCart,
  Work,
} from '@mui/icons-material'
import { Divider, List } from '@mui/material'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Paths, Roles } from 'constants/index'
import { CustomerOption, useGetCurrentCustomerOptionsQuery } from 'generated/graphql'
import { useUserRole } from 'hooks'
import { Restricted, usePermission } from 'providers/PermissionProvider'

import ListItemLink from './ListItemLink'

const DrawerContent = (props: RouteComponentProps) => {
  const userRole = useUserRole()
  const {
    location: { pathname },
  } = props

  const [isShownOptionsItem, setShownOptionsItem] = useState(false)

  const [isAllowedToViewCurrentCustomerOptions] = usePermission('ViewCurrentCustomerOptions')

  const { data, loading } = useGetCurrentCustomerOptionsQuery({
    skip: !isAllowedToViewCurrentCustomerOptions,
  })

  useEffect(() => {
    if (loading) {
      setShownOptionsItem(false)
    } else if (isAllowedToViewCurrentCustomerOptions) {
      setShownOptionsItem(!data?.currentCustomerOptions.includes(CustomerOption.None))
    } else {
      setShownOptionsItem(true)
    }
  }, [loading, isAllowedToViewCurrentCustomerOptions, data?.currentCustomerOptions])

  return (
    <div>
      <List>
        <Restricted to='ViewDashboard'>
          <ListItemLink
            to={Paths.dashboard.all}
            primary='Dashboard'
            icon={<Dashboard />}
            selected={pathname.startsWith(Paths.dashboard.all)}
          />
        </Restricted>

        <Restricted to={['ViewBookingsList', 'ViewUserBookingsList']}>
          <ListItemLink
            to={Paths.bookings.all}
            primary='Bookings'
            icon={<Work />}
            selected={
              pathname.startsWith(Paths.bookings.startsWith) ||
              pathname.startsWith(Paths.quotes.startsWith) ||
              pathname.startsWith(Paths.quickQuotes.startsWith) ||
              pathname.startsWith(Paths.potentialBookings.startsWith)
            }
          />
        </Restricted>

        <Restricted to='ViewCustomerList'>
          <ListItemLink
            to={Paths.customers.all}
            primary='Customers'
            icon={<AccountBox />}
            selected={pathname.startsWith(Paths.customers.startsWith)}
          />
        </Restricted>

        <Restricted to='ViewSuppliers'>
          <ListItemLink
            to={Paths.suppliers.all}
            primary='Suppliers'
            icon={<LocalShipping />}
            selected={pathname.startsWith(Paths.suppliers.all)}
          />
        </Restricted>

        {userRole === Roles.driver && (
          <Restricted to='ViewDriversProfile'>
            <ListItemLink
              to={Paths.drivers.updateProfile}
              primary='Driver details'
              icon={<LocalShipping />}
              selected={pathname.startsWith(Paths.drivers.updateProfile)}
            />
          </Restricted>
        )}
      </List>

      <Restricted to={['ViewUserList', 'ViewCustomerIncomeReport', 'ViewTariffList']}>
        <Divider />
      </Restricted>

      <List>
        <Restricted to='ViewUserList'>
          <ListItemLink
            to={Paths.users.all}
            primary='Users'
            icon={<PeopleAlt />}
            selected={pathname.startsWith(Paths.users.startsWith)}
          />
        </Restricted>

        <Restricted to='ViewReports'>
          <ListItemLink
            to={Paths.reports.all}
            primary='Reports'
            icon={<InsertChart />}
            selected={pathname.startsWith(Paths.reports.startsWith)}
          />
        </Restricted>

        <Restricted to='ViewStandardOperatingProcedures'>
          <ListItemLink
            to={Paths.standardOperatingProcedures.all}
            primary='Standard Operating Procedures'
            icon={<FactCheck />}
            selected={pathname.startsWith(Paths.standardOperatingProcedures.startsWith)}
          />
        </Restricted>

        <Restricted to='ViewOptions'>
          {isShownOptionsItem && (
            <ListItemLink
              to={Paths.options.all}
              primary='Options'
              icon={<SettingsApplications />}
              selected={pathname.startsWith(Paths.options.all)}
            />
          )}
        </Restricted>

        <Restricted to='ViewTariffList'>
          <ListItemLink
            to={Paths.settings.all}
            primary='Settings'
            icon={<Settings />}
            selected={pathname.startsWith(Paths.settings.all)}
          />
        </Restricted>
      </List>
      <Divider />
      <List>
        <Restricted to='ViewCustomerSalesNameList'>
          <ListItemLink
            to={Paths.accountsPayable.all}
            primary='Accounts Payable'
            icon={<ShoppingCart />}
            selected={pathname.startsWith(Paths.accountsPayable.all)}
          />
        </Restricted>

        <ListItemLink
          to={Paths.help.all}
          primary='Help'
          icon={<Help />}
          selected={pathname.startsWith(Paths.help.all)}
        />
      </List>
    </div>
  )
}

export default withRouter(DrawerContent)
