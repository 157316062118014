import { useMemo, useState } from 'react'

import { Box, Button, Grid } from '@mui/material'
import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { ExchangeRateFilterInput } from 'generated/graphql'
import { GET_PAGED_EXCHANGE_RATES_QUERY } from 'graphql/queries'

import ExchangeRatesFilter from './ExchangeRatesFilter'
import { ExchangeRateTasks } from './ScheduledExchangeTasks'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'sourceCurrency',
    headerName: 'Source Currency',
    minWidth: 150,
    flex: 1,
    sortPath: ['sourceCurrency', 'code'],
    valueGetter: ({ value }) => value?.code,
  },
  {
    field: 'targetCurrency',
    headerName: 'Target Currency',
    minWidth: 150,
    flex: 1,
    sortPath: ['targetCurrency', 'code'],
    valueGetter: ({ value }) => value?.code,
  },
  { field: 'rate', headerName: 'Rate', minWidth: 150, flex: 1, type: 'number' },
  {
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 150,
    flex: 1,
    valueFormatter: ({ value }) => value && format(parseJSON(value), DATE_TIME_FORMAT),
  },
]

type FilterCriteriaType = {
  sourceCurrencyId: number | null
  targetCurrencyId: number | null
  startDate: Date
  endDate: Date
}

const ExchangeRates = () => {
  const [openTasksDialog, setOpenTasksDialog] = useState(false)
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteriaType>({
    sourceCurrencyId: null,
    targetCurrencyId: null,
    startDate: new Date(),
    endDate: new Date(),
  })

  const filter: ExchangeRateFilterInput = useMemo(() => {
    let criteria: Array<ExchangeRateFilterInput> = [
      { createdAt: { lt: filterCriteria.endDate, gt: filterCriteria.startDate } },
    ]

    if (filterCriteria.sourceCurrencyId) {
      criteria = [...criteria, { sourceCurrencyId: { eq: filterCriteria.sourceCurrencyId } }]
    }

    if (filterCriteria.targetCurrencyId) {
      criteria = [...criteria, { targetCurrencyId: { eq: filterCriteria.targetCurrencyId } }]
    }

    return { and: criteria }
  }, [filterCriteria])

  // handlers
  const handleRetrieveClick = () => setOpenTasksDialog(true)
  const handleCloseDialog = () => setOpenTasksDialog(false)
  const handleFilterChange = (criteria: FilterCriteriaType) => setFilterCriteria(criteria)

  return (
    <>
      <Box mb={2}>
        <ExchangeRatesFilter onFilterChange={handleFilterChange} />
      </Box>

      <FplDataGrid
        query={GET_PAGED_EXCHANGE_RATES_QUERY}
        entityName='exchangeRates'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'createdAt', sort: 'desc' }}
        toolbar={{
          caption: 'Exchange Rates',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleRetrieveClick}>
                Tasks
              </Button>
            </Grid>
          ),
        }}
      />

      {openTasksDialog && (
        <ExchangeRateTasks openDialog={openTasksDialog} onClose={handleCloseDialog} />
      )}
    </>
  )
}

export default ExchangeRates
