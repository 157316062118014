import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { DEBOUNCE_DELAY, Paths } from 'constants/index'
import { GetSearchResultsQuery, useGetSearchResultsLazyQuery } from 'generated/graphql'

import SearchContainer from './SearchContainer'
import SearchIcon from './SearchIcon'
import SearchResults from './SearchResults'
import StyledInputBase from './StyledInputBase'

const InternalSearch = () => {
  const [inputValue, setInputValue] = useState<string>('')
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false)
  const history = useHistory()

  const [debouncedInputValue] = useDebounce(inputValue, DEBOUNCE_DELAY)
  const [getSearchResults, { data, loading }] = useGetSearchResultsLazyQuery({
    fetchPolicy: 'network-only',
  })

  const handleSearchChange = (e) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length >= 1) {
      getSearchResults({
        variables: { query: debouncedInputValue },
      })
    }
  }, [debouncedInputValue, getSearchResults])

  const handleSearchFocus = () => {
    setShowSearchResult(true)
  }

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSearchResult(false)
    }, 200)
  }

  const handleSearchResultClick = (searchResult: GetSearchResultsQuery['searchResults'][0]) => {
    switch (searchResult.entityName) {
      case 'Booking':
        history.push(Paths.bookings.updateWithId(searchResult.entityId))
        break
      case 'BookingInvoice':
        history.push(Paths.customers.updateWithId(searchResult.entityId) + '#invoices')
        break
      case 'StandardOperatingProcedure':
        history.push(Paths.standardOperatingProcedures.updateWithId(searchResult.entityId))
        break
    }

    setInputValue('')
  }

  return (
    <SearchContainer>
      <SearchIcon />
      <StyledInputBase
        placeholder='Search…'
        inputProps={{ 'aria-label': 'search' }}
        value={inputValue}
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
      />
      {showSearchResult && inputValue && (
        <SearchResults
          debouncedInputValue={debouncedInputValue}
          searchResultsData={data?.searchResults}
          loading={loading}
          onSearchResultClick={handleSearchResultClick}
        />
      )}
    </SearchContainer>
  )
}

export default InternalSearch
