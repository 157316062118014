import { useEffect, useMemo } from 'react'

import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  CardTitle,
  ControlledAutocomplete,
  ControlledCountryAutocomplete,
  ControlledDatePicker,
  ControlledSelector,
  ControlledTextField,
} from 'components/common'
import { AvailableRoles, DEFAULT_COUNTRY_ID, Mode, Roles } from 'constants/index'
import { SortEnumType, useGetCustomersLazyQuery } from 'generated/graphql'
import { TUserForm } from 'types'

interface IProps {
  mode: number
}

const UserForm = (props: IProps) => {
  const { mode } = props

  const theme = useTheme()

  const {
    control,
    watch,
    setValue,
    formState: { dirtyFields, errors },
  } = useFormContext<TUserForm>()

  const [roleValue, customerIdValue] = watch(['role', 'companyDetails.customerId'])

  // queries
  const [getCustomers, { loading: customersLoading, data: customersData }] =
    useGetCustomersLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (roleValue === Roles.customer) {
      getCustomers({
        variables: { order: { name: SortEnumType.Asc } },
      })
    }
  }, [roleValue, getCustomers])

  const customerItems = useMemo(
    () =>
      customersData?.customers.map((customer) => ({
        value: Number(customer.id),
        label: customer.name,
      })) || [],
    [customersData],
  )

  useEffect(() => {
    if (dirtyFields.companyDetails?.customerId) {
      setValue('companyDetails.contactId', null, { shouldDirty: true })
    }
  }, [customerIdValue, dirtyFields, setValue])

  const contactItems = useMemo(() => {
    const customerContacts = customersData?.customers.find(
      (customer) => customer.id === String(customerIdValue),
    )?.contacts

    return (
      customerContacts
        ?.filter((x) => x.isEnabled)
        .map((contact) => ({ value: contact.id, label: contact.name })) || []
    )
  }, [customersData, customerIdValue])

  return (
    <Stack>
      <div>
        <CardTitle>User Details</CardTitle>
        <Grid container columnSpacing={3} rowSpacing={1.5}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Email'
              name='email'
              required
              type='email'
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Username'
              name='username'
              required
              readOnly={mode === Mode.Update}
              error={!!errors.username}
              helperText={errors.username?.message}
              autoComplete='off'
            />
          </Grid>

          {mode === Mode.Create && (
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Password'
                name='password'
                required
                type='password'
                error={!!errors.password}
                helperText={errors.password?.message}
                autoComplete='new-password'
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='First Name'
              name='firstName'
              required
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Last Name'
              name='lastName'
              required
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='City'
              name='city'
              required
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledCountryAutocomplete
              control={control}
              required
              defaultValue={DEFAULT_COUNTRY_ID}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Phone'
              name='phoneNumber'
              required
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Postcode'
              name='postcode'
              required
              error={!!errors.postcode}
              helperText={errors.postcode?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledDatePicker
              control={control}
              label='D.O.B.'
              name='dob'
              required
              error={errors.dob}
              helperText={errors.dob?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledSelector
              control={control}
              label='Role'
              name='role'
              options={AvailableRoles}
              disabled={mode === Mode.Update}
              error={!!errors.role}
              helperText={errors.role?.message}
            />
          </Grid>
        </Grid>
      </div>
      {roleValue === Roles.customer && (
        <Box paddingTop={2}>
          <CardTitle>Company Details</CardTitle>
          <Grid container columnSpacing={3} rowSpacing={1.5}>
            {mode === Mode.Update && dirtyFields.companyDetails?.customerId && (
              <Grid item xs={12}>
                <Typography variant='subtitle1' color={theme.palette.error.main}>
                  WARNING: This action will move user to a new Customer. This user will no longer
                  have access to the old Customers data (bookings/quotes/etc).
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Company'
                name='companyDetails.companyName'
                required={mode === Mode.Create}
                readOnly={mode === Mode.Update}
                error={!!errors.companyDetails?.companyName}
                helperText={errors.companyDetails?.companyName?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledAutocomplete
                control={control}
                name='companyDetails.customerId'
                label='Customer'
                options={customerItems}
                required
                defaultValue=''
                loading={customersLoading}
                error={!!errors.companyDetails?.customerId}
                helperText={errors.companyDetails?.customerId?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledAutocomplete
                control={control}
                name='companyDetails.contactId'
                label='Contact'
                options={contactItems}
                defaultValue=''
                error={!!errors.companyDetails?.contactId}
                helperText={errors.companyDetails?.contactId?.message}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Stack>
  )
}

export default UserForm
