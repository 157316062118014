import { useOidcIdToken } from "@axa-fr/react-oidc-context"

import { Roles } from "constants/index";


export const useUserRole = (): keyof typeof Roles => {
  const { idTokenPayload } = useOidcIdToken()

  return idTokenPayload.role;
}
