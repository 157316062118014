import React from 'react'

import { Close, Refresh } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import Decimal from 'decimal.js'

import { DATE_TIME_FORMAT } from 'constants/index'
import { LoadQuotesMutation } from 'generated/graphql'

interface IProps {
  quotes?: LoadQuotesMutation['loadQuotes']
  revenue: number
  loading: boolean
  onSelectQuote: (id: string | undefined) => void
  onDeleteQuote: (id: string | undefined) => void
  onRefreshClick: () => void
}

const CxQuotesTable = (props: IProps) => {
  const { quotes, revenue, loading, onSelectQuote, onDeleteQuote, onRefreshClick } = props

  const getGrossProfit = React.useCallback(
    (totalPrice): number => {
      return revenue - totalPrice
    },
    [revenue],
  )

  const getGrossProfitPct = React.useCallback(
    (totalPrice): string => {
      if (revenue === 0) {
        return 'n/a'
      }

      return new Decimal(revenue)
        .minus(totalPrice)
        .mul(100)
        .div(revenue)
        .toDecimalPlaces(2, Decimal.ROUND_FLOOR)
        .toString()
    },
    [revenue],
  )

  const handleOnDeleteQuote = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    quoteId: string | undefined,
  ) => {
    event.stopPropagation()
    onDeleteQuote(quoteId)
  }

  return (
    <>
      <Toolbar>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Typography color='inherit' variant='h6'>
              Available Quotes
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onRefreshClick} size='large'>
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      {loading ? (
        <Box component='div' height={200} width='100%' display='flex' justifyContent='center'>
          <CircularProgress size={150} style={{ marginTop: 10 }} variant='indeterminate' />
        </Box>
      ) : (
        <TableContainer sx={{ height: '400px' }}>
          <Table aria-label='cx quotes table'>
            <TableHead>
              <TableRow>
                <TableCell>CX Member ID</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Driver Name</TableCell>
                <TableCell>Price (£)</TableCell>
                <TableCell>Total Price (£)</TableCell>
                <TableCell>Gross Profit (£)</TableCell>
                <TableCell>Gross Profit (%)</TableCell>
                <TableCell>Date-Time Posted</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>In system?</TableCell>
                <TableCell>Our app?</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotes?.map((quote) => (
                <TableRow key={quote?.id} onClick={() => onSelectQuote(quote?.id)}>
                  <TableCell>{quote?.quoteOwnerCompanyId}</TableCell>
                  <TableCell>{quote?.quoteOwnerCompanyName}</TableCell>
                  <TableCell>{quote?.quoteOwnerDriverName}</TableCell>
                  <TableCell>{quote?.price}</TableCell>
                  <TableCell>{quote?.totalPrice}</TableCell>
                  <TableCell>{getGrossProfit(quote?.totalPrice)}</TableCell>
                  <TableCell>{getGrossProfitPct(quote?.totalPrice)}</TableCell>
                  <TableCell>
                    {quote?.createdOn && format(new Date(quote?.createdOn), DATE_TIME_FORMAT)}
                  </TableCell>
                  <TableCell>
                    {quote?.notes ? (
                      quote?.notes
                    ) : (
                      <span style={{ fontStyle: 'italic' }}>No data</span>
                    )}
                  </TableCell>
                  <TableCell>{quote?.isDriverInSystem ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{quote?.isDriverUsingOurApp ? 'Yes' : 'No'}</TableCell>
                  <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }} align='right'>
                    <IconButton
                      onClick={(event) => handleOnDeleteQuote(event, quote?.id)}
                      size='large'>
                      <Close />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default CxQuotesTable
