import { useMemo } from 'react'

import { ControlledSelector } from 'components/common'
import {
  SortEnumType,
  useGetCustomerProfileQuery,
  useGetSubCustomersQuery,
} from 'generated/graphql'

interface IProps {
  control: any
}

const UserCustomersSelector = (props: IProps) => {
  const { control } = props

  // Queries
  const { data: customerProfileData } = useGetCustomerProfileQuery()

  const customerId = customerProfileData?.customerProfile.customerId

  const { data: customersData } = useGetSubCustomersQuery({
    skip: !customerId,
    variables: {
      primaryCustomerId: customerId,
      isPrimaryCustomerIncluded: true,
      order: { name: SortEnumType.Asc },
    },
  })

  const customersItems = useMemo(
    () =>
      customersData?.subCustomers?.map((subCustomer) => ({
        value: subCustomer.id,
        label: subCustomer.name,
      })) || [],
    [customersData],
  )

  return (
    <ControlledSelector
      control={control}
      name='customerId'
      label='Customer'
      options={customersItems}
      emptyValue={{ value: '', label: 'All Customers' }}
      displayEmpty
    />
  )
}

export default UserCustomersSelector
