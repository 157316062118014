import { gql } from '@apollo/client'

const GET_ACQUISITION_SOURCES_QUERY = gql`
  query GetAcquisitionSources {
    acquisitionSources {
      id
      name
    }
  }
`

export { GET_ACQUISITION_SOURCES_QUERY }
