import { Box, Button, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledDatePicker, SplitButton } from 'components/common'
import { ViewDownloadButtonsId, ViewDownloadButtonValues } from 'constants/EntityConstants'

interface IProps {
  isProcessing: boolean
  onReportButtonClick: (buttonId: ViewDownloadButtonsId) => void
  onClearAll: () => void
}

const FilterCriteriaForm = (props: IProps) => {
  const { isProcessing, onReportButtonClick, onClearAll } = props
  const { control } = useFormContext()

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <ControlledDatePicker control={control} name='start' label='From' />
      </Grid>

      <Grid item xs={2}>
        <ControlledDatePicker control={control} name='end' label='To' />
      </Grid>

      <Grid item xs={8}>
        <Grid container spacing={3} item justifyContent='flex-end'>
          <Grid item>
            <Box display='flex' justifyContent='center'>
              <Button variant='contained' color='grey' onClick={onClearAll}>
                Clear All
              </Button>
            </Box>
          </Grid>

          <Grid item>
            <SplitButton
              options={ViewDownloadButtonValues}
              loading={isProcessing}
              onButtonClick={onReportButtonClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FilterCriteriaForm
