import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'

import { FplDialogTitle, FplDataGrid, IGridColumn } from 'components/common'
import { AddressFilterInput } from 'generated/graphql'
import { GET_PAGED_CUSTOMER_ADDRESSES } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'name', headerName: 'Title/Company', minWidth: 200, flex: 1 },
  { field: 'addressLine1', headerName: 'Address 1', minWidth: 200, flex: 1 },
  { field: 'addressLine2', headerName: 'Address 2', minWidth: 150 },
  { field: 'city', headerName: 'Town/City', minWidth: 150 },
  { field: 'postcode', headerName: 'Post Code', minWidth: 150 },
]

interface IProps {
  open: boolean
  setOpen: any
  customerId: number
  postcode: string
  onSelectAddress: (id: number) => void
}

const FindAddressDialog = (props: IProps) => {
  const { open, setOpen, customerId, postcode, onSelectAddress } = props

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectAddress(Number(id))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const filter: AddressFilterInput = {
    and: [{ postcode: { contains: postcode } }],
  }

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='find-address-dialog'>
      <FplDialogTitle id='find-address-dialog' onClose={handleClose}>
        <Typography variant='h6'>Find Address</Typography>
      </FplDialogTitle>
      <DialogContent>
        <FplDataGrid
          query={GET_PAGED_CUSTOMER_ADDRESSES}
          entityName='addresses'
          queryVariables={{ customerId }}
          columns={columns}
          filter={filter}
          defaultOrder={{ field: 'name', sort: 'asc' }}
          toolbar={{ caption: 'Address List' }}
          onRowClick={handleRowClick}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FindAddressDialog
