import { useMemo } from 'react'

import LaunchIcon from '@mui/icons-material/Launch'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Box, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useHistory } from 'react-router-dom'

import { IGridColumn } from 'components/common'
import DriverRating from 'components/suppliers/drivers/DriverRating/DriverRating'
import { Paths } from 'constants/Paths'

interface IProps {
  handleRatingClick: (bookingId: number) => () => void
}

function useGetBookingDriverRatingColumns({ handleRatingClick }: IProps) {
  const history = useHistory()
  const columns: IGridColumn[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Allocation ID',
        flex: 0.1,
      },
      {
        field: 'bookingId',
        headerName: 'Booking ID',
        flex: 0.1,
      },
      {
        field: 'rating',
        headerName: 'Rating',
        flex: 0.2,
        renderCell(params) {
          if (params.row.rating !== null)
            return (
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DriverRating ratingValue={params.row.rating} size='small' />
              </Box>
            )
          return 'N/A'
        },
      },
      {
        field: 'ratingComment',
        headerName: 'Comment',
        flex: 0.3,
      },
      {
        field: 'createdBy',
        headerName: 'Allocated By',
        flex: 0.2,
      },
      {
        field: 'lastModifiedBy',
        headerName: 'Allocation Updated By',
        flex: 0.2,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.2,
        type: 'actions',
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label='Rating'
            size='large'
            onClick={handleRatingClick(row.bookingId)}
            icon={
              <Tooltip title={'Rating'} arrow>
                {row.rating ? <StarIcon sx={{ color: '#faaf00' }} /> : <StarBorderIcon />}
              </Tooltip>
            }
          />,
          <GridActionsCellItem
            key={id}
            label='Booking Page'
            size='large'
            onClick={() => window.open(Paths.bookings.updateWithId(row.bookingId))}
            icon={
              <Tooltip title={'Booking Page'} arrow>
                <LaunchIcon />
              </Tooltip>
            }
          />,
        ],
      },
    ],
    [history, handleRatingClick],
  )

  return [columns]
}

export default useGetBookingDriverRatingColumns
