import React from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledSelector,
  ControlledDatePicker,
  SplitButton,
  ISelectorOptions,
} from 'components/common'
import { ReportTypeButtonsId, ReportTypeButtonValues } from 'constants/EntityConstants'
import { GetSalesNamesQuery } from 'generated/graphql'

interface IProps {
  salesNames: GetSalesNamesQuery['salesNames'] | undefined
  salesNamesLoading: boolean
  isProcessing: boolean
  onReportButtonClick: (buttonId: ReportTypeButtonsId) => void
  onClearAll: () => void
}

const FilterCriteriaForm = (props: IProps) => {
  const { salesNames, salesNamesLoading, isProcessing, onReportButtonClick, onClearAll } = props

  const { control } = useFormContext()

  const salesNameOptions: ISelectorOptions = React.useMemo(
    () => salesNames?.map((salesName) => ({ value: salesName, label: salesName })) || [],
    [salesNames],
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <ControlledDatePicker control={control} name='start' label='From' />
      </Grid>

      <Grid item xs={2}>
        <ControlledDatePicker control={control} name='end' label='To' />
      </Grid>

      <Grid item xs={2}>
        {salesNamesLoading ? (
          <Box mt={-1.25} mb={-1}>
            <Typography variant='h2'>
              <Skeleton animation='wave' />
            </Typography>
          </Box>
        ) : (
          <ControlledSelector
            control={control}
            name='salesName'
            label='Sales Person'
            options={salesNameOptions}
            displayEmpty
            emptyValue={{ value: null, label: 'All Sales Persons' }}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={3} item justifyContent='flex-end'>
          <Grid item>
            <Box display='flex' justifyContent='center'>
              <Button variant='contained' color='grey' onClick={onClearAll}>
                Clear All
              </Button>
            </Box>
          </Grid>

          <Grid item>
            <SplitButton
              options={ReportTypeButtonValues}
              loading={isProcessing}
              onButtonClick={onReportButtonClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FilterCriteriaForm
