interface IProps {
  html: string
}

function PodDocument({ html }: IProps) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html ?? '',
      }}></div>
  )
}

export default PodDocument
