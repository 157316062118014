import { useCallback, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT, DEBOUNCE_DELAY } from 'constants/index'
import { SortEnumType, TariffIconFilterInput, useDeleteTariffIconMutation } from 'generated/graphql'
import { GET_PAGED_TARIFF_ICONS } from 'graphql/queries'

import { TariffIconDialog } from './TariffIconDialog'

const TariffIcons = () => {
  const [selectedTariffIconId, setSelectedTariffIconId] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { control, watch, setValue } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const [deleteTariffIcon, { loading: deletingTariffIcon }] = useDeleteTariffIconMutation({
    refetchQueries: [
      {
        query: GET_PAGED_TARIFF_ICONS,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { createdAt: SortEnumType.Asc },
        },
      },
    ],
  })

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedTariffIconId(Number(id))
    setOpenDialog(true)
  }

  const handleAddTariffIcon = () => {
    setSelectedTariffIconId(null)
    setOpenDialog(true)
  }

  const handleDeleteTariffIcon = useCallback(
    (id: GridRowId) => () => {
      deleteTariffIcon({
        variables: {
          id: Number(id),
        },
      }).then((response) => {
        if (response.data?.deleteTariffIcon) {
          toast.success('Tariff Icon was deleted with success!')
        }
      })
    },
    [deleteTariffIcon],
  )

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'vehicleCapacity', headerName: 'Vehicle Capacity', minWidth: 200, flex: 1 },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 200,
        flex: 0.5,
        valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
      },
      { field: 'createdBy', headerName: 'Created By', minWidth: 200, flex: 0.5 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deletingTariffIcon}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteTariffIcon(id)}
          />,
        ],
      },
    ],
    [deletingTariffIcon, handleDeleteTariffIcon],
  )

  const filter: TariffIconFilterInput = useMemo(
    () => ({ or: [{ name: { contains: searchValue } }] }),
    [searchValue],
  )

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_TARIFF_ICONS}
        entityName='pagedTariffIcons'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'createdAt', sort: 'asc' }}
        toolbar={{
          caption: 'Tariff Icons',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddTariffIcon}>
                New Tariff Icon
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <TariffIconDialog
          id={selectedTariffIconId}
          openDialog={openDialog}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default TariffIcons
