import { Backdrop, CircularProgress, styled } from '@mui/material'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.drawer - 1,
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
}))

interface IProps {
  loading: boolean
}

export const LoadingBackdrop = ({ loading }: IProps) => {
  return (
    <StyledBackdrop open={loading}>
      <CircularProgress color='primary' />
    </StyledBackdrop>
  )
}
