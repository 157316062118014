import React from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import {
  CreateAndUpdateStrategicPartnerDtoInput,
  useGetStrategicPartnerQuery,
} from 'generated/graphql'

import StrategicPartnerFormControls from './StrategicPartnerFormControls'

interface IProps {
  strategicPartnerId: number
  isProcessing: boolean
}

const UpdateStrategicPartner = (props: IProps) => {
  const { strategicPartnerId, isProcessing } = props

  const { reset } = useFormContext<CreateAndUpdateStrategicPartnerDtoInput>()

  // Queries
  const { data: strategicPartnerData, loading: strategicPartnerLoading } =
    useGetStrategicPartnerQuery({
      variables: {
        strategicPartnerId,
      },
    })

  React.useEffect(() => {
    if (strategicPartnerData) {
      reset({
        ...strategicPartnerData.strategicPartner,
      })
    }
  }, [strategicPartnerData, reset])

  return (
    <>
      <StrategicPartnerFormControls isProcessing={isProcessing} />
      <LoadingBackdrop loading={strategicPartnerLoading} />
    </>
  )
}

export default UpdateStrategicPartner
