import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import FplDialogTitle from './FplDialogTitle'

interface FplDialogProps {
  id: string
  open: boolean
  caption: string
  onCloseDialog: () => void
  children: React.ReactNode
  closeCaption?: string
  loading?: boolean
  submitCaption?: string
  disabled?: boolean
  onSaveClick: () => void
  fullWidth?: boolean
}

const FplDialog = (props: FplDialogProps) => {
  const {
    id,
    open,
    caption,
    onCloseDialog,
    children,
    closeCaption,
    loading,
    submitCaption,
    disabled,
    onSaveClick,
    fullWidth,
  } = props

  return (
    <Dialog open={open} aria-label={id} maxWidth='lg' fullWidth={fullWidth}>
      <FplDialogTitle id={id} onClose={onCloseDialog}>
        <Typography variant='h4'>{caption}</Typography>
      </FplDialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={onCloseDialog}>
          {closeCaption ? closeCaption : 'Close'}
        </Button>
        <LoadingButton
          variant='contained'
          loading={loading}
          disabled={disabled}
          onClick={onSaveClick}>
          {submitCaption ? submitCaption : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default FplDialog
