import { useState } from 'react'

import { Box, FormHelperText, Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledCheckbox,
  ControlledSelector,
  ControlledTextField,
  DropZoneIcon,
} from 'components/common'
import { BannerTypeValues } from 'constants/EntityConstants'
import { Mode } from 'constants/Mode'
import { GetBannerQuery } from 'generated/graphql'
import { TBannerDialogForm } from 'types'

interface IProps {
  mode: number
  bannerData?: GetBannerQuery['banner']
  onFilesChanged: (files: File[]) => void
}

const BannerForm = (props: IProps) => {
  const { mode, bannerData, onFilesChanged } = props
  const [isFileSelected, setIsFileSelected] = useState(false)

  const {
    control,
    formState: { errors, isSubmitted },
  } = useFormContext<TBannerDialogForm>()

  const handleFilesChanged = (files: File[]) => {
    onFilesChanged(files)

    if (files.length > 0) {
      setIsFileSelected(true)
    }
    if (files.length === 0) {
      setIsFileSelected(false)
    }
  }

  return (
    <Box pt={1}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='name'
            label='Name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledSelector
            control={control}
            name='type'
            label='Type'
            options={BannerTypeValues}
            required
            error={!!errors.type}
            helperText={errors.type?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
            <ControlledCheckbox control={control} name='isActive' label='Is Active' />
          </Box>
        </Grid>

        {mode === Mode.Update && bannerData?.file && (
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item>
                <Typography variant='body1' fontWeight='Medium'>
                  Current file:
                </Typography>
              </Grid>
              <Grid item>
                <img src={bannerData.file?.absoluteUri} style={{ maxHeight: 80 }} />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <DropZoneIcon
            dropzoneText={mode === Mode.Create ? 'Add Banner*' : 'Update Banner'}
            filesLimit={1}
            onFilesChanged={handleFilesChanged}
          />
          {mode === Mode.Create && isSubmitted && !isFileSelected && (
            <FormHelperText sx={{ marginTop: 0.375, marginX: 1.75 }} error>
              Banner is required
            </FormHelperText>
          )}

          {mode === Mode.Update && isSubmitted && !bannerData?.fileId && !isFileSelected && (
            <FormHelperText sx={{ marginTop: 0.375, marginX: 1.75 }} error>
              Banner is required
            </FormHelperText>
          )}
          <FormHelperText sx={{ marginTop: 0.375, marginX: 1.75 }}>
            Recommended image resolution 1000px x 200px
          </FormHelperText>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BannerForm
