import { gql } from '@apollo/client'

export const GET_BOOKING_INVOICE_BATCHES = gql`
  query GetBookingInvoiceBatches(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingInvoiceBatchSortInput!]
    $where: BookingInvoiceBatchFilterInput
  ) {
    bookingInvoiceBatches(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          date
          paymentDate
          createdAt
          lastModifiedAt
          currencyId
          isSentToXero
          currencyId
          currency {
            code
          }
          isSentToXero
          isSuccess
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_BOOKING_INVOICE_BATCH_LINES = gql`
  query allbookingInvoiceBatchLines(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingInvoiceBatchLineSortInput!]
    $where: BookingInvoiceBatchLineFilterInput
  ) {
    bookingInvoiceBatchLines(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          accountCode
          taxType
          total
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_BOOKING_INVOICE_BATCH = gql`
  query GetBookingInvoiceBatch($id: Long!) {
    bookingInvoiceBatch(bookingInvoiceBatchId: $id) {
      id
      isSentToXero
      isSuccess
      errorMessage
    }
  }
`
