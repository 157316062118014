import { format, parseISO } from 'date-fns'

import { DATE_ABBREVIATED_FORMAT, TIME_FORMAT } from 'constants/index'

export const orderDetailsDateFormatter = (startDate: string, endDate: string) => {
  let formattedStartDate = "";
  let formattedEndDate = "";
  if (startDate !== "") {
    const parsedStartDate = parseISO(startDate)
    formattedStartDate = format(parsedStartDate, `${DATE_ABBREVIATED_FORMAT} ${TIME_FORMAT}`)
  }
  if (endDate !== ""){
    const parsedEndDate = parseISO(endDate)
    formattedEndDate = format(parsedEndDate, TIME_FORMAT)
  }

  return `${formattedStartDate}-${formattedEndDate}`
}
