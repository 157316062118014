import Joi from 'joi'

import { Roles } from 'constants/EntityConstants/Roles'
import { Mode, PasswordRegExp } from 'constants/index'

const companyDetailsSchema = Joi.object({
  companyName: Joi.string().required().label('Company Name'),
  customerId: Joi.number().required().label('Customer'),
  contactId: Joi.string().allow(null, '').optional().label('Contact'),
}).required()

const ValidationSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email'),
  firstName: Joi.string().required().label('First Name'),
  lastName: Joi.string().required().label('Last Name'),
  password: Joi.when('$mode', {
    is: Mode.Create,
    then: Joi.string().required().label('Password').pattern(PasswordRegExp).messages({
      'string.pattern.base':
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (!@#$%^&*)',
    }),
    otherwise: Joi.string().label('Password'),
  }),
  username: Joi.string().required().label('Username'),
  countryId: Joi.number().required().label('Country'),
  city: Joi.string().required().label('City'),
  role: Joi.string().required().label('Role'),
  phoneNumber: Joi.string().required().label('Phone Number'),
  postcode: Joi.string().required().label('Postcode'),
  dob: Joi.date().required().label('D.O.B.'),
  driverId: Joi.number().min(0).allow(null),
  customerId: Joi.number().min(0).allow(null),
  companyDetails: Joi.alternatives().conditional('role', {
    switch: [
      {
        is: Roles.customer,
        then: Joi.when('mode', { is: Mode.Create, then: companyDetailsSchema }),
      },
    ],
    otherwise: Joi.optional().allow(null, ''),
  }),
})

export default ValidationSchema
