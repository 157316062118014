import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'

import { FplDialogTitle } from 'components/common'

interface IProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmCopyFromPcDialog = (props: IProps) => {
  const { open, onConfirm, onCancel } = props

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      open={open}
      onClose={onCancel}
      aria-labelledby='confirm-copy-from-pc-dialog'>
      <FplDialogTitle id='confirm-copy-from-pc-dialog' onClose={onCancel}>
        Confirm copy from Primary Customer
      </FplDialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to copy info from the selected Primary Customer?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='contained' onClick={onConfirm}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmCopyFromPcDialog
