import { Grid, Tooltip } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField, TextInput } from 'components/common'
import { UpdateProofOfDeliveryDtoInput } from 'generated/graphql'

interface IProps {
  totalCollectionWtTime?: number
  totalDeliveryWtTime?: number
  hoverText: string
  disabled: boolean
}

export const WaitingTimeChargeDetails = (props: IProps) => {
  const { totalCollectionWtTime, totalDeliveryWtTime, hoverText, disabled } = props
  const {
    control,

    formState: { errors },
  } = useFormContext<UpdateProofOfDeliveryDtoInput>()

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={4}>
            <TextInput
              name='totalCollectionWtTime'
              label='Total Collection Wt. Time'
              value={totalCollectionWtTime}
              readOnly
              endAdornment='mins'
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              name='totalDeliveryWtTime'
              label='Total Delivery Wt. Time'
              value={totalDeliveryWtTime}
              readOnly
              endAdornment='mins'
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ControlledTextField
              control={control}
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              min='0'
              name='waitingTimeCharge'
              label='Total Wt. Charge'
              startAdornment='£'
              disabled={disabled}
              error={!!errors.waitingTimeCharge}
              helperText={errors.waitingTime?.message}
            />
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  )
}

export default WaitingTimeChargeDetails
