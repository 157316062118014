import { toast } from 'react-toastify'

import { DEFAULT_COUNTRY_ID } from 'constants/index'
import { PackagingType } from 'generated/graphql'
import { TBookingAddressForm, TBookingForm, TBookingPackageForm } from 'types/form-types'

import { TRequiredAdditionalData, TAdditionalData, TExtractedData } from '.'

const collectionSequenceOrder = 0

export const DeltaGroupAppleRequiredAdditionalData: TRequiredAdditionalData = {
  isRequiredReasonCode: false,
  isRequiredCountriesList: true,
  isRequiredTariffsList: false,
}

export const DeltaGroupApple = (
  bookingData: TExtractedData,
  newBooking: TBookingForm,
  additionalData: TAdditionalData,
): TBookingForm => {
  // Process Collection
  const collectionAddress = {
    name: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    countryId: DEFAULT_COUNTRY_ID,
    contact: '',
    email: '',
    telephoneNumber: '',
    note: '',
    sequenceOrder: collectionSequenceOrder,
  } as unknown as TBookingAddressForm

  newBooking.collectionAddresses = [collectionAddress]

  // Process Deliveries and Packages
  newBooking.deliveryAddresses = []
  newBooking.packages = []
  let sequenceOrder = collectionSequenceOrder + 1

  bookingData.forEach((address) => {
    // Delivery
    const countryFromData = address['Country']
    const countryFromDB = additionalData.countries?.filter(
      (x) => x.name.includes(countryFromData) || x.formalName.includes(countryFromData),
    )[0]

    if (!countryFromDB) {
      toast.warn(`Couldn't find country "${countryFromData}", please set manually.`)
    }

    const deliveryAddress = {
      name: address['Retailer'],
      city: address['City'] || address['CITY'],
      addressLine1: address['Address Line 1'] || address['ADDRESS LINE 1'],
      addressLine2: address['Address Line 2'] || '',
      postcode: address['ZIP'] || address['POSTAL / ZIP CODE'],
      countryId: Number(countryFromDB?.id) ?? DEFAULT_COUNTRY_ID,
      contact: address['Contact Name'] || '',
      email: '',
      telephoneNumber: address['Contact Number'] || '',
      note: '',
      sequenceOrder: sequenceOrder,
      reasonCodeId: 0,
    } as unknown as TBookingAddressForm

    newBooking.deliveryAddresses.push(deliveryAddress)

    // Package
    const packing = address['Packing size and weight'] || address['Size']
    const regex =
      /^(?<count>\d+)?(?:\sx\s)?(?<details>[\w\s]+)\s.*?(?<length>\d+)\s?x\s?(?<width>\d+)\s?x\s?(?<height>\d+)mm.*?(?<weight>\d+)kg.*$/gm
    const matches = regex.exec(packing)
    const isMultipleMatches = regex.exec(packing) !== null

    const count = Number(matches?.groups?.count)
    const length = Number(matches?.groups?.length) / 10 ?? 1
    const width = Number(matches?.groups?.width) / 10 ?? 1
    const height = Number(matches?.groups?.height) / 10 ?? 1
    const weight = Number(matches?.groups?.weight) ?? 0
    const details = matches?.groups?.details

    const pack = {
      quantity: Number(address['No. Packs']) || count || 1,
      packageType: PackagingType.Packages,
      collectionSequenceOrder: collectionSequenceOrder,
      deliverySequenceOrder: sequenceOrder,
      length: isMultipleMatches ? 1 : length,
      width: isMultipleMatches ? 1 : width,
      height: isMultipleMatches ? 1 : height,
      weight: isMultipleMatches ? 0 : weight,
      reference: 'Apple ID: ' + address['Apple ID'],
      details: isMultipleMatches ? packing : details,
    } as unknown as TBookingPackageForm

    newBooking.packages.push(pack)

    sequenceOrder++
  })

  return newBooking
}
