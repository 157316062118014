import { Typography } from '@mui/material'

import { Mode } from 'constants/index'

const AddressFormHeader = ({ mode }: { mode: number }) => {
  let title

  switch (mode) {
    case Mode.Create: {
      title = 'New Address'
      break
    }
    case Mode.Update: {
      title = 'Update Address'
      break
    }
    default: {
      title = 'Invalid Address id'
      break
    }
  }
  return (
    <Typography paragraph variant='h4'>
      {title}
    </Typography>
  )
}

export default AddressFormHeader
