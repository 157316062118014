import React from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { Weekday } from 'generated/graphql'

interface IProps {
  selectedWeekdays: Array<Weekday>
  setSelectedWeekdays: React.Dispatch<React.SetStateAction<Array<Weekday>>>
}

export default function WeekdaysGroup(props: IProps) {
  const { selectedWeekdays, setSelectedWeekdays } = props
  const handleValueChange = (_: React.MouseEvent<HTMLElement>, newValues: any) => {
    setSelectedWeekdays(newValues)
  }

  return (
    <ToggleButtonGroup value={selectedWeekdays} onChange={handleValueChange}>
      <ToggleButton value={Weekday.Monday} aria-label='monday'>
        Monday
      </ToggleButton>
      <ToggleButton value={Weekday.Tuesday} aria-label='tuesday'>
        Tuesday
      </ToggleButton>
      <ToggleButton value={Weekday.Wednesday} aria-label='wednesday'>
        Wednesday
      </ToggleButton>
      <ToggleButton value={Weekday.Thursday} aria-label='thursday'>
        Thursday
      </ToggleButton>
      <ToggleButton value={Weekday.Friday} aria-label='friday'>
        Friday
      </ToggleButton>
      <ToggleButton value={Weekday.Saturday} aria-label='saturday'>
        Saturday
      </ToggleButton>
      <ToggleButton value={Weekday.Sunday} aria-label='sunday'>
        Sunday
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
