import { gql } from '@apollo/client'

export const OtherChargeDetailsFragment = gql`
  fragment OtherChargeDetails on OtherCharge {
    name
    rate
    description
    xeroServiceCode
    vatCategoryId
  }
`
