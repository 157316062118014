import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { CongestionZoneFilterInput } from 'generated/graphql'
import { GET_PAGED_CONGESTION_ZONES_QUERY } from 'graphql/queries/CongestionZoneQueries'

import CreateUpdateCongestionZoneDialog from './CreateUpdateCongestionZone'

const columns: IGridColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'customerCharge',
    headerName: 'Customer Charge',
    minWidth: 200,
    flex: 1,
  },

  {
    field: 'isAttachedToAllTariffs',
    headerName: 'Attached To All Tariffs',
    minWidth: 200,
    flex: 1,
    type: 'boolean',
  },
  {
    field: 'isAppliedAutomatically',
    headerName: 'Is Applied Automatically',
    minWidth: 200,
    flex: 1,
    type: 'boolean',
  },
]

const CongestionZones = () => {
  const [selectedCongestionZoneId, setSelectedCongestionZoneId] = React.useState<
    string | undefined
  >()
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedCongestionZoneId(String(id))
    setOpenDialog(true)
  }

  const handleAddNewCongestionZone = () => {
    setSelectedCongestionZoneId(undefined)
    setOpenDialog(true)
  }

  const handleCloseCongestionZoneDialog = () => {
    setOpenDialog(false)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const filter = {
    or: [{ name: { contains: searchValue } }],
  } as CongestionZoneFilterInput

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_CONGESTION_ZONES_QUERY}
        entityName='pagedCongestionZones'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'id', sort: 'asc' }}
        toolbar={{
          caption: 'Special Zones',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddNewCongestionZone}>
                Add New
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <CreateUpdateCongestionZoneDialog
          congestionZoneId={selectedCongestionZoneId}
          openDialog={openDialog}
          handleCloseDialog={handleCloseCongestionZoneDialog}
        />
      )}
    </>
  )
}

export default CongestionZones
