import { useEffect, useMemo } from 'react'

import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledAutocomplete,
  ControlledCheckbox,
  ControlledSelector,
  ISelectorOptions,
  TextInput,
} from 'components/common'
import { CreateAndUpdateTariffDtoInput, useGetTariffIconsQuery } from 'generated/graphql'

const ExternalTariffDetails = () => {
  const {
    control,
    watch,
    trigger,
    formState: { errors, isSubmitted },
  } = useFormContext<CreateAndUpdateTariffDtoInput>()

  const { data: tariffIconsData } = useGetTariffIconsQuery()

  const isExternalValue = watch('isExternal', false)
  const tariffIconIdValue = watch('tariffIconId')

  useEffect(() => {
    if (isSubmitted) {
      trigger(['visibilitySequenceNumber', 'iconUrl', 'capacity'])
    }
  }, [isExternalValue, isSubmitted, trigger])

  const vehicleIconOptions: ISelectorOptions = useMemo(
    () =>
      tariffIconsData?.tariffIcons.map((tariffIcon) => ({
        value: tariffIcon.id,
        label: (
          <Box display='flex' alignItems='center'>
            <Box height='32.5px'>
              <img
                src={tariffIcon.file?.absoluteUri}
                alt={`${tariffIcon.id} img`}
                style={{ maxWidth: 90, maxHeight: 33 }}
              />
            </Box>
            <Box marginLeft='2px'>{tariffIcon.name}</Box>
          </Box>
        ),
      })) || [],
    [tariffIconsData?.tariffIcons],
  )

  const sequenceNumberOptions = useMemo(() => {
    const items = Array.from({ length: vehicleIconOptions.length + 2 }, (_, index) => index + 1)

    return items.map((item) => {
      return { value: item, label: `${item}` }
    })
  }, [vehicleIconOptions.length])

  const vehicleCapacityValue: string = useMemo(
    () =>
      tariffIconsData?.tariffIcons.find((tariffIcon) => tariffIcon.id === String(tariffIconIdValue))
        ?.vehicleCapacity || '',
    [tariffIconIdValue, tariffIconsData],
  )

  return (
    <Grid container columnSpacing={3} rowSpacing={1.5}>
      <Grid item xs={12} lg={6}>
        <ControlledCheckbox control={control} name='isExternal' label='Mark as External' />
      </Grid>

      <Grid item xs={12} lg={6}>
        <ControlledAutocomplete
          control={control}
          name='visibilitySequenceNumber'
          label='Sequence Number'
          options={sequenceNumberOptions}
          required={isExternalValue}
          disabled={!isExternalValue}
          error={!!errors.visibilitySequenceNumber}
          helperText={errors.visibilitySequenceNumber?.message}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <ControlledSelector
          control={control}
          name='tariffIconId'
          label='Icon'
          options={vehicleIconOptions}
          // items={vehicleIconItems}
          required={isExternalValue}
          disabled={!isExternalValue}
          error={!!errors.tariffIconId}
          helperText={errors.tariffIconId?.message}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextInput
          name='vehicleCapacity'
          label='Vehicle Capacity'
          value={vehicleCapacityValue}
          readOnly
          multiline
          disabled={!isExternalValue}
        />
      </Grid>
    </Grid>
  )
}

export default ExternalTariffDetails
