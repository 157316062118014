export const EntityConstants = {
  MAX_NAME_LENGTH: 200,
  MAX_BANK_SORT_CODE_LENGTH: 6,
  MAX_ADDRESS_LENGTH: 100,
  MAX_CITY_LENGTH: 140,
  MAX_EMAIL_LENGTH: 320,
  MAX_NOTE_LENGTH: 2000,
  MAX_NOTE_LENGTH_POST_LOAD_OR_BOOK_DIRECT: 1000,
  MAX_SHORT_NOTE_LENGTH: 200,
  MAX_POSTCODE_LENGTH: 12,
  MAX_TELEPHONE_NUMBER_LENGTH: 20,
  MAX_VAT_ID_NUMBER_LENGTH: 20,
  MAX_EORI_NUMBER_LENGTH: 20,
  MAX_PERSON_NAME_LENGTH: 70,
  MAX_GENERIC_TEXT_LENGTH: 20,
  MAX_CUSTOMER_REFERENCE_LENGTH: 30,
  MAX_FIRST_NAME_LENGTH: 250,
}

export * from './Booking'
export * from './ContactEntity'
export * from './CourierExchange'
export * from './Customer'
export * from './Driver'
export * from './OtherChargeEntity'
export * from './Reports'
export * from './ScheduledBookingTemplateEntity'
export * from './StrategicPartner'
export * from './TariffEntity'
export * from './VatCategory'
export * from './VehicleConstants'
export * from './Roles'
export * from './Tasks'
export * from './Banner'
export * from './AccountPayable'
