import Joi from 'joi'

import { UpdatePackageOnBoardDtoInput } from 'generated/graphql'

const validationSchema = Joi.object<UpdatePackageOnBoardDtoInput>({
  signedAt: Joi.date().empty('').required().label('Signed at'),
  arrivedAt: Joi.date().empty(null).optional().label('Arrived at'),
  noteFromDriver: Joi.string().empty('').optional().label('Note from driver'),
  signedByFirstName: Joi.string().empty('').optional().label('First name'),
  signedByLastName: Joi.string().empty('').optional().label('Last name'),
  waitingTime: Joi.number().empty(null).optional().min(0).label('Waiting time'),
  packages: Joi.array()
    .optional()
    .items({
      quantity: Joi.number().optional().min(0).label('Quantity'),
    }),
})

export default validationSchema
