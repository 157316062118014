import { useReactiveVar } from '@apollo/client'
import { LinearProgress } from '@mui/material'

import { isPageLoadingVar } from 'graphql/reactiveVariables'

const PageLoadingOverlay = () => {
  const isPageLoading = useReactiveVar(isPageLoadingVar)

  return (
    <>
      {isPageLoading && (
        <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      )}
    </>
  )
}

export default PageLoadingOverlay
