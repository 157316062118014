import { gql } from '@apollo/client'

import { FileDetailsFragment, TariffDetailsFragment } from 'graphql/fragments'

export const GET_PAGED_TARIFFS = gql`
  query GetPagedTariffs(
    $customerId: Long
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [TariffPagedDtoSortInput!]
    $where: TariffPagedDtoFilterInput
  ) {
    pagedTariffs(
      customerId: $customerId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          type
          category
          baseUnit
          minMiles
          rate
          minCharge
          customer
          isExternal
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_USER_PAGED_TARIFFS = gql`
  query GetUserPagedTariffs(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [TariffPagedDtoSortInput!]
    $where: TariffPagedDtoFilterInput
  ) {
    userPagedTariffs(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          category
          type
          tariffIcon {
            id
            name
            vehicleCapacity
            file {
              id
              absoluteUri
            }
          }
          visibilitySequenceNumber
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_TARIFFS_QUERY = gql`
  query GetTariffs {
    tariffs {
      id
      name
      isEnabled
    }
  }
`

export const GET_TARIFFS_FOR_CUSTOMER = gql`
  query GetTariffsForCustomer($customerId: Long!) {
    tariffsForCustomer(customerId: $customerId) {
      id
      tariffCategory {
        id
        courierExchangeVehicleSize
      }
    }
  }
`

// ToDo: rename query to "tariff"
export const GET_TARIFF = gql`
  query GetTariff($id: Long!) {
    tariffById(id: $id) {
      ...TariffDetails

      xeroCode {
        id
        serviceType
      }
    }
    ${TariffDetailsFragment}
  }
`

// ToDo: rename query to "tariff"
export const GET_BOOKING_TARIFF_DETAILS = gql`
  query GetBookingTariff($id: Long!) {
    tariffById(id: $id) {
      id
      name
      tariffCategory {
        id
        name
      }
      type
      isWaitAndReturnEnabled
      minCharge
      minMiles
      rate
      baseUnit
      iconUrl
      isExternal

      xeroCode {
        id
        serviceType
      }

      isDropChargeEnabled
      isUseDefaultDropCharge
      dropChargeRate
    }
  }
`

// ToDo: rename query to "tariff"
export const GET_USER_TARIFF_DETAILS = gql`
  query GetUserTariffDetails($id: Long!) {
    tariffById(id: $id) {
      id
      name
      tariffCategory {
        id
        name
      }
      type
      isWaitAndReturnEnabled
      tariffIcon {
        id
        vehicleCapacity
        file {
          id
          name
          absoluteUri
        }
      }
      isExternal

      xeroCode {
        id
        serviceType
      }
    }
  }
`

export const GET_PAGED_TARIFF_CATEGORIES = gql`
  query GetPagedTariffCategories(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [TariffCategorySortInput!]
    $where: TariffCategoryFilterInput
  ) {
    pagedTariffCategories(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          courierExchangeVehicleSize
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_TARIFF_CATEGORIES = gql`
  query GetTariffCategories {
    tariffCategories {
      id
      name
      createdAt
      createdBy
    }
  }
`

export const GET_TARIFF_CATEGORY = gql`
  query GetTariffCategory($id: Long!) {
    tariffCategory(id: $id) {
      id
      name
      courierExchangeVehicleSize
      hasSpotRate
    }
  }
`

export const GET_TARIFF_CATEGORY_NAME = gql`
  query GetTariffCategoryName($id: Long!) {
    tariffCategory(id: $id) {
      id
      name
    }
  }
`

export const GET_EXTERNAL_TARIFFS = gql`
  query GetExternalTariffs($where: TariffPagedDtoFilterInput, $order: [TariffPagedDtoSortInput!]) {
    pagedTariffs(where: $where, order: $order) {
      edges {
        node {
          id
          visibilitySequenceNumber
        }
      }

      totalCount
    }
  }
`

export const GET_PAGED_TARIFF_ICONS = gql`
  query GetPagedTariffIcons(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: TariffIconFilterInput
    $order: [TariffIconSortInput!]
  ) {
    pagedTariffIcons(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          vehicleCapacity
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
export const GET_TARIFF_ICONS = gql`
  query GetTariffIcons {
    tariffIcons {
      id
      name
      vehicleCapacity
      file {
        id
        ...FileDetails
      }
    }
    
    ${FileDetailsFragment}
  }
`

export const GET_TARIFF_ICON = gql`
  query GetTariffIcon($id: Long!) {
    tariffIcon(id: $id) {
      id
      name
      vehicleCapacity
      fileId
      file {
        id
        ...FileDetails
      }
    }
    
    ${FileDetailsFragment}
  }
`
