import React from 'react'

import { CircularProgress, Modal, styled, TableCell, TableRow } from '@mui/material'
import { format } from 'date-fns'

import { IPoRow } from 'components/bookings/BookingDetails/PoTable'
import { DATE_TIME_FORMAT } from 'constants/index'
import { useGetBookingAddressSignatureQuery, File } from 'generated/graphql'

interface SignatureProps {
  loading?: boolean
  signature?: File
}

const StyledSignature = styled('div')(({ theme }) => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  width: 450,
  height: 450,
  border: '2px solid #000',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}))

const Signature = (props: SignatureProps) => {
  const [viewModal, setViewModal] = React.useState(false)
  if (props.loading) {
    return <CircularProgress />
  }

  const handleImageClick = () => setViewModal(true)
  const handleModalClose = () => setViewModal(false)

  return props.signature ? (
    <div>
      <img
        onClick={handleImageClick}
        src={props.signature.absoluteUri}
        alt='signature'
        width='50'
        height='50'
      />
      <Modal open={viewModal} onClose={handleModalClose}>
        <StyledSignature>
          <img
            onClick={handleImageClick}
            src={props.signature.absoluteUri}
            alt='signature'
            width='400'
            height='400'
          />
        </StyledSignature>
      </Modal>
    </div>
  ) : (
    <></>
  )
}

interface PoRowProps {
  selectedAddressId: string | undefined
  row: IPoRow
}

export const PoRow = (props: PoRowProps) => {
  const { row, selectedAddressId } = props

  const { loading: loadingSignature, data } = useGetBookingAddressSignatureQuery({
    variables: { bookingAddressId: Number(row.id) },
  })

  return (
    <TableRow key={`pod/pob-row-${row.id}`} selected={row.id === selectedAddressId}>
      <TableCell key={`pod/pob-address-${row.id}`}>{row.address}</TableCell>
      <TableCell key={`pod/pob-dateTime-${row.id}`}>
        {row.dateTime && format(new Date(row.dateTime), DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell key={`pod/pob-wtTime-${row.id}`}>{row.wtTime}</TableCell>
      <TableCell key={`pod/pob-cxNumberOfItems-${row.id}`}>{row?.cxNumberOfItems}</TableCell>
      <TableCell key={`pod/pob-cxWeight-${row.id}`}>{row?.cxWeight}</TableCell>
      <TableCell key={`pod/pob-signedBy-${row.id}`}>{row.signedBy}</TableCell>
      <TableCell key={`pod/pob-signature-${row.id}`}>
        <Signature loading={loadingSignature} signature={data?.bookingAddressSignature as File} />
      </TableCell>
    </TableRow>
  )
}

export default PoRow
