import { ISelectorOptions } from 'components/common'
import { TariffCategory, VatCategory } from 'generated/graphql'

export const getTariffCategoryValues = (
  tariffCategories: TariffCategory[] = [],
): ISelectorOptions => {
  return tariffCategories.map((t) => ({ value: t.id, label: t.name }))
}

export const getVatCategoryValues = (vatCategories: VatCategory[] = []): ISelectorOptions => {
  return vatCategories.map((vc) => ({ value: vc.id, label: vc.name }))
}
