import { gql } from '@apollo/client'

export const ExchangeRateDetailsFragment = gql`
  fragment ExchangeRateDetails on ExchangeRate {
    id
    rate

    sourceCurrencyId
    sourceCurrency {
      id
      code
    }

    targetCurrencyId
    targetCurrency {
      id
      code
    }

    createdAt
  }
`
