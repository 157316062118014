import { gql } from '@apollo/client'

export const GET_XERO_SERVICE_TYPES = gql`
  query GetXeroServiceTypes {
    xeroServiceTypes {
      id
      serviceType
      description
    }
  }
`

export const GET_XERO_CODES = gql`
  query GetXeroCodes($where: XeroCodeFilterInput) {
    xeroCodes(where: $where) {
      id
      name
      code
    }
  }
`

export const GET_XERO_CODE = gql`
  query GetXeroCode($id: Long!) {
    xeroCode(id: $id) {
      id
      name
      code
      description
      type
      serviceType
      isInternational
    }
  }
`

export const GET_PAGED_XERO_CODES = gql`
  query GetPagedXeroCodes(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [XeroCodeSortInput!]
    $where: XeroCodeFilterInput
  ) {
    pagedXeroCodes(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          code
          description
          type
          createdAt
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
