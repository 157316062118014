import { useEffect, useState } from 'react'

import DocViewer, { DocViewerRenderers, IConfig, IDocument } from '@cyntler/react-doc-viewer'

import { IFilePreviewWrapperProps } from '../IFilePreviewWrapperProps'

const config: IConfig = {
  header: {
    disableHeader: true,
    disableFileName: true,
    retainURLParams: false,
  },
  csvDelimiter: ',',
  pdfZoom: {
    defaultZoom: 1.0,
    zoomJump: 0.1,
  },
  pdfVerticalScrollByDefault: false,
}

export const DocViewerWrapper = (props: IFilePreviewWrapperProps) => {
  const { file } = props

  const [docs, setDocs] = useState<IDocument[]>([])

  useEffect(() => {
    setDocs([
      {
        uri: `data:${file.getMimeType()};base64,${file.getDataAsBase64()}`,
        fileType: file.getMimeType(),
      },
    ])
  }, [file])

  return <DocViewer config={config} documents={docs} pluginRenderers={DocViewerRenderers} />
}
