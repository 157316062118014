import { Typography } from '@mui/material'

import { FplDialogTitle } from 'components/common/Dialog'

interface TitleProps {
  onClose: () => void
  name: string
}

const DriverUserTitle = ({ onClose, name }: TitleProps) => {
  return (
    <FplDialogTitle id='form-user-driver-dialog-title' onClose={onClose}>
      <Typography paragraph variant='h4'>
        Create user for {name}
      </Typography>
    </FplDialogTitle>
  )
}

export default DriverUserTitle
