import { gql } from '@apollo/client'

export const GET_ADDRESS = gql`
  query GetAddress($id: Long!) {
    address(id: $id) {
      id
      name
      city
      addressLine1
      addressLine2
      postcode
      email
      countryId
      telephoneNumber
      contact
      isNotification
      isAutoFill
      isDefault
    }
  }
`

export const GET_PAGED_CUSTOMER_ADDRESSES = gql`
  query GetPagedAddresses(
    $customerId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [AddressSortInput!]
    $where: AddressFilterInput
  ) {
    addresses(
      customerId: $customerId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          addressLine1
          addressLine2
          city
          postcode
          email
          telephoneNumber
          isDefault
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_CUSTOMER_DEFAULT_ADDRESS = gql`
  query GetCustomerDefaultAddress(
    $customerId: Long!
    $where: AddressFilterInput = { isDefault: { eq: true } }
  ) {
    addresses(customerId: $customerId, where: $where) {
      edges {
        node {
          id
          name
          city
          addressLine1
          addressLine2
          postcode
          countryId
          contact
          email
          telephoneNumber
          isDefault
        }
      }
    }
  }
`

export const GET_LOQATE_FIND = gql`
  query GetLoqateFind(
    $text: String!
    $countryId: Long!
    $container: String
    $order: [LoqateFindDtoSortInput!]
    $where: LoqateFindDtoFilterInput
  ) {
    loqateFind(
      text: $text
      countryId: $countryId
      container: $container
      order: $order
      where: $where
    ) {
      id
      type
      text
      description
      isContainer
      isRetrievable
    }
  }
`

export const GET_LOQATE_RETRIEVE = gql`
  query GetLoqateRetrieve($id: String!) {
    loqateRetrieve(id: $id) {
      id
      name
      postcode
      addressLine1
      addressLine2
      city
      countryId
    }
  }
`

export const GET_DISTANCE_CALCULATIONS = gql`
  query GetDistanceCalculation($input: [GoogleAddressDtoInput!]!) {
    distanceCalculation(input: $input) {
      id
      status
      errorMessage
      distance
    }
  }
`
