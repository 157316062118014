import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

interface IProps {
  control: any
  name: string
  label: string
  defaultValue?: any
  disabled?: boolean
  required?: boolean
  error?: boolean
  helperText?: any
}

const ControlledNumericFormat = (props: IProps) => {
  const { control, name, defaultValue = '', ...otherProps } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, name, value, onChange, onBlur } }) => (
        <NumericFormat
          inputRef={ref}
          name={name}
          value={value}
          onValueChange={({ value }) => onChange(value)}
          onBlur={onBlur}
          decimalScale={2}
          valueIsNumericString
          thousandSeparator
          fixedDecimalScale
          allowNegative={false}
          customInput={TextField}
          fullWidth
          {...otherProps}
        />
      )}
    />
  )
}

export default ControlledNumericFormat
