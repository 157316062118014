import { useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { IdentityUserDtoFilterInput } from 'generated/graphql'
import { GET_PAGED_USERS_QUERY } from 'graphql/queries'

import { ApproveDeclineUser } from './approveDeclineUser'

const columns: IGridColumn[] = [
  { field: 'userName', headerName: 'User Name', minWidth: 200, flex: 1 },
  { field: 'email', headerName: 'Email', minWidth: 200, flex: 1 },
  {
    field: 'emailConfirmed',
    headerName: 'Email Confirmed',
    width: 200,
    type: 'boolean',
  },
  { field: 'companyName', headerName: 'Company Name', minWidth: 200, flex: 1 },
]

const PendingApproval = () => {
  const [openApproveUserDialog, setOpenApproveUserDialog] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)

  const { control, watch, setValue } = useForm({
    defaultValues: { search: '' },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedUserId(Number(id))
    setOpenApproveUserDialog(true)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleCloseApproveUserDialog = () => {
    setSelectedUserId(null)
    setOpenApproveUserDialog(false)
  }

  const filter: IdentityUserDtoFilterInput = useMemo(
    () => ({
      isApproved: { eq: false },
      ...(searchValue && {
        or: [{ userName: { contains: searchValue } }, { email: { contains: searchValue } }],
      }),
    }),
    [searchValue],
  )

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_USERS_QUERY}
        entityName='users'
        columns={columns}
        toolbar={{
          caption: 'Pending Approval',
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
          refreshButton: true,
        }}
        filter={filter}
        onRowClick={handleRowClick}
      />

      {openApproveUserDialog && selectedUserId && (
        <ApproveDeclineUser
          userId={selectedUserId}
          openDialog={openApproveUserDialog}
          onCloseDialog={handleCloseApproveUserDialog}
        />
      )}
    </>
  )
}

export default PendingApproval
