import React from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Paper } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { LoadingBackdrop } from 'components/common'
import { Mode } from 'constants/index'
import {
  CustomerAcquisitionSource,
  PaymentMethod,
  useGetAcquisitionSourcesQuery,
  useGetPaymentMethodsQuery,
  useGetVatCategoriesQuery,
  VatCategory,
  File,
  CreateAndUpdateCustomerDtoInput,
} from 'generated/graphql'

import { AccountDetails } from './AccountDetails'
import { MainDetails } from './MainDetails'
import { OtherDetails } from './OtherDetails'

interface IProps {
  mode: number
  newLogo: any
  customerLoading?: boolean
  isProcessing: boolean
  customerLogo: {
    onUploadCustomerLogo: (file) => void
    onRemoveCustomerLogo: () => void
    uploadingLogo?: boolean
    setNewLogo: React.Dispatch<React.SetStateAction<any>>
    file?: File
  }
}

const CustomerFormControls = (props: IProps) => {
  const { mode, newLogo, customerLoading, isProcessing, customerLogo } = props

  const spacing = 3
  const history = useHistory()
  const {
    formState: { isDirty },
  } = useFormContext<CreateAndUpdateCustomerDtoInput>()

  // Queries
  const { data: paymentMethodData, loading: paymentMethodLoading } = useGetPaymentMethodsQuery()

  const { data: vatCategoryData, loading: vatCategoriesLoading } = useGetVatCategoriesQuery()

  const { data: acquisitionSourceData, loading: acquisitionSourcesLoading } =
    useGetAcquisitionSourcesQuery()

  const loading = paymentMethodLoading || vatCategoriesLoading || acquisitionSourcesLoading

  const isSaveDisabled =
    (mode === Mode.Create && !isDirty) || (mode === Mode.Update && !isDirty && !newLogo)

  const handleCancel = () => {
    // TODO: check if form  is dirty
    history.goBack()
  }

  return (
    <>
      <Grid container spacing={spacing}>
        <Grid item xs={12} lg={6}>
          <Paper
            variant='outlined'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              padding: (theme) => theme.spacing(2),
              minHeight: '640px',
            }}>
            <MainDetails
              mode={mode}
              customerLogo={customerLogo}
              paymentMethods={paymentMethodData?.paymentMethods as PaymentMethod[]}
              vatCategories={vatCategoryData?.vatCategories as VatCategory[]}
            />
          </Paper>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <Paper
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                padding: (theme) => theme.spacing(2),
              }}>
              <AccountDetails
                acquisitionSources={
                  acquisitionSourceData?.acquisitionSources as CustomerAcquisitionSource[]
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                padding: (theme) => theme.spacing(2),
                marginTop: (theme) => theme.spacing(3),
              }}>
              <OtherDetails mode={mode} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ margin: (theme) => theme.spacing(2) }}
              display='flex'
              justifyContent='flex-end'>
              <Button variant='contained' color='grey' onClick={handleCancel}>
                Cancel
              </Button>
              <Box ml={3}>
                <LoadingButton
                  variant='contained'
                  type='submit'
                  loading={isProcessing}
                  disabled={isSaveDisabled}>
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <LoadingBackdrop loading={loading || !!customerLoading} />
    </>
  )
}

export default CustomerFormControls
