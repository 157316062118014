import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { FplDialogTitle } from 'components/common/Dialog'
import { useGetBookingQuery } from 'generated/graphql'

import { AllocatedDriverDetails } from './AllocatedDriverDetails'
import { ChargesDetails } from './ChargesDetails'
import { SelectDriverDetails } from './SelectDriverDetails'

interface IProps {
  bookingId?: string
  openDialog: boolean
  onCloseDialog: () => void
}

const AllocateDrivers = (props: IProps) => {
  const { bookingId, openDialog, onCloseDialog } = props
  const [isFullScreen, setIsFullScreen] = React.useState(false)

  const theme = useTheme()
  const maxWidthLg = useMediaQuery(theme.breakpoints.down('xl'))

  // Queries
  const { data: bookingData } = useGetBookingQuery({
    variables: { bookingId: Number(bookingId) },
  })

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth={maxWidthLg ? 'lg' : false}
      fullScreen={isFullScreen}
      scroll='body'
      aria-labelledby='allocate-drivers-dialog'>
      <FplDialogTitle
        id='allocate-drivers-dialog'
        isFullScreen={isFullScreen}
        onClose={handleCloseDialog}
        onFullScreen={handleFullScreen}>
        Allocate Drivers
      </FplDialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6}>
            <Paper variant='outlined' sx={{ padding: 2 }}>
              <SelectDriverDetails
                booking={bookingData?.booking}
                onAllocateDriverSuccessfully={handleCloseDialog}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} xl={6}>
            <Stack spacing={3}>
              <Paper variant='outlined' sx={{ padding: 2 }}>
                <ChargesDetails booking={bookingData?.booking} />
              </Paper>

              <AllocatedDriverDetails
                bookingId={bookingId}
                bookingStatus={bookingData?.booking?.status}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AllocateDrivers
