import { useEffect, useMemo, useState } from 'react'

import { DocumentNode, useQuery } from '@apollo/client'
import { Box, Grid, Skeleton } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { GridSortItem } from '@mui/x-data-grid'

import { IGridColumn } from 'components/common'
import { IPageState } from 'components/common/FplDataGrid/FplDataGrid'
import { getDefaultSortModel, getPaging } from 'components/common/FplDataGrid/helpers'
import { expandableGridClickedRowIdVar } from 'graphql/reactiveVariables'

import ExpandableTableRow from './ExpandableTableRow'

interface IProps {
  columns: Array<IGridColumn>
  expandedItem: React.ReactElement
  query: DocumentNode
  entityName: string
  queryVariables?: Record<string, any>
  defaultOrder?: GridSortItem
  filter?: any
}

export default function ExpandableGrid(props: IProps) {
  const { columns, expandedItem, query, entityName, queryVariables, defaultOrder, filter } = props

  const [activeIndex, setActiveIndex] = useState(0)

  const [pageState, setPageState] = useState<IPageState>({
    page: 0,
    pageSize: 10,
  })
  const sortModel = useMemo(
    () => getDefaultSortModel(defaultOrder, columns),
    [defaultOrder, columns],
  )
  // query
  const { data, loading, fetchMore, refetch } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.[entityName]?.pageInfo.hasPreviousPage === false && pageState.page !== 0) {
        setPageState((prevState) => ({ ...prevState, page: 0 }))
      }
    },
    variables: {
      where: filter,
      ...queryVariables,
    },
  })

  useEffect(() => {
    const variables = {
      ...getPaging({ first: pageState.pageSize }),
      order: sortModel[0]?.orderInput,
      where: filter,
      ...queryVariables,
    }

    refetch(variables)
  }, [filter, sortModel, pageState.pageSize, queryVariables, refetch])

  const rows: [] = useMemo(() => {
    if (data?.[entityName]?.edges) {
      return data[entityName].edges.map((row) => row.node)
    } else {
      return []
    }
  }, [data, entityName])

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const totalCount = data?.[entityName]?.totalCount

    if (pageState.pageSize * pageState.page > totalCount) {
      return
    }
    const newPageSize = newPage < pageState.page

    const paging = newPageSize
      ? getPaging({ before: data?.[entityName]?.pageInfo.startCursor, last: pageState.pageSize })
      : getPaging({ after: data?.[entityName]?.pageInfo.endCursor, first: pageState.pageSize })

    fetchMore({
      variables: {
        ...paging,
        order: sortModel[0]?.orderInput,
        ...queryVariables,
      },
    })
    setPageState((prev) => ({ ...prev, page: newPage }))
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageState({ page: 0, pageSize: parseInt(event.target.value, 10) })
  }

  if (loading) {
    return <Skeleton />
  }

  expandableGridClickedRowIdVar(activeIndex)

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => {
                return <TableCell key={column.headerName}>{column.headerName}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <ExpandableTableRow
                key={row?.id}
                row={row}
                expandedItem={expandedItem}
                columns={columns}
                expanded={activeIndex === row?.id}
                setRowId={(id) => {
                  setActiveIndex(id)
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={data?.[entityName]?.totalCount ?? 0}
          rowsPerPage={pageState.pageSize}
          page={pageState.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Box>
  )
}
