import { gql } from '@apollo/client'

export const GET_BOOKING_ATTACHMENT = gql`
  query getBookingAttachment($bookingId: Long!, $fileId: Long!) {
    bookingAttachment(bookingId: $bookingId, fileId: $fileId) {
      id
      isAvailableForDriver
      isAvailableForCustomer
      fileId
      file {
        id
        absoluteUri
      }
      bookingId
      createdBy
    }
  }
`

export const GET_BOOKING_ATTACHMENTS = gql`
  query GetBookingAttachments(
    $bookingId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: FileFilterInput
    $order: [FileSortInput!]
  ) {
    bookingAttachments(
      bookingId: $bookingId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          category
          size
          description
          fileOrigin
          originalFileName
          bookingAttachmentId
          bookingAttachment {
            id
            isAvailableForDriver
            isAvailableForCustomer
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
