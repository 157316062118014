import { useCallback, useEffect, useState } from 'react'

import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid'
import { read, utils, WorkSheet } from 'xlsx'

import { IFilePreviewWrapperProps } from '../IFilePreviewWrapperProps'

// This is based on: https://docs.sheetjs.com/docs/demos/grid/mui

type DataSet = { [index: string]: WorkSheet }
type Row = any[]
type RowCol = { rows: Row[]; columns: GridColDef[] }

function arrayify(rows: any[]): Row[] {
  return rows.map((row) => {
    if (Array.isArray(row)) return row
    let length = Object.keys(row).length
    for (; length > 0; --length) if (row[length - 1] != null) break
    return Array.from({ length, ...row })
  })
}

// This method returns `rows` and `columns` data for sheet change
const getRowsCols = (data: DataSet, sheetName: string): RowCol => ({
  rows: utils.sheet_to_json<Row>(data[sheetName], { header: 1 }).map((r, id) => ({ ...r, id })),
  columns: Array.from(
    {
      length: utils.decode_range(data[sheetName]['!ref'] || 'A1').e.c + 1,
    },
    (_, i) => ({ field: String(i), headerName: utils.encode_col(i), editable: true }),
  ),
})

export const XlsxWrapper = (props: IFilePreviewWrapperProps) => {
  const { file } = props

  const [rows, setRows] = useState<Row[]>([]) // Data rows
  const [columns, setColumns] = useState<GridColDef[]>([]) // Columns
  const [workBook, setWorkBook] = useState<DataSet>({} as DataSet) // Workbook
  const [sheets, setSheets] = useState<string[]>([]) // List of sheet names
  const [current, setCurrent] = useState<string>('') // Selected sheet

  useEffect(() => {
    const workbook = read(file.getDataAsArrayBuffer())

    // Update workbook state
    setWorkBook(workbook.Sheets)
    setSheets(workbook.SheetNames)

    // Select the first worksheet
    const name = workbook.SheetNames[0]
    const { rows: new_rows, columns: new_columns } = getRowsCols(workbook.Sheets, name)
    setRows(new_rows)
    setColumns(new_columns)
    setCurrent(name)
  }, [file])

  // Called when sheet dropdown is changed
  function selectSheet(name: string) {
    // Update workbook cache in case the current worksheet was changed
    workBook[current] = utils.aoa_to_sheet(arrayify(rows))

    // Get data for desired sheet and update state
    const { rows: new_rows, columns: new_columns } = getRowsCols(workBook, name)
    setRows(new_rows)
    setColumns(new_columns)
    setCurrent(name)
  }

  const processRowUpdate = useCallback(
    (rowNew: GridRowModel) => {
      for (let j = 0; j < columns.length; ++j)
        if (rowNew[j] != null) rows[rowNew.id][j] = isNaN(+rowNew[j]) ? rowNew[j] : +rowNew[j]

      setRows(rows)
      return rowNew
    },
    [columns, rows],
  )

  return (
    <>
      {sheets.length > 0 && (
        <>
          <p>
            Use the dropdown to switch to a worksheet:&nbsp;
            <select onChange={async (e) => selectSheet(sheets[+e.target.value])}>
              {sheets.map((sheet, idx) => (
                <option key={sheet} value={idx}>
                  {sheet}
                </option>
              ))}
            </select>
          </p>
          <div className='flex-cont'>
            <b>Current Sheet: {current}</b>
          </div>
          <div style={{ width: '100%', height: 400 }}>
            <DataGrid columns={columns} rows={rows} processRowUpdate={processRowUpdate} />
          </div>
        </>
      )}
    </>
  )
}
