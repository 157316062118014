import { useEffect } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import {
  BookingDriverRatingDtoInput,
  useGetBookingDriverRatingQuery,
  useUpdateBookingDriverRatingMutation,
} from 'generated/graphql'

import AddRatingForm from './AddRatingForm'
import validationSchema from './ValidationSchema'

interface Iprops {
  bookingId: number
  driverId: number
  openDialog: boolean
  handleCloseDialog: () => void
}

const AddRatingDialog = (props: Iprops) => {
  const { bookingId, driverId, openDialog, handleCloseDialog } = props

  const methods = useForm<BookingDriverRatingDtoInput>({
    resolver: joiResolver(validationSchema),
    shouldUnregister: true,
    defaultValues: {
      rating: 0,
      ratingComment: '',
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods

  // Queries
  const { data: driverRating } = useGetBookingDriverRatingQuery({
    variables: {
      driverId: driverId,
      bookingId: bookingId,
    },
  })

  const driverRatingData = driverRating?.bookingDriverRating[0]
  const driverName = driverRatingData?.driver.name

  useEffect(() => {
    if (driverRating) {
      reset({
        rating: driverRatingData?.rating || 0,
        ratingComment: driverRatingData?.ratingComment || '',
      })
    }
  }, [driverRating, driverRatingData?.rating, driverRatingData?.ratingComment, reset])

  // Mutations
  const [updateRating, { loading: isUpdatingRating }] = useUpdateBookingDriverRatingMutation({
    onCompleted: () => {
      toast.success('Rating saved successfully')
      handleCloseDialog()
    },
    onError: () => {
      toast.error('The rating was not saved, please try again')
    },
  })

  const onSubmit = (formData) => {
    updateRating({
      variables: {
        input: {
          bookingId: bookingId,
          driverId: driverId,
          rating: formData.rating,
          ratingComment: formData.ratingComment,
        },
      },
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={openDialog}
      aria-labelledby='add-rating-driver-dialog-title'>
      <FplDialogTitle id='add-rating-driver-dialog-title' onClose={handleCloseDialog}>
        <Typography mb={0} paragraph variant='h4'>
          Rating for Suplier: {driverName}
        </Typography>
      </FplDialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form id='add-rating-driver-dialog-title' noValidate>
            <AddRatingForm />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          form='edit-allocated-driver-dialog-form'
          disabled={!isDirty}
          loading={isUpdatingRating}
          onClick={handleSubmit(onSubmit)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddRatingDialog
