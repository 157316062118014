import { Decimal } from 'decimal.js'

function calcBookingConsignmentFee(
  distance: number,
  minMiles: number,
  minCharge: number,
  rate: number,
) {
  let consignmentFee: Decimal

  if (distance <= minMiles) {
    // IF Distance <= Min_Miles
    //    Consignment_Fee := Min_Charge
    consignmentFee = new Decimal(minCharge)
  } else {
    // ELSE
    //    Extra_Miles := Distance – Min_Miles
    //    Consignment_Fee := Min_Charge + Extra_Miles * Rate_Per_Mile
    const extraMiles = distance - minMiles
    consignmentFee = new Decimal(extraMiles).mul(rate).plus(minCharge)
  }

  return consignmentFee.toNumber()
}

export { calcBookingConsignmentFee }
