import { Box, CircularProgress, Grid, Paper, styled, Typography } from '@mui/material'

import { GetSearchResultsQuery } from 'generated/graphql'

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  zIndex: 100,
  left: '0px',
  right: 'auto',
  marginTop: '6px',

  background: 'white',
  height: '500px',
  overflow: 'auto',

  [theme.breakpoints.up('xs')]: {
    width: '250px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.up('md')]: {
    width: '600px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '700px',
  },
}))

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  margin: 0,
  width: '100%',
  paddingTop: theme.spacing(1),
}))

const StyledEntityName = styled(Box)(({ theme }) => ({
  wordBreak: 'break-word',
  paddingRight: theme.spacing(2),
}))

const HigherWidthStyledEntityName = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: { display: 'none' },
}))

const LowerWidthStyledEntityName = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'none' },
}))

const StyledDescription = styled(Grid)({
  cursor: 'pointer',
  borderLeft: '1px solid #ddd',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
})

// https://stackoverflow.com/a/26188945/1578975
const splitPascalCase = (text: string): string =>
  text.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1')

function isCharacterACapitalLetter(char): boolean {
  return /[A-Z]/.test(char)
}

function onlyCapitalLetters(title: string): string {
  let newStr = ''
  for (let i = 0; i < title.length; i++) {
    if (isCharacterACapitalLetter(title[i])) {
      newStr += title[i]
    }
  }
  return newStr
}

interface IProps {
  debouncedInputValue: string | null
  searchResultsData: GetSearchResultsQuery['searchResults'] | undefined
  loading: boolean
  onSearchResultClick: (searchResult: GetSearchResultsQuery['searchResults'][0]) => void
}

const SearchResults = (props: IProps) => {
  const { debouncedInputValue, searchResultsData, loading, onSearchResultClick } = props

  // ToDo: send from back-end part of description to highlight
  const formatDescription = (description: string) => {
    if (debouncedInputValue) {
      const startIndex = description
        .toLocaleLowerCase()
        .indexOf(debouncedInputValue.toLocaleLowerCase())
      if (startIndex !== -1) {
        return (
          <>
            {description.slice(0, startIndex)}
            <strong>
              {description.slice(startIndex, startIndex + debouncedInputValue.length)}
            </strong>
            {description.slice(startIndex + debouncedInputValue.length)}
          </>
        )
      }
    }

    return description
  }

  return (
    <StyledPaper>
      <Box p={1}>
        {loading && (
          <Box display='flex' justifyContent='center'>
            <CircularProgress size={50} style={{ marginTop: 10 }} variant='indeterminate' />
          </Box>
        )}
        {!loading &&
          searchResultsData?.map((searchResult, index) => (
            <StyledGridContainer
              container
              spacing={2}
              key={`searchResults-${searchResult.entityId}-${index}`}>
              <Grid item xs={2}>
                <StyledEntityName>
                  <HigherWidthStyledEntityName>
                    <Typography align='right'>
                      {splitPascalCase(searchResult.entityName)}
                    </Typography>
                  </HigherWidthStyledEntityName>
                  <LowerWidthStyledEntityName>
                    <Typography align='right'>
                      {onlyCapitalLetters(splitPascalCase(searchResult.entityName))}
                    </Typography>
                  </LowerWidthStyledEntityName>
                </StyledEntityName>
              </Grid>

              <StyledDescription item xs={10} onClick={() => onSearchResultClick(searchResult)}>
                <Box>
                  <Typography>{formatDescription(searchResult.description)}</Typography>
                </Box>
              </StyledDescription>
            </StyledGridContainer>
          ))}

        {!loading && searchResultsData?.length === 0 && (
          <Typography align='center'>No results found for your query</Typography>
        )}
      </Box>
    </StyledPaper>
  )
}

export default SearchResults
