import { useState, useEffect } from 'react'

import { Box, Grid } from '@mui/material'
import Decimal from 'decimal.js'
import GoogleMapReact from 'google-map-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import DriverIcon from 'components/bookings/MapRoute/DriverIcon'
import { ALPHABET, BRISTOL_COORDINATES, API_KEY } from 'constants/TrackingMap'
import {
  BookingAddressType,
  DriverLocation,
  GetBookingQuery,
  GetUserBookingQuery,
  GoogleDistanceStatus,
  useUpdateSequenceOrderMutation,
} from 'generated/graphql'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import useMapDistanceCalculation from 'hooks/useMapDistanceCalculation'

import AddressDescription from './AddressDescription'
import MapAddressesForm from './MapAddressesForm'
import UnverifiedAddressInfo from './UnverifiedAddressInfo'

// Function to help us with reordering the result
// Example: https://codesandbox.io/s/4qp6vjp319?file=/index.js
const reorder = (
  list: IOrderableAddressListItem[],
  startIndex,
  endIndex,
): IOrderableAddressListItem[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

interface IOrderableAddressListItem {
  id: string
  name: React.ReactNode
  description: React.ReactNode
  letter: string
  placeId: string | null | undefined
  isVerified: boolean
}

interface IProps {
  bookingId: string | null
  isQuote: boolean
  bookingDetails: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  driverLocations: Array<DriverLocation>
}

const MapWithReorder = (props: IProps) => {
  const { bookingId, isQuote, bookingDetails, driverLocations } = props
  const [addressItems, setAddressItems] = useState<IOrderableAddressListItem[]>([])
  const [optimizedAddresses, setOptimizedAddresses] = useState<IOrderableAddressListItem[]>([])
  const [isOptimizeWaypoints, setIsOptimizeWaypoints] = useState(false)
  const [isMapEnabled, setIsMapEnabled] = useState(true)
  const [key, setKey] = useState(Math.random())

  const bookingAddresses = bookingDetails?.addresses

  const methods = useForm({
    defaultValues: {
      distance: 0,
      consignmentFee: '',
    },
  })
  const { setValue } = methods

  // mutation
  const [updateSequenceOrder, { loading: updatingSequenceOrder }] = useUpdateSequenceOrderMutation({
    onCompleted: () => {
      toast.success(`Booking addresses order was updated with success`)
    },
  })

  const { calculateDistance, distanceCalculationData, distanceCalculationLoading } =
    useMapDistanceCalculation(bookingAddresses)

  useEffect(() => {
    if (bookingAddresses) {
      if (bookingAddresses.filter((x) => !x?.address.isVerified).length !== 0) {
        setIsMapEnabled(false)
      }

      const defaultItems: IOrderableAddressListItem[] =
        bookingAddresses
          .slice()
          .sort((a, b) => a.sequenceOrder - b.sequenceOrder)
          .map((bookingAddress, index) => {
            const name =
              bookingAddress.type === BookingAddressType.Collection ? 'Collection' : 'Delivery'
            const description = <AddressDescription bookingAddress={bookingAddress} />

            return {
              id: bookingAddress.id,
              name,
              description,
              letter: ALPHABET[index] ?? 'n/a',
              placeId: bookingAddress.address.googleMapsPlaceId,
              isVerified: bookingAddress.address.isVerified,
            }
          }) || []

      setAddressItems(defaultItems)
    }
  }, [bookingAddresses, isMapEnabled])

  useEffect(() => {
    if (distanceCalculationData) {
      if (distanceCalculationData.distanceCalculation.status === GoogleDistanceStatus.Success) {
        const distance = new Decimal(distanceCalculationData.distanceCalculation.distance)
          .toDP(2, Decimal.ROUND_FLOOR)
          .toNumber()

        setValue('distance', distance)
      } else if (
        distanceCalculationData.distanceCalculation.status === GoogleDistanceStatus.Error
      ) {
        toast.warning(` ${distanceCalculationData.distanceCalculation.errorMessage}`)
      }
    }
  }, [distanceCalculationData, setValue])

  useEffect(() => {
    isPageLoadingVar(distanceCalculationLoading)
  }, [distanceCalculationLoading])

  useEffect(() => {
    if (bookingAddresses) {
      setKey(Math.random())
    }
  }, [bookingAddresses])

  const handleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService()
    const directionsRenderer = new maps.DirectionsRenderer()
    directionsRenderer.setMap(map)

    let origin: { placeId: string | null | undefined } = { placeId: '' }
    let destination: { placeId: string | null | undefined } = { placeId: '' }
    const waypoints = [] as any // TODO: Type is google.maps.DirectionsWaypoint[]

    addressItems.forEach((address, index) => {
      if (index === 0) {
        // First
        origin = { placeId: address.placeId }
      } else if (index === addressItems.length - 1) {
        // Last
        destination = { placeId: address.placeId }
      } else {
        // In between
        waypoints.push({
          location: { placeId: address.placeId },
          stopover: true,
        })
      }
    })

    // Example: https://developers.google.com/maps/documentation/javascript/examples/directions-waypoints
    const request = {
      origin,
      destination,

      waypoints,
      optimizeWaypoints: isOptimizeWaypoints,

      provideRouteAlternatives: true,
      travelMode: 'DRIVING',
      drivingOptions: {
        departureTime: new Date(),
      },
      unitSystem: maps.UnitSystem.IMPERIAL,
    }

    directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(response)

        if (isOptimizeWaypoints) {
          const geoWaypoints = response.geocoded_waypoints.map((geo) => geo.place_id)
          const optimizedAddresses = addressItems
            .slice()
            .sort((a, b) => geoWaypoints.indexOf(a.placeId) - geoWaypoints.indexOf(b.placeId))
            .map((address, index) => ({ ...address, letter: ALPHABET[index] ?? 'n/a' }))

          setOptimizedAddresses(optimizedAddresses)
          calculateDistance(optimizedAddresses)
        }
      }
    })
  }

  const handleOnDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedAddressItems = reorder(
      addressItems,
      result.source.index,
      result.destination.index,
    )

    const addressItemsWithLetter = reorderedAddressItems.map((addressItem, index) => ({
      ...addressItem,
      letter: ALPHABET[index] ?? 'n/a',
    }))

    setAddressItems(addressItemsWithLetter)
    setKey(Math.random())

    calculateDistance(addressItemsWithLetter)
  }

  const handleOptimizeWaypointsChange = (event) => {
    const checked = event.target.checked

    setIsOptimizeWaypoints(checked)
    setKey(Math.random())

    if (checked === false) {
      calculateDistance(addressItems)
    }
  }

  const handleOnSaveClick: SubmitHandler<{ distance: any; consignmentFee: any }> = ({
    distance,
    consignmentFee,
  }) => {
    let bookingAddresses
    if (!isOptimizeWaypoints) {
      bookingAddresses = addressItems?.map((addressItem, index) => ({
        bookingAddressId: addressItem.id,
        sequenceOrder: index,
      }))
    } else {
      bookingAddresses = optimizedAddresses?.map((addressItem, index) => ({
        bookingAddressId: addressItem.id,
        sequenceOrder: index,
      }))
    }

    bookingId &&
      updateSequenceOrder({
        variables: {
          input: {
            bookingId,
            bookingAddresses,
            distance: distance,
            consignmentFee: consignmentFee,
          },
        },
      }).then(() => {
        setIsOptimizeWaypoints(false)
      })
  }

  return (
    <Grid container>
      <Grid item xs={12} lg={3}>
        <MapAddressesForm
          addressItems={addressItems}
          bookingDetails={bookingDetails}
          handleOnDragEnd={handleOnDragEnd}
          handleOnSaveClick={handleOnSaveClick}
          handleOptimizeWaypointsChange={handleOptimizeWaypointsChange}
          isOptimizeWaypoints={isOptimizeWaypoints}
          isQuote={isQuote}
          methods={methods}
          optimizedAddresses={optimizedAddresses}
          updatingSequenceOrder={updatingSequenceOrder}
        />
      </Grid>
      <Grid item xs={12} lg={9}>
        {isMapEnabled ? (
          <Box height={{ xs: 500, lg: '80vh' }}>
            <GoogleMapReact
              key={key}
              bootstrapURLKeys={{ key: API_KEY }}
              defaultCenter={BRISTOL_COORDINATES}
              defaultZoom={11}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
              {driverLocations.map((l) => (
                <DriverIcon key={l.driverId} lat={Number(l.latitude)} lng={Number(l.longitude)} />
              ))}
            </GoogleMapReact>
          </Box>
        ) : (
          <UnverifiedAddressInfo />
        )}
      </Grid>
    </Grid>
  )
}

export { MapWithReorder }
export type { IOrderableAddressListItem }
