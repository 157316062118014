import { useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import {
  Tariff,
  useGetUserBookingLazyQuery,
  useGetUserTariffDetailsLazyQuery,
} from 'generated/graphql'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import { emitter } from 'providers'
import { TBookingForm } from 'types/form-types'

import ExternalTariffForm from './ExternalTariffForm'
import SelectExternalTariffDialog from './SelectExternalTariffDialog'

interface IProps {
  bookingId: string | undefined
  isQuote: boolean
  disabled?: boolean
}

const ExternalTariff = (props: IProps) => {
  const { bookingId, isQuote, disabled } = props
  const [openSelectTariffDialog, setOpenSelectTariffDialog] = useState(false)

  const { getValues, setValue, register } = useFormContext<TBookingForm>()

  const [getUserBooking, { data: userBookingData }] = useGetUserBookingLazyQuery()

  const [
    getUserTariffDetails,
    {
      data: tariffDetailsData,
      previousData: tariffDetailsPerviousData,
      loading: tariffDetailsLoading,
    },
  ] = useGetUserTariffDetailsLazyQuery({
    notifyOnNetworkStatusChange: true,
  })

  const tariffDetails = tariffDetailsData?.tariffById?.id
    ? tariffDetailsData
    : tariffDetailsPerviousData

  useEffect(() => {
    register('tariffId')
  }, [register])

  useEffect(() => {
    if (bookingId) {
      getUserBooking({
        variables: { bookingId: Number(bookingId) },
      })
    }
  }, [bookingId, getUserBooking])

  const bookingTariffId = Number(userBookingData?.userBooking?.tariffId ?? getValues('tariffId'))
  useEffect(() => {
    if (bookingTariffId) {
      getUserTariffDetails({ variables: { id: bookingTariffId } })
    }
  }, [getUserTariffDetails, bookingTariffId])

  useEffect(() => {
    isPageLoadingVar(tariffDetailsLoading)
  }, [tariffDetailsLoading])

  const handleSelectTariffClick = () => {
    setOpenSelectTariffDialog(true)
  }

  const handleSelectTariff = (id: number) => {
    getUserTariffDetails({ variables: { id } })
    setValue('tariffId', String(id), { shouldDirty: true, shouldValidate: true })
    setOpenSelectTariffDialog(false)

    emitter.emit('add-drop-charge')
  }

  return (
    <>
      <ExternalTariffForm
        tariffDetails={tariffDetails?.tariffById as Tariff}
        isQuote={isQuote}
        disabled={disabled}
        onSelectTariffClick={handleSelectTariffClick}
      />

      {openSelectTariffDialog && (
        <SelectExternalTariffDialog
          open={openSelectTariffDialog}
          setOpen={setOpenSelectTariffDialog}
          onSelectTariff={handleSelectTariff}
        />
      )}
    </>
  )
}

export default ExternalTariff
