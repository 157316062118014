import { useMemo } from 'react'

import { Skeleton } from '@mui/material'
import { GridSortItem } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { allStatusesFilter } from 'components/accountsPayable/AccountPayableFilter/AccountPayableQueryFilters'
import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT } from 'constants/DateFormats'
import { Paths } from 'constants/Paths'
import { AccountPayableRecordFilterInput } from 'generated/graphql'
import { GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY } from 'graphql/queries'

interface IProps {
  driverId: number
}

const columns: IGridColumn[] = [
  {
    field: 'SuplierName',
    headerName: 'Supplier (ID)',
    sortPath: ['bookingInvoice', 'bookingDriver', 'driver', 'name'],
    flex: 0.2,
    valueGetter(params) {
      return (
        params.row.bookingInvoice.bookingDriver.driver.name +
        ' (' +
        params.row.bookingInvoice.bookingDriver.driver.id +
        ')'
      )
    },
  },
  {
    field: 'invoiceNumber',
    headerName: 'Invoice No.',
    sortPath: ['bookingInvoice', 'driverInvoiceNumber'],
    flex: 0.2,
    valueGetter(params) {
      return params.row.bookingInvoice.driverInvoiceNumber
    },
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    sortPath: ['bookingInvoice', 'invoiceDate'],
    flex: 0.3,
    valueGetter(params) {
      const invoiceDate = params.row.bookingInvoice.invoiceDate
      return invoiceDate ? format(parseJSON(invoiceDate as string), DATE_FORMAT) : ''
    },
  },
  {
    field: 'ourReference',
    headerName: 'FPL Number',
    sortable: false,
    flex: 0.3,
    valueGetter(params) {
      return params.row.bookingInvoice.booking.ourReference
    },
  },
  {
    field: 'dueDate',
    headerName: 'Due date',
    sortPath: ['bookingInvoice', 'dueDate'],
    flex: 0.3,
    renderCell(params) {
      const dueDate = params.row.bookingInvoice.dueDate
      return (
        <div style={{ color: 'red' }}>
          {dueDate ? format(parseJSON(params.row.bookingInvoice.dueDate), DATE_FORMAT) : ''}
        </div>
      )
    },
  },

  {
    field: 'status',
    headerName: 'Status',
    flex: 0.3,
    renderCell(params) {
      return <div style={{ color: 'red' }}>{params.row.statusLabel}</div>
    },
  },
]

const descendingOrderByStatus: GridSortItem = {
  field: 'status',
  sort: 'desc',
}

function DriverAccountsPayable({ driverId }: IProps) {
  const history = useHistory()
  const defaultGridId = 'driverAccountPayableRecords'

  const filter: AccountPayableRecordFilterInput = useMemo(() => {
    const statusCategoryFilterInput = allStatusesFilter

    const supplierFilter: AccountPayableRecordFilterInput = {
      bookingInvoice: { bookingDriver: { driver: { id: { eq: driverId } } } },
    }
    return {
      and: [statusCategoryFilterInput, supplierFilter],
    }
  }, [driverId])

  const handleRowClick = (e) => {
    history.push(Paths.accountsPayable.updateWithId(e.id))
  }

  return (
    <>
      {driverId ? (
        <FplDataGrid
          query={GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY}
          overwriteId={defaultGridId}
          refetchWithDelay
          filter={filter}
          defaultOrder={descendingOrderByStatus}
          variableSortOrder={descendingOrderByStatus}
          entityName='accountPayableRecords'
          toolbar={{
            caption: 'Accounts Payable',
          }}
          columns={columns}
          onRowClick={(e) => handleRowClick(e)}
        />
      ) : (
        <Skeleton />
      )}
    </>
  )
}

export default DriverAccountsPayable
