import { AccountPayableTabHash } from 'constants/AccountPayableStatusCategories'

import { useReactiveVar } from '@apollo/client'
import { Box, Button, Grid, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { GridRowId } from '@mui/x-data-grid'
import { accountPayableFiltersDataVar } from 'graphql/reactiveVariables'
import { numberFormat } from 'helpers'

const StyledBox = styled(Box)({
  borderStyle: 'solid',
  borderColor: '#9c27b0',
  border: '1px',
  marginRight: '20px',
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
  borderRadius: '5px',
})

interface IProps {
  handleOpenApproveAllModal: any
  selectedItems?: GridRowId[]
}

const TotalSumDetails = ({ handleOpenApproveAllModal, selectedItems = [] }: IProps) => {
  const tab = window.location.href.split('#')[1]

  const totalSumDetails = useReactiveVar(accountPayableFiltersDataVar)
  const totalRecords = totalSumDetails.totalRecords
  const dataPerCurrency = totalSumDetails.dataPerCurrency

  const disabledOption = selectedItems?.length === 0

  const isFlagged = tab === AccountPayableTabHash.Flagged
  const isPaymentFailed = tab === AccountPayableTabHash.PaymentFailed

  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
      <Grid container item xs={10} spacing={1}>
        <Grid item>
          <StyledBox>
            <Typography>
              Nr of records:
              {dataPerCurrency && dataPerCurrency.length === 0 ? ' 0' : ' ' + totalRecords}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item>
          <StyledBox>
            <Typography>
              Total net:
              {dataPerCurrency && dataPerCurrency.length === 0
                ? ' 0'
                : dataPerCurrency?.map((x: any, index: number) => {
                    let total = ' '
                    if (index !== 0) {
                      total = total + ' + ' + x.currencySymbol + numberFormat(x.net, 0, 2) + ' '
                    } else {
                      total = total + x.currencySymbol + numberFormat(x.net, 0, 2) + ' '
                    }
                    return total
                  })}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item>
          <StyledBox>
            <Typography>
              Total VAT:
              {dataPerCurrency && dataPerCurrency.length === 0
                ? ' 0'
                : dataPerCurrency?.map((x: any, index: number) => {
                    let total = ' '
                    if (index !== 0) {
                      total = total + ' + ' + x.currencySymbol + numberFormat(x.vat, 0, 2) + ' '
                    } else {
                      total = total + x.currencySymbol + numberFormat(x.vat, 0, 2) + ' '
                    }
                    return total
                  })}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item>
          <StyledBox>
            <Typography>
              Total Gross:
              {dataPerCurrency && dataPerCurrency.length === 0
                ? ' 0'
                : dataPerCurrency?.map((x: any, index: number) => {
                    let total = ' '
                    if (index !== 0) {
                      total = total + ' + ' + x.currencySymbol + numberFormat(x.gross, 0, 2) + ' '
                    } else {
                      total = total + x.currencySymbol + numberFormat(x.gross, 0, 2) + ' '
                    }
                    return total
                  })}
            </Typography>
          </StyledBox>
        </Grid>
      </Grid>
      {(isFlagged || isPaymentFailed) && (
        <Grid item>
          <Button variant='contained' onClick={handleOpenApproveAllModal} disabled={disabledOption}>
            Approve All
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default TotalSumDetails
