import { useState } from 'react'

import { LazyQueryExecFunction } from '@apollo/client'
import { Alert, AlertTitle } from '@mui/material'
import { useParams } from 'react-router-dom'

import { LoadingBackdrop } from 'components/common'
import { Mode } from 'constants/index'
import {
  Exact,
  GetReasonCodesQuery,
  GetUserReasonCodesQuery,
  PotentialBookingAlgorithmType,
  useGetPotentialBookingQuery,
  useGetReasonCodesLazyQuery,
  useGetUserReasonCodesLazyQuery,
  useGetCountriesLazyQuery,
  useGetTariffsForCustomerLazyQuery,
} from 'generated/graphql'
import {
  GetErrorMessage,
  TAdditionalData,
  TRequiredAdditionalData,
  TradechoiceGeneralRequiredAdditionalData,
} from 'helpers'
import { GetBookingBasedOnPotentialBooking } from 'helpers'
import { DeltaGroupAppleRequiredAdditionalData } from 'helpers/potential-booking.helpers/DeltaGroupApple'
import { usePermission } from 'providers'

import BookingDetails from './BookingDetails'

const getReasonCodes = async (
  customerId: any,
  isAllowedToViewReasonCodeList: boolean,
  getAdminReasonCodes: LazyQueryExecFunction<
    GetReasonCodesQuery,
    Exact<{
      customerId: any
    }>
  >,
  isAllowedToViewUserReasonCodeList: boolean,
  getUserReasonCodes: LazyQueryExecFunction<
    GetUserReasonCodesQuery,
    Exact<{
      isIncludeSubCustomers: boolean
    }>
  >,
) => {
  if (isAllowedToViewReasonCodeList && customerId) {
    const adminReasonCodes = await getAdminReasonCodes({
      variables: {
        customerId: Number(customerId),
      },
    })
    return adminReasonCodes?.data?.reasonCodes
  } else if (isAllowedToViewUserReasonCodeList) {
    const userReasonCodes = await getUserReasonCodes({
      variables: {
        isIncludeSubCustomers: false,
      },
    })
    return userReasonCodes?.data?.userReasonCodes
  }
}

type RouteParams = {
  id: any
  customerId: any
}

const PotentialBookingDetails = () => {
  const { id, customerId } = useParams<RouteParams>()

  const [error, setError] = useState<string | null>(null)
  const [bookingData, setBookingData] = useState<any | null>(null)

  // Retrieve data
  const [isAllowedToViewReasonCodeList] = usePermission('ViewReasonCodeList')
  const [isAllowedToViewUserReasonCodeList] = usePermission('ViewUserReasonCodeList')

  const [getAdminReasonCodes] = useGetReasonCodesLazyQuery()

  const [getUserReasonCodes] = useGetUserReasonCodesLazyQuery()

  const [getCountries] = useGetCountriesLazyQuery()

  const [getTariffs] = useGetTariffsForCustomerLazyQuery({
    variables: { customerId: Number(customerId) },
  })

  // Queries
  const { loading: potentialBookingLoading } = useGetPotentialBookingQuery({
    variables: { id: Number(id) },
    onCompleted: async (responseData) => {
      if (!responseData.potentialBooking) {
        setError('Could not retrieve Potential Booking.')
        return
      }

      // Process
      const type = responseData?.potentialBooking?.potentialBookingMapping?.type
      let requiredAdditionalData: TRequiredAdditionalData

      switch (type) {
        case PotentialBookingAlgorithmType.TradechoiceGeneral:
          requiredAdditionalData = TradechoiceGeneralRequiredAdditionalData
          break

        case PotentialBookingAlgorithmType.DeltaGroupApple:
          requiredAdditionalData = DeltaGroupAppleRequiredAdditionalData
          break

        default:
          setError("Can't proceed with Potential Booking processing: unknown algorithm type.")
          return
      }

      const additionalData: TAdditionalData = {
        reasonCodes: [],
        countries: [],
        tariffs: [],
      }

      if (requiredAdditionalData.isRequiredReasonCode) {
        const reasonCodes = await getReasonCodes(
          customerId,
          isAllowedToViewReasonCodeList,
          getAdminReasonCodes,
          isAllowedToViewUserReasonCodeList,
          getUserReasonCodes,
        )

        additionalData.reasonCodes = reasonCodes ?? []
      }

      if (requiredAdditionalData.isRequiredCountriesList) {
        const countries = await getCountries()
        additionalData.countries = countries?.data?.countries ?? []
      }

      if (requiredAdditionalData.isRequiredTariffsList) {
        const tariffs = await getTariffs()
        additionalData.tariffs = tariffs?.data?.tariffsForCustomer ?? []
      }

      try {
        setBookingData(GetBookingBasedOnPotentialBooking(customerId, responseData, additionalData))
      } catch (error: unknown) {
        setError(GetErrorMessage(error))
      }
    },
  })

  // Render
  if (error) {
    return (
      <Alert severity='error'>
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    )
  }

  if (potentialBookingLoading || !bookingData) {
    return <LoadingBackdrop loading={true} />
  }

  return <BookingDetails bookingId={null} mode={Mode.Create} preFilledFields={bookingData} />
}

export default PotentialBookingDetails
