import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField } from 'components/common'
import ControlledRating from 'components/common/controller-wrapper/ControlledRating'

const AddRatingForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={1.5}>
        <Grid item xs={12}>
          <ControlledRating
            control={control}
            name='rating'
            defaultValue={0}
            error={!!errors.rating}
            helperText={errors.rating?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label='Comment'
            name='ratingComment'
            multiline
            rows={7}
            error={!!errors.ratingComment}
            helperText={errors.ratingComment?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AddRatingForm
