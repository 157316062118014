import { gql } from '@apollo/client'

export const GET_PERMISSIONS_QUERY = gql`
  query getPermissions($role: String!) {
    policyRoles(role: $role) {
      id
      roleId
      role {
        id
        name
        description
      }
      policyId
      policy {
        id
        name
        description
      }
    }
  }
`
