import React from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'
import {
  ScheduledBookingTemplate,
  useGetScheduledBookingTemplateByBookingIdLazyQuery,
} from 'generated/graphql'

import ScheduledDates from './ScheduledDates'
import ScheduledDays from './ScheduledDays'
import ScheduledHistory from './ScheduledHistory'

enum TabId {
  Days = 0,
  Dates,
  History,
}

function commonProps(index: TabId) {
  return {
    id: `scheduled-booking-tab-${index}`,
    'aria-controls': `scheduled-booking-tabpanel-${index}`,
  }
}

interface IProps {
  bookingId: string | null
}

const ScheduledTemplate = (props: IProps) => {
  const { bookingId } = props

  const [getTemplate, { data: template }] = useGetScheduledBookingTemplateByBookingIdLazyQuery()

  React.useEffect(() => {
    if (bookingId) {
      getTemplate({ variables: { bookingId: Number(bookingId) } })
    }
  }, [bookingId, getTemplate])

  const [tabValue, setTabValue] = React.useState(0)

  const tabs = {
    days: { id: TabId.Days, label: 'Days' },
    dates: { id: TabId.Dates, label: 'Dates' },
    history: { id: TabId.History, label: 'History' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='Scheduled Booking tabs'
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
          width: (theme) => theme.spacing(17),
        }}>
        <Tab label={tabs.days.label} key={tabs.days.id} {...commonProps(tabs.days.id)} />
        <Tab label={tabs.dates.label} key={tabs.dates.id} {...commonProps(tabs.dates.id)} />
        <Tab label={tabs.history.label} key={tabs.history.id} {...commonProps(tabs.history.id)} />
      </Tabs>

      <VerticalTabPanel value={tabValue} index={tabs.days.id} key={tabs.days.id} px={3}>
        {template?.scheduledBookingTemplateByBookingId && (
          <ScheduledDays
            template={
              (template?.scheduledBookingTemplateByBookingId as ScheduledBookingTemplate) ?? null
            }
          />
        )}
      </VerticalTabPanel>

      <VerticalTabPanel value={tabValue} index={tabs.dates.id} key={tabs.dates.id} px={3}>
        <ScheduledDates templateId={template?.scheduledBookingTemplateByBookingId?.id ?? null} />
      </VerticalTabPanel>

      <VerticalTabPanel value={tabValue} index={tabs.history.id} key={tabs.history.id} px={3}>
        <ScheduledHistory templateId={template?.scheduledBookingTemplateByBookingId?.id ?? null} />
      </VerticalTabPanel>
    </Box>
  )
}

export default ScheduledTemplate
