import React from 'react'

import { Button, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { DropzoneDialog } from 'react-mui-dropzone'

import { ControlledTextField } from 'components/common'
import { UpdateEmailTemplateInput } from 'generated/graphql'

const CustomerSignatureFormControls = () => {
  const [openDropZoneDialog, setOpenDropZoneDialog] = React.useState(false)
  const spacing = 3
  const {
    control,
    formState: { errors },
  } = useFormContext<UpdateEmailTemplateInput>()

  const handleSubmitFile = () => {
    setOpenDropZoneDialog(false)
  }

  return (
    <>
      <Grid container spacing={spacing}>
        <Grid item xs={4}>
          <Button variant='contained' color='grey' onClick={() => setOpenDropZoneDialog(true)}>
            Select Icon
          </Button>
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label='Signature'
            name='message'
            defaultValue=''
            multiline
            rows={12}
            required
            error={!!errors.message}
            helperText={errors.message?.message}
          />
        </Grid>
      </Grid>

      <DropzoneDialog
        acceptedFiles={['.jpg']}
        dialogTitle='Upload Icon'
        cancelButtonText='Cancel'
        submitButtonText='Submit'
        open={openDropZoneDialog}
        onClose={() => setOpenDropZoneDialog(false)}
        onSave={handleSubmitFile}
        showPreviews
        showFileNamesInPreview
      />
    </>
  )
}

export default CustomerSignatureFormControls
