import { FormControlLabel, Switch } from '@mui/material'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
  name: string
  label: string
  disabled?: boolean
  required?: boolean
  readonly?: boolean
  defaultValue?: any
  hidden?: boolean
  size?: 'small' | 'medium'
  classes?: { root: string }
}

const ControlledSwitch = (props: IProps) => {
  const {
    control,
    hidden,
    name,
    label,
    required,
    disabled,
    readonly,
    size,
    defaultValue,
    classes,
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          className={classes?.root}
          control={
            <Switch
              onChange={(event) => field.onChange(event.target.checked)}
              checked={field.value}
              size={size}
              required={required}
              disabled={disabled}
              readOnly={readonly}
              hidden={hidden}
            />
          }
        />
      )}
    />
  )
}

export default ControlledSwitch
