import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { Mode } from 'constants/index'
import {
  CreateAndUpdateReasonCodeDtoInput,
  useCreateReasonCodeMutation,
  useUpdateReasonCodeMutation,
} from 'generated/graphql'
import { isNumber } from 'helpers'
import { usePermission } from 'providers'

import { getRefetchQueries } from '../getRefetchQueries'
import ReasonCodeForm from './ReasonCodeForm'
import UpdateReasonCode from './UpdateReasonCode'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}
export default function ReasonCodeDialog(props: IProps) {
  const { id, openDialog, onCloseDialog } = props

  let mode: number | undefined

  if (id === null) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const [isAllowedToViewUserReasonCodeList] = usePermission('ViewUserReasonCodeList')

  const handleClose = () => {
    onCloseDialog()
  }

  const methods = useForm<CreateAndUpdateReasonCodeDtoInput>({
    shouldUnregister: true,
    defaultValues: {
      customerId: null,
      name: '',
      code: '',
      description: '',
    },
  })

  const { handleSubmit } = methods

  // Mutations
  const [createReasonCode, { loading: creatingReasonCode }] = useCreateReasonCodeMutation({
    refetchQueries: getRefetchQueries(isAllowedToViewUserReasonCodeList),
    onCompleted: (data) => {
      toast.success(`Reason Code ${data?.createReasonCode[0].name} was successfully created.`)
      onCloseDialog()
    },
  })

  const [updateReasonCode, { loading: updatingReasonCode }] = useUpdateReasonCodeMutation({
    onCompleted: (data) => {
      toast.success(`Reason Code ${data?.updateReasonCode[0].name} was successfully updated.`)
      onCloseDialog()
    },
  })

  const onSubmitReasonCode: SubmitHandler<CreateAndUpdateReasonCodeDtoInput> = (formData) => {
    if (mode === Mode.Create) {
      createReasonCode({
        variables: {
          input: { ...formData, customerId: Number(formData.customerId) },
        },
      })
    } else {
      updateReasonCode({
        variables: {
          input: {
            ...formData,
            id: Number(id),
            customerId: null,
          },
        },
      })
    }
  }

  const isProcessing = creatingReasonCode || updatingReasonCode

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      scroll='body'
      open={openDialog}
      aria-labelledby='reason-code-dialog'>
      <FplDialogTitle id='reason-code-dialog' onClose={handleClose}>
        <Typography paragraph variant='h4'>
          {mode === Mode.Create ? 'Create' : 'Update'} Reason Code
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? <ReasonCodeForm /> : <UpdateReasonCode id={id ?? 0} />}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          loading={isProcessing}
          onClick={handleSubmit(onSubmitReasonCode)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
