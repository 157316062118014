import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import { FplDialogTitle } from 'components/common'
import { BookingExchangeRate, useGetBookingExchangeRatesQuery } from 'generated/graphql'

import BookingRatesDataGrid from './BookingRatesDataGrid'

interface IProps {
  bookingId: number
  open: boolean
  handleCloseDialog: () => void
}

const BookingExchangeRates = (props: IProps) => {
  const { bookingId, open, handleCloseDialog } = props

  // query
  const { loading, data, refetch } = useGetBookingExchangeRatesQuery({
    nextFetchPolicy: 'network-only',
    variables: {
      bookingId: bookingId,
    },
  })

  // handlers
  const handleRefetchClick = () =>
    refetch({
      bookingId,
    })

  return (
    <Dialog maxWidth='md' fullWidth open={open} aria-label='booking-exchange-rate-dialog'>
      <FplDialogTitle id='booking-exchange-rate-dialog' onClose={handleCloseDialog}>
        <Typography paragraph variant='h4'>
          Booking Exchange Rates {loading && 'Loading...'}
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <BookingRatesDataGrid
          loading={loading}
          exchangeRates={(data?.bookingExchangeRates ?? []) as BookingExchangeRate[]}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Close
        </Button>
        <LoadingButton loading={loading} variant='contained' onClick={handleRefetchClick}>
          Refresh
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default BookingExchangeRates
