import { gql } from '@apollo/client'

import { ActivityDetailsFragment } from 'graphql/fragments'

export const GET_ACTIVITIES_QUERY = gql`
  query getActivities(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ActivitySortInput!]
    $where: ActivityFilterInput
  ) {
    activities(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          ...ActivityDetails
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }

    ${ActivityDetailsFragment}
  }
`

export const GET_ACTIVITY_QUERY = gql`
  query getActivity($id: Long!) {
    activity(id: $id) {
      ...ActivityDetails
    }

    ${ActivityDetailsFragment}
  }
`
