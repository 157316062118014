import { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { LoadingBackdrop } from 'components/common'
import { useGetDriverLazyQuery } from 'generated/graphql'
import { ShowAxiosError } from 'helpers'
import { RegisterSubDriver } from 'services'
import { ICreateDriverUserForm } from 'types/form-types'

import { CreateDriverUserForm } from './CreateDriverUserForm'
import DriverUserTitle from './DriverUserTitle'

interface CreateDriverUserDialogProps {
  open: boolean
  driverId: number | null
  onClose: () => void
}

const defaultValues = {
  driverId: 0,
  email: '',
  firstName: '',
  lastName: '',
  name: '',
  username: '',
}

const CreateDriverUserDialog = (props: CreateDriverUserDialogProps) => {
  const { open, driverId, onClose } = props
  const [creating, setCreating] = useState(false)

  const methods = useForm<ICreateDriverUserForm>({
    defaultValues: defaultValues,
  })

  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = methods

  const [getDriver, { loading: driverLoading, data: driverData }] = useGetDriverLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response.driver) {
        const { id, email, name } = response.driver
        reset({
          driverId: Number(id),
          email,
          name,
        })
      }
    },
  })

  useEffect(() => {
    if (driverId) {
      getDriver({
        variables: {
          driverId: driverId,
        },
      })
    } else {
      reset(defaultValues)
    }
  }, [driverId, reset, getDriver])

  // handlers
  const handleOnClose = () => {
    onClose()
  }

  const registerDriver = (data: ICreateDriverUserForm) => {
    setCreating(true)

    RegisterSubDriver({ ...data, driverId: Number(driverId) })
      .then(() => toast.success('User was created with success'))
      .catch(ShowAxiosError)
      .finally(() => {
        setCreating(false)
        handleOnClose()
      })
  }

  return (
    <Dialog fullWidth maxWidth='md' open={open} aria-labelledby='form-user-driver-dialog'>
      <DriverUserTitle onClose={handleOnClose} name={driverData?.driver?.name || 'Driver'} />

      <DialogContent>
        <FormProvider {...methods}>
          <CreateDriverUserForm />
        </FormProvider>
        <LoadingBackdrop loading={driverLoading} />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' disabled={creating} color='grey' onClick={handleOnClose}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          disabled={!isValid || creating}
          loading={creating}
          onClick={handleSubmit(registerDriver)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateDriverUserDialog
