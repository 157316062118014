import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import OnePointLogo from 'assets/images/onepoint-logo.png'

const Title = () => {
  return (
    <Box display={{ xs: 'none', sm: 'block' }}>
      <Link to='/' style={{ display: 'flex' }}>
        <img style={{ height: 50 }} src={OnePointLogo} alt='OnePoint logo' />
      </Link>
    </Box>
  )
}

export default Title
