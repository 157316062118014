import { ReactNode } from 'react'

import { Box, CircularProgress, IconButton } from '@mui/material'

interface IProps {
  onClick: () => void
  children?: ReactNode
  loading?: boolean
  disabled?: boolean
}

const IconLoadingButton = (props: IProps) => {
  const { children, loading, disabled, onClick } = props

  return (
    <Box style={{ position: 'relative' }}>
      <IconButton disabled={disabled || loading} onClick={onClick} color='inherit' size='large'>
        {children}
      </IconButton>
      {loading && (
        <CircularProgress size={38} style={{ position: 'absolute', top: 5, left: 4, zIndex: 1 }} />
      )}
    </Box>
  )
}
export default IconLoadingButton
