export enum SelectDriversActionKind {
  ADD = 'add',
  CHANGE = 'change',
  REMOVE = 'remove',
  SELECT_VEHICLE = 'select_vehicle',
  SELECT_SUB_DRIVER = 'select_sub_driver',
  SELECT_CONTACT = 'select_contact',
  SELECT_INSTRUCTION = 'select_instruction',
  SET_VEHICLE = 'set_vehicle',
  SET_SUB_DRIVER = 'set_sub_driver',
  SET_CONTACT = 'set_contact',
  SET_INSTRUCTION = 'set_instruction',
  CLEAR_PAYLOAD = 'clear_payload',
  FOCUS_DRIVER = 'focus_driver',
  CLOSE_MODALS = 'close_modals',
  SUBMIT = 'submit',
}
