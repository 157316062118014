import { useEffect, useMemo, useState } from 'react'

import { Box, Grid, Paper } from '@mui/material'
import { endOfDay, startOfMonth } from 'date-fns'

import { CardTitle } from 'components/common/controls'
import { ReportFileType, ReportFileTypeValues } from 'constants/EntityConstants'
import {
  ProofOfDeliveryReportInputDtoInput,
  useGetProofOfDeliveryReportQuery,
} from 'generated/graphql'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadProofOfDeliveryReport } from 'services/RestClient'

import { ReportTable } from '../common/ReportTable'
import FilterCriteria from './FilterCriteria'

const defaultFilter = {
  start: startOfMonth(new Date()),
  end: endOfDay(new Date()),
  customerIds: [],
}

const ProofOfDelivery = () => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [filter, setFilter] = useState<ProofOfDeliveryReportInputDtoInput>(defaultFilter)

  const { data: proofOfDeliveryReportData, fetchMore } = useGetProofOfDeliveryReportQuery({
    variables: { input: filter },
  })

  useEffect(() => {
    fetchMore({ variables: { input: filter } })
  }, [filter, fetchMore])

  const headerRow = useMemo(
    () => proofOfDeliveryReportData?.proofOfDeliveryReport.rows[0],
    [proofOfDeliveryReportData],
  )

  const bodyRows = useMemo(
    () =>
      proofOfDeliveryReportData?.proofOfDeliveryReport.rows.filter((_, index) => {
        if (index !== 0) {
          return true
        }
      }),
    [proofOfDeliveryReportData],
  )

  const handleViewReport = (filter: ProofOfDeliveryReportInputDtoInput) => {
    setFilter(filter)
  }

  const handleDownloadReport = (
    type: ReportFileType,
    filter: ProofOfDeliveryReportInputDtoInput,
  ) => {
    setIsProcessing(true)

    DownloadProofOfDeliveryReport(type, filter)
      .then((response) => {
        setIsProcessing(false)

        let fileName = GetFileName(response)
        if (!fileName) {
          const format = ReportFileTypeValues.find((reportType) => reportType.id === type)?.name
          fileName = `proof-of-delivery.${format}`
        }

        SaveFile(response.data, fileName)
      })
      .catch((error) => {
        ShowRestError(error)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>Filter Criteria</CardTitle>
            <FilterCriteria
              defaultFilter={defaultFilter}
              isProcessing={isProcessing}
              onViewReport={handleViewReport}
              onDownloadReport={handleDownloadReport}
            />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>Report Table</CardTitle>
            <ReportTable headerRow={headerRow} bodyRows={bodyRows} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ProofOfDelivery
