import { useMemo } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledSelector, ISelectorOptions } from 'components/common'
import { TBookingForm } from 'types/form-types'
interface IProps {
  index: number
  isQuickQuote: boolean
  isRequired: boolean
  disabled?: boolean
}

const CollectionSequenceOrder = (props: IProps) => {
  const { index, isQuickQuote, isRequired, disabled } = props

  const {
    control,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const collectionAddressesValue = useWatch({ name: 'collectionAddresses' })

  const collectionSequenceOptions: ISelectorOptions = useMemo(() => {
    const label = isQuickQuote ? 'postcode' : 'name'

    return (
      collectionAddressesValue
        ?.filter((address) => address?.[label])
        .map((address) => ({
          value: address.sequenceOrder,
          label: address[label],
        })) || []
    )
  }, [collectionAddressesValue, isQuickQuote])

  return (
    <ControlledSelector
      emptyValue={{ value: '', label: 'None' }}
      control={control}
      name={`packages.${index}.collectionSequenceOrder`}
      label='Collection'
      options={collectionSequenceOptions}
      defaultValue=''
      required={isRequired}
      disabled={disabled}
      error={!!errors.packages?.[index]?.collectionSequenceOrder}
      helperText={errors.packages?.[index]?.collectionSequenceOrder?.message}
    />
  )
}

export default CollectionSequenceOrder
