import { useState } from 'react'

import { AppBar, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

import { TabPanel } from 'components/common'
import { useHashToSelectTab } from 'helpers'

import { CookiePolicy } from './CookiePolicy'
import { TermsAndConditions } from './TermsAndConditions'

enum TabId {
  TermsAndConditions = 0,
  CookiePolicy,
}

const tabsList = [
  { id: TabId.TermsAndConditions, label: 'Terms And Conditions', hash: '#terms-and-conditions' },
  { id: TabId.CookiePolicy, label: 'Cookie Policy', hash: '#cookie-policy' },
]

const primaryTab = tabsList[0]
const secondaryTabs = tabsList.filter((_, index) => index !== 0)

function commonProps(index: TabId) {
  return {
    id: `help-tab-${index}`,
    'aria-controls': `help-tabpanel-${index}`,
  }
}

const Help = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)

  useHashToSelectTab(tabsList, setTabValue)

  return (
    <>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          aria-label='help tabs'
          TabIndicatorProps={{
            style: {
              transition: 'none',
            },
          }}>
          <Tab
            label={primaryTab.label}
            {...commonProps(primaryTab.id)}
            component={Link}
            to={{ hash: primaryTab.hash }}
          />
          {secondaryTabs.map((tab) => (
            <Tab
              label={tab.label}
              key={tab.id}
              {...commonProps(tab.id)}
              component={Link}
              to={{ hash: tab.hash }}
            />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={TabId.TermsAndConditions}>
        <TermsAndConditions />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.CookiePolicy}>
        <CookiePolicy />
      </TabPanel>
    </>
  )
}

export default Help
