import React from 'react'

type PermissionContextType = {
  isAllowedTo: (permission: string | Array<string>) => boolean
  loading: boolean
}

const defaultBehavior = {
  isAllowedTo: () => false,
  loading: false,
}

const PermissionContext = React.createContext<PermissionContextType>(defaultBehavior)

export default PermissionContext
