import { Box, Container, Stack, Typography } from '@mui/material'

import { AuthHeader } from './common'

const AuthenticatingPage = () => {
  return (
    <Stack>
      <AuthHeader />

      <Container maxWidth='lg'>
        <Stack alignItems='center' textAlign='center' marginTop={5} marginBottom={8}>
          <Typography fontSize='38px' fontFamily='Goldplay-SemiBold, sans-serif'>
            Authentication in progress
          </Typography>

          <Box marginTop={2.5} marginBottom={7}>
            <Typography fontSize='18px' fontFamily='Goldplay-SemiBold, sans-serif'>
              You will be redirected to the login page.
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Stack>
  )
}

export default AuthenticatingPage
