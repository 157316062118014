import { useMemo } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { MedicalServices } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { FplDialogTitle, ControlledAutocomplete, IAutocompleteOptions } from 'components/common'
import { JoiValidationOptions, Paths } from 'constants/index'
import { SortEnumType, useGetCustomersQuery, useGetPotentialBookingQuery } from 'generated/graphql'
import { TPotentialBookingDialogForm } from 'types'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}
const PotentialBookingDialog = (props: IProps) => {
  const { id, openDialog, onCloseDialog } = props
  const { push } = useHistory()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TPotentialBookingDialogForm>({
    resolver: joiResolver(
      Joi.object({
        customerId: Joi.number().required().empty(null),
      }),
      JoiValidationOptions,
    ),
    defaultValues: {
      customerId: null,
    },
  })

  // Queries
  const { data: potentialBookingData, loading: potentialBookingLoading } =
    useGetPotentialBookingQuery({
      variables: { id },
      skip: !openDialog,
    })

  const potentialBooking = potentialBookingData?.potentialBooking

  const { data: customersData, loading: customersLoading } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
    skip: !openDialog,
  })

  const customerOptions: IAutocompleteOptions = useMemo(
    () =>
      customersData?.customers.map((customer) => ({
        value: customer.id,
        label: customer.name,
      })) || [],
    [customersData],
  )

  // Handlers
  const handleClose = () => {
    onCloseDialog()
  }

  const handleCreateBooking = (formData: TPotentialBookingDialogForm) => {
    if (formData.customerId && potentialBooking) {
      push(Paths.potentialBookings.processWithId(potentialBooking.id, formData.customerId))
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        scroll='body'
        open={openDialog}
        aria-labelledby='potential-booking-dialog'>
        <FplDialogTitle id='potential-booking-dialog' onClose={handleClose}>
          <Typography paragraph variant='h4'>
            Potential Booking ID {id}
          </Typography>
        </FplDialogTitle>

        <DialogContent>
          <Box mb={4}>
            <Typography paragraph>
              Booking Key (column name): <strong>{potentialBooking?.bookingIdentifierKey}</strong>
            </Typography>
            <Typography paragraph>
              Booking Value (data in row):{' '}
              <strong>{potentialBooking?.bookingIdentifierValue}</strong>
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography paragraph>
              Customer Key (column name): <strong>{potentialBooking?.customerIdentifierKey}</strong>
            </Typography>
            <Typography paragraph>
              Customer Value (data in row):{' '}
              <strong>{potentialBooking?.customerIdentifierValue}</strong>
            </Typography>
          </Box>

          <Box>
            <Typography paragraph>To proceed, please select Customer:</Typography>

            <ControlledAutocomplete
              control={control}
              label='Customer'
              name='customerId'
              options={customerOptions}
              defaultValue=''
              required
              loading={customersLoading}
              disableClearable
              error={!!errors.customerId}
              helperText={errors.customerId?.message}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='contained' color='grey' onClick={handleClose}>
            Close
          </Button>

          <LoadingButton
            variant='contained'
            startIcon={<MedicalServices />}
            loading={potentialBookingLoading || customersLoading}
            onClick={handleSubmit(handleCreateBooking)}>
            Create Booking
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PotentialBookingDialog
