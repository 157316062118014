import { Box, Stack, Step, StepContent, StepLabel, Stepper, styled } from '@mui/material'

import { orderDetailsDateFormatter } from 'helpers/date.format'
import { DeliveryType, TAdress, TAdresses } from 'types/orderDetails.types'

interface IProps {
  adresses: TAdresses | undefined
}

const StyledBox = styled(Box)({
  opacity: 0.5,
})

const formatAddress = (adress: TAdress) => {
  return `${adress.adressLine1 !== null ? `${adress.adressLine1},` : ''} ${
    adress.adressLine2 !== null ? `${adress.adressLine2},` : ''
  } ${adress.city}, ${adress.countryName}, ${adress.postcode}`
}

function OrderDetailsStepper({ adresses }: IProps) {
  return (
    <Stepper
      orientation='vertical'
      sx={{
        '& .MuiStepConnector-root': {
          display: 'none',
        },
        '& .MuiSvgIcon-root': {
          width: 10,
        },
      }}>
      {adresses &&
        adresses
          .filter((address) => address.type === DeliveryType.collection)
          .map((pickup) => (
            <Step key={pickup.id} active>
              <StepLabel icon=' ' sx={{ p: 0, pl: 1 }}>
                Collection
              </StepLabel>
              <StepContent>
                <Stack>
                  <strong>{orderDetailsDateFormatter(pickup.at ?? '', pickup.by ?? '')}</strong>
                  <StyledBox>{pickup.adress.name}</StyledBox>
                  <StyledBox>{formatAddress(pickup.adress)}</StyledBox>
                </Stack>
              </StepContent>
            </Step>
          ))}
      {adresses &&
        adresses
          .filter((address) => address.type === DeliveryType.delivery)
          .map((delivery) => (
            <Step
              key={delivery.id}
              active
              sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}>
              <StepLabel icon=' ' sx={{ p: 0, pl: 1 }}>
                Delivery
              </StepLabel>
              <StepContent>
                <Stack>
                  <strong>{orderDetailsDateFormatter(delivery.at ?? '', delivery.by ?? '')}</strong>
                  <StyledBox>{delivery.adress.name}</StyledBox>
                  <StyledBox>{formatAddress(delivery.adress)}</StyledBox>
                </Stack>
              </StepContent>
            </Step>
          ))}
    </Stepper>
  )
}

export default OrderDetailsStepper
