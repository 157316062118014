// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Joi from 'joi'

import { Driver, EntityConstants } from 'constants/index'
import { DriverType } from 'generated/graphql'

function validationSchema() {
  return Joi.object({
    // Sub-driver Details
    name: Joi.string().required().max(EntityConstants.MAX_NAME_LENGTH),
    callSign: Joi.string().max(Driver.MAX_CALL_SIGN_LENGTH).allow(''),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .max(EntityConstants.MAX_ADDRESS_LENGTH),
    telephoneNumber: Joi.string().required().max(EntityConstants.MAX_TELEPHONE_NUMBER_LENGTH),
    driverType: Joi.string()
      .valid(...Object.values(DriverType))
      .required(),
    xeroPurchaseCode: Joi.string().allow(''),
    xeroCodeId: Joi.number().allow(null, ''),
    isActive: Joi.boolean().allow(null, '').optional(),
    // Payment Details
    vatCategoryId: Joi.number().required().label('Vat category'),
    bankName: Joi.string().allow(null, '').optional(),
    bankAccountNumber: Joi.string().allow(null, '').optional(),
    bankAccountHolderName: Joi.string().allow(null, '').optional(),
    internationalBankAccountNumber: Joi.string().allow(null, '').optional(),
    vatIdNumber: Joi.number().allow(null, '').optional(),
    bankSortCode: Joi.string().allow(null, '').optional(),
    swift: Joi.string().allow(null, '').optional(),
  })
}

export default validationSchema
