import { useMemo } from 'react'

import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledDatePicker,
  ControlledSelector,
  ControlledTextField,
  ISelectorOptions,
  ControlledTimePicker,
} from 'components/common'
import {
  CreateUpdateTaskDtoInput,
  useGetTaskCategoriesQuery,
  useGetTaskUsersQuery,
} from 'generated/graphql'

const TaskForm = () => {
  const {
    control,

    formState: { errors },
  } = useFormContext<CreateUpdateTaskDtoInput>()

  // queries
  const { data: usersData, loading: usersLoading } = useGetTaskUsersQuery()

  const { data: categoriesData } = useGetTaskCategoriesQuery()

  const userOptions: ISelectorOptions = useMemo(() => {
    if (!usersLoading && usersData?.taskUsers) {
      return usersData.taskUsers.map((u) => ({
        value: u.userName,
        label: `${u.firstName} ${u.lastName}`,
      }))
    }

    return []
  }, [usersLoading, usersData?.taskUsers])

  const categoryOptions: ISelectorOptions = useMemo(
    () =>
      categoriesData?.taskCategories?.map((c) => ({
        value: c.id,
        label: c.name,
      })) || [],
    [categoriesData],
  )

  return (
    <Box pt={1}>
      <Grid container columnSpacing={3} rowSpacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='description'
            label='Description'
            defaultValue=''
            required
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledDatePicker
            control={control}
            name='endDate'
            label='Due'
            required
            error={!!errors.endDate}
            helperText={errors.endDate?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTimePicker
            control={control}
            name='time'
            label='Time'
            error={!!errors.time}
            helperText={errors.time?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledSelector
            control={control}
            name='categoryId'
            label='Category'
            options={categoryOptions}
            emptyValue={{ value: null, label: 'None' }}
            displayEmpty
            error={!!errors.categoryId}
            helperText={errors.categoryId?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledSelector
            control={control}
            name='assignedUserName'
            label='Assigned To'
            options={userOptions}
            defaultValue=''
            required
            error={!!errors.assignedUserName}
            helperText={errors.assignedUserName?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='additionalDetail'
            label='Additional Detail'
            defaultValue=''
            multiline
            rows={4}
            error={!!errors.additionalDetail}
            helperText={errors.additionalDetail?.message}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default TaskForm
