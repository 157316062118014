// import { relayStylePagination } from '@apollo/client/utilities'
import { FieldPolicy, Reference } from '@apollo/client'

type KeyArgs = FieldPolicy<any>['keyArgs']

function makeEmptyData() {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}

function updateCursor<TNode>(
  edges: TInternalRelay<TNode>['edges'],
  index: number,
  cursor: string | undefined,
) {
  if (index < 0) index += edges.length
  const edge = edges[index]
  if (cursor && cursor !== edge.cursor) {
    edges[index] = { ...edge, cursor }
  }
}

type TInternalRelay<TNode> = Readonly<{
  edges: Array<{
    cursor: string
    node: TNode
  }>
  pageInfo: Readonly<{
    hasPreviousPage: boolean
    hasNextPage: boolean
    startCursor: string
    endCursor: string
  }>
}>

function cursorFromEdge<TNode>(edges: TInternalRelay<TNode>['edges'], index: number): string {
  if (index < 0) index += edges.length
  const edge = edges[index]
  return (edge && edge.cursor) || ''
}

// As proof of the flexibility of field policies, this function generates
// one that handles Relay-style pagination, without Apollo Client knowing
// anything about connections, edges, cursors, or pageInfo objects.
function relayStylePagination<TNode = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<TInternalRelay<TNode>> {
  return {
    keyArgs,

    read(existing, { canRead }) {
      if (!existing) return
      const edges = existing.edges.filter((edge) => canRead(edge.node))
      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...existing,
        edges,
        pageInfo: {
          ...existing.pageInfo,
          startCursor: cursorFromEdge(edges, 0),
          endCursor: cursorFromEdge(edges, -1),
        },
      }
    },

    merge(existing = makeEmptyData(), incoming, { args }) {
      if (!args) return existing // TODO Maybe throw?

      const incomingEdges = incoming.edges.slice(0)
      if (incoming.pageInfo) {
        updateCursor(incomingEdges, 0, incoming.pageInfo.startCursor)
        updateCursor(incomingEdges, -1, incoming.pageInfo.endCursor)
      }

      let prefix = existing.edges
      let suffix: typeof prefix = []

      if (args.after) {
        const index = prefix.findIndex((edge) => edge.cursor === args.after)
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
          // suffix = []; // already true
        }
      } else if (args.before) {
        const index = prefix.findIndex((edge) => edge.cursor === args.before)
        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      } else {
        // If we have neither args.after nor args.before, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = []
      }

      const edges = [...prefix, ...incomingEdges, ...suffix]

      const pageInfo = {
        ...incoming.pageInfo,
        ...existing.pageInfo,
        startCursor: cursorFromEdge(edges, 0),
        endCursor: cursorFromEdge(edges, -1),
      }

      const updatePageInfo = (name: keyof TInternalRelay<TNode>['pageInfo']) => {
        const value = incoming.pageInfo[name]
        if (value !== void 0) {
          // ToDo: think why here was semicolon
          ;(pageInfo as any)[name] = value
        }
      }
      if (!prefix.length) updatePageInfo('hasPreviousPage')
      if (!suffix.length) updatePageInfo('hasNextPage')

      return {
        ...existing,
        ...incoming,
        edges,
        pageInfo,
      }
    },
  }
}

// ToDo: add strategic partners here
// TODO: save in cache
// this example is just returning the incoming
const Query = {
  fields: {
    pagedXeroCodes: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    customers: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedCustomers: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedSubCustomers: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    drivers: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    primaryDrivers: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    subDrivers: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedBookingAllocatedDrivers: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    vehicles: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    driverContacts: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    strategicPartners: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    strategicPartnerContacts: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    tariffs: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedTariffs: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    userPagedTariffs: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedTariffCategories: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedTariffIcons: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    // ToDo: rename query to "tariff"
    tariffById: {
      read(_, { args, toReference }) {
        return toReference({
          __typename: 'Tariff',
          id: args.id,
        })
      },
    },
    tariffCategory: {
      read(_, { args, toReference }) {
        return toReference({
          __typename: 'TariffCategory',
          id: args.id,
        })
      },
    },
    customerContacts: {
      keyArgs: ['customerId'],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedCustomerContacts: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    addresses: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookings: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookingLogs: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookingAudit: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    courierExchangeQuotes: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookingInvoice: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedCustomerInvoices: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookingAttachments: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedCharges: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedCongestionZones: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    scheduledBookingDates: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    sentEmails: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    users: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    tasks: {
      keyArgs: ['where', ['bookingId', 'assignedUserName']],
      merge(_, incoming) {
        return incoming
      },
    },
    scheduledExchangeRateTemplates: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    scheduledExchangeRateTasks: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    exchangeRates: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    files: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedExternalDriverInvoices: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedInvoiceLines: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    activities: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    scheduledBookingTasks: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedQuoteLostReasons: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedBanners: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    standardOperatingProcedureHistory: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    standardOperatingProcedures: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedReasonCodes: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    userPagedReasonCodes: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    potentialBookingFiles: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    potentialBookings: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    accountPayableRecords: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    groupedAccountPayableRecords: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    currentDriversLocations: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookingTrackingDashboardData: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    pagedBookingDriverAllocations: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookingInvoiceBatches: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    bookingInvoiceBatchLines: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    accountPayableRecordLogs: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
    driverBidsPaged: {
      keyArgs: [],
      merge(_, incoming) {
        return incoming
      },
    },
  },
}

// export const Query = {
//   fields: {
//     customers: relayStylePagination(),
//     drivers: relayStylePagination(),
//     vehicles: relayStylePagination(),
//     tariffs: relayStylePagination(),
//     customerContacts: relayStylePagination(),
//     addresses: relayStylePagination(),
//   },
// }

export { relayStylePagination, Query }
