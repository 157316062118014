import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { useGetAccountPayableInvoiceQuery } from 'generated/graphql'
import { Restricted } from 'providers'

import DocumentActionPanel from '../DocumentActionPanel/DocumentActionPanel'
import PaperPanel from '../PaperPanel'

function InvoicePanel() {
  const { id } = useParams<{ id?: string }>()

  const { data: invoice, loading } = useGetAccountPayableInvoiceQuery({
    variables: { accountPayableRecordId: Number(id) },
  })
  if (loading) {
    return <LoadingButton loading={loading} />
  }
  return (
    <Stack spacing={2}>
      <Typography variant='h6'>Invoice</Typography>
      <PaperPanel>
        <div
          dangerouslySetInnerHTML={{
            __html: invoice?.accountPayableInvoice.htmlString ?? '',
          }}></div>
      </PaperPanel>
      <DocumentActionPanel
        documentName='Invoice'
        createdAt={new Date(invoice?.accountPayableInvoice.createdAt)}>
        <Restricted to='ViewAccountsPayableRecordDocumentActions'>
          <Button variant='outlined' disabled>
            Approve
          </Button>
        </Restricted>
      </DocumentActionPanel>
    </Stack>
  )
}

export default InvoicePanel
