import { useState } from 'react'

import { Tabs, Tab, AppBar } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { TabPanel } from 'components/common'
import { CustomerEmails } from 'components/settings/CustomerEmails'
import { DriverEmails } from 'components/settings/DriverEmails'
import { Environment } from 'constants/index'
import { CloneProps, useHashToSelectTab } from 'helpers'
import { PermissionType, Restricted } from 'providers'
import { Banners } from './Banners'
import { Currencies } from './Currencies'
import { ExchangeRates } from './ExchangeRates'
import { ImportExportDataTab } from './ImportExportData'
import { InternalEmails } from './InternalEmails'
import { MiscellaneousTab } from './Miscellaneous'
import { SentEmails } from './SentEmails'
import { SurchargesTab } from './Surcharges'
import { TariffsTab } from './Tariffs'
import { TaskSettingsTab } from './TaskSettings'
import Artifacts from './Artifacts'

enum TabId {
  Tariffs = 0,
  Surcharges,
  CustomerEmails,
  DriverEmails,
  InternalEmails,
  SentEmails,
  ExchangeRates,
  Currencies,
  ImportExportData,
  TaskSettings,
  Miscellaneous,
  Banners,
  Artifacts,
}

const tabsList = [
  {
    id: TabId.Tariffs,
    label: 'Tariffs',
    hash: '#tariffs',
    policy: 'ViewTariffTab' as PermissionType,
  },
  {
    id: TabId.Surcharges,
    label: 'Surcharges',
    hash: '#surcharges',
    policy: 'ViewSurchargesTab' as PermissionType,
  },
  {
    id: TabId.CustomerEmails,
    label: 'Customer Emails',
    hash: '#customer-emails',
    policy: 'ViewEmailTemplateList' as PermissionType,
  },
  {
    id: TabId.DriverEmails,
    label: 'Driver Emails',
    hash: '#driver-emails',
    policy: 'ViewEmailTemplateList' as PermissionType,
  },
  {
    id: TabId.InternalEmails,
    label: 'Internal emails',
    hash: '#internal-emails',
    policy: 'ViewEmailTemplateList' as PermissionType,
  },
  {
    id: TabId.SentEmails,
    label: 'Sent Emails',
    hash: '#sent-emails',
    policy: 'ViewSentEmail' as PermissionType,
  },
  {
    id: TabId.ExchangeRates,
    label: 'Exchange Rate',
    hash: '#exchange-rates',
    policy: 'ViewExchangeRateSettings' as PermissionType,
  },
  {
    id: TabId.Currencies,
    label: 'Currencies',
    hash: '#currencies',
    policy: 'ViewCurrenciesTab' as PermissionType,
  },
  {
    id: TabId.ImportExportData,
    label: 'Import/Export Data',
    hash: '#import-export-data',
    policy: 'ViewImportExportDataTab' as PermissionType,
  },
  {
    id: TabId.TaskSettings,
    label: 'Task Settings',
    hash: '#task-settings',
    policy: 'ViewTaskSettings' as PermissionType,
  },
  {
    id: TabId.Miscellaneous,
    label: 'Miscellaneous',
    hash: '#miscellaneous',
    policy: 'ViewMiscellaneousTab' as PermissionType,
  },
  {
    id: TabId.Banners,
    label: 'Banners',
    hash: '#banners',
    policy: 'ViewBannersTab' as PermissionType,
  },
  {
    id: TabId.Artifacts,
    label: 'Artifacts',
    hash: '#artifacts',
    policy: 'ViewMiscellaneousTab' as PermissionType,
  },
]

const isArtifactsHidden = process.env.REACT_APP_ENV === Environment.live

const primaryTab = tabsList[0]
const secondaryTabs = tabsList.filter((tab, index) => index !== 0 && tab.id !== TabId.Artifacts)

function commonProps(index: TabId) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  }
}

const Settings = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)

  const history = useHistory()

  useHashToSelectTab(tabsList, setTabValue)

  const handleTabChange = (_, newValue: TabId) => {
    const selectedTab = tabsList.find((tab) => tab.id === newValue)

    if (selectedTab) {
      history.push(selectedTab.hash)
    }
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='settings tabs'
          variant='scrollable'>
          <CloneProps>
            {(tabProps) => (
              <Restricted to={primaryTab.policy}>
                <Tab
                  {...tabProps}
                  {...commonProps(primaryTab.id)}
                  label={primaryTab.label}
                  value={primaryTab.id}
                />
              </Restricted>
            )}
          </CloneProps>

          {secondaryTabs.map((tab) => (
            <CloneProps key={tab.id}>
              {(tabProps) => (
                <Restricted to={tab.policy}>
                  <Tab {...tabProps} {...commonProps(tab.id)} label={tab.label} value={tab.id} />
                </Restricted>
              )}
            </CloneProps>
          ))}

          {!isArtifactsHidden && (
            <CloneProps>
              {(tabProps) => (
                <Tab
                  {...tabProps}
                  {...commonProps(TabId.Artifacts)}
                  label='Artifacts'
                  value={TabId.Artifacts}
                />
              )}
            </CloneProps>
          )}
        </Tabs>
      </AppBar>

      <Restricted to='ViewTariffTab'>
        <TabPanel value={tabValue} index={TabId.Tariffs} paddingX={0}>
          <TariffsTab />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewSurchargesTab'>
        <TabPanel value={tabValue} index={TabId.Surcharges} paddingX={0}>
          <SurchargesTab />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewEmailTemplateList'>
        <TabPanel value={tabValue} index={TabId.CustomerEmails} paddingX={0}>
          <CustomerEmails />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewEmailTemplateList'>
        <TabPanel value={tabValue} index={TabId.DriverEmails} paddingX={0}>
          <DriverEmails />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewEmailTemplateList'>
        <TabPanel value={tabValue} index={TabId.InternalEmails}>
          <InternalEmails />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewSentEmail'>
        <TabPanel value={tabValue} index={TabId.SentEmails}>
          <SentEmails />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewExchangeRateSettings'>
        <TabPanel value={tabValue} index={TabId.ExchangeRates}>
          <ExchangeRates />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewCurrenciesTab'>
        <TabPanel value={tabValue} index={TabId.Currencies}>
          <Currencies />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewImportExportDataTab'>
        <TabPanel value={tabValue} index={TabId.ImportExportData} paddingX={0}>
          <ImportExportDataTab />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewTaskSettings'>
        <TabPanel value={tabValue} index={TabId.TaskSettings}>
          <TaskSettingsTab />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewMiscellaneousTab'>
        <TabPanel value={tabValue} index={TabId.Miscellaneous}>
          <MiscellaneousTab />
        </TabPanel>
      </Restricted>

      <Restricted to='ViewBannersTab'>
        <TabPanel value={tabValue} index={TabId.Banners}>
          <Banners />
        </TabPanel>
      </Restricted>

      {!isArtifactsHidden && (
        <TabPanel value={tabValue} index={TabId.Artifacts}>
          <Artifacts />
        </TabPanel>
      )}
    </div>
  )
}

export default Settings
