import { useMemo } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledSelector, ISelectorOptions } from 'components/common'
import { TBookingForm } from 'types/form-types'

interface IProps {
  index: number
  isQuickQuote: boolean
  isRequired: boolean
  disabled?: boolean
}
const DeliverySequenceOrder = (props: IProps) => {
  const { index, isQuickQuote, isRequired, disabled } = props

  const {
    control,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const deliveryAddressesValue = useWatch({ name: 'deliveryAddresses' })

  const deliverySequenceOrder: ISelectorOptions = useMemo(() => {
    const label = isQuickQuote ? 'postcode' : 'name'

    return (
      deliveryAddressesValue
        ?.filter((address) => address?.[label])
        .map((address) => ({
          value: address.sequenceOrder,
          label: address[label],
        })) || []
    )
  }, [deliveryAddressesValue, isQuickQuote])

  return (
    <ControlledSelector
      emptyValue={{ value: '', label: 'None' }}
      control={control}
      name={`packages.${index}.deliverySequenceOrder`}
      label='Delivery'
      options={deliverySequenceOrder}
      defaultValue=''
      required={isRequired}
      disabled={disabled}
      error={!!errors.packages?.[index]?.deliverySequenceOrder}
      helperText={errors.packages?.[index]?.deliverySequenceOrder?.message}
    />
  )
}

export default DeliverySequenceOrder
