import { useCallback, useEffect, useState } from 'react'

import { Stack, Tooltip } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { CardTitle } from 'components/common/controls'
import { DefaultDropCharge } from 'constants/EntityConstants/Booking'
import {
  BookingChargeType,
  useDeleteBookingChargeMutation,
  useGetBookingTariffLazyQuery,
} from 'generated/graphql'
import { Restricted, emitter } from 'providers'
import { TBookingChargeForm, TBookingForm } from 'types/form-types'

import ChargeMoreInfoDialog from './ChargeMoreInfoDialog'
import { ChargeTable } from './ChargeTable'
import { ChargeToolbar } from './ChargeToolbar'
import { TChargeToolbarForm } from './ChargeToolbar/chargeToolbar.types'

interface IProps {
  hoverText?: string
  disabled: boolean
}

const OtherCharges = (props: IProps) => {
  const { hoverText = '', disabled } = props
  const [openChargeDescriptionDialog, setOpenChargeDescriptionDialog] = useState(false)
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number | null>(null)

  const { getValues, setValue } = useFormContext<TBookingForm>()
  const { fields, append, update, remove } = useFieldArray({
    name: 'charges',
    keyName: 'chargeKey',
  })

  const [getBookingTariffDetails] = useGetBookingTariffLazyQuery()

  // Mutations
  const [deleteBookingCharge] = useDeleteBookingChargeMutation()

  const handleAddDropCharge = useCallback(async () => {
    const [collectionAddressesValue, deliveryAddressesValue, chargesValue] = getValues([
      'collectionAddresses',
      'deliveryAddresses',
      'charges',
    ])

    const dropQuantity = collectionAddressesValue.length + deliveryAddressesValue.length - 2
    const currentDropCharge = chargesValue?.find((x) => x?.type === BookingChargeType.Drop)

    // More than 2 Addresses
    if (dropQuantity >= 1) {
      const tariffIdValue = getValues('tariffId')
      if (!tariffIdValue) {
        return
      }

      const tariffDetailsResult = await getBookingTariffDetails({
        variables: { id: Number(tariffIdValue) },
      })

      const tariffDetails = tariffDetailsResult.data?.tariffById
      // Check if Tariff exist and Drop Charge is enabled
      if (!tariffDetails || !tariffDetails.isDropChargeEnabled) {
        return
      }

      const rate = tariffDetails.isUseDefaultDropCharge
        ? DefaultDropCharge
        : tariffDetails.dropChargeRate ?? 0

      // Add new Drop Charge
      if (currentDropCharge === undefined) {
        const newDropCharge: TBookingChargeForm = {
          name: 'Drop',
          type: BookingChargeType.Drop,
          quantity: dropQuantity,
          rate,
        }

        append(newDropCharge, { shouldFocus: false })

        return
      }

      // Update current Drop Charge
      const updatedDropCharge = {
        ...currentDropCharge,
        quantity: dropQuantity,
        rate,
      }

      const dropChargeIndex = chargesValue.indexOf(currentDropCharge)
      dropChargeIndex !== -1 && update(dropChargeIndex, updatedDropCharge)

      return
    }

    if (dropQuantity === 0 && currentDropCharge) {
      const dropChargeIndex = chargesValue.indexOf(currentDropCharge)
      dropChargeIndex !== -1 && remove(dropChargeIndex)
    }
  }, [append, getBookingTariffDetails, getValues, remove, update])

  useEffect(() => {
    emitter.on('add-drop-charge', () => handleAddDropCharge())

    return () => emitter.off('add-drop-charge')
  }, [handleAddDropCharge])

  const handleAddCharge = (formData: TChargeToolbarForm) => {
    const { name, type, rate, vatCategoryId, xeroServiceCode, description } = formData
    const chargesValue = getValues().charges
    let existingChargeIndex

    const existingCharge = chargesValue?.find((charge, index) => {
      existingChargeIndex = index
      return charge.name.trim() === name?.trim()
    })

    if (existingCharge) {
      setValue(`charges.${existingChargeIndex}.quantity`, Number(existingCharge.quantity) + 1, {
        shouldDirty: true,
      })
      return
    }

    append({
      name,
      type,
      quantity: 1,
      rate: rate ? rate : 1,
      vatCategoryId,
      xeroServiceCode,
      description,
    })
  }

  const handleOnRemoveClick = (index: number, id: string) => {
    if (id) {
      deleteBookingCharge({ variables: { bookingChargeId: Number(id) } }).then((result) => {
        if (!result.data?.deleteBookingCharge) {
          toast.error('Something went wrong')
        }

        toast.success('Charge was deleted with success')
        remove(index)
      })
    } else {
      remove(index)
    }
  }

  const handleOnInfoClick = (index: number) => {
    setSelectedFieldIndex(index)
    setOpenChargeDescriptionDialog(true)
  }

  const handleCloseChargeDescriptionDialog = () => {
    setOpenChargeDescriptionDialog(false)
  }

  const handleSaveChargeDescriptionDialog = ({
    vatCategoryId,
    xeroServiceCode,
    description,
  }: {
    vatCategoryId: string
    xeroServiceCode: string
    description: string
  }) => {
    setOpenChargeDescriptionDialog(false)

    if (selectedFieldIndex !== null) {
      setValue(`charges.${selectedFieldIndex}.vatCategoryId`, vatCategoryId, {
        shouldDirty: true,
      })
      setValue(`charges.${selectedFieldIndex}.xeroServiceCode`, xeroServiceCode, {
        shouldDirty: true,
      })
      setValue(`charges.${selectedFieldIndex}.description`, description, { shouldDirty: true })
    }
  }

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <CardTitle disabled={disabled}>Other Charges</CardTitle>
        <Stack spacing={2}>
          <Restricted to='AddBookingCharge'>
            <ChargeToolbar disabled={disabled} onAddCharge={handleAddCharge} />
          </Restricted>

          <ChargeTable
            fields={fields}
            disabled={disabled}
            onRemoveClick={handleOnRemoveClick}
            onInfoClick={handleOnInfoClick}
          />
        </Stack>

        {openChargeDescriptionDialog && selectedFieldIndex !== null && (
          <ChargeMoreInfoDialog
            selectedFieldIndex={selectedFieldIndex}
            openChargeDescriptionDialog={openChargeDescriptionDialog}
            onCloseDialog={handleCloseChargeDescriptionDialog}
            onSaveClick={handleSaveChargeDescriptionDialog}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default OtherCharges
