import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DEFAULT_COUNTRY_ID, Mode, Paths } from 'constants/index'
import {
  CreateAndUpdateStrategicPartnerDtoInput,
  ServiceType,
  useCreateStrategicPartnerMutation,
  useUpdateStrategicPartnerMutation,
} from 'generated/graphql'

import StrategicPartnerFormControls from './StrategicPartnerFormControls'
import UpdateStrategicPartner from './UpdateStrategicPartner'

interface IProps {
  strategicPartnerId: number | null
  mode: number | undefined
}

const StrategicPartnerDetails = (props: IProps) => {
  const { strategicPartnerId, mode } = props

  const history = useHistory()

  const methods = useForm<CreateAndUpdateStrategicPartnerDtoInput>({
    shouldUnregister: true,
    defaultValues: {
      name: '',
      serviceType: ServiceType.None,
      city: '',
      addressLine1: '',
      addressLine2: '',
      postcode: '',
      countryId: DEFAULT_COUNTRY_ID,
      email: '',
      telephoneNumber: '',
      vatIdNumber: '',
      eoriNumber: '',
      xeroPurchaseCode: '',
    },
  })

  const { handleSubmit } = methods

  // Mutations
  const [createStrategicPartner, { loading: creatingStrategicPartner }] =
    useCreateStrategicPartnerMutation({
      onCompleted: (data) => {
        toast.success(
          `Strategic Partner ${data?.createStrategicPartner.name} was successfully created.`,
        )
        history.push(Paths.strategicPartners.updateWithId(data?.createStrategicPartner.id))
      },
    })

  const [updateStrategicPartner, { loading: updatingStrategicPartner }] =
    useUpdateStrategicPartnerMutation({
      onCompleted: (data) => {
        toast.success(
          `Strategic Partner ${data?.updateStrategicPartner.name} was successfully updated.`,
        )
      },
    })

  const onSubmitStrategicPartner: SubmitHandler<CreateAndUpdateStrategicPartnerDtoInput> = (
    formData,
  ) => {
    if (mode === Mode.Create) {
      createStrategicPartner({
        variables: {
          input: formData,
        },
      })
    } else {
      updateStrategicPartner({
        variables: {
          input: {
            ...formData,
            id: String(strategicPartnerId),
          },
        },
      })
    }
  }

  const isProcessing = creatingStrategicPartner || updatingStrategicPartner

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmitStrategicPartner)}>
        {mode === Mode.Create ? (
          <StrategicPartnerFormControls isProcessing={isProcessing} />
        ) : (
          <UpdateStrategicPartner
            strategicPartnerId={strategicPartnerId!}
            isProcessing={isProcessing}
          />
        )}
      </form>
    </FormProvider>
  )
}

export default StrategicPartnerDetails
