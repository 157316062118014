import { GridRowModes } from '@mui/x-data-grid'

import { SelectDriversState } from 'components/bookings/BookingDetails/DriverDetails/AllocateDrivers/SelectDriverDetails/SelectDriverDetails'
import { SelectDriversActionKind } from 'constants/EntityConstants/SelectDriversAction'

type SelectDriversAction = {
  type: SelectDriversActionKind
  payload: any
}

export const selectingDriversReducer = (state: SelectDriversState, action: SelectDriversAction) => {
  const { type, payload } = action
  switch (type) {
    case SelectDriversActionKind.ADD:
      return {
        ...state,
        rowMode: {
          ...state.rowMode,
          [payload.driverId]: { mode: GridRowModes.Edit, fieldToFocus: 'minCharge' },
        },
        drivers: [...state.drivers, payload],
      }
    case SelectDriversActionKind.CHANGE:
      return {
        ...state,
        drivers: state.drivers.map((driver) => {
          if (driver.driverId === payload.driverId) {
            return {
              ...driver,
              ...payload,
            }
          }
          return driver
        }),
      }
    case SelectDriversActionKind.REMOVE:
      return {
        ...state,
        rowMode: {
          ...state.rowMode,
          [payload.driverId]: { mode: GridRowModes.View, ignoreModifications: true },
        },
        drivers: state.drivers.filter((driver) => driver.driverId !== payload.driverId),
      }

    case SelectDriversActionKind.SET_VEHICLE:
      return {
        ...state,
        selectVehicle: false,
        focusedDriverId: null,
        drivers: state.drivers.map((driver) => {
          if (driver.driverId === state.focusedDriverId) {
            return {
              ...driver,
              ...payload,
            }
          }

          return driver
        }),
      }

    case SelectDriversActionKind.SET_SUB_DRIVER:
      return {
        ...state,
        selectSubDriver: false,
        focusedDriverId: null,
        drivers: state.drivers.map((driver) => {
          if (driver.driverId === state.focusedDriverId) {
            return {
              ...driver,
              ...payload,
            }
          }
          return driver
        }),
      }

    case SelectDriversActionKind.SET_CONTACT:
      return {
        ...state,
        selectContact: false,
        focusedDriverId: null,
        drivers: state.drivers.map((driver) => {
          if (driver.driverId === state.focusedDriverId) {
            return {
              ...driver,
              ...payload,
            }
          }
          return driver
        }),
      }

    case SelectDriversActionKind.SET_INSTRUCTION:
      return {
        ...state,
        selectInstruction: false,
        focusedDriverId: null,
        drivers: state.drivers.map((driver) => {
          if (driver.driverId === state.focusedDriverId) {
            return {
              ...driver,
              ...payload,
            }
          }
          return driver
        }),
      }

    case SelectDriversActionKind.CLEAR_PAYLOAD: {
      let rowMode = { ...state.rowMode }
      state.drivers.forEach((driver) => {
        rowMode = {
          ...rowMode,
          [driver.driverId]: { mode: GridRowModes.View, ignoreModifications: true },
        }
      })

      return {
        drivers: [],
        focusedDriverId: null,
        selectVehicle: false,
        selectSubDriver: false,
        selectContact: false,
        selectInstruction: false,
        rowMode: rowMode,
      }
    }

    case SelectDriversActionKind.FOCUS_DRIVER:
      return {
        ...state,
        focusedDriverId: payload,
      }

    case SelectDriversActionKind.SELECT_VEHICLE:
      return {
        ...state,
        selectVehicle: true,
        focusedDriverId: payload.driverId,
      }

    case SelectDriversActionKind.SELECT_SUB_DRIVER:
      return {
        ...state,
        selectSubDriver: true,
        focusedDriverId: payload.driverId,
      }

    case SelectDriversActionKind.SELECT_CONTACT:
      return {
        ...state,
        selectContact: true,
        focusedDriverId: payload.driverId,
      }

    case SelectDriversActionKind.SELECT_INSTRUCTION:
      return {
        ...state,
        selectInstruction: true,
        focusedDriverId: payload.driverId,
      }

    case SelectDriversActionKind.CLOSE_MODALS:
      return {
        ...state,
        selectVehicle: false,
        selectSubDriver: false,
        selectContact: false,
        selectInstruction: false,
        focusedDriverId: null,
      }

    case SelectDriversActionKind.SUBMIT: {
      let rowMode = { ...state.rowMode }

      state.drivers.forEach((driver) => {
        rowMode = {
          ...rowMode,
          [driver.driverId]: { mode: GridRowModes.View },
        }
      })

      return {
        ...state,
        rowMode: rowMode,
      }
    }

    default:
      return state
  }
}
