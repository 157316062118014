import { useCallback, useState } from 'react'

import { Stack } from '@mui/material'
import { GridRowId, GridSortItem } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'

import AddRatingDialog from 'components/bookings/BookingDetails/DriverDetails/AllocateDrivers/AllocatedDriverDetails/AddRatingDialog/AddRatingDialog'
import { FplDataGrid } from 'components/common/FplDataGrid'
import { GET_DRIVER_ALLOCATIONS } from 'graphql/queries/DriverQueries'
import useGetBookingDriverRatingColumns from 'hooks/bookingDriver/useGetBookingDriverRatingColumns'

import DriverAverageRating from '../DriverRating/DriverAverageRating'
interface Props {
  id: string
}

function BookingDriverRatingsTab() {
  const [bookingId, setBookingId] = useState<number>()
  const [openRatingDialog, setOpenRatingDialog] = useState(false)
  const { id } = useParams<Props>()

  const descendingOrderByAllocationId: GridSortItem = {
    field: 'id',
    sort: 'desc',
  }

  const handleAddRating = useCallback(
    (bookingId: GridRowId) => () => {
      setBookingId(Number(bookingId))
      setOpenRatingDialog(true)
    },
    [],
  )
  const handelCloseAddRatingDialog = () => setOpenRatingDialog(false)
  const [columns] = useGetBookingDriverRatingColumns({ handleRatingClick: handleAddRating })

  return (
    <Stack spacing={2}>
      <DriverAverageRating />
      <FplDataGrid
        query={GET_DRIVER_ALLOCATIONS}
        queryVariables={{ driverId: Number(id) }}
        entityName='pagedBookingDriverAllocations'
        toolbar={{
          caption: 'Completed or Cancelled Allocations',
        }}
        columns={columns}
        defaultOrder={descendingOrderByAllocationId}
      />
      {id && bookingId && (
        <AddRatingDialog
          driverId={Number(id)}
          bookingId={Number(bookingId)}
          openDialog={openRatingDialog}
          handleCloseDialog={handelCloseAddRatingDialog}
        />
      )}
    </Stack>
  )
}

export default BookingDriverRatingsTab
