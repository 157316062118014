import { useState } from 'react'

import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
import { colorBrewer } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { GET_BOOKING_AUDIT } from 'graphql/queries'

import ViewAuditRecord from './ViewAuditRecord'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'createdAt',
    headerName: 'Date/Time',
    width: 150,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_TIME_FORMAT),
  },
  { field: 'createdBy', headerName: 'User', width: 200 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'primaryKey', headerName: 'Entity ID', width: 100 },
  { field: 'action', headerName: 'Action', width: 100 },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => {
      const jsonValue = JSON.parse(value ?? '{}')
      const textValue = JSON.stringify(jsonValue, null, 2)
      SyntaxHighlighter.registerLanguage('json', json)

      return (
        <SyntaxHighlighter language='json' style={colorBrewer}>
          {textValue}
        </SyntaxHighlighter>
      )
    },
  },
  {
    field: 'transactionId',
    headerName: 'Transaction ID',
    minWidth: 200,
    flex: 0.5,
    sortable: false,
  },
]

interface IProps {
  bookingId: string | null
}

const Audit = (props: IProps) => {
  const { bookingId } = props
  const [selectedAuditRecordId, setSelectedAuditRecordId] = useState<number | null>(null)
  const [openColumnValuesDialog, setOpenColumnValuesDialog] = useState(false)

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedAuditRecordId(Number(id))
    setOpenColumnValuesDialog(true)
  }

  const handleCloseColumnValuesDialog = () => {
    setOpenColumnValuesDialog(false)
  }

  return (
    <>
      <FplDataGrid
        query={GET_BOOKING_AUDIT}
        queryVariables={{ bookingId: Number(bookingId) }}
        entityName='bookingAudit'
        columns={columns}
        defaultOrder={{ field: 'id', sort: 'desc' }}
        toolbar={{ caption: 'Booking Audit' }}
        getRowHeight={() => 'auto'}
        onRowClick={handleRowClick}
      />

      {openColumnValuesDialog && (
        <ViewAuditRecord
          selectedAuditRecordId={selectedAuditRecordId}
          openDialog={openColumnValuesDialog}
          handleCloseDialog={handleCloseColumnValuesDialog}
        />
      )}
    </>
  )
}

export default Audit
