import { useState } from 'react'

import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'

import { VerticalTabPanel } from 'components/common'
import { TaskRelatedTo } from 'generated/graphql'

import { TaskSettings } from './TaskSettings'

enum TabId {
  QuoteTasks,
}

function commonProps(index: TabId) {
  return { id: `setting-tasks-tab-${index}`, 'aria-controls': `setting-tasks-tabpanel-${index}` }
}

export const TaskSettingsTab = () => {
  const [tabValue, setTabValue] = useState(0)

  const tabs = {
    quoteTasks: { id: TabId.QuoteTasks, label: 'Quote tasks' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='settings tasks tabs'
        indicatorColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
          width: (theme) => theme.spacing(27),
        }}>
        <Tab
          label={tabs.quoteTasks.label}
          key={tabs.quoteTasks.id}
          {...commonProps(tabs.quoteTasks.id)}
        />
      </Tabs>

      <VerticalTabPanel value={tabValue} index={tabs.quoteTasks.id} key={tabs.quoteTasks.id} px={3}>
        <TaskSettings relatedTo={TaskRelatedTo.Quote} caption='Quote task settings' />
      </VerticalTabPanel>
    </Box>
  )
}
