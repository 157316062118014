import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledTextField } from 'components/common'
import { useGetMyProfileQuery } from 'generated/graphql'
import { ChangeUserPassword } from 'services'
import { ChangePasswordModel } from 'types'

const validationSchema = Joi.object({
  currentPassword: Joi.string().required().label('Current password'),
  newPassword: Joi.string().required().label('New password').min(8),
  confirmNewPassword: Joi.string()
    .required()
    .label('Confirm password')
    .min(8)
    .valid(Joi.ref('newPassword')),
}).messages({
  'any.only': 'Passwords do not match',
})

const ChangePassword = () => {
  const spacing = 3
  const [serverError, setServerError] = useState('')
  const [changingPassword, setChangingPassword] = useState(false)

  const {
    control,
    handleSubmit,
    reset,

    formState: { isDirty, errors },
  } = useForm<ChangePasswordModel>({
    shouldUnregister: true,
    resolver: joiResolver(validationSchema),
    defaultValues: { currentPassword: '', newPassword: '', confirmNewPassword: '' },
  })

  const { loading: profileLoading, data: profileData } = useGetMyProfileQuery()

  const handleSubmitPress = (values: ChangePasswordModel) => {
    setChangingPassword(true)
    ChangeUserPassword({ ...values, userName: profileData?.myProfile.userName })
      .then(() => {
        toast.success('Password changed successfully')
        setServerError('')
        reset({
          confirmNewPassword: '',
          currentPassword: '',
          newPassword: '',
        })
      })
      .catch((reason: AxiosError) => {
        const error = reason.response?.data.toString() ?? reason.message
        setServerError(error)
      })
      .finally(() => setChangingPassword(false))
  }

  const handleCancelClick = () =>
    reset({ newPassword: '', confirmNewPassword: '', currentPassword: '' })

  return (
    <form onSubmit={handleSubmit((data) => handleSubmitPress(data))}>
      <Card>
        <CardHeader title='Password' subheader='Change password' />
        <Divider />
        <CardContent>
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <Typography color='error' variant='body1'>
                {serverError}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                label='Current password'
                name='currentPassword'
                type='password'
                error={Boolean(errors.currentPassword)}
                helperText={errors.currentPassword?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                label='New password'
                name='newPassword'
                type='password'
                error={Boolean(errors.newPassword)}
                helperText={errors.newPassword?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                label='Confirm new password'
                name='confirmNewPassword'
                control={control}
                type='password'
                error={Boolean(errors.confirmNewPassword)}
                helperText={errors.confirmNewPassword?.message}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <Stack direction='row' justifyContent='flex-end' spacing={1.5} padding={1}>
          <Grid item>
            <Button onClick={handleCancelClick} disabled={!isDirty}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              disabled={!isDirty}
              loading={changingPassword || profileLoading}>
              Submit
            </LoadingButton>
          </Grid>
        </Stack>
      </Card>
    </form>
  )
}

export default ChangePassword
