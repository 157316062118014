import React from 'react'

import { ListItem, ListItemIcon, ListItemText, Tooltip, Zoom } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const ListItemLink = (props: any) => {
  const { icon, primary, to, selected } = props

  const renderLink = React.useMemo(
    // @ts-ignore // ToDo: Fix React v17
    // eslint-disable-next-line react/display-name
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  )

  return (
    <li>
      <Tooltip title={primary} placement='right' arrow TransitionComponent={Zoom}>
        <ListItem button component={renderLink} selected={selected}>
          {icon ? (
            <ListItemIcon
              onClick={() => {
                !selected
              }}>
              {icon}
            </ListItemIcon>
          ) : null}
          <ListItemText
            primary={primary}
            onClick={() => {
              !selected
            }}
          />
        </ListItem>
      </Tooltip>
    </li>
  )
}

export default ListItemLink
