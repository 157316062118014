import { Fragment, ReactElement } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { TableRow, TableCell, IconButton, Typography, Collapse, Box } from '@mui/material'

import { IGridColumn } from 'components/common'

import CellContent from './CellContent'

interface ISecondaryTableProps {
  row: any
  expandedItem: ReactElement
  columns: Array<IGridColumn>
  expanded: boolean
  setRowId: (id: number) => void
}

export default function ExpandableTableRow(props: ISecondaryTableProps) {
  const { row, expandedItem, columns, expanded, setRowId } = props

  const keys = Object.keys(row)
  keys.shift()

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => {
              !expanded ? setRowId(row?.id) : setRowId(0)
            }}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => (
          <TableCell key={column.field}>
            <Typography variant='body2' noWrap>
              <CellContent
                value={row[column.sortPath ? column.sortPath[0] : column.field]}
                paths={column.sortPath}
              />
            </Typography>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 3 }}>{expandedItem}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
