import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { Box, Container, Grid, Stack, styled } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledTextField } from 'components/common'
import { ShowAxiosError } from 'helpers'
import { RegisterCustomer } from 'services'
import { CustomerRegistrationModel } from 'types'

import { PrimaryButton } from '../common'
import SignUpContainer from './SignUpContainer'
import SignUpSuccess from './SignUpSuccess'
import validationSchema from './ValidationSchema'

const StyledTextField = styled(ControlledTextField)({
  '& .MuiFilledInput-root': {
    backgroundColor: 'white',

    ':hover': {
      backgroundColor: 'white',
    },

    '&.Mui-focused': {
      backgroundColor: 'white',
    },
  },
})

const SignUpForm = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerRegistrationModel>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      companyName: '',
      contactEmail: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      userName: '',
      allowExtraEmails: true,
    },
  })

  const handleSubmitForm: SubmitHandler<CustomerRegistrationModel> = (data) => {
    setLoading(true)

    RegisterCustomer(data)
      .then(() => {
        toast.success('Please check your email inbox for the verification action.')
        setSuccess(true)
      })
      .catch(ShowAxiosError)
      .finally(() => setLoading(false))
  }

  if (success) {
    return (
      <SignUpContainer>
        <SignUpSuccess />
      </SignUpContainer>
    )
  }

  return (
    <Box sx={{ backgroundColor: '#f2f2f2' }}>
      <Container maxWidth='lg'>
        <Box sx={{ marginX: { xs: 3, md: 6, lg: 12 }, marginY: 8 }}>
          <Grid container columnSpacing={{ xs: 3, md: 5 }} rowSpacing={4}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                control={control}
                label='Username'
                name='userName'
                variant='filled'
                required
                error={!!errors.userName}
                helperText={errors.userName?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTextField
                control={control}
                label='Contact email'
                name='contactEmail'
                variant='filled'
                required
                error={!!errors.contactEmail}
                helperText={errors.contactEmail?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTextField
                control={control}
                label='First name'
                name='firstName'
                variant='filled'
                required
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTextField
                control={control}
                label='Last name'
                name='lastName'
                variant='filled'
                required
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTextField
                control={control}
                label='Phone number'
                name='phoneNumber'
                variant='filled'
                required
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTextField
                control={control}
                label='Company name'
                name='companyName'
                variant='filled'
                required
                error={!!errors.companyName}
                helperText={errors.companyName?.message}
              />
            </Grid>
          </Grid>

          <Stack direction='row' justifyContent='center' marginTop={5}>
            <Box width='350px'>
              <PrimaryButton loading={loading} onClick={handleSubmit(handleSubmitForm)}>
                Sign up
              </PrimaryButton>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  )
}

export default SignUpForm
