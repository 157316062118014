import { Chip } from '@mui/material'

interface IProps {
  isToday: boolean
  isTomorrow: boolean
  isPast: boolean
}

const DayChip = (props: IProps) => {
  const { isToday, isTomorrow, isPast } = props

  if (isToday) {
    return <Chip label='Today' color='warning' size='small' />
  } else if (isTomorrow) {
    return <Chip label='Tomorrow' size='small' />
  } else if (isPast) {
    return <Chip label='Overdue' color='error' size='small' />
  }

  return null
}

export default DayChip
