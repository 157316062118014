import { useCallback, useMemo, useState } from 'react'

import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import {
  ControlledSelector,
  ControlledTextField,
  FplDataGrid,
  IGridColumn,
  NotificationsDialog,
  ISelectorOptions,
} from 'components/common'
import { DEBOUNCE_DELAY, Roles, DATE_TIME_FORMAT, DATE_FORMAT } from 'constants/index'
import { IdentityUserDtoFilterInput, useDeleteUserMutation } from 'generated/graphql'
import { GET_PAGED_USERS_QUERY } from 'graphql/queries'

import { CreateUpdateUser } from './createUpdateUser'

const AllUsers = () => {
  const [openUserDialog, setOpenUserDialog] = useState(false)
  const [openNotificationsDialog, setOpenNotificationsDialog] = useState(false)
  const [selectedUsername, setSelectedUsername] = useState('')
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)

  const { control, watch, setValue } = useForm({
    defaultValues: {
      search: '',
      role: '',
    },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const selectedRole: string = watch('role', '')

  // mutation
  const [deleteUser, { loading: deleting }] = useDeleteUserMutation({
    refetchQueries: [
      {
        query: GET_PAGED_USERS_QUERY,
        variables: {
          where: { isApproved: { eq: true } },
          after: null,
          before: null,
          first: 10,
          last: null,
        },
      },
    ],
    onCompleted: (data) => {
      if (data?.deleteApplicationUser) {
        toast.success('User was removed with success.')
      } else {
        toast.error('Something went wrong')
      }
    },
  })

  const roleItems: ISelectorOptions = useMemo(
    () =>
      Object.entries(Roles).map(([, value]) => ({
        value: value,
        label: value,
      })),
    [],
  )

  // handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedUserId(Number(id))
    setOpenUserDialog(true)
  }

  const handleAddUser = () => {
    setSelectedUserId(null)
    setOpenUserDialog(true)
  }

  // ToDo: add confirmation dialog
  const handleDeleteUser = useCallback(
    (id: GridRowId) => () => {
      deleteUser({
        variables: {
          id,
        },
      })
    },
    [deleteUser],
  )

  const handleAllNotificationsClick = useCallback(
    (row) => () => {
      setSelectedUsername(row.row.userName)
      setOpenNotificationsDialog(true)
    },
    [setOpenNotificationsDialog, setSelectedUsername],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false)
  }

  const handleCloseNotificationsDialog = () => {
    setOpenNotificationsDialog(false)
    setSelectedUsername('')
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'userName', headerName: 'User Name', minWidth: 200, flex: 0.7 },
      { field: 'firstName', headerName: 'First Name', minWidth: 170, flex: 0.7 },
      { field: 'lastName', headerName: 'Last Name', minWidth: 170, flex: 0.7 },
      { field: 'isEnabled', headerName: 'Active', minWidth: 100, type: 'boolean' },
      { field: 'Address', headerName: 'Address', minWidth: 200, flex: 1, sortable: false },

      { field: 'email', headerName: 'Email', minWidth: 250 },
      { field: 'role', headerName: 'Role', minWidth: 150 },

      {
        field: 'createdDate',
        headerName: 'Created Date',
        minWidth: 120,
        valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
      },
      {
        field: 'lastLoggedIn',
        headerName: 'Last Logged In',
        minWidth: 150,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 100,
        type: 'actions',
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            label='Notifications'
            size='large'
            key={id}
            icon={
              <Tooltip title={'Notifications'} arrow>
                <CircleNotificationsIcon />
              </Tooltip>
            }
            onClick={handleAllNotificationsClick({ row })}
          />,
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleting}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteUser(id)}
          />,
        ],
      },
    ],
    [deleting, handleDeleteUser, handleAllNotificationsClick],
  )

  const filter: IdentityUserDtoFilterInput = useMemo(() => {
    const filterValue: string = searchInput.length >= 3 ? searchInput : ''

    return {
      and: [
        { isApproved: { eq: true } },
        ...(selectedRole !== '' ? [{ role: { eq: selectedRole } }] : []),
      ],
      ...(filterValue && {
        or: [{ userName: { contains: filterValue } }, { email: { contains: filterValue } }],
      }),
    }
  }, [searchInput, selectedRole])

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_USERS_QUERY}
        entityName='users'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'userName', sort: 'asc' }}
        toolbar={{
          caption: 'Users',
          refreshButton: true,
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddUser}>
                New User
              </Button>
            </Grid>
          ),
          rightSide: (
            <>
              <Grid item>
                <Box width='150px'>
                  <ControlledSelector
                    control={control}
                    name='role'
                    label='Role'
                    options={roleItems}
                    size='small'
                    displayEmpty
                    emptyValue={{ value: '', label: 'All Roles' }}
                  />
                </Box>
              </Grid>

              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            </>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openUserDialog && (
        <CreateUpdateUser
          userId={selectedUserId}
          openDialog={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}

      {openNotificationsDialog && (
        <NotificationsDialog
          username={selectedUsername}
          openDialog={openNotificationsDialog}
          onCloseDialog={handleCloseNotificationsDialog}
        />
      )}
    </>
  )
}

export default AllUsers
