import { useEffect, useState } from 'react'

import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ToolbarConfig } from './ToolbarConfig'

// This is based on:
// https://medium.com/@asce4s/integrating-daftjs-with-react-hook-form-1f87907b9ba9

const defaultValue = ''

export interface IHtmlEditorProps {
  content: string | null
  onChange: any
  readOnly?: boolean
  spellCheck?: boolean
  toolbarHidden?: boolean
}
export const HtmlEditor = (props: IHtmlEditorProps) => {
  const { content, onChange, readOnly = false, spellCheck = true, toolbarHidden = false } = props

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    if (!updated) {
      const value = content ?? defaultValue

      // Convert HTML -> DraftJS
      const blocksFromHtml = htmlToDraft(value)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)

      // Update state
      const newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  // Handler
  const editorStateChangeHandler = (editorState) => {
    setUpdated(true)
    setEditorState(editorState)

    // Convert DraftJS -> HTML
    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={editorStateChangeHandler}
      wrapperClassName='wrapper-class'
      editorClassName='editor-class'
      toolbarClassName='toolbar-class'
      toolbarHidden={toolbarHidden}
      toolbar={ToolbarConfig}
      readOnly={readOnly}
      spellCheck={spellCheck}
      // onFocus={(event) => {}} // TODO: Implement
      // onBlur={(event, editorState) => {}} // TODO: Implement
    />
  )
}
