import { gql } from '@apollo/client'

import { OtherChargeDetailsFragment } from 'graphql/fragments'

export const CREATE_OTHER_CHARGE_MUTATION = gql`
  mutation CreateOtherCharge($input: CreateAndUpdateOtherChargeDtoInput!) {
    createOtherCharge(input: $input) {
      id
      ...OtherChargeDetails
    }
    ${OtherChargeDetailsFragment}
  }
`

export const UPDATE_OTHER_CHARGE_MUTATION = gql`
  mutation UpdateOtherCharge($input: CreateAndUpdateOtherChargeDtoInput!) {
    updateOtherCharge(input: $input) {
      id
      ...OtherChargeDetails
    }
    ${OtherChargeDetailsFragment}
  }
`

export const DELETE_OTHER_CHARGE_MUTATION = gql`
  mutation DeleteOtherCharge($id: Long!) {
    deleteOtherCharge(id: $id)
  }
`
