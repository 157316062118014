import React from 'react'

import { Box, Button, Grid } from '@mui/material'
import { toast } from 'react-toastify'

import { CardTitle } from 'components/common'
import { useDeleteActivityMutation } from 'generated/graphql'
import { Restricted } from 'providers/PermissionProvider'

import { CreateUpdateActivity } from './CreateUpdateActivity'
import HistoryList from './HistoryList'

interface IProps {
  bookingId: string | null
}

const History = (props: IProps) => {
  const { bookingId } = props

  const [selectedActivityId, setSelectedActivityId] = React.useState<number | null>(null)
  const [openActivityDialog, setOpenActivityDialog] = React.useState(false)

  // mutations
  const [deleteActivity] = useDeleteActivityMutation({
    onCompleted: () => toast.success('Activity was deleted with success.'),
  })

  // handlers
  const handleLogActivity = () => {
    setSelectedActivityId(null)
    setOpenActivityDialog(true)
  }

  const handleActivityClick = (id) => {
    setSelectedActivityId(Number(id))
    setOpenActivityDialog(true)
  }

  const handleDeleteClick = (id) => {
    deleteActivity({
      variables: { id: Number(id) },
      update(cache) {
        cache.modify({
          fields: {
            activities(existingActivities = {}, { readField }) {
              const edges = existingActivities.edges.filter(
                (activityEdge) => id !== readField('id', activityEdge.node),
              )

              return { ...existingActivities, edges }
            },
          },
        })
      },
    })
  }

  const handleCloseActivityDialog = () => {
    setOpenActivityDialog(false)
  }

  return (
    <>
      <Box paddingX={2} paddingTop={2}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <CardTitle>History</CardTitle>
          </Grid>
          {bookingId && (
            <Restricted to='CreateActivity'>
              <Grid item>
                <Button variant='outlined' onClick={handleLogActivity}>
                  Log Activity
                </Button>
              </Grid>
            </Restricted>
          )}
        </Grid>
      </Box>

      <HistoryList
        bookingId={bookingId}
        onActivityClick={handleActivityClick}
        onDeleteClick={handleDeleteClick}
      />

      {openActivityDialog && (
        <CreateUpdateActivity
          id={selectedActivityId}
          bookingId={bookingId}
          openDialog={openActivityDialog}
          onCloseDialog={handleCloseActivityDialog}
        />
      )}
    </>
  )
}

export default History
