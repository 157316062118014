import { useState, useMemo } from 'react'

import { Button, Grid } from '@mui/material'

import { BookingStatus, GetBookingQuery } from 'generated/graphql'

import { PobDetails } from '../PobDetails'
import { PodDetails } from '../PodDetails'

interface IProps {
  booking: GetBookingQuery['booking']
  hoverText: string
  disabled: boolean
}

const PobPodButtons = (props: IProps) => {
  const { booking, hoverText, disabled } = props
  const [openPobDetailsDialog, setOpenPobDetailsDialog] = useState<boolean>(false)
  const [openPodDetailsDialog, setPodDetailsDialog] = useState<boolean>(false)

  const bookingId = booking?.id

  const handleViewPob = () => setOpenPobDetailsDialog(true)

  const handleViewPod = () => setPodDetailsDialog(true)

  const handleClosePobDetailsDialog = () => setOpenPobDetailsDialog(false)

  const handleClosePodDialog = () => setPodDetailsDialog(false)

  const isPobPodButtonsDisabled = useMemo(
    () => !bookingId || booking?.status === BookingStatus.Waiting,
    [bookingId, booking?.status],
  )

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item>
          <Button
            variant='contained'
            color='grey'
            disabled={isPobPodButtonsDisabled}
            onClick={handleViewPob}>
            View POB
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant='contained'
            color='grey'
            disabled={isPobPodButtonsDisabled}
            onClick={handleViewPod}>
            View POD
          </Button>
        </Grid>
      </Grid>

      {openPobDetailsDialog && bookingId && (
        <PobDetails
          bookingId={bookingId}
          openDialog={openPobDetailsDialog}
          hoverText={hoverText}
          disabled={disabled}
          handleCloseDialog={handleClosePobDetailsDialog}
        />
      )}

      {openPodDetailsDialog && bookingId && (
        <PodDetails
          bookingId={bookingId}
          openDialog={openPodDetailsDialog}
          hoverText={hoverText}
          disabled={disabled}
          handleCloseDialog={handleClosePodDialog}
        />
      )}
    </>
  )
}

export default PobPodButtons
