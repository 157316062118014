import React from 'react'

import { Dialog, DialogContent } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  CreateAndUpdateCongestionZoneDtoInput,
  CreateAndUpdateCongestionZonePostcodeDtoInput,
  useCreateCongestionZoneMutation,
  useCreateCongestionZonePostcodeMutation,
  useDeleteCongestionZonePostcodeMutation,
  useUpdateCongestionZoneMutation,
  useUpdateCongestionZonePostcodeMutation,
  Weekday,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'

import CongestionDialogActions from './CongestionDialogActions'
import CongestionDialogContent from './CongestionDialogContent'
import CongestionZoneDialogTitle from './CongestionZoneDialogTitle'

interface IProps {
  congestionZoneId?: string
  openDialog: boolean
  handleCloseDialog: () => void
}

export default function CreateUpdateCongestionZoneDialog(props: IProps) {
  const { openDialog, congestionZoneId, handleCloseDialog } = props
  const [notFound, setNotFound] = React.useState(false)

  const [postcodes, setPostcodes] = React.useState<
    Array<CreateAndUpdateCongestionZonePostcodeDtoInput>
  >([])
  const [congestionZone, setCongestionZone] = React.useState<CreateAndUpdateCongestionZoneDtoInput>(
    {
      id: congestionZoneId,
      isAppliedAutomatically: false,
      isAttachedToAllTariffs: true,
      durationFrom: new Date(),
      durationTo: new Date(),
      durationFromHours: 0,
      durationFromMin: 0,
      durationToHours: 0,
      durationToMin: 0,
      customerCharge: '',
      name: '',
      weekdays: [],
      xeroServiceCode: '',
      is24Hours: true,
      timeZoneId: '1',
    },
  )

  const [selectedWeekdays, setSelectedWeekdays] = React.useState<Array<Weekday>>([])

  const methods = useForm<CreateAndUpdateCongestionZoneDtoInput>({
    shouldUnregister: true,
    defaultValues: { ...congestionZone },
  })

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  const {
    handleSubmit,
    reset,
    trigger,
    formState: { isDirty },
  } = methods

  React.useEffect(() => {
    reset({ ...congestionZone })
    trigger()
  }, [congestionZone, reset, trigger])

  const [createCongestionZone, { loading: creating }] = useCreateCongestionZoneMutation({
    onCompleted: (data) => {
      if (data.createCongestionZone) {
        toast.success(`Special Zone ${data.createCongestionZone.name} was created with success.`)
        handleCloseDialog()
      }
    },
  })

  const [updateCongestionZone, { loading: updating }] = useUpdateCongestionZoneMutation({
    onCompleted: (data) => {
      if (data.updateCongestionZone) {
        const { __typename, tariffs, weekdaysCollection, ...congestionZoneData } =
          data.updateCongestionZone

        const tariffIds: any = tariffs
          ? tariffs.map((t) => ({ value: t.tariff.id, label: t.tariff.name }))
            : []

        setCongestionZone({
          ...congestionZoneData,
          tariffIds,
          weekdays: weekdaysCollection,
          postcodeNames: null,
          is24Hours: true,
          timeZoneId: '1',
          durationFromHours: 0,
          durationFromMin: 0,
          durationToHours: 0,
          durationToMin: 0,
        })
        toast.success(`Special Zone ${data.updateCongestionZone.name} was updated with success.`)
        handleCloseDialog()
      }
    },
  })

  const [addPostcode, { loading: creatingPostcode }] = useCreateCongestionZonePostcodeMutation({
    onCompleted: (response) => {
      if (response) {
        setPostcodes([
          ...postcodes,
          {
            id: response.createCongestionZonePostcode.id,
            name: response.createCongestionZonePostcode.name,
            congestionZoneId: response.createCongestionZonePostcode.congestionZoneId,
          },
        ])
      }
    },
  })

  const [updatePostcode, { loading: updatingPostcode }] = useUpdateCongestionZonePostcodeMutation({
    onCompleted: (response) => {
      if (response) {
        setPostcodes([
          ...postcodes.filter((p) => p.id !== response.updateCongestionZonePostcode.id),
          {
            id: response.updateCongestionZonePostcode.id,
            name: response.updateCongestionZonePostcode.name,
            congestionZoneId: response.updateCongestionZonePostcode.congestionZoneId,
          },
        ])
      }
    },
  })

  const [deletePostcode, { loading: deletingPostcode }] = useDeleteCongestionZonePostcodeMutation()

  const handleAddPostcode = (name: string) => {
    if (!congestionZoneId) {
      setPostcodes([...postcodes, { name, id: (postcodes.length + 1).toString() }])
    } else {
      addPostcode({
        variables: {
          input: {
            name,
            congestionZoneId,
          },
        },
      })
    }
  }

  const handleEditPostcode = (id: string, name: string) => {
    if (!congestionZoneId) {
      setPostcodes([...postcodes.filter((p) => p.id !== id), { id, name }])
    } else {
      updatePostcode({
        variables: {
          input: {
            id,
            name,
            congestionZoneId,
          },
        },
      })
    }
  }

  const handleDeletePostcode = (id: string) => {
    if (!congestionZoneId) {
      setPostcodes(postcodes.filter((p) => p.id !== id))
    } else {
      setPostcodes(postcodes.filter((p) => p.id !== id))
      deletePostcode({ variables: { id: Number(id) } })
    }
  }

  const onSubmit = (data: CreateAndUpdateCongestionZoneDtoInput) => {
    const fromDate = new Date(data.durationFrom)
    const toDate = new Date(data.durationTo)
    const normalizedProps = () => ({
      customerCharge: Number(data.customerCharge),
      durationFrom: fromDate,
      durationTo: toDate,
      durationFromHours: fromDate.getHours(),
      durationFromMin: fromDate.getMinutes(),
      durationToHours: toDate.getHours(),
      durationToMin: toDate.getMinutes(),
      tariffIds:
        data.tariffIds && data.tariffIds.length > 0 ? data.tariffIds.map((x: any) => x.value) : [],
      timeZoneId: Number(data.timeZoneId),
    })

    if (!congestionZoneId) {
      createCongestionZone({
        variables: {
          input: {
            ...data,
            ...normalizedProps(),
            postcodeNames: postcodes.map((p) => p.name),
          } as CreateAndUpdateCongestionZoneDtoInput,
        },
      })
    } else {
      updateCongestionZone({
        variables: {
          input: { ...data, id: congestionZoneId, ...normalizedProps() },
        },
      })
    }
  }

  const handleOnCancel = () => {
    if (isDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  return (
    <Dialog fullWidth maxWidth='md' open={openDialog} aria-labelledby='form-charge-dialog-title'>
      <CongestionZoneDialogTitle
        id='form-charge-dialog-title'
        notFound={notFound}
        creating={!congestionZoneId}
        onClose={handleOnCancel}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))} noValidate>
          <DialogContent>
            <CongestionDialogContent
              notFound={notFound}
              processing={
                creating || updating || creatingPostcode || updatingPostcode || deletingPostcode
              }
              setCongestionZone={setCongestionZone}
              setNotFound={setNotFound}
              zoneId={congestionZoneId}
              selectedWeekdays={selectedWeekdays}
              setSelectedWeekdays={setSelectedWeekdays}
              postcodes={postcodes}
              setPostcodes={setPostcodes}
              postcodeActions={{
                onAdd: handleAddPostcode,
                onEdit: handleEditPostcode,
                onDelete: handleDeletePostcode,
              }}
            />
            <CongestionDialogActions isDirty={isDirty} onCloseClick={handleOnCancel} />
          </DialogContent>
        </form>
      </FormProvider>
    </Dialog>
  )
}
