import { gql } from '@apollo/client'

export const GET_THEME_IS_LIGHT = gql`
  query GetThemeIsLight {
    isThemeLight @client
  }
`
export const GET_SETTINGS = gql`
  query GetSettings {
    isThemeLight @client
  }
`
