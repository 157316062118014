import { Typography } from '@mui/material'
import Rating, { RatingPropsSizeOverrides } from '@mui/material/Rating'
import { OverridableStringUnion } from '@mui/types'

interface IProps {
  size?: OverridableStringUnion<'small' | 'medium' | 'large', RatingPropsSizeOverrides>
  displayRatingValue?: boolean
  ratingValue: number
}

function DriverRating(props: IProps) {
  const { size = 'large', displayRatingValue = true, ratingValue } = props
  return (
    <>
      <Rating name='read-only' value={ratingValue} precision={0.1} size={size} readOnly />
      {displayRatingValue && <Typography>{ratingValue}</Typography>}
    </>
  )
}

export default DriverRating
