import { useMemo } from 'react'

import { Grid, Tooltip } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'

import { TextInput } from 'components/common'
import { CardTitle } from 'components/common/controls'

interface IProps {
  loadId?: string | null
  lastManualUpdateAt?: string | null
  disabled?: boolean
  hoverText?: string
}

const CourierExchangeDetails = (props: IProps) => {
  const { loadId, lastManualUpdateAt, disabled, hoverText = '' } = props

  const lastManualUpdateAtValue = useMemo(() => {
    if (lastManualUpdateAt) {
      const lastUpdateToNow = formatDistanceToNow(new Date(lastManualUpdateAt))
      return `${lastUpdateToNow} ago`
    }
  }, [lastManualUpdateAt])

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <CardTitle disabled={disabled}>Courier Exchange Details</CardTitle>
        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <TextInput
              value={loadId ?? ''}
              name='courierExchangeLoadId'
              label='Load ID'
              readOnly
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              value={lastManualUpdateAtValue ?? ''}
              name='courierExchangeLastManualUpdateAt'
              label='Last Manual Update'
              readOnly
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  )
}

export default CourierExchangeDetails
