import { useEffect, useMemo } from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  ControlledSelector,
  ControlledTextField,
  ISelectorOptions,
  CardTitle,
} from 'components/common'
import { VolumeUnitTypeValues, WeightUnitTypeValues } from 'constants/index'
import {
  GetBookingQuery,
  useGetCustomersInstructionsForDriverLazyQuery,
  useGetPackagingTypesQuery,
} from 'generated/graphql'

interface IProps {
  bookingDetails?: GetBookingQuery['booking']
}
const PackageDetails = (props: IProps) => {
  const { bookingDetails } = props

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext()

  // QUERIES
  const { loading, data } = useGetPackagingTypesQuery()

  const [getInstructions] = useGetCustomersInstructionsForDriverLazyQuery({
    variables: {
      customerId: bookingDetails?.customerId,
    },
    onCompleted: (response) => {
      if (!response) {
        toast.error("Couldn't get instructions for driver")
        return
      }

      const oldNotes = getValues('notes')
      const instructions = response.customersInstructionsForDriver
      const newNotes = [oldNotes, instructions].filter((x) => !!x).join('\r\n\r\n')

      setValue('notes', newNotes, {
        shouldDirty: true,
        shouldValidate: true,
      })
    },
  })

  useEffect(() => {
    if (bookingDetails) {
      getInstructions()
    }
  }, [bookingDetails, getInstructions])

  const packagingTypeItems: ISelectorOptions = useMemo(() => {
    if (loading || !data?.packagingTypes) {
      return []
    }

    return data.packagingTypes.map((t) => ({
      value: t.packagingType,
      label: t.description,
      disabled: t.disabled,
    }))
  }, [loading, data])

  return (
    <>
      <CardTitle>Package Details</CardTitle>
      <Grid container spacing={3}>
        <Grid container item xs={12} sm={6} spacing={3}>
          <Grid item xs={6}>
            <ControlledTextField
              control={control}
              label='Total Items'
              name='numberOfItems'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              min='0'
              defaultValue={0}
              error={!!errors.numberOfItems}
              helperText={errors.numberOfItems?.message}
            />
          </Grid>

          <Grid item xs={6}>
            <ControlledSelector
              control={control}
              label='Packaging'
              name='packaging'
              required
              defaultValue=''
              options={packagingTypeItems}
            />
          </Grid>

          <Grid item xs={6}>
            <ControlledTextField
              control={control}
              label='Gross Weight'
              name='grossWeight.value'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              min='0'
              defaultValue={0}
              // @ts-ignore
              error={!!errors.grossWeight?.value}
              // @ts-ignore
              helperText={errors.grossWeight?.value?.message}
            />
          </Grid>

          <Grid item xs={6}>
            <ControlledSelector
              control={control}
              label='Unit'
              name='grossWeight.units'
              options={WeightUnitTypeValues}
            />
          </Grid>

          <Grid item xs={3}>
            <ControlledTextField
              control={control}
              label='Length'
              name='volume.length'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              min='0'
              defaultValue={0}
              // @ts-ignore
              error={!!errors.volume?.length}
              // @ts-ignore
              helperText={errors.volume?.length?.message}
            />
          </Grid>

          <Grid item xs={3}>
            <ControlledTextField
              control={control}
              label='Width'
              name='volume.width'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              min='0'
              defaultValue={0}
              // @ts-ignore
              error={!!errors.volume?.width}
              // @ts-ignore
              helperText={errors.volume?.width?.message}
            />
          </Grid>

          <Grid item xs={3}>
            <ControlledTextField
              control={control}
              label='Height'
              name='volume.height'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              min='0'
              defaultValue={0}
              // @ts-ignore
              error={!!errors.volume?.height}
              // @ts-ignore
              helperText={errors.volume?.height?.message}
            />
          </Grid>

          <Grid item xs={3}>
            <ControlledSelector
              control={control}
              label='Unit'
              name='volume.units'
              options={VolumeUnitTypeValues}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Load Notes and Instructions'
            name='notes'
            multiline
            rows={7}
            defaultValue=''
            error={!!errors.notes}
            helperText={errors.notes?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PackageDetails
