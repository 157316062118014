import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const NotFound = () => {
  const spacing = 3

  return (
    <Container maxWidth='sm'>
      <Paper elevation={3}>
        <Box p={2} textAlign='center'>
          <Typography variant='h6'>
            Oops! The page that you&apos;re looking for is not found.
          </Typography>
          <Box mt={2}>
            <Typography>
              It might have been renamed, removed or never existed in the first place.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography>Here are some helpful links instead:</Typography>
          </Box>
          <Box mt={2}>
            <Grid container spacing={spacing} justifyContent='center'>
              <Grid item>
                <Button color='primary' component={RouterLink} to='/'>
                  Home
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default NotFound
