import { endOfDay, startOfDay } from 'date-fns'
import { FormProvider, useForm } from 'react-hook-form'

import { ReportFileType, ReportTypeButtonsId } from 'constants/EntityConstants'
import {
  MyIncomeReportInputDtoInput,
  SortEnumType,
  useGetCustomerProfileQuery,
  useGetSubCustomersQuery,
  useGetUserReasonCodesQuery,
} from 'generated/graphql'

import FilterCriteriaForm from './FilterCriteriaForm'

interface IProps {
  defaultFilter: MyIncomeReportInputDtoInput
  isProcessing: boolean
  onViewReport: (filter: MyIncomeReportInputDtoInput) => void
  onDownloadReport: (type: ReportFileType, filter: MyIncomeReportInputDtoInput) => void
}

const FilterCriteria = (props: IProps) => {
  const { defaultFilter, isProcessing, onViewReport, onDownloadReport } = props

  const methods = useForm<MyIncomeReportInputDtoInput>({
    shouldUnregister: true,
    defaultValues: defaultFilter,
  })
  const { handleSubmit, reset } = methods

  // Query
  const { data: customerProfileData } = useGetCustomerProfileQuery()

  const customerId = customerProfileData?.customerProfile.customerId
  const isReasonCodeOptionEnabled =
    customerProfileData?.customerProfile.isReasonCodeOptionEnabled ?? false

  const { loading: customersLoading, data: customersData } = useGetSubCustomersQuery({
    skip: !customerId,
    variables: {
      primaryCustomerId: customerId,
      order: { name: SortEnumType.Asc },
    },
  })

  const { loading: reasonCodesLoading, data: reasonCodesData } = useGetUserReasonCodesQuery({
    skip: !isReasonCodeOptionEnabled,
    variables: {
      isIncludeSubCustomers: true,
    },
  })

  const handleClearAll = () => {
    reset(defaultFilter)
  }

  const handleOnSubmit = (buttonId: ReportTypeButtonsId, formData) => {
    const { start, end, customerIds, reasonCodes } = formData

    const filter: MyIncomeReportInputDtoInput = {
      start: start ? startOfDay(start) : undefined,
      end: end ? endOfDay(end) : undefined,
      customerIds: customerIds.length > 0 ? customerIds.map((x) => Number(x.value)) : undefined,
      reasonCodes: reasonCodes.length > 0 ? reasonCodes.map((x) => x.value) : undefined,
    }

    switch (buttonId) {
      case ReportTypeButtonsId.ViewReport:
        onViewReport(filter)
        break

      case ReportTypeButtonsId.DownloadXlsx:
        onDownloadReport(ReportFileType.xlsx, filter)
        break

      case ReportTypeButtonsId.DownloadPdf:
        onDownloadReport(ReportFileType.pdf, filter)
        break

      case ReportTypeButtonsId.DownloadHtml:
        onDownloadReport(ReportFileType.html, filter)
        break
    }
  }

  const handleReportButtonClick = (buttonId: ReportTypeButtonsId) => {
    handleSubmit((formData) => handleOnSubmit(buttonId, formData))()
  }

  return (
    <FormProvider {...methods}>
      <FilterCriteriaForm
        customersLoading={customersLoading}
        subCustomers={customersData?.subCustomers}
        isReasonCodeOptionEnabled={isReasonCodeOptionEnabled}
        reasonCodesLoading={reasonCodesLoading}
        reasonCodes={reasonCodesData?.userReasonCodes}
        isProcessing={isProcessing}
        onReportButtonClick={handleReportButtonClick}
        onClearAll={handleClearAll}
      />
    </FormProvider>
  )
}

export default FilterCriteria
