import { EntityConstants } from 'constants/EntityConstants'

import { isAfter } from 'date-fns'
import { DeliverTimeRangeType, PickupTimeRangeType, StopTimeRangeType } from 'generated/graphql'
import Joi from 'joi'

// ToDo: add form type
const PostLoadOrBookDirectValidation = Joi.object<any>({
  // Load Details
  minVehicleSize: Joi.string().empty(''),
  jobDescription: Joi.string(),
  freightType: Joi.string(),
  bodyType: Joi.string(),

  // Service Details
  pickupTimeRange: Joi.any(),
  readyAt: Joi.when('pickupTimeRange', {
    is: [PickupTimeRangeType.At, PickupTimeRangeType.Between],
    then: Joi.date().min('now').message(`'Pickup From' must be in future`),
  }),
  collectBy: Joi.when('pickupTimeRange', [
    {
      is: PickupTimeRangeType.Before,
      then: Joi.date().min('now').message(`'Pickup To' must be in future`),
    },
    {
      is: PickupTimeRangeType.Between,
      then: Joi.date()
        .min(Joi.ref('readyAt'))
        .message(`'Pickup To' value must be greater than 'Pickup From' value`),
    },
  ]),

  stopTimeDetails: Joi.array()
    .optional()
    .items(
      Joi.object({
        timeRange: Joi.any(),
        timeFrom: Joi.when('timeRange', {
          is: StopTimeRangeType.Between,
          then: Joi.date().required(),
        }).custom((value, { state }) => {
          if (value) {
            const index = state.path?.[1] as any
            const stopTimeFrom = new Date(value)
            if (index === 0) {
              const pickupTimeRange = state.ancestors[2].pickupTimeRange

              if (
                pickupTimeRange === PickupTimeRangeType.Before ||
                pickupTimeRange === PickupTimeRangeType.Between
              ) {
                const timeRange = state.ancestors[0].timeRange
                const collectBy = new Date(state.ancestors[2].collectBy)

                if (timeRange === StopTimeRangeType.Between && !isAfter(stopTimeFrom, collectBy)) {
                  throw `'Stop(1) From' value must be greater than 'Pickup To' value`
                }
              }

              if (pickupTimeRange === PickupTimeRangeType.At) {
                const readyAt = new Date(state.ancestors[2].readyAt)

                if (!isAfter(stopTimeFrom, readyAt)) {
                  throw `'Stop(1) From' value must be greater than 'Pickup From' value`
                }
              }
            } else if (index > 0) {
              const timeRange = state.ancestors[0].timeRange
              const stopTimeDetails = state.ancestors[1]

              if (timeRange === StopTimeRangeType.Between) {
                let asapStopsCount = 0

                for (let i = index - 1; i >= 0; i--) {
                  if (stopTimeDetails[i].timeRange === StopTimeRangeType.Between) {
                    const timeTo = new Date(stopTimeDetails[i].timeTo)

                    if (!isAfter(stopTimeFrom, timeTo)) {
                      throw `'Stop(${index + 1}) From' value must be greater than 'Stop (${
                        i + 1
                      }) To' value`
                    }
                  } else {
                    asapStopsCount++
                  }
                }

                // All stops above are ASAP
                if (asapStopsCount === index) {
                  const pickupTimeRange = state.ancestors[2].pickupTimeRange

                  if (
                    pickupTimeRange === PickupTimeRangeType.Before ||
                    pickupTimeRange === PickupTimeRangeType.Between
                  ) {
                    const collectBy = new Date(state.ancestors[2].collectBy)

                    if (!isAfter(stopTimeFrom, collectBy)) {
                      throw `'Stop(${index + 1}) From' value must be greater than 'Pickup To' value`
                    }
                  } else if (pickupTimeRange === PickupTimeRangeType.At) {
                    const readyAt = new Date(state.ancestors[2].readyAt)

                    if (!isAfter(stopTimeFrom, readyAt)) {
                      throw `'Stop(${
                        index + 1
                      }) From' value must be greater than 'Pickup From' value`
                    }
                  }
                }
              }
            }
          }

          return value
        }, 'is-stop-after'),
        timeTo: Joi.when('timeRange', {
          is: StopTimeRangeType.Between,
          then: Joi.date()
            .min(Joi.ref('timeFrom'))
            .message(`'Stop To' value must be greater than 'Stop From' value`),
        }),
      }),
    ),

  deliverTimeRange: Joi.any(),
  deliverFrom: Joi.when('deliverTimeRange', {
    is: [DeliverTimeRangeType.At, DeliverTimeRangeType.Between],
    then: Joi.date().required(),
  }).custom((value, { state }) => {
    if (value) {
      const deliverTimeRange = state.ancestors[0].deliverTimeRange

      if (
        deliverTimeRange === DeliverTimeRangeType.At ||
        deliverTimeRange === DeliverTimeRangeType.Between
      ) {
        const someAvailableStops = state.ancestors[0].stopTimeDetails?.some(
          (stop) => stop.timeRange === StopTimeRangeType.Between,
        )

        if (!someAvailableStops) {
          const pickupTimeRange = state.ancestors[0].pickupTimeRange

          if (
            pickupTimeRange === PickupTimeRangeType.Before ||
            pickupTimeRange === PickupTimeRangeType.Between
          ) {
            const collectBy = new Date(state.ancestors[0].collectBy)

            if (!isAfter(value, collectBy)) {
              throw `'Deliver From' value must be greater than 'Pickup To' value`
            }
          } else if (pickupTimeRange === PickupTimeRangeType.At) {
            const readyAt = new Date(state.ancestors[0].readyAt)

            if (!isAfter(value, readyAt)) {
              throw `'Deliver From' value must be greater than 'Pickup From' value`
            }
          }
        } else {
          const stopTimeDetails = state.ancestors[0].stopTimeDetails

          for (let i = stopTimeDetails.length - 1; i >= 0; i--) {
            if (stopTimeDetails[i].timeRange === StopTimeRangeType.Between) {
              const timeTo = new Date(stopTimeDetails[i].timeTo)

              if (!isAfter(value, timeTo)) {
                throw `'Deliver From' value must be greater than 'Stop(${i + 1}) To' value`
              }
            }
          }
        }
      }
    }

    return value
  }, 'is-deliver-from-after'),
  deliverBy: Joi.when('deliverTimeRange', {
    is: [DeliverTimeRangeType.By, DeliverTimeRangeType.Between],
    then: Joi.date().required(),
  }).custom((value, { state }) => {
    if (value) {
      const deliverTimeRange = state.ancestors[0].deliverTimeRange

      if (deliverTimeRange === DeliverTimeRangeType.Between) {
        const deliverFrom = state.ancestors[0].deliverFrom

        if (!isAfter(value, deliverFrom)) {
          throw `'Deliver To' value must be greater than 'Deliver From' value`
        }
      } else if (deliverTimeRange === DeliverTimeRangeType.By) {
        const someAvailableStops = state.ancestors[0].stopTimeDetails?.some(
          (stop) => stop.timeRange === StopTimeRangeType.Between,
        )

        if (!someAvailableStops) {
          const pickupTimeRange = state.ancestors[0].pickupTimeRange

          if (
            pickupTimeRange === PickupTimeRangeType.Before ||
            pickupTimeRange === PickupTimeRangeType.Between
          ) {
            const collectBy = new Date(state.ancestors[0].collectBy)

            if (!isAfter(value, collectBy)) {
              throw `'Deliver To' value must be greater than 'Pickup To' value`
            }
          } else if (pickupTimeRange === PickupTimeRangeType.At) {
            const readyAt = new Date(state.ancestors[0].readyAt)

            if (!isAfter(value, readyAt)) {
              throw `'Deliver To' value must be greater than 'Pickup From' value`
            }
          }
        } else {
          const stopTimeDetails = state.ancestors[0].stopTimeDetails

          for (let i = stopTimeDetails.length - 1; i >= 0; i--) {
            if (stopTimeDetails[i].timeRange === StopTimeRangeType.Between) {
              const timeTo = new Date(stopTimeDetails[i].timeTo)

              if (!isAfter(value, timeTo)) {
                throw `'Deliver To' value must be greater than 'Stop(${i + 1}) To' value`
              }
            }
          }
        }
      }
    }

    return value
  }, 'is-deliver-to-after'),
  // Package Details
  numberOfItems: Joi.number().empty('').optional(),
  grossWeight: Joi.object({
    value: Joi.number().empty('').optional(),
  }),
  volume: Joi.object({
    length: Joi.number().empty('').optional(),
    width: Joi.number().empty('').optional(),
    height: Joi.number().empty('').optional(),
  }),
  notes: Joi.string()
    .empty('')
    .max(EntityConstants.MAX_NOTE_LENGTH_POST_LOAD_OR_BOOK_DIRECT)
    .optional(),

  // CX Member Details
  orderSubcontractorId: Joi.number().empty('').allow(null).optional(),
  orderAgreedRate: Joi.number().min(0).empty('').optional(),
})

export { PostLoadOrBookDirectValidation }
