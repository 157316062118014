import { gql } from '@apollo/client'

export const CustomerReferenceAndNotesFragment = gql`
  fragment CustomerReferenceAndNotes on Customer {
    customerReferenceLabel
    isCustomerReferenceMandatory
    isPaperworkRequired
    noteForAccount
    noteForBooking
    instructionsForCourierExchangeDriver
    noteForPackage
    noteForPaperwork
  }
`

export const CustomerNotificationsFragment = gql`
  fragment CustomerNotifications on Customer {
    isNotificationForQuote
    isNotificationForReadyForAllocation
    isNotificationForBooking
    isNotificationForPobArrival
    isNotificationForPOB
    isNotificationForPodArrival
    isNotificationForPodPartial
    isNotificationForPOD
    isNotificationForCancellation
    isNotificationForCarded
    isNotificationForAmendment
    isNotificationForDriverAllocated
    isNotificationForInvoice
    isNotificationForInvoiceDue
    customerInvoiceNotificationType
  }
`

export const CustomerDetailsFragment = gql`
  fragment CustomerDetails on Customer {
    id
    primaryCustomerId
    name
    addressLine1
    addressLine2
    legalAccountType
    customerAccountType
    city
    postcode
    countryId
    invoiceEmail
    paymentMethodId
    vatCategoryId
    accountCode
    companyRegistrationNumber
    vatIdNumber
    eoriNumber
    invoiceCompanyName
    startDate
    acquiredBy
    customerAcquisitionSourceId
    notes
    invoiceTermId
    invoiceFrequency
    isInvoiceGrouped
    currencyId
    isReferenceRequired
    isUlezCharge
    isOnHold
    isOnHold
    isNotificationForBooking
    isNotificationForInvoice
    isNotificationForInvoiceDue
    isNotificationForPOB
    isNotificationForPOD
    isNotificationForQuote
    isNotificationForReadyForAllocation
    isCustomerReferenceMandatory
    customerInvoiceNotificationType
    telephoneNumber
    salesName
    additionalInvoiceEmails
    isPaperworkRequired
    isApplyVatToOtherCharges
    isEnabled
    isReasonCodeOptionEnabled
    isBookingImportOptionEnabled
    isPackageDetailsOptional
  }
`

export const CustomerOptionsFragment = gql`
  fragment CustomerOptions on Customer {
    isReferenceRequired
    isReasonCodeOptionEnabled
    isPackageDetailsOptional
  }
`
