import React from 'react'

import { useReactiveVar } from '@apollo/client'
import { GridSortItem } from '@mui/x-data-grid'

import { FplDataGrid } from 'components/common'
import { AccountPayableRecordFilterInput } from 'generated/graphql'
import { GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY_WITH_BATCHLINE } from 'graphql/queries'
import { expandableGridClickedRowIdVar } from 'graphql/reactiveVariables'
import useGetAccountsPayableCompactedColumns from 'hooks/accountsPayable/useGetAccountsPayableCompactedColumns'

const descendingOrderByStatus: GridSortItem = {
  field: 'status',
  sort: 'desc',
}

function AccountPayableCompactedGrid() {
  const [columns] = useGetAccountsPayableCompactedColumns()

  const expandableGridClickedRowId = useReactiveVar(expandableGridClickedRowIdVar)

  const filterByBatchLineId: AccountPayableRecordFilterInput = {
    bookingInvoiceBatchLine: { id: { eq: Number(expandableGridClickedRowId) } },
  }

  return (
    <FplDataGrid
      overwriteId={'accountPayableRecords'}
      query={GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY_WITH_BATCHLINE}
      filter={filterByBatchLineId}
      isHideOldData={true}
      refetchWithDelay
      entityName='accountPayableRecords'
      toolbar={{
        caption: `Account Payable Records for Batch Line ID ${expandableGridClickedRowId}`,
      }}
      columns={columns}
      defaultOrder={descendingOrderByStatus}
    />
  )
}

export default AccountPayableCompactedGrid
