import { Rating, Typography } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

export interface ControlledRatingProps {
  control?: Control<any, any>
  name: string
  defaultValue?: any
  error?: boolean
  helperText?: any
}

const ControlledRating = (props: ControlledRatingProps) => {
  const { control, name, defaultValue, error, helperText, ...otherProps } = props
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          <Rating
            name='simple-controlled-rating'
            value={field.value}
            onChange={(_, value) => {
              field.onChange(value)
            }}
            {...otherProps}
          />
          {error && (
            <Typography fontSize={'12px'} color='error'>
              {helperText}
            </Typography>
          )}
        </>
      )}
    />
  )
}

export default ControlledRating
