import { Redirect, Route, RouteProps } from 'react-router-dom'

import { Paths } from 'constants/index'
import { Permissions } from 'generated/graphql'

import { usePermission } from './PermissionProvider'

interface IProps extends RouteProps {
  to: keyof Permissions | Array<keyof Permissions>
}

const ProtectedRoute = (props: IProps) => {
  const { to, component: Component, ...rest } = props

  const [allowed, loading] = usePermission(to)

  if (loading) {
    return <>Loading...</>
  }

  if (allowed) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Component {...routeProps} />
        )}
      />
    )
  } else {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect
            to={{
              pathname: Paths.requestAccess,
              state: { from: routeProps.location },
            }}
          />
        )}
      />
    )
  }
}

export default ProtectedRoute
