import { ReactNode } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@mui/material'

interface IProps {
  title?: string
  message?: string
  children?: ReactNode
  open: boolean
  onConfirm: any
  setOpen: any
  option1?: string
  option2?: string
}

const ConfirmDialog = (props: IProps) => {
  const {
    title = 'Discard changes',
    message = "You have made changes that haven't been saved yet. Are you sure to discard changes?",
    children,
    open,
    setOpen,
    onConfirm,
    option1 = 'no',
    option2 = 'yes',
  } = props

  const handleOnClose = () => {
    setOpen(false)
  }
  const handleOnConfirm = () => {
    onConfirm()
    handleOnClose()
  }

  return (
    <Dialog maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='confirm-dialog'>
      <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
      <DialogContent>{children || <DialogContentText>{message}</DialogContentText>}</DialogContent>
      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleOnClose}>
          {option1}
        </Button>
        <Button variant='contained' color='primary' onClick={handleOnConfirm}>
          {option2}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
