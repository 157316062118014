import { gql } from '@apollo/client'

import { CustomerDetailsFragment } from 'graphql/fragments'

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation createCustomer($input: CreateAndUpdateCustomerDtoInput!) {
    createCustomer(input: $input) {
      ...CustomerDetails
    }
    ${CustomerDetailsFragment}
  }
`

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation updateCustomer($input: CreateAndUpdateCustomerDtoInput!) {
    updateCustomer(input: $input) {
      ...CustomerDetails
    }
    ${CustomerDetailsFragment}
  }
`

export const DELETE_CUSTOMER_MUTATION = gql`
  mutation deleteCustomer($customerId: Long!) {
    deleteCustomer(customerId: $customerId)
  }
`

export const CREATE_ADDRESS_MUTATION = gql`
  mutation createAddress($input: CreateAndUpdateAddressDtoInput!) {
    createAddress(input: $input) {
      id
      name
      city
      addressLine1
      addressLine2
      postcode
      email
      countryId
      telephoneNumber
      contact
      isNotification
      isAutoFill
      isDefault
    }
  }
`

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation updateAddress($input: CreateAndUpdateAddressDtoInput!) {
    updateAddress(input: $input) {
      id
      name
      city
      addressLine1
      addressLine2
      postcode
      email
      countryId
      telephoneNumber
      contact
      isNotification
      isAutoFill
      isDefault
    }
  }
`

export const DELETE_ADDRESS_MUTATION = gql`
  mutation DeleteAddress($customerId: Long!, $addressId: Long!) {
    deleteAddress(addressId: $addressId, customerId: $customerId) {
      id
      name
      customerId
    }
  }
`

export const CREATE_CONTACT_MUTATION = gql`
  mutation createContact($input: CreateAndUpdateContactDtoInput!) {
    createContact(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact($input: CreateAndUpdateContactDtoInput!) {
    updateContact(input: $input) {
      id
      name
    }
  }
`

export const DELETE_CONTACT_MUTATION = gql`
  mutation deleteContact($contactId: Long!) {
    deleteContact(contactId: $contactId)
  }
`

export const DELETE_CUSTOMER_LOGO_MUTATION = gql`
  mutation deleteCustomerLogo($customerId: Long!) {
    deleteCustomerLogo(customerId: $customerId)
  }
`

export const UPDATE_CUSTOMER_NOTIFICATIONS_MUTATION = gql`
  mutation updateCustomerNotifications($input: UpdateCustomerNotificationsDtoInput!) {
    updateCustomerNotifications(input: $input) {
      id
      isNotificationForQuote
      isNotificationForReadyForAllocation
      isNotificationForBooking
      isNotificationForPobArrival
      isNotificationForPOB
      isNotificationForPodArrival
      isNotificationForPodPartial
      isNotificationForPOD
      isNotificationForCancellation
      isNotificationForCarded
      isNotificationForAmendment
      isNotificationForDriverAllocated
      isNotificationForInvoice
      isNotificationForInvoiceDue
      customerInvoiceNotificationType
    }
  }
`

export const UPDATE_CUSTOMER_REFERENCES_AND_NOTES_MUTATION = gql`
  mutation updateCustomerReferencesAndNotes($input: UpdateCustomerReferencesAndNotesDtoInput!) {
    updateCustomerReferencesAndNotes(input: $input) {
      id
      customerReferenceLabel
      isCustomerReferenceMandatory
      isPaperworkRequired
      noteForAccount
      noteForBooking
      instructionsForCourierExchangeDriver
      noteForPackage
      noteForPaperwork
    }
  }
`

export const DELETE_CUSTOMER_TARIFF_MUTATION = gql`
  mutation DeleteCustomerTariff($customerId: Long!, $tariffId: Long!) {
    deleteCustomerTariff(customerId: $customerId, tariffId: $tariffId) {
      id
      name
      tariffs {
        id
        customerId
        tariffId
      }
    }
  }
`
