import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { Mode } from 'constants/Mode'
import { useGetUserDetailsQuery } from 'generated/graphql'
import { normalizeUserForm } from 'helpers'
import { TUserForm } from 'types'

import UserForm from './UserForm'

interface IProps {
  userId: number
}

const UpdateUser = (props: IProps) => {
  const { userId } = props
  const { reset } = useFormContext<TUserForm>()

  const { loading, data: userDetailsData } = useGetUserDetailsQuery({
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (userDetailsData?.userDetails) {
      reset(normalizeUserForm(userDetailsData.userDetails))
    }
  }, [userDetailsData, reset])

  return (
    <>
      <UserForm mode={Mode.Update} />
      <LoadingBackdrop loading={loading} />
    </>
  )
}

export default UpdateUser
