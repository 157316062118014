import React, { useCallback, useMemo, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { DialogActions } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid'
import { Decimal } from 'decimal.js'
import { toast } from 'react-toastify'
import { FplDataGrid } from 'components/common'
import ConfirmDialog from 'components/common/Dialog/ConfirmDialog'
import {
  DriverBidFilterInput,
  GetDriverBidsDocument,
  useAllocateDriverBidsMutation,
  useDeleteBookingDriverByBookingAndDriverIdMutation,
  useGetBookingQuery,
  useGetDriverBidsQuery,
  useGetExchangeRatesQuery,
} from 'generated/graphql'
import { GET_BIDS_BY_BOOKING_QUERY_PAGED } from 'graphql/queries/DriverBidQueries'
import useGetDriverBidsColumns from 'hooks/fplDataGridColumns/useGetDriverBidsColumns'

interface IProps {
  bookingId: string | null
}

const AvailableBids = (props: IProps) => {
  const { bookingId } = props

  const [isListOfBidsEmpty, setApproveState] = useState<boolean>(true)
  const [allocatedBidsIds, setallocatedBidsIds] = useState<string[]>([])
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  // Queries
  const { data: bookingData } = useGetBookingQuery({
    variables: { bookingId: Number(bookingId) },
  })

  const { data: latestExchangeRates } = useGetExchangeRatesQuery({
    variables: { where: { targetCurrencyId: { eq: 1 } }, last: 3 },
    nextFetchPolicy: 'cache-and-network',
  })

  const { data: bidsData, refetch: refetchDriverBids } = useGetDriverBidsQuery({
    variables: {
      where: {
        bookingId: { eq: Number(bookingId) },
      },
    },
    nextFetchPolicy: 'network-only',
    skip: !bookingId,
  })

  const revenue: number = React.useMemo(() => {
    if (bookingData?.booking) {
      const consignmentFee = bookingData.booking.consignmentFee
      const chargesList = bookingData.booking.charges

      const otherCharges =
        chargesList?.reduce(
          (previousValue, currentValue) =>
            currentValue?.rate * currentValue.quantity + previousValue,
          0,
        ) || 0

      return new Decimal(consignmentFee).plus(otherCharges).toNumber()
    } else {
      return 0
    }
  }, [bookingData])

  const [allocateBids] = useAllocateDriverBidsMutation({
    refetchQueries: [
      {
        query: GetDriverBidsDocument,
        variables: { where: { bookingId: { eq: Number(bookingId) } } },
      },
    ],
    onCompleted: (data) => {
      if (data.allocateDriverBids?.length > 0) {
        toast.success(`Drivers were allocated with success.`)
      } else {
        toast.error(`Something went wrong. Please try again later.`)
      }
    },
  })

  // mutations
  const [deleteBookingDriver] = useDeleteBookingDriverByBookingAndDriverIdMutation({
    refetchQueries: [
      {
        query: GetDriverBidsDocument,
        variables: { where: { bookingId: { eq: Number(bookingId) } } },
      },
    ],
    onCompleted: () => {
      toast.success('Driver was deleted with success.')
      setallocatedBidsIds([])
      setApproveState(true)
      refetchDriverBids()
    },
  })

  const handleApprove = () => {
    if (bookingId !== null && allocatedBidsIds.length > 0) {
      const bidsToAllocate: number[] = []

      allocatedBidsIds.forEach((bid) => {
        // Select all that should be allocated
        const selectedBid = bidsData?.driverBids.find(
          (d) => d.id === bid && d.isAllocated === false,
        )

        if (selectedBid) {
          bidsToAllocate.push(Number(selectedBid.id))
        }

        // Select all that should be removed
        const bidsToRemove = bidsData?.driverBids.find(
          (d) => d.id === bid && d.isAllocated === true,
        )

        if (bidsToRemove) {
          const driverId = bidsToRemove.driverId
          const bookingId = String(bidsToRemove.bookingId)

          //Remove Driver
          deleteBookingDriver({
            variables: { bookingId: Number(bookingId), driverId: Number(driverId) },
          })
        }
      })

      if (bidsToAllocate.length > 0) {
        allocateBids({
          variables: {
            ids: bidsToAllocate,
          },
        }).finally(() => {
          setallocatedBidsIds([])
          setApproveState(true)
          refetchDriverBids()
        })
      }
    }
  }

  const filter: DriverBidFilterInput = useMemo(() => {
    return {
      bookingId: { eq: Number(bookingId) },
      isEnabled: { eq: true },
    }
  }, [bookingId])

  const handleOnSaveClick = () => {
    setConfirmOpen(true)
  }

  const isRowSelectable = useCallback(
    (id: GridRowId) => {
      return !allocatedBidsIds.includes(String(id))
    },
    [allocatedBidsIds],
  )

  const handleAllocateClick = useCallback(
    (id: GridRowId) => () => {
      if (!allocatedBidsIds.includes(String(id))) {
        allocatedBidsIds.push(String(id))
      } else {
        allocatedBidsIds.splice(
          allocatedBidsIds.findIndex((x) => x === String(id)),
          1,
        )
      }
      setApproveState(allocatedBidsIds.length === 0)
    },
    [allocatedBidsIds],
  )

  const getGrossProfit = React.useCallback(
    ({ totalPrice, currencyId }: { totalPrice: number; currencyId: string }) => {
      const rate: number | undefined = latestExchangeRates?.exchangeRates?.edges?.find(
        (exchangeRate) => exchangeRate.node.sourceCurrencyId === Number(currencyId),
      )?.node.rate

      return new Decimal(revenue).minus(totalPrice * (rate ?? 1)).toFixed(2)
    },
    [latestExchangeRates?.exchangeRates?.edges, revenue],
  )

  const getGrossProfitPct = React.useCallback(
    ({ totalPrice, currencyId }: { totalPrice: number; currencyId: string }) => {
      if (revenue === 0) {
        return 'n/a'
      }
      const rate: number | undefined = latestExchangeRates?.exchangeRates?.edges?.find(
        (exchangeRate) => exchangeRate.node.sourceCurrencyId === Number(currencyId),
      )?.node.rate

      return new Decimal(revenue)
        .minus(totalPrice * (rate ?? 1))
        .mul(100)
        .div(revenue)
        .toFixed(2)
        .toString()
    },
    [latestExchangeRates?.exchangeRates?.edges, revenue],
  )

  const columns = useGetDriverBidsColumns(
    handleAllocateClick,
    isRowSelectable,
    getGrossProfit,
    getGrossProfitPct,
  )

  const entityName = 'driverBidsPaged'
  const rowSelectableDefault = () => {
    return false
  }

  return (
    <>
      <FplDataGrid
        query={GET_BIDS_BY_BOOKING_QUERY_PAGED}
        entityName={entityName}
        overwriteId={entityName}
        columns={columns}
        skipQuery={bookingId === undefined}
        defaultOrder={{ field: 'name', sort: 'asc' }}
        variableSortOrder={{ field: 'name', sort: 'asc' }}
        pageOptions={{ pageSize: 5, rowsPerPage: [5, 10] }}
        filter={filter}
        isHideOldData={true}
        isRowSelectable={rowSelectableDefault}
        fetchPolicy='network-only'
      />
      <DialogActions>
        <LoadingButton
          color='primary'
          type='submit'
          disabled={isListOfBidsEmpty}
          onClick={handleOnSaveClick}>
          Approve
        </LoadingButton>
      </DialogActions>
      <ConfirmDialog
        open={confirmOpen}
        onConfirm={allocatedBidsIds.length > 0 ? handleApprove : null}
        setOpen={setConfirmOpen}
        title={`Approve Bids Allocation`}
        message={`You chose to approve the selected Bids?`}
        option1='Cancel'
        option2='Approve'
      />
    </>
  )
}

export default AvailableBids
