import { SxProps, Theme } from '@mui/material'

export const EmailBoxSx: SxProps<Theme> = {
  display: 'flex',
  flexGrow: 1,
  backgroundColor: (theme) => theme.palette.background.paper,
}

export const EmailTabSx: SxProps<Theme> = {
  boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
  width: (theme) => theme.spacing(27),
}
