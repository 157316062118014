import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

interface IProps {
  open: boolean
  setOpen: any
  onConfirm: () => void
  onReject: () => void
}

const ConfirmDistanceChangeDialog = (props: IProps) => {
  const { open, setOpen, onConfirm, onReject } = props

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    onConfirm()
    handleClose()
  }

  const handleReject = () => {
    onReject()
    handleClose()
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={open} aria-labelledby='confirm-distance-change-dialog'>
      <DialogTitle id='confirm-distance-change-dialog'>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Docket will be re-priced. Would you like this to happen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleReject}>
          No
        </Button>
        <Button variant='contained' onClick={handleConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDistanceChangeDialog
