import { ReactNode } from 'react'

import { Box, Paper } from '@mui/material'

const elevation4 = `
  0px 2px 4px -1px rgba(0,0,0,0.2),
  0px 4px 5px 0px rgba(0,0,0,0.14),
  0px 1px 10px 0px rgba(0,0,0,0.12)
`

interface IProps {
  value: any
  index: any
  children?: ReactNode
}

const TabPanelBootstrap = (props: IProps) => {
  const { value, index, children, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`bootstrap-tab-${index}`}
      aria-labelledby={`bootstrap-tab-${index}`}
      {...other}>
      <Paper sx={{ boxShadow: { xs: 'none', sm: elevation4 } }} square>
        <Box px={{ sm: 1.5 }} pt={2.25} pb={{ sm: 1.5 }}>
          {children}
        </Box>
      </Paper>
    </div>
  )
}

export { TabPanelBootstrap }
