import { gql } from '@apollo/client'

export const CREATE_OPEN_QUERY_MUTATION = gql`
  mutation createOpenQuery(
    $input: CreateUpdateOpenQueryDtoInput!
    $emailOptions: OpenQueryEmailOptionsDtoInput!
  ) {
    createOpenQuery(input: $input, emailOptions: $emailOptions) {
      accountPayableRecordId
      reason
    }
  }
`
export const DELETE_OPEN_QUERY_MUTATION = gql`
  mutation deleteOpenQuery($id: Long!) {
    deleteOpenQuery(id: $id)
  }
`

export const DELETE_OPEN_QUERY_BY_ACCOUNT_PAYABLE_RECORD_ID_MUTATION = gql`
  mutation deleteOpenQueryByAccountPayableRecordId($accountPayableRecordId: Long!) {
    deleteOpenQueryByAccountPayableRecord(accountPayableRecordId: $accountPayableRecordId)
  }
`
