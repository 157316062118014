import { amber, green, grey, red } from '@mui/material/colors'
import { GridCellParams, GridRowModel, GridSortItem } from '@mui/x-data-grid'
import clsx from 'clsx'
import { differenceInMinutes, format, parseJSON } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, Paths } from 'constants/index'
import { GET_BOOKINGS_TRACKING_DASHBOARD_DATA } from 'graphql/queries'

import { DriverStatus } from './DriverStatusEnum'

function formatDriverStatusDetails(status: string, postCode: string, date: string): string {
  if (!postCode) {
    return 'Unknown'
  }

  let formatedDate = ''
  let minutes = 0

  if (date) {
    formatedDate = format(parseJSON(date as string), DATE_TIME_FORMAT)
    minutes = differenceInMinutes(new Date(), new Date(date))
  }
  switch (status) {
    case DriverStatus.Unknown:
      return `Next stop ${postCode} has no time information`
    case DriverStatus.OnTime:
      return `Next stop ${postCode} before ${formatedDate}`
    case DriverStatus.Risk:
      return `Possibly late for next stop ${postCode} at ${formatedDate}`
    case DriverStatus.Late:
      return `Missed last stop ${postCode} by ${minutes} minutes`
    default:
      return `Unknown`
  }
}

const descendingOrderByDriverStatus: GridSortItem = {
  field: 'driverStatus',
  sort: 'desc',
}

const columns: IGridColumn[] = [
  {
    field: 'bookingRef',
    headerName: 'Booking Ref',
    sortPath: ['id'],
    flex: 0.1,
    valueGetter(params) {
      return params.row.ourReference
    },
  },
  {
    field: 'statusLabel',
    headerName: 'Status',
    sortPath: ['status'],
    flex: 0.1,
    valueGetter(params) {
      return params.row.statusLabel
    },
  },
  {
    field: 'customer',
    headerName: 'Customer',
    sortPath: ['customerName'],
    flex: 0.2,
    valueGetter(params) {
      return params.row.customerName
    },
  },
  {
    field: 'customerRef',
    headerName: 'Customer Ref',
    sortable: false,
    flex: 0.2,
    valueGetter(params) {
      return params.row.customerReference
    },
  },
  {
    field: 'driverStatus',
    headerName: 'Driver Status',
    sortPath: ['driverStatus', 'status'],
    cellClassName: (params: GridCellParams<GridRowModel>) =>
      clsx({
        onTime: params.row.driverStatus.status === DriverStatus.OnTime,
        risk: params.row.driverStatus.status === DriverStatus.Risk,
        late: params.row.driverStatus.status === DriverStatus.Late,
        unknown: params.row.driverStatus.status === DriverStatus.Unknown,
      }),
    flex: 0.1,
    valueGetter(params) {
      return params.row.driverStatus.statusLabel
    },
  },
  {
    field: 'driverStatusDetails',
    headerName: 'Driver Status Details',
    sortable: false,
    flex: 0.5,
    valueGetter(params) {
      const driverParams = params.row.driverStatus
      return formatDriverStatusDetails(
        driverParams.status,
        driverParams.postcode,
        driverParams.date,
      )
    },
  },
]

function BookingsTracking() {
  const history = useHistory()

  const handleRowClick = (row) => {
    history.push(Paths.bookings.updateWithId(row.id))
  }
  const DefaultOrder: GridSortItem = {
    field: 'id',
    sort: 'asc',
  }

  return (
    <FplDataGrid
      query={GET_BOOKINGS_TRACKING_DASHBOARD_DATA}
      isHideOldData={true}
      refetchWithDelay
      entityName='bookingTrackingDashboardData'
      defaultOrder={descendingOrderByDriverStatus}
      variableSortOrder={DefaultOrder}
      toolbar={{
        caption: 'Bookings Tracking',
      }}
      onRowClick={handleRowClick}
      columns={columns}
      rootSx={{
        '& .onTime': { backgroundColor: green[500] },
        '& .risk': { backgroundColor: amber[500] },
        '& .late': { backgroundColor: red[500] },
        '& .unknown': { backgroundColor: grey[500] },
      }}
    />
  )
}

export default BookingsTracking
