import Joi from 'joi'

import { EntityConstants } from 'constants/EntityConstants'

const CreateUpdateCustomerAddressValidation = Joi.object({
  name: Joi.string().empty('').max(EntityConstants.MAX_NAME_LENGTH),
  city: Joi.string().empty('').max(EntityConstants.MAX_CITY_LENGTH),
  addressLine1: Joi.string().empty('').max(EntityConstants.MAX_ADDRESS_LENGTH),
  addressLine2: Joi.string()
    .allow(null)
    .empty('')
    .max(EntityConstants.MAX_ADDRESS_LENGTH)
    .optional(),
  postcode: Joi.string().empty('').max(EntityConstants.MAX_POSTCODE_LENGTH),
  email: Joi.string()
    .allow(null, '')
    .email({ tlds: false })
    .max(EntityConstants.MAX_EMAIL_LENGTH)
    .optional(),
  countryId: Joi.number(),
  telephoneNumber: Joi.string()
    .allow(null, '')
    .max(EntityConstants.MAX_TELEPHONE_NUMBER_LENGTH)
    .optional(),
  contact: Joi.string().allow(null, '').max(EntityConstants.MAX_PERSON_NAME_LENGTH).optional(),
})

export { CreateUpdateCustomerAddressValidation }
