import React from 'react'

import { ArrowDropDown } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from '@mui/material'

import { Currency } from 'generated/graphql'

interface IProps {
  loading?: boolean
  currencies: Currency[]
  onClick: (currencyId: number) => void
}

const CurrencySplitButton = (props: IProps) => {
  const { onClick, currencies, loading } = props
  const [open, setOpen] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  // handlers

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleToggle = () => setOpen((prevOpen) => !prevOpen)
  const handleClick = () => onClick(Number(currencies[selectedIndex].id))
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index)
    setOpen(false)
  }
  return (
    <>
      <ButtonGroup
        variant='contained'
        color='primary'
        ref={anchorRef}
        aria-label='currency split button'
        disabled={currencies.length === 0 || loading}>
        {currencies.length > 0 ? (
          <>
            <Button color='primary' onClick={handleClick}>
              Add {currencies[selectedIndex].code}
            </Button>
            <Button
              color='primary'
              size='small'
              arian-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup='menu'
              onClick={handleToggle}>
              <ArrowDropDown />
            </Button>
          </>
        ) : (
          <Button disabled>Loading...</Button>
        )}
      </ButtonGroup>
      {/* // ToDo: use "sx props" instead of "style" */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 100 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' color='primary'>
                  {currencies.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      selected={index === selectedIndex}
                      color='primary'
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      {option.code}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default CurrencySplitButton
