import { gql } from '@apollo/client'

const GET_COUNTRIES_QUERY = gql`
  query GetCountries {
    countries {
      id
      name
      formalName
    }
  }
`

export { GET_COUNTRIES_QUERY }
