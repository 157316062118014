import { useState } from 'react'

import { Box, FormHelperText, Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField, DropZoneIcon } from 'components/common'
import { Mode } from 'constants/index'
import { CreateAndUpdateTariffIconDtoInput, GetTariffIconQuery } from 'generated/graphql'

interface IProps {
  mode: number
  tariffIcon?: GetTariffIconQuery['tariffIcon']
  onFilesChanged: (files: File[]) => void
}

const TariffIconForm = (props: IProps) => {
  const { mode, tariffIcon, onFilesChanged } = props
  const [isFileSelected, setIsFileSelected] = useState(false)

  const {
    control,
    formState: { errors, isSubmitted },
  } = useFormContext<CreateAndUpdateTariffIconDtoInput>()

  const handleFilesChanged = (files: File[]) => {
    onFilesChanged(files)

    if (files.length > 0) {
      setIsFileSelected(true)
    }
    if (files.length === 0) {
      setIsFileSelected(false)
    }
  }

  return (
    <Box pt={1}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='name'
            label='Name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name='vehicleCapacity'
            label='Vehicle Capacity'
            required
            multiline
            error={!!errors.vehicleCapacity}
            helperText={errors.vehicleCapacity?.message}
          />
        </Grid>

        {mode === Mode.Update && tariffIcon?.file && (
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item>
                <Typography variant='body1' fontWeight='Medium'>
                  Current Icon:
                </Typography>
              </Grid>
              <Grid item>
                <img src={tariffIcon.file?.absoluteUri} style={{ maxHeight: 80 }} />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <DropZoneIcon
            dropzoneText={mode === Mode.Create ? 'Add Tariff Icon*' : 'Update Tariff Icon'}
            filesLimit={1}
            onFilesChanged={handleFilesChanged}
          />
          {mode === Mode.Create && isSubmitted && !isFileSelected && (
            <FormHelperText sx={{ marginTop: 0.375, marginX: 1.75 }} error>
              Tariff Icon is required
            </FormHelperText>
          )}

          {mode === Mode.Update && isSubmitted && !tariffIcon?.fileId && !isFileSelected && (
            <FormHelperText sx={{ marginTop: 0.375, marginX: 1.75 }} error>
              Tariff Icon is required
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default TariffIconForm
