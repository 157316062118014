import React from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  AutoFillAddressDialog,
  CardTitle,
  ControlledCountryAutocomplete,
  ControlledPostCode,
  ControlledSelector,
  ControlledTextField,
} from 'components/common'
import { DEFAULT_COUNTRY_ID, ServiceTypeValues } from 'constants/index'
import {
  Address,
  CreateAndUpdateStrategicPartnerDtoInput,
  useGetLoqateRetrieveLazyQuery,
} from 'generated/graphql'

// ToDo: add nullable country id
const MainDetails = () => {
  const [openAutoFillAddress, setOpenAutoFillAddress] = React.useState(false)
  const {
    control,
    setValue,
    watch,

    formState: { errors },
  } = useFormContext<CreateAndUpdateStrategicPartnerDtoInput>()

  const postcode = watch('postcode')
  const countryId = watch('countryId')

  const spacing = 2

  // Queries
  const [getLoqateRetrieve] = useGetLoqateRetrieveLazyQuery({
    onCompleted: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetAddressDetails(data.loqateRetrieve as any)
    },
  })

  const handleAutoFillClick = () => {
    setOpenAutoFillAddress(true)
  }

  const handleAutoFillAddressSelect = (id: string) => {
    setOpenAutoFillAddress(false)
    getLoqateRetrieve({ variables: { id } })
  }

  const resetAddressDetails = React.useCallback(
    (address: Address) => {
      setValue('addressLine1', address.addressLine1)
      setValue('postcode', address.postcode)
      setValue('addressLine2', address.addressLine2)
      setValue('countryId', address.countryId)
      setValue('city', address.city)
      address.telephoneNumber && setValue('telephoneNumber', address.telephoneNumber)
    },
    [setValue],
  )

  return (
    <>
      <CardTitle>Main Details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='Name'
            name='name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledSelector
            control={control}
            label='ServiceType'
            name='serviceType'
            options={ServiceTypeValues}
            required
            error={!!errors.serviceType}
            helperText={errors.serviceType?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='City'
            name='city'
            error={!!errors.city}
            helperText={errors.city?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='Address Line 1'
            name='addressLine1'
            error={!!errors.addressLine1}
            helperText={errors.addressLine1?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='Address Line 2'
            name='addressLine2'
            error={!!errors.addressLine2}
            helperText={errors.addressLine2?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledPostCode
            control={control}
            name='postcode'
            defaultValue=''
            onAutoFillClick={handleAutoFillClick}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledCountryAutocomplete
            control={control}
            name='countryId'
            defaultValue={DEFAULT_COUNTRY_ID}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='Email'
            name='email'
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='Telephone Number'
            name='telephoneNumber'
            error={!!errors.telephoneNumber}
            helperText={errors.telephoneNumber?.message}
          />
        </Grid>
      </Grid>

      {openAutoFillAddress && (
        <AutoFillAddressDialog
          open={openAutoFillAddress}
          setOpen={setOpenAutoFillAddress}
          postcode={postcode || ''}
          countryId={countryId || 1}
          onSelectAddress={handleAutoFillAddressSelect}
        />
      )}
    </>
  )
}

export default MainDetails
