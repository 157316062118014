import { List, ListItem, Paper } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { AccountPayableStatusCategories, Paths } from 'constants/index'
import { accountPayableStatusCategoryVar } from 'graphql/reactiveVariables'

import OverviewTableItem from './OverviewTableItem'

interface IProps {
  countOfBookingsWithCompletedDocuments: number | undefined
  countOfBookingsWithOpenQueries: number | undefined
  countOfBookingsWithSupplementaryInvoiceReceived: number | undefined
  countOfBookingsWithCreditNoteReceived: number | undefined
  countOfBookingsWithApprovedDocuments: number | undefined
  countOfBookingsWithPaymentSent: number | undefined
  countOfOverduesForApproval: number | undefined
  countOfFailedPayments: number | undefined
  loading: boolean
}
function OverviewTable(props: IProps) {
  const {
    countOfBookingsWithCompletedDocuments = 0,
    countOfBookingsWithOpenQueries = 0,
    countOfBookingsWithSupplementaryInvoiceReceived = 0,
    countOfBookingsWithCreditNoteReceived = 0,
    countOfBookingsWithApprovedDocuments = 0,
    countOfBookingsWithPaymentSent = 0,
    countOfOverduesForApproval = 0,
    countOfFailedPayments = 0,
    loading,
  } = props

  const history = useHistory()

  const handleTableItemClick = (status: AccountPayableStatusCategories, path: string) => {
    accountPayableStatusCategoryVar(status)
    history.push(Paths.accountsPayable.all + path)
  }

  if (loading) {
    return <>Loading...</>
  }

  return (
    <Paper sx={{ border: '1px solid lightgrey' }}>
      <List sx={{ p: 0 }}>
        <ListItem divider>
          <OverviewTableItem
            invoiceTotal={countOfBookingsWithCompletedDocuments}
            statusLabel='Bookings with completed documents'
            onClick={() =>
              handleTableItemClick(
                AccountPayableStatusCategories.UnderReview,
                '/#accountsPayable-under-review',
              )
            }
          />
        </ListItem>
        <ListItem divider>
          <OverviewTableItem
            invoiceTotal={countOfBookingsWithOpenQueries}
            statusLabel='Bookings with open queries'
            onClick={() =>
              handleTableItemClick(
                AccountPayableStatusCategories.OpenQueries,
                '/#accountsPayable-queries',
              )
            }
          />
        </ListItem>
        <ListItem divider>
          <OverviewTableItem
            invoiceTotal={countOfBookingsWithSupplementaryInvoiceReceived}
            statusLabel='Bookings with supplementary invoice received'
          />
        </ListItem>
        <ListItem divider>
          <OverviewTableItem
            invoiceTotal={countOfBookingsWithCreditNoteReceived}
            statusLabel='Bookings with credit note received'
          />
        </ListItem>
        <ListItem divider>
          <OverviewTableItem
            invoiceTotal={countOfBookingsWithApprovedDocuments}
            statusLabel='Bookings with approved documents'
            onClick={() =>
              handleTableItemClick(
                AccountPayableStatusCategories.ScheduledPayment,
                '/#accountsPayable-scheduled-payment',
              )
            }
          />
        </ListItem>
        <ListItem divider>
          <OverviewTableItem
            invoiceTotal={countOfBookingsWithPaymentSent}
            statusLabel='Bookings with payment sent'
            onClick={() =>
              handleTableItemClick(
                AccountPayableStatusCategories.PaymentSent,
                '/#accountsPayable-payment-sent',
              )
            }
          />
        </ListItem>
        <ListItem divider>
          <OverviewTableItem
            invoiceTotal={countOfOverduesForApproval}
            statusLabel='Overdue for approval '
            onClick={() =>
              handleTableItemClick(
                AccountPayableStatusCategories.UnderReview,
                '/#accountsPayable-under-review',
              )
            }
          />
        </ListItem>
        <ListItem>
          <OverviewTableItem
            invoiceTotal={countOfFailedPayments}
            statusLabel='Failed Payments'
            onClick={() =>
              handleTableItemClick(
                AccountPayableStatusCategories.PaymentFailed,
                '/#accountsPayable-payment-failed',
              )
            }
          />
        </ListItem>
      </List>
    </Paper>
  )
}

export default OverviewTable
