import { List, ListItem, Typography } from '@mui/material'

import { GetEmailTemplateTokensQuery } from 'generated/graphql'

interface IProps {
  tokens?: GetEmailTemplateTokensQuery['emailTemplateTokens']
}

const EmailTokens = (props: IProps) => {
  const { tokens } = props

  return (
    <List>
      {tokens?.map((token) => (
        <ListItem key={token.numericValue}>
          <Typography>
            <strong>{token.token}</strong>
            {' - '}
            {token.description}
          </Typography>
        </ListItem>
      ))}
      <ListItem>
        <Typography>
          <strong>&lt;br/&gt;</strong>
          {' - Move to next line / New line'}
        </Typography>
      </ListItem>
    </List>
  )
}

export default EmailTokens
