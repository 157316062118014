import { DATE_FORMAT } from 'constants/DateFormats'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, Button } from '@mui/material'
import { IGridColumn } from 'components/common'
import { format } from 'date-fns'
import { AccountPayableRecordStatus } from 'generated/graphql'
import { parseDateWithoutTimeZone } from 'helpers'
import { numberFormat } from 'helpers/numberFormat'
import { TabId } from 'components/accountsPayable/AccountsPayableTabs'

function useGetAccountsPayableColumns(activeTabId?: TabId) {
  const ungroupedColumns: IGridColumn[] = [
    {
      field: 'SuplierName',
      headerName: 'Supplier (ID)',
      sortPath: ['bookingInvoice', 'bookingDriver', 'driver', 'name'],
      flex: 0.2,
      valueGetter(params) {
        return (
          params.row.bookingInvoice.bookingDriver.driver.name +
          ' (' +
          params.row.bookingInvoice.bookingDriver.driver.id +
          ')'
        )
      },
    },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice No.',
      sortPath: ['bookingInvoice', 'driverInvoiceNumber'],
      flex: 0.2,
      valueGetter(params) {
        return params.row.bookingInvoice.driverInvoiceNumber
      },
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      sortPath: ['bookingInvoice', 'invoiceDate'],
      flex: 0.3,
      valueGetter(params) {
        const invoiceDate = params.row.bookingInvoice.invoiceDate
        return invoiceDate
          ? format(parseDateWithoutTimeZone(invoiceDate as string), DATE_FORMAT)
          : ''
      },
    },
    {
      field: 'ourReference',
      headerName: 'FPL Number',
      sortPath: ['bookingInvoice', 'booking', 'ourReference'],
      sortable: false,
      flex: 0.3,
      valueGetter(params) {
        return params.row.bookingInvoice.booking.ourReference
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due date',
      sortPath: ['bookingInvoice', 'dueDate'],
      flex: 0.3,
      renderCell(params) {
        const dueDate = params.row.bookingInvoice.dueDate
        return (
          <div style={{ color: 'red' }}>
            {dueDate ? format(parseDateWithoutTimeZone(dueDate as string), DATE_FORMAT) : ''}
          </div>
        )
      },
    },
    {
      field: 'paymentDate',
      headerName: 'Payment date',
      sortPath: ['bookingInvoice', 'paymentDate'],
      flex: 0.3,
      renderCell(params) {
        const paymentDate = params.row.bookingInvoice.paymentDate
        return paymentDate
          ? format(parseDateWithoutTimeZone(paymentDate as string), DATE_FORMAT)
          : ''
      },
    },
    {
      field: 'net',
      sortable: false,
      headerName: 'Net Total',
      flex: 0.2,
      valueGetter(params) {
        return params.row.currency?.symbol + numberFormat(params.row.net, 0, 2)
      },
    },
    {
      field: 'vat',
      sortable: false,
      headerName: 'VAT',
      flex: 0.2,
      valueGetter(params) {
        return params.row.currency?.symbol + numberFormat(params.row.vat, 0, 2)
      },
    },
    {
      field: 'gross',
      sortable: false,
      headerName: 'Gross total',
      flex: 0.2,
      valueGetter(params) {
        return params.row.currency?.symbol + numberFormat(params.row.gross, 0, 2)
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.4,
      renderCell(params) {
        return <div style={{ color: 'red' }}>{params.row.statusLabel}</div>
      },
    },
    {
      field: 'actions',
      renderHeader() {
        return <NotificationsIcon />
      },
      renderCell: (params) => {
        const openQueriesBadgeColor: 'primary' | 'default' =
          params.row.openQueries.length > 0 ? 'primary' : 'default'
        return (
          <>
            <Badge badgeContent={params.row.openQueries.length} color={openQueriesBadgeColor}>
              <NotificationsIcon />
            </Badge>
            <Button variant='outlined'>
              {params.row.status === AccountPayableRecordStatus.UnderReview ? 'Approve' : 'View'}
            </Button>
            <MoreVertIcon />
          </>
        )
      },
      sortable: false,
      flex: 0.3,
    },
  ]

  const groupedColumns: IGridColumn[] = [
    {
      field: 'driverName',
      headerName: 'Supplier (ID)',
      flex: 0.2,
      valueGetter(params) {
        return params.row.driverName + ' (' + params.row.driverId + ')'
      },
    },

    { field: 'invoiceCount', headerName: 'No. of Records', flex: 0.2 },
    {
      field: 'net',
      sortable: false,
      headerName: 'Net Total',
      flex: 0.2,
      valueGetter(params) {
        let amountString = ''
        params.row.currencyAmountSum.forEach(function (amountData) {
          amountString =
            amountString + '  ' + amountData.currencySymbol + numberFormat(amountData.net, 0, 2)
        })
        return amountString
      },
    },
    {
      field: 'vat',
      sortable: false,
      headerName: 'VAT',
      flex: 0.2,
      valueGetter(params) {
        let amountString = ''
        params.row.currencyAmountSum.forEach(function (amountData) {
          amountString =
            amountString + '  ' + amountData.currencySymbol + numberFormat(amountData.vat, 0, 2)
        })
        return amountString
      },
    },
    {
      field: 'gross',
      sortable: false,
      headerName: 'Gross total',
      flex: 0.2,
      valueGetter(params) {
        let amountString = ''
        params.row.currencyAmountSum.forEach(function (amountData) {
          amountString =
            amountString + '  ' + amountData.currencySymbol + numberFormat(amountData.gross, 0, 2)
        })
        return amountString
      },
    },
    {
      field: 'actions',
      renderHeader() {
        return <NotificationsIcon />
      },
      renderCell() {
        return (
          <>
            <Badge badgeContent={4} color='primary'>
              <NotificationsIcon />
            </Badge>
            <Button variant='outlined'>View</Button>
            <MoreVertIcon></MoreVertIcon>
          </>
        )
      },
      sortable: false,
      flex: 0.2,
    },
  ]

  if (
    activeTabId === TabId.ScheduledPayment ||
    activeTabId === TabId.PaymentSent ||
    activeTabId === TabId.PaymentFailed ||
    activeTabId === TabId.Archive
  ) {
    const statusIndex = ungroupedColumns.findIndex((column) => column.field === 'status')
    if (statusIndex !== -1) {
      ungroupedColumns.splice(statusIndex + 1, 0, {
        field: 'batchId',
        headerName: 'Batch ID',
        sortPath: ['bookingInvoiceBatchLine', 'batchId'],
        flex: 0.2,
        valueGetter(params) {
          const batchId = params.row.bookingInvoiceBatchLine?.batchId
          return batchId ? batchId : 'N/A'
        },
      })
    }
  }

  return [ungroupedColumns, groupedColumns]
}

export default useGetAccountsPayableColumns
