import { compareAsc, eachDayOfInterval, isWeekend, isWithinInterval, parse, set } from 'date-fns'

export type DateInterval = {
  start: Date
  end: Date
}

export const businessDaysBetween = (interval: DateInterval): number =>
  eachDayOfInterval(interval).filter((day) => !isWeekend(day)).length - 1

export const isWithinTimeInterval = (date: Date, interval: DateInterval): boolean => {
  // isWithinInterval checks date & time,
  // so we have to be sure that de date is the same for all
  const commonDate = new Date()

  return isWithinInterval(
    set(commonDate, { hours: date.getHours(), minutes: date.getMinutes(), seconds: 0 }),
    {
      start: set(commonDate, { hours: interval.start.getHours(), minutes: 0, seconds: 0 }),
      end: set(commonDate, { hours: interval.end.getHours(), minutes: 0, seconds: 0 }),
    },
  )
}

export const daysBetween = (interval: DateInterval): number =>
  eachDayOfInterval(interval).length - 1

export const dateIsGreater = (dateLeft: Date | number, dateRight: Date | number): boolean =>
  compareAsc(dateLeft, dateRight) === 1

export const formatToShortDate = (date: string) => {
  const shortDate = date.split('T')[0]
  return shortDate
}

export const dateTimeZoneFormat = (date: Date | null, order: 'start' | 'end'): Date | null => {
  if (!date) {
    return null
  }

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const dateString = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`

  if (order === 'start') {
    return new Date(`${dateString}T00:00:00Z`)
  } else {
    return new Date(`${dateString}T23:59:59Z`)
  }
}

export function parseDateWithoutTimeZone(dateString: string) {
  const [year, month, day] = dateString.split('T')[0].split('-').map(Number)

  const parsedDate = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date())

  return parsedDate
}
