import React from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, DialogActions, Paper } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { CXMemberDetails } from 'components/bookings/PostLoadOrBookDirect'
import {
  FixedOrderDtoPaymentTerm,
  GetBookingAllocatedDriversDocument,
  SortEnumType,
  useBookLoadMutation,
  useGetCourierExchangeQuoteLazyQuery,
} from 'generated/graphql'

import AvailableQuotes from './AvailableQuotes'

interface IProps {
  bookingId: string | null
  courierExchangeLoadId?: string | null
  isQuote?: boolean
}

const AllocateCXMember = (props: IProps) => {
  const { bookingId, courierExchangeLoadId = null, isQuote = false } = props

  const methods = useForm({
    shouldUnregister: true,
    defaultValues: {
      orderSubcontractorId: undefined,
      orderAgreedRate: 0,
      orderPaymentTerm: FixedOrderDtoPaymentTerm.Value_30Eom,
    },
  })
  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods

  // Queries
  const [getCourierExchangeQuote, { data: courierExchangeQuoteData }] =
    useGetCourierExchangeQuoteLazyQuery({
    })

  // Mutations
  const [bookLoad, { loading: bookLoadProcessing }] = useBookLoadMutation({
    refetchQueries: [
      {
        query: GetBookingAllocatedDriversDocument,
        variables: {
          bookingId: Number(bookingId),
          order: { createdAt: SortEnumType.Desc },
        },
      },
    ],
    onCompleted: (response) => {
      if (!response) {
        toast.error('Something went wrong.')
        return
      }

      reset()
      toast.success('CX Member was allocated with success')
    },
  })

  const courierExchangeQuote = courierExchangeQuoteData?.courierExchangeQuote
  React.useEffect(() => {
    if (courierExchangeQuote) {
      setValue('orderSubcontractorId', courierExchangeQuote?.quoteOwnerCompanyId, {
        shouldDirty: true,
      })
      setValue('orderAgreedRate', Number(courierExchangeQuote?.totalPrice), { shouldDirty: true })
    }
  }, [courierExchangeQuote, setValue])

  const handleSelectQuote = (id: string | undefined) => {
    id && getCourierExchangeQuote({ variables: { courierExchangeQuoteId: Number(id) } })
  }

  const handleAllocateClick = (data) => {
    const { orderAgreedRate, orderPaymentTerm, orderSubcontractorId } = data
    const loadId = courierExchangeQuote?.loadId ?? courierExchangeLoadId
    if (!loadId) {
      toast.error('LoadId needs to have a value.')
      return
    }

    bookLoad({
      variables: {
        input: {
          loadId: Number(loadId),
          courierExchangeQuoteId: Number(courierExchangeQuote?.id),
          agreedRate: Number(orderAgreedRate),
          paymentTerm: orderPaymentTerm,
          subcontractorId: Number(orderSubcontractorId),
        },
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleAllocateClick)} noValidate>
        <Box>
          <Paper variant='outlined'>
            <AvailableQuotes bookingId={bookingId} onSelectQuote={handleSelectQuote} />
          </Paper>
        </Box>
        <Box marginY={1.5}>
          <Paper variant='outlined'>
            <Box padding={2}>
              <CXMemberDetails disabled={isQuote} />
            </Box>
          </Paper>
        </Box>
        <DialogActions>
          <LoadingButton
            color='primary'
            type='submit'
            disabled={!isDirty || isQuote}
            loading={bookLoadProcessing}>
            Allocate
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  )
}

export default AllocateCXMember
