import { Box, Container, Grid, Stack, Typography } from '@mui/material'

import DownloadIcon from 'assets/images/auth-page-icons/download-icon.png'
import SuperFastIcon from 'assets/images/auth-page-icons/super-fast-icon.png'
import TrackIcon from 'assets/images/auth-page-icons/track-icon.png'
import HeroImageCrop from 'assets/images/hero-image-crop.jpg'

import SignUpForm from './SignUpForm'

const SingUpPage = () => {
  return (
    <Stack>
      <Box
        width='100%'
        sx={{
          backgroundImage: `url(${HeroImageCrop})`,
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <Container maxWidth='lg'>
          <Box marginY={7}>
            <Typography fontSize='38px' color='#c6017e' fontFamily='Goldplay-Bold, sans-serif'>
              Activate your online access
            </Typography>

            <Typography fontSize='18px' fontFamily='Goldplay-Bold, sans-serif'>
              Book and track your delivery online in minutes
            </Typography>
          </Box>
        </Container>
      </Box>

      <Container maxWidth='lg'>
        <Grid container columnSpacing={6} rowSpacing={4} marginTop={3} marginBottom={6}>
          <Grid item xs={12} sm={4}>
            <Stack justifyContent='space-between' alignItems='start'>
              <Stack justifyContent='center' height='100px'>
                <img src={SuperFastIcon} alt='Super Fast Icon' style={{ maxHeight: 60 }} />
              </Stack>

              <Box marginBottom={2} marginTop={4}>
                <Typography
                  fontSize='23px'
                  fontFamily='Goldplay-SemiBold, sans-serif'
                  sx={{
                    background: 'linear-gradient(to right, #471d7c, #c6007e)',
                    backgroundClip: 'text',
                    color: 'transparent',
                  }}>
                  Super-fast
                </Typography>
              </Box>

              <Typography fontSize='16px' fontFamily='Goldplay-Medium, sans-serif'>
                Simply quicker than using the phone or email the Firstpoint quoting and booking
                platform gets your goods on the road so you don&apos;t waste any time.
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Stack justifyContent='space-between' alignItems='start'>
              <div>
                <img src={TrackIcon} alt='Track Icon' style={{ maxHeight: 100 }} />
              </div>

              <Box marginBottom={2} marginTop={4}>
                <Typography
                  fontSize='23px'
                  fontFamily='Goldplay-SemiBold, sans-serif'
                  sx={{
                    background: 'linear-gradient(to right, #471d7c, #c6007e)',
                    backgroundClip: 'text',
                    color: 'transparent',
                  }}>
                  Track your consignments
                </Typography>
              </Box>

              <Typography fontSize='16px' fontFamily='Goldplay-Medium, sans-serif'>
                From the second you book to the final second we deliver, your consignments are
                tracked and notified to you in real-time right through to your destination.
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Stack justifyContent='space-between' alignItems='start'>
              <div>
                <img src={DownloadIcon} alt='Download Icon' style={{ maxHeight: 100 }} />
              </div>

              <Box marginBottom={2} marginTop={4}>
                <Typography
                  fontSize='23px'
                  fontFamily='Goldplay-SemiBold, sans-serif'
                  sx={{
                    background: 'linear-gradient(to right, #471d7c, #c6007e)',
                    backgroundClip: 'text',
                    color: 'transparent',
                  }}>
                  Download KPI reports
                </Typography>
              </Box>

              <Typography fontSize='16px' fontFamily='Goldplay-Medium, sans-serif'>
                Need access to your entire delivery history or just one consignment? You can
                download all the data you need and more through your online account.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <SignUpForm />
    </Stack>
  )
}

export default SingUpPage
