import React from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { CreateAndUpdateDriverContactDtoInput, useGetDriverContactQuery } from 'generated/graphql'

import ContactForm from './ContactForm'

interface IProps {
  contactId: number
}

const UpdateContact = (props: IProps) => {
  const { contactId } = props

  const { reset } = useFormContext<CreateAndUpdateDriverContactDtoInput>()

  // Queries
  const { data, loading } = useGetDriverContactQuery({
    variables: { driverContactId: contactId },
  })

  React.useEffect(() => {
    if (data) {
      reset({ ...data.driverContact })
    }
  }, [data, reset])

  return (
    <>
      <ContactForm />
      <LoadingBackdrop loading={loading} />
    </>
  )
}

export default UpdateContact
