import { useMemo } from 'react'

import { BookingAddressType, BookingStatus, GetBookingQuery } from 'generated/graphql'

const useTabsTooltipText = (bookingId: string | null, booking: any): string => {
  const tabsTooltipText = useMemo(() => {
    if (!bookingId) {
      return 'Available only for existing bookings'
    }

    if (booking?.status === BookingStatus.ScheduledTemplate) {
      return 'Not available for Scheduled Booking Template'
    }

    const collectionAddress = booking?.addresses.filter(
      (address) => address.type === BookingAddressType.Collection,
    )
    const deliveryAddress = booking?.addresses.filter(
      (address) => address.type === BookingAddressType.Delivery,
    )

    if (collectionAddress?.length === 0 || deliveryAddress?.length === 0) {
      return 'Available only if addresses are selected'
    }

    if ((booking as GetBookingQuery)?.booking?.drivers?.length === 0) {
      return 'Booking already has Post Load'
    }

    if (booking?.courierExchangeLoadId) {
      return 'CX Member was already allocated'
    }

    return ''
  }, [bookingId, booking])

  return tabsTooltipText
}

export { useTabsTooltipText }
