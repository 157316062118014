import Joi from 'joi'

import { AddressPackageDtoInput, UpdateProofOfDeliveryDtoInput } from 'generated/graphql'

const validationSchema = Joi.object<UpdateProofOfDeliveryDtoInput>({
  signedAt: Joi.date().empty(null).required().label('Signed at'),
  arrivedAt: Joi.date().empty(null).required().label('Arrived at'),
  noteFromDriver: Joi.string().empty('').optional().label('Note from driver'),
  signedByFirstName: Joi.string().empty('').required().label('First name'),
  signedByLastName: Joi.string().empty('').required().label('Last name'),
  waitingTime: Joi.number().empty(null).optional().min(0).label('Waiting time'),
  packages: Joi.array<AddressPackageDtoInput>()
    .optional()
    .items({
      quantity: Joi.number().empty(0).required().label('Quantity'),
    }),
})

export default validationSchema
