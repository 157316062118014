import { useMemo } from 'react'

import { QuotesTabId } from 'constants/Tabs'

const allTabs = {
  quoteDetails: {
    id: QuotesTabId.Details,
    label: 'Quote Details',
    hash: '#quote-details',
  },
  mapRoute: {
    id: QuotesTabId.MapRoute,
    label: 'Map Route',
    hash: '#map-route',
  },
  attachments: {
    id: QuotesTabId.Attachments,
    label: 'Attachments',
    hash: '#attachments',
  },
  postLoad: {
    id: QuotesTabId.PostLoad,
    label: 'Post Load or Book Direct',
    hash: '#post-load',
  },
  allocateCXMember: {
    id: QuotesTabId.AllocateCXMember,
    label: 'Allocate CX Member',
    hash: '#allocate-cx-member',
  },
  tasks: {
    id: QuotesTabId.Tasks,
    label: 'Tasks',
    hash: '#tasks',
  },
}

const useQuoteAllTabs = () => {
  return useMemo(() => allTabs, [])
}

export { useQuoteAllTabs }
