import { useMemo } from 'react'

import { ControlledSelector } from 'components/common'
import { SortEnumType, useGetCustomersQuery } from 'generated/graphql'

interface IProps {
  control: any
}

const CustomersSelector = (props: IProps) => {
  const { control } = props

  // Queries
  const { data: customersData } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
  })

  const customersItems = useMemo(
    () =>
      customersData?.customers.map((customer) => ({
        value: customer.id,
        label: customer.name,
      })) || [],
    [customersData],
  )

  return (
    <ControlledSelector
      control={control}
      name='customerId'
      label='Customer'
      options={customersItems}
      emptyValue={{ value: '', label: 'All Customers' }}
      displayEmpty
    />
  )
}

export default CustomersSelector
