import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import VerifiedIcon from '@mui/icons-material/Verified'
import { Grid, InputAdornment } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledCountryAutocomplete,
  ControlledDateTimePicker,
  ControlledPostCode,
  ControlledReasonCodeAutocomplete,
  ControlledTextField,
} from 'components/common'
import { TBookingForm } from 'types'

interface IProps {
  labelAt: string
  labelBy: string
  addressType: string
  index: number
  isAtRequired: boolean
  isByRequired: boolean
  disabled: boolean | undefined
  isQuickQuote: boolean
  item: any
  isReasonCodeEnabled: boolean
  customerIdValue: number
  isUpdate: boolean | undefined
  handleAutoFillClick: () => void
  handleFindClick: () => void
  isContactNameAndTelNumberRequired: boolean
  isBookingInternational: boolean
}

const BookingAddressForm = ({
  labelAt,
  labelBy,
  addressType,
  index,
  isAtRequired,
  isByRequired,
  disabled,
  isQuickQuote,
  item,
  isReasonCodeEnabled,
  customerIdValue,
  isUpdate,
  handleAutoFillClick,
  handleFindClick,
  isContactNameAndTelNumberRequired,
  isBookingInternational,
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TBookingForm>()
  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={6}>
          <ControlledDateTimePicker
            control={control}
            label={labelAt}
            name={`${addressType}Addresses.${index}.at`}
            defaultValue=''
            required={isAtRequired}
            disabled={disabled}
            autoComplete='chrome-off'
            error={!!errors?.[`${addressType}Addresses`]?.[index]?.at}
            helperText={errors?.[`${addressType}Addresses`]?.[index]?.at?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledDateTimePicker
            control={control}
            label={labelBy}
            name={`${addressType}Addresses.${index}.by`}
            defaultValue=''
            required={isByRequired}
            disabled={disabled}
            autoComplete='chrome-off'
            error={!!errors?.[`${addressType}Addresses`]?.[index]?.by}
            helperText={errors?.[`${addressType}Addresses`]?.[index]?.by?.message}
          />
        </Grid>

        {!isQuickQuote && (
          <>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Title/Company Name'
                name={`${addressType}Addresses.${index}.name`}
                defaultValue=''
                required
                disabled={disabled}
                autoComplete='chrome-off'
                endAdornment={
                  <InputAdornment position='end'>
                    {(item.isVerified !== '' || false) &&
                      (item.isVerified ? (
                        <span title='Address is verified via Google Maps'>
                          <VerifiedIcon color='primary' />
                        </span>
                      ) : (
                        <span title='Address is not verified via Google Maps'>
                          <NotListedLocationIcon color='error' />
                        </span>
                      ))}
                  </InputAdornment>
                }
                error={!!errors?.[`${addressType}Addresses`]?.[index]?.name}
                helperText={errors?.[`${addressType}Addresses`]?.[index]?.name?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Town/City'
                name={`${addressType}Addresses.${index}.city`}
                defaultValue=''
                required
                disabled={disabled}
                autoComplete='chrome-off'
                error={!!errors?.[`${addressType}Addresses`]?.[index]?.city}
                helperText={errors?.[`${addressType}Addresses`]?.[index]?.city?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Address Line 1'
                name={`${addressType}Addresses.${index}.addressLine1`}
                defaultValue=''
                required
                disabled={disabled}
                autoComplete='chrome-off'
                error={!!errors?.[`${addressType}Addresses`]?.[index]?.addressLine1}
                helperText={errors?.[`${addressType}Addresses`]?.[index]?.addressLine1?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Address Line 2'
                name={`${addressType}Addresses.${index}.addressLine2`}
                defaultValue={item.addressLine2}
                disabled={disabled}
                autoComplete='chrome-off'
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6}>
          <ControlledPostCode
            control={control}
            name={`${addressType}Addresses.${index}.postcode`}
            defaultValue=''
            required
            disabled={disabled}
            autoComplete='chrome-off'
            error={!!errors?.[`${addressType}Addresses`]?.[index]?.postcode}
            helperText={errors?.[`${addressType}Addresses`]?.[index]?.postcode?.message}
            onFindClick={handleFindClick}
            onAutoFillClick={handleAutoFillClick}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledCountryAutocomplete
            control={control}
            name={`${addressType}Addresses.${index}.countryId`}
            required
            defaultValue=''
            disabled={disabled}
            autoComplete='chrome-off'
          />
        </Grid>

        {!isQuickQuote && (
          <>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Contact Name'
                name={`${addressType}Addresses.${index}.contact`}
                defaultValue=''
                disabled={disabled}
                autoComplete='chrome-off'
                required={isBookingInternational || isContactNameAndTelNumberRequired}
                error={!!errors?.[`${addressType}Addresses`]?.[index]?.contact}
                helperText={errors?.[`${addressType}Addresses`]?.[index]?.contact?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Contact Email'
                name={`${addressType}Addresses.${index}.email`}
                type='email'
                defaultValue=''
                disabled={disabled}
                autoComplete='chrome-off'
                required={isBookingInternational}
                error={!!errors?.[`${addressType}Addresses`]?.[index]?.email}
                helperText={errors?.[`${addressType}Addresses`]?.[index]?.email?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Telephone Number'
                name={`${addressType}Addresses.${index}.telephoneNumber`}
                defaultValue=''
                disabled={disabled}
                autoComplete='chrome-off'
                required={isBookingInternational || isContactNameAndTelNumberRequired}
                error={!!errors?.[`${addressType}Addresses`]?.[index]?.telephoneNumber}
                helperText={errors?.[`${addressType}Addresses`]?.[index]?.telephoneNumber?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Note for Driver'
                name={`${addressType}Addresses.${index}.note`}
                defaultValue=''
                multiline
                disabled={disabled}
              />
            </Grid>
          </>
        )}

        {addressType === 'delivery' && isReasonCodeEnabled && (
          <Grid item xs={12} sm={6}>
            <ControlledReasonCodeAutocomplete
              control={control}
              name={`${addressType}Addresses.${index}.reasonCodeId`}
              defaultValue=''
              disabled={disabled}
              autoComplete='chrome-off'
              required={isReasonCodeEnabled}
              error={!!errors?.[`${addressType}Addresses`]?.[index]?.reasonCodeId}
              helperText={errors?.[`${addressType}Addresses`]?.[index]?.reasonCodeId?.message}
              customerId={customerIdValue}
              isIncludeSubCustomers={isUpdate}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export { BookingAddressForm }
