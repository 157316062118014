import React from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { FplDialogTitle } from 'components/common/Dialog'
import { Mode, ValidationMessages, DEFAULT_COUNTRY_ID, JoiValidationOptions } from 'constants/index'
import {
  Address,
  CreateAndUpdateAddressDtoInput,
  useCreateAddressMutation,
  useUpdateAddressMutation,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'
import { CreateUpdateCustomerAddressValidation } from 'validation-schemas'

import UpdateAddress from '../UpdateAddress'
import AddressForm from './AddressForm'
import AddressFormHeader from './AddressFormHeader'

// ToDo: remove
yup.setLocale(ValidationMessages)

interface IProps {
  addressId: number | null
  customerId: number | null
  openDialog: boolean
  handleCloseDialog: () => any
}

const CreateUpdateAddress = (props: IProps) => {
  const { addressId, customerId, openDialog, handleCloseDialog } = props

  const [address, setAddress] = React.useState<CreateAndUpdateAddressDtoInput>({
    customerId,
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    email: '',
    contact: '',
    isAutoFill: false,
    countryId: DEFAULT_COUNTRY_ID,
    telephoneNumber: '',
    isNotification: false,
    isDefault: false,
  })

  const methods = useForm<CreateAndUpdateAddressDtoInput>({
    shouldUnregister: true,
    defaultValues: { ...address },
    resolver: joiResolver(CreateUpdateCustomerAddressValidation, JoiValidationOptions),
  })

  const {
    handleSubmit,
    formState: { isDirty: formIsDirty },
    reset,
  } = methods

  React.useEffect(() => {
    reset({ ...address })
  }, [reset, address])

  const [createAddress, { error: createAddressError, loading: creatingAddress }] =
    useCreateAddressMutation()
  const [updateAddress, { error: updateAddressError, loading: updatingAddress }] =
    useUpdateAddressMutation()

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  const mode = addressId ? Mode.Update : Mode.Create

  const handleOnSuccess = (data) => {
    if (mode === Mode.Create) {
      createAddress({
        variables: {
          input: {
            customerId,
            ...data,
          } as CreateAndUpdateAddressDtoInput,
        },
      }).then((result) => {
        toast.success(
          `'${result.data?.createAddress.name}' Title/Company was successfully created.`,
        )
        handleCloseDialog()
      })
    } else {
      updateAddress({
        variables: {
          input: {
            id: addressId,
            customerId,
            ...data,
          } as CreateAndUpdateAddressDtoInput,
        },
      }).then((result) => {
        toast.success(
          `'${result.data?.updateAddress.name}' Title/Company was successfully updated.`,
        )
        handleCloseDialog()
      })
    }
  }

  const handleOnCancel = () => {
    if (formIsDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  const isProcessing = creatingAddress || updatingAddress
  const processingErrors = createAddressError || updateAddressError

  if (processingErrors) {
    processingErrors?.graphQLErrors.map((e) => toast.error(e.message))
  }

  return (
    <Dialog fullWidth maxWidth='md' open={openDialog} aria-labelledby='form-address-dialog-title'>
      <FplDialogTitle id='form-address-dialog-title' onClose={handleOnCancel}>
        <AddressFormHeader mode={mode} />
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <form id='create-update-address-form' onSubmit={handleSubmit(handleOnSuccess)} noValidate>
            {mode === Mode.Create ? (
              <AddressForm />
            ) : (
              <UpdateAddress
                addressId={addressId as number}
                address={address as Address}
                setAddress={setAddress}
              />
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button
          form='create-update-address-form'
          color='primary'
          variant='contained'
          type='submit'
          disabled={isProcessing}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateAddress
