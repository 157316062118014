import { gql } from '@apollo/client'

export const GET_COURIER_EXCHANGE_QUOTES = gql`
  query GetCourierExchangeQuotes(
    $loadId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [CourierExchangeQuoteSortInput!]
    $where: CourierExchangeQuoteFilterInput
  ) {
    courierExchangeQuotes(
      loadId: $loadId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          loadId
          quoteId
          price
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_COURIER_EXCHANGE_QUOTE = gql`
  query GetCourierExchangeQuote($courierExchangeQuoteId: Long!) {
    courierExchangeQuote(courierExchangeQuoteId: $courierExchangeQuoteId) {
      id
      quoteId
      loadId
      quoteOwnerCompanyId
      totalPrice
    }
  }
`

export const GET_PACKAGING_TYPES_QUERY = gql`
  query getPackagingTypes {
    packagingTypes {
      id
      description
      packagingType
      disabled
    }
  }
`

export const GET_LOAD_CURRENT_LOCATION = gql`
  query GetLoadCurrentLocation($loadId: Long!) {
    loadCurrentLocation(loadId: $loadId) {
      driverId
      longitude
      latitude
      accuracy
    }
  }
`
