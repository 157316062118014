import { format, parseJSON } from 'date-fns'

import { IGridColumn } from 'components/common'
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/index'

export const getColumns = (isForUser: boolean): IGridColumn[] =>
  isForUser
    ? [
        { field: 'name', headerName: 'Name', minWidth: 250 },
        { field: 'code', headerName: 'Code', minWidth: 250 },
        { field: 'description', headerName: 'Description', minWidth: 200, flex: 1 },
        {
          field: 'createdAt',
          headerName: 'Created On',
          minWidth: 150,
          valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
        },
      ]
    : [
        {
          field: 'customer',
          headerName: 'Customer',
          minWidth: 200,
          flex: 1,
          sortPath: ['customer', 'name'],
          valueGetter: ({ value }) => value?.name,
        },
        { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
        { field: 'code', headerName: 'Code', minWidth: 200, flex: 1 },
        {
          field: 'createdAt',
          headerName: 'Created At',
          minWidth: 200,
          flex: 1,
          valueFormatter: ({ value }) =>
            value && format(parseJSON(value as string), DATE_TIME_FORMAT),
        },
        { field: 'createdBy', headerName: 'Created By', minWidth: 200, flex: 1 },
      ]
