import { useEffect, useMemo } from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledAutocomplete, ControlledTextField } from 'components/common'
import { SortEnumType, useGetCustomersQuery, useGetDriversLazyQuery } from 'generated/graphql'

const ApproveDeclineUserForm = () => {
  const {
    control,
    watch,
    setValue,

    formState: { errors },
  } = useFormContext()

  const customerIdValue = watch('customerId')
  const driverId = watch('driverId')

  const { loading: customersLoading, data: customersData } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
  })

  const [getDrivers, { loading: driversLoading, data: driversData }] = useGetDriversLazyQuery({
    variables: { order: { name: SortEnumType.Asc } },
  })

  useEffect(() => {
    if (driverId) {
      getDrivers()
    }
  }, [driverId, getDrivers])

  const customerOptions = useMemo(
    () =>
      customersData?.customers.map((customer) => ({
        value: customer.id,
        label: customer.name,
      })) || [],
    [customersData],
  )

  const driverOptions = useMemo(
    () =>
      driversData?.drivers?.edges?.map((driver) => ({
        value: driver.node.id,
        label: driver.node.name,
      })) || [],
    [driversData],
  )

  const contactOptions = useMemo(() => {
    const customerContacts = customersData?.customers.find(
      (customer) => customer.id === String(customerIdValue),
    )?.contacts

    return customerContacts?.map((contact) => ({ value: contact.id, label: contact.name })) || []
  }, [customersData, customerIdValue])

  useEffect(() => {
    if (customerIdValue) {
      setValue('contactId', '')
    }
  }, [customerIdValue, setValue])

  return (
    <Grid container columnSpacing={3} rowSpacing={1.5}>
      <Grid item xs={12} sm={6}>
        <ControlledTextField control={control} label='Email' name='email' readOnly />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField control={control} label='Username' name='userName' readOnly />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField control={control} label='First Name' name='firstName' readOnly />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField control={control} label='Last Name' name='lastName' readOnly />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField control={control} label='Phone' name='phoneNumber' readOnly />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField control={control} label='Role' name='role' readOnly />
      </Grid>

      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label='Suggested Company name'
          name='suggestedCompany'
          readOnly
        />
      </Grid>

      <Grid item xs={12} sm={6} display={!driverId ? 'none' : undefined}>
        <ControlledAutocomplete
          control={control}
          name='driverId'
          label='Driver'
          options={driverOptions}
          defaultValue=''
          loading={driversLoading}
          error={!!errors.driverId}
          helperText={errors.driverId?.message}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6} display={driverId ? 'none' : undefined}>
        <ControlledAutocomplete
          control={control}
          name='customerId'
          label='Customer'
          options={customerOptions}
          required
          defaultValue=''
          loading={customersLoading}
          error={!!errors.customerId}
          helperText={errors.customerId?.message}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={driverId ? 'none' : undefined}>
        <ControlledAutocomplete
          control={control}
          name='contactId'
          label='Contact'
          options={contactOptions}
          defaultValue=''
          error={!!errors.contactId}
          helperText={errors.contactId?.message}
        />
      </Grid>
    </Grid>
  )
}

export default ApproveDeclineUserForm
