import { SortEnumType } from 'generated/graphql'
import { GET_PAGED_REASON_CODES, GET_USER_PAGED_REASON_CODES } from 'graphql/queries'

export const getRefetchQueries = (isForUser: boolean) => [
  isForUser
    ? {
        query: GET_USER_PAGED_REASON_CODES,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { name: SortEnumType.Asc },
        },
      }
    : {
        query: GET_PAGED_REASON_CODES,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { createdAt: SortEnumType.Desc },
        },
      },
]
