import { Button, DialogActions } from '@mui/material'

interface IProps {
  onCloseClick: () => void
  isDirty: boolean
}

export default function CongestionDialogActions(props: IProps) {
  const { onCloseClick, isDirty } = props

  return (
    <DialogActions>
      <Button variant='contained' color='grey' onClick={onCloseClick}>
        {isDirty ? 'Cancel' : 'Close'}
      </Button>
      <Button color='primary' variant='contained' type='submit' disabled={!isDirty}>
        Save
      </Button>
    </DialogActions>
  )
}
