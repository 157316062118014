import { useEffect } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { Button, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

import { ControlledSelector } from 'components/common'
import { BookingChargeTypeValues } from 'constants/index'
import { BookingChargeType } from 'generated/graphql'

import { TChargeToolbarForm } from './chargeToolbar.types'
import OtherChargeOptions from './OtherChargeOptions'
import SpecialZoneChargeOptions from './SpecialZoneChargeOptions'
import validationSchema from './ValidationSchema'

const schema = validationSchema()

interface IProps {
  disabled: boolean
  onAddCharge: (formData: TChargeToolbarForm) => void
}

const ChargeToolbar = (props: IProps) => {
  const { disabled, onAddCharge } = props

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<TChargeToolbarForm>({
    resolver: joiResolver(schema),
    defaultValues: {
      type: BookingChargeType.Other,
      name: '',
      rate: '',
      description: '',
      xeroServiceCode: '',
      vatCategoryId: '',
      specialZoneId: '',
      otherChargeId: '',
    },
  })

  const chargeType = watch('type')

  useEffect(() => {
    reset({
      type: chargeType,
      name: '',
      rate: '',
      description: '',
      xeroServiceCode: '',
      vatCategoryId: '',
      specialZoneId: '',
      otherChargeId: '',
    })
  }, [chargeType, reset])

  return (
    <Grid container spacing={1.5} justifyContent='space-between' alignItems='center'>
      <Grid item xs={12} sm={4}>
        <ControlledSelector
          control={control}
          label='Type'
          name='type'
          options={BookingChargeTypeValues}
          defaultValue=''
          required
          disabled={disabled}
          error={!!errors.type}
          helperText={errors.type?.message}
        />
      </Grid>

      {chargeType === BookingChargeType.SpecialZone && (
        <SpecialZoneChargeOptions
          control={control}
          watch={watch}
          setValue={setValue}
          errors={errors}
          disabled={disabled}
        />
      )}

      {chargeType === BookingChargeType.Other && (
        <OtherChargeOptions
          control={control}
          watch={watch}
          setValue={setValue}
          errors={errors}
          disabled={disabled}
        />
      )}

      <Grid item xs='auto' marginLeft='auto'>
        <Button
          variant='contained'
          color='grey'
          disabled={disabled}
          onClick={handleSubmit(onAddCharge)}>
          Add
        </Button>
      </Grid>
    </Grid>
  )
}

export default ChargeToolbar
