import { FplDataGrid, IGridColumn } from 'components/common'
import { GET_PAGED_CURRENCIES_QUERY } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'ID', minWidth: 100, flex: 1 },
  { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
  { field: 'code', headerName: 'Code', minWidth: 200, flex: 1 },
  {
    field: 'isBaseCurrency',
    headerName: 'Is Base Currency',
    minWidth: 200,
    flex: 1,
    type: 'boolean',
  },
]

const Currencies = () => {
  return (
    <FplDataGrid
      query={GET_PAGED_CURRENCIES_QUERY}
      entityName='pagedCurrencies'
      columns={columns}
      defaultOrder={{ field: 'id', sort: 'asc' }}
      toolbar={{ caption: 'Currencies' }}
    />
  )
}

export default Currencies
