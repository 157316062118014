import { Search as Icon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const SearchIcon = () => {
  return (
    <SearchIconWrapper>
      <Icon />
    </SearchIconWrapper>
  )
}

export default SearchIcon
