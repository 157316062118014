import { endOfDay, startOfDay } from 'date-fns'
import { FormProvider, useForm } from 'react-hook-form'

import { ReportFileType, ReportTypeButtonsId } from 'constants/EntityConstants'
import { SalesCommissionReportInputDtoInput, useGetSalesNamesQuery } from 'generated/graphql'

import FilterCriteriaForm from './FilterCriteriaForm'

interface IProps {
  defaultFilter: SalesCommissionReportInputDtoInput
  isProcessing: boolean
  onViewReport: (filter: SalesCommissionReportInputDtoInput) => void
  onDownloadReport: (type: ReportFileType, filter: SalesCommissionReportInputDtoInput) => void
}

const FilterCriteria = (props: IProps) => {
  const { defaultFilter, isProcessing, onViewReport, onDownloadReport } = props

  const methods = useForm<SalesCommissionReportInputDtoInput>({
    shouldUnregister: true,
    defaultValues: defaultFilter,
  })
  const { handleSubmit, reset } = methods

  // Query
  const { loading: salesNamesLoading, data: salesNamesData } = useGetSalesNamesQuery()

  const handleClearAll = () => {
    reset(defaultFilter)
  }

  const handleOnSubmit = (buttonId: ReportTypeButtonsId, formData) => {
    const { start, end, salesName } = formData

    const filter: SalesCommissionReportInputDtoInput = {
      start: start ? startOfDay(start) : undefined,
      end: end ? endOfDay(end) : undefined,
      salesName: salesName || undefined,
    }

    switch (buttonId) {
      case ReportTypeButtonsId.ViewReport:
        onViewReport(filter)
        break

      case ReportTypeButtonsId.DownloadXlsx:
        onDownloadReport(ReportFileType.xlsx, filter)
        break

      case ReportTypeButtonsId.DownloadPdf:
        onDownloadReport(ReportFileType.pdf, filter)
        break

      case ReportTypeButtonsId.DownloadHtml:
        onDownloadReport(ReportFileType.html, filter)
        break
    }
  }

  const handleReportButtonClick = (buttonId: ReportTypeButtonsId) => {
    handleSubmit((formData) => handleOnSubmit(buttonId, formData))()
  }

  return (
    <FormProvider {...methods}>
      <FilterCriteriaForm
        salesNames={salesNamesData?.salesNames}
        salesNamesLoading={salesNamesLoading}
        isProcessing={isProcessing}
        onReportButtonClick={handleReportButtonClick}
        onClearAll={handleClearAll}
      />
    </FormProvider>
  )
}

export default FilterCriteria
