import { gql } from '@apollo/client'

export const GET_PAGED_CURRENCIES_QUERY = gql`
  query getPagedCurrencies(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [CurrencySortInput!]
    $where: CurrencyFilterInput
  ) {
    pagedCurrencies(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id

          name
          code
          isBaseCurrency
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_CURRENCIES_QUERY = gql`
  query getCurrencies($where: CurrencyFilterInput) {
    currencies(where: $where) {
      id
      name
      code
      isBaseCurrency
    }
  }
`
