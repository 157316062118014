import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { Mode } from 'constants/Mode'
import {
  CreateAndUpdateTariffIconDtoInput,
  GetTariffIconQuery,
  useGetTariffIconQuery,
} from 'generated/graphql'

import TariffIconForm from './TariffIconForm'

interface IProps {
  id: number
  onTariffLoaded: (tariff: NonNullable<GetTariffIconQuery['tariffIcon']>) => void
  onFilesChanged: (files: File[]) => void
}

const UpdateTariffIcon = (props: IProps) => {
  const { id, onTariffLoaded, onFilesChanged } = props

  const { reset } = useFormContext<CreateAndUpdateTariffIconDtoInput>()

  const { data: tariffIconData, loading: tariffIconLoading } = useGetTariffIconQuery({
    variables: { id },
  })

  useEffect(() => {
    if (tariffIconData?.tariffIcon) {
      reset({ ...tariffIconData.tariffIcon })

      onTariffLoaded(tariffIconData.tariffIcon)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffIconData, reset])

  return (
    <>
      <TariffIconForm
        mode={Mode.Update}
        tariffIcon={tariffIconData?.tariffIcon}
        onFilesChanged={onFilesChanged}
      />
      <LoadingBackdrop loading={tariffIconLoading} />
    </>
  )
}

export default UpdateTariffIcon
