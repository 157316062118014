import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ControlledAutocomplete, IAutocompleteOptions } from 'components/common'
import { PARAM_NEW, Mode } from 'constants/index'
import { useGetCustomerContactsLazyQuery } from 'generated/graphql'
import { isNumber } from 'helpers'
import { TBookingForm } from 'types'

interface IProps {
  disabled: boolean
}

const ContactsAutocomplete = (props: IProps) => {
  const { disabled } = props

  const { id: bookingId } = useParams<{ id?: string }>()

  const {
    control,
    setValue,
    formState: { errors, isSubmitted },
    getValues,
  } = useFormContext<TBookingForm>()

  const customerIdValue = useWatch({ name: 'customerId' })

  let mode: number | undefined

  const [getCustomerContacts, { data: customerContactsData, loading: contactsLoading }] =
    useGetCustomerContactsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        customerId: Number(customerIdValue),
        where: { isActive: { eq: true } },
      },
    })

  if (bookingId === PARAM_NEW) {
    mode = Mode.Create
  } else if (bookingId && isNumber(bookingId)) {
    mode = Mode.Update
  }

  useEffect(() => {
    if (customerIdValue && customerIdValue !== '') {
      getCustomerContacts({
        variables: {
          customerId: Number(customerIdValue),
          where: { isActive: { eq: true } },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerIdValue])

  const customerContacts = customerContactsData?.customerContacts

  useEffect(() => {
    if (mode === Mode.Create) {
      const defaultContact = customerContacts?.find((contact) => contact.isDefault)

      if (defaultContact) {
        setValue('contactId', defaultContact.id, { shouldValidate: isSubmitted })
      } else {
        setValue('contactId', '', { shouldValidate: isSubmitted })
      }
    }
    if (mode === Mode.Update) {
      const currentContactId = getValues('contactId')
      const currentContact = customerContacts?.find((contact) => contact.id === currentContactId)

      if (!currentContact?.isActive) {
        setValue('contactId', '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerContacts, mode])

  const contactOptions: IAutocompleteOptions = useMemo(
    () =>
      customerContacts
        ?.filter((c) => c.isActive)
        .map((contact) => ({
          value: contact.id,
          label: contact.name,
        })) || [],
    [customerContacts],
  )

  return (
    <ControlledAutocomplete
      control={control}
      label='Contact'
      name='contactId'
      options={contactOptions}
      sortOptions
      defaultValue=''
      required
      disabled={disabled}
      loading={contactsLoading}
      disableClearable
      error={!!errors.contactId}
      helperText={errors.contactId?.message}
    />
  )
}

export default ContactsAutocomplete
