import { useMemo } from 'react'

import { AutocompleteRenderOptionState, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

const AutocompleteWrapper = ({ value, onChange, options, children, ...otherProps }) => {
  const selectedOption = useMemo(
    () => options.find((option) => String(option.value) === String(value)),
    [options, value],
  )

  return children({
    ...otherProps,
    onChange: (_, data) => onChange(data?.value),
    value: selectedOption || { value: '', label: '' },
  })
}

interface IAutocompleteOption {
  value: any
  label: string
  tag?: string
  disabled?: boolean
}

type IAutocompleteOptions = Array<IAutocompleteOption>

interface IProps {
  control: Control<any, any>
  label: string
  name: string
  options: IAutocompleteOptions
  sortOptions?: boolean
  defaultValue?: any
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  loading?: boolean
  disableClearable?: boolean
  placeholder?: string
  error?: any
  helperText?: any
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: IAutocompleteOption,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode
}

const ControlledAutocomplete = (props: IProps) => {
  const {
    control,
    label,
    name,
    options,
    sortOptions,
    defaultValue,
    required,
    disabled,
    readOnly,
    loading,
    disableClearable,
    placeholder,
    error,
    helperText,
    renderOption,
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...restField } }) => (
        <AutocompleteWrapper {...restField} options={options}>
          {(props) => (
            <Autocomplete
              {...props}
              options={
                sortOptions ? options.sort((a, b) => a.label.localeCompare(b.label)) : options
              }
              loading={loading}
              getOptionLabel={(option: any) => option?.label}
              getOptionDisabled={(option: any) => option?.disabled}
              isOptionEqualToValue={(option: any, optionValue: any) =>
                option?.value === optionValue?.value
              }
              disableClearable={disableClearable}
              disabled={disabled}
              readOnly={readOnly}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label={label}
                  required={required}
                  placeholder={placeholder}
                  error={error}
                  helperText={helperText}
                />
              )}
              renderOption={
                renderOption
                  ? renderOption
                  : (props, option: IAutocompleteOption) => {
                      return (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )
                    }
              }
            />
          )}
        </AutocompleteWrapper>
      )}
    />
  )
}

export { ControlledAutocomplete }
export type { IAutocompleteOption, IAutocompleteOptions }
