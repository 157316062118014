import React from 'react'

import { Badge, CircularProgress, IconButton } from '@mui/material'

interface NavIconButtonProps {
  loading?: boolean
  isOpen?: boolean
  badgeCount: number
  children?: any
  onClick?: () => void
  ariaLabel?: string
}

// eslint-disable-next-line react/display-name
export const NavIconButton = React.forwardRef<HTMLButtonElement, NavIconButtonProps>(
  (props, ref) => {
    const { isOpen, loading, onClick, badgeCount, children, ariaLabel } = props
    const iconBackColorOpen = 'grey.300'
    const iconBackColor = 'grey.100'

    return (
      <IconButton
        disableRipple
        color='secondary'
        disabled={loading}
        sx={{
          color: 'text.primary',
          bgcolor: isOpen ? iconBackColorOpen : iconBackColor,
        }}
        ref={ref}
        onClick={onClick}
        aria-label={ariaLabel}
        aria-haspopup='true'>
        <Badge badgeContent={badgeCount} color='primary'>
          {loading ? <CircularProgress color='secondary' size={25} /> : <>{children}</>}
        </Badge>
      </IconButton>
    )
  },
)
