import { Container, Typography } from '@mui/material'

const CookiePolicy = () => {
  return (
    <Container>
      <Typography variant='h6'>This policy will be written soon</Typography>
    </Container>
  )
}

export default CookiePolicy
