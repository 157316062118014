import { gql } from '@apollo/client'

export const CongestionZoneDetailsFragment = gql`
  fragment CongestionZoneDetails on CongestionZone {
    name
    isAppliedAutomatically
    isAttachedToAllTariffs
    customerCharge
    durationFrom
    durationTo
    durationFromTotalSeconds
    durationToTotalSeconds
    weekdaysCollection
    xeroServiceCode
    tariffs {
      id
      tariffId
      congestionZoneId
      tariff {
        id
        name
      }
    }
  }
`

export const CongestionZonePostcodeFragment = gql`
  fragment CongestionZonePostcodeFragment on CongestionZonePostcode {
    name
    congestionZoneId
  }
`
