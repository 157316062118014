import { ChangeEvent } from 'react'

import { Button, InputAdornment, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
  name: string
  defaultValue?: any
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  fullWidth?: boolean
  error?: boolean
  helperText?: any
  autoComplete?: string
  onFindClick?: (postcode: string) => void
  onAutoFillClick?: (postcode: string) => void
}

const ControlledPostCode = (props: IProps) => {
  const {
    control,
    name,
    defaultValue = '',
    disabled,
    readOnly,
    fullWidth = true,
    onFindClick,
    onAutoFillClick,
    ...otherProps
  } = props

  const forceOnChangeUppercase = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const el = event.target
    const start = el.selectionStart
    const end = el.selectionEnd
    el.value = el.value.toUpperCase()
    el.setSelectionRange(start, end)
  }

  const handleFindClick = (value: any) => {
    onFindClick && onFindClick(value)
  }

  const handleAutoFillClick = (value: any) => {
    onAutoFillClick && onAutoFillClick(value)
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, ...restField } }) => (
        <TextField
          {...restField}
          inputRef={ref}
          onChange={(event) => {
            forceOnChangeUppercase(event)

            onChange(event)
          }}
          label='Post Code'
          fullWidth={fullWidth}
          disabled={disabled}
          InputProps={{
            readOnly,
            endAdornment: (
              <InputAdornment position='end'>
                {onFindClick && (
                  <Button
                    color='grey'
                    disabled={disabled}
                    onClick={() => handleFindClick(restField.value)}>
                    Find
                  </Button>
                )}
                <Button
                  color='grey'
                  disabled={disabled}
                  onClick={() => handleAutoFillClick(restField.value)}>
                  AF
                </Button>
              </InputAdornment>
            ),
          }}
          {...otherProps}
        />
      )}
    />
  )
}

export default ControlledPostCode
