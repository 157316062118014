import { useReactiveVar } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Paths } from 'constants/Paths'
import { isBookingProcessingVar, isPageLoadingVar } from 'graphql/reactiveVariables'
import { Restricted } from 'providers'
import { TBookingForm } from 'types/form-types'

interface IProps {
  disabled?: boolean
}

const BookingSaveButtons = (props: IProps) => {
  const { disabled } = props

  const isPageLoading = useReactiveVar(isPageLoadingVar)
  const isBookingProcessing = useReactiveVar(isBookingProcessingVar)
  const history = useHistory()

  const {
    formState: { isDirty },
  } = useFormContext<TBookingForm>()

  const handleBackClick = () => {
    history.push(Paths.bookings.all)
  }

  return (
    <Grid container spacing={1.5} justifyContent='end' wrap='wrap-reverse'>
      <Grid item>
        <Button variant='contained' color='grey' onClick={handleBackClick}>
          Back
        </Button>
      </Grid>

      <Restricted to='UpdateBookingAfterQuoteIsBooked'>
        <Grid item>
          <LoadingButton
            variant='contained'
            type='submit'
            form='booking-details-form'
            loading={isBookingProcessing}
            disabled={!isDirty || disabled || isPageLoading}>
            Save
          </LoadingButton>
        </Grid>
      </Restricted>
    </Grid>
  )
}

export default BookingSaveButtons
