import { gql } from '@apollo/client'

const GET_INVOICE_TERMS_QUERIES = gql`
  query GetInvoiceTerms {
    invoiceTerms {
      id
      name
      days
    }
  }
`

export { GET_INVOICE_TERMS_QUERIES }
