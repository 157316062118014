enum AccountPayableStatusCategories {
  Overview = -2,
  All = -1,
  UnderReview = 1,
  Flagged = 2,
  ScheduledPayment = 3,
  OpenQueries = 4,
  PaymentSent = 5,
  PaymentFailed = 6,
  Archived = 7,
}

enum AccountPayableTabHash {
  Overview = 'accountsPayable-overview',
  All = 'accountsPayable-all',
  UnderReview = 'accountsPayable-under-review',
  Queries = 'accountsPayable-queries',
  Flagged = 'accountsPayable-flagged',
  ScheduledPayment = 'accountsPayable-scheduled-payment',
  PaymentSent = 'accountsPayable-payment-sent',
  PaymentFailed = 'accountsPayable-payment-failed',
  Archive = 'accountsPayable-archived',
}

export { AccountPayableStatusCategories, AccountPayableTabHash }
