import { useState, useCallback, useMemo } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { GET_POTENTIAL_BOOKINGS } from 'graphql/queries'

import PotentialBookingDialog from './PotentialBookingDialog'

const PotentialBookings = () => {
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  // Handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedFileId(Number(id))
    setOpenDialog(true)
  }

  const handleDeletePotentialBooking = useCallback(
    // eslint-disable-next-line
    (id: GridRowId) => () => {
      alert('To be implemented')
      // TODO: Write and call mutation
    },
    [],
  )

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      {
        field: 'potentialBookingFile',
        headerName: 'File Name',
        minWidth: 250,
        flex: 1,
        sortPath: ['potentialBookingFile', 'file', 'name'],
        valueGetter: ({ value }) => value?.file?.name,
      },
      {
        field: 'potentialBookingMapping',
        headerName: 'Mapping Name',
        minWidth: 200,
        sortPath: ['potentialBookingMapping', 'name'],
        valueGetter: ({ value }) => value?.name,
      },
      {
        field: 'bookingIdentifierKey',
        headerName: 'Booking Key',
        minWidth: 150,
        // flex: 1,
      },
      {
        field: 'bookingIdentifierValue',
        headerName: 'Booking Value',
        minWidth: 150,
        // flex: 1,
      },
      {
        field: 'customerIdentifierKey',
        headerName: 'Customer Key',
        minWidth: 130,
      },
      {
        field: 'customerIdentifierValue',
        headerName: 'Customer Value',
        minWidth: 130,
      },
      {
        field: 'isProcessed',
        headerName: 'Is processed?',
        width: 130,
        type: 'boolean',
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 150,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        minWidth: 150,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            // disabled={deletePotentialBookingFileProcessing}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeletePotentialBooking(id)}
          />,
        ],
      },
    ],
    [handleDeletePotentialBooking],
  )

  return (
    <>
      <FplDataGrid
        query={GET_POTENTIAL_BOOKINGS}
        entityName='potentialBookings'
        columns={columns}
        defaultOrder={{ field: 'createdAt', sort: 'desc' }}
        toolbar={{
          caption: 'Potential Bookings',
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <PotentialBookingDialog
          id={selectedFileId}
          openDialog={openDialog}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default PotentialBookings
