import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, Paths } from 'constants/index'
import { GET_PAGED_STANDARD_OPERATING_PROCEDURES } from 'graphql/queries'

interface CustomerSopProps {
  customerId: number
}

const columns: Array<IGridColumn> = [
  { field: 'id', headerName: 'ID', minWidth: 150, flex: 0.4 },
  { field: 'name', headerName: 'Name', minWidth: 230, flex: 1 },
  { field: 'isActive', headerName: 'Is Active', width: 100, type: 'boolean' },
  { field: 'createdBy', headerName: 'Created By', minWidth: 100, flex: 1 },
  {
    field: 'lastModifiedAt',
    headerName: 'Last Modified At',
    minWidth: 100,
    flex: 0.6,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_TIME_FORMAT),
  },
]

const CustomerSop = ({ customerId }: CustomerSopProps) => {
  const history = useHistory()

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    history.push(Paths.standardOperatingProcedures.updateWithId(id))
  }

  return (
    <FplDataGrid
      query={GET_PAGED_STANDARD_OPERATING_PROCEDURES}
      entityName='standardOperatingProcedures'
      columns={columns}
      queryVariables={{ customerId }}
      defaultOrder={{ field: 'id', sort: 'asc' }}
      onRowClick={handleRowClick}
      toolbar={{
        caption: 'Standard Operating Procedures',
      }}
    />
  )
}

export default CustomerSop
