import { Typography } from '@mui/material'

interface DetailsTitleProps {
  marginBottom?: number
  children?: any
  disabled?: boolean
}

const CardTitle = (props: DetailsTitleProps) => {
  const { marginBottom = 2, children, disabled } = props
  const color = !disabled ? 'primary' : 'textSecondary'

  return (
    <Typography marginBottom={marginBottom} component='h2' variant='h5' color={color}>
      {children}
    </Typography>
  )
}

export default CardTitle
