import { useState } from 'react'

import { Dialog, Typography, DialogContent } from '@mui/material'

import { FplDialogTitle } from '../Dialog'

interface IProps {
  openDialog: boolean
  onCloseDialog: () => void
  description: string | null
}

function SpecialTariffDialog(props: IProps) {
  const { openDialog, onCloseDialog, description } = props
  const [isFullScreen, setIsFullScreen] = useState(false)

  const handleFullScreenClick = () => setIsFullScreen(!isFullScreen)

  const handleCloseDialog = () => {
    onCloseDialog()
  }
  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='sm'
      fullScreen={isFullScreen}
      aria-labelledby='special-tariff-title'>
      <FplDialogTitle
        id='special-tariff-title'
        onClose={handleCloseDialog}
        onFullScreen={handleFullScreenClick}
        isFullScreen={isFullScreen}>
        <Typography variant='h4'>Special Tariff</Typography>
      </FplDialogTitle>

      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-line' }} variant='body1' mb={2}>
          {description}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default SpecialTariffDialog
