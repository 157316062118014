export enum ReportTypeButtonsId {
  ViewReport = 0,
  DownloadXlsx,
  DownloadPdf,
  DownloadHtml,
}

export const ReportTypeButtonValues = [
  { id: ReportTypeButtonsId.ViewReport, label: 'View Report' },
  { id: ReportTypeButtonsId.DownloadXlsx, label: 'Download XLSX' },
  { id: ReportTypeButtonsId.DownloadPdf, label: 'Download PDF' },
  { id: ReportTypeButtonsId.DownloadHtml, label: 'Download HTML' },
]

export enum ReportFileType {
  xlsx = 2,
  pdf,
  html,
}

export const ReportFileTypeValues = [
  {
    id: ReportFileType.xlsx,
    name: 'xlsx',
    description: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  { id: ReportFileType.pdf, name: 'pdf', description: 'application/pdf' },
  { id: ReportFileType.html, name: 'html', description: 'text/html' },
]

export enum ViewDownloadButtonsId {
  ViewReport = 0,
  DownloadReport,
}

export const ViewDownloadButtonValues = [
  { id: ViewDownloadButtonsId.ViewReport, label: 'View Report' },
  { id: ViewDownloadButtonsId.DownloadReport, label: 'Download Report' },
]
