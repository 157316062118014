import { Box, Grid, Paper, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox } from 'components/common'

const InvoiceDetails = () => {
  const { control } = useFormContext()

  return (
    <Paper variant='outlined'>
      <Box p={2}>
        <Typography paragraph variant='h5' color='primary'>
          Invoice Details
        </Typography>
        <Box p={1}>
          <Grid container spacing={3}>
            <Grid item>
              <ControlledCheckbox
                control={control}
                name='generateSeparateInvoicePerDocket'
                label='Generate Separate Invoice per Docket'
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  )
}

export default InvoiceDetails
