import React, { useMemo } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import LaunchIcon from '@mui/icons-material/Launch'
import { Checkbox, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import { format, fromUnixTime, isPast, isToday, isTomorrow, isYesterday } from 'date-fns'

import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/index'
import { BookingStatus, TaskStatus } from 'generated/graphql'

import DayChip from './DayChip'

interface IProps {
  task: any
  bookingId: string | null
  loggedUserName: string | undefined
  isAllowedToUpdateTask: boolean
  isAllowedToDeleteTask: boolean
  onTaskClick: (id, bookingId?: string | null) => void
  onCheckboxChange?: (value: boolean, task) => void
  onDeleteClick?: (id) => void
  onRedirectClick?: (bookingId, status: BookingStatus) => void
}

const TaskRow = (props: IProps) => {
  const {
    task,
    bookingId,
    loggedUserName,
    isAllowedToUpdateTask,
    isAllowedToDeleteTask,
    onTaskClick,
    onCheckboxChange,
    onDeleteClick,
    onRedirectClick,
  } = props

  const isCompleted = task.status === TaskStatus.Completed
  const taskEndDate = task.endDate
  const taskEndDateTime = task.timeTotalSeconds

  const isEndDateToday = useMemo(() => isToday(new Date(taskEndDate)), [taskEndDate])
  const isEndDateTomorrow = useMemo(() => isTomorrow(new Date(taskEndDate)), [taskEndDate])
  const isEndDateYesterday = useMemo(() => isYesterday(new Date(taskEndDate)), [taskEndDate])
  const isEndDatePast = useMemo(() => isPast(new Date(taskEndDate)), [taskEndDate])

  const endDate = useMemo(() => {
    const endTime = taskEndDateTime ? format(fromUnixTime(taskEndDateTime), TIME_FORMAT) : null

    if (isEndDateToday) {
      return endTime ? `Today, ${endTime}` : 'Today'
    } else if (isEndDateTomorrow) {
      return endTime ? `Tomorrow, ${endTime}` : 'Tomorrow'
    } else if (isEndDateYesterday) {
      return endTime ? `Yesterday, ${endTime}` : 'Yesterday'
    }

    const endTimeFormatted = format(new Date(taskEndDate), DATE_TIME_FORMAT)

    return endTime ? `${endTimeFormatted}, ${endTime}` : endTimeFormatted
  }, [isEndDateToday, isEndDateTomorrow, isEndDateYesterday, taskEndDate, taskEndDateTime])

  const taskUserName = task.assignedUserName
  const userName = useMemo(() => {
    if (taskUserName === loggedUserName) {
      return null
    } else {
      return task.assignedUser
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskUserName, loggedUserName])

  const handleTaskClick = () => {
    onTaskClick(task.id, task.bookingId)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheckboxChange) {
      onCheckboxChange(event.target.checked, task)
    }
  }

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick(task.id)
    }
  }

  const handleRedirectClick = () => {
    if (onRedirectClick) {
      onRedirectClick(task.bookingId, task.booking.status)
    }
  }

  return (
    <Grid
      container
      sx={{ padding: 1, ':hover': (theme) => ({ backgroundColor: theme.palette.action.hover }) }}>
      {onCheckboxChange && (
        <Grid item>
          <Checkbox
            sx={{ cursor: isAllowedToUpdateTask ? 'pointer' : 'default' }}
            checked={isCompleted}
            disabled={isCompleted}
            disableRipple={!isAllowedToUpdateTask}
            onChange={isAllowedToUpdateTask ? handleCheckboxChange : undefined}
          />
        </Grid>
      )}

      <Grid
        item
        xs
        paddingX={1}
        overflow='hidden'
        onClick={isAllowedToUpdateTask ? handleTaskClick : undefined}>
        <Stack spacing={0.25}>
          <Stack direction='row' alignItems='center' spacing={0.75}>
            <DayChip
              isToday={isEndDateToday}
              isTomorrow={isEndDateTomorrow}
              isPast={isEndDatePast}
            />

            {task.categoryId && (
              <Chip
                label={task.category?.name}
                size='small'
                sx={{ backgroundColor: task.category?.colour }}
              />
            )}

            <Typography
              variant='subtitle1'
              sx={{
                fontWeight: 'medium',
                color: (theme) => (isCompleted ? theme.palette.text.disabled : undefined),
                textDecoration: isCompleted ? 'line-through' : undefined,
              }}>
              {task.description}
            </Typography>

            {userName && (
              <Typography variant='subtitle1' color='text.secondary' fontWeight='regular'>
                ({userName})
              </Typography>
            )}
          </Stack>

          {task.additionalDetail && (
            <Typography variant='body1' color='text.secondary' noWrap>
              {task.additionalDetail}
            </Typography>
          )}

          <Stack direction='row'>
            <Typography variant='body2'>{endDate}</Typography>
            {!bookingId && task.bookingId && (
              <>
                <Typography variant='body2' color='text.secondary'>
                  &nbsp;for
                </Typography>
                <Typography variant='body2' fontWeight='regular'>
                  &nbsp; {task.booking?.ourReference}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Grid>

      <Grid item>
        {!bookingId && onRedirectClick && (
          <IconButton
            aria-label='redirect-task'
            disabled={!task.bookingId}
            onClick={handleRedirectClick}>
            <LaunchIcon />
          </IconButton>
        )}
        {isAllowedToDeleteTask && onDeleteClick && (
          <IconButton aria-label='delete-task' onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default TaskRow
