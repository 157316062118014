import { useEffect, useMemo } from 'react'

import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { useForm, useFormContext } from 'react-hook-form'

import { ControlledSelector, ControlledTextField, ISelectorOptions } from 'components/common'
import { FplDialogTitle } from 'components/common/Dialog'
import { useGetVatCategoriesQuery } from 'generated/graphql'
import { TBookingForm } from 'types/form-types'

interface IProps {
  selectedFieldIndex: number
  openChargeDescriptionDialog: boolean
  onCloseDialog: () => void
  onSaveClick: ({
    vatCategoryId,
    xeroServiceCode,
    description,
  }: {
    vatCategoryId: string
    xeroServiceCode: string
    description: string
  }) => void
}

const ChargeMoreInfoDialog = (props: IProps) => {
  const { selectedFieldIndex, openChargeDescriptionDialog, onCloseDialog, onSaveClick } = props

  const { getValues } = useFormContext<TBookingForm>()
  const selectedCharge = getValues().charges?.[selectedFieldIndex]

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      vatCategoryId: '',
      xeroServiceCode: '',
      description: '',
    },
  })

  // Queries
  const { data: vatCategoriesData } = useGetVatCategoriesQuery()

  useEffect(() => {
    reset({
      vatCategoryId: selectedCharge?.vatCategoryId || '',
      xeroServiceCode: selectedCharge?.xeroServiceCode || '',
      description: selectedCharge?.description || '',
    })
  }, [selectedCharge, reset])

  const vatCategoriesOptions: ISelectorOptions = useMemo(
    () => vatCategoriesData?.vatCategories?.map((vc) => ({ value: vc.id, label: vc.name })) || [],
    [vatCategoriesData],
  )

  const handleCloseClick = () => {
    onCloseDialog()
  }

  const handleSaveClick = ({
    vatCategoryId,
    xeroServiceCode,
    description,
  }: {
    vatCategoryId: string
    xeroServiceCode: string
    description: string
  }) => {
    onSaveClick({ vatCategoryId, xeroServiceCode, description })
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      scroll='body'
      open={openChargeDescriptionDialog}
      aria-labelledby='charge-description-dialog'>
      <FplDialogTitle id='charge-description-dialog' onClose={handleCloseClick}>
        <Typography paragraph variant='h4'>
          {selectedCharge?.name}
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <Box pt={1}>
          <Grid container columnSpacing={2} rowSpacing={1.5}>
            <Grid item xs={12} sm={6}>
              <ControlledSelector
                control={control}
                label='Vat Category'
                name='vatCategoryId'
                defaultValue=''
                options={vatCategoriesOptions}
                emptyValue={{ value: null, label: 'None' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Service Code'
                name='xeroServiceCode'
                defaultValue=''
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                control={control}
                label='Description'
                name='description'
                defaultValue=''
                multiline
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseClick}>
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={handleSubmit(handleSaveClick)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChargeMoreInfoDialog
