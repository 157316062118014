import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from '@mui/material'
import { DropzoneArea } from 'react-mui-dropzone'

import { CardTitle } from 'components/common'
import { FileConstants } from 'constants/index'
import { Exact, BookingAddress } from 'generated/graphql'
import { ShowDropzoneAlert } from 'helpers'

import AttachmentsList from './AttachmentsList'

interface IProps {
  data?: BookingAddress
  refetch: (
    variables?:
      | Partial<
          Exact<{
            id: any
          }>
        >
      | undefined,
  ) => Promise<any>
  uploadButtonProps: {
    onClick: () => void
    disabled?: boolean
    uploading?: boolean
  }
  dropzoneKey?: number
  loading?: boolean
  disabled?: boolean
  hoverText?: string
  onFilesChanged: (files: any[]) => void
}

const PoAttachments = (props: IProps) => {
  const { disabled, dropzoneKey, onFilesChanged, data, loading, refetch, uploadButtonProps } = props

  const handleFileRemoved = () => refetch()

  return (
    <Accordion defaultExpanded={disabled} sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id='attachments-header' disabled={disabled}>
        <Box sx={{ flexBasis: '33.33%' }}>
          <CardTitle marginBottom={0}>
            Attachments ({data ? data.attachments.length : 'Loading...'})
          </CardTitle>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ alignItems: 'center', flexDirection: 'column' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && data.attachments.length > 0 && (
              <AttachmentsList
                disabled={disabled}
                loading={loading}
                onFileRemoved={handleFileRemoved}
                attachments={data.attachments}
                addressType={data.type}
              />
            )}
          </Grid>

          {!disabled && (
            <Grid item xs={12}>
              <DropzoneArea
                key={dropzoneKey}
                filesLimit={10}
                showAlerts={false}
                dropzoneText='Please ensure files are uploaded before updating POB/POD.'
                onAlert={ShowDropzoneAlert}
                onChange={onFilesChanged}
                maxFileSize={FileConstants.maxFileSize}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <LoadingButton
              onClick={uploadButtonProps.onClick}
              disabled={uploadButtonProps.disabled}
              loading={uploadButtonProps.uploading}>
              Upload
            </LoadingButton>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default PoAttachments
