import { useMemo } from 'react'

import { Grid, Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledSelector, ControlledTextField } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { DriverTypeValues } from 'constants/index'
import { DriverType, XeroCode, useGetXeroCodesQuery } from 'generated/graphql'

const getXeroCodeValues = (xeroCodes: XeroCode[] = []) => {
  return xeroCodes.map((c) => ({ value: c.id, label: c.name }))
}

const SubDriverDetails = () => {
  const spacing = 3

  const {
    control,

    formState: { errors },
  } = useFormContext()

  const { data: xeroCodesData, loading: xeroCodeLoading } = useGetXeroCodesQuery()

  const xeroCodes = useMemo(
    () => getXeroCodeValues(xeroCodesData?.xeroCodes as XeroCode[]),
    [xeroCodesData],
  )

  return (
    <>
      <CardTitle>Sub-driver Details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Name'
            name='name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Call Sign'
            name='callSign'
            error={!!errors.callSign}
            helperText={errors.callSign?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='email'
            label='Email'
            type='email'
            required
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Telephone Number'
            name='telephoneNumber'
            required
            error={!!errors.telephoneNumber}
            helperText={errors.telephoneNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Purchase Code'
            name='xeroPurchaseCode'
            defaultValue=''
            error={!!errors.xeroPurchaseCode}
            helperText={errors.xeroPurchaseCode?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            label='Driver Type'
            name='driverType'
            options={DriverTypeValues}
            defaultValue={DriverType.Contract}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {xeroCodeLoading ? (
            <Skeleton animation='wave' />
          ) : (
            <ControlledSelector
              control={control}
              name='xeroCodeId'
              label='Service Code'
              options={xeroCodes}
              error={!!errors.xeroCodeId}
              helperText={errors.xeroCodeId?.message}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <ControlledCheckbox control={control} label='Active' name='isActive' />
        </Grid>
      </Grid>
    </>
  )
}

export default SubDriverDetails
