import { gql } from '@apollo/client'

import { TaskDetailsFragment } from 'graphql/fragments'

export const GET_TASKS_QUERY = gql`
  query getTasks(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [TaskSortInput!]
    $where: TaskFilterInput
  ) {
    tasks(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          bookingId
          booking {
            id
            ourReference
            status
          }
          description
          status
          endDate
          time
          timeTotalSeconds
          categoryId
          category {
            id
            name
            colour
          }
          assignedUser
          assignedUserName
          additionalDetail
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_TASK_QUERY = gql`
  query getTask($id: Long!) {
    task(id: $id) {
      ...TaskDetails
    }
    
    ${TaskDetailsFragment}
  }
`

export const GET_TASKS_COUNT_QUERY = gql`
  query getTasksCount($where: TaskFilterInput) {
    tasks(where: $where) {
      totalCount
    }
  }
`

export const GET_TASK_STATUSES_QUERY = gql`
  query getTaskStatuses {
    taskStatuses {
      id
      status
      description
    }
  }
`

export const GET_TASK_USERS_QUERY = gql`
  query getTaskUsers($where: TaskUserDtoFilterInput) {
    taskUsers(where: $where) {
      id
      userName
      lastName
      firstName
      isEnabled
      emailConfirmed
    }
  }
`

export const GET_TASK_CATEGORIES_QUERY = gql`
  query getTaskCategories {
    taskCategories {
      id
      name
      colour
    }
  }
`

export const GET_TASK_BOOKINGS_QUERY = gql`
  query getTaskBookings($where: BookingFilterInput) {
    taskBookings(where: $where) {
      id
      ourReference
    }
  }
`
