import { gql } from '@apollo/client'

import {
  BookingAddressesFragment,
  BookingDetailsFragment,
  BookingPackagesFragment,
  BookingTariffFragment,
  BookingAllocatedDriverFragment,
  BookingChargesFragment,
  BookingDriversFragment,
  ExchangeRateDetailsFragment,
  UserBookingDetailsFragment,
} from 'graphql/fragments'
import { FileDetailsFragment } from 'graphql/fragments'

export const GET_PAGED_BOOKINGS = gql`
  query getPagedBookings(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingSortInput!]
    $where: BookingFilterInput
  ) {
    bookings(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          ourReference
          status
          pagedStatus
          statusLabel
          courierServicesStatusLabel
          collectionEstimatedTimeOfArrival
          deliveryEstimatedTimeOfArrival
          isOnHold
          isArchived
          isCancelledOrQuoteLost
          isPending
          isQuickQuote
          takenOn
          docs
          isUsingInternalApp
          consignmentFee
          ourReference
          contactId
          courierExchangeLoadId
          contact {
            id
            name
          }
          customer {
            id
            name
          }
          charges {
            id
            rate
            quantity
          }
          customerReference
          customerId
          customer {
            id
            name
          }
          addresses {
            id
            type
            sequenceOrder
            at
            by
            address {
              id
              name
              city
              postcode
            }
          }
          tariffId
          tariff {
            id
            name
            tariffCategory {
              id
              name
            }
            xeroCode {
              id
              code
              name
              isInternational
            }
          }
          attachments {
            id
          }
          drivers {
            charge
            driver {
              id
              name
            }
          }
          revenue
          packages {
            packageReference
          }
          hasTasks
          hasNotes
          takenBy
          salesInvoiceNumber
          invoices {
            id
            invoiceType
            invoiceNumber
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_USER_BOOKINGS = gql`
  query GerUserBookings(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingSortInput!]
    $where: BookingFilterInput
  ) {
    userBookings(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          ourReference
          status
          pagedStatus
          statusLabel
          collectionEstimatedTimeOfArrival
          deliveryEstimatedTimeOfArrival
          isOnHold
          isArchived
          isCancelledOrQuoteLost
          isPending
          isQuickQuote
          takenOn
          docs
          isUsingInternalApp
          consignmentFee
          ourReference
          courierExchangeLoadId
          contactId
          contact {
            id
            name
          }
          customer {
            id
            name
            isReferenceRequired
          }
          charges {
            id
            rate
            quantity
          }
          customerReference
          customerId
          customer {
            id
            name
          }
          addresses {
            id
            type
            sequenceOrder
            at
            by
            address {
              id
              name
              city
              postcode
            }
          }
          tariffId
          tariff {
            id
            name
          }
          attachments {
            id
          }
          distance
          packages {
            packageReference
          }
          hasNotes
          takenBy
          salesInvoiceNumber
          invoices {
            id
            invoiceType
            invoiceNumber
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_BOOKING_BY_TRACKING_NUMBER = gql`
  query GetBookingByTrackingNumber($trackingGuid: UUID!) {
    bookingByTrackingNumber(trackingGuid: $trackingGuid) {
      id
      distance
      consignmentFee
      trackingGuid
      trackingExpiredDate
      tariff {
        id
        rate
        minMiles
        minCharge
      }
      addresses {
        id
        sequenceOrder
        type
        address {
          id
          addressLine1
          addressLine2
          postcode
          city
          countryId
          isVerified
          name
          googleMapsPlaceId
        }
      }
    }
  }
`

export const GET_BOOKING = gql`
  query GetBooking($bookingId: Long!) {
    booking(bookingId: $bookingId) {
      id
      ...BookingDetails
      ...BookingAddresses
      ...BookingDrivers
      ...BookingCharges
      ...BookingPackages
      ...BookingTariff
    }

    ${BookingDetailsFragment}
    ${BookingAddressesFragment}
    ${BookingDriversFragment}
    ${BookingChargesFragment}
    ${BookingPackagesFragment}
    ${BookingTariffFragment}
  }
`

export const GET_USER_BOOKING = gql`
    query GetUserBooking($bookingId: Long!) {
    userBooking(bookingId: $bookingId) {
      id
      ...UserBookingDetails
      ...BookingAddresses
      ...BookingCharges
      ...BookingPackages
      ...BookingTariff
    }

    ${UserBookingDetailsFragment}
    ${BookingAddressesFragment}
    ${BookingChargesFragment}
    ${BookingPackagesFragment}
    ${BookingTariffFragment}
  }
`

export const GET_BOOKING_ADDRESSES = gql`
    query GetBookingAddresses($bookingId: Long!){
        booking(bookingId: $bookingId) {
            id
            ...BookingAddresses
        }
        ${BookingAddressesFragment}
    }
`

export const GET_BOOKING_ADDRESSES_DATA = gql`
  query GetBookingAddressesData($bookingId: Long!, $where: BookingAddressFilterInput) {
    bookingAddresses(bookingId: $bookingId, where: $where) {
      id
      address {
        id
        name
        postcode
      }
    }
  }
`

export const GET_USER_BOOKING_ADDRESSES = gql`
  query GetUserBookingAddresses($bookingId: Long!) {
    userBooking(bookingId: $bookingId){
      id
            ...BookingAddresses
    }
    ${BookingAddressesFragment}
  }
`

export const GET_BOOKING_ADDRESS_SIGNATURE = gql`
    query GetBookingAddressSignature($bookingAddressId: Long!){
      bookingAddressSignature(id: $bookingAddressId) {
        id
        ...FileDetails
      }
      ${FileDetailsFragment}
    }
`

export const GET_BOOKING_ADDRESS_ATTACHMENTS = gql`
  query GetBookingAddressAttachments($id: Long!){
    bookingAddress(id: $id) {
      id
      type
      bookingId
      attachments {
        id
        ...FileDetails
      }
      ${FileDetailsFragment}
    }
  }
`

export const GET_BOOKING_ALLOCATED_DRIVERS = gql`
  query GetBookingAllocatedDrivers(
    $bookingId: Long!
    $order: [BookingDriverSortInput!]
    $where: BookingDriverFilterInput
  ) {
    bookingAllocatedDrivers(bookingId: $bookingId, order: $order, where: $where) {
      id
      charge
      extraPayment
      driver {
        id
        name
      }
      vehicle {
        id
        vehicleType {
          id
          name
        }
      }
    }
  }
`
export const GET_BOOKING_ALLOCATED_DRIVERS_RATINGS = gql`
  query GetBookingAllocatedDriversRatings(
    $bookingId: Long!
    $order: [BookingDriverSortInput!]
    $where: BookingDriverFilterInput
  ) {
    bookingAllocatedDrivers(bookingId: $bookingId, order: $order, where: $where) {
      id
      rating
    }
  }
`

export const GET_PAGED_BOOKING_ALLOCATED_DRIVERS = gql`
  query GetPagedBookingAllocatedDrivers(
    $bookingId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingDriverSortInput!]
    $where: BookingDriverFilterInput
  ) {
    pagedBookingAllocatedDrivers(
      bookingId: $bookingId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          charge
          extraPayment
          createdAt
          xeroPurchaseCode
          allocationStatus
          createdAt
          totalCharge
          driverNotes
          reference
          rating
          bookingExchangeRates {
            id
            stage
            exchangeRate {
              id
              rate

              sourceCurrency {
                id
                code
              }

              targetCurrency {
                id
                code
              }
            }
          }
          driver {
            id
            name
            callSign
            xeroCode {
              id
              name
            }
            identityUserName
            isUsingInternalApp
          }
          vehicle {
            id
            vehicleType {
              id
              name
            }
          }
          currency {
            id
            code
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_BOOKING_ALLOCATED_DRIVER = gql`
  query GetBookingAllocatedDriver($bookingDriverId: Long!) {
    bookingAllocatedDriver(bookingDriverId: $bookingDriverId) {
      id
      ...BookingAllocatedDriver
    }
    ${BookingAllocatedDriverFragment}
  }
`

export const GET_INVOICE_BOOKINGS = gql`
  query getInvoiceBookings(
    $invoiceNumber: Long!
    $invoiceType: InvoiceType!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingSortInput!]
    $where: BookingFilterInput
  ) {
    invoiceBookings(
      invoiceNumber: $invoiceNumber
      invoiceType: $invoiceType
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          ourReference
          customerReference
          status
          createdAt
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_PAGED_BOOKING_INVOICES_BY_CUSTOMER = gql`
  query GetBookingInvoice(
    $customerId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingInvoiceSortInput!]
    $where: BookingInvoiceFilterInput
  ) {
    bookingInvoice(
      customerId: $customerId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          invoiceNumber
          invoiceDate
          reference
          bookingId
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_BOOKING_LOAD_ID = gql`
  query GetBookingLoadId($bookingId: Long!) {
    booking(bookingId: $bookingId) {
      id
      courierExchangeLoadId
    }
  }
`

export const GET_BOOKING_LOGS = gql`
  query GetBookingLogs(
    $bookingId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingLogSortInput!]
  ) {
    bookingLogs(
      bookingId: $bookingId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
    ) {
      edges {
        node {
          id
          createdAt
          createdBy
          type
          description
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_LOG_RECORD = gql`
  query GetBookingLog($bookingLogId: Long!) {
    bookingLog(bookingLogId: $bookingLogId) {
      id
      type
      description
      details
    }
  }
`

export const GET_BOOKING_AUDIT = gql`
  query GetBookingAudit(
    $bookingId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [AuditRecordSortInput!]
  ) {
    bookingAudit(
      bookingId: $bookingId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
    ) {
      edges {
        node {
          id
          createdAt
          createdBy
          name
          primaryKey
          action
          changes
          columnValues
          transactionId
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_AUDIT_RECORD = gql`
  query GetAuditRecord($auditRecordId: Long!) {
    auditRecord(auditRecordId: $auditRecordId) {
      id
      name
      action
      columnValues
      success
      errorMessage
    }
  }
`

export const GET_QUOTE = gql`
  query GetQuote($quoteId: Long!) {
    booking(bookingId: $quoteId) {
      id
      docketReference
      takenBy
      takenOn
      customer {
        id
        name
      }
      drivers {
        id
      }

      ...BookingDetails
      ...BookingAddresses
      ...BookingCharges
      ...BookingTariff
    }
    ${BookingDetailsFragment}
    ${BookingAddressesFragment}
    ${BookingChargesFragment}
    ${BookingTariffFragment}
  }
`

export const GET_USER_QUOTE = gql`
  query GetUserQuote($quoteId: Long!) {
    userBooking(bookingId: $quoteId) {
      id
      docketReference
      takenBy
      takenOn
      customer {
        id
        name
      }
      drivers {
        id
      }

      ...UserBookingDetails
      ...BookingAddresses
      ...BookingCharges
      ...BookingTariff
    }
    ${UserBookingDetailsFragment}
    ${BookingAddressesFragment}
    ${BookingChargesFragment}
    ${BookingTariffFragment}
  }
`

export const GET_PAGED_CUSTOMER_INVOICE = gql`
  query getPagedCustomerInvoice(
    $customerId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [CustomerInvoiceDtoSortInput!]
    $where: CustomerInvoiceDtoFilterInput
  ) {
    pagedCustomerInvoices(
      customerId: $customerId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          bookings {
            id
            status
            customerReference
            createdAt
            collectionEstimatedTimeOfArrival
          }

          bookingStatuses
          ourReferences
          customerReferences
          bookingDate
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_PAGED_INVOICE_LINES = gql`
  query GetPagedInvoiceLines(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingInvoiceContentSortInput!]
    $where: BookingInvoiceContentFilterInput
  ) {
    pagedInvoiceLines(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          unitAmount
          accountCode
          description
          bookingInvoice {
            invoiceNumber
            reference
            invoiceDate
            dueDate
            bookingId
            booking {
              id
              ourReference
            }
            customer {
              name
              postcode
              customerReferenceLabel
            }
            driverInvoiceNumber
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_BOOKING_EXCHANGE_RATES = gql`
  query getBookingExchangeRates($bookingId: Long!) {
    bookingExchangeRates(bookingId: $bookingId) {
      id
      stage
      bookingId
      exchangeRateId
      exchangeRate {
        ...ExchangeRateDetails
      }
      createdAt
    }
  }
  ${ExchangeRateDetailsFragment}
`

export const GET_BOOKING_TOTAL_DRIVER_CHARGE = gql`
  query getBookingDriverTotalCharge($bookingId: Long!) {
    booking(bookingId: $bookingId) {
      id
      totalDriverCharge
    }
  }
`

export const GET_PAGED_QUOTE_LOST_REASONS = gql`
  query GetPagedQuoteLostReasons(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [QuoteLostReasonSortInput!]
    $where: QuoteLostReasonFilterInput
  ) {
    pagedQuoteLostReasons(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          code
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_QUOTE_LOST_REASONS = gql`
  query GetQuoteLostReasons {
    quoteLostReasons {
      id
      name
      code
    }
  }
`

export const GET_QUOTE_LOST_REASON = gql`
  query GetQuoteLostReason($id: Long!) {
    quoteLostReason(id: $id) {
      id
      name
      code
    }
  }
`

export const GET_BOOKING_USERS_QUERY = gql`
  query getBookingUsers($where: BookingUserDtoFilterInput) {
    bookingUsers(where: $where) {
      id
      email
      userName
      lastName
      firstName
    }
  }
`

export const GET_POTENTIAL_BOOKING_FILES = gql`
  query GetPotentialBookingFiles(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [PotentialBookingFileSortInput!]
    $where: PotentialBookingFileFilterInput
  ) {
    potentialBookingFiles(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          file {
            id
            originalFileName
          }
          uploadedByCustomer {
            id
            name
          }
          isProcessed
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_POTENTIAL_BOOKING_FILE = gql`
  query GetPotentialBookingFile($id: Long!) {
    potentialBookingFile(id: $id) {
      id
      file {
        id
        originalFileName
      }
      uploadedByCustomer {
        id
        name
      }
      isProcessed
      createdAt
      createdBy
      extractedData
    }
  }
`

export const GET_POTENTIAL_BOOKING_MAPPINGS = gql`
  query GetPotentialBookingMappings {
    potentialBookingMappings {
      id
      name
    }
  }
`

export const GET_POTENTIAL_BOOKINGS = gql`
  query GetPotentialBookings(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [PotentialBookingSortInput!]
    $where: PotentialBookingFilterInput
  ) {
    potentialBookings(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          potentialBookingFile {
            id
            file {
              id
              name
            }
          }
          potentialBookingMapping {
            id
            name
          }
          bookingIdentifierKey
          bookingIdentifierValue
          customerIdentifierKey
          customerIdentifierValue
          isProcessed
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_POTENTIAL_BOOKING = gql`
  query GetPotentialBooking($id: Long!) {
    potentialBooking(id: $id) {
      id
      bookingIdentifierKey
      bookingIdentifierValue
      customerIdentifierKey
      customerIdentifierValue
      potentialBookingFile {
        id
        extractedData
      }
      potentialBookingMapping {
        id
        type
      }
    }
  }
`

export const GET_BOOKINGS_TRACKING_DASHBOARD_DATA = gql`
  query bookingsTrackingDashboard(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingTrackingDashboardDtoSortInput!]
    $where: BookingTrackingDashboardDtoFilterInput
  ) {
    bookingTrackingDashboardData(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          ourReference
          status
          statusLabel
          customerName
          customerReference
          driverStatus {
            id
            status
            statusLabel
            date
            postcode
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
