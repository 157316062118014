import { gql } from '@apollo/client'

import { EmailTemplateDetailsFragment } from '../fragments'

export const UPDATE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation UpdateEmailTemplate($input: UpdateEmailTemplateInput!) {
    updateEmailTemplate(input: $input) {
      id
      ...EmailTemplateDetails
   }
   ${EmailTemplateDetailsFragment}
 }
`

export const TEST_SENDING_EMAIL_TO_CUSTOMER = gql`
  mutation TestSendingEmailToCustomer(
    $type: EmailTemplateType
    $bookingId: Long
    $bookingAddressId: Long
  ) {
    testSendingEmailToCustomer(
      type: $type
      bookingId: $bookingId
      bookingAddressId: $bookingAddressId
    )
  }
`

export const TEST_SENDING_EMAIL_TO_DRIVER = gql`
  mutation TestSendingEmailToDriver($type: EmailTemplateType, $bookingId: Long, $driverId: Long) {
    testSendingEmailToDriver(type: $type, bookingId: $bookingId, driverId: $driverId)
  }
`
