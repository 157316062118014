import { useState, useMemo, useEffect, useCallback } from 'react'

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import { Box, Button, Grid, Skeleton, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FplDataGrid, IGridColumn, TextInput } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { Paths } from 'constants/index'
import {
  BookingDriver,
  BookingExchangeRate,
  BookingExchangeRateStage,
  BookingStatus,
  CalculatedStatus,
  GetBookingQuery,
  SortEnumType,
  useGetBookingAllocatedDriversRatingsLazyQuery,
  useGetBookingDriverTotalChargeLazyQuery,
  useGetCurrenciesQuery,
} from 'generated/graphql'
import { GET_PAGED_BOOKING_ALLOCATED_DRIVERS } from 'graphql/queries'
import { decimalFormatter } from 'helpers'
import { usePermission } from 'providers'

import { AllocateDrivers } from './AllocateDrivers'

interface IProps {
  bookingId?: string
  booking: GetBookingQuery['booking']
  disabled?: boolean
  hoverText: string
}

const DriverDetails = (props: IProps) => {
  const history = useHistory()
  const [isAllowedToUpdateDriverProfile] = usePermission('UpdateDriver')
  const { bookingId, booking, disabled, hoverText } = props
  const [openAllocateDriverDialog, setOpenAllocateDriverDialog] = useState<boolean>(false)

  const [getTotalCharge, { data: totalChargeData, loading: loadingCharges }] =
    useGetBookingDriverTotalChargeLazyQuery()

  const { data: currencyData } = useGetCurrenciesQuery({
    variables: {
      where: {
        isBaseCurrency: { eq: true },
        isEnabled: { eq: true },
      },
    },
  })

  const [getDriversRatings, { data: driversRatings }] =
    useGetBookingAllocatedDriversRatingsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        bookingId: Number(bookingId),
        order: { createdAt: SortEnumType.Desc },
      },
    })

  useEffect(() => {
    if (bookingId) {
      getTotalCharge({
        fetchPolicy: 'network-only',
        variables: {
          bookingId: Number(bookingId),
        },
      }),
        getDriversRatings()
    }
  }, [bookingId, getTotalCharge, getDriversRatings, booking?.drivers?.length])

  const driverNeedsRating = driversRatings?.bookingAllocatedDrivers.find((d) => d.rating === null)

  const bookingStatusForRating =
    booking?.status &&
    [BookingStatus.Invoice, BookingStatus.Complete, BookingStatus.ProofOfDelivery].includes(
      booking?.status,
    ) &&
    booking.calculatedStatus !== CalculatedStatus.IsArchived

  const showRatingMessage =
    booking?.drivers &&
    booking?.drivers?.length > 0 &&
    !!driverNeedsRating &&
    bookingStatusForRating

  useEffect(() => {
    showRatingMessage && toast.info('Please give ratings to Suppliers allocated to this Booking')
  }, [showRatingMessage])

  const baseCurrencyCode = useMemo(() => {
    return currencyData?.currencies && currencyData.currencies.length > 0
      ? currencyData.currencies[0].code
      : ''
  }, [currencyData?.currencies])

  const totalDriverCharge = useMemo(() => {
    if (bookingId && totalChargeData?.booking?.totalDriverCharge) {
      return decimalFormatter.format(Number(totalChargeData?.booking?.totalDriverCharge))
    }

    return 0
  }, [bookingId, totalChargeData?.booking?.totalDriverCharge])

  const handleExchangeClick = useCallback(
    (row: BookingDriver) => () => {
      const baseCharge = decimalFormatter.format(row.charge + row.extraPayment)

      const exchangeRate = row.bookingExchangeRates?.filter(
        (r) =>
          r.stage === BookingExchangeRateStage.Allocation &&
          r.exchangeRate.sourceCurrency.id === row.currency?.id,
      )[0]

      const message = `${baseCharge} ${
        exchangeRate?.exchangeRate.sourceCurrency.code
      } * ${decimalFormatter.format(
        Number(exchangeRate?.exchangeRate.rate),
      )} => ${decimalFormatter.format(Number(row.totalCharge))} ${
        exchangeRate?.exchangeRate.targetCurrency.code
      }`

      toast.info(message)
    },
    [],
  )

  const handleDriverProfileClick = useCallback(
    (row: BookingDriver) => () => {
      history.push(Paths.drivers.updateWithId(row.driver.id))
    },
    [history],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      {
        field: 'driver',
        headerName: 'Driver',
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ value }) => value?.name,
      },
      {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        valueGetter: ({ value }) => value?.vehicleType.name || 'N/A',
      },
      {
        field: 'totalCharge',
        headerName: 'Job cost',
        width: 120,
        type: 'text',
        sortable: false,
        valueGetter: ({ row }) => {
          let charge = decimalFormatter.format(Number(row.totalCharge))

          if (row.bookingExchangeRates) {
            const currencyCode = (row.bookingExchangeRates as Array<BookingExchangeRate>).filter(
              (e) => e.stage === BookingExchangeRateStage.Allocation,
            )[0]?.exchangeRate.targetCurrency.code

            charge = `${charge} ${currencyCode}`
          }

          return charge
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 100,
        type: 'actions',
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label='Exchange'
            size='large'
            icon={
              <Tooltip title={'Exchange'} arrow>
                <CurrencyExchangeOutlinedIcon />
              </Tooltip>
            }
            onClick={handleExchangeClick(row)}
          />,
          <GridActionsCellItem
            key={id}
            label='Driver profile'
            size='large'
            disabled={!isAllowedToUpdateDriverProfile}
            icon={
              <Tooltip title={'Driver profile'} arrow>
                <AccountBoxOutlinedIcon />
              </Tooltip>
            }
            onClick={handleDriverProfileClick(row)}
          />,
        ],
      },
      { field: 'createdAt', headerName: 'Created At', hide: true },
    ],
    [handleExchangeClick, isAllowedToUpdateDriverProfile, handleDriverProfileClick],
  )

  const queryVariables = useMemo(() => ({ bookingId: Number(bookingId) }), [bookingId])

  const handleAllocate = () => setOpenAllocateDriverDialog(true)

  const handleCloseAllocateDriversDialog = () => {
    setOpenAllocateDriverDialog(false)
  }

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <CardTitle disabled={disabled}>Driver/Vehicle Details</CardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FplDataGrid
              query={GET_PAGED_BOOKING_ALLOCATED_DRIVERS}
              queryVariables={queryVariables}
              skipQuery={bookingId === undefined}
              entityName='pagedBookingAllocatedDrivers'
              columns={columns}
              defaultOrder={{ field: 'createdAt', sort: 'desc' }}
              pageOptions={{ pageSize: 100, rowsPerPage: [100] }}
              hideFooter
              hideToolbar
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {loadingCharges ? (
              <Skeleton height={55} />
            ) : (
              <TextInput
                label='Total Driver Charge'
                variant='outlined'
                value={totalDriverCharge}
                disabled={disabled}
                readOnly
                endAdornment={baseCurrencyCode}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='flex-end'>
              <Button onClick={handleAllocate} variant='contained' color='grey' disabled={disabled}>
                Allocate
              </Button>
            </Box>
          </Grid>
        </Grid>

        {openAllocateDriverDialog && (
          <AllocateDrivers
            bookingId={bookingId}
            openDialog={openAllocateDriverDialog}
            onCloseDialog={handleCloseAllocateDriversDialog}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default DriverDetails
