import { gql } from '@apollo/client'

export const BookingDetailsFragment = gql`
  fragment BookingDetails on Booking {
    customerReference
    customerId
    contactId
    customer {
      id
      name
      isReferenceRequired
      isReasonCodeOptionEnabled
      isPackageDetailsOptional
    }
    consignmentFee
    isEmailNotification
    courierExchangeLoadId
    isArchived
    isCancelledOrQuoteLost
    isOnHold
    noteOrSpecialInstruction
    alternativeOurReference
    ourReference
    overrideContactEmail
    overrideContactTelephoneNumber
    status
    statusLabel
    calculatedStatus
    distance
    isCalculateDistanceAutomatically
    isWaitAndReturn
    tariffId
    courierExchangeLastManualUpdateAt
    isImported
    isQuoteOriginally
    isQuickQuote
    isCreatedByCustomer
    validUntil
    marginPercent
    docketReference
    revenue
    cost
    takenBy
    takenOn
    createdBy
    createdAt
    isTransitIgnored
    potentialBookingId
    isBiddingEnabled
  }
`

export const BookingAddressesFragment = gql`
  fragment BookingAddresses on Booking {
    addresses {
      id
      bookingId
      type
      at
      by
      sequenceOrder
      note
      noteFromDriver
      waitingTime
      waitingTimeCharge
      arrivedAt
      signedAt
      signedBy
      signedByFirstName
      signedByLastName
      driverTimeFrom
      driverTimeTo
      courierServiceNumberOfItems
      courierServiceWeight
      courierServiceWeightUnit
      eTA
      reasonCodeId
      addressPackages {
        id
        packageId
        quantity
        package {
          id
          name
          quantity
        }
      }
      allocations {
        id
        packageId
        quantity
        package {
          id
          name
          quantity
        }
      }
      address {
        id
        name
        addressLine1
        addressLine2
        postcode
        city
        countryId
        contact
        email
        telephoneNumber
        isVerified
        googleMapsPlaceId
      }
    }
  }
`

export const BookingDriversFragment = gql`
  fragment BookingDrivers on Booking {
    drivers {
      id
      charge
      extraPayment
      driverId
      driver {
        id
        name
        callSign
        xeroCode {
          id
          name
        }
      }
      vehicleId
      vehicle {
        id
        vehicleTypeId
        vehicleType {
          id
          name
        }
      }
    }
  }
`

export const BookingChargesFragment = gql`
  fragment BookingCharges on Booking {
    charges {
      id
      bookingId
      name
      type
      description
      quantity
      rate
      xeroServiceCode
      vatCategoryId
    }
  }
`

export const BookingPackagesFragment = gql`
  fragment BookingPackages on Booking {
    packages {
      id
      name
      quantity
      type
      height
      width
      length
      weight
      packageReference
      collectionId
      deliveryId
      collection {
        sequenceOrder # Needed for collectionSequenceOrder
      }
      collectionSequenceOrder
      delivery {
        sequenceOrder # Needed for deliverySequenceOrder
      }
      deliverySequenceOrder
    }
  }
`

export const BookingTariffFragment = gql`
  fragment BookingTariff on Booking {
    tariff {
      id
      name
      tariffCategory {
        id
        name
        courierExchangeVehicleSize
      }
      xeroCode {
        id
        serviceType
      }
      type
      minCharge
      minMiles
      rate
      baseUnit
      isWaitAndReturnEnabled
      iconUrl
      isExternal
      capacity
    }
  }
`

export const BookingAllocatedDriverFragment = gql`
  fragment BookingAllocatedDriver on BookingDriver {
    bookingId
    driverId
    xeroPurchaseCode
    charge
    extraPayment
    isShownInstruction
    instruction
    currencyId
    driver {
      id
      name
      callSign
      xeroCode {
        id
        name
      }
    }
    vehicleId
    vehicle {
      id
      vehicleTypeId
      vehicleType {
        id
        name
      }
    }
    driverContact {
      name
    }
  }
`
export const UserBookingDetailsFragment = gql`
  fragment UserBookingDetails on Booking {
    customerReference
    customerId
    contactId
    customer {
      id
      name
      isReasonCodeOptionEnabled
      isPackageDetailsOptional
      isReferenceRequired
    }
    consignmentFee
    isEmailNotification
    courierExchangeLoadId
    isArchived
    isCancelledOrQuoteLost
    isOnHold
    noteOrSpecialInstruction
    alternativeOurReference
    ourReference
    overrideContactEmail
    overrideContactTelephoneNumber
    status
    statusLabel
    calculatedStatus
    distance
    isCalculateDistanceAutomatically
    isWaitAndReturn
    tariffId
    courierExchangeLastManualUpdateAt
    isImported
    isQuoteOriginally
    isQuickQuote
    isCreatedByCustomer
    isTransitIgnored
    validUntil
    docketReference
    takenBy
    takenOn
    createdBy
    createdAt
    potentialBookingId
    isBiddingEnabled
  }
`
