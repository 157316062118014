import { useEffect, useMemo } from 'react'

import { Grid } from '@mui/material'
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import { ControlledSelector, ControlledTextField, ISelectorOptions } from 'components/common'
import { useGetCongestionZoneLazyQuery, useGetPagedCongestionZonesQuery } from 'generated/graphql'

import { TChargeToolbarForm } from './chargeToolbar.types'

interface IProps {
  control: Control<TChargeToolbarForm, any>
  watch: UseFormWatch<TChargeToolbarForm>
  setValue: UseFormSetValue<TChargeToolbarForm>
  errors: FieldErrors<TChargeToolbarForm>
  disabled?: boolean
}

const SpecialZoneChargeOptions = (props: IProps) => {
  const { control, watch, setValue, errors, disabled } = props

  const selectedSpecialZoneId = watch('specialZoneId')

  const { data: pagedCongestionZonesData } = useGetPagedCongestionZonesQuery()

  const [getCongestionZone] = useGetCongestionZoneLazyQuery()

  const congestionZoneOptions: ISelectorOptions = useMemo(
    () =>
      pagedCongestionZonesData?.pagedCongestionZones?.edges?.map((congestionZone) => ({
        value: congestionZone.node.id,
        label: congestionZone.node.name,
      })) || [],
    [pagedCongestionZonesData],
  )

  useEffect(() => {
    if (selectedSpecialZoneId) {
      getCongestionZone({ variables: { id: Number(selectedSpecialZoneId) } }).then((response) => {
        const congestionZoneData = response.data?.congestionZone

        if (congestionZoneData) {
          setValue('name', `Special Zone: ${congestionZoneData.name}`)
          setValue('rate', congestionZoneData?.customerCharge)
          congestionZoneData.xeroServiceCode &&
            setValue('xeroServiceCode', congestionZoneData.xeroServiceCode)
        }
      })
    }
  }, [selectedSpecialZoneId, getCongestionZone, setValue])

  return (
    <>
      <Grid item xs={8} sm>
        <ControlledSelector
          control={control}
          label='Special Zone'
          name='specialZoneId'
          options={congestionZoneOptions}
          required
          disabled={disabled}
          error={!!errors.specialZoneId}
          helperText={errors.specialZoneId?.message}
        />
      </Grid>

      <Grid item xs={4} sm>
        <ControlledTextField
          control={control}
          name='rate'
          label='Rate'
          defaultValue=''
          startAdornment='£'
          readOnly
          disabled={disabled}
        />
      </Grid>
    </>
  )
}

export default SpecialZoneChargeOptions
