import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import { IconButton, InputAdornment, TextField } from "@mui/material"
import { Controller } from "react-hook-form"

import { ControlledTextFieldProps } from "./ControlledTextField"

export const ControlledTelephoneNumber = (props: ControlledTextFieldProps) => {
  const {
    control,
    name,
    label,
    defaultValue = '',
    disabled,
    readOnly,
    fullWidth = true,
    min,
    startAdornment,
    variant,
    ...otherProps
  } = props

  const handleOnCallClick = (telephoneNumber: string) => window.open(`tel:${telephoneNumber}`)

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...restField } }) => (
        <TextField
          {...restField}
          inputRef={ref}
          label={label}
          variant={variant}
          inputProps={{ min }}
          fullWidth={fullWidth}
          disabled={disabled}
          InputProps={{
            readOnly,
            startAdornment: startAdornment && (
              <InputAdornment position='start'>{startAdornment}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='call' onClick={() => handleOnCallClick(restField.value)}>
                  <PhoneOutlinedIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          {...otherProps}
        />
      )}
    />
  )
}
