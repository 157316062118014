import { useState, useMemo, useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import {
  GridActionsCellItem,
  GridEventListener,
  GridRowHeightParams,
  GridRowId,
} from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { AddressFilterInput, useDeleteAddressMutation } from 'generated/graphql'
import { GET_PAGED_CUSTOMER_ADDRESSES } from 'graphql/queries'

import { CreateUpdateAddress } from './createUpdateAddress'

interface IProps {
  customerId: number | null
}

const AddressList = (props: IProps) => {
  const { customerId } = props
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null)
  const [openContactDialog, setOpenContactDialog] = useState(false)

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  // mutations
  const [deleteAddress, { loading: deletingAddress }] = useDeleteAddressMutation()

  // handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedAddressId(Number(id))
    setOpenContactDialog(true)
  }

  const handleCreateAddress = () => {
    setSelectedAddressId(null)
    setOpenContactDialog(true)
  }

  // ToDo: add confirmation dialog
  const handleDeleteAddress = useCallback(
    (id: GridRowId) => () => {
      deleteAddress({
        variables: {
          addressId: Number(id),
          customerId,
        },
      }).then((response) => {
        if (response.data) {
          toast.success(`Address ${id} was deleted with success!`)
        }
      })
    },
    [customerId, deleteAddress],
  )

  const handleCloseContactDialog = () => {
    setOpenContactDialog(false)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleGetRowHeight = useCallback(({ model, densityFactor }: GridRowHeightParams) => {
    if (model.addressLine2) {
      return 65 * densityFactor
    } else {
      return null
    }
  }, [])

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Title/Company', minWidth: 200, flex: 1 },
      {
        field: 'addressLine1',
        headerName: 'Address',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <div>
              <div>{row.addressLine1}</div>
              <div>{row.addressLine2}</div>
            </div>
          )
        },
      },
      { field: 'city', headerName: 'Town/City', width: 200 },
      { field: 'postcode', headerName: 'Post Code', width: 150 },
      { field: 'email', headerName: 'Email', width: 200 },
      { field: 'telephoneNumber', headerName: 'Telephone Number', width: 200 },
      { field: 'isDefault', headerName: 'Default', width: 150, type: 'boolean' },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deletingAddress}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteAddress(id)}
          />,
        ],
      },
    ],
    [deletingAddress, handleDeleteAddress],
  )

  const filter: AddressFilterInput = {
    or: [{ name: { contains: searchValue } }, { addressLine1: { contains: searchValue } }],
  }

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_CUSTOMER_ADDRESSES}
        queryVariables={{ customerId }}
        entityName='addresses'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'id', sort: 'asc' }}
        toolbar={{
          caption: 'Address List',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateAddress}>
                New Address
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        getRowHeight={handleGetRowHeight}
        onRowClick={handleRowClick}
      />

      {openContactDialog && (
        <CreateUpdateAddress
          addressId={selectedAddressId}
          customerId={customerId}
          openDialog={openContactDialog}
          handleCloseDialog={handleCloseContactDialog}
        />
      )}
    </>
  )
}

export default AddressList
