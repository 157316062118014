import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'
import { Control, FieldErrors } from 'react-hook-form'

import {
  ControlledTextField,
  ControlledAutocomplete,
  ControlledCheckbox,
  LegalAccountTypeControl,
  DropZoneIcon,
  ControlledPostCode,
  ControlledCountryAutocomplete,
  ControlledTelephoneNumber,
  ControlledSelector,
  IAutocompleteOptions,
} from 'components/common'
import { VatCategoryType, Mode } from 'constants/index'
import { CreateAndUpdateCustomerDtoInput, PaymentMethod, VatCategory } from 'generated/graphql'

import CustomerLogo from './CustomerLogo'

interface IProps {
  control: Control<CreateAndUpdateCustomerDtoInput, any>
  errors: FieldErrors<CreateAndUpdateCustomerDtoInput>
  subCustomersOptions: IAutocompleteOptions
  customersLoading: boolean
  customerInfoForSubCustomerLoading: boolean
  mode: number
  primaryCustomerIdValue: string
  customerId: number | null
  paymentMethods: PaymentMethod[]
  vatCategories: VatCategory[]
  customerLogo: any
  handleChangeCustomerLogo: (file: any) => void
  handleRemoveCustomerLogo: () => void
  handleAutoFillClick: () => void
  handleCopyFromPcClick: () => void
}

const CustomerDetailsForm = ({
  control,
  errors,
  subCustomersOptions,
  customersLoading,
  mode,
  customerInfoForSubCustomerLoading,
  primaryCustomerIdValue,
  customerId,
  customerLogo,
  paymentMethods,
  vatCategories,
  handleChangeCustomerLogo,
  handleCopyFromPcClick,
  handleRemoveCustomerLogo,
  handleAutoFillClick,
}: IProps) => {
  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} justifyContent='space-between' spacing={2}>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Trading Name'
              name='name'
              defaultValue=''
              required
              error={!!errors?.name}
              helperText={errors?.name?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledAutocomplete
              control={control}
              label='Primary Customer'
              name='primaryCustomerId'
              options={subCustomersOptions}
              defaultValue=''
              loading={customersLoading}
            />
          </Grid>

          <Grid item>
            <ControlledCheckbox
              control={control}
              label='Is Active'
              name='isEnabled'
              disabled={mode !== Mode.Update}
            />
          </Grid>

          <Grid item ml='auto'>
            <LoadingButton
              variant='contained'
              color='grey'
              loading={customerInfoForSubCustomerLoading}
              disabled={!primaryCustomerIdValue}
              onClick={handleCopyFromPcClick}>
              Copy From PC
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <LegalAccountTypeControl control={control} required />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            {customerId && customerLogo.file ? (
              <CustomerLogo
                onNewLogoSubmitted={handleChangeCustomerLogo}
                onOldLogoRemoved={handleRemoveCustomerLogo}
                loadingFile={customerLogo.uploadingLogo}
                imageUri={customerLogo.file?.absoluteUri}
              />
            ) : (
              <DropZoneIcon
                dropzoneText='Customer Logo'
                filesLimit={1}
                onFilesChanged={(files) => {
                  customerLogo.setNewLogo(files.length > 0 ? files[0] : null)
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent='space-between' spacing={2}>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Address (Line 1)'
              name='addressLine1'
              defaultValue=''
              required
              error={!!errors?.addressLine1}
              helperText={errors?.addressLine1?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Address (Line 2)'
              name='addressLine2'
              defaultValue=''
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Town/City'
              name='city'
              defaultValue=''
              required
              error={!!errors?.city}
              helperText={errors?.city?.message}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <ControlledPostCode
              control={control}
              name='postcode'
              required
              onAutoFillClick={handleAutoFillClick}
              error={!!errors?.postcode}
              helperText={errors?.postcode?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledCountryAutocomplete
              control={control}
              required
              error={!!errors?.countryId}
              helperText={errors?.countryId?.message}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent='space-between' spacing={2}>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Invoice Email'
              name='invoiceEmail'
              defaultValue=''
              type='email'
              error={!!errors?.invoiceEmail}
              helperText={errors?.invoiceEmail?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Add. Emails (separated by ";")'
              name='additionalInvoiceEmails'
              defaultValue=''
              type='text'
              multiline
              error={!!errors?.additionalInvoiceEmails}
              helperText={errors?.additionalInvoiceEmails?.message}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <ControlledTelephoneNumber
              control={control}
              label='Tel No'
              name='telephoneNumber'
              defaultValue=''
              error={!!errors?.telephoneNumber}
              helperText={errors?.telephoneNumber?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledCheckbox
              control={control}
              label='On Hold'
              name='isOnHold'
              defaultValue={false}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent='space-between' spacing={2}>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <ControlledSelector
              control={control}
              label='Pay Method'
              name='paymentMethodId'
              options={
                paymentMethods?.map((p) => ({
                  value: p.id,
                  label: p.name,
                })) || []
              }
              defaultValue={1}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCheckbox
              control={control}
              label='Apply VAT to other charges'
              name='isApplyVatToOtherCharges'
              defaultValue={false}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <ControlledSelector
              control={control}
              label='VAT Category'
              name='vatCategoryId'
              options={vatCategories?.map((v) => ({ value: v.id, label: v.name })) || []}
              defaultValue={VatCategoryType.t1}
              error={!!errors?.vatCategoryId}
              helperText={errors?.vatCategoryId?.message}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default CustomerDetailsForm
