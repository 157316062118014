import { useEffect, useMemo } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Box, DialogActions, Paper } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { LoadingBackdrop } from 'components/common'
import { JoiValidationOptions, Paths } from 'constants/index'
import {
  CourierExchangeLoadDtoInput,
  DeliverTimeRangeType,
  FixedJobDescriptionType,
  LoadDtoBodyType,
  LoadDtoFreightType,
  LoadDtoJobType,
  FixedOrderDtoPaymentTerm,
  PickupTimeRangeType,
  useGetBookingQuery,
  usePostLoadMutation,
  VolumeUnitType,
  WeightUnitType,
  GetBookingAllocatedDriversDocument,
  SortEnumType,
  GetBookingDocument,
  PackagingType,
} from 'generated/graphql'
import { PostLoadOrBookDirectValidation } from 'validation-schemas'

import CXMemberDetails from './CXMemberDetails'
import LoadDetails from './LoadDetails'
import PackageDetails from './PackageDetails'
import { normalizePostLoadForm } from './PostLoadOrBookDirect.helpers'
import ServiceDetails from './ServiceDetails'

const defaultFormValues = {
  // Load Details
  showYourReferenceToSubcontractor: true,
  showCustomerReferenceToSubcontractor: false,
  freightType: LoadDtoFreightType.Na,
  minVehicleSize: '' as any,
  jobDescription: FixedJobDescriptionType.SameDayTimed,
  bodyType: LoadDtoBodyType.Na,
  jobType: LoadDtoJobType.Hotshot,
  tailLift: false,
  hiab: false,
  trailer: false,
  moffetMounty: false,
  twoMan: false,
  waitAndReturn: false,
  acceptanceRequired: true,
  podHardCopiesRequired: true,

  // Service Details
  pickupTimeRange: PickupTimeRangeType.Between,
  deliverTimeRange: DeliverTimeRangeType.By,
  stopTimeDetails: [],

  // Package Details
  numberOfItems: 0,
  packaging: PackagingType.Packages,
  volume: {
    height: 0,
    width: 0,
    length: 0,
    units: VolumeUnitType.Cm,
  },
  grossWeight: {
    value: 0,
    units: WeightUnitType.Kg,
  },
  notes: '',

  // CX Member Details
  orderSubcontractorId: '',
  orderAgreedRate: 0,
  orderPaymentTerm: FixedOrderDtoPaymentTerm.Value_30Eom,
}

interface IProps {
  bookingId: string | null
  isQuote?: boolean
}

const PostLoadOrBookDirect = (props: IProps) => {
  const { bookingId, isQuote = false } = props

  const methods = useForm<CourierExchangeLoadDtoInput>({
    resolver: joiResolver(PostLoadOrBookDirectValidation, JoiValidationOptions),
    defaultValues: defaultFormValues,
  })
  const { handleSubmit, reset, watch } = methods

  const history = useHistory()

  const cxMemberId = watch('orderSubcontractorId')

  // Queries
  const { data: bookingData, loading: bookingLoading } = useGetBookingQuery({
    variables: { bookingId: Number(bookingId) },
  })

  // Mutations
  const [postLoad, { loading: postingLoad }] = usePostLoadMutation({
    refetchQueries: [
      {
        query: GetBookingAllocatedDriversDocument,
        variables: {
          bookingId: Number(bookingId),
          order: { createdAt: SortEnumType.Desc },
        },
      },
      { query: GetBookingDocument, variables: { bookingId: Number(bookingId) } },
    ],
    onCompleted: (data) => {
      if (data.postLoad) {
        toast.success(`Load (${data.postLoad.id}) was posted to CX with success.`)
        history.push(Paths.bookings.updateWithId(data.postLoad.bookingId))
      }
    },
  })

  useEffect(() => {
    const bookingPackage = bookingData?.booking?.packages?.[0]
    const isUpdatePackage = bookingPackage && bookingData?.booking?.packages.length === 1
    const totalWeight = bookingData?.booking?.packages?.reduce(
      (previousValue, currentPackage) =>
        currentPackage.quantity * currentPackage.weight + previousValue,
      0,
    )

    const tariffCXVehicleSize =
      bookingData?.booking?.tariff?.tariffCategory.courierExchangeVehicleSize
    const isUpdateMinVehicleSize = bookingData?.booking?.tariffId && tariffCXVehicleSize

    reset({
      ...defaultFormValues,

      ...(isUpdateMinVehicleSize && {
        minVehicleSize: tariffCXVehicleSize || '',
      }),

      ...(isUpdatePackage && {
        numberOfItems: bookingPackage.quantity,
        packaging: bookingPackage.type,
        volume: {
          length: bookingPackage.length,
          width: bookingPackage.width,
          height: bookingPackage.height,
          units: VolumeUnitType.Cm,
        },
        grossWeight: {
          value: bookingPackage.weight,
          units: WeightUnitType.Kg,
        },
        notes: bookingPackage.name,
      }),

      ...(totalWeight !== 0 && {
        grossWeight: {
          value: totalWeight,
          units: WeightUnitType.Kg,
        },
      }),
    })
  }, [bookingData, reset])

  const isBookDirectDisabled = useMemo(() => !cxMemberId || isQuote, [cxMemberId, isQuote])

  const handlePostLoadClick = (formData) => {
    const normalizedFormData = normalizePostLoadForm(formData)

    postLoad({
      variables: {
        input: {
          ...formData,
          ...normalizedFormData,
          orderSubcontractorId: null,
          orderAgreedRate: null,
          orderPaymentTerm: null,
          bookingId: Number(bookingId),
        },
      },
    })
  }

  const handleBookDirectClick = (formData) => {
    const normalizedFormData = normalizePostLoadForm(formData)

    postLoad({
      variables: {
        input: {
          ...formData,
          ...normalizedFormData,
          bookingId: Number(bookingId),
        },
      },
    })
  }

  return (
    <>
      <FormProvider {...methods}>
        <form noValidate>
          <Box>
            <Paper variant='outlined'>
              <Box padding={2}>
                <LoadDetails bookingDetails={bookingData?.booking} />
              </Box>
            </Paper>
          </Box>
          <Box marginY={1.5}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <ServiceDetails bookingDetails={bookingData?.booking} />
              </Box>
            </Paper>
          </Box>
          <Box marginY={1.5}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <PackageDetails bookingDetails={bookingData?.booking} />
              </Box>
            </Paper>
          </Box>
          <Box marginY={1.5}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <CXMemberDetails disabled={isQuote} />
              </Box>
            </Paper>
          </Box>
          <DialogActions>
            <LoadingButton
              variant='contained'
              loading={postingLoad}
              onClick={(e) => handleSubmit(handlePostLoadClick)(e)}>
              Post Load
            </LoadingButton>
            <LoadingButton
              variant='contained'
              disabled={isBookDirectDisabled}
              loading={postingLoad}
              onClick={(e) => handleSubmit(handleBookDirectClick)(e)}>
              Book Direct
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>

      <LoadingBackdrop loading={bookingLoading} />
    </>
  )
}

export default PostLoadOrBookDirect
