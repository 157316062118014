import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { CreateAndUpdateXeroCodeDtoInput, useGetXeroCodeQuery } from 'generated/graphql'

import ServiceCodeForm from './ServiceCodeForm'

interface IProps {
  id: number
}

const UpdateXeroCode = (props: IProps) => {
  const { id } = props
  const { reset } = useFormContext<CreateAndUpdateXeroCodeDtoInput>()

  // queries
  const { data, loading } = useGetXeroCodeQuery({
    variables: { id },
  })

  useEffect(() => {
    if (data) {
      reset({ ...data.xeroCode })
    }
  }, [data, reset])

  return (
    <>
      <ServiceCodeForm />
      <LoadingBackdrop loading={loading} />
    </>
  )
}

export default UpdateXeroCode
