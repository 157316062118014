import { useState } from 'react'

import { AxiosError } from 'axios'

import { GetFileName, SaveFile } from 'helpers'
import { DownloadFile } from 'services'

export interface DownloadState {
  id: number
  loading: boolean
  error: string
}

export const useFileDownloader = (): [DownloadState, (id: number) => Promise<boolean>] => {
  const [state, setState] = useState<DownloadState>({
    loading: false,
    error: '',
    id: 0,
  })

  const downloadFile = async (id: number) => {
    setState({
      loading: true,
      error: '',
      id: id,
    })

    try {
      await DownloadFile(id).then((response) => {
        let fileName = GetFileName(response)
        if (!fileName) {
          fileName = `file-${+new Date()}.dat`
        }
        SaveFile(response.data, fileName)
      })

      setState({ loading: false, id: 0, error: '' })
      return true
    } catch (e) {
      let error = ''
      if (typeof e === 'string') {
        error = e.toLocaleUpperCase()
      } else if (e instanceof Error) {
        error = (e as AxiosError).message
      }
      setState({ loading: false, id: 0, error })
      return false
    }
  }

  return [state, downloadFile]
}
