import { useMemo } from 'react'

import { TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { useGetCountriesQuery } from 'generated/graphql'

interface IProps {
  control: Control<any, any>
  name?: string
  defaultValue?: any
  required?: boolean
  disabled?: boolean
  loading?: boolean
  error?: boolean
  helperText?: any
  autoComplete?: string
}

function AutocompleteWrapper({ value, onChange, countries, children, ...otherProps }) {
  const selectedCountry = useMemo(
    () => countries.find((country) => Number(country.id) === Number(value)),
    [countries, value],
  )

  return children({
    ...otherProps,
    onChange: (_, data) => {
      onChange(Number(data?.id))
    },
    value: selectedCountry || { value: '', name: '' },
  })
}

const ControlledCountryAutocomplete = (props: IProps) => {
  const {
    control,
    name = 'countryId',
    defaultValue,
    required,
    disabled,
    loading,
    error,
    helperText,
    autoComplete,
  } = props

  const { data: countriesData } = useGetCountriesQuery()

  const countries = countriesData?.countries || []

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...restField } }) => (
        <AutocompleteWrapper {...restField} countries={countries}>
          {(props: any) => (
            <Autocomplete
              {...props}
              options={countries}
              loading={loading}
              getOptionLabel={(option: any) => option?.name}
              isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
              disabled={disabled}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Country'
                  variant='outlined'
                  required={required}
                  fullWidth
                  error={error}
                  helperText={helperText}
                  autoComplete={autoComplete}
                />
              )}
            />
          )}
        </AutocompleteWrapper>
      )}
    />
  )
}

export default ControlledCountryAutocomplete
