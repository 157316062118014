import { Driver, XeroCode } from 'generated/graphql'

export const getXeroPurchaseCodeValuesToLabelCode = (xeroPurchaseCodes: Driver[]) => {
  return [
    {
      label: 'No purchase code',
      value: null,
    },

    ...new Map(
      xeroPurchaseCodes.map((item) => [
        item.xeroPurchaseCode,
        {
          label: item.xeroPurchaseCode ?? 'none',
          value: item.xeroPurchaseCode,
        },
      ]),
    ).values(),
  ]
}

export const getXeroCodeValuesToLabelName = (xeroCodes: XeroCode[] = []) => {
  return xeroCodes.map((code) => ({ value: code.id, label: code.name }))
}
