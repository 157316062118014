import { useEffect } from 'react'

import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledTextField } from 'components/common'
import { CreateAndUpdateTariffDtoInput } from 'generated/graphql'

const DropChargeDetails = () => {
  const {
    control,
    watch,
    setValue,
    trigger,

    formState: { isSubmitted, errors },
  } = useFormContext<CreateAndUpdateTariffDtoInput>()

  const isDropChargeEnabled: boolean = watch('isDropChargeEnabled', false)
  const isUseDefaultDropCharge: boolean = watch('isUseDefaultDropCharge', true)

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === 'isDropChargeEnabled') {
        if (!data.isDropChargeEnabled) {
          setValue('isUseDefaultDropCharge', false)
          setValue('dropChargeRate', '')
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [setValue, watch])

  useEffect(() => {
    if (isSubmitted) {
      trigger('dropChargeRate')
    }
  }, [isDropChargeEnabled, isUseDefaultDropCharge, isSubmitted, trigger])

  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12} lg={4}>
        <ControlledCheckbox control={control} name='isDropChargeEnabled' label='Enable' />
      </Grid>

      <Grid item xs={12} lg={4}>
        <ControlledCheckbox
          control={control}
          name='isUseDefaultDropCharge'
          label='Use Default Drop Charge'
          disabled={!isDropChargeEnabled}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <ControlledTextField
          control={control}
          name='dropChargeRate'
          label='Rate'
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          min='0'
          endAdornment='£/drop'
          required={isDropChargeEnabled && !isUseDefaultDropCharge}
          disabled={!isDropChargeEnabled || isUseDefaultDropCharge}
          error={!!errors.dropChargeRate}
          helperText={errors.dropChargeRate?.message}
        />
      </Grid>
    </Grid>
  )
}

export default DropChargeDetails
