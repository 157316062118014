import { useCallback, useMemo } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTimePicker, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, DEBOUNCE_DELAY, TIME_FORMAT } from 'constants/index'
import {
  Currency,
  GetScheduledExchangeRateTemplatesDocument,
  useCreateScheduledExchangeRateTemplateMutation,
  useDeleteScheduledExchangeRateTemplateMutation,
  useGetCurrenciesQuery,
  useRunExchangeRateServiceMutation,
} from 'generated/graphql'
import { GET_PAGED_EXCHANGE_RATE_TEMPLATES_QUERY } from 'graphql/queries'

import CurrencySplitButton from './CurrencySplitButton'

const TasksPanel = () => {
  const { control, watch } = useForm<{ date: Date }>({
    shouldUnregister: true,
    defaultValues: {
      date: new Date(),
    },
  })

  const [date] = useDebounce(watch('date', new Date()), DEBOUNCE_DELAY)

  // queries
  const { data: currenciesData, loading: currenciesLoading } = useGetCurrenciesQuery()

  // mutations
  const [createTemplate, { loading: creating }] = useCreateScheduledExchangeRateTemplateMutation({
    refetchQueries: [{ query: GetScheduledExchangeRateTemplatesDocument }],
  })

  const [deleteTemplate, { loading: deleting }] = useDeleteScheduledExchangeRateTemplateMutation({
    refetchQueries: [{ query: GetScheduledExchangeRateTemplatesDocument }],
  })

  const [runExchangeRateService, { loading: gettingExchangeRates }] =
    useRunExchangeRateServiceMutation({
      onCompleted: (response) => {
        if (response.runExchangeRateService) {
          toast.success('Success')
        } else {
          toast.error('Something went wrong')
        }
      },
    })

  const currencies: Currency[] = useMemo(() => {
    if (currenciesLoading || !currenciesData) {
      return []
    }

    return currenciesData.currencies?.map((c) => c as Currency) ?? []
  }, [currenciesLoading, currenciesData])

  // handlers
  const onGetExchangeRates = () => runExchangeRateService()
  const onAddClick = (id: number) =>
    createTemplate({
      variables: {
        currencyId: id,
        date: date,
      },
    })

  // ToDo: add confirmation dialog
  const handleDelete = useCallback(
    (id: GridRowId) => () => {
      deleteTemplate({ variables: { id: Number(id) } })
    },
    [deleteTemplate],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'id', headerName: 'Id', width: 100 },
      {
        field: 'date',
        headerName: 'Time',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) => value && format(parseJSON(value), TIME_FORMAT),
      },
      {
        field: 'currency',
        headerName: 'Currency',
        minWidth: 100,
        flex: 1,
        sortPath: ['currency', 'code'],
        valueGetter: ({ value }) => value?.code,
      },
      { field: 'createdBy', headerName: 'Created by', minWidth: 150, flex: 1 },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) => value && format(parseJSON(value), DATE_TIME_FORMAT),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleting}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDelete(id)}
          />,
        ],
      },
    ],
    [deleting, handleDelete],
  )

  return (
    <>
      <Stack direction='row' spacing={3} alignItems='center' py={2}>
        <div>
          <ControlledTimePicker label='Hour' control={control} name='date' />
        </div>

        <CurrencySplitButton currencies={currencies} onClick={onAddClick} loading={creating} />

        <LoadingButton loading={gettingExchangeRates} onClick={onGetExchangeRates}>
          Run now
        </LoadingButton>
      </Stack>

      <FplDataGrid
        query={GET_PAGED_EXCHANGE_RATE_TEMPLATES_QUERY}
        entityName='scheduledExchangeRateTemplates'
        columns={columns}
        defaultOrder={{ field: 'id', sort: 'asc' }}
        toolbar={{ caption: 'Scheduled Tasks' }}
      />
    </>
  )
}

export default TasksPanel
