import { gql } from '@apollo/client'

export const ReasonCodeDetailsFragment = gql`
  fragment ReasonCodeDetails on ReasonCode {
    name
    code
    description
    customerId

    customer {
      id
      name
    }
  }
`
