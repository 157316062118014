import { useEffect, useMemo } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { LoadingBackdrop } from 'components/common'
import {
  useGetUserBookingLazyQuery,
  useGetBookingLazyQuery,
  BookingStatus,
  useOnBookingUpdateSubscription,
} from 'generated/graphql'
import { isBookingProcessingVar } from 'graphql/reactiveVariables'
import { normalizeBookingForm } from 'helpers'
import { usePermission } from 'providers/PermissionProvider'
import { TBookingForm } from 'types/form-types'

import BookingFormControls from './BookingFormControls'

interface IProps {
  bookingId: string
  isQuickQuote: boolean
  mode: number | undefined
}

const UpdateBooking = (props: IProps) => {
  const { bookingId, isQuickQuote, mode } = props
  const { idTokenPayload } = useOidcIdToken()

  const { reset } = useFormContext<TBookingForm>()

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')

  // Queries
  const [getAdminBooking, { data: adminBookingData, loading: adminBookingLoading }] =
    useGetBookingLazyQuery()

  const [getUserBooking, { data: userBookingData, loading: userBookingLoading }] =
    useGetUserBookingLazyQuery()

  // subscriptions
  useOnBookingUpdateSubscription({
    variables: {
      bookingId: Number(bookingId),
    },
    onData: (data) => {
      if (data.data.data?.onBookingUpdated.updatedBy !== idTokenPayload.name) {
        toast.warn('Booking was updated by another user')
        const toastBookingRefreshed = () => toast.success('Booking was refreshed')

        if (isAllowedToViewBookingFee) {
          getAdminBooking({
            variables: { bookingId: Number(bookingId) },
            fetchPolicy: 'network-only',
          }).then(toastBookingRefreshed)
        } else {
          getUserBooking({
            variables: { bookingId: Number(bookingId) },
            fetchPolicy: 'network-only',
          }).then(toastBookingRefreshed)
        }
      }
    },
  })

  const booking = adminBookingData?.booking || userBookingData?.userBooking

  useEffect(() => {
    if (isAllowedToViewBookingFee) {
      getAdminBooking({
        variables: { bookingId: Number(bookingId) },
      })
    } else {
      getUserBooking({
        variables: { bookingId: Number(bookingId) },
      })
    }
  }, [bookingId, isAllowedToViewBookingFee, getAdminBooking, getUserBooking])

  useEffect(() => {
    isBookingProcessingVar(adminBookingLoading || userBookingLoading)
  }, [adminBookingLoading, userBookingLoading])

  useEffect(() => {
    if (booking) {
      reset(normalizeBookingForm(booking))
    }
  }, [booking, reset])

  const bookingStatus = booking?.status
  const isQuoteStatus = useMemo(() => bookingStatus === BookingStatus.Quotation, [bookingStatus])

  return (
    <>
      <BookingFormControls
        bookingId={bookingId}
        booking={booking}
        isQuote={isQuoteStatus}
        isQuickQuote={isQuickQuote}
        isUpdate
        mode={mode}
      />
      <LoadingBackdrop loading={adminBookingLoading || userBookingLoading} />
    </>
  )
}

export default UpdateBooking
