import { Grid, Paper, Box, Button } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { CreateAndUpdateDriverDtoInput } from 'generated/graphql'

import ContactDetails from './ContactDetails'
import InsuranceDetails from './InsuranceDetails'
import MainDetails from './MainDetails'
import OtherDetails from './OtherDetails'
import { PaymentDetails } from './PaymentDetails'

interface IProps {
  mode: number
  isProcessing: boolean
}

const DriverFormControls = (props: IProps) => {
  const { mode, isProcessing } = props
  const history = useHistory()
  const {
    formState: { isDirty },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()
  const spacing = 3

  const handleCancel = () => history.goBack()

  return (
    <Grid container spacing={spacing}>
      <Grid container item xs={12} lg={6}>
        {/* Driver Details */}
        <Grid item xs={12}>
          <Paper variant='outlined' sx={{ padding: 2 }}>
            <MainDetails />
          </Paper>
        </Grid>
        {/* Contact Details */}
        <Grid item xs={12}>
          <Paper variant='outlined' sx={{ padding: 2, marginTop: 2 }}>
            <ContactDetails />
          </Paper>
        </Grid>
      </Grid>
      <Grid container item xs={12} lg={6}>
        {/* Payment Details */}
        <Grid item xs={12}>
          <Paper variant='outlined' sx={{ padding: 2 }}>
            <PaymentDetails />
          </Paper>
        </Grid>
        {/* Insurance Details */}
        <Grid item xs={12} sm={6}>
          <Paper variant='outlined' sx={{ padding: 2, marginTop: 2, marginRight: 2 }}>
            <InsuranceDetails />
          </Paper>
        </Grid>
        {/* Other Details */}
        <Grid item xs={12}>
          <Paper variant='outlined' sx={{ padding: 2, marginTop: 2 }}>
            <OtherDetails mode={mode} />
          </Paper>
        </Grid>

        <Grid item xs={12} margin={2}>
          <Box display='flex' justifyContent='flex-end'>
            <Button variant='contained' color='grey' onClick={handleCancel} disabled={isProcessing}>
              Cancel
            </Button>
            <Box ml={3}>
              <Button
                color='primary'
                variant='contained'
                type='submit'
                disabled={isProcessing || !isDirty}>
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DriverFormControls
