import { useEffect } from 'react'

import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledCheckbox, ControlledTextField, FplDialogTitle } from 'components/common'
import { useGetCustomersInstructionsForDriverLazyQuery } from 'generated/graphql'

import { DriverInstruction } from '../types'

interface IProps {
  openDialog: boolean
  selectedDrivers: any
  customerId: string | null
  onSelectInstruction: (data: DriverInstruction) => void
  onCloseDialog: () => void
}

const SelectInstructionDialog = (props: IProps) => {
  const { openDialog, selectedDrivers, customerId, onSelectInstruction, onCloseDialog } = props

  const driver = selectedDrivers.drivers.find(
    (driver: { driverId: any }) => driver.driverId === selectedDrivers.focusedDriverId,
  )

  const { control, setValue, getValues } = useForm<DriverInstruction>({
    defaultValues: {
      isShownInstruction: driver.isShownInstruction ?? false,
      instruction: driver.instruction ?? '',
    },
  })

  const [getInstructions] = useGetCustomersInstructionsForDriverLazyQuery({
    variables: {
      customerId,
    },
    onCompleted: (response) => {
      if (!response) {
        toast.error("Couldn't get instructions for driver")
        return
      }

      setValue('instruction', response.customersInstructionsForDriver, {
        shouldDirty: true,
        shouldValidate: true,
      })
    },
  })

  useEffect(() => {
    if (customerId && getValues('instruction') === '') {
      getInstructions()
    }
  }, [customerId, getValues, getInstructions])

  const handleUpdateClick = () => {
    const values = getValues()
    onSelectInstruction(values)
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='md'
      scroll='body'
      aria-labelledby='select-driver-instruction-dialog'>
      <FplDialogTitle id='select-driver-instruction-dialog' onClose={handleCloseDialog}>
        Driver Instruction
      </FplDialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ControlledCheckbox
              control={control}
              label='Show Instruction to Driver'
              name='isShownInstruction'
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label='Instruction'
              name='instruction'
              multiline
              rows={7}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Close
        </Button>
        <Button variant='contained' onClick={handleUpdateClick}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectInstructionDialog
