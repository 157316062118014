import { useEffect } from 'react'

import { Checkbox, FormControlLabel, Grid, Tooltip } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledTextField } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { GetUserBookingQuery } from 'generated/graphql'
import { TBookingForm } from 'types/form-types'

interface IProps {
  booking: GetUserBookingQuery['userBooking']
  disabled?: boolean
  hoverText?: string
}

const NotesAndInstructions = (props: IProps) => {
  const { booking, disabled, hoverText = '' } = props

  const {
    control,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  useEffect(() => {
    if (booking?.noteOrSpecialInstruction) {
      toast.info('This booking has some Notes/Special Instruction.', { autoClose: 7000 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking?.id])

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <CardTitle disabled={disabled}>Notes/Special Instructions</CardTitle>
        <Grid container spacing={1.5} alignItems='center'>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name='noteOrSpecialInstruction'
              label='Notes/Instructions'
              defaultValue=''
              multiline
              disabled={disabled}
              error={!!errors.noteOrSpecialInstruction}
              helperText={errors.noteOrSpecialInstruction?.message}
            />
          </Grid>

          <Grid item xs={12} sm={7}>
            <ControlledTextField
              control={control}
              name='alternativeOurReference'
              label='Alternative Our Reference'
              defaultValue=''
              disabled={disabled}
              error={!!errors.alternativeOurReference}
              helperText={errors.alternativeOurReference?.message}
            />
          </Grid>

          <Grid item xs={6} sm={5}>
            <FormControlLabel
              sx={{ cursor: 'default' }}
              title='read-only'
              disabled={disabled}
              control={
                <Checkbox
                  sx={{ cursor: 'default' }}
                  checked={booking?.isImported || false}
                  disableRipple
                />
              }
              label='Is Imported'
            />
          </Grid>

          <Grid item xs={6} sm={7}>
            <FormControlLabel
              sx={{ cursor: 'default' }}
              title='read-only'
              disabled={disabled}
              control={
                <Checkbox
                  sx={{ cursor: 'default' }}
                  checked={booking?.isQuoteOriginally || false}
                  disableRipple
                />
              }
              label='Is Quote Originally'
            />
          </Grid>

          <Grid item xs={6} sm={5}>
            <FormControlLabel
              sx={{ cursor: 'default' }}
              title='read-only'
              disabled={disabled}
              control={
                <Checkbox
                  sx={{ cursor: 'default' }}
                  checked={booking?.isCreatedByCustomer || false}
                  disableRipple
                />
              }
              label='Is created by Customer'
            />
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  )
}

export default NotesAndInstructions
