import React from 'react'

import { Grid, Paper, Box } from '@mui/material'
import { endOfDay, startOfMonth, subYears } from 'date-fns'
import { format, parseJSON } from 'date-fns'

import { CardTitle, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT } from 'constants/index'
import { BookingInvoiceContentFilterInput, InvoiceType } from 'generated/graphql'
import { GET_PAGED_INVOICE_LINES } from 'graphql/queries'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadBookingInvoices } from 'services'

import FilterCriteria from './FilterCriteria'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'Id', width: 70 },
  {
    field: 'companyName',
    headerName: 'Company Name',
    minWidth: 150,
    flex: 1,
    sortPath: ['bookingInvoice', 'customer', 'name'],
    valueGetter: ({ row }) => row?.bookingInvoice?.customer?.name,
  },
  {
    field: 'postcode',
    headerName: 'Postcode',
    minWidth: 100,
    flex: 0.5,
    sortPath: ['bookingInvoice', 'customer', 'postcode'],
    valueGetter: ({ row }) => row?.bookingInvoice?.customer?.postcode,
  },
  {
    field: 'invoiceNumber',
    headerName: 'Invoice Number',
    minWidth: 110,
    flex: 0.5,
    sortPath: ['bookingInvoice', 'booking', 'ourReference'],
    valueGetter: ({ row }) =>
      row?.bookingInvoice?.driverInvoiceNumber ?? row?.bookingInvoice?.booking?.ourReference,
  },
  {
    field: 'fplRef',
    headerName: 'FPL Ref',
    minWidth: 100,
    flex: 0.5,
    sortPath: ['bookingInvoice', 'reference'],
    valueGetter: ({ row }) => row?.bookingInvoice?.reference,
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    minWidth: 100,
    flex: 0.5,
    sortPath: ['bookingInvoice', 'invoiceDate'],
    valueGetter: ({ row }) => {
      const invoiceDate = row?.bookingInvoice?.invoiceDate

      if (invoiceDate) {
        return format(parseJSON(invoiceDate as string), DATE_FORMAT)
      }
    },
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    minWidth: 100,
    flex: 0.5,
    sortPath: ['bookingInvoice', 'dueDate'],
    valueGetter: ({ row }) => {
      const dueDate = row?.bookingInvoice?.dueDate

      if (dueDate) {
        return format(parseJSON(dueDate as string), DATE_FORMAT)
      }
    },
  },
  {
    field: 'customerReferenceLabel',
    headerName: 'Customer Ref',
    minWidth: 100,
    flex: 0.5,
    sortPath: ['bookingInvoice', 'customer', 'customerReferenceLabel'],
    valueGetter: ({ row }) => row?.bookingInvoice?.customer?.customerReferenceLabel,
  },
  { field: 'unitAmount', headerName: 'Invoice value', minWidth: 100, flex: 0.5 },
  { field: 'accountCode', headerName: 'Service Type', minWidth: 100, flex: 0.5 },
  {
    field: 'description',
    headerName: 'Additional surcharges',
    minWidth: 400,
    flex: 2,
  },
]

interface IPurchasesFilter {
  start: Date | undefined
  end: Date | undefined
}

const defaultFilter: IPurchasesFilter = {
  start: startOfMonth(new Date()),
  end: endOfDay(new Date()),
}

const Purchases = () => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [purchasesFilter, setPurchasesFilter] = React.useState<BookingInvoiceContentFilterInput>({
    bookingInvoice: {
      invoiceType: { eq: InvoiceType.Purchase },
      invoiceDate: { gte: defaultFilter.start, lte: defaultFilter.end },
    },
  })

  const handleViewReport = ({ start, end }: IPurchasesFilter) => {
    setPurchasesFilter({
      bookingInvoice: {
        invoiceType: { eq: InvoiceType.Purchase },
        ...((start || end) && { invoiceDate: { gte: start, lte: end } }),
      },
    })
  }

  const handleDownloadReport = ({ start, end }: IPurchasesFilter) => {
    setIsProcessing(true)

    const startDate = start ? start : subYears(new Date(), 5)
    const endDate = end ? end : new Date()

    DownloadBookingInvoices(startDate, endDate, InvoiceType.Purchase)
      .then((response) => {
        setIsProcessing(false)

        let fileName = GetFileName(response)
        if (!fileName) {
          fileName = `Purchase-invoices-from-${start}-to-${end}.csv`
        }

        SaveFile(response.data, fileName)
      })
      .catch((error) => ShowRestError(error))
      .finally(() => setIsProcessing(false))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>Filter Criteria</CardTitle>
            <FilterCriteria
              defaultFilter={defaultFilter}
              isProcessing={isProcessing}
              onViewReport={handleViewReport}
              onDownloadReport={handleDownloadReport}
            />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <FplDataGrid
          query={GET_PAGED_INVOICE_LINES}
          entityName='pagedInvoiceLines'
          columns={columns}
          defaultOrder={{ field: 'invoiceDate', sort: 'desc' }}
          toolbar={{ caption: 'Purchases' }}
          filter={purchasesFilter}
        />
      </Grid>
    </Grid>
  )
}

export default Purchases
export type { IPurchasesFilter }
