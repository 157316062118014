import { ReactNode } from 'react'

import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

const StyledButton = styled(LoadingButton)({
  textTransform: 'none',
  boxShadow: 'none',
  background: 'linear-gradient(to right, #471d7c, #c6007e)',
  borderRadius: '25px',
  fontFamily: 'Goldplay-Medium, sans-serif',
  fontSize: '16px',
  padding: '10px 16px',
})

interface IProps {
  children?: ReactNode
  loading?: boolean
  onClick?: any
}

// ToDo: rename
const PrimaryButton = (props: IProps) => {
  const { children, loading = false, ...otherProps } = props

  return (
    <StyledButton {...otherProps} loading={loading} variant='contained' fullWidth>
      {children}
    </StyledButton>
  )
}

export default PrimaryButton
