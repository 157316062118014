import { useMemo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, IconButton, Paper } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, DEBOUNCE_DELAY, Paths } from 'constants/index'
import { StandardOperatingProcedureFilterInput } from 'generated/graphql'
import { GET_PAGED_STANDARD_OPERATING_PROCEDURES } from 'graphql/queries'

const columns: Array<IGridColumn> = [
  { field: 'id', headerName: 'ID', minWidth: 150, flex: 0.4 },
  { field: 'name', headerName: 'Name', minWidth: 230, flex: 1 },
  { field: 'isActive', headerName: 'Is Active', width: 100, type: 'boolean' },
  { field: 'createdBy', headerName: 'Created By', minWidth: 100, flex: 1 },
  {
    field: 'lastModifiedAt',
    headerName: 'Last Modified At',
    minWidth: 100,
    flex: 0.6,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_TIME_FORMAT),
  },
]

const Sops = () => {
  const history = useHistory()

  const { control, watch, setValue } = useForm({
    defaultValues: { search: '' },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  const filter: StandardOperatingProcedureFilterInput = useMemo(
    () => ({
      isEnabled: { eq: true },
      ...(searchValue && {
        and: [{ name: { contains: searchValue } }],
      }),
    }),
    [searchValue],
  )

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    history.push(Paths.standardOperatingProcedures.updateWithId(id))
  }

  const handleCreateStandardOperatingProcedure = () => {
    history.push(Paths.standardOperatingProcedures.create)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <FplDataGrid
          query={GET_PAGED_STANDARD_OPERATING_PROCEDURES}
          entityName='standardOperatingProcedures'
          columns={columns}
          filter={filter}
          toolbar={{
            caption: 'Standard Operating Procedures',
            leftSide: (
              <Grid item>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={handleCreateStandardOperatingProcedure}>
                  New SOP
                </Button>
              </Grid>
            ),
            rightSide: (
              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            ),
            refreshButton: true,
          }}
          onRowClick={handleRowClick}
        />
      </Box>
    </Paper>
  )
}

export default Sops
