import { useGetDistanceCalculationLazyQuery } from 'generated/graphql'

const useMapDistanceCalculation = (bookingAddresses: any) => {
  const [
    getDistanceCalculation,
    { data: distanceCalculationData, loading: distanceCalculationLoading },
  ] = useGetDistanceCalculationLazyQuery()

  const calculateDistance = (addresses: Array<{ id: string }>) => {
    const googleAddressInput = addresses.map((address, index) => {
      const bookingAddressNew = bookingAddresses?.find((ba) => ba.id === address.id)

      return {
        sequenceOrder: index,
        addressLine1: bookingAddressNew?.address.addressLine1,
        ...(bookingAddressNew?.address.addressLine2 && {
          addressLine2: bookingAddressNew?.address.addressLine2,
        }),
        postcode: bookingAddressNew?.address.postcode,
        city: bookingAddressNew?.address.city,
        countryId: bookingAddressNew?.address.countryId,
      }
    })
    console.log('calcuate')
    getDistanceCalculation({ variables: { input: googleAddressInput } })
  }

  return {
    calculateDistance,
    distanceCalculationData,
    distanceCalculationLoading,
  }
}

export default useMapDistanceCalculation
