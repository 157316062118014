import { useEffect, useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FplDialogTitle, LoadingBackdrop } from 'components/common'
import { Paths } from 'constants/index'
import {
  useGetApproveDeclineUserDetailsQuery,
  useUpdateUserApprovalMutation,
} from 'generated/graphql'
import { GET_PAGED_USERS_QUERY } from 'graphql/queries'
import { Restricted } from 'providers'

import ApproveDeclineUserForm from './ApproveDeclineUserForm'
import ValidationSchema from './ValidationSchema'

interface IProps {
  userId: number
  openDialog: boolean
  onCloseDialog: () => any
}

const ApproveDeclineUser = (props: IProps) => {
  const { userId, openDialog, onCloseDialog } = props
  const [isFullScreen, setIsFullScreen] = useState(false)
  const history = useHistory()
  const methods = useForm({
    shouldUnregister: true,
    resolver: joiResolver(ValidationSchema),
    defaultValues: {
      email: '',
      userName: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      role: '',
      customerId: '',
      contactId: '',
      driverId: '',
      suggestedCompany: '',
    },
  })
  const { reset, handleSubmit, watch } = methods

  const { data: userDetailsData, loading: getUserDetailsLoading } =
    useGetApproveDeclineUserDetailsQuery({
      variables: { id: userId },
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (userDetailsData?.userDetails) {
      const { emailConfirmed, ...details } = userDetailsData.userDetails
      reset({ ...details })
    }
  }, [userDetailsData, reset])

  const driverId = watch('driverId')

  const [updateUserApproval, { loading: updateUserApprovalLoading }] =
    useUpdateUserApprovalMutation({
      refetchQueries: [
        { query: GET_PAGED_USERS_QUERY, variables: { where: { isApproved: { eq: false } } } },
      ],
    })

  const handleDecline = () => {
    updateUserApproval({
      variables: { input: { id: String(userId), isApproved: false } },
    }).then((response) => {
      if (response.data) {
        toast.success('User was declined.')
        onCloseDialog()
      }
    })
  }

  const handleApprove: SubmitHandler<any> = ({ customerId, contactId, driverId, role }) => {
    updateUserApproval({
      variables: {
        input: {
          id: String(userId),
          isApproved: true,
          role: role || userDetailsData?.userDetails?.role,
          ...(driverId && { driverId: String(driverId) }),
          ...(customerId && { customerId: String(customerId) }),
          ...(contactId && { contactId: String(contactId) }),
        },
      },
    }).then((response) => {
      if (response.data) {
        toast.success('User was approved with success.')
        onCloseDialog()
      }
    })
  }

  const handleDriverDetailsClick = () => {
    history.push(Paths.drivers.updateWithId(driverId))
  }

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const handleOnClose = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openDialog}
      fullScreen={isFullScreen}
      aria-labelledby='form-approve-decline-user-dialog'>
      <FplDialogTitle
        id='form-approve-decline-user-dialog'
        onClose={handleOnClose}
        onFullScreen={handleFullScreen}>
        <Typography variant='h4' component='p'>
          User Details
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <Box pt={1}>
            <ApproveDeclineUserForm />
            <LoadingBackdrop loading={getUserDetailsLoading} />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' spacing={1}>
          <Button variant='contained' color='grey' onClick={handleOnClose}>
            Cancel
          </Button>

          {driverId && (
            <Button variant='contained' color='grey' onClick={handleDriverDetailsClick}>
              Driver details
            </Button>
          )}

          <Restricted to='DeclineUser'>
            <LoadingButton
              variant='contained'
              loading={updateUserApprovalLoading}
              onClick={handleDecline}>
              Decline
            </LoadingButton>
          </Restricted>

          <Restricted to='ApproveUser'>
            <Tooltip
              title={
                !userDetailsData?.userDetails.emailConfirmed ? 'Email confirmation is required' : ''
              }>
              <span>
                <LoadingButton
                  variant='contained'
                  disabled={!userDetailsData?.userDetails.emailConfirmed}
                  loading={updateUserApprovalLoading}
                  onClick={handleSubmit(handleApprove)}>
                  Approve
                </LoadingButton>
              </span>
            </Tooltip>
          </Restricted>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default ApproveDeclineUser
