import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'

import {
  BookingAddress,
  useGetBookingAddressesLazyQuery,
  useGetUserBookingAddressesLazyQuery,
} from 'generated/graphql'
import { usePermission } from 'providers'

import AddressAttachmentsAccordion from './AddressAttachmentsAccordion'

interface BookingAddressAttachmentsProps {
  bookingId: string | null
}

const BookingAddressAttachments = (props: BookingAddressAttachmentsProps) => {
  const { bookingId } = props
  const [hasFullAccess] = usePermission('ViewBookingDetails')

  const [expanded, setExpanded] = useState<string | false>(false)

  const [getBookingAddresses, { loading: addressesLoading, data: addressesData }] =
    useGetBookingAddressesLazyQuery({
      // variables: {
      //   bookingId: Number(bookingId),
      // },
    })

  const [getUserBookingAddresses, { loading: userAddressesLoading, data: userAddressData }] =
    useGetUserBookingAddressesLazyQuery()

  const bookingData = addressesData?.booking || userAddressData?.userBooking

  useEffect(() => {
    if (hasFullAccess) {
      getBookingAddresses({ variables: { bookingId: Number(bookingId) } })
    } else {
      getUserBookingAddresses({ variables: { bookingId: Number(bookingId) } })
    }
  }, [bookingId, getBookingAddresses, getUserBookingAddresses, hasFullAccess])

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Box>
      {addressesLoading && userAddressesLoading
        ? 'Loading...'
        : bookingData?.addresses.map((a) => (
          <AddressAttachmentsAccordion
            key={`address-${a.id}`}
            address={a as BookingAddress}
            expand={expanded}
            onChange={handleChange}
          />
        ))}
    </Box>
  )
}

export default BookingAddressAttachments
