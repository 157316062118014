import { useCallback, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT, DEBOUNCE_DELAY } from 'constants/index'
import { BannerFilterInput, SortEnumType, useDeleteBannerMutation } from 'generated/graphql'
import { GET_PAGED_BANNERS } from 'graphql/queries'

import { BannerDialog } from './BannerDialog'

const Banners = () => {
  const [selectedBannerId, setSelectedBannerId] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const [deleteBanner, { loading: deletingBanner }] = useDeleteBannerMutation({
    refetchQueries: [
      {
        query: GET_PAGED_BANNERS,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { createdAt: SortEnumType.Asc },
        },
      },
    ],
  })

  const handleCreateBanner = () => {
    setSelectedBannerId(null)
    setOpenDialog(true)
  }

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedBannerId(Number(id))
    setOpenDialog(true)
  }

  const handleDeleteBanner = useCallback(
    (id: GridRowId) => () => {
      deleteBanner({
        variables: {
          id: Number(id),
        },
      }).then((response) => {
        if (response.data?.deleteBanner) {
          toast.success('Banner was deleted with success!')
        }
      })
    },
    [deleteBanner],
  )

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'isActive', headerName: 'Is Active', width: 150, type: 'boolean' },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 200,
        flex: 0.5,
        valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
      },
      { field: 'createdBy', headerName: 'Created By', minWidth: 200, flex: 0.5 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deletingBanner}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteBanner(id)}
          />,
        ],
      },
    ],
    [deletingBanner, handleDeleteBanner],
  )

  const filter: BannerFilterInput = useMemo(
    () => ({
      or: [{ name: { contains: searchValue } }],
    }),
    [searchValue],
  )

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_BANNERS}
        entityName='pagedBanners'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'id', sort: 'asc' }}
        toolbar={{
          caption: 'Banners',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateBanner}>
                New Banner
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <BannerDialog
          id={selectedBannerId}
          openDialog={openDialog}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default Banners
