import { forwardRef } from 'react'

import { Box, Fade, Grow } from '@mui/material'

export enum TransitionType {
  grow,
  fade,
  collapse,
  slide,
  zoom,
}

interface TransitionsProps {
  children: React.ReactNode
  in?: boolean
  type?: TransitionType
  // position?: 'top-left' | 'top-right' | 'top' | 'bottom-left' | 'bottom-right' | 'bottom'
}
// eslint-disable-next-line react/display-name
export const Transitions = forwardRef<HTMLDivElement, TransitionsProps>((props, ref) => {
  const { children, type = TransitionType.grow, ...others } = props

  const positionSX = {
    transformOrigin: '0 0 0',
  }

  return (
    <Box ref={ref}>
      {type === TransitionType.grow && (
        <Grow {...others}>
          <Box sx={positionSX}>{children}</Box>
        </Grow>
      )}
      {type === TransitionType.fade && (
        <Fade
          {...others}
          timeout={{
            appear: 0,
            enter: 300,
            exit: 150,
          }}>
          <Box sx={positionSX}>{children}</Box>
        </Fade>
      )}
    </Box>
  )
})
