import React from 'react'

import { useOidc, useOidcUser } from '@axa-fr/react-oidc-context'
import { Button, Grid } from '@mui/material'

import ExceptionMessage from 'components/common/ExceptionMessage'
import { Paths } from 'constants/index'

const NotAuthorized = () => {
  const { logout } = useOidc()
  const { oidcUser } = useOidcUser()

  // ToDo: use isAuthenticated from useOidc() hook
  const isAuthenticated = React.useMemo(() => Boolean(oidcUser), [oidcUser])

  const onLogoutClicked = () => logout(`${process.env.REACT_APP_PUBLIC_URL}`)

  const onGoHomeClick = () => window.location.assign(Paths.home)
  const bodyMessage =
    "You don't have permission to access this page. Please contact helpdesk@firstpointlogistics.co.uk and request access to this page Go back! Logout"

  return (
    <ExceptionMessage
      headerText='Unauthorized access'
      bodyText={bodyMessage}
      imageUri='/static/firstpoint-logo-1.png'
      actions={
        <Grid container spacing={3} justifyContent='center'>
          <Grid item>
            <Button color='primary' onClick={onGoHomeClick}>
              Home
            </Button>
          </Grid>
          <Grid item>
            {isAuthenticated && (
              <Button onClick={onLogoutClicked} color='primary'>
                Logout
              </Button>
            )}
          </Grid>
        </Grid>
      }
    />
  )
}

export default NotAuthorized
