import { gql } from '@apollo/client'

export const CREATE_VEHICLE_MUTATION = gql`
  mutation CreateVehicle($input: CreateAndUpdateVehicleDtoInput!) {
    createVehicle(input: $input) {
      id
      isDefault
      driverId
      registrationNumber
      vehicleTypeId
      manufacturer
      dateOfManufacture
      model
      colour
      insuranceCompany
      insuranceExpiryDate
      motTestExpiryDate
      isRenewalReminder
      policyOrCoverNote
      goodsInTransitExpiryDate
    }
  }
`

export const UPDATE_VEHICLE_MUTATION = gql`
  mutation UpdateVehicle($input: CreateAndUpdateVehicleDtoInput!) {
    updateVehicle(input: $input) {
      id
      isDefault
      driverId
      registrationNumber
      vehicleTypeId
      manufacturer
      dateOfManufacture
      model
      colour
      insuranceCompany
      insuranceExpiryDate
      motTestExpiryDate
      isRenewalReminder
      policyOrCoverNote
      goodsInTransitExpiryDate
    }
  }
`
