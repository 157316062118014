import { Mode } from 'constants/index'

import React from 'react'

import { Grid } from '@mui/material'
import {
  ControlledSelector,
  ControlledDatePicker,
  ControlledTextField,
  ControlledCurrencyAutocomplete,
  CardTitle,
  ISelectorOptions,
} from 'components/common'
import {
  CreateAndUpdateDriverDtoInput,
  useGetCurrenciesQuery,
  useGetPaymentTermsQuery,
} from 'generated/graphql'
import { usePermission } from 'providers'
import { useFormContext } from 'react-hook-form'

interface IProps {
  mode: number
}

const OtherDetails = (props: IProps) => {
  const { mode } = props
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()
  const spacing = 2

  // Queries
  const { data: paymentTermsData } = useGetPaymentTermsQuery()
  const [isAllowedToUpdatePaymentTerms] = usePermission('UpdatePaymentTerms')

  const { data: currenciesData, loading: currenciesLoading } = useGetCurrenciesQuery()

  const paymentTermsItems: ISelectorOptions = React.useMemo(
    () =>
      paymentTermsData?.paymentTerms.map((t) => ({
        value: t.paymentTerm,
        label: t.description,
      })) || [],
    [paymentTermsData],
  )

  React.useEffect(() => {
    if (mode === Mode.Create && currenciesData) {
      const baseCurrency = currenciesData.currencies.find((c) => c.isBaseCurrency)
      baseCurrency && setValue('currencyId', baseCurrency.id)
    }
  }, [mode, currenciesData, setValue])

  return (
    <>
      <CardTitle>Other Details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            control={control}
            name='startDate'
            label='Start Date'
            error={!!errors.startDate}
            helperText={errors.startDate?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            readOnly={!isAllowedToUpdatePaymentTerms}
            label='Payment Terms'
            name='paymentTerm'
            required
            options={paymentTermsItems}
            error={!!errors.paymentTerm}
            helperText={errors.paymentTerm?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name='invoiceEmail'
            label='Invoice Email'
            defaultValue=''
            type='email'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField name='eoriNumber' label='EORI Number' defaultValue='' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledCurrencyAutocomplete
            control={control}
            options={currenciesData?.currencies || []}
            loading={currenciesLoading}
            error={!!errors.currencyId}
            helperText={errors.currencyId?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='companyRegistrationNumber'
            label='Company Registration Number'
            error={!!errors.companyRegistrationNumber}
            helperText={errors.companyRegistrationNumber?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OtherDetails
