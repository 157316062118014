import { useState, useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material'
import { compareAsc } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { getPaging } from 'components/common/FplDataGrid/helpers'
import { Paths } from 'constants/Paths'
import { SortEnumType, useGetActivitiesQuery } from 'generated/graphql'
import { usePermission } from 'providers/PermissionProvider'

import HistoryRow from './HistoryRow'

interface IProps {
  bookingId: string | null
  onActivityClick: (id) => void
  onDeleteClick: (id) => void
}

const HistoryList = (props: IProps) => {
  const { bookingId, onActivityClick, onDeleteClick } = props
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const history = useHistory()
  const [isAllowedToUpdateActivity] = usePermission('UpdateActivity')
  const [isAllowedToDeleteActivity] = usePermission('DeleteActivity')

  // queries
  const { data, loading, fetchMore } = useGetActivitiesQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      ...getPaging({ first: 10 }),
      ...(bookingId && {
        where: {
          bookingId: { eq: Number(bookingId) },
        },
      }),
      order: { date: SortEnumType.Desc },
    },
  })

  const activitiesSorted = useMemo(
    () =>
      data?.activities?.edges
        ?.slice()
        .sort((a, b) => compareAsc(new Date(a.node.date), new Date(b.node.date))),
    [data],
  )

  const handleRedirectClick = (bookingId) => {
    history.push(`${Paths.bookings.updateWithId(bookingId)}#tasks`)
  }

  const handleLoadMore = () => {
    const paging = getPaging({ after: data?.activities?.pageInfo.endCursor, first: 10 })

    setFetchMoreLoading(true)

    fetchMore({
      variables: {
        ...paging,
        ...(bookingId && {
          where: {
            bookingId: { eq: Number(bookingId) },
          },
        }),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newActivities = fetchMoreResult?.activities?.edges ?? []
        const previousEdges = previousResult.activities?.edges ?? []

        if (fetchMoreResult?.activities) {
          return {
            activities: {
              pageInfo: fetchMoreResult.activities.pageInfo,
              totalCount: fetchMoreResult.activities.totalCount,
              __typename: fetchMoreResult.activities.__typename,
              edges: [...previousEdges, ...newActivities],
            },
          }
        } else {
          return previousResult
        }
      },
    }).finally(() => setFetchMoreLoading(false))
  }

  return (
    <>
      <Stack divider={<Divider orientation='horizontal' flexItem />}>
        {loading
          ? [...Array(5)].map((_, index) => (
            <Box px={1} py={0.5} key={index}>
              <Typography variant='h6'>
                <Skeleton animation='wave' />
              </Typography>
            </Box>
          ))
          : activitiesSorted?.map((activity) => (
            <HistoryRow
              key={activity.node.id}
              activity={activity.node}
              bookingId={bookingId}
              isAllowedToUpdateActivity={isAllowedToUpdateActivity}
              isAllowedToDeleteActivity={isAllowedToDeleteActivity}
              onActivityClick={onActivityClick}
              onDeleteClick={onDeleteClick}
              onRedirectClick={handleRedirectClick}
            />
          ))}
      </Stack>

      <Divider orientation='horizontal' flexItem />

      <Stack direction='row' justifyContent='center' paddingY={1}>
        <LoadingButton
          loading={fetchMoreLoading}
          disabled={!data?.activities?.pageInfo.hasNextPage}
          onClick={handleLoadMore}>
          Load more
        </LoadingButton>
      </Stack>
    </>
  )
}

export default HistoryList
