import Joi from 'joi'

import { Roles } from 'constants/index'

const ValidationSchema = Joi.object({
  role: Joi.string().allow(''),
  customerId: Joi.when('role', {
    is: Roles.customer,
    then: Joi.string().required(),
    otherwise: Joi.allow('', null),
  }).label('Customer'),
  contactId: Joi.string().allow(''),
  driverId: Joi.allow('', null).optional().label('Driver'),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
  userName: Joi.string().allow(''),
  firstName: Joi.string().allow(''),
  lastName: Joi.string().allow(''),
  phoneNumber: Joi.number().allow(''),
  suggestedCompany: Joi.string().allow(''),
})

export default ValidationSchema
