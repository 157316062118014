import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Grid, Typography } from '@mui/material'
import { DropzoneArea } from 'react-mui-dropzone'
import { toast } from 'react-toastify'

import { GetErrorMessage, ShowDropzoneAlert } from 'helpers'
import {
  ImportBookingsFile,
  ImportDriversCodeFile,
  ImportDriversFile,
  UploadPotentialBookingFile,
} from 'services'

import ImportResult from './ImportResult'

enum ImportDataTypes {
  LegacyDrivers = 0,
  LegacyBookings,
  DriversCode,
  PotentialBooking,
}

interface IProps {
  type: ImportDataTypes
}

const ImportDataForm = (props: IProps) => {
  const { type } = props

  const [importing, setImporting] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const [results, setResults] = useState<string[]>([])

  // handlers
  const handleFilesChanged = (files: File[]) => setFiles(files)

  const handleImportClick = async () => {
    setImporting(true)
    setResults([])
    try {
      if (files.length === 0) {
        toast.error('No files to import')
        return
      }

      const fileData = new FormData()
      files.forEach((f) => fileData.append('file', f))

      if (type === ImportDataTypes.LegacyDrivers) {
        const result = await ImportDriversFile(fileData)

        setResults(result.data)
      } else if (type === ImportDataTypes.LegacyBookings) {
        const result = await ImportBookingsFile(fileData)

        setResults(result.data)
      } else if (type === ImportDataTypes.DriversCode) {
        const result = await ImportDriversCodeFile(fileData)

        setResults(result.data)
      } else if (type === ImportDataTypes.PotentialBooking) {
        const result = await UploadPotentialBookingFile(fileData)

        setResults(result.data)
      }
    } catch (error: unknown) {
      toast.error(GetErrorMessage(error))
    } finally {
      setImporting(false)
    }
  }

  const dropzoneText =
    type !== ImportDataTypes.PotentialBooking
      ? 'Drag and drop a file here or click (.csv only)'
      : 'Drag and drop a file here or click (.xlsx only)'

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='body1'>
          {type !== ImportDataTypes.PotentialBooking &&
            'Please convert files to CSV via Microsoft Excel before uploading.'}
          <br />
          {type === ImportDataTypes.LegacyBookings &&
            'Note that it takes around 1 second to import 1 Booking, so the whole process can take a long time for big files.'}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <DropzoneArea
          clearOnUnmount={true}
          initialFiles={files}
          onChange={handleFilesChanged}
          filesLimit={1}
          showAlerts={false}
          dropzoneText={dropzoneText}
          onAlert={ShowDropzoneAlert}
        />
      </Grid>

      <Grid item xs={12}>
        <LoadingButton
          loading={importing}
          onClick={handleImportClick}
          variant='contained'
          disabled={files.length === 0}>
          Import
        </LoadingButton>
      </Grid>

      <Grid item xs={12}>
        <ImportResult data={results} />
      </Grid>
    </Grid>
  )
}

export { ImportDataForm, ImportDataTypes }
