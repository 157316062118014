import { LocaleObject } from 'yup'

export const ValidationMessages: LocaleObject = {
  mixed: {
    required: 'Field is required',
  },
  string: {
    email: 'Invalid email',
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Field must be greater than or equal to ${min}',
  },
}
