import { AccordionDetails, Divider, Skeleton } from '@mui/material'
import { Box } from '@mui/system'

import { File, useGetBookingAddressAttachmentsQuery } from 'generated/graphql'

import AttachmentFile from './AttachmentFile'

interface AttachmentsAccordionDetailsProps {
  id: number
}

const AttachmentsAccordionDetails = (props: AttachmentsAccordionDetailsProps) => {
  const { loading: attachmentsLoading, data: attachmentsData } =
    useGetBookingAddressAttachmentsQuery({
      variables: {
        id: props.id,
      },
    })

  return (
    <AccordionDetails>
      <Divider />
      {attachmentsLoading ? (
        <Skeleton about='Loading attachments' height={50} />
      ) : (
        <Box display='flex' flexDirection='row' flexWrap='wrap'>
          {attachmentsData?.bookingAddress?.attachments.map((a) => (
            <AttachmentFile key={a.id} file={a as File} />
          ))}
        </Box>
      )}
    </AccordionDetails>
  )
}

export default AttachmentsAccordionDetails
