import { gql } from '@apollo/client'

import { ReasonCodeDetailsFragment } from 'graphql/fragments'

export const GET_PAGED_REASON_CODES = gql`
  query GetPagedReasonCodes(
    $customerId: Long
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: ReasonCodeFilterInput
    $order: [ReasonCodeSortInput!]
  ) {
    pagedReasonCodes(
      customerId: $customerId
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      order: $order
    ) {
      edges {
        node {
          id
          name
          code
          createdAt
          createdBy

          customer {
            id
            name
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_USER_PAGED_REASON_CODES = gql`
  query GetUserPagedReasonCodes(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: ReasonCodeFilterInput
    $order: [ReasonCodeSortInput!]
  ) {
    userPagedReasonCodes(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          code
          description
          createdAt
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_REASON_CODE = gql`
  query GetReasonCode($id: Long!) {
    reasonCode(id: $id) {
      id
      ...ReasonCodeDetails
    }

    ${ReasonCodeDetailsFragment}
  }
`

export const GET_REASON_CODES = gql`
  query GetReasonCodes($customerId: Long!) {
    reasonCodes(customerId: $customerId) {
      id
      ...ReasonCodeDetails
    }

    ${ReasonCodeDetailsFragment}
  }
`

export const GET_USER_REASON_CODES = gql`
  query GetUserReasonCodes($isIncludeSubCustomers: Boolean!) {
    userReasonCodes(isIncludeSubCustomers: $isIncludeSubCustomers) {
      id
      ...ReasonCodeDetails
    }

    ${ReasonCodeDetailsFragment}
  }
`
