import { CardTitle } from 'components/common'

import { ImportDataForm, ImportDataTypes } from './common'

const ImportLegacyDrivers = () => {
  return (
    <>
      <CardTitle>Import Legacy Drivers</CardTitle>
      <ImportDataForm type={ImportDataTypes.LegacyDrivers} />
    </>
  )
}

export default ImportLegacyDrivers
