import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField, ControlledSelector, ControlledDatePicker } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { CustomerAcquisitionSource } from 'generated/graphql'

import AccountType from './AccountType'

interface AccountDetailsProps {
  acquisitionSources: Array<CustomerAcquisitionSource>
}

const AccountDetails = (props: AccountDetailsProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const { acquisitionSources } = props

  const spacing = 2

  return (
    <>
      <CardTitle>Account Details</CardTitle>
      <Grid container spacing={spacing} justifyContent='space-between'>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Account Code'
            name='accountCode'
            defaultValue=''
            required
            error={!!errors?.accountCode}
            helperText={errors?.accountCode?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Company Reg Number'
            name='companyRegistrationNumber'
            defaultValue=''
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='VAT Number'
            name='vatIdNumber'
            defaultValue=''
            error={!!errors?.vatIdNumber}
            helperText={errors?.vatIdNumber?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='EORI Number'
            name='eoriNumber'
            defaultValue=''
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Sales Name'
            name='salesName'
            defaultValue=''
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            label='Acquired By'
            name='acquiredBy'
            defaultValue=''
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledSelector
            control={control}
            label='Acquisition Source'
            name='customerAcquisitionSourceId'
            options={acquisitionSources?.map((a) => ({ value: a.id, label: a.name })) || []}
            defaultValue={2}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            control={control}
            name='startDate'
            label='Start Date'
            required
            error={!!errors?.startDate}
            helperText={errors?.startDate?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AccountType control={control} required />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label='Notes'
            name='notes'
            defaultValue=''
            multiline
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AccountDetails
