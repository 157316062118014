import { useEffect, useMemo } from 'react'

import { FormHelperText, Grid } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledSelector, ControlledTextField, ISelectorOptions } from 'components/common'
import { PackagingSizes } from 'constants/index'
import { PackagingType, useGetPackagingTypesQuery } from 'generated/graphql'
import { TBookingForm } from 'types/form-types'

import CollectionSequenceOrder from './CollectionSequenceOrder'
import DeliverySequenceOrder from './DeliverySequenceOrder'

interface IProps {
  index: number
  isQuickQuote: boolean
  disabled?: boolean
}

const PackageForm = (props: IProps) => {
  const { index, isQuickQuote, disabled } = props
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const selectedPackagingType: PackagingType = watch(`packages.${index}.packageType`)

  const isPackageDetailsSectionOptional = useWatch({ name: 'isPackageDetailsSectionOptional' })

  useEffect(() => {
    const size = PackagingSizes[selectedPackagingType]
    if (size) {
      setValue(`packages.${index}.length`, size.length)

      setValue(`packages.${index}.width`, size.width)
    }
  }, [index, selectedPackagingType, setValue])

  // Queries
  const { loading: packagingTypesLoading, data: packagingTypesData } = useGetPackagingTypesQuery()

  const packagingTypeOptions: ISelectorOptions = useMemo(() => {
    if (packagingTypesLoading || !packagingTypesData?.packagingTypes) {
      return []
    }

    return packagingTypesData.packagingTypes.map((t) => ({
      value: t.packagingType,
      label: t.description,
      disabled: t.disabled,
    }))
  }, [packagingTypesLoading, packagingTypesData])

  const isRequired = !isQuickQuote && !isPackageDetailsSectionOptional

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={6} md={3}>
        <ControlledTextField
          control={control}
          name={`packages.${index}.quantity`}
          label='Quantity'
          defaultValue=''
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          min='0'
          required={isRequired}
          disabled={disabled}
          error={!!errors.packages?.[index]?.quantity}
          helperText={errors.packages?.[index]?.quantity?.message}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <ControlledSelector
          control={control}
          name={`packages.${index}.packageType`}
          label='Type'
          options={packagingTypeOptions}
          defaultValue=''
          required={isRequired}
          disabled={disabled}
          error={!!errors.packages?.[index]?.packageType}
          helperText={errors.packages?.[index]?.packageType?.message}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <CollectionSequenceOrder
          index={index}
          isQuickQuote={isQuickQuote}
          isRequired={isRequired}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <DeliverySequenceOrder
          index={index}
          isQuickQuote={isQuickQuote}
          isRequired={isRequired}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <ControlledTextField
          control={control}
          name={`packages.${index}.length`}
          label='Length'
          defaultValue=''
          required={isRequired}
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          endAdornment='cm'
          min='0'
          disabled={disabled}
          error={!!errors.packages?.[index]?.length}
          helperText={errors.packages?.[index]?.length?.message}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <ControlledTextField
          control={control}
          name={`packages.${index}.width`}
          label='Width'
          defaultValue=''
          required={isRequired}
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          endAdornment='cm'
          min='0'
          disabled={disabled}
          error={!!errors.packages?.[index]?.width}
          helperText={errors.packages?.[index]?.width?.message}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <ControlledTextField
          control={control}
          name={`packages.${index}.height`}
          label='Height'
          defaultValue=''
          required={isRequired}
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          endAdornment='cm'
          min='0'
          disabled={disabled}
          error={!!errors.packages?.[index]?.height}
          helperText={errors.packages?.[index]?.height?.message}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <ControlledTextField
          control={control}
          name={`packages.${index}.weight`}
          label='Weight'
          defaultValue=''
          required={isRequired}
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          endAdornment='kg'
          min='0'
          disabled={disabled}
          error={!!errors.packages?.[index]?.weight}
          helperText={errors.packages?.[index]?.weight?.message}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4.5}>
        <ControlledTextField
          control={control}
          name={`packages.${index}.reference`}
          label='Reference'
          defaultValue=''
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={7.5}>
        <ControlledTextField
          control={control}
          name={`packages.${index}.details`}
          label='Details'
          defaultValue=''
          multiline
          disabled={disabled}
        />
      </Grid>

      {errors.packages?.[index]?.message && (
        <Grid item xs={12}>
          {/* // ToDo: Add focus on error */}
          <FormHelperText error required focused>
            {errors.packages?.[index]?.message}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  )
}

export default PackageForm
