const keyIsThemeLight = 'isThemeLight'
const keyIsCookiesAccepted = 'isCookiesAccepted'
const pageSize = 'pageSize'
const pageNumber = 'pageNumber'
const paging = 'paging'
const dataSort = 'dataSort'
const columnVisibility = 'columnVisibility'
const density = 'density'
const separator = '_'
const autoUpdate = 'autoUpdate'

export function setIsThemeLight(value: unknown): void {
  localStorage.setItem(keyIsThemeLight, JSON.stringify(value))
}

export function getIsThemeLight(): boolean {
  let isLightTheme = true // default value
  const keyValue = localStorage.getItem(keyIsThemeLight)

  if (keyValue === null) {
    setIsThemeLight(isLightTheme)
  } else {
    isLightTheme = JSON.parse(localStorage.getItem(keyIsThemeLight) as string)
  }

  return isLightTheme
}

export function setIsCookiesAccepted(value: boolean) {
  localStorage.setItem(keyIsCookiesAccepted, JSON.stringify(value))
}

export function getIsCookiesAccepted(): boolean | null {
  const keyValue = localStorage.getItem(keyIsCookiesAccepted)

  if (keyValue === null) {
    return null
  } else {
    return JSON.parse(keyValue)
  }
}

export function setPageSize(value: unknown, gridId: string): void {
  localStorage.setItem(gridId + separator + pageSize, JSON.stringify(value))
}

export function setPageNumber(value: unknown, gridId: string): void {
  localStorage.setItem(gridId + separator + pageNumber, JSON.stringify(value))
}
export function setPagination(value: unknown, gridId: string): void {
  localStorage.setItem(gridId + separator + paging, JSON.stringify(value))
}
export function setDataSort(value: unknown, gridId: string): void {
  localStorage.setItem(gridId + separator + dataSort, JSON.stringify(value))
}

export function setColumnVisibility(value: unknown, gridId: string): void {
  localStorage.setItem(gridId + separator + columnVisibility, JSON.stringify(value))
}

export function setDensityModel(value: unknown, gridId: string): void {
  localStorage.setItem(gridId + separator + density, JSON.stringify(value))
}

export function setAutoUpdate(value: unknown, Id: string): void {
  localStorage.setItem(Id + '_' + autoUpdate, JSON.stringify(value))
}

export function getPageSize(gridId: string) {
  return (
    localStorage.getItem(gridId + separator + pageSize) &&
    JSON.parse(localStorage.getItem(gridId + separator + pageSize) ?? '')
  )
}

export function getPageNumber(gridId: string) {
  return (
    localStorage.getItem(gridId + separator + pageNumber) &&
    JSON.parse(localStorage.getItem(gridId + separator + pageNumber) ?? '')
  )
}
export function getPagination(gridId: string) {
  return localStorage.getItem(gridId + separator + paging)
    ? JSON.parse(localStorage.getItem(gridId + '_' + paging) ?? '')
    : null
}
export function getDataSort(gridId: string) {
  return (
    localStorage.getItem(gridId + separator + dataSort) &&
    JSON.parse(localStorage.getItem(gridId + separator + dataSort) ?? '')
  )
}

export function getColumnVisibility(gridId: string) {
  return localStorage.getItem(gridId + separator + columnVisibility)
    ? JSON.parse(localStorage.getItem(gridId + separator + columnVisibility) ?? '')
    : {}
}

export function getDensityModel(gridId: string) {
  return localStorage.getItem(gridId + separator + density)
    ? JSON.parse(localStorage.getItem(gridId + separator + density) ?? '')
    : 'standard'
}

export function getAutoUpdate(Id: string) {
  return (
    localStorage.getItem(Id + '_' + autoUpdate) &&
    JSON.parse(localStorage.getItem(Id + '_' + autoUpdate) ?? '')
  )
}

export function removePagination(gridId: string): void {
  localStorage.removeItem(`${gridId}${separator}paging`)
}
