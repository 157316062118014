import { useEffect } from 'react'

import { useOidcIdToken, withOidcSecure } from '@axa-fr/react-oidc-context'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { NotAuthorized } from 'components/auth'
import { Bookings, BookingTab } from 'components/bookings'
import { Customers, CustomerTab } from 'components/customers'
import { Dashboard } from 'components/dashboard'
import { Help } from 'components/help'
import { NotFound } from 'components/navigation'
import { Options } from 'components/options'
import { PotentialBookingTab } from 'components/options/PotentialBookings'
import { Profile } from 'components/profile'
import { QuickQuoteTab, QuoteTab } from 'components/quotes'
import { Reports } from 'components/reports'
import { ExternalDriverInvoices } from 'components/reports/ExternalInvoices'
import { Settings } from 'components/settings'
import { Tariffs } from 'components/settings/Tariffs'
import { TariffDetails } from 'components/settings/Tariffs/Tariffs/TariffDetails'
import { Sops, SopDetails } from 'components/sops'
import { SuppliersTab } from 'components/suppliers'
import { DriverTab } from 'components/suppliers/drivers'
import { StrategicPartnerTab } from 'components/suppliers/strategicPartners'
import { UsersTab } from 'components/users'
import { Paths, SESSION_STORAGE_OIDC_KEY } from 'constants/index'
import ProtectedRoute from 'providers/ProtectedRoute'

import { AccountPayableDocuments } from './accountsPayable'
import AccountsPayableTabs from './accountsPayable/AccountsPayableTabs'
import { PotentialBookingDetails } from './bookings/BookingDetails'
import PersonalNotifications from './profile/PersonalNotifications'
import TrackingPage from './tracking/TrackingPage'

const getHomePagePath = (userRole: any) => {
  const roleURLs = {
    Admin: Paths.dashboard.all,
    Sales: Paths.dashboard.all,
    Customer: Paths.bookings.all,
    Driver: Paths.profile,
  }

  return roleURLs[userRole] ?? Paths.bookings.all
}

export default function RouterOutlet() {
  const { idTokenPayload } = useOidcIdToken()
  const { push } = useHistory()

  const homePagePath = getHomePagePath(idTokenPayload.role)

  useEffect(() => {
    const loginCall = sessionStorage.getItem(SESSION_STORAGE_OIDC_KEY)
    if (loginCall) {
      const oidcSession = JSON.parse(loginCall)

      if (
        oidcSession.callbackPath &&
        window.location.href !== oidcSession.callbackPath &&
        oidcSession.callbackPath !== Paths.auth.welcome
      ) {
        const path = oidcSession.callbackPath

        oidcSession.callbackPath = null
        sessionStorage.setItem(SESSION_STORAGE_OIDC_KEY, JSON.stringify(oidcSession))
        push(path)
      }
    }
  }, [push])

  // * Keep in mind, order meters
  return (
    <Switch>
      <ProtectedRoute
        to='ViewCustomerSalesNameList'
        path={Paths.accountsPayable.all}
        component={withOidcSecure(AccountsPayableTabs)}
      />

      {/* Profile */}
      <ProtectedRoute to='ViewProfile' path={Paths.profile} component={withOidcSecure(Profile)} />

      <ProtectedRoute
        to='ViewYourNotifications'
        path={Paths.myNotifications}
        component={withOidcSecure(PersonalNotifications)}
      />

      {/* Dashboard */}
      <ProtectedRoute
        to='ViewTasks'
        path={Paths.dashboard.all}
        component={withOidcSecure(Dashboard)}
      />

      {/* Bookings */}
      <ProtectedRoute
        to={['ViewBookingsList', 'ViewUserBookingsList']}
        path={Paths.bookings.all}
        component={withOidcSecure(Bookings)}
      />
      <ProtectedRoute
        to={['ViewBookingDetails', 'ViewUserBookingDetails']}
        path={Paths.bookings.update}
        component={withOidcSecure(BookingTab)}
      />
      <ProtectedRoute
        to={['ViewBookingDetails', 'ViewUserBookingDetails']}
        path={Paths.quotes.update}
        component={withOidcSecure(QuoteTab)}
      />
      <ProtectedRoute
        to={['ViewBookingDetails', 'ViewUserBookingDetails']}
        path={Paths.quickQuotes.update}
        component={withOidcSecure(QuickQuoteTab)}
      />

      {/* Potential Bookings */}
      <ProtectedRoute
        to={['ViewPotentialBookingFiles']}
        path={Paths.potentialBookings.all}
        component={withOidcSecure(PotentialBookingTab)}
      />

      <ProtectedRoute
        to={['ViewPotentialBookings']}
        path={Paths.potentialBookings.process}
        component={PotentialBookingDetails}
      />

      {/* Customers */}
      <ProtectedRoute
        to='ViewCustomerList'
        path={Paths.customers.all}
        component={withOidcSecure(Customers)}
      />
      <ProtectedRoute
        to='ViewCustomerDetails'
        path={Paths.customers.update}
        component={withOidcSecure(CustomerTab)}
      />

      {/* Driver */}
      <ProtectedRoute
        to='ViewDriverDetails'
        path={Paths.drivers.update}
        component={withOidcSecure(DriverTab)}
      />

      <ProtectedRoute
        to='ViewDriversProfile'
        path={Paths.drivers.updateProfile}
        component={withOidcSecure(DriverTab)}
      />

      {/* StrategicPartner */}
      <ProtectedRoute
        to='ViewDriverDetails'
        path={Paths.strategicPartners.update}
        component={withOidcSecure(StrategicPartnerTab)}
      />

      {/* Suppliers */}
      <ProtectedRoute
        to='ViewSuppliers'
        path={Paths.suppliers.all}
        component={withOidcSecure(SuppliersTab)}
      />

      {/* Users */}
      <ProtectedRoute
        to='ViewUserList'
        path={Paths.users.all}
        component={withOidcSecure(UsersTab)}
      />

      {/* Reports */}
      <ProtectedRoute
        to='ViewReports'
        path={Paths.reports.all}
        component={withOidcSecure(Reports)}
      />

      {/* SOPs */}
      <ProtectedRoute
        to='ViewStandardOperatingProcedures'
        path={Paths.standardOperatingProcedures.all}
        component={withOidcSecure(Sops)}
      />
      <ProtectedRoute
        to='ViewStandardOperatingProcedures'
        path={Paths.standardOperatingProcedures.update}
        component={withOidcSecure(SopDetails)}
      />

      {/* TODO: use valid policy */}
      <ProtectedRoute
        to='ViewExternalDriverInvoices'
        path={Paths.reports.externalInvoices}
        component={withOidcSecure(ExternalDriverInvoices)}
      />

      {/* Tariffs */}
      <ProtectedRoute
        to='ViewTariffList'
        path={Paths.tariffs.all}
        component={withOidcSecure(Tariffs)}
      />
      <ProtectedRoute
        to='ViewTariffDetails'
        path={Paths.tariffs.update}
        component={withOidcSecure(TariffDetails)}
      />

      {/* Settings */}
      <ProtectedRoute
        to='ViewTariffList'
        path={Paths.settings.all}
        component={withOidcSecure(Settings)}
      />

      <ProtectedRoute
        to='ViewOptions'
        path={Paths.options.all}
        component={withOidcSecure(Options)}
      />

      <ProtectedRoute
        to='ViewAccountsPayableRecord'
        path={Paths.accountsPayable.documents}
        component={withOidcSecure(AccountPayableDocuments)}
      />

      {/* Tracking Page Url */}
      <Route path={Paths.trackingPage} component={TrackingPage} />

      {/* Help */}
      <Route path={Paths.help.all} component={withOidcSecure(Help)} />

      {/* Request Access */}
      <Route path={Paths.requestAccess} component={NotAuthorized} />

      <Route path='/' exact>
        <Redirect to={homePagePath} />
      </Route>

      <Route path='' exact>
        <Redirect to={homePagePath} />
      </Route>

      <Route path='/*' component={NotFound} />
    </Switch>
  )
}
