import { useEffect, useState } from 'react'

import mammoth from 'mammoth/mammoth.browser'

import SanitizeHTML from 'helpers/sanitizeHTML'

import { IFilePreviewWrapperProps } from '../IFilePreviewWrapperProps'

export const MammothWrapper = (props: IFilePreviewWrapperProps) => {
  const { file } = props

  const [html, setHtml] = useState(null)

  useEffect(() => {
    mammoth
      .convertToHtml({ arrayBuffer: file.getDataAsArrayBuffer() })
      .then(function (result) {
        setHtml(result.value)
        // console.log('MammothWrapper, messages: ', result.messages) // Might have useful info
      })
      .catch(function (error) {
        console.error(error)
      })
  }, [file])

  return <SanitizeHTML html={html ?? ''} />
}
