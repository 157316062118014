import { gql } from '@apollo/client'

export const DriverDetailsFragment = gql`
  fragment DriverDetails on Driver {
    id
    firstName
    lastName
    addressLine1
    addressLine2
    bankAccountNumber
    bankName
    bankSortCode
    courierExchangeMemberId
    city
    countryId
    driverType
    xeroCodeId
    email
    employeeReference
    endorsements
    paymentTerm
    invoiceEmail
    eoriNumber
    isActive
    isEmailInvoice
    isGenerateSeparateInvoice
    lastPaid
    licenseExpiryDate
    licenseType
    currencyId
    name
    nationalInsuranceNumber
    paymentMethodId
    postcode
    publicLiabilityExpirationDate
    startDate
    telephoneNumber
    vatCategoryId
    vatIdNumber
    swift
    xeroPurchaseCode
    currencyId
    isOnboardingCompleted
    alternativeTelephoneNumber
    bankAccountHolderName
    internationalBankAccountNumber
    subDrivers {
      name
      email
      callSign
      telephoneNumber
      xeroPurchaseCode
    }
    companyRegistrationNumber
  }
`

export const DriverContactDetailsFragment = gql`
  fragment DriverContactDetails on DriverContact {
    id
    name
    isDefault
    telephoneNumber
    email
    additionalEmails
    department
    jobRole
    driverId
  }
`
