import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox } from 'components/common'
import { usePermission } from 'providers'
import { TBookingForm } from 'types'

const BookingOptions = () => {
  const { control } = useFormContext<TBookingForm>()
  const [isAllowedToChangeTransitionValidation] = usePermission(
    'UpdateBookingTransitTimeValidation',
  )

  return (
    <Box hidden={!isAllowedToChangeTransitionValidation}>
      <ControlledCheckbox
        control={control}
        name='isTransitIgnored'
        label='Ignore transit validation'
      />
    </Box>
  )
}

export default BookingOptions
