import { useMemo } from 'react'

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'

import { FplDataGrid, IGridColumn } from 'components/common'
import { FplDialogTitle } from 'components/common/Dialog'
import { GET_SUB_DRIVERS } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'callSign', headerName: 'Call Sign', minWidth: 150, flex: 1 },
  { field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
  { field: 'email', headerName: 'Email', minWidth: 150 },
  { field: 'telephoneNumber', headerName: 'Telephone Number', minWidth: 150 },
  {
    field: 'xeroCodeId',
    headerName: 'Service Code',
    width: 150,
    renderCell: ({ row }) => row?.xeroCode?.name ?? 'Unknown',
  },
]

interface IProps {
  openDialog: boolean
  selectedDriverId: string | null
  onSelectSubDriver: (id: number) => void
  onCloseDialog: () => void
}

const SelectSubDriverDialog = (props: IProps) => {
  const { openDialog, selectedDriverId, onSelectSubDriver, onCloseDialog } = props

  const queryVariables = useMemo(() => ({ driverId: Number(selectedDriverId) }), [selectedDriverId])

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectSubDriver(Number(id))
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='md'
      scroll='body'
      aria-labelledby='select-sub-drivers-dialog'>
      <FplDialogTitle id='select-sub-drivers-dialog' onClose={handleCloseDialog}>
        Select Sub-Driver
      </FplDialogTitle>

      <DialogContent>
        <FplDataGrid
          query={GET_SUB_DRIVERS}
          entityName='subDrivers'
          queryVariables={queryVariables}
          columns={columns}
          defaultOrder={{ field: 'name', sort: 'asc' }}
          toolbar={{ caption: 'Sub-drivers' }}
          onRowClick={handleRowClick}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectSubDriverDialog
