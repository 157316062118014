import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTelephoneNumber, ControlledTextField } from 'components/common'
import { CreateAndUpdateDriverContactDtoInput } from 'generated/graphql'

const ContactForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverContactDtoInput>()

  const spacing = 3

  return (
    <Grid container spacing={spacing} sx={{ marginTop: 0, marginBottom: 0 }}>
      <Grid item xs={12} sm={6}>
        <ControlledTextField
          control={control}
          label='Name'
          name='name'
          required
          error={!!errors.name}
          helperText={errors.name?.message}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTelephoneNumber
          control={control}
          label='Telephone Number'
          name='telephoneNumber'
          required
          error={!!errors.telephoneNumber}
          helperText={errors.telephoneNumber?.message}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField
          control={control}
          label='Email'
          name='email'
          type='email'
          required
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField
          control={control}
          label='Additional Email(s)'
          name='additionalEmails'
          multiline
          error={!!errors.additionalEmails}
          helperText={
            errors.additionalEmails
              ? errors.additionalEmails?.message
              : 'Manage multiple emails separated by ;'
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField
          control={control}
          label='Department'
          name='department'
          error={!!errors.department}
          helperText={errors.department?.message}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledTextField
          control={control}
          label='Job Role'
          name='jobRole'
          error={!!errors.jobRole}
          helperText={errors.jobRole?.message}
        />
      </Grid>

      {/* // ToDo: add isDefault checkbox if need */}
    </Grid>
  )
}

export default ContactForm
