import React from 'react'

import { Grid } from '@mui/material'

import { CreateAndUpdateCongestionZonePostcodeDtoInput, Weekday } from 'generated/graphql'

import CongestionZoneForm from './CongestionZoneForm'
import { IPostcodeActions } from './Postcodes'
import UpdateCongestionZone from './UpdateCongestionZone'

interface IProps {
  zoneId?: string
  notFound: boolean
  processing: boolean
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>
  setCongestionZone: React.Dispatch<React.SetStateAction<any>>
  selectedWeekdays: Array<Weekday>
  setSelectedWeekdays: React.Dispatch<React.SetStateAction<Array<Weekday>>>
  postcodes: Array<CreateAndUpdateCongestionZonePostcodeDtoInput>
  setPostcodes: React.Dispatch<
    React.SetStateAction<Array<CreateAndUpdateCongestionZonePostcodeDtoInput>>
  >
  postcodeActions: IPostcodeActions
}

export default function CongestionDialogContent(props: IProps) {
  const { notFound, zoneId, setNotFound, setCongestionZone, setPostcodes, ...restProps } = props

  if (notFound) {
    return <></>
  }

  return (
    <Grid container spacing={3}>
      <Grid item>
        {!zoneId ? (
          <CongestionZoneForm {...restProps} />
        ) : (
          <UpdateCongestionZone
            chargeZoneId={zoneId}
            setNotFound={setNotFound}
            setCongestionZone={setCongestionZone}
            setPostcodes={setPostcodes}
            {...restProps}
          />
        )}
      </Grid>
    </Grid>
  )
}
