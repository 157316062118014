import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { Avatar, Box, Typography } from "@mui/material"

const SignUpSuccess = () => {

  return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Avatar sx={{ backgroundColor: (theme) => theme.palette.success.main }}>
      <CheckCircleOutlineOutlinedIcon />
    </Avatar>

    <Typography fontFamily='Goldplay-Medium, sans-serif' component='h1' variant='h3'>
      Success.
    </Typography>

    <Typography fontFamily='Goldplay-Medium, sans-serif' component='h2' variant='h4' align='center'>
      Please check your email inbox for the verification action.
    </Typography>
  </Box>
}

export default SignUpSuccess
