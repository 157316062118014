import { gql } from '@apollo/client'

export const StandardOperatingProcedureDetailsFragment = gql`
  fragment StandardOperatingProcedureDetails on StandardOperatingProcedure {
    name
    isActive
    description
    steps
    frequentlyAskedQuestions
    notes
    driverInstructions
    isAttachedToAllCustomers

    customers {
      id

      customer {
        id
        name
      }
    }
  }
`
