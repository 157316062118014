import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledTextField } from 'components/common'
import { BookingStatus } from 'generated/graphql'
import { TBookingForm } from 'types'

interface IProps {
  disabled: boolean
}

const CustomerReference = (props: IProps) => {
  const { disabled } = props

  const {
    control,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const formValues = useWatch({ name: ['status', 'isReferenceRequired'] })
  const acceptableStatuses = [
    BookingStatus.ProofOfDelivery,
    BookingStatus.Complete,
    BookingStatus.Invoice,
    BookingStatus.ScheduledTemplate,
  ]

  const isRequired = acceptableStatuses.some((s) => s === formValues[0]) && formValues[1] === true

  return (
    <ControlledTextField
      control={control}
      label='Cust. Ref. / PO number'
      name='customerReference'
      required={isRequired}
      disabled={disabled}
      error={!!errors.customerReference}
      helperText={errors.customerReference?.message}
    />
  )
}

export default CustomerReference
