import { ReactNode, FocusEvent, ChangeEvent } from 'react'

import { InputAdornment, SxProps, TextField, Theme } from '@mui/material'

interface IProps {
  name?: string
  label: string
  register?: any
  onBlur?: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  value?: any
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  defaultValue?: any
  variant?: 'outlined' | 'standard' | 'filled'
  readOnly?: boolean
  disabled?: boolean
  required?: boolean
  multiline?: boolean
  rows?: number
  helperText?: any
  startAdornment?: string | ReactNode
  endAdornment?: string | ReactNode
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
}
// ToDo: add loading state
const TextInput = (props: IProps) => {
  const {
    label,
    value = '',
    onChange,
    name,
    defaultValue,
    variant = 'outlined',
    readOnly,
    disabled,
    register,
    startAdornment,
    endAdornment,
    ...otherProps
  } = props

  return (
    <TextField
      value={value}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        onChange && onChange(event)
      }
      label={label}
      defaultValue={defaultValue}
      variant={variant}
      name={name}
      InputProps={{
        readOnly,
        startAdornment: startAdornment && (
          <InputAdornment position='start'>{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position='end'>{endAdornment}</InputAdornment>
        ),
      }}
      disabled={disabled}
      inputRef={register}
      fullWidth
      {...otherProps}
    />
  )
}

export { TextInput }
