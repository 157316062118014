import { LoadingButton } from '@mui/lab'
import { Box, Divider, Paper } from '@mui/material'
import { toast } from 'react-toastify'

import { CardTitle } from 'components/common'
import {
  useUpdateAllBookingEtasMutation,
  useVerifyAddressDeliveryMutation,
} from 'generated/graphql'

const OtherMiscellaneous = () => {
  const [updateAllBookingEtas, { loading: updateAllBookingEtasLoading }] =
    useUpdateAllBookingEtasMutation({
      onCompleted: (data) => {
        if (!data) {
          toast.error('Something went wrong.')
          return
        }

        toast.success('All Booking Etas was updated with success.')
      },
    })

  const [verifyAddressDelivery, { loading: verifyAddressDeliveryLoading }] =
    useVerifyAddressDeliveryMutation({
      onCompleted: (data) => {
        if (!data.verifyAddressDelivery) {
          toast.error('Something went wrong.')
        } else {
          toast.success('Verification done with success')
        }
      },
    })

  const handleUpdateAllBookingEtas = () => {
    updateAllBookingEtas()
  }

  const handleAddressVerification = () => {
    verifyAddressDelivery()
  }

  return (
    <Paper variant='outlined'>
      <Box p={2}>
        <CardTitle>Booking List</CardTitle>
        <LoadingButton
          color='grey'
          variant='contained'
          loading={updateAllBookingEtasLoading}
          onClick={handleUpdateAllBookingEtas}>
          Update All Etas
        </LoadingButton>
      </Box>
      <Divider />
      <Box p={2}>
        <CardTitle>Booking address</CardTitle>

        <LoadingButton
          color='grey'
          variant='contained'
          loading={verifyAddressDeliveryLoading}
          onClick={handleAddressVerification}>
          Check late drivers
        </LoadingButton>
      </Box>
    </Paper>
  )
}

export default OtherMiscellaneous
