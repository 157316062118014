import { gql } from '@apollo/client'

import { TaskDetailsFragment } from 'graphql/fragments'

export const CREATE_TASK_MUTATION = gql`
  mutation createTask($input: CreateUpdateTaskDtoInput!){
    createTask(input: $input){
      id

      ...TaskDetails
    }

    ${TaskDetailsFragment}
  }
`

export const UPDATE_TASK_MUTATION = gql`
  mutation updateTask($input: CreateUpdateTaskDtoInput!){
    updateTask(input: $input){
      id

      ...TaskDetails
    }
    
    ${TaskDetailsFragment}
  }
`

export const DELETE_TASK_MUTATION = gql`
  mutation deleteTask($id: Long!) {
    deleteTask(id: $id)
  }
`
