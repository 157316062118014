import { MouseEventHandler } from 'react'

import { Close } from '@mui/icons-material'
import { Badge, BadgeProps, IconButton, styled, Tab } from '@mui/material'

const StyledBadge = styled(Badge)<BadgeProps>({
  '& .MuiBadge-badge': {
    right: -7,
  },
})

function a11yProps(name, index) {
  return {
    id: `${name}-tab-${index}`,
    'aria-controls': `${name}-tabpanel-${index}`,
  }
}

interface IProps {
  value: any
  label: string
  name: string
  closeIconDisabled: boolean
  showRemoveButton?: boolean
  errorBadge?: boolean
  onCloseClick: MouseEventHandler<HTMLButtonElement>
}

const FplDynamicTab = styled((props: IProps) => {
  const {
    value,
    label,
    name,
    closeIconDisabled,
    showRemoveButton = true,
    errorBadge,
    onCloseClick,
    ...otherProps
  } = props

  return (
    <Tab
      value={value}
      component='div'
      disableRipple
      iconPosition='end'
      icon={
        showRemoveButton ? (
          <IconButton
            size='small'
            aria-label='close'
            disabled={closeIconDisabled}
            onClick={onCloseClick}>
            <Close />
          </IconButton>
        ) : undefined
      }
      label={
        <StyledBadge badgeContent='!' color='error' invisible={!errorBadge}>
          {label}
        </StyledBadge>
      }
      {...otherProps}
      {...a11yProps(name, value)}
    />
  )
})(({ theme }) => ({
  '&.MuiTab-labelIcon': {
    minHeight: theme.spacing(6),
  },
  '& .MuiTab-iconWrapper': {
    marginLeft: theme.spacing(2),
  },
}))

export default FplDynamicTab
