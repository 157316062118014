import { cyan, deepOrange, grey, lightGreen, orange, purple } from '@mui/material/colors'
import { alpha, createTheme } from '@mui/material/styles'

// Tools:
// - https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=616161&primary.color=FFC107
// - https://www.materialui.co/colors

// const themeAgnostic = {
//   primary: {
//     main: purple[500],
//   },
//   secondary: {
//     main: grey[700],
//   },
//   error: {
//     main: deepOrange.A400,
//   },
//   warning: {
//     main: orange.A400,
//   },
//   info: {
//     main: cyan[500],
//   },
//   success: {
//     main: lightGreen[500],
//   },
// }

// export const AppTheme = {
//   DARK: 'dark',
//   LIGHT: 'light',
// }

// export const AppThemeOptions = {
//   ['light']: {
//     palette: {
//       type: 'light',
//       drawer: {
//         main: grey[200],
//       },

//       ...themeAgnostic,
//     },
//   },
//   ['dark']: {
//     palette: {
//       type: 'dark',
//       drawer: {
//         main: grey[900],
//       },

//       ...themeAgnostic,
//     },
//   },
// }

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
  }
}

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    drawer: {
      main: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    drawer?: {
      main?: string
    }
  }
}

const BaseTheme = createTheme({
  // ToDo: add readOnly color to palette
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: grey[700],
    },
    error: {
      main: deepOrange.A400,
    },
    warning: {
      main: orange.A400,
    },
    info: {
      main: cyan[500],
    },
    success: {
      main: lightGreen[500],
    },
    grey: {
      main: grey[300],
      dark: grey[400],
    },
  },
})

const AppTheme = createTheme(BaseTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: BaseTheme.palette.getContrastText(BaseTheme.palette.grey.main),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: BaseTheme.palette.text.primary,
            borderColor:
              BaseTheme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${BaseTheme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                BaseTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                BaseTheme.palette.text.primary,
                BaseTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
        {
          props: { color: 'grey', variant: 'text' },
          style: {
            color: BaseTheme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                BaseTheme.palette.text.primary,
                BaseTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.InputProps?.readOnly && {
            backgroundColor: purple[50],
          }),
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ ownerState }) => ({
          ...(ownerState?.readOnly && {
            backgroundColor: purple[50],
            cursor: 'default',
          }),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.readOnly && {
            backgroundColor: purple[50],
            cursor: 'default',
          }),
        }),
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '.MuiMultiSectionDigitalClock-root': {
            '.MuiList-root': {
              width: '100%',
              '.MuiMenuItem-root': {
                padding: '8px 35px',
              },
            },
          },
        },
      },
    },
  },
})

export { AppTheme }
