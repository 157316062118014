import { format, parseJSON } from 'date-fns'

import { IGridColumn } from 'components/common'
import { DATE_FORMAT } from 'constants/DateFormats'
import { numberFormat } from 'helpers/numberFormat'

function useGetAccountsPayableCompactedColumns() {
  const columns: IGridColumn[] = [
    {
      field: 'SuplierName',
      headerName: 'Supplier (ID)',
      sortPath: ['bookingInvoice', 'bookingDriver', 'driver', 'name'],
      flex: 0.2,
      valueGetter(params) {
        return (
          params.row.bookingInvoice.bookingDriver.driver.name +
          ' (' +
          params.row.bookingInvoice.bookingDriver.driver.id +
          ')'
        )
      },
    },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice No.',
      sortPath: ['bookingInvoice', 'driverInvoiceNumber'],
      flex: 0.2,
      valueGetter(params) {
        return params.row.bookingInvoice.driverInvoiceNumber
      },
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      sortPath: ['bookingInvoice', 'invoiceDate'],
      flex: 0.3,
      valueGetter(params) {
        const invoiceDate = params.row.bookingInvoice.invoiceDate
        return invoiceDate ? format(parseJSON(invoiceDate as string), DATE_FORMAT) : ''
      },
    },
    {
      field: 'ourReference',
      headerName: 'FPL Number',
      sortable: false,
      flex: 0.3,
      valueGetter(params) {
        return params.row.bookingInvoice.booking.ourReference
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due date',
      sortPath: ['bookingInvoice', 'dueDate'],
      flex: 0.3,
      renderCell(params) {
        const dueDate = params.row.bookingInvoice.dueDate
        return (
          <div style={{ color: 'red' }}>
            {dueDate ? format(parseJSON(params.row.bookingInvoice.dueDate), DATE_FORMAT) : ''}
          </div>
        )
      },
    },
    {
      field: 'paymentDate',
      headerName: 'Payment date',
      sortPath: ['bookingInvoice', 'paymentDate'],
      flex: 0.3,
      renderCell(params) {
        const paymentDate = params.row.bookingInvoice.paymentDate
        return paymentDate ? format(parseJSON(paymentDate as string), DATE_FORMAT) : ''
      },
    },
    {
      field: 'net',
      sortable: false,
      headerName: 'Net Total',
      flex: 0.2,
      valueGetter(params) {
        return params.row.currency?.symbol + numberFormat(params.row.net, 0, 2)
      },
    },
    {
      field: 'vat',
      sortable: false,
      headerName: 'VAT',
      flex: 0.2,
      valueGetter(params) {
        return params.row.currency?.symbol + numberFormat(params.row.vat, 0, 2)
      },
    },
    {
      field: 'gross',
      sortable: false,
      headerName: 'Gross total',
      flex: 0.2,
      valueGetter(params) {
        return params.row.currency?.symbol + numberFormat(params.row.gross, 0, 2)
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.3,
      renderCell(params) {
        return <div style={{ color: 'red' }}>{params.row.statusLabel}</div>
      },
    },
  ]
  return [columns]
}

export default useGetAccountsPayableCompactedColumns
