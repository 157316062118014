import { LoadingBackdrop } from 'components/common'
import { Address, CreateAndUpdateAddressDtoInput, useGetAddressQuery } from 'generated/graphql'

import AddressForm from './createUpdateAddress/AddressForm'

interface IProps {
  addressId: number
  address: Address
  setAddress: any
}

const UpdateAddress = (props: IProps) => {
  const { addressId, address, setAddress } = props

  const { loading: addressLoading } = useGetAddressQuery({
    variables: {
      id: addressId,
    },
    onCompleted: (data) => {
      const { __typename, ...address } = data?.address as Address
      setAddress(address as CreateAndUpdateAddressDtoInput)
    },
  })

  const isLoading = addressLoading || !address.id

  return (
    <>
      <AddressForm />
      <LoadingBackdrop loading={isLoading} />
    </>
  )
}

export default UpdateAddress
