import { useState } from 'react'

import { AppBar, Tab, Tabs } from '@mui/material'

import { TabPanel } from 'components/common'

import { AllUsers } from './allUsers'
import { PendingApproval } from './pendingApproval'

enum TabId {
  PendingApproval = 0,
  AllUsers,
}

const tabs = [
  { id: TabId.PendingApproval, label: 'Pending Approval' },
  { id: TabId.AllUsers, label: 'All Users', hash: '#all-users' },
]

function commonProps(index: TabId) {
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  }
}

const UsersTab = () => {
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='user tabs'
          TabIndicatorProps={{
            style: {
              transition: 'none',
            },
          }}>
          {tabs.map((tab) => (
            <Tab label={tab.label} key={tab.id} {...commonProps(tab.id)} />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={TabId.PendingApproval}>
        <PendingApproval />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.AllUsers}>
        <AllUsers />
      </TabPanel>
    </div>
  )
}

export default UsersTab
