import { getBookingAddressDefaultValues } from 'components/bookings/BookingDetails/AddressDetails'
import { BookingPackageDefaultValues } from 'components/bookings/BookingDetails/PackageDetails'
import {
  BookingAddressType,
  CreateAndUpdateAddressDtoInput,
  CreateAndUpdateBookingCombinedChargeDtoInput,
  CreateAndUpdateBookingCombinedDtoInput,
  CreateAndUpdateBookingCombinedPackageDtoInput,
  GetBookingQuery,
  GetUserBookingQuery,
  PackagingType,
} from 'generated/graphql'
import {
  TBookingAddressForm,
  TBookingChargeForm,
  TBookingForm,
  TBookingPackageForm,
} from 'types/form-types'

const normalizeBookingAddressForm = (
  booking:
    | NonNullable<GetBookingQuery['booking']>
    | NonNullable<GetUserBookingQuery['userBooking']>,
  addressType: BookingAddressType,
): Array<TBookingAddressForm> => {
  const bookingAddresses = booking.addresses
    .filter((address) => address.type === addressType)
    .sort((a, b) => a.sequenceOrder - b.sequenceOrder)

  if (bookingAddresses.length === 0) {
    return [getBookingAddressDefaultValues(0)]
  } else {
    return bookingAddresses.map((bookingAddress) => ({
      id: bookingAddress.id,
      at: bookingAddress.at,
      by: bookingAddress.by,
      name: bookingAddress.address.name,
      city: bookingAddress.address.city,
      addressLine1: bookingAddress.address.addressLine1,
      addressLine2: bookingAddress.address.addressLine2 ?? '',
      postcode: bookingAddress.address.postcode,
      countryId: bookingAddress.address.countryId,
      contact: bookingAddress.address.contact ?? '',
      email: bookingAddress.address.email ?? '',
      telephoneNumber: bookingAddress.address.telephoneNumber ?? '',
      note: bookingAddress.note ?? '',
      reasonCodeId: bookingAddress.reasonCodeId ?? 0,
      sequenceOrder: bookingAddress.sequenceOrder,
      isVerified: bookingAddress.address.isVerified,
    }))
  }
}

const normalizeBookingPackagesForm = (
  booking:
    | NonNullable<GetBookingQuery['booking']>
    | NonNullable<GetUserBookingQuery['userBooking']>,
): Array<TBookingPackageForm> => {
  const bookingPackages = booking.packages.slice().sort((a, b) => Number(a.id) - Number(b.id))

  if (bookingPackages.length === 0) {
    return [BookingPackageDefaultValues]
  } else {
    return bookingPackages.map((bookingPackage) => ({
      id: bookingPackage.id,
      quantity: bookingPackage.quantity,
      packageType: bookingPackage.type ?? PackagingType.Packages,
      collectionSequenceOrder: bookingPackage.collectionSequenceOrder ?? '',
      deliverySequenceOrder: bookingPackage.deliverySequenceOrder ?? '',
      length: bookingPackage.length,
      width: bookingPackage.width,
      height: bookingPackage.height,
      weight: bookingPackage.weight,
      reference: bookingPackage.packageReference ?? '',
      details: bookingPackage.name,
    }))
  }
}

const normalizeBookingChargesForm = (
  booking:
    | NonNullable<GetBookingQuery['booking']>
    | NonNullable<GetUserBookingQuery['userBooking']>,
): Array<TBookingChargeForm> => {
  const bookingCharges = booking.charges

  if (bookingCharges.length === 0) {
    return []
  } else {
    return bookingCharges.map((bookingCharge) => ({
      id: bookingCharge.id,
      name: bookingCharge.name,
      type: bookingCharge.type,
      quantity: bookingCharge.quantity,
      rate: bookingCharge.rate,
      description: bookingCharge.description ?? '',
      xeroServiceCode: bookingCharge.xeroServiceCode ?? '',
      vatCategoryId: bookingCharge.vatCategoryId ?? '',
    }))
  }
}

export const normalizeBookingForm = (
  booking:
    | NonNullable<GetBookingQuery['booking']>
    | NonNullable<GetUserBookingQuery['userBooking']>,
): TBookingForm => {
  const collectionAddresses = normalizeBookingAddressForm(booking, BookingAddressType.Collection)
  const deliveryAddresses = normalizeBookingAddressForm(booking, BookingAddressType.Delivery)

  const packages = normalizeBookingPackagesForm(booking)
  const charges = normalizeBookingChargesForm(booking)

  return {
    customerId: String(booking.customerId),
    customerReference: booking.customerReference ?? '',
    ourReference: booking.ourReference,
    contactId: String(booking.contactId),
    overrideContactEmail: booking.overrideContactEmail ?? '',
    overrideContactTelephoneNumber: booking.overrideContactTelephoneNumber ?? '',
    validUntil: booking.validUntil,
    collectionAddresses,
    deliveryAddresses,
    distance: booking.distance,
    packages,
    tariffId: String(booking.tariffId),
    isWaitAndReturn: booking.isWaitAndReturn,
    charges,
    isBiddingEnabled: booking.isBiddingEnabled,
    noteOrSpecialInstruction: booking.noteOrSpecialInstruction ?? '',
    alternativeOurReference: booking.alternativeOurReference ?? '',
    consignmentFee: booking.consignmentFee,
    isEmailNotification: booking.isEmailNotification,
    isTransitIgnored: booking.isTransitIgnored,
    serviceType: booking.tariff?.xeroCode?.serviceType,
    //validation fields
    status: booking.status,
    isReasonCodeEnabled: booking.customer.isReasonCodeOptionEnabled,
    isReferenceRequired: booking.customer.isReferenceRequired,
    isPackageDetailsSectionOptional: booking.customer.isPackageDetailsOptional,
  }
}

function normalizeAddressDto(
  bookingAddress: TBookingAddressForm,
  customerId,
  isQuickQuote,
): CreateAndUpdateAddressDtoInput {
  const name = !(isQuickQuote && !bookingAddress.name) ? bookingAddress.name : ''
  const city = !(isQuickQuote && !bookingAddress.city) ? bookingAddress.city : ''
  const addressLine1 = !(isQuickQuote && !bookingAddress.addressLine1)
    ? bookingAddress.addressLine1
    : ''
  const addressLine2 = !(isQuickQuote && !bookingAddress.addressLine2)
    ? bookingAddress.addressLine2
    : ''
  const contact = !(isQuickQuote && !bookingAddress.contact) ? bookingAddress.contact : ''
  const email = !(isQuickQuote && !bookingAddress.email) ? bookingAddress.email : ''
  const telephoneNumber = !(isQuickQuote && !bookingAddress.telephoneNumber)
    ? bookingAddress.telephoneNumber
    : ''

  return {
    name,
    city,
    addressLine1,
    addressLine2,
    postcode: bookingAddress.postcode,
    countryId: bookingAddress.countryId,
    contact,
    email,
    telephoneNumber,
    customerId: Number(customerId),
    isAutoFill: false,
    isDefault: false,
    isNotification: false,
  }
}

// ToDo: remove 'isQuote' after quote address changes confirmed
export function normalizeBookingAddressesDto(
  formData: TBookingForm,
  bookingId,
  dirtyFields,
  isQuickQuote = false,
) {
  const { collectionAddresses, deliveryAddresses, customerId } = formData
  const addressesList: CreateAndUpdateBookingCombinedDtoInput['addressesList'] = []

  if (dirtyFields.collectionAddresses) {
    const collectionAddressesNormalized = collectionAddresses?.map((collectionAddress) => {
      return {
        id: collectionAddress.id || undefined,
        bookingId: bookingId || undefined,
        type: BookingAddressType.Collection,
        at: collectionAddress.at,
        by: collectionAddress.by,
        sequenceOrder: collectionAddress.sequenceOrder,
        note: collectionAddress.note,
        addressData: normalizeAddressDto(collectionAddress, customerId, isQuickQuote),
      }
    })

    addressesList.push(...collectionAddressesNormalized)
  }

  if (dirtyFields.deliveryAddresses) {
    const deliveryAddressesNormalized = deliveryAddresses?.map((deliveryAddress) => {
      return {
        id: deliveryAddress.id || undefined,
        bookingId: bookingId || undefined,
        type: BookingAddressType.Delivery,
        at: deliveryAddress.at,
        by: deliveryAddress.by,
        sequenceOrder: deliveryAddress.sequenceOrder,
        note: deliveryAddress.note,
        addressData: normalizeAddressDto(deliveryAddress, customerId, isQuickQuote),
        reasonCodeId: deliveryAddress.reasonCodeId || undefined,
      }
    })

    addressesList.push(...deliveryAddressesNormalized)
  }

  return addressesList
}

export function normalizeBookingPackagesDto(packages: Array<TBookingPackageForm>, bookingId) {
  const packagesList: CreateAndUpdateBookingCombinedDtoInput['packagesList'] = []

  const packagesNormalized = packages?.map((packageItem) => {
    const {
      id,
      quantity,
      packageType,
      height,
      width,
      weight,
      length,
      details,
      reference,
      ...rest
    } = packageItem

    return {
      id: id || undefined,
      bookingId: bookingId || undefined,
      quantity: Number(quantity),
      type: packageType,
      height: Number(height),
      width: Number(width),
      weight: Number(weight),
      length: Number(length),
      packageReference: reference,
      name: details,
      ...rest,
    } as CreateAndUpdateBookingCombinedPackageDtoInput
  })

  packagesList.push(...packagesNormalized)

  return packagesList.filter((p) => p.quantity && p.quantity !== 0)
}

export function normalizeBookingChargesDto(charges, bookingId) {
  const chargesList: CreateAndUpdateBookingCombinedDtoInput['chargesList'] = []

  const chargesNormalized = charges?.map((chargeItem) => {
    const { id, quantity, rate, xeroServiceCode, vatCategoryId, ...rest } = chargeItem

    return {
      id: id || undefined,
      bookingId: bookingId || undefined,
      quantity: quantity && Number(quantity),
      rate: rate && Number(rate),
      xeroServiceCode: xeroServiceCode ? xeroServiceCode : undefined,
      vatCategoryId: vatCategoryId ? Number(vatCategoryId) : undefined,
      ...rest,
    } as CreateAndUpdateBookingCombinedChargeDtoInput
  })

  chargesList.push(...chargesNormalized)

  return chargesList
}
