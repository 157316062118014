import { AccountPayableTabHash } from 'constants/AccountPayableStatusCategories'

import DownloadIcon from '@mui/icons-material/Download'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import { Box, Button, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/styles'
import { GridRowId } from '@mui/x-data-grid'

const StyledTypography = styled(Typography)({
  fontSize: '13px',
  color: 'black',
})

const StyledDownloadIcon = styled(DownloadIcon)({
  fontSize: '13px',
})

const StyledNoteAltIcon = styled(NoteAltIcon)({
  fontSize: '13px',
})

interface IProps {
  handleOpenModal: any
  handleOpenManagePaymentShedule: any
  selectedItems?: GridRowId[]
}

const ActionOptions = ({
  handleOpenModal,
  handleOpenManagePaymentShedule,
  selectedItems = [],
}: IProps) => {
  const tab = window.location.href.split('#')[1]

  const isNotUnderReviewTab = tab !== AccountPayableTabHash.UnderReview
  const isFlagged = tab === AccountPayableTabHash.Flagged
  const disabledOption = selectedItems?.length === 0

  return (
    <Box
      className='d-flex'
      sx={{
        p: 1,
        mb: 1,
        border: 1,
        borderColor: grey[300],
        borderRadius: '5px',
      }}>
      <Button>
        <StyledDownloadIcon />
        <StyledTypography>Export</StyledTypography>
      </Button>
      <Button>
        <StyledDownloadIcon />
        <StyledTypography>Download Documents</StyledTypography>
      </Button>
      <Button>
        <StyledNoteAltIcon />
        <StyledTypography>Send Reminder</StyledTypography>
      </Button>
      {!isNotUnderReviewTab && (
        <Button onClick={handleOpenModal} disabled={disabledOption}>
          <StyledNoteAltIcon />
          <StyledTypography>Smart Approve</StyledTypography>
        </Button>
      )}
      <Button>
        <StyledNoteAltIcon />
        <StyledTypography>Schedule for payment</StyledTypography>
      </Button>
      {(!isNotUnderReviewTab || isFlagged) && (
        <Button onClick={handleOpenManagePaymentShedule} disabled={disabledOption}>
          <StyledNoteAltIcon />
          <StyledTypography>Manage payment schedule</StyledTypography>
        </Button>
      )}
      <Button>
        <StyledNoteAltIcon />
        <StyledTypography>Mark as paid</StyledTypography>
      </Button>
      <Button>
        <StyledNoteAltIcon />
        <StyledTypography>Send to xero</StyledTypography>
      </Button>
      <Button>
        <StyledNoteAltIcon />
        <StyledTypography>Archive</StyledTypography>
      </Button>
      <Button>
        <StyledTypography>Batch invoice</StyledTypography>
      </Button>
    </Box>
  )
}

export default ActionOptions
