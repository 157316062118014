import React from 'react'

import { Box, Grid } from '@mui/material'
import { Control } from 'react-hook-form'

import { ControlledTextField, TextInput } from 'components/common'

interface IProps {
  field: Record<'packagesKey', string>
  disabled: boolean
  control: Control<
    {
      selectedAddressId: string
    },
    any
  >
  index: number
  errors: any
  getPackageName: (packageId: string) => string
}

function PackagesDetails(props: IProps) {
  const { field, disabled, control, index, errors, getPackageName } = props
  return (
    <React.Fragment key={field.packagesKey}>
      <Grid item xs={12} sm={2}>
        <TextInput
          name='addressDetails'
          label='Package'
          // @ts-ignore
          value={getPackageName(field.packageId)}
          readOnly
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <TextInput
          name='totalPackages'
          label='Total Packages'
          // @ts-ignore
          value={field.totalPackages}
          readOnly
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <ControlledTextField
          control={control}
          name={`packages[${index}].quantity`}
          label='Processed Packages'
          // @ts-ignore
          defaultValue={field.quantity}
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          min='0'
          required
          disabled={disabled}
          error={!!errors.packages?.[index]?.quantity}
          helperText={errors.packages?.[index]?.quantity?.message}
        />
      </Grid>

      {/* This is hidden elements for RHF registration */}
      <Box hidden>
        <ControlledTextField
          control={control}
          name={`packages[${index}].packageId`}
          label=''
          // @ts-ignore
          defaultValue={field.packageId}
        />
      </Box>
    </React.Fragment>
  )
}

export default PackagesDetails
