import React from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useForm, useFormContext } from 'react-hook-form'

import { ControlledCheckbox, SplitButton, ControlledMultipleAutocomplete } from 'components/common'
import { ReportTypeButtonsId, ReportTypeButtonValues } from 'constants/EntityConstants'
import { GetCustomersQuery } from 'generated/graphql'

interface IProps {
  customers: GetCustomersQuery['customers'] | undefined
  customersLoading: boolean
  isProcessing: boolean
  onReportButtonClick: (buttonId: ReportTypeButtonsId) => void
  onClearAll: () => void
}

const FilterCriteriaForm = (props: IProps) => {
  const { customers, customersLoading, isProcessing, onReportButtonClick, onClearAll } = props

  const { control, setValue } = useFormContext()
  const { control: showAllCustomersControl, watch: showAllCustomersWatch } = useForm({
    shouldUnregister: true,
    defaultValues: { isShowAllCustomers: true },
  })

  const isShowAllCustomers = showAllCustomersWatch('isShowAllCustomers')

  React.useEffect(() => {
    isShowAllCustomers && setValue('customerIds', [])
  }, [isShowAllCustomers, setValue])

  const customersItems = React.useMemo(
    () =>
      customers?.map((customer) => ({
        value: customer.id,
        label: customer.name,
      })) || [],
    [customers],
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        {customersLoading ? (
          <Box mt={-1.25} mb={-1}>
            <Typography variant='h2'>
              <Skeleton animation='wave' />
            </Typography>
          </Box>
        ) : (
          <ControlledMultipleAutocomplete
            control={control}
            name='customerIds'
            label='Customers'
            options={customersItems}
            disabled={isShowAllCustomers}
            limitTags={3}
            defaultValue={[]}
          />
        )}
      </Grid>

      <Grid item xs={2}>
        <Box display='flex' alignItems='center' height='100%'>
          <ControlledCheckbox
            name='isShowAllCustomers'
            label='All Customers'
            control={showAllCustomersControl}
          />
        </Box>
      </Grid>

      <Grid item xs={8}>
        <Grid container spacing={3} item justifyContent='flex-end'>
          <Grid item>
            <Box display='flex' justifyContent='center'>
              <Button variant='contained' color='grey' onClick={onClearAll}>
                Clear All
              </Button>
            </Box>
          </Grid>

          <Grid item>
            <SplitButton
              options={ReportTypeButtonValues}
              loading={isProcessing}
              onButtonClick={onReportButtonClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FilterCriteriaForm
