import { useCallback, useMemo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { Paths, DEBOUNCE_DELAY } from 'constants/index'
import { TariffPagedDtoFilterInput, useDeleteCustomerTariffMutation } from 'generated/graphql'
import { GET_PAGED_TARIFFS } from 'graphql/queries'

import TariffDetails from './TariffDetails'

interface IProps {
  customerId: number
}

const CustomerTariffs = (props: IProps) => {
  const { customerId } = props
  const history = useHistory()
  const methods = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
      defaultTariff: '',
    },
  })
  const { control, watch, setValue } = methods

  // ToDo: set default Tariff after defaultTariff is changed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchInput] = useDebounce(watch('search'), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  const [deleteTariff, { loading: deleting }] = useDeleteCustomerTariffMutation()

  const handleCreateTariff = () => history.push(Paths.tariffs.create)

  // ToDo: add confirmation dialog
  const handleDeleteTariff = useCallback(
    (id: GridRowId) => () => {
      deleteTariff({
        variables: {
          customerId,
          tariffId: Number(id),
        },
      }).then((response) => {
        if (response.data) {
          toast.success('Tariff was successfully deleted!')
        }
      })
    },
    [customerId, deleteTariff],
  )

  const handleEditTariff = useCallback(
    (id: GridRowId) => () => {
      history.push(Paths.tariffs.updateWithId(id))
    },
    [history],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'category', headerName: 'Category', minWidth: 200, flex: 1 },
      { field: 'type', headerName: 'Type', width: 150 },
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'minMiles', headerName: 'Min. Miles', width: 150, type: 'number' },
      { field: 'rate', headerName: 'Rate (£)', width: 150, type: 'number' },
      { field: 'minCharge', headerName: 'Min. Charge (£)', width: 150, type: 'number' },
      { field: 'baseUnit', headerName: 'Base Unit', width: 150 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Edit'
            size='medium'
            disabled={deleting}
            icon={
              <Tooltip title={'Edit'} arrow>
                <EditIcon />
              </Tooltip>
            }
            onClick={handleEditTariff(id)}
          />,
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='medium'
            disabled={deleting}
            icon={
              <Tooltip title={'Delete'} arrow>
                <RemoveCircleIcon />
              </Tooltip>
            }
            onClick={handleDeleteTariff(id)}
          />,
        ],
      },
    ],
    [deleting, handleDeleteTariff, handleEditTariff],
  )

  const filter: TariffPagedDtoFilterInput = {
    or: [{ name: { contains: searchValue } }, { category: { contains: searchValue } }],
  }

  return (
    <FormProvider {...methods}>
      <TariffDetails customerId={customerId} />

      <Box marginTop={2}>
        <FplDataGrid
          query={GET_PAGED_TARIFFS}
          queryVariables={{ customerId }}
          entityName='pagedTariffs'
          columns={columns}
          filter={filter}
          defaultOrder={{ field: 'id', sort: 'asc' }}
          toolbar={{
            caption: 'Tariffs',
            leftSide: (
              <Grid item>
                <Button variant='outlined' size='small' onClick={handleCreateTariff}>
                  New Tariff
                </Button>
              </Grid>
            ),
            rightSide: (
              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            ),
          }}
        />
      </Box>
    </FormProvider>
  )
}

export default CustomerTariffs
