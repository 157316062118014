import Joi from 'joi'

import { DEFAULT_COUNTRY_ID, UK_POSTCODE_REGEX } from 'constants/index'
import { TBookingAddressForm } from 'types'

const validatePostcode = (value: string | undefined, helpers: Joi.CustomHelpers<string>) => {
  // UK Validation
  if (value?.length === 0) {
    return value
  }

  const addressDetails = helpers.state.ancestors[0] as TBookingAddressForm
  //!value?.match(UK_POSTCODE_REGEX)
  if (
    Number(addressDetails.countryId) === DEFAULT_COUNTRY_ID &&
    !UK_POSTCODE_REGEX.test(value || '')
  ) {
    return helpers.error('string.pattern.base')
  }

  return value
}

export { validatePostcode }
