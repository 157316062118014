import { useState, useEffect, useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledDateTimePicker, ControlledSelector } from 'components/common'
import { CardTitle } from 'components/common/controls'
import {
  DeliverTimeRangeTypeValues,
  PickupTimeRangeTypeValues,
  StopsTimeRangeTypeValues,
} from 'constants/EntityConstants/CourierExchange'
import {
  DeliverTimeRangeType,
  GetBookingQuery,
  PickupTimeRangeType,
  StopTimeRangeType,
} from 'generated/graphql'

interface IProps {
  bookingDetails?: GetBookingQuery['booking']
}

const ServiceDetails = (props: IProps) => {
  const { bookingDetails } = props
  const [pickupAddress, setPickupAddress] =
    useState<NonNullable<GetBookingQuery['booking']>['addresses'][0]>()
  const [stopAddresses, setStopAddresses] = useState<
    NonNullable<GetBookingQuery['booking']>['addresses']
  >([])
  const [deliverAddress, setDeliverAddress] =
    useState<NonNullable<GetBookingQuery['booking']>['addresses'][0]>()

  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitted, errors },
  } = useFormContext()

  const pickupTimeRangeValue = useWatch({ name: 'pickupTimeRange' })
  const deliverTimeRangeValue = useWatch({ name: 'deliverTimeRange' })
  const stopTimeDetailsValue = useWatch({ name: 'stopTimeDetails' })

  const isReadyAtDisabled = useMemo(() => {
    return pickupTimeRangeValue === PickupTimeRangeType.Before
  }, [pickupTimeRangeValue])

  const isCollectByDisabled = useMemo(() => {
    return pickupTimeRangeValue === PickupTimeRangeType.At
  }, [pickupTimeRangeValue])

  const isDeliverFromDisabled = useMemo(() => {
    return (
      deliverTimeRangeValue === DeliverTimeRangeType.Asap ||
      deliverTimeRangeValue === DeliverTimeRangeType.By
    )
  }, [deliverTimeRangeValue])

  const isDeliverByDisabled = useMemo(() => {
    return (
      deliverTimeRangeValue === DeliverTimeRangeType.Asap ||
      deliverTimeRangeValue === DeliverTimeRangeType.At
    )
  }, [deliverTimeRangeValue])

  // ToDo: may be do this calculations using "reset" method
  useEffect(() => {
    if (bookingDetails) {
      const addresses = bookingDetails.addresses
        .slice()
        .sort((a, b) => a.sequenceOrder - b.sequenceOrder)

      const stopAddresses = addresses.slice(1, addresses.length - 1)

      setPickupAddress(addresses[0])
      setStopAddresses(stopAddresses)
      setDeliverAddress(addresses[addresses.length - 1])
    }
  }, [bookingDetails])

  useEffect(() => {
    if (pickupAddress?.at && pickupAddress?.by) {
      setValue('pickupTimeRange', PickupTimeRangeType.Between)
    } else if (pickupAddress?.at) {
      setValue('pickupTimeRange', PickupTimeRangeType.At)
    } else if (pickupAddress?.by) {
      setValue('pickupTimeRange', PickupTimeRangeType.Before)
    }

    if (deliverAddress?.at && deliverAddress?.by) {
      setValue('deliverTimeRange', DeliverTimeRangeType.Between)
    } else if (deliverAddress?.at) {
      setValue('deliverTimeRange', DeliverTimeRangeType.At)
    } else if (deliverAddress?.by) {
      setValue('deliverTimeRange', DeliverTimeRangeType.By)
    }

    stopAddresses?.forEach((_, index) => {
      setValue(`stopTimeDetails[${index}].timeRange`, StopTimeRangeType.Between)
    })

    pickupAddress?.at && setValue('readyAt', pickupAddress.at)
    pickupAddress?.by && setValue('collectBy', pickupAddress.by)
    deliverAddress?.at && setValue('deliverFrom', deliverAddress.at)
    deliverAddress?.by && setValue('deliverBy', deliverAddress.by)
  }, [pickupAddress, deliverAddress, stopAddresses, setValue])

  const stopTimeRanges = stopTimeDetailsValue?.map((stop) => stop?.timeRange)
  const stringStopTimeRanges = JSON.stringify(stopTimeRanges)

  useEffect(() => {
    if (isSubmitted) {
      trigger()
    }
  }, [pickupTimeRangeValue, deliverTimeRangeValue, stringStopTimeRanges, isSubmitted, trigger])

  const checkIfStopIsDisabled = (index: number) =>
    stopTimeDetailsValue?.[index]?.timeRange === StopTimeRangeType.Asap

  return (
    <>
      <CardTitle>Service Details</CardTitle>
      <Grid container columnSpacing={3}>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='subtitle1'>{pickupAddress?.address.name}</Typography>
        </Grid>

        <Grid item xs={3}>
          <ControlledSelector
            control={control}
            label='Pickup'
            name='pickupTimeRange'
            options={PickupTimeRangeTypeValues}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledDateTimePicker
            control={control}
            label='From'
            name='readyAt'
            defaultValue={null}
            disabled={isReadyAtDisabled}
            required={!isReadyAtDisabled}
            error={!!errors.readyAt}
            helperText={errors.readyAt?.message}
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledDateTimePicker
            control={control}
            label='To'
            name='collectBy'
            defaultValue={null}
            disabled={isCollectByDisabled}
            required={!isCollectByDisabled}
            error={!!errors.collectBy}
            helperText={errors.collectBy?.message}
          />
        </Grid>
      </Grid>

      {stopAddresses?.map((stopAddress, index) => (
        <Grid container columnSpacing={3} pt={1.5} key={stopAddress?.id}>
          <Grid
            item
            xs={3}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='subtitle1'>{stopAddress?.address?.name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <ControlledSelector
              control={control}
              label={`Stop ${index + 1}`}
              name={`stopTimeDetails[${index}].timeRange`}
              options={StopsTimeRangeTypeValues}
              defaultValue={StopTimeRangeType.Between}
              required
            />
          </Grid>

          <Grid item xs={3}>
            <ControlledDateTimePicker
              control={control}
              label='From'
              name={`stopTimeDetails[${index}].timeFrom`}
              defaultValue={stopAddress?.at}
              disabled={checkIfStopIsDisabled(index)}
              required={!checkIfStopIsDisabled(index)}
              error={!!errors.stopTimeDetails?.[index]?.timeFrom}
              helperText={errors.stopTimeDetails?.[index]?.timeFrom?.message}
            />
          </Grid>

          <Grid item xs={3}>
            <ControlledDateTimePicker
              control={control}
              label='To'
              name={`stopTimeDetails[${index}].timeTo`}
              defaultValue={stopAddress?.by}
              disabled={checkIfStopIsDisabled(index)}
              required={!checkIfStopIsDisabled(index)}
              error={!!errors.stopTimeDetails?.[index]?.timeTo}
              helperText={errors.stopTimeDetails?.[index]?.timeTo?.message}
            />
          </Grid>
        </Grid>
      ))}

      <Grid container columnSpacing={3} pt={1.5}>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='subtitle1'>{deliverAddress?.address.name}</Typography>
        </Grid>

        <Grid item xs={3}>
          <ControlledSelector
            control={control}
            label='Deliver'
            name='deliverTimeRange'
            options={DeliverTimeRangeTypeValues}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledDateTimePicker
            control={control}
            label='From'
            name='deliverFrom'
            defaultValue={null}
            disabled={isDeliverFromDisabled}
            required={!isDeliverFromDisabled}
            error={!!errors.deliverFrom}
            helperText={errors.deliverFrom?.message}
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledDateTimePicker
            control={control}
            label='To'
            name='deliverBy'
            defaultValue={null}
            disabled={isDeliverByDisabled}
            required={!isDeliverByDisabled}
            error={!!errors.deliverBy}
            helperText={errors.deliverBy?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ServiceDetails
