import { ReactElement } from 'react'

import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid'

interface IProps {
  caption?: string
  refreshButton: boolean
  leftSide?: ReactElement
  rightSide?: ReactElement
  onRefreshClick: () => void
}

const CustomToolbar = (props: IProps) => {
  const { caption, refreshButton, leftSide, rightSide, onRefreshClick } = props

  return (
    <Grid
      container
      columnSpacing={1.5}
      rowSpacing={1}
      px={1}
      pt={1}
      justifyContent={{ xs: 'space-around', sm: 'start' }}
      alignItems='center'>
      {caption && (
        <Grid item xs={12} sm='auto'>
          <Box pl={1.5} pr={2}>
            <Typography variant='h6' textAlign='center'>
              {caption}
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item pl={{ sm: '0 !important' }}>
        {/* @ts-ignore */}
        <GridToolbarColumnsButton />
      </Grid>

      <Grid item pl={{ sm: '0 !important' }}>
        {/* @ts-ignore */}
        <GridToolbarDensitySelector />
      </Grid>

      {leftSide && leftSide}

      {refreshButton && (
        <Grid item>
          <IconButton size='small' onClick={onRefreshClick}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      )}

      <Grid item xs={12} sm padding='0 !important' />

      {rightSide && rightSide}
    </Grid>
  )
}

export default CustomToolbar
