import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'

import { IPoRow, PoRow } from 'components/bookings/BookingDetails/PoTable'
import { Restricted } from 'providers'

interface IProps {
  selectedAddressId: string | undefined
  rows: Array<IPoRow>
}

const PoTable = (props: IProps) => {
  const { rows, selectedAddressId } = props

  return (
    <TableContainer style={{ height: 250 }}>
      <Table stickyHeader aria-label='pod/pob table'>
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>Date Time</TableCell>
            <TableCell>Wt. Time (Mins)</TableCell>
            <Restricted
              to='ShowInPOBAndPODWaitingTimeTableHeaderLabelsWithoutCXWord'
              fallback={
                <>
                  <TableCell>CX Items</TableCell>
                  <TableCell>CX Weight</TableCell>
                </>
              }>
              <TableCell>Items</TableCell>
              <TableCell>Weight</TableCell>
            </Restricted>

            <TableCell>Signed By</TableCell>
            <TableCell>Signature</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <PoRow selectedAddressId={selectedAddressId} row={row} key={row.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PoTable
