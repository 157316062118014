import { gql } from '@apollo/client'

export const MATCH_EXTERNAL_DRIVER_INVOICES_MUTATION = gql`
  mutation matchExternalDriverInvoice($id: Long!) {
    matchExternalDriverInvoices(fileId: $id) {
      id
      fileId
      invoiceNumber
      createdAt
    }
  }
`
