import React from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'

import ExportDriversCode from './ExportDriversCode'
import ImportDriversCode from './ImportDriversCode'
import ImportLegacyBookings from './ImportLegacyBookings'
import ImportLegacyDrivers from './ImportLegacyDrivers'

enum TabId {
  ImportLegacyDrivers = 0,
  ImportLegacyBookings,
  ImportDriversCode,
  ExportDriversCode,
}

function commonProps(index: TabId) {
  return {
    id: `settings-import-export-data-tab-${index}`,
    'aria-controls': `settings-import-export-data-tabpanel-${index}`,
  }
}

const ImportExportDataTab = () => {
  const [tabValue, setTabValue] = React.useState(0)

  const tabs = {
    importLegacyDrivers: { id: TabId.ImportLegacyDrivers, label: 'Import Legacy Drivers' },
    importLegacyBookings: { id: TabId.ImportLegacyBookings, label: 'Import Legacy Bookings' },
    importDriversCode: { id: TabId.ImportDriversCode, label: 'Import Drivers Code' },
    exportDriversCode: { id: TabId.ExportDriversCode, label: 'Export Drivers Code' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{
          boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}`,
          width: (theme) => theme.spacing(27),
        }}
        orientation='vertical'
        aria-label='settings-import-export-data-tabs'
        variant='scrollable'>
        <Tab
          label={tabs.importLegacyDrivers.label}
          key={tabs.importLegacyDrivers.id}
          {...commonProps(tabs.importLegacyDrivers.id)}
        />

        <Tab
          label={tabs.importLegacyBookings.label}
          key={tabs.importLegacyBookings.id}
          {...commonProps(tabs.importLegacyBookings.id)}
        />

        <Tab
          label={tabs.importDriversCode.label}
          key={tabs.importDriversCode.id}
          {...commonProps(tabs.importDriversCode.id)}
        />

        <Tab
          label={tabs.exportDriversCode.label}
          key={tabs.exportDriversCode.id}
          {...commonProps(tabs.exportDriversCode.id)}
        />
      </Tabs>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.importLegacyDrivers.id}
        key={tabs.importLegacyDrivers.id}
        px={3}>
        <ImportLegacyDrivers />
      </VerticalTabPanel>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.importLegacyBookings.id}
        key={tabs.importLegacyBookings.id}
        px={3}>
        <ImportLegacyBookings />
      </VerticalTabPanel>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.importDriversCode.id}
        key={tabs.importDriversCode.id}
        px={3}>
        <ImportDriversCode />
      </VerticalTabPanel>

      <VerticalTabPanel
        value={tabValue}
        index={tabs.exportDriversCode.id}
        key={tabs.exportDriversCode.id}
        px={3}>
        <ExportDriversCode />
      </VerticalTabPanel>
    </Box>
  )
}

export default ImportExportDataTab
