import { Grid, Paper } from '@mui/material'

import { AddressProvider } from './AddressProvider'
import CollectionDetails from './CollectionDetails'
import DeliveryDetails from './DeliveryDetails'

interface IProps {
  isQuote: boolean
  isQuickQuote: boolean
  isUpdate?: boolean
  hoverText: string
  disabled?: boolean
}

const AddressDetails = (props: IProps) => {
  const { isQuote, isQuickQuote, isUpdate, hoverText, disabled } = props

  return (
    <AddressProvider>
      <Grid item xs={12} lg={6}>
        <Paper sx={{ padding: 2 }} variant='outlined'>
          <CollectionDetails
            isQuote={isQuote}
            isQuickQuote={isQuickQuote}
            disabled={disabled}
            hoverText={hoverText}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Paper sx={{ padding: 2 }} variant='outlined'>
          <DeliveryDetails
            isQuote={isQuote}
            isQuickQuote={isQuickQuote}
            isUpdate={isUpdate}
            disabled={disabled}
            hoverText={hoverText}
          />
        </Paper>
      </Grid>
    </AddressProvider>
  )
}

export default AddressDetails
