// ToDo: maybe use enum here
export const ColumnType = {
  date: typeof new Date(),
  time: 'time',
  datetime: 'datetime',
  datetimeoffset: 'datetimeoffset',
  string: typeof '',
  number: typeof 0,
  floatNumber: 'floatNumber',
  boolean: typeof false,
  email: 'email',
  object: typeof Object,
}

export const MaterialTableDefaults = {
  ROWS_PER_PAGE_OPTIONS: [5, 10, 1000],
  PAGE_LIMIT: 10,
}

export const SortingDirection: {
  ASCENDING: 'asc'
  DESCENDING: 'desc'
} = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
}
