import React from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { CreateAndUpdateSubDriverDtoInput, useGetDriverQuery } from 'generated/graphql'

import SubDriverForm from './SubDriverForm/SubDriverForm'

interface IProps {
  subDriverId: number | null
}

const UpdateSubDriver = (props: IProps) => {
  const { subDriverId } = props

  const { reset } = useFormContext<CreateAndUpdateSubDriverDtoInput>()
  // Queries
  const { data: driverData, loading: driverLoading } = useGetDriverQuery({
    variables: { driverId: subDriverId },
  })

  React.useEffect(() => {
    if (driverData) {
      reset({ ...driverData.driver, vatCategoryId: driverData.driver?.vatCategoryId?.toString() })
    }
  }, [driverData, reset])

  return (
    <>
      <SubDriverForm />
      <LoadingBackdrop loading={driverLoading} />
    </>
  )
}

export default UpdateSubDriver
