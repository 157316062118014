// @ts-nocheck

import Joi from 'joi'

import { EntityConstants, Tariff } from 'constants/index'
import { TariffCalculateCharge } from 'generated/graphql'

function validationSchema() {
  return Joi.object({
    // Tariff Details
    name: Joi.string().required().max(EntityConstants.MAX_NAME_LENGTH).label('Name'),
    xeroCodeId: Joi.any().optional(),
    xeroServiceCode: Joi.string()
      .max(Tariff.MAX_SERVICE_CODE_LENGTH)
      .allow(null, '')
      .optional()
      .label('Secondary Service Code'),
    isAttachedToAllCustomers: Joi.boolean().allow(null, '').optional(),
    customerIds: Joi.array()
      .label('Customers')
      .when('isAttachedToAllCustomers', {
        is: false,
        then: Joi.array().items(Joi.any()).min(1).required(),
        otherwise: Joi.array().optional(),
      }),
    tariffCategoryId: Joi.number()
      .integer()
      .optional()
      .required()
      .default(null)
      .label('Tariff Category'),
    vatCategoryId: Joi.string().allow(null).required().label('VAT Category'),
    baseUnit: Joi.string().required(),
    minCharge: Joi.number().allow(null).min(0).required().label('Min Charge'),
    rate: Joi.number().allow(null).min(0).required().label('Rate'),
    minMiles: Joi.number().allow(null).min(0).required().label('Min Miles'),
    type: Joi.string().required(),

    // Waiting Time Charge Details
    isOverwriteDefaultWaitingTime: Joi.boolean().allow(null, '').optional(),
    collectionAllowanceMinutes: Joi.when('isOverwriteDefaultWaitingTime', {
      is: true,
      then: Joi.number().allow(null, '').optional().min(0),
      otherwise: Joi.allow(null, '').optional(),
    }),
    deliveryAllowanceMinutes: Joi.when('isOverwriteDefaultWaitingTime', {
      is: true,
      then: Joi.number().allow(null, '').optional().min(0),
      otherwise: Joi.allow(null, '').optional(),
    }),
    excessWaitingTimeCharge: Joi.when('isOverwriteDefaultWaitingTime', {
      is: true,
      then: Joi.number().required().min(0),
      otherwise: Joi.number().allow(null, '').optional(),
    }).label('Waiting time Charge'),
    excessWaitingTimeMinutesPer: Joi.when('isOverwriteDefaultWaitingTime', {
      is: true,
      then: Joi.number().required().min(0),
      otherwise: Joi.number().allow(null, '').optional(),
    }).label('Waiting Minutes'),
    calculateCharge: Joi.string()
      .valid(...Object.values(TariffCalculateCharge))
      .optional(),

    // Drop Charge Details
    isDropChargeEnabled: Joi.boolean(),
    isUseDefaultDropCharge: Joi.boolean(),
    dropChargeRate: Joi.when('isDropChargeEnabled', {
      is: true,
      then: Joi.when('isUseDefaultDropCharge', {
        is: false,
        then: Joi.number().required().allow(null).min(0),
        otherwise: Joi.optional(),
      }),
    }),

    // External Tariff Details
    isExternal: Joi.boolean(),
    visibilitySequenceNumber: Joi.allow(null).when('isExternal', {
      is: true,
      then: Joi.number().required(),
      otherwise: Joi.optional(),
    }),
    tariffIconId: Joi.any().when('isExternal', {
      is: true,
      then: Joi.any().required(),
      otherwise: Joi.any().optional(),
    }),
  })
}

export default validationSchema
