import { useMemo } from 'react'

import { ControlledMultipleAutocomplete } from 'components/common'
import { Driver, useGetDriversXeroPurchaseCodesQuery } from 'generated/graphql'
import { getXeroPurchaseCodeValuesToLabelCode } from 'helpers'

interface IProps {
  control: any
}

const XeroPurchaseCodesMultipleAutocomplete = (props: IProps) => {
  const { control } = props

  const { data: xeroPurchaseCodesData, loading: xeroPurchaseCodesLoading } =
    useGetDriversXeroPurchaseCodesQuery({
      variables: {
        where: {
          xeroPurchaseCode: { and: [{ neq: null }, { neq: '' }] },
        },
      },
    })

  const xeroPurchaseCodes = useMemo(() => {
    if (xeroPurchaseCodesData?.nonPaginatedDrivers) {
      return getXeroPurchaseCodeValuesToLabelCode(
        xeroPurchaseCodesData.nonPaginatedDrivers as Driver[],
      )
    }
    return []
  }, [xeroPurchaseCodesData])

  return (
    <ControlledMultipleAutocomplete
      getOptionKey={(option) => option.value + option.label}
      disabled={xeroPurchaseCodesLoading}
      control={control}
      name='xeroPurchaseCodes'
      label='Supplier Codes'
      options={xeroPurchaseCodes}
      limitTags={3}
      defaultValue={[]}
      tooltipText='For multiple Supplier codes search, please introduce needed codes.'
    />
  )
}

export default XeroPurchaseCodesMultipleAutocomplete
