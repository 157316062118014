import { gql } from '@apollo/client'

export const CREATE_BANNER = gql`
  mutation CreateBanner($input: CreateAndUpdateBannerDtoInput!) {
    createBanner(input: $input) {
      id
      name
      type
      isActive
      fileId
      file {
        id
        ...FileDetails
      }
    }
  }
`

export const UPDATE_BANNER = gql`
  mutation UpdateBanner($input: CreateAndUpdateBannerDtoInput!) {
    updateBanner(input: $input) {
      id
      name
      type
      isActive
      fileId
      file {
        id
        ...FileDetails
      }
    }
  }
`

export const DELETE_BANNER = gql`
  mutation DeleteBanner($id: Long!) {
    deleteBanner(id: $id)
  }
`
