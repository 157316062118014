import React from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { FplDialogTitle } from 'components/common/Dialog'
import { EntityConstants, ValidationMessages, OtherChargeEntity } from 'constants/index'
import {
  CreateAndUpdateOtherChargeDtoInput,
  Exact,
  OtherChargeNameIsUniqueQuery,
  useCreateOtherChargeMutation,
  useOtherChargeNameIsUniqueQuery,
  useUpdateOtherChargeMutation,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'

import OtherChargeForm from './OtherChargeForm'
import OtherChargeTitle from './OtherChargeTitle'
import UpdateOtherCharge from './UpdateOtherCharge'

interface IProps {
  otherChargeId: number | null
  openDialog: boolean
  handleCloseDialog: () => void
}

yup.setLocale(ValidationMessages)

const schema = (
  validateName: (
    variables?: Partial<Exact<{ name: string; id?: any }>> | undefined,
  ) => Promise<ApolloQueryResult<OtherChargeNameIsUniqueQuery>>,
  chargeId: number | null,
) =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(EntityConstants.MAX_NAME_LENGTH)
      .test(
        'duplicated-name',
        'Charge with same name already exists in the system',
        async function (value) {
          if (!value || value.length === 0) {
            return true
          }

          return validateName
            ? (
                await validateName({
                  name: value,
                  id: chargeId,
                })
              ).data.otherChargeNameIsUnique
            : true
        },
      ),
    xeroServiceCode: yup
      .string()
      .label('Service Code')
      .max(OtherChargeEntity.MAX_NOMINAL_CODE_LENGTH),
    rate: yup.number().required().moreThan(0),
    description: yup.string().nullable().max(OtherChargeEntity.MAX_DESCRIPTION_LENGTH),
  })

export default function CreateUpdateOtherChargeDialog(props: IProps) {
  const { otherChargeId, handleCloseDialog, openDialog } = props
  const [notFound, setNotFound] = React.useState<boolean>(false)
  const [otherCharge, setOtherCharge] = React.useState<CreateAndUpdateOtherChargeDtoInput>({
    name: '',
    xeroServiceCode: '',
    rate: '0',
    vatCategoryId: '',
    description: '',
  })

  const { refetch: validateName } = useOtherChargeNameIsUniqueQuery({
    fetchPolicy: 'network-only',
    variables: {
      name: '',
    },
  })

  const methods = useForm<CreateAndUpdateOtherChargeDtoInput>({
    shouldUnregister: true,
    defaultValues: otherCharge,
    resolver: yupResolver(schema(validateName, otherChargeId)),
  })
  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  const handleOnCancel = () => {
    if (isDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  React.useEffect(() => {
    reset({
      ...otherCharge,
    })
  }, [reset, otherCharge])

  const [createOtherCharge, { loading: creating }] = useCreateOtherChargeMutation({
    onCompleted: (data) => {
      toast.success(`Tariff ${data.createOtherCharge.name} was created with success.`)

      handleCloseDialog()
    },
  })

  const [updateOtherCharge, { loading: updating }] = useUpdateOtherChargeMutation({
    onCompleted: (data) => {
      const { /* __typename, */ ...otherChargeData } = data.updateOtherCharge

      setOtherCharge({ ...otherChargeData } as CreateAndUpdateOtherChargeDtoInput)
      toast.success(`Tariff ${data.updateOtherCharge.name} was updated with success.`)

      handleCloseDialog()
    },
  })

  const onSubmit: SubmitHandler<CreateAndUpdateOtherChargeDtoInput> = (data) => {
    if (!otherChargeId) {
      createOtherCharge({
        variables: {
          input: {
            ...data,
            rate: Number(data.rate),
          },
        },
      })
    } else {
      updateOtherCharge({
        variables: {
          input: {
            ...data,
            id: otherChargeId.toString(),
            rate: Number(data.rate),
          },
        },
      })
    }
  }

  return (
    <Dialog fullWidth maxWidth='md' open={openDialog} aria-labelledby='form-charge-dialog-title'>
      <FplDialogTitle id='form-charge-dialog-title' onClose={handleOnCancel}>
        <OtherChargeTitle notFound={notFound} createCharge={!otherChargeId} />
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <Grid container spacing={3}>
            <Grid item>
              {!notFound &&
                (!otherChargeId ? (
                  <OtherChargeForm processing={creating || updating} />
                ) : (
                  <UpdateOtherCharge
                    chargeId={otherChargeId}
                    setNotFound={setNotFound}
                    setOtherCharge={setOtherCharge}
                    processing={creating || updating}
                  />
                ))}
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleOnCancel}>
          {isDirty ? 'Cancel' : 'Close'}
        </Button>

        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
