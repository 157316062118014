import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import GetAppIcon from '@mui/icons-material/GetApp'
import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { FplDataGrid, ControlledTextField, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, DEBOUNCE_DELAY } from 'constants/index'
import { BookingInvoiceFilterInput } from 'generated/graphql'
import { GET_PAGED_DRIVER_INVOICES } from 'graphql/queries'
import { DisableSubmit, GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadDriverInvoice } from 'services'

interface IProps {
  driverId: number
}

const DriverInvoices = (props: IProps) => {
  const { driverId } = props

  const { control, watch, setValue } = useForm({
    defaultValues: { search: '' },
  })

  const [searchValue] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)

  const handleDownloadInvoice = React.useCallback(
    (driverInvoiceId: GridRowId) => () => {
      DownloadDriverInvoice(driverInvoiceId)
        .then((response) => {
          const fileName = GetFileName(response) ?? `driver-invoice-${driverInvoiceId}.csv`

          SaveFile(response.data, fileName)
        })
        .catch(ShowRestError)
    },
    [],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const where: BookingInvoiceFilterInput | undefined = React.useMemo(() => {
    if (searchValue) {
      return { invoiceNumber: { eq: Number(searchValue) } }
    } else {
      return undefined
    }
  }, [searchValue])

  const columns: IGridColumn[] = React.useMemo(
    () => [
      { field: 'id', headerName: 'Id', width: 100 },
      {
        field: 'booking',
        headerName: 'Invoice Number',
        minWidth: 150,
        flex: 1,
        sortPath: ['booking', 'ourReference'],
        valueGetter: ({ value, row }) => row?.driverInvoiceNumber ?? value?.ourReference,
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      {
        field: 'pOAddressLine1',
        headerName: 'PO Address Line 1',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'dueDate',
        headerName: 'Due Date',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Download'
            size='large'
            icon={
              <Tooltip title={'Download'} arrow>
                <GetAppIcon />
              </Tooltip>
            }
            onClick={handleDownloadInvoice(id)}
          />,
        ],
      },
    ],
    [handleDownloadInvoice],
  )

  return (
    <FplDataGrid
      query={GET_PAGED_DRIVER_INVOICES}
      entityName='pagedDriverInvoices'
      queryVariables={{ driverId }}
      skipQuery={!driverId}
      columns={columns}
      defaultOrder={{ field: 'invoiceNumber', sort: 'desc' }}
      filter={where}
      pageOptions={{
        pageSize: 50,
        rowsPerPage: [10, 50, 100],
      }}
      toolbar={{
        caption: 'Invoices',
        rightSide: (
          <Grid item>
            <form autoComplete='off' onSubmit={DisableSubmit}>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchValue && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </form>
          </Grid>
        ),
      }}
    />
  )
}

export default DriverInvoices
