import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { CardTitle, AutoFillAddressDialog, IAutocompleteOptions } from 'components/common'
import { Mode } from 'constants/index'
import {
  PaymentMethod,
  VatCategory,
  useGetLoqateRetrieveLazyQuery,
  Address,
  CreateAndUpdateCustomerDtoInput,
  File,
  useGetCustomersQuery,
  SortEnumType,
  useGetCustomerInfoForSubCustomerLazyQuery,
  CustomerAccountType,
  InvoiceFrequency,
} from 'generated/graphql'

import ConfirmCopyFromPcDialog from './ConfirmCopyFromPcDialog'
import CustomerDetailsForm from './CustomerDetailsForm'

interface DetailsProps {
  paymentMethods: Array<PaymentMethod>
  vatCategories: Array<VatCategory>
  customerLogo: {
    onUploadCustomerLogo: (file: any) => void
    onRemoveCustomerLogo: () => void
    uploadingLogo?: boolean
    setNewLogo: Dispatch<SetStateAction<any>>
    file?: File
  }
  mode: number
}

const MainDetails = (props: DetailsProps) => {
  const { paymentMethods, vatCategories, mode, customerLogo } = props
  const [openAutoFillAddress, setOpenAutoFillAddress] = useState(false)
  const [openConfirmCopyFromPcDialog, setOpenConfirmCopyFromPcDialog] = useState(false)

  const { id } = useParams<{ id?: string }>()

  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext<CreateAndUpdateCustomerDtoInput>()

  const postcodeValue = watch('postcode')
  const countryIdValue = watch('countryId')
  const primaryCustomerIdValue = useWatch({ name: 'primaryCustomerId' })

  const customerId = mode === Mode.Update ? Number(id) : null

  // ToDo: add loading
  const [getLoqateRetrieve] = useGetLoqateRetrieveLazyQuery({
    onCompleted: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetAddressDetails(data.loqateRetrieve as any)
    },
  })

  const { data: customersData, loading: customersLoading } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
  })

  const [getCustomerInfoForSubCustomer, { loading: customerInfoForSubCustomerLoading }] =
    useGetCustomerInfoForSubCustomerLazyQuery({
      fetchPolicy: 'network-only',
    })

  const handleChangeCustomerLogo = (file: any) => customerLogo.onUploadCustomerLogo(file)

  const handleRemoveCustomerLogo = () => {
    customerLogo.onRemoveCustomerLogo()
  }

  const handleAutoFillClick = () => {
    setOpenAutoFillAddress(true)
  }

  const handleAutoFillAddressSelect = (id: string) => {
    setOpenAutoFillAddress(false)
    getLoqateRetrieve({ variables: { id } })
  }

  const resetAddressDetails = useCallback(
    (address: Address) => {
      setValue('addressLine1', address.addressLine1)
      setValue('postcode', address.postcode)
      setValue('addressLine2', address.addressLine2)
      setValue('countryId', address.countryId)
      setValue('city', address.city)
      address.telephoneNumber && setValue('telephoneNumber', address.telephoneNumber)
    },
    [setValue],
  )

  const getCustomerInfoAndResetForm = async () => {
    const customerInfoResult = await getCustomerInfoForSubCustomer({
      variables: { customerId: Number(primaryCustomerIdValue) },
    })

    if (customerInfoResult.data?.customer) {
      const customerData = customerInfoResult.data?.customer.customer

      reset((values) => ({
        ...values,
        invoiceEmail: customerData.invoiceEmail ?? '',
        paymentMethodId: customerData.paymentMethodId ?? '',
        customerAccountType: customerData.customerAccountType ?? CustomerAccountType.Cash,
        invoiceTermId: customerData.invoiceTermId ?? '',
        invoiceFrequency: customerData.invoiceFrequency ?? InvoiceFrequency.Daily,
        invoiceCompanyName: customerData.invoiceCompanyName ?? '',
        isInvoiceGrouped: customerData.isInvoiceGrouped,
        currencyId: customerData.currencyId ?? '',
        isReferenceRequired: customerData.isReferenceRequired,
        isUlezCharge: customerData.isUlezCharge,
      }))
    }
  }

  const handleCopyFromPcClick = () => {
    if (mode === Mode.Create) {
      getCustomerInfoAndResetForm()
    } else if (mode === Mode.Update) {
      setOpenConfirmCopyFromPcDialog(true)
    }
  }

  const handleConfirmCopyFromPc = () => {
    getCustomerInfoAndResetForm()
    setOpenConfirmCopyFromPcDialog(false)
  }

  const handleCancelCopyFromPc = () => {
    setOpenConfirmCopyFromPcDialog(false)
  }

  const subCustomersOptions: IAutocompleteOptions = useMemo(
    () =>
      customersData?.customers
        ?.filter((customer) => customer.id !== String(customerId))
        .map((customer) => ({
          value: customer.id,
          label: customer.name,
        })) || [],
    [customerId, customersData?.customers],
  )

  return (
    <>
      <CardTitle>Customer Details</CardTitle>
      <CustomerDetailsForm
        control={control}
        customerId={customerId}
        customerLogo={customerLogo}
        customersLoading={customersLoading}
        customerInfoForSubCustomerLoading={customerInfoForSubCustomerLoading}
        errors={errors}
        handleAutoFillClick={handleAutoFillClick}
        handleChangeCustomerLogo={handleChangeCustomerLogo}
        handleCopyFromPcClick={handleCopyFromPcClick}
        handleRemoveCustomerLogo={handleRemoveCustomerLogo}
        mode={mode}
        paymentMethods={paymentMethods}
        primaryCustomerIdValue={primaryCustomerIdValue}
        subCustomersOptions={subCustomersOptions}
        vatCategories={vatCategories}
      />

      {openAutoFillAddress && (
        <AutoFillAddressDialog
          open={openAutoFillAddress}
          setOpen={setOpenAutoFillAddress}
          postcode={postcodeValue}
          countryId={countryIdValue}
          onSelectAddress={handleAutoFillAddressSelect}
        />
      )}
      {openConfirmCopyFromPcDialog && (
        <ConfirmCopyFromPcDialog
          open={openConfirmCopyFromPcDialog}
          onConfirm={handleConfirmCopyFromPc}
          onCancel={handleCancelCopyFromPc}
        />
      )}
    </>
  )
}

export default MainDetails
