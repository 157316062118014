import { ISelectorOptions } from 'components/common'
import { ServiceType } from 'generated/graphql'

export const ServiceTypeValues: ISelectorOptions = [
  { value: ServiceType.None, label: 'None' },
  { value: ServiceType.DomesticDedicated, label: 'Domestic Dedicated' },
  { value: ServiceType.DomesticParcels, label: 'Domestic Parcels' },
  { value: ServiceType.DomesticPallets, label: 'Domestic Pallets' },
  { value: ServiceType.EuropeanDedicated, label: 'European Dedicated' },
  { value: ServiceType.EuropeanGroupage, label: 'European Groupage' },
  { value: ServiceType.InternationalParcels, label: 'International Parcels' },
  { value: ServiceType.InternationalAirFreight, label: 'International Air Freight' },
  { value: ServiceType.SeaFreight, label: 'Sea Freight' },
  { value: ServiceType.CustomsClearanceAgent, label: 'Customs Clearance Agent' },
]
