import { endOfDay, startOfDay } from 'date-fns'
import { FormProvider, useForm } from 'react-hook-form'

import { ReportFileType, ReportTypeButtonsId } from 'constants/EntityConstants'
import {
  CustomerIncomeReportInputDtoInput,
  SortEnumType,
  useGetCustomersQuery,
} from 'generated/graphql'

import FilterCriteriaForm from './FilterCriteriaForm'

interface IProps {
  defaultFilter: CustomerIncomeReportInputDtoInput
  isProcessing: boolean
  onViewReport: (filter: CustomerIncomeReportInputDtoInput) => void
  onDownloadReport: (type: ReportFileType, filter: CustomerIncomeReportInputDtoInput) => void
}

const FilterCriteria = (props: IProps) => {
  const { defaultFilter, isProcessing, onViewReport, onDownloadReport } = props

  const methods = useForm<CustomerIncomeReportInputDtoInput>({
    shouldUnregister: true,
    defaultValues: defaultFilter,
  })
  const { handleSubmit, reset } = methods

  // Query
  const { loading: customersLoading, data: customersData } = useGetCustomersQuery({
    variables: { order: { name: SortEnumType.Asc } },
  })

  const handleClearAll = () => {
    reset(defaultFilter)
  }

  const handleOnSubmit = (buttonId: ReportTypeButtonsId, formData) => {
    const { start, end, customerIds } = formData

    const filter: CustomerIncomeReportInputDtoInput = {
      start: start ? startOfDay(start) : undefined,
      end: end ? endOfDay(end) : undefined,
      customerIds: customerIds.length > 0 ? customerIds.map((x) => Number(x.value)) : undefined,
    }

    switch (buttonId) {
      case ReportTypeButtonsId.ViewReport:
        onViewReport(filter)
        break

      case ReportTypeButtonsId.DownloadXlsx:
        onDownloadReport(ReportFileType.xlsx, filter)
        break

      case ReportTypeButtonsId.DownloadPdf:
        onDownloadReport(ReportFileType.pdf, filter)
        break

      case ReportTypeButtonsId.DownloadHtml:
        onDownloadReport(ReportFileType.html, filter)
        break
    }
  }

  const handleReportButtonClick = (buttonId: ReportTypeButtonsId) => {
    handleSubmit((formData) => handleOnSubmit(buttonId, formData))()
  }

  return (
    <FormProvider {...methods}>
      <FilterCriteriaForm
        customers={customersData?.customers}
        customersLoading={customersLoading}
        isProcessing={isProcessing}
        onReportButtonClick={handleReportButtonClick}
        onClearAll={handleClearAll}
      />
    </FormProvider>
  )
}

export default FilterCriteria
