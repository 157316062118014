import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { ReportRowsFragment } from 'generated/graphql'

import ReportTableRow from './ReportTableRow'

const HeaderTabCell = styled(TableCell)({
  fontSize: 16,
  whiteSpace: 'nowrap',
})

interface IProps {
  headerRow: ReportRowsFragment['rows'][0] | undefined
  bodyRows: ReportRowsFragment['rows'] | undefined
}

const ReportTable = (props: IProps) => {
  const { headerRow, bodyRows } = props

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {headerRow?.cells.map((cell, index) => (
              <HeaderTabCell key={index}>{cell?.value}</HeaderTabCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyRows?.map((row, index) => (
            <ReportTableRow key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReportTable
