import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledTextField } from 'components/common'
import { FplDialogTitle } from 'components/common/Dialog'
import {
  CreateAndUpdateTariffCategoryDtoInput,
  useCreateTariffCategoryMutation,
} from 'generated/graphql'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'

interface IProps {
  openDialog: boolean
  handleCloseDialog: () => any
}

const CreateTariffCategoryDialog = (props: IProps) => {
  const { openDialog, handleCloseDialog } = props
  const {
    handleSubmit,
    control,

    formState: { isDirty },
  } = useForm<CreateAndUpdateTariffCategoryDtoInput>({
    shouldUnregister: true,
    defaultValues: {
      name: '',
    },
  })

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  const [createCategory, { loading }] = useCreateTariffCategoryMutation({
    onCompleted: (data) => {
      toast.success(`Tariff Category (${data.createTariffCategory.name}) was created with success.`)
      handleCloseDialog()
    },
  })

  const onSubmit = (data: CreateAndUpdateTariffCategoryDtoInput) => {
    createCategory({
      variables: {
        input: data,
      },
    })
  }

  const handleCancel = () => {
    if (isDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  return (
    <Dialog open={openDialog} color='primary' fullWidth maxWidth='xs'>
      <FplDialogTitle id='form-dialog-title' onClose={handleCancel}>
        <Typography paragraph variant='h5'>
          New Tariff Category
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <form id='tariff-category-form'>
          <Box pt='6px'>
            <ControlledTextField control={control} name='name' label='Name' required />
          </Box>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          form='tariff-category-form'
          variant='contained'
          disabled={loading || !isDirty}
          onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateTariffCategoryDialog
