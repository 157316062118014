import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import {
  ChangePasswordModel,
  CustomerRegistrationModel,
  SubDriverRegistrationModel,
} from '../types'

const baseURL = process.env.REACT_APP_AUTH_URL
const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export const IdentityApi = axios.create({
  baseURL,
})

export const CHANGE_PASSWORD_URI = `${baseURL}/api/customer/change-password`
export const EDIT_PROFILE_URI = `${baseURL}/api/customer/edit-profile`

export function RegisterCustomer(data: CustomerRegistrationModel): Promise<AxiosResponse<any>> {
  return IdentityApi.post('/api/customer/registration', {
    ...data,
  })
}

export function RegisterSubDriver(data: SubDriverRegistrationModel): Promise<AxiosResponse<any>> {
  return IdentityApi.post('/api/subdriver/registration', {
    ...data,
  })
}

export const ResetPassword = (email: string): Promise<AxiosResponse<any>> =>
  IdentityApi.post('/api/reset-password', {
    email,
  })

export function ChangeUserPassword(data: ChangePasswordModel): Promise<AxiosResponse<any>> {
  return IdentityApi.post('/api/user/change-password', {
    ...data,
    headers: {
      ...commonHeaders,
    },
  } as AxiosRequestConfig)
}
