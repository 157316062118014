import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { Mode } from 'constants/Mode'
import { GetBannerQuery, useGetBannerQuery } from 'generated/graphql'

import BannerForm from './BannerForm'

interface IProps {
  id: number
  onBannerLoaded: (banner: NonNullable<GetBannerQuery['banner']>) => void
  onFilesChanged: (files: File[]) => void
}

const UpdateBanner = (props: IProps) => {
  const { id, onBannerLoaded, onFilesChanged } = props

  const { reset } = useFormContext<any>()

  const { data: bannerData, loading: bannerLoading } = useGetBannerQuery({
    variables: { id },
  })

  useEffect(() => {
    if (bannerData?.banner) {
      reset({ ...bannerData.banner })

      onBannerLoaded(bannerData.banner)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerData, reset])

  return (
    <>
      <BannerForm
        mode={Mode.Update}
        bannerData={bannerData?.banner}
        onFilesChanged={onFilesChanged}
      />
      <LoadingBackdrop loading={bannerLoading} />
    </>
  )
}

export default UpdateBanner
