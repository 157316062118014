import { gql } from '@apollo/client'

export const UPDATE_ACCOUNT_PAYABLE_RECORD_STATUS_MUTATION = gql`
  mutation updateAccountPayableRecordStatus($input: AccountPayableRecordStatusDtoInput!) {
    updateAccountPayableRecordStatus(input: $input) {
      id
      status
    }
  }
`

export const UPDATE_ACCOUNT_PAYABLES_PAYMENT_DATE_MUTATION = gql`
  mutation updateAccountPayablesPaymentDate($input: AccountPayablePaymentDateDtoInput!) {
    updateAccountPayablesPaymentDate(input: $input) {
      id
      bookingId
      paymentDate
    }
  }
`
export const UPDATE_ACCOUNT_PAYABLE_RECORDS_STATUS_RANGE_MUTATION_TO_SCHEDULED_PAYMENT = gql`
  mutation updateAccountPayableRecordsStatusRangeToScheduledPayment(
    $inputs: [AccountPayableRecordStatusDtoInput!]!
  ) {
    updateAccountPayableRecordsStatusRangeToScheduledPayment(inputs: $inputs)
  }
`
export const UPDATE_DRIVER_PURCHASE_CODE = gql`
  mutation UpdateDriverPurchaseCode($input: UpdateDriverPurchaseCodeDtoInput!) {
    updateDriverPurchaseCode(input: $input) {
      id
    }
  }
`
