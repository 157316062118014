import { format, parseJSON } from 'date-fns'

import { IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'

export const useGetAccountsPayableDocumentsHistoryCollumns = () => {
  const columns: IGridColumn[] = [
    {
      field: 'createdAt',
      headerName: 'Created Date',
      flex: 0.2,
      minWidth: 140,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.createdAt ? format(parseJSON(row.createdAt as string), DATE_TIME_FORMAT) : ''
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 0.5,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'reason',
      headerName: 'Reason',
      flex: 0.5,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      minWidth: 300,
      sortable: false,
    },
  ]
  return [columns]
}
