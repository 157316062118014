import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import { JoiValidationOptions } from 'constants/index'
import { Mode } from 'constants/Mode'
import {
  CreateAndUpdateTariffIconDtoInput,
  GetTariffIconQuery,
  SortEnumType,
  useCreateTariffIconMutation,
  useDeleteTariffIconMutation,
  useUpdateTariffIconMutation,
} from 'generated/graphql'
import { GET_PAGED_TARIFF_ICONS } from 'graphql/queries'
import { isNumber, ShowAxiosError } from 'helpers'
import { UploadTariffIcon } from 'services'
import { TariffIconDialogValidation } from 'validation-schemas'

import TariffIconForm from './TariffIconForm'
import UpdateTariffIcon from './UpdateTariffIcon'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}

const TariffIconDialog = (props: IProps) => {
  const { id, openDialog, onCloseDialog } = props
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [currentTariffIconId, setCurrentTariffIconId] = useState<number | null>(null)

  const methods = useForm<CreateAndUpdateTariffIconDtoInput>({
    resolver: joiResolver(TariffIconDialogValidation, JoiValidationOptions),
    defaultValues: { name: '', vehicleCapacity: '' },
  })

  const { handleSubmit } = methods

  const [createTariffIcon, { loading: creatingTariffIcon }] = useCreateTariffIconMutation({
    refetchQueries: [
      {
        query: GET_PAGED_TARIFF_ICONS,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { createdAt: SortEnumType.Asc },
        },
      },
    ],
  })

  const [updateTariffIcon, { loading: updatingTariffIcon }] = useUpdateTariffIconMutation()

  const [deleteTariffIcon, { loading: deletingTariffIcon }] = useDeleteTariffIconMutation()

  let mode: number | undefined

  if (id === null) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const handleFilesChanged = (files: File[]) => {
    if (files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const handleTariffLoaded = (tariff: NonNullable<GetTariffIconQuery['tariffIcon']>) => {
    setCurrentTariffIconId(tariff.fileId)
  }

  const handleSubmitTariffIcon: SubmitHandler<CreateAndUpdateTariffIconDtoInput> = async ({
    name,
    vehicleCapacity,
  }) => {
    if (mode === Mode.Create) {
      if (selectedFile) {
        const fileData = new FormData()
        const date = new Date().toISOString()
        fileData.append(`tariff-icon-${date}`, selectedFile)

        const createTariffIconResponse = await createTariffIcon({
          variables: { input: { name, vehicleCapacity } },
        })

        const tariffIconId = createTariffIconResponse.data?.createTariffIcon?.id
        if (tariffIconId) {
          try {
            const uploadTariffIconResponse = await UploadTariffIcon(Number(tariffIconId), fileData)

            if (uploadTariffIconResponse?.data?.[0]?.id) {
              const updateTariffIconResponse = await updateTariffIcon({
                variables: {
                  input: {
                    id: Number(tariffIconId),
                    name,
                    vehicleCapacity,
                    fileId: uploadTariffIconResponse.data[0].id,
                  },
                },
              })

              if (updateTariffIconResponse.data?.updateTariffIcon) {
                toast.success(
                  `Tariff Icon (${updateTariffIconResponse.data.updateTariffIcon?.id}) was created with success`,
                )
                onCloseDialog()
              }
            }
          } catch (error: any) {
            deleteTariffIcon({ variables: { id: Number(tariffIconId) } })
            ShowAxiosError(error)
          }
        }
      }
    } else {
      if (selectedFile) {
        const fileData = new FormData()
        const date = new Date().toISOString()
        fileData.append(`tariff-icon-${date}`, selectedFile)

        try {
          const uploadTariffIconResponse = await UploadTariffIcon(id!, fileData)

          if (uploadTariffIconResponse.status === 200 && uploadTariffIconResponse.data[0].id) {
            const updateTariffIconResponse = await updateTariffIcon({
              variables: {
                input: {
                  id,
                  name,
                  vehicleCapacity,
                  fileId: uploadTariffIconResponse.data[0].id,
                },
              },
            })

            if (updateTariffIconResponse.data?.updateTariffIcon) {
              toast.success(
                `Tariff Icon (${updateTariffIconResponse.data.updateTariffIcon?.id}) was updated with success`,
              )
              onCloseDialog()
            }
          }
        } catch (error: any) {
          ShowAxiosError(error)
        }
      } else {
        if (currentTariffIconId) {
          const updateTariffIconResponse = await updateTariffIcon({
            variables: {
              input: {
                id,
                name,
                vehicleCapacity,
                fileId: currentTariffIconId,
              },
            },
          })

          if (updateTariffIconResponse.data?.updateTariffIcon) {
            toast.success(
              `Tariff Icon (${updateTariffIconResponse.data.updateTariffIcon?.id}) was updated with success`,
            )
            onCloseDialog()
          }
        }
      }
    }
  }

  const handleClose = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      scroll='body'
      open={openDialog}
      aria-labelledby='tariff-icon-dialog'>
      <FplDialogTitle id='tariff-icon-dialog' onClose={handleClose}>
        {mode === Mode.Create ? 'Create' : 'Update'} Tariff Icon
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          {mode === Mode.Create ? (
            <TariffIconForm mode={Mode.Create} onFilesChanged={handleFilesChanged} />
          ) : (
            <UpdateTariffIcon
              id={id!}
              onTariffLoaded={handleTariffLoaded}
              onFilesChanged={handleFilesChanged}
            />
          )}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          loading={creatingTariffIcon || updatingTariffIcon || deletingTariffIcon}
          onClick={handleSubmit(handleSubmitTariffIcon)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TariffIconDialog
