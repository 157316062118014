import { useState } from 'react'

import { Accordion, AccordionDetails } from '@mui/material'

import AccordionHeader from './AccordionHeader'

interface SopAccordionProps {
  caption: string
  children: React.ReactNode
}

const SopAccordion = (props: SopAccordionProps) => {
  const [expanded, setExpanded] = useState(false)

  const handleChangeAccordion = () => setExpanded((prevState) => !prevState)

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={handleChangeAccordion}>
      <AccordionHeader>{props.caption}</AccordionHeader>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  )
}

export default SopAccordion
