import { useState } from 'react'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { FilePreviewDialog } from 'components/common'
import { File } from 'generated/graphql'

import AttachmentRow from './AttachmentRow'

interface IProps {
  attachments: Array<File>
  addressType: string
  onFileRemoved?: () => void
  loading?: boolean
  disabled?: boolean
}

const AttachmentsList = (props: IProps) => {
  const { attachments, addressType, disabled, onFileRemoved } = props

  const [fileId, setFileId] = useState<number | null>(null)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

  const onFilePreviewed = (id: number): void => {
    setFileId(id)
    setOpenPreviewDialog(true)
  }

  const onClosePreviewDialog = (): void => {
    setOpenPreviewDialog(false)
  }

  return (
    <Box my={2}>
      <TableContainer style={{ height: 250 }}>
        <Table stickyHeader aria-label='pob/pod attachments'>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Uploaded at</TableCell>
              <TableCell>Uploaded by</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachments.map((a) => (
              <AttachmentRow
                key={`${a.id}-po-attachment`}
                disabled={disabled}
                data={a}
                type={addressType}
                onFileRemoved={onFileRemoved}
                onFilePreviewed={onFilePreviewed}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FilePreviewDialog
        fileId={fileId}
        openDialog={openPreviewDialog}
        handleCloseDialog={onClosePreviewDialog}
      />
    </Box>
  )
}

export default AttachmentsList
