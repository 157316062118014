import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField } from 'components/common'
import { UpdateEmailTemplateInput } from 'generated/graphql'

const DriverSignatureFormControl = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UpdateEmailTemplateInput>()

  return (
    <Grid container sx={{ marginTop: 'unset' }}>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label='Signature'
          name='message'
          defaultValue=''
          multiline
          rows={12}
          required
          error={!!errors.message}
          helperText={errors.message?.message}
        />
      </Grid>
    </Grid>
  )
}

export default DriverSignatureFormControl
