import { gql } from '@apollo/client'

export const TariffDetailsFragment = gql`
  fragment TariffDetails on Tariff {
    id
    basedOnTariffId
    name
    tariffCategoryId
    tariffCategory {
      id
      name
    }
    xeroCodeId
    xeroServiceCode
    type
    minCharge
    minMiles
    rate
    baseUnit
    isWaitAndReturnEnabled
    xeroCode {
      id
      name
      code
    }
    vatCategoryId
    isAttachedToAllCustomers
    customers {
      id
      customer {
        id
        name
      }
    }
    isDropChargeEnabled
    isUseDefaultDropCharge
    dropChargeRate
    isOverwriteDefaultWaitingTime
    collectionAllowanceMinutes
    deliveryAllowanceMinutes
    excessWaitingTimeCharge
    excessWaitingTimeMinutesPer
    calculateCharge
    isExternal
    visibilitySequenceNumber
    tariffIconId
  }
`
