import { useContext, useEffect, useMemo } from 'react'

import { Grid, Stack, Tooltip } from '@mui/material'
import Decimal from 'decimal.js'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { CardTitle, TabPanelBootstrap, FplDynamicTabs } from 'components/common'
import { DEBOUNCE_DELAY, DeliveryIntervals, IgnoredXeroServiceCodes } from 'constants/index'
import {
  BookingAddressType,
  GoogleDistanceStatus,
  useDeleteBookingAddressMutation,
  useGetDistanceCalculationLazyQuery,
} from 'generated/graphql'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import {
  calculateBusinessTransitInterval,
  calculateTransitInterval,
  getGoogleAddressInput,
} from 'helpers'
import usePrevious from 'helpers/usePrevious'
import { usePermission, emitter } from 'providers'
import { FillIntervalParams, TBookingForm } from 'types'

import { AddressContext } from './AddressProvider'
import { BookingAddress, getBookingAddressDefaultValues } from './BookingAddress'
import { DistanceInput } from './DistanceInput'

interface IProps {
  isQuote: boolean
  isQuickQuote: boolean
  isUpdate?: boolean
  hoverText: string
  disabled?: boolean
}
const DeliveryDetails = (props: IProps) => {
  const { isQuote, isQuickQuote, isUpdate, hoverText, disabled } = props

  const { deliveryTabValue, setDeliveryTabValue } = useContext(AddressContext)

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<TBookingForm>()
  const {
    fields: deliveryFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'deliveryAddresses',
    keyName: 'tabsKey',
  })

  const [collectionAt] = useDebounce(useWatch({ name: 'collectionAddresses.0.at' }), DEBOUNCE_DELAY)
  const [collectionBy] = useDebounce(useWatch({ name: 'collectionAddresses.0.by' }), DEBOUNCE_DELAY)
  const serviceType = useWatch({ name: 'serviceType' })

  const [isAllowedToUpdateBookingAfterQuoteIsBooked] = usePermission(
    'UpdateBookingAfterQuoteIsBooked',
  )
  const showAddAndRemoveButtons =
    !isQuote && !isAllowedToUpdateBookingAfterQuoteIsBooked ? false : true

  // Queries
  const [
    getDistanceCalculation,
    { data: distanceCalculationData, loading: distanceCalculationLoading },
  ] = useGetDistanceCalculationLazyQuery()

  // Mutations
  const [deleteBookingAddress] = useDeleteBookingAddressMutation()

  const fieldsLength = deliveryFields.length
  const prevFieldsLength = usePrevious<number>(deliveryFields.length)

  const deliveryAddressesErrors = errors?.deliveryAddresses
  const errorTabs = useMemo(
    () =>
      (deliveryAddressesErrors as any)?.map((value, index) => (value !== undefined ? index : null)),
    [deliveryAddressesErrors],
  )

  useEffect(() => {
    if (fieldsLength < Number(prevFieldsLength)) {
      const collectionAddressesValue = getValues('collectionAddresses')
      const deliveryAddressesValue = getValues('deliveryAddresses')

      if (collectionAddressesValue && deliveryAddressesValue) {
        const googleAddressInput = getGoogleAddressInput(
          collectionAddressesValue,
          deliveryAddressesValue,
        )

        getDistanceCalculation({ variables: { input: googleAddressInput } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsLength, getDistanceCalculation, getValues])

  useEffect(() => {
    // is valid xero service type for auto pop values
    if (!serviceType || IgnoredXeroServiceCodes.includes(serviceType)) {
      return
    }

    const collectionAddresses = getValues('collectionAddresses')
    const deliveryAddresses = getValues('deliveryAddresses')

    // is not multi-drop
    if (collectionAddresses.length + deliveryAddresses.length !== 2) {
      return
    }

    const deliveryInterval = DeliveryIntervals[serviceType]

    if (!deliveryInterval) {
      return
    }

    const intervalParams: FillIntervalParams = {
      transitInterval: deliveryInterval,
      collectionAt: collectionAt ? new Date(collectionAt) : undefined,
      collectionBy: collectionBy ? new Date(collectionBy) : undefined,
      deliveryAddress: deliveryAddresses[0],
      onDeliveryAtSet: (deliveryAt: Date) => setValue('deliveryAddresses.0.at', deliveryAt),
      onDeliveryBySet: (deliveryBy: Date) => setValue('deliveryAddresses.0.by', deliveryBy),
    }

    if (deliveryInterval.workingPeriod) {
      calculateBusinessTransitInterval(intervalParams)
    } else {
      calculateTransitInterval(intervalParams)
    }
  }, [serviceType, getValues, setValue, collectionAt, collectionBy])

  useEffect(() => {
    if (distanceCalculationData) {
      if (distanceCalculationData.distanceCalculation.status === GoogleDistanceStatus.Success) {
        const distance = new Decimal(distanceCalculationData.distanceCalculation.distance)
          .toDP(2, Decimal.ROUND_FLOOR)
          .toNumber()

        setValue('distance', distance, { shouldDirty: true })
      } else if (
        distanceCalculationData.distanceCalculation.status === GoogleDistanceStatus.Error
      ) {
        toast.warning(` ${distanceCalculationData.distanceCalculation.errorMessage}`)
      }
    }
  }, [distanceCalculationData, setValue])

  useEffect(() => {
    isPageLoadingVar(distanceCalculationLoading)
  }, [distanceCalculationLoading])

  const handleTabChange = (_, newValue) => {
    setDeliveryTabValue(newValue)
  }

  const handleAddClick = () => {
    const newTabValue = deliveryFields.length
    const collectionAddressesValue = getValues('collectionAddresses')
    const deliveryAddressesValue = getValues('deliveryAddresses')
    const newSequenceOrder =
      [...collectionAddressesValue, ...deliveryAddressesValue].reduce(
        (max, address) => (address.sequenceOrder > max ? address.sequenceOrder : max),
        0,
      ) + 1

    const newAddress = getBookingAddressDefaultValues(newSequenceOrder)

    if (deliveryAddressesValue[0].reasonCodeId)
      newAddress.reasonCodeId = deliveryAddressesValue[0].reasonCodeId

    append(newAddress)
    setDeliveryTabValue(newTabValue)

    emitter.emit('add-drop-charge')
  }

  const handleRemoveClick = (e, index: number, id?: string) => {
    e.stopPropagation()

    if (id) {
      deleteBookingAddress({ variables: { bookingAddressId: Number(id) } }).then((result) => {
        if (!result.data?.deleteBookingAddress) {
          return
        }

        if (deliveryTabValue === deliveryFields.length - 1) {
          setDeliveryTabValue(deliveryFields.length - 2)
        }
        remove(index)
        toast.success('Address was deleted with success')

        emitter.emit('add-drop-charge')
      })
    } else {
      if (deliveryTabValue === deliveryFields.length - 1) {
        setDeliveryTabValue(deliveryFields.length - 2)
      }
      remove(index)

      emitter.emit('add-drop-charge')
    }
  }

  const handleFindOrAf = () => {
    const collectionAddressesValue = getValues('collectionAddresses')
    const deliveryAddressesValue = getValues('deliveryAddresses')

    if (collectionAddressesValue && deliveryAddressesValue) {
      const googleAddressInput = getGoogleAddressInput(
        collectionAddressesValue,
        deliveryAddressesValue,
      )

      if (googleAddressInput.length >= 2) {
        getDistanceCalculation({ variables: { input: googleAddressInput } })
      }
    }
  }

  return (
    <Stack spacing={1}>
      <Grid container justifyContent='space-between' rowSpacing={1}>
        <Grid item>
          <CardTitle marginBottom={0} disabled={disabled}>
            Delivery
          </CardTitle>
        </Grid>

        <Grid item ml='auto'>
          <DistanceInput isQuickQuote={isQuickQuote} disabled={disabled} />
        </Grid>
      </Grid>

      <Stack>
        <FplDynamicTabs
          tabValue={deliveryTabValue}
          fields={deliveryFields}
          name='delivery-address'
          label='Add'
          disabled={disabled}
          showAddButton={showAddAndRemoveButtons}
          showRemoveButton={showAddAndRemoveButtons}
          errorTabs={errorTabs}
          onTabChange={handleTabChange}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
        />

        <Tooltip title={disabled ? hoverText : ''} followCursor>
          <div>
            {deliveryFields.map((item, index) => (
              <TabPanelBootstrap value={deliveryTabValue} index={index} key={item.tabsKey}>
                <BookingAddress
                  bookingAddressType={BookingAddressType.Delivery}
                  disabled={disabled}
                  isQuote={isQuote}
                  isQuickQuote={isQuickQuote}
                  isUpdate={isUpdate}
                  item={item}
                  index={index}
                  key={item.tabsKey}
                  onFindOrAf={handleFindOrAf}
                />
              </TabPanelBootstrap>
            ))}
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default DeliveryDetails
