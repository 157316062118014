import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledSelector, TextInput } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { BodyTypeValues, FreightTypeValues, MinVehicleSizeValues } from 'constants/EntityConstants'
import { JobDescriptionTypeValues } from 'constants/EntityConstants/CourierExchange'
import { GetBookingQuery, LoadDtoJobType } from 'generated/graphql'

interface IProps {
  bookingDetails?: GetBookingQuery['booking']
}

const LoadDetails = (props: IProps) => {
  const { bookingDetails } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <CardTitle>Load Details</CardTitle>
      <Grid container columnSpacing={3} rowSpacing={1.5} alignItems='center'>
        <Grid item xs={3}>
          <TextInput label='Your Ref' value={bookingDetails?.ourReference} readOnly />
        </Grid>

        <Grid item xs={3}>
          <ControlledCheckbox
            control={control}
            label='Send Your Ref to Sub-contractor'
            name='showYourReferenceToSubcontractor'
          />
        </Grid>

        <Grid item xs={3}>
          <TextInput label='Customer Ref' value={bookingDetails?.customerReference} readOnly />
        </Grid>

        <Grid item xs={3}>
          <ControlledCheckbox
            control={control}
            label='Send Customer Ref to Sub-contractor'
            name='showCustomerReferenceToSubcontractor'
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledSelector
            control={control}
            label='Vehicle'
            name='minVehicleSize'
            options={MinVehicleSizeValues}
            required
            error={!!errors.minVehicleSize}
            helperText={errors.minVehicleSize?.message}
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledSelector
            control={control}
            label='Job Description'
            name='jobDescription'
            options={JobDescriptionTypeValues}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledSelector
            control={control}
            label='Freight Type'
            name='freightType'
            options={FreightTypeValues}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <ControlledSelector
            control={control}
            label='Body Type'
            name='bodyType'
            options={BodyTypeValues}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            control={control}
            name='jobType'
            render={({ field }) => (
              <FormControl component='fieldset'>
                <FormLabel component='label'>Job Type</FormLabel>
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value={LoadDtoJobType.Hotshot}
                    control={<Radio color='primary' />}
                    label='Hotshot'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value={LoadDtoJobType.Backload}
                    control={<Radio color='primary' />}
                    label='Backload'
                    labelPlacement='end'
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={8}>
          <ControlledCheckbox control={control} label='Tail Lift' name='tailLift' />
          <ControlledCheckbox control={control} label='Hiab' name='hiab' />
          <ControlledCheckbox control={control} label='Trailer' name='trailer' />
          <ControlledCheckbox control={control} label='Moffett Mounty' name='moffetMounty' />
          <ControlledCheckbox control={control} label='Two Man' name='twoMan' />
          <ControlledCheckbox control={control} label='Wait and Return' name='waitAndReturn' />
        </Grid>

        <Grid item xs={3}>
          <ControlledCheckbox
            control={control}
            label='Driver/Carrier must Confirm Acceptance'
            name='acceptanceRequired'
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledCheckbox
            control={control}
            label='Requires Signed and Time Stamped Hard Copies of POD/Delivery Note'
            name='podHardCopiesRequired'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default LoadDetails
