import { useMemo } from 'react'

import { Grid, Tooltip } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledNumericFormat, ControlledTextField, TextInput } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { DEBOUNCE_DELAY } from 'constants/index'
import { decimalFormatter } from 'helpers'
import { getBookingRevenue, getOtherChargesValue } from 'helpers'
import { Restricted } from 'providers'
import { TBookingForm } from 'types/form-types'

interface IProps {
  disabled?: boolean
  hoverText?: string
}

const FeeAndCharges = (props: IProps) => {
  const { disabled, hoverText = '' } = props

  const {
    control,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const [consignmentFeeValue] = useDebounce(
    useWatch({ name: 'consignmentFee', defaultValue: 0 }),
    DEBOUNCE_DELAY,
  )
  const chargesValue = useWatch({ name: 'charges' })

  // ToDo: quantity is string need to be number
  const otherCharges = useMemo(() => {
    return getOtherChargesValue(chargesValue)
  }, [chargesValue])

  const totalFormatted = useMemo(() => {
    const bookingRevenue = getBookingRevenue(Number(consignmentFeeValue), otherCharges)

    return decimalFormatter.format(bookingRevenue)
  }, [consignmentFeeValue, otherCharges])

  const otherChargesFormatted = decimalFormatter.format(otherCharges)

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <CardTitle disabled={disabled}>Fee and Charges</CardTitle>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Restricted
              to='MakeBookingConsignmentFeeFieldReadonly'
              fallback={
                <ControlledNumericFormat
                  control={control}
                  name='consignmentFee'
                  label='Consignment Fee'
                  disabled={disabled}
                  required
                  error={!!errors.consignmentFee}
                  helperText={errors.consignmentFee?.message}
                />
              }>
              <ControlledTextField
                control={control}
                name='consignmentFee'
                label='Consignment Fee'
                disabled={disabled}
                readOnly
              />
            </Restricted>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name='otherCharges'
              label='Other Charges'
              value={otherChargesFormatted}
              disabled={disabled}
              readOnly
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name='total'
              label='Total'
              value={totalFormatted}
              disabled={disabled}
              readOnly
            />
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  )
}

export default FeeAndCharges
