import React from 'react'

import { Grid } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'

import {
  AutoFillAddressDialog,
  ControlledCountryAutocomplete,
  ControlledPostCode,
  ControlledTelephoneNumWithCountryCode,
  ControlledTextField,
} from 'components/common'
import {
  Address,
  CreateAndUpdateDriverDtoInput,
  useGetLoqateRetrieveLazyQuery,
} from 'generated/graphql'

function ContactInformationStep() {
  const [openAutoFillAddress, setOpenAutoFillAddress] = React.useState(false)

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()

  const postcode = useWatch({ control, name: 'postcode' })
  const countryId = useWatch({ control, name: 'countryId' })
  const spacing = 2

  //Queries
  // ToDo: add loading
  const [getLoqateRetrieve] = useGetLoqateRetrieveLazyQuery({
    onCompleted: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetContactDetails(data.loqateRetrieve as any)
    },
  })

  const handleAutoFillClick = () => {
    setOpenAutoFillAddress(true)
  }

  const handleAutoFillAddressSelect = (id: string) => {
    setOpenAutoFillAddress(false)
    getLoqateRetrieve({ variables: { id } })
  }

  const resetContactDetails = React.useCallback(
    (address: Address) => {
      setValue('addressLine1', address.addressLine1)
      setValue('addressLine2', address.addressLine2)
      setValue('city', address.city)
      setValue('countryId', address.countryId)
      setValue('postcode', address.postcode)
      address.telephoneNumber && setValue('telephoneNumber', address.telephoneNumber)
      address.email && setValue('email', address.email)
    },
    [setValue],
  )

  return (
    <>
      <Grid container spacing={spacing} justifyContent='center'>
        <Grid container spacing={spacing} item xs={12} sm={6}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Address 1'
              name='addressLine1'
              required
              error={!!errors.addressLine1}
              helperText={errors.addressLine1?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Address 2'
              name='addressLine2'
              error={!!errors.addressLine2?.message}
              helperText={errors.addressLine2?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              label='Town/City'
              required
              name='city'
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledCountryAutocomplete
              control={control}
              error={!!errors.countryId}
              helperText={errors.countryId?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledPostCode
              control={control}
              name='postcode'
              onAutoFillClick={handleAutoFillClick}
              required
              error={!!errors.postcode}
              helperText={errors.postcode?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTelephoneNumWithCountryCode
              control={control}
              name='telephoneNumber'
              label='Telephone Number'
              required
              error={!!errors.telephoneNumber}
              helperText={errors.telephoneNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTelephoneNumWithCountryCode
              control={control}
              name='alternativeTelephoneNumber'
              label='Alternative Telephone Number'
              error={!!errors.alternativeTelephoneNumber}
              helperText={errors.alternativeTelephoneNumber?.message}
            />
          </Grid>
        </Grid>
      </Grid>

      {openAutoFillAddress && (
        <AutoFillAddressDialog
          open={openAutoFillAddress}
          setOpen={setOpenAutoFillAddress}
          postcode={postcode}
          countryId={countryId}
          onSelectAddress={handleAutoFillAddressSelect}
        />
      )}
    </>
  )
}

export default ContactInformationStep
