import { gql } from '@apollo/client'

import { ReasonCodeDetailsFragment } from 'graphql/fragments'

export const CREATE_REASON_CODE = gql`
  mutation createReasonCode(
    $input: CreateAndUpdateReasonCodeDtoInput!
  ) {
    createReasonCode(input: $input) {
      id
      ...ReasonCodeDetails
    }

    ${ReasonCodeDetailsFragment}
  }
`

export const UPDATE_REASON_CODE = gql`
  mutation updateReasonCode(
    $input: CreateAndUpdateReasonCodeDtoInput!
  ) {
    updateReasonCode(input: $input) {
      id
      ...ReasonCodeDetails
    }

    ${ReasonCodeDetailsFragment}
  }
`

export const DELETE_REASON_CODE = gql`
  mutation deleteReasonCode($reasonCodeId: Long!) {
    deleteReasonCode(reasonCodeId: $reasonCodeId)
  }
`
