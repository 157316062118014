import { ApolloClient, InMemoryCache, createHttpLink, split, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/link-context'
import { createClient } from 'graphql-ws'
import { toast } from 'react-toastify'

import { initClientData, Query, resolvers, typeDefs } from './graphql'

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL // process.env.GRAPHQL_URL
const GRAPHQL_WS_URL = process.env.REACT_APP_GRAPHQL_WS_URL

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
})

const wsLink = (token: string) =>
  new GraphQLWsLink(
    createClient({
      url: GRAPHQL_WS_URL ?? 'UNDEFINED URI',
      lazy: true,
      connectionParams: () => {
        return { headers: { Authorization: `Bearer ${token}` } }
      },
    }),
  )

const authLink = (token: string) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })
}

// The split function takes three parameters:
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const terminatingLink = (token: string) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink(token),
    // @ts-ignore
    authLink(token).concat(httpLink),
  )
}

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, code, locations, path }) => {
      toast.error(message)

      console.error(
        `[GraphQL error]${code}: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })
    return
  }

  if (networkError && !graphQLErrors) {
    console.error(`[Network error]: ${networkError}`)
    toast.error('Something went wrong')
  }
})

const link = (token: string) => {
  // The order matters here. "onErrorLink" needs to be first, otherwise it
  // gets ignored in some cases, see #68644.
  return ApolloLink.from([onErrorLink, terminatingLink(token)])
}

const cache = new InMemoryCache({
  typePolicies: {
    Query,
    Country: {
      keyFields: false,
    },
    Policy: {
      keyFields: false,
    },
    PolicyRole: {
      keyFields: false,
    },
  },
})

const client = (token: string) => {
  return new ApolloClient({
    link: link(token),
    cache,
    typeDefs,
    resolvers,
    connectToDevTools: true,
  })
}

initClientData(cache)

// TODO: fix this
// @ts-ignore // ToDo: Fix React v17
// client.onResetStore(() => initClientData(cache))

export default client
