import { useMemo, useEffect } from 'react'

import { Grid } from '@mui/material'
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import { ControlledSelector, ControlledTextField, ISelectorOptions } from 'components/common'
import { useGetOtherChargesQuery } from 'generated/graphql'

import { TChargeToolbarForm } from './chargeToolbar.types'

interface IProps {
  control: Control<TChargeToolbarForm, any>
  watch: UseFormWatch<TChargeToolbarForm>
  setValue: UseFormSetValue<TChargeToolbarForm>
  errors: FieldErrors<TChargeToolbarForm>
  disabled?: boolean
}

const OtherChargeOptions = (props: IProps) => {
  const { control, watch, setValue, errors, disabled } = props

  const selectedOtherChargeId = watch('otherChargeId')

  const { data: otherChargesData } = useGetOtherChargesQuery()

  const chargeOptions: ISelectorOptions = useMemo(
    () =>
      otherChargesData?.otherCharges.map((charge) => ({
        value: charge.id,
        label: charge.name,
      })) || [],
    [otherChargesData],
  )

  useEffect(() => {
    if (otherChargesData) {
      const otherCharge = otherChargesData.otherCharges.find(
        (otherCharge) => otherCharge.id === String(selectedOtherChargeId),
      )

      if (otherCharge) {
        setValue('name', otherCharge.name)
        setValue('rate', otherCharge.rate)
        setValue('vatCategoryId', String(otherCharge.vatCategoryId))
        otherCharge.xeroServiceCode && setValue('xeroServiceCode', otherCharge.xeroServiceCode)
        otherCharge.description && setValue('description', otherCharge.description)
      }
    }
  }, [selectedOtherChargeId, otherChargesData, setValue])

  return (
    <>
      <Grid item xs={8} sm>
        <ControlledSelector
          control={control}
          label='Other Charge'
          name='otherChargeId'
          options={chargeOptions}
          required
          disabled={disabled}
          error={!!errors.otherChargeId}
          helperText={errors.otherChargeId?.message}
        />
      </Grid>

      <Grid item xs={4} sm minWidth='100px'>
        <ControlledTextField
          control={control}
          name='rate'
          label='Rate'
          defaultValue=''
          type='text'
          inputMode='numeric'
          pattern='[0-9]*'
          sanitizedNumber
          startAdornment='£'
          disabled={disabled}
        />
      </Grid>
    </>
  )
}

export default OtherChargeOptions
