import React from 'react'

import { Box, Grid, Paper } from '@mui/material'

import { CardTitle } from 'components/common/controls'
import { ReportFileType, ReportFileTypeValues } from 'constants/EntityConstants/Reports'
import {
  CustomerContactListReportInputDtoInput,
  useGetCustomerContactListReportQuery,
} from 'generated/graphql'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadCustomerContactListReport } from 'services/RestClient'

import { ReportTable } from '../common'
import FilterCriteria from './FilterCriteria'

const defaultFilter = {
  customerIds: [],
}

const CustomersContact = () => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [filter, setFilter] = React.useState<CustomerContactListReportInputDtoInput>(defaultFilter)

  const { data: customerContactListReportData, fetchMore } = useGetCustomerContactListReportQuery({
    variables: { input: filter },
  })

  React.useEffect(() => {
    fetchMore({ variables: { input: filter } })
  }, [filter, fetchMore])

  const headerRow = React.useMemo(
    () => customerContactListReportData?.customerContactListReport.rows[0],
    [customerContactListReportData],
  )

  const bodyRows = React.useMemo(
    () =>
      customerContactListReportData?.customerContactListReport.rows.filter((_, index) => {
        if (index !== 0) {
          return true
        }
      }),
    [customerContactListReportData],
  )

  const handleViewReport = (filter: CustomerContactListReportInputDtoInput) => {
    setFilter(filter)
  }

  const handleDownloadReport = (
    type: ReportFileType,
    filter: CustomerContactListReportInputDtoInput,
  ) => {
    setIsProcessing(true)

    DownloadCustomerContactListReport(type, filter)
      .then((response) => {
        setIsProcessing(false)

        let fileName = GetFileName(response)
        if (!fileName) {
          const format = ReportFileTypeValues.find((reportType) => reportType.id === type)?.name
          fileName = `customers-contact-report.${format}`
        }

        SaveFile(response.data, fileName)
      })
      .catch((error) => {
        ShowRestError(error)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>Filter Criteria</CardTitle>
            <FilterCriteria
              defaultFilter={defaultFilter}
              isProcessing={isProcessing}
              onViewReport={handleViewReport}
              onDownloadReport={handleDownloadReport}
            />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>Report Table</CardTitle>
            <ReportTable headerRow={headerRow} bodyRows={bodyRows} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CustomersContact
