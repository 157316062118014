import { useState, useEffect, useMemo } from 'react'

import { AppBar, Tab, Tabs, Tooltip } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'

import { PostLoadOrBookDirect } from 'components/bookings/PostLoadOrBookDirect'
import { CounterBadge, FplTab, TabPanel, TasksAndHistory } from 'components/common'
import { Mode, PARAM_NEW } from 'constants/index'
import { BookingsTabId } from 'constants/Tabs'
import {
  BookingStatus,
  GetBookingQuery,
  TaskStatus,
  useGetBookingLazyQuery,
  useGetTasksCountLazyQuery,
  useGetUserBookingLazyQuery,
} from 'generated/graphql'
import { useHashToSelectTab, CloneProps, isNumber } from 'helpers'
import { useBookingAllTabs, useIsBaseDisabled, useTabsTooltipText } from 'hooks/bookingTab'
import { Restricted, usePermission } from 'providers/PermissionProvider'

import { AllocateBids } from './AllocateBids'
import { AllocateCXMember } from './AllocateCXMember'
import AttachmentsTab from './Attachments/AttachmentsTab'
import { Audit } from './Audit'
import { BookingDetails } from './BookingDetails'
import { Logs } from './Logs'
import { MapRoute } from './MapRoute'
import { ScheduledTemplate } from './ScheduledBooking'

function commonProps(index: BookingsTabId) {
  return {
    id: `booking-tab-${index}`,
    'aria-controls': `booking-tabpanel-${index}`,
  }
}

const BookingTab = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)
  const { id } = useParams<{ id?: string }>()

  const history = useHistory()

  const allTabs = useBookingAllTabs()
  const arrayOfTabs = Object.values(allTabs)
  useHashToSelectTab(arrayOfTabs, setTabValue)

  const [isAllowedToViewBookingFee] = usePermission('ViewBookingFeeInfoMargin')
  const [isAllowedToViewTasks] = usePermission('ViewTasks')

  let mode: number | undefined

  if (id === PARAM_NEW) {
    mode = Mode.Create
  } else if (id && isNumber(id)) {
    mode = Mode.Update
  }

  const bookingId: string | null = mode === Mode.Update && id ? id : null

  // Queries
  const [getAdminBooking, { data: adminBookingData }] = useGetBookingLazyQuery()

  const [getUserBooking, { data: userBookingData }] = useGetUserBookingLazyQuery()

  const [getTasksCount, { data: tasksData }] = useGetTasksCountLazyQuery()

  const booking = adminBookingData?.booking || userBookingData?.userBooking

  useEffect(() => {
    if (isAllowedToViewTasks && bookingId) {
      getTasksCount({
        fetchPolicy: 'network-only',
        variables: {
          where: { bookingId: { eq: Number(bookingId) }, status: { eq: TaskStatus.New } },
        },
      })
    }
  }, [bookingId, getTasksCount, isAllowedToViewTasks, booking?.status])

  const isBaseDisabled = useIsBaseDisabled(booking, mode)

  const isPostLoadDisabled = useMemo(() => !!booking?.courierExchangeLoadId, [booking])

  const isAllocateCxMemberDisabled = useMemo(
    () =>
      !!(booking as GetBookingQuery)?.booking?.courierExchangeLoadId &&
      (booking as GetBookingQuery)?.booking?.drivers?.length !== 0,
    [booking],
  )

  const isBookingScheduledTemplate = useMemo(
    () => booking?.status === BookingStatus.ScheduledTemplate,
    [booking],
  )

  const tabsTooltipText = useTabsTooltipText(bookingId, booking)

  useEffect(() => {
    if (isAllowedToViewBookingFee) {
      getAdminBooking({
        variables: { bookingId: Number(bookingId) },
      })
    } else {
      getUserBooking({
        variables: { bookingId: Number(bookingId) },
      })
    }
  }, [bookingId, isAllowedToViewBookingFee, getAdminBooking, getUserBooking])

  const handleTabChange = (_, newValue: BookingsTabId) => {
    const selectedTab = arrayOfTabs.find((tab) => tab.id === newValue)

    if (selectedTab) {
      history.push(selectedTab.hash)
    }
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='booking tabs'
          variant='scrollable'>
          <CloneProps>
            {(tabProps) => (
              <Restricted to={['ViewBookingDetails', 'ViewUserBookingDetails']}>
                <Tab
                  {...tabProps}
                  {...commonProps(allTabs.bookingDetails.id)}
                  label={allTabs.bookingDetails.label}
                  value={allTabs.bookingDetails.id}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewBookingMapRouteTab'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.mapRoute}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewBookingAttachmentsTab'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.attachments}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='CourierExchangePostLoad'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.postLoad}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled || isPostLoadDisabled || isBookingScheduledTemplate}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewDriverBid'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.allocateBids}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewCourierExchangeQuoteList'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.allocateCXMember}
                  tooltipTitle={tabsTooltipText}
                  disabled={
                    isBaseDisabled || isAllocateCxMemberDisabled || isBookingScheduledTemplate
                  }
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewBookingLogs'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.logs}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewBookingAudit'>
                <FplTab
                  tabProps={tabProps}
                  tabDetails={allTabs.audit}
                  tooltipTitle={tabsTooltipText}
                  disabled={isBaseDisabled}
                />
              </Restricted>
            )}
          </CloneProps>

          <CloneProps>
            {(tabProps) => (
              <Restricted to='ViewTasks'>
                <Tab
                  {...tabProps}
                  {...commonProps(allTabs.tasks.id)}
                  sx={{ pointerEvents: 'auto !important' }}
                  value={allTabs.tasks.id}
                  disabled={isBaseDisabled}
                  label={
                    <Tooltip
                      title={tabsTooltipText}
                      disableHoverListener={!isBaseDisabled}
                      disableTouchListener={!isBaseDisabled}
                      disableFocusListener={!isBaseDisabled}>
                      <CounterBadge color='primary' badgeContent={tasksData?.tasks?.totalCount}>
                        {allTabs.tasks.label}
                      </CounterBadge>
                    </Tooltip>
                  }
                />
              </Restricted>
            )}
          </CloneProps>

          {isBookingScheduledTemplate && (
            <CloneProps>
              {(tabProps) => (
                <Restricted to='ViewScheduledBookingTasksList'>
                  <FplTab
                    tabProps={tabProps}
                    tabDetails={allTabs.scheduledTemplate}
                    tooltipTitle={tabsTooltipText}
                    disabled={isBaseDisabled}
                  />
                </Restricted>
              )}
            </CloneProps>
          )}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={BookingsTabId.Details}>
        <BookingDetails bookingId={bookingId} mode={mode} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.MapRoute} padding={0}>
        <MapRoute bookingId={bookingId} courierExchangeLoadId={booking?.courierExchangeLoadId} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.Attachments} paddingX={{ xs: 2, sm: 0 }}>
        <AttachmentsTab bookingId={bookingId} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.PostLoad}>
        <PostLoadOrBookDirect bookingId={bookingId} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.AllocateBids}>
        <AllocateBids bookingId={bookingId} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.AllocateCXMember}>
        <AllocateCXMember
          bookingId={bookingId}
          courierExchangeLoadId={booking?.courierExchangeLoadId}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.Logs}>
        <Logs bookingId={bookingId} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.Audit}>
        <Audit bookingId={bookingId} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.Tasks}>
        <TasksAndHistory bookingId={bookingId} />
      </TabPanel>

      <TabPanel value={tabValue} index={BookingsTabId.ScheduledTemplate}>
        <ScheduledTemplate bookingId={bookingId} />
      </TabPanel>
    </div>
  )
}

export default BookingTab
