import { useCallback, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { ReasonCodeFilterInput, useDeleteReasonCodeMutation } from 'generated/graphql'
import { GET_PAGED_REASON_CODES, GET_USER_PAGED_REASON_CODES } from 'graphql/queries'
import { Restricted, usePermission } from 'providers'

import { getColumns } from './getColumns'
import { getRefetchQueries } from './getRefetchQueries'
import { ReasonCodeDialog } from './ReasonCodeDialog'

export default function ReasonCodes() {
  const [selectedReasonCodeId, setSelectedReasonCodeId] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [isAllowedToViewUserReasonCodeList] = usePermission('ViewUserReasonCodeList')

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const [deleteReasonCode, { loading: deleteReasonCodeProcessing }] = useDeleteReasonCodeMutation({
    refetchQueries: getRefetchQueries(isAllowedToViewUserReasonCodeList),
  })

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedReasonCodeId(Number(id))
    setOpenDialog(true)
  }

  const handleAddReasonCode = () => {
    setSelectedReasonCodeId(null)
    setOpenDialog(true)
  }

  const handleDeleteReasonCode = useCallback(
    (id: GridRowId) => () => {
      deleteReasonCode({
        variables: {
          reasonCodeId: Number(id),
        },
      }).then((response) => {
        if (response.data) {
          toast.success(`Reason Code was deleted successfully`)
        }
      })
    },
    [deleteReasonCode],
  )

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const filter: ReasonCodeFilterInput = useMemo(
    () => ({
      or: [{ name: { contains: searchValue } }, { code: { contains: searchValue } }],
    }),
    [searchValue],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      ...getColumns(isAllowedToViewUserReasonCodeList),
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleteReasonCodeProcessing}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteReasonCode(id)}
          />,
        ],
      },
    ],
    [isAllowedToViewUserReasonCodeList, deleteReasonCodeProcessing, handleDeleteReasonCode],
  )

  const toolbar = {
    caption: 'Reason Codes',
    leftSide: (
      <Grid item>
        <Button variant='outlined' size='small' onClick={handleAddReasonCode}>
          New Reason Code
        </Button>
      </Grid>
    ),
    rightSide: (
      <Grid item>
        <Box width='210px'>
          <ControlledTextField
            control={control}
            name='search'
            label='Search'
            defaultValue=''
            size='small'
            endAdornment={
              searchInput && (
                <IconButton size='small' onClick={handleSearchClearClick}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              )
            }
          />
        </Box>
      </Grid>
    ),
  }

  const commonProps = {
    toolbar: toolbar,
    filter: filter,
    columns: columns,
    onRowClick: handleRowClick,
  }

  return (
    <Box px={2}>
      <Restricted to='ViewReasonCodeList'>
        <FplDataGrid
          query={GET_PAGED_REASON_CODES}
          entityName='pagedReasonCodes'
          defaultOrder={{ field: 'createdAt', sort: 'desc' }}
          {...commonProps}
        />
      </Restricted>

      <Restricted to='ViewUserReasonCodeList'>
        <FplDataGrid
          query={GET_USER_PAGED_REASON_CODES}
          entityName='userPagedReasonCodes'
          defaultOrder={{ field: 'name', sort: 'asc' }}
          {...commonProps}
        />
      </Restricted>

      {openDialog && (
        <ReasonCodeDialog
          id={selectedReasonCodeId}
          openDialog={openDialog}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </Box>
  )
}
