import { useEffect, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledTextField, TextInput } from 'components/common'
import {
  GetBookingQuery,
  GetUserBookingQuery,
  useGetCustomerContactsLazyQuery,
} from 'generated/graphql'
import { TBookingForm } from 'types/form-types'

interface IProps {
  bookingDetails: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  disabled: boolean
}

const ContactTelephoneNumberForm = (props: IProps) => {
  const { bookingDetails, disabled } = props
  const [isTelephoneNumberEditable, setTelephoneNumberEditable] = useState(false)

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const [getCustomerContacts, { data: customerContactsData }] = useGetCustomerContactsLazyQuery({
    fetchPolicy: 'network-only',
  })

  const customerIdValue = useWatch({ name: 'customerId' })
  const contactIdValue = useWatch({ name: 'contactId' })

  useEffect(() => {
    if (customerIdValue && customerIdValue !== '') {
      getCustomerContacts({ variables: { customerId: Number(customerIdValue) } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerIdValue])

  const customerContacts = customerContactsData?.customerContacts
  const contactTelephoneNumberValue = useMemo(
    () =>
      customerContacts?.find((contact) => contact.id === String(contactIdValue) && contact.isActive)
        ?.telephoneNumber,
    [contactIdValue, customerContacts],
  )

  useEffect(() => {
    if (bookingDetails?.overrideContactTelephoneNumber) {
      setTelephoneNumberEditable(true)
    }
  }, [bookingDetails?.overrideContactTelephoneNumber])

  const handleEditTelephoneNumber = () => {
    setTelephoneNumberEditable(true)

    if (bookingDetails?.overrideContactTelephoneNumber) {
      setValue('overrideContactTelephoneNumber', bookingDetails?.overrideContactTelephoneNumber)
    } else if (contactTelephoneNumberValue) {
      setValue('overrideContactTelephoneNumber', contactTelephoneNumberValue)
    }
  }

  const handleCancelEditTelephoneNumber = () => {
    setTelephoneNumberEditable(false)
    setValue('overrideContactTelephoneNumber', '')
  }

  return (
    <>
      <Box hidden={isTelephoneNumberEditable}>
        <TextInput
          name='contactTelephoneNumber'
          label='Tel No'
          value={contactTelephoneNumberValue}
          disabled={disabled}
          readOnly
          endAdornment={
            <IconButton
              size='small'
              disabled={!contactIdValue || disabled}
              title='Override Contact Tel No'
              onClick={handleEditTelephoneNumber}>
              <EditIcon />
            </IconButton>
          }
        />
      </Box>

      <Box hidden={!isTelephoneNumberEditable}>
        <ControlledTextField
          control={control}
          name='overrideContactTelephoneNumber'
          label='Tel No'
          defaultValue=''
          disabled={disabled}
          endAdornment={
            <IconButton
              size='small'
              title='Use Default Contact Tel No'
              onClick={handleCancelEditTelephoneNumber}>
              <CloseIcon />
            </IconButton>
          }
          error={!!errors.overrideContactTelephoneNumber}
          helperText={errors.overrideContactTelephoneNumber?.message}
        />
      </Box>
    </>
  )
}

export default ContactTelephoneNumberForm
