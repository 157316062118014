import { useContext, useMemo, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { SplitButton } from 'components/common'
import {
  GetBookingQuery,
  useUpdateBookingStatusFromCourierExchangeMutation,
} from 'generated/graphql'
import {
  getBookingMargin,
  getBookingRevenue,
  getOtherChargesValue,
  getTotalDriverCharge,
} from 'helpers'
import { useBookingButtonActions } from 'hooks/useBookingButtonActions'
import { Restricted } from 'providers/PermissionProvider'
import PermissionContext from 'providers/PermissionProvider/PermissionContext'
import { TBookingForm } from 'types/form-types'

import { OperationType as DeleteDialogType } from '../../DeleteBookingDialog'
import { getStatusButtons, StatusButtonsId } from '../helpers'
import ConfirmationDialogs from './ConfirmationDialogs'

interface IProps {
  booking: GetBookingQuery['booking']
  isQuote: boolean
  hoverText: string
  disabled: boolean
  isFormDirty?: boolean
}

const ActionButtons = (props: IProps) => {
  const { booking, isQuote, disabled, isFormDirty } = props
  const [openConfirmCancellation, setOpenConfirmCancellation] = useState(false)
  const [openConfirmQuoteLost, setOpenConfirmQuoteLost] = useState(false)
  const [openConfirmCompletion, setOpenConfirmCompletion] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<{
    open: boolean
    operationType: DeleteDialogType
  }>({
    open: false,
    operationType: DeleteDialogType.Delete,
  })

  const { isAllowedTo } = useContext(PermissionContext)

  const { getValues } = useFormContext<TBookingForm>()

  const bookingId = booking?.id

  // hooks
  const bookingCalculatedStatus = booking?.calculatedStatus
  const bookingStatus = booking?.status
  const statusButtons = useMemo(() => {
    if (bookingCalculatedStatus && bookingStatus) {
      return getStatusButtons(bookingCalculatedStatus, bookingStatus, isAllowedTo)
    }
    return []
  }, [bookingStatus, bookingCalculatedStatus, isAllowedTo])

  const {
    bookQuote,
    confirmQuickQuote,
    completeBooking,
    moveBookingToInvoice,
    archiveBooking,
    copyBooking,
    makeBookingOnHold,
    convertToScheduledTemplate,
    sendDocketEmails,
    handleConfirmCancellation,
    handleConfirmQuoteLost,
    handleConfirmCompletion,
    isSplitBookingProcessing,
  } = useBookingButtonActions(bookingId, isQuote)

  const [updateBookingStatusFromCXMutation, { loading: updatingBookingStatusFromCX }] =
    useUpdateBookingStatusFromCourierExchangeMutation({
      onCompleted: () => {
        toast.success('Booking status was updated with success.')
      },
    })

  function tryToCompleteBooking() {
    if (booking?.customer.isReferenceRequired && booking?.customerReference === '') {
      toast.error(
        'Cust. Ref is required for booking completion. Please update booking, and try again.',
      )
      return
    }

    const consignmentFeeValue = Number(getValues('consignmentFee'))
    const chargesValue = getValues('charges')

    const totalDriverCharge = getTotalDriverCharge(booking?.drivers ?? [])
    const otherCharges = getOtherChargesValue(chargesValue)
    const revenue = getBookingRevenue(consignmentFeeValue, otherCharges)
    const margin = getBookingMargin(revenue, totalDriverCharge)

    if (margin < 0.2) {
      setOpenConfirmCompletion(true)
      return
    }

    completeBooking()
  }
  const buttonFunctions = {
    [StatusButtonsId.CancelBook]: () => setOpenConfirmCancellation(true),
    [StatusButtonsId.BookQuote]: bookQuote,
    [StatusButtonsId.QuoteLost]: () => setOpenConfirmQuoteLost(true),
    [StatusButtonsId.ConfirmQuickQuote]: confirmQuickQuote,
    [StatusButtonsId.MakeCompleted]: tryToCompleteBooking,
    [StatusButtonsId.MoveToInvoice]: moveBookingToInvoice,
    [StatusButtonsId.Archive]: archiveBooking,
    [StatusButtonsId.MakeOnHold]: () => makeBookingOnHold(true),
    [StatusButtonsId.Copy]: copyBooking,
    [StatusButtonsId.MakeActive]: () => makeBookingOnHold(false),
    [StatusButtonsId.ConvertToScheduledTemplate]: convertToScheduledTemplate,
    [StatusButtonsId.Runsheet]: sendDocketEmails,
    [StatusButtonsId.Delete]: () =>
      setOpenDeleteDialog({ open: true, operationType: DeleteDialogType.Delete }),
    [StatusButtonsId.DisableOrEnable]: () =>
      setOpenDeleteDialog({ open: true, operationType: DeleteDialogType.DisableEnable }),
  }
  // handlers
  const handleStatusButtonClick = (buttonId: StatusButtonsId) => {
    const buttonFunction = buttonFunctions[buttonId]
    if (buttonFunction) {
      buttonFunction()
    }
  }

  const handleOnUpdateStatusFromCX = () => {
    const loadId = booking?.courierExchangeLoadId
    loadId && updateBookingStatusFromCXMutation({ variables: { loadId: Number(loadId) } })
  }

  const handleCloseDeleteDialog = () =>
    setOpenDeleteDialog({
      open: false,
      operationType: openDeleteDialog.operationType,
    })

  return (
    <>
      <Grid container spacing={1.5} wrap='wrap-reverse'>
        {!!booking && (
          <Restricted to='CourierExchangeUpdateBookingStatus'>
            <Grid item>
              <LoadingButton
                variant='contained'
                color='grey'
                loading={updatingBookingStatusFromCX}
                disabled={disabled || !booking?.courierExchangeLoadId}
                onClick={handleOnUpdateStatusFromCX}>
                Update from CX
              </LoadingButton>
            </Grid>
          </Restricted>
        )}

        {statusButtons.length !== 0 && (
          <Grid item>
            <SplitButton
              options={statusButtons}
              loading={isSplitBookingProcessing}
              onButtonClick={handleStatusButtonClick}
              disabled={isFormDirty}
            />
          </Grid>
        )}
      </Grid>
      <ConfirmationDialogs
        bookingId={bookingId}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleConfirmCancellation={handleConfirmCancellation}
        handleConfirmCompletion={handleConfirmCompletion}
        handleConfirmQuoteLost={handleConfirmQuoteLost}
        openConfirmCancellation={openConfirmCancellation}
        openConfirmCompletion={openConfirmCompletion}
        openConfirmQuoteLost={openConfirmQuoteLost}
        openDeleteDialog={openDeleteDialog}
        setOpenConfirmCancellation={setOpenConfirmCancellation}
        setOpenConfirmCompletion={setOpenConfirmCompletion}
        setOpenConfirmQuoteLost={setOpenConfirmQuoteLost}
      />
    </>
  )
}

export default ActionButtons
