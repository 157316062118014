import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import validationSchema from 'components/bookings/BookingDetails/PobDetails/ValidationSchema'
import { JoiValidationOptions } from 'constants/index'
import {
  UpdatePackageOnBoardDtoInput,
  useGetBookingAddressAttachmentsQuery,
  useGetBookingLazyQuery,
  useGetUserBookingLazyQuery,
  useUpdateBookingPackageOnBoardMutation,
} from 'generated/graphql'
import { UploadBookingAddressAttachment } from 'services'

const usePobDetailsFunctions = ({
  setSelectedAddressId,
  selectedAddressId,
  handleCloseDialog,
  bookingId,
  setIsFullScreen,
  isFullScreen,
  setNewFiles,
  setUploadingFiles,
  setDropzoneKey,
  newFiles,
  dropzoneKey,
}) => {
  const methods = useForm<UpdatePackageOnBoardDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(validationSchema, JoiValidationOptions),
    defaultValues: {
      signedAt: null,
      waitingTime: null,
      signedByFirstName: '',
      signedByLastName: '',
      packages: [],
      arrivedAt: null,
      noteFromDriver: '',
    },
  })

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = methods

  // Queries
  const [getAdminBooking, { data: adminBookingData }] = useGetBookingLazyQuery()

  const [getUserBooking, { data: userBookingData }] = useGetUserBookingLazyQuery()

  const {
    loading: attachmentsLoading,
    data: attachmentsData,
    refetch: refetchAttachments,
  } = useGetBookingAddressAttachmentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: Number(selectedAddressId),
    },
  })

  // Mutations
  const [updatePob, { loading: updatingPob }] = useUpdateBookingPackageOnBoardMutation({
    onCompleted: () => {
      toast.success('POB was updated with success.')
      handleCloseDialog()
    },
  })

  const handleOnUpdate = async (formData: UpdatePackageOnBoardDtoInput) => {
    const { waitingTime, packages, ...otherFormData } = formData
    const normalizedPackages = packages?.map((pack) => ({
      packageId: Number(pack.packageId),
      quantity: Number(pack.quantity),
    }))

    if (!selectedAddressId) {
      return
    }

    await updatePob({
      variables: {
        input: {
          ...otherFormData,
          id: selectedAddressId,
          packages: normalizedPackages,
          waitingTime: waitingTime ? Number(waitingTime) : null,
          bookingId,
        },
      },
    })
  }

  const handleFullScreen = () => setIsFullScreen(!isFullScreen)
  const handleFilesChanged = (files: File[]) => setNewFiles(files)
  const handleCancel = () => handleCloseDialog()
  const handleSelectedAddressIdChange = (addressId: string) => {
    setSelectedAddressId(addressId)
  }
  const handleUploadFiles = () => {
    if (newFiles.length > 0) {
      const attachmentsData = new FormData()

      newFiles.forEach((file) => {
        attachmentsData.append('attachment', file)
      })

      setUploadingFiles(true)
      UploadBookingAddressAttachment(Number(selectedAddressId), attachmentsData)
        .then(() => {
          refetchAttachments()
          setNewFiles([])

          // HACK: this will re-render the dropzone area & clear selected files
          // this is the way o_o
          setDropzoneKey(dropzoneKey + 1)
        })
        .finally(() => {
          setUploadingFiles(false)
        })
    }
  }

  return {
    reset,
    handleSubmit,
    control,
    errors,
    isDirty,
    getAdminBooking,
    adminBookingData,
    getUserBooking,
    userBookingData,
    attachmentsLoading,
    attachmentsData,
    refetchAttachments,
    updatingPob,
    handleOnUpdate,
    handleFullScreen,
    handleFilesChanged,
    handleCancel,
    handleUploadFiles,
    handleSelectedAddressIdChange,
  }
}

export default usePobDetailsFunctions
