
import { useEffect, useState } from 'react'

import { Search } from '@mui/icons-material'
import { Box, InputAdornment, TextField } from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import SearchResults from 'components/navigation/Search/SearchResults'
import { DEBOUNCE_DELAY } from 'constants/index'
import {
  GetAccountPayableInvoiceSearchRequestQuery,
  useGetAccountPayableInvoiceSearchRequestLazyQuery,
} from 'generated/graphql'


const MultipleSearchAutocomplete = () => {
  const { setValue, control, reset } = useFormContext<any>()
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false)

  const inputValue = useWatch({ name: 'multipleSearch' })

  const [debouncedInputValue] = useDebounce(inputValue, DEBOUNCE_DELAY)

  const [getAccountPayableInvoiceSearchRequest, { data, loading }] =
    useGetAccountPayableInvoiceSearchRequestLazyQuery({
      fetchPolicy: 'no-cache',
    })

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length >= 1) {
      getAccountPayableInvoiceSearchRequest({
        variables: { query: debouncedInputValue },
      })
    }
  }, [debouncedInputValue, getAccountPayableInvoiceSearchRequest])

  const handleSearchResultClick = (
    searchResult: GetAccountPayableInvoiceSearchRequestQuery['accountPayableSearchResults'][0],
  ) => {
    reset()

    switch (searchResult.entityName) {
      case 'Driver / Supplier Name':
        setValue('driverName', searchResult.description)
        break
      case 'Driver Number':
        setValue('driverNumber', searchResult.description)
        break
      case 'CX Number':
        setValue('courierExchangeLoadId', searchResult.description)
        break
      case 'Our Reference':
        setValue('ourReference', searchResult.description)
        break
      case 'Net Invoice Amount':
        setValue('netInvoiceAmount', searchResult.description)
        break
      case 'Vat Invoice Amount':
        setValue('vatInvoiceAmount', searchResult.description)
        break
      case 'Gross Invoice Amount':
        setValue('grossInvoiceAmount', searchResult.description)
        break
    }

    setValue('multipleSearch', searchResult.description)
  }

  const handleSearchFocus = () => {
    setShowSearchResult(true)
  }

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSearchResult(false)
    }, 200)
  }

  return (
    <Box position='relative'>
      <Controller
        control={control}
        name={'multipleSearch'}
        render={({ field: { ref, ...restField } }) => (
          <TextField
            {...restField}
            inputRef={ref}
            fullWidth
            placeholder='SuppName, SuppNum, CX ID, FPL, Invoice amount'
            inputProps={{ 'aria-label': 'search' }}
            value={inputValue}
            onFocus={handleSearchFocus}
            onBlur={handleSearchBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      {inputValue && showSearchResult && (
        <SearchResults
          debouncedInputValue={inputValue}
          searchResultsData={data?.accountPayableSearchResults}
          loading={loading}
          onSearchResultClick={handleSearchResultClick}
        />
      )}
    </Box>
  )
}

export default MultipleSearchAutocomplete
