import React from 'react'

import { CreateAndUpdateOtherChargeDtoInput, useGetOtherChargeQuery } from 'generated/graphql'

import OtherChargeForm from './OtherChargeForm'

interface IProps {
  chargeId: number
  processing: boolean
  setNotFound: React.Dispatch<boolean>
  setOtherCharge: React.Dispatch<CreateAndUpdateOtherChargeDtoInput>
}

export default function UpdateOtherCharge(props: IProps) {
  const { chargeId, setOtherCharge, setNotFound, processing } = props
  const { loading: retrievingCharge } = useGetOtherChargeQuery({
    variables: {
      id: chargeId,
    },
    onError: () => {
      setNotFound(true)
    },
    onCompleted: (data) => {
      const { /* __typename, */ ...otherCharge } = data.otherCharge
      setOtherCharge({
        ...otherCharge,
      } as CreateAndUpdateOtherChargeDtoInput)
    },
  })

  return <OtherChargeForm processing={processing || retrievingCharge} />
}
