import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller } from 'react-hook-form'

import { DATE_FORMAT } from 'constants/index'

interface IProps {
  control: any
  name: string
  label: string
  defaultValue?: any
  variant?: 'standard' | 'filled' | 'outlined'
  required?: boolean
  fullWidth?: boolean
  disabled?: boolean
  autoComplete?: string
  disablePast?: boolean
  disableFuture?: boolean
  error?: any
  helperText?: any
}

const ControlledDatePicker = (props: IProps) => {
  const {
    control,
    name,
    defaultValue = '',
    variant,
    fullWidth = true,
    required,
    error,
    helperText,
    ...otherProps
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...restField } }) => (
        <DatePicker
          {...restField}
          inputRef={ref}
          format={DATE_FORMAT}
          value={restField.value ? new Date(restField.value) : null}
          slotProps={{
            textField: {
              variant: variant,
              fullWidth: fullWidth,
              required: required,
              error: error,
              helperText: helperText,
            },
          }}
          {...otherProps}
          onChange={restField.onChange}
        />
      )}
    />
  )
}

export default ControlledDatePicker
