import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Dialog, DialogContent } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ConfirmDialog, FplDialogTitle } from 'components/common'
import { DEFAULT_COUNTRY_ID } from 'constants/index'
import {
  CreateAndUpdateDriverDtoInput,
  DriverLicenseType,
  DriverType,
  PaymentTerm,
  useGetDriverByCurrentUserQuery,
  useUpdateDriverMutation,
} from 'generated/graphql'

import DriverOnboardingDialogValidation from './DriverOnboardingDialog.validation'
import OnboardingStepperForm from './OnboardingStepperForm'

const schema = DriverOnboardingDialogValidation()

export default function DriverOnboardingDialogue() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)

  const handleDialog = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClose = () => {
    setOpenConfirmDialog(true)
  }

  const handleConfirmDialog = () => {
    setIsOpen(false)
  }

  const { data: driverByCurrentUserData, loading: driverByCurrentUserLoading } =
    useGetDriverByCurrentUserQuery()

  const methods = useForm<CreateAndUpdateDriverDtoInput>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      driverType: DriverType.Contract,
      xeroCodeId: '',
      courierExchangeMemberId: '',
      xeroPurchaseCode: '',
      postcode: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      countryId: DEFAULT_COUNTRY_ID,
      email: '',
      telephoneNumber: '',
      startDate: new Date(),
      paymentTerm: PaymentTerm.MonthFromEndOfInvoiceMoth,
      invoiceEmail: '',
      eoriNumber: '',
      useDefaultRate: false,
      distanceRate: 0,
      minMiles: 0,
      minCharge: 0,
      currencyId: '',
      licenseType: DriverLicenseType.Standard,
      licenseExpiryDate: null,
      endorsements: '',
      lastPaid: null,
      vatCategoryId: '',
      bankName: '',
      bankAccountNumber: '',
      vatIdNumber: '',
      bankSortCode: '',
      employeeReference: '',
      isActive: true,
      creditCard: '',
      bankAccountHolderName: '',
      alternativeTelephoneNumber: '',
      internationalBankAccountNumber: '',
      companyRegistrationNumber: '',
    },
  })
  const hasFormErrors = Object.keys(methods.formState.errors).length !== 0

  useEffect(() => {
    if (
      driverByCurrentUserData &&
      !driverByCurrentUserData?.driverByCurrentUser?.isOnboardingCompleted
    ) {
      handleDialog()
    }
    if (driverByCurrentUserData?.driverByCurrentUser && !driverByCurrentUserLoading) {
      const { __typename, ...rest } = driverByCurrentUserData.driverByCurrentUser

      methods.reset({
        ...rest,
        vatCategoryId: rest.vatCategoryId ?? '',
      })
    }
  }, [driverByCurrentUserData, driverByCurrentUserLoading, methods, methods.reset])

  const [updateDriver, { loading: updatingDriver }] = useUpdateDriverMutation({
    onCompleted: (data) => {
      if (!data?.updateDriver?.id) {
        return
      }
      toast.success(`The Onboarding process was successfully completed.`)
    },
  })

  const onSubmit: SubmitHandler<CreateAndUpdateDriverDtoInput> = async (data) => {
    const result = await updateDriver({
      variables: {
        input: {
          id: String(driverByCurrentUserData?.driverByCurrentUser?.id),
          ...data,
          isOnboardingCompleted: true,
        },
      },
    })

    if (!result.data?.updateDriver?.id) {
      return
    } else {
      handleDialog()
    }
  }

  return (
    <>
      {driverByCurrentUserLoading ? (
        <CircularProgress />
      ) : (
        <Dialog fullScreen={true} open={isOpen} aria-label='driver-onboarding-dialog' keepMounted>
          <FplDialogTitle id='driver-onboarding-dialog' onClose={handleClose}>
            Onboarding
          </FplDialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <OnboardingStepperForm disabled={hasFormErrors} loading={updatingDriver} />
              </form>
            </FormProvider>
          </DialogContent>
        </Dialog>
      )}
      {openConfirmDialog && (
        <ConfirmDialog
          message={
            'Are you sure you want to end the on-boarding? Doing so will take you to your profile, but you will be required to finish the on-boarding next time.'
          }
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={handleConfirmDialog}
        />
      )}
    </>
  )
}
