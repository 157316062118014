import Joi from 'joi'

import { ContactEntity, EntityConstants } from 'constants/EntityConstants'

const CreateUpdateCustomerContactValidation = Joi.object({
  name: Joi.string().max(EntityConstants.MAX_NAME_LENGTH).empty(''),
  telephoneNumber: Joi.string().max(EntityConstants.MAX_TELEPHONE_NUMBER_LENGTH).empty(''),
  email: Joi.string().email({ tlds: false }).max(EntityConstants.MAX_EMAIL_LENGTH).empty(''),
  additionalEmails: Joi.string()
    .email({ multiple: true, separator: ';', tlds: false })
    .max(ContactEntity.MAX_ADDITIONAL_EMAILS_LENGTH)
    .allow(''),
})

export { CreateUpdateCustomerContactValidation }
