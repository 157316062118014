import Joi from 'joi'

import { ContactEntity, EntityConstants } from 'constants/EntityConstants'
import { CustomerAccountType, InvoiceFrequency, LegalAccountType } from 'generated/graphql'

const CustomerDetailsValidations = Joi.object({
  name: Joi.string().required().label('Trading Name'),
  postcode: Joi.string().required().label('Postcode'),
  countryId: Joi.number().required().integer().positive().label('Country ID').messages({
    'number.base': '"Country ID" is required',
    'number.integer': '"Country ID" is required',
    'number.positive': '"Country ID" is required',
    'any.required': '"Country ID" is required',
  }),
  addressLine1: Joi.string().required().label('Address (Line 1)'),
  addressLine2: Joi.string().allow(null, ''),
  city: Joi.string().required().label('City'),
  vatCategoryId: Joi.number().required().integer().positive().label('VAT Category ID'),
  invoiceEmail: Joi.string()
    .email({ tlds: false })
    .allow(null, '')
    .max(EntityConstants.MAX_EMAIL_LENGTH),
  additionalInvoiceEmails: Joi.string()
    .email({ multiple: true, separator: ';', tlds: false })
    .optional()
    .allow(null, '')
    .max(ContactEntity.MAX_ADDITIONAL_EMAILS_LENGTH),
  telephoneNumber: Joi.string()
    .allow(null, '')
    .optional()
    .regex(/^[0-9]*$/, 'numeric')
    .max(EntityConstants.MAX_TELEPHONE_NUMBER_LENGTH)
    .label('Tel No'),
  accountCode: Joi.string().required().label('Account Code'),
  vatIdNumber: Joi.string()
    .allow(null, '')
    .max(EntityConstants.MAX_VAT_ID_NUMBER_LENGTH)
    .label('VAT Number'),
  currencyId: Joi.number().required().integer().positive().allow(null, '').label('Currency'),
  legalAccountType: Joi.string()
    .valid(LegalAccountType.Business, LegalAccountType.Personal)
    .required()
    .label('Legal Account Type'),
  invoiceFrequency: Joi.string()
    .valid(InvoiceFrequency.Daily, InvoiceFrequency.Weekly)
    .required()
    .label('Invoice Frequency'),
  customerAccountType: Joi.string()
    .required()
    .valid(CustomerAccountType.Cash, CustomerAccountType.Credit, CustomerAccountType.Card)
    .label('Customer Account Type'),
  startDate: Joi.date().label('Start Date').required().messages({
    'date.base': 'The start date must be a valid date',
    'any.required': 'Start date is required',
  }),
})

export { CustomerDetailsValidations }
