import { Tab, Tooltip } from '@mui/material'

function commonProps(index) {
  return {
    id: `fpl-tab-${index}`,
    'aria-controls': `fpl-tabpanel-${index}`,
  }
}

interface IProps {
  tabDetails: { id: any; label: string }
  tooltipTitle: string
  tabProps: any
  disabled: boolean
}

const FplTab = (props: IProps) => {
  const { tabDetails, tooltipTitle, tabProps, disabled } = props

  return (
    <Tab
      {...tabProps}
      {...commonProps(tabDetails.id)}
      sx={{ pointerEvents: 'auto !important' }}
      value={tabDetails.id}
      disabled={disabled}
      label={
        <Tooltip
          title={tooltipTitle}
          disableHoverListener={!disabled}
          disableTouchListener={!disabled}
          disableFocusListener={!disabled}>
          <span>{tabDetails.label}</span>
        </Tooltip>
      }
    />
  )
}

export default FplTab
