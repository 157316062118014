import { LoadingButton } from '@mui/lab'
import { Button, Stack } from '@mui/material'
import { GridFooterContainer, GridPagination, useGridApiContext } from '@mui/x-data-grid'

import { EditedDriverRow, SelectedDriver } from './types'

interface IProps {
  onSaveClick: (rows: EditedDriverRow[]) => void
  onClearClick: () => void
  selectedDrivers: SelectedDriver[]
  loading: boolean
  processing?: boolean
}

const CustomGridFooter = (props: IProps) => {
  const { selectedDrivers, loading, onSaveClick, processing, onClearClick } = props

  const apiRef = useGridApiContext()

  const handleSavePress = () => {
    const selectedRows = apiRef.current.state.editRows

    const rows: EditedDriverRow[] = Object.keys(selectedRows).map((key) => ({
      driverId: key,
      currencyId: selectedRows[key].currencyId.value.toString(),
      extraPay: selectedRows[key].extraPay.value,
      minCharge: selectedRows[key].minCharge.value,
    }))

    onSaveClick(rows)
  }

  return (
    <GridFooterContainer>
      <Stack spacing={3} sx={{ width: '100%' }}>
        <Stack direction='column' spacing={3} mx={2} my={1}>
          {/* // ToDo: use spinier here */}
          {loading
            ? 'Loading...'
            : selectedDrivers.map((driver) => (
                <Stack
                  direction='row'
                  sx={{ paddingTop: 1, paddingLeft: 1 }}
                  spacing={1}
                  justifyContent='flex-start'
                  key={driver.driverId}>
                  {driver.driverName && (
                    <>
                      <strong>Driver:</strong> {driver.driverName}
                    </>
                  )}
                  {driver.selectedSubDriverName && (
                    <>
                      , <strong>Sub-driver:</strong> {driver.selectedSubDriverName}
                    </>
                  )}
                  {driver.selectedVehicleName && (
                    <>
                      , <strong>Vehicle:</strong> {driver.selectedVehicleName}
                    </>
                  )}
                  {driver.selectedContactName && (
                    <>
                      , <strong>Contact:</strong> {driver.selectedContactName}
                    </>
                  )}
                </Stack>
              ))}
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={3}
          justifyContent='space-between'
          alignItems='center'
          py={1}
          px={2}>
          <Stack direction='row' spacing={3}>
            <Button
              disabled={selectedDrivers.length === 0}
              onClick={onClearClick}
              variant='contained'
              color='grey'>
              Clear
            </Button>

            <LoadingButton
              variant='contained'
              loading={processing}
              disabled={selectedDrivers.length === 0 || loading}
              onClick={handleSavePress}>
              Add
            </LoadingButton>
          </Stack>

          <GridPagination />
        </Stack>
      </Stack>
    </GridFooterContainer>
  )
}

export default CustomGridFooter
