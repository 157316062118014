import { useCallback, useEffect, useMemo } from 'react'

import { useFormContext } from 'react-hook-form'

import { ControlledAutocomplete, IAutocompleteOptions, TextInput } from 'components/common'
import { DEFAULT_COUNTRY_ID, Mode } from 'constants/index'
import {
  Customer,
  CustomerOptionsFragment,
  GetBookingQuery,
  GetCustomerDefaultAddressQuery,
  GetUserBookingQuery,
  SortEnumType,
  useGetCustomerDefaultAddressLazyQuery,
  useGetCustomerProfileLazyQuery,
  useGetCustomersLazyQuery,
  useGetSubCustomersLazyQuery,
} from 'generated/graphql'
import { isPageLoadingVar } from 'graphql/reactiveVariables'
import { usePermission } from 'providers'
import { TBookingForm } from 'types'

interface IProps {
  bookingDetails: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  isQuickQuote: boolean
  disabled: boolean
  mode: number | undefined
}

const CustomersAutocomplete = (props: IProps) => {
  const { bookingDetails, isQuickQuote, disabled, mode } = props

  const {
    resetField,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<TBookingForm>()

  const [isAllowedToViewCustomers] = usePermission('ViewCustomerList')
  const [isAllowedToViewViewCustomerProfile] = usePermission('ViewCustomerProfile')

  const [getCustomers, { data: customerData, loading: customersLoading }] =
    useGetCustomersLazyQuery({
      variables: { order: { name: SortEnumType.Asc } },
    })

  const [getSubCustomers, { data: subCustomersData, loading: subCustomersLoading }] =
    useGetSubCustomersLazyQuery()

  const [getCustomerProfile, { data: customerProfileData }] = useGetCustomerProfileLazyQuery()

  const [
    getCustomerDefaultAddress,
    { data: customerDefaultAddressData, loading: customerDefaultAddressLoading, refetch },
  ] = useGetCustomerDefaultAddressLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const updateCustomerOptions = useCallback(
    (customer?: CustomerOptionsFragment) => {
      if (customer) {
        setValue('isReferenceRequired', customer?.isReferenceRequired, {
          shouldDirty: false,
        })
        setValue('isReasonCodeEnabled', customer?.isReasonCodeOptionEnabled, { shouldDirty: false })
        setValue('isPackageDetailsSectionOptional', customer?.isPackageDetailsOptional, {
          shouldDirty: false,
        })
      }
    },
    [setValue],
  )

  const setAddressBasedOnCustomer = useCallback(
    async (customerId: string) => {
      const { data: customerAddresses } = !customerDefaultAddressData
        ? await getCustomerDefaultAddress({
          variables: {
            customerId: Number(customerId),
          },
        })
        : await refetch({
          customerId: Number(customerId),
        })

      if (customerAddresses?.addresses?.edges?.length === 0) {
        resetField('collectionAddresses.0')
        return
      }

      let fetchedDefaultAddress:
        | NonNullable<NonNullable<GetCustomerDefaultAddressQuery['addresses']>['edges']>[0]['node']
        | null = null

      if (customerAddresses?.addresses?.edges) {
        fetchedDefaultAddress = customerAddresses.addresses.edges[0].node
      }

      const defaultFormAddress = getValues('collectionAddresses.0')

      setValue(
        'collectionAddresses.0',
        {
          ...defaultFormAddress,
          ...(!isQuickQuote && {
            name: fetchedDefaultAddress?.name || '',
            city: fetchedDefaultAddress?.city || '',
            addressLine1: fetchedDefaultAddress?.addressLine1 || '',
            addressLine2: fetchedDefaultAddress?.addressLine2 || '',
            contact: fetchedDefaultAddress?.contact || '',
            email: fetchedDefaultAddress?.email || '',
            telephoneNumber: fetchedDefaultAddress?.telephoneNumber || '',
          }),
          postcode: fetchedDefaultAddress?.postcode || '',
          countryId: fetchedDefaultAddress?.countryId || DEFAULT_COUNTRY_ID,
        },
        { shouldDirty: true },
      )
    },
    [
      customerDefaultAddressData,
      getCustomerDefaultAddress,
      getValues,
      isQuickQuote,
      refetch,
      resetField,
      setValue,
    ],
  )

  useEffect(() => {
    if (mode === Mode.Create) {
      if (isAllowedToViewCustomers) {
        getCustomers().then(async (result) => {
          const customerId = getValues('customerId')
          if (customerId) {
            const customer = result.data?.customers.find((x) => x.id === customerId)
            updateCustomerOptions(customer as Customer)
            await setAddressBasedOnCustomer(customerId)
          }
        })
      } else if (isAllowedToViewViewCustomerProfile) {
        getCustomerProfile()
          // retrieving related customers
          .then((result) => {
            if (result.data?.customerProfile.primaryCustomerId) {
              getSubCustomers({
                variables: {
                  primaryCustomerId: Number(result.data.customerProfile.primaryCustomerId),
                  isPrimaryCustomerIncluded: true,
                  order: { name: SortEnumType.Asc },
                },
              })
            }

            return result
          })
          // setting form with default data
          .then((result) => {
            if (result.data?.customerProfile.customerId) {
              setValue('customerId', String(result.data.customerProfile.customerId), {
                shouldDirty: false,
              })
              setValue('isReferenceRequired', result.data.customerProfile.isReferenceRequired, {
                shouldDirty: false,
              })
              setValue(
                'isReasonCodeEnabled',
                result.data.customerProfile.isReasonCodeOptionEnabled,
                { shouldDirty: false },
              )
              setValue(
                'isPackageDetailsSectionOptional',
                result.data.customerProfile.isPackageDetailsOptional,
                { shouldDirty: false },
              )
            }
          })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, isAllowedToViewCustomers, isAllowedToViewViewCustomerProfile])

  useEffect(() => {
    isPageLoadingVar(customerDefaultAddressLoading)
  }, [customerDefaultAddressLoading])

  const customers = customerData?.customers || subCustomersData?.subCustomers

  const customerOptions: IAutocompleteOptions = useMemo(
    () =>
      customers?.map((customer: { id: any; name: any }) => ({
        value: customer.id,
        label: customer.name,
      })) || [],
    [customers],
  )

  const bookingCustomerName = bookingDetails?.customer.name
  const profileCustomerTradingName = customerProfileData?.customerProfile.customerTradingName

  const customerTradingName = useMemo(() => {
    switch (mode) {
      case Mode.Create:
        return profileCustomerTradingName || ''

      case Mode.Update:
        return bookingCustomerName || ''
    }
  }, [bookingCustomerName, profileCustomerTradingName, mode])

  const handleCustomerValueChange = useCallback(
    async (customerId: string) => {
      const customer = customers?.find((x: { id: string }) => x.id === customerId)
      updateCustomerOptions(customer as Customer)
      await setAddressBasedOnCustomer(customerId)
    },
    [customers, updateCustomerOptions, setAddressBasedOnCustomer],
  )

  useEffect(() => {
    if (mode === Mode.Create) {
      const subscription = watch((data, { name }) => {
        if (name === 'customerId' && data.customerId) {
          handleCustomerValueChange(data.customerId)
        }
      })

      return () => {
        subscription.unsubscribe()
      }
    }
  }, [handleCustomerValueChange, mode, watch])

  return customerOptions?.length > 0 ? (
    <ControlledAutocomplete
      control={control}
      label='Customer'
      name='customerId'
      options={customerOptions}
      defaultValue=''
      required
      disabled={disabled}
      readOnly={mode === Mode.Update}
      loading={customersLoading || subCustomersLoading}
      disableClearable
      error={!!errors.customerId}
      helperText={errors.customerId?.message}
    />
  ) : (
    <TextInput name='customer' label='Customer' value={customerTradingName} required readOnly />
  )
}

export default CustomersAutocomplete
