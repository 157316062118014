export const PARAM_NEW = 'new'
export const PARAM_ID = ':id'
export type DefaultParams = {
  id?: string
}

export const Paths = {
  home: '/',
  profile: '/profile',
  myNotifications: '/my-notifications',

  // oidc
  signin: '/signin-oidc',
  logout: '/logout',
  logoutCallback: '/',
  register: '/register',
  silentRenew: '/signin-oidc',

  auth: {
    welcome: '/welcome',
    signUp: '/sign-up',
  },

  dashboard: {
    all: '/dashboard',
  },

  bookings: {
    startsWith: '/booking',
    all: '/bookings',
    create: `/booking/${PARAM_NEW}`,
    update: `/booking/${PARAM_ID}`,
    updateWithId: (id: any) => `/booking/${id}`,
  },

  quotes: {
    startsWith: '/quote',
    all: '/quotes',
    create: `/quote/${PARAM_NEW}`,
    update: `/quote/${PARAM_ID}`,
    updateWithId: (id: any) => `/quote/${id}`,
  },

  quickQuotes: {
    startsWith: '/quick-quote',
    all: '/quick-quotes',
    create: `/quick-quote/${PARAM_NEW}`,
    update: `/quick-quote/${PARAM_ID}`,
    updateWithId: (id: any) => `/quick-quote/${id}`,
  },

  potentialBookings: {
    startsWith: '/potential-booking',
    all: '/potential-bookings',
    process: `/potential-booking/${PARAM_ID}/:customerId`,
    processWithId: (id: any, customerId: any) => `/potential-booking/${id}/${customerId}`,
  },

  customers: {
    startsWith: '/customer',
    all: '/customers',
    create: `/customer/${PARAM_NEW}`,
    update: `/customer/${PARAM_ID}`,
    updateWithId: (id: any) => `/customer/${id}`,
  },

  suppliers: {
    all: '/suppliers',
  },

  drivers: {
    create: `/suppliers/driver/${PARAM_NEW}`,
    update: `/suppliers/driver/${PARAM_ID}`,
    updateWithId: (id: any) => `/suppliers/driver/${id}`,
    updateProfile: '/driver-details',
  },

  strategicPartners: {
    create: `/suppliers/strategicPartner/${PARAM_NEW}`,
    update: `/suppliers/strategicPartner/${PARAM_ID}`,
    updateWithId: (id: any) => `/suppliers/strategicPartner/${id}`,
  },

  users: {
    all: '/users',
    startsWith: '/user',

    // TODO: remove
    // create: `${process.env.REACT_APP_AUTH_URL}/Admin/user/Create`,
    // update: (id) => `${process.env.REACT_APP_AUTH_URL}/Admin/user/Edit/${id}`,
  },

  reports: {
    all: '/reports',
    externalInvoices: `/external-invoices/${PARAM_ID}`,
    matchExternalInvoices: (id: any) => `/external-invoices/${id}`,
    startsWith: '/report',
  },

  standardOperatingProcedures: {
    startsWith: '/standard-operating-procedure',
    all: '/standard-operating-procedures',
    create: `/standard-operating-procedure/${PARAM_NEW}`,
    update: `/standard-operating-procedure/${PARAM_ID}`,
    updateWithId: (id: any) => `/standard-operating-procedure/${id}`,
  },

  settings: {
    all: '/settings',
  },

  options: {
    all: '/options',
  },

  tariffs: {
    all: `/settings/tariffs`,
    create: `/settings/tariff/${PARAM_NEW}`,
    update: `/settings/tariff/${PARAM_ID}`,
    updateWithId: (id: any) => `/settings/tariff/${id}`,
  },

  accountsPayable: {
    all: '/accountsPayables',
    startsWith: '/accountPayable',
    documents: `/accountPayable/documents/${PARAM_ID}`,
    updateWithId: (id: any) => `/accountPayable/documents/${id}`,
  },

  help: {
    all: '/help',
  },

  requestAccess: '/request-access',
  trackingPage: '/live-tracking/:trackingNumber'
}
