import { Grid, Tooltip } from '@mui/material'

import { TextInput } from 'components/common'
import { GetBookingQuery, GetUserBookingQuery } from 'generated/graphql'

interface IProps {
  bookingDetails: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  hoverText: string
  disabled: boolean
}

const PoBookingDetails = (props: IProps) => {
  const { bookingDetails, hoverText, disabled } = props

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={3}>
            <TextInput
              name='customerName'
              label='Customer'
              value={bookingDetails?.customer.name || ''}
              readOnly
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextInput
              name='ourRef'
              label='Our Ref'
              value={bookingDetails?.ourReference || ''}
              readOnly
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextInput
              name='customerRef'
              label='Customer Ref'
              value={bookingDetails?.customerReference || ''}
              readOnly
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextInput
              name='docketRef'
              label='Docket Ref'
              value={bookingDetails?.docketReference || ''}
              readOnly
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  )
}

export default PoBookingDetails
