import { GridSortDirection, GridSortItem } from '@mui/x-data-grid'

import { IGridColumn, ISortModel } from './FplDataGrid'

interface IGetPaging {
  after?: string | null
  before?: string | null
  first?: number | null
  last?: number | null
}

const getPaging = ({ after = null, before = null, first = null, last = null }: IGetPaging) => {
  const firstElements = first && first < 0 ? 1000 : first

  return {
    after,
    before,
    first: firstElements,
    last,
  }
}

const getOrderBy = (path: Array<string>, sortDirection: GridSortDirection): any => {
  if (path.length === 1) {
    return { [path[0]]: sortDirection?.toUpperCase() }
  }

  return path.reduceRight(
    // @ts-ignore
    (previousValue, currentValue) => ({ [currentValue]: previousValue }),
    sortDirection?.toUpperCase(),
  )
}

const getSortModel = (field: string, sort: GridSortDirection, columns: Array<IGridColumn>) => {
  const selectedColumn = columns.find((value) => value.field === field)

  if (selectedColumn) {
    const path = selectedColumn.sortPath || [selectedColumn.field]

    return { field, sort, orderInput: getOrderBy(path, sort) }
  }
}

const getDefaultSortModel = (
  defaultOrder: GridSortItem | undefined,
  columns: Array<IGridColumn>,
): Array<ISortModel> => {
  if (defaultOrder === undefined) {
    return []
  }

  const sortModel = getSortModel(defaultOrder.field, defaultOrder.sort, columns)

  if (sortModel === undefined) {
    return []
  }

  return [sortModel]
}

export { getPaging, getSortModel, getDefaultSortModel }
