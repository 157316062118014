import { useContext } from 'react'

import { Permissions } from 'generated/graphql'

import PermissionContext from './PermissionContext'

type PermissionType = keyof Permissions | Array<keyof Permissions>

const usePermission = (permission: PermissionType) => {
  const { isAllowedTo, loading } = useContext(PermissionContext)

  return [isAllowedTo(permission), loading]
}

export { usePermission }
export type { PermissionType }
