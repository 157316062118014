import Joi from 'joi'

const validationSchema = Joi.object({
  rating: Joi.number().required().min(1).label('Rating').messages({
    'number.min': 'Rating is required',
    'number.base': 'Rating is required',
    'any.required': 'Rating is required',
  }),
  ratingComment: Joi.string().allow('', null).label('Comment').max(200),
})

export default validationSchema
