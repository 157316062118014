import { useOidc } from '@axa-fr/react-oidc-context'
import { Button, Grid, styled } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Paths } from 'constants/index'

import { PrimaryButton } from '../common'

const SecondaryButton = styled(Button)({
  textTransform: 'none',
  borderRadius: '20px',
  fontFamily: 'Goldplay-Medium, sans-serif',
  fontSize: '16px',
  padding: '6px 16px',
})

const SignInActions = () => {
  const { login } = useOidc()
  const history = useHistory()

  const handleLogin = () => login()

  const handleForgotPassword = () => {
    const forgotPasswordUrl = `${process.env.REACT_APP_AUTH_URL}/Account/ForgotPassword`
    window.location.replace(forgotPasswordUrl)
  }

  const handleSignUp = () => {
    history.push(Paths.auth.signUp)
  }

  return (
    <Grid container rowSpacing={0.5} justifyContent='space-between'>
      <Grid item xs={12}>
        <PrimaryButton onClick={handleLogin}>Sign in</PrimaryButton>
      </Grid>
      <Grid item>
        <SecondaryButton color='grey' fullWidth onClick={handleForgotPassword}>
          Forgotten password?
        </SecondaryButton>
      </Grid>
      <Grid item>
        <SecondaryButton color='grey' fullWidth onClick={handleSignUp}>
          Sign up
        </SecondaryButton>
      </Grid>
    </Grid>
  )
}

export default SignInActions
