import React from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Grid } from '@mui/material'
import { addSeconds } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledSelector, ControlledTimePicker } from 'components/common'
import WeekdaysGroup from 'components/settings/Surcharges/CreateUpdateCongestionZone/WeekdaysGroup'
import { ProcessOnTypeValues } from 'constants/index'
import {
  ScheduledBookingProcessOnType,
  ScheduledBookingTemplate,
  UpdateScheduledBookingTemplateDtoInput,
  useUpdateScheduledBookingTemplateMutation,
  Weekday,
} from 'generated/graphql'

interface IProps {
  template: ScheduledBookingTemplate | null
}

const ScheduledDays = (props: IProps) => {
  const { template } = props
  const [selectedWeekdays, setSelectedWeekdays] = React.useState<Array<Weekday>>(
    template?.weekdaysCollection ?? [Weekday.None],
  )

  // Queries & Mutations
  const { control, handleSubmit, watch, setValue } =
    useForm<UpdateScheduledBookingTemplateDtoInput>({
      shouldUnregister: true,
      defaultValues: {
        id: template?.id,
        processOn: template?.processOn,
        processOnOnwards: template?.processOnOnwardsTotalSeconds
          ? addSeconds(new Date(0), template.processOnOnwardsTotalSeconds ?? 0).toString()
          : null,
        weekdays: template?.weekdaysCollection,
      },
    })

  const [updateTemplate, { loading: updatingTemplate }] = useUpdateScheduledBookingTemplateMutation(
    {
      onCompleted: (result) =>
        toast.success(
          `Template with ID ${result.updateScheduledBookingTemplate.id} updated with success.`,
        ),
    },
  )

  // Weekdays field
  React.useEffect(
    () => setValue('weekdays', selectedWeekdays, { shouldDirty: true }),
    [setValue, selectedWeekdays],
  )

  // Process On field
  const processOn = watch('processOn')

  React.useEffect(() => {
    if (processOn === ScheduledBookingProcessOnType.SameDay) {
      setValue('processOnOnwards', null)
    }
  }, [processOn, setValue])

  const onSubmit = (data: UpdateScheduledBookingTemplateDtoInput) => {
    updateTemplate({
      variables: {
        input: {
          ...data,
          id: template?.id,
          weekdays: selectedWeekdays,
          processOnOnwards: data.processOnOnwards ? new Date(data.processOnOnwards) : null,
        },
      },
    })
  }

  return (
    <>
      {/* Uncomment to see Template data */}
      {/* <pre>{JSON.stringify(template, null, 2)}</pre> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction='column' spacing={2}>
          <Grid item container direction='row' justifyContent='center' alignItems='center'>
            <Grid item>
              <Controller
                control={control}
                name='weekdays'
                render={() => (
                  <WeekdaysGroup
                    selectedWeekdays={selectedWeekdays}
                    setSelectedWeekdays={setSelectedWeekdays}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            columnSpacing={3}>
            <Grid item xs={12} sm={3}>
              <ControlledSelector
                control={control}
                name='processOn'
                label='Process On'
                options={ProcessOnTypeValues}
                required
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <ControlledTimePicker
                control={control}
                name='processOnOnwards'
                label='Onwards'
                disabled={processOn === ScheduledBookingProcessOnType.SameDay}
                required={processOn === ScheduledBookingProcessOnType.DayBefore}
              />
            </Grid>
          </Grid>

          <Grid item>
            <Box display='flex' justifyContent='center'>
              <LoadingButton
                type='submit'
                variant='contained'
                color='primary'
                loading={updatingTemplate}>
                Update
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ScheduledDays
