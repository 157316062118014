import { Box, Grid, Paper } from '@mui/material'

import { CardTitle } from 'components/common/controls'

import FilterCriteriaForm from './FilterCriteriaForm'

const AccountPayableFilter = () => {
  return (
    <Box p={2}>
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xs={true}>
          <Paper variant='outlined'>
            <Grid p={2}>
              <CardTitle>Filter Criteria</CardTitle>
              <Box>
                <FilterCriteriaForm />
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AccountPayableFilter
