import { AccountPayableTabHash, Paths } from 'constants/index'

import { useEffect, useMemo, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Typography, Paper } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/styles'
import { GridRowId, GridSortItem } from '@mui/x-data-grid'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import ManagePaymentSheduleDialog from 'components/accountsPayable/ManagePaymentSheduleDialog'
import { ConfirmDialog, FplDataGrid } from 'components/common'
import {
  useCreateSmartApprovalPaymentMutation,
  useGetAllAccountPayableRecordsQuery,
  useUpdateFailedPaymentStatusMutation,
} from 'generated/graphql'
import {
  GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY,
  GET_ALL_GROUPED_ACCOUNTPAYABLE_RECORDS_QUERY,
} from 'graphql/queries/AccountPayableRecordQueries'
import {
  accountPayableFiltersDataVar,
  accountPayableFiltersFormVar,
  accountPayableStatusCategoryVar,
} from 'graphql/reactiveVariables'
import { dateTimeZoneFormat } from 'helpers'
import useGetAccountsPayableColumns from 'hooks/accountsPayable/useGetAccountsPayableColumns'
import useGetAccountsPayableFilters from 'hooks/accountsPayable/useGetAccountsPayableFilters'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import ActionOptions from './ActionOptions'
import TotalSumDetails from './TotalSumDetails'
import { tabsList } from './AccountsPayableTabs'

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
})

const descendingOrderByInvoiceCount: GridSortItem = {
  field: 'invoiceCount',
  sort: 'desc',
}

const descendingOrderByStatus: GridSortItem = {
  field: 'status',
  sort: 'desc',
}

const defaultGridId = 'accountPayableRecords'
const AccountsPayable = () => {
  const tab = window.location.href.split('#')[1]
  const activeTabId = tabsList.find((tabItem) => tabItem.hash === `#${tab}`)?.id
  const groupToggleButtonState = localStorage.getItem(`${tab}_groupBy`)

  const [displayGroupedGrid, setDisplayGroupedGrid] = useState<boolean>(
    groupToggleButtonState && JSON.parse(groupToggleButtonState)
      ? JSON.parse(groupToggleButtonState)
      : false,
  )
  const [supplierFilterId, setSupplierFilterId] = useState(-1)
  const accountPayableFilters = useReactiveVar(accountPayableFiltersFormVar)
  const [supplierGridId, setSupplierGridId] = useState(defaultGridId)
  const [selectedApIds, setSelectedApIds] = useState<GridRowId[]>([])
  const [grid, setGrid] = useState<React.MutableRefObject<GridApiCommunity>>()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [managePaymentSheduleOpen, setManagePaymentSheduleOpen] = useState(false)
  const [openApproveAllModal, setOpenApproveAllModal] = useState(false)

  const isFlagged = tab === AccountPayableTabHash.Flagged
  const isPaymentFailed = tab === AccountPayableTabHash.PaymentFailed

  const [ungroupedTableFilter, groupedTableFilter] = useGetAccountsPayableFilters({
    supplierFilterId,
  })
  const [ungroupedColumns, groupedColumns] = useGetAccountsPayableColumns(activeTabId)
  const history = useHistory()

  // Queries
  const { data: allAccountPayableRecordsData, refetch: refetchAllAccountPayableRecords } =
    useGetAllAccountPayableRecordsQuery({
      fetchPolicy: 'no-cache',
      variables: { where: ungroupedTableFilter },
    })

  // Mutations
  const [createSmartPayment, { loading: creatingSmartPayment }] =
    useCreateSmartApprovalPaymentMutation({
      onCompleted: () => {
        setSelectedApIds([])
        toast.success('Payment approved')
        refetchAllAccountPayableRecords()
      },
      onError: () => {
        setSelectedApIds([])
        toast.error('Error approving payment')
      },
    })

  const [updateFailedPaymentStatus, { loading: updatingFailedPaymentStatus }] =
    useUpdateFailedPaymentStatusMutation({
      onCompleted: () => {
        setSelectedApIds([])
        toast.success('Payment approved')
        refetchAllAccountPayableRecords()
      },
      onError: () => {
        setSelectedApIds([])
        toast.error('Error approving payment')
      },
    })

  // Methods
  const handleUngroupedChange = (e) => {
    localStorage.setItem(`${tab}_groupBy`, e.target.checked)
    setDisplayGroupedGrid(e.target.checked)
  }

  const handleGroupedChange = () => {
    setSupplierFilterId(-1)
    setDisplayGroupedGrid(true)
  }

  const handleRowClickGroupedGrid = (e) => {
    setSupplierGridId(defaultGridId + '_' + e.row.driverId)
    setSupplierFilterId(e.row.driverId)
    setDisplayGroupedGrid(!displayGroupedGrid)
  }

  const handleRowClickAllGrid = (e) => {
    localStorage.setItem('previousUrl', window.location.href)
    history.push(Paths.accountsPayable.updateWithId(e.id))
  }

  const handleApprove = () => {
    if (selectedApIds) {
      createSmartPayment({
        variables: {
          accountPayableIds: selectedApIds.map((id) => parseInt(id.toString(), 10)),
        },
      })
    }
  }
  const handleUpdateFailedPaymentStatus = () => {
    if (selectedApIds) {
      updateFailedPaymentStatus({
        variables: {
          accountPayableIds: selectedApIds.map((id) => parseInt(id.toString(), 10)),
        },
      })
    }
  }

  const handleConfirm = (selectedApIds, isFlagged, isPaymentFailed) => {
    switch (true) {
      case selectedApIds.length === 0:
        return null
      case isFlagged:
        return handleApprove
      case isPaymentFailed:
        return handleUpdateFailedPaymentStatus
      default:
        return null
    }
  }

  const getFplsNumberForSelectedRows = (): string[] => {
    const fplsList: string[] = []
    if (grid?.current) {
      const selectedRows = grid.current.getSelectedRows()
      selectedRows?.forEach((key) => {
        const fpl: string = key.bookingInvoice.booking.ourReference
        fplsList.push(' ' + fpl)
      })
    }
    return fplsList
  }

  const accountPayableStatusCategory = useReactiveVar(accountPayableStatusCategoryVar)

  const accountPayableDueDateStartCategory = useMemo(() => {
    return dateTimeZoneFormat(accountPayableFilters.dueInStartDate, 'start')
  }, [accountPayableFilters.dueInStartDate])

  const accountPayableDueDateEndCategory = useMemo(() => {
    return dateTimeZoneFormat(accountPayableFilters.dueInEndDate, 'end')
  }, [accountPayableFilters.dueInEndDate])

  const { totalsArray, totalRecords } = useMemo(() => {
    const totalsPerCurrency = {}

    allAccountPayableRecordsData?.allAccountPayableRecords.forEach((row) => {
      const {
        currency: { symbol },
        net,
        vat,
        gross,
      } = row

      if (!totalsPerCurrency[symbol]) {
        totalsPerCurrency[symbol] = { net, vat, gross }
      } else {
        totalsPerCurrency[symbol].net += net
        totalsPerCurrency[symbol].vat += vat
        totalsPerCurrency[symbol].gross += gross
      }
    })

    const totalsArray = Object.keys(totalsPerCurrency).map((currency) => ({
      currencySymbol: currency,
      net: totalsPerCurrency[currency].net,
      vat: totalsPerCurrency[currency].vat,
      gross: totalsPerCurrency[currency].gross,
    }))

    return {
      totalsArray,
      totalRecords: allAccountPayableRecordsData?.allAccountPayableRecords.length,
    }
  }, [allAccountPayableRecordsData])

  useEffect(() => {
    accountPayableFiltersDataVar({
      totalRecords,
      dataPerCurrency: totalsArray,
    })
  }, [totalRecords, totalsArray])

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <StyledBox>
          {supplierFilterId !== -1 && (
            <>
              <Button sx={{ marginRight: '15px' }} onClick={() => handleGroupedChange()}>
                <ArrowBackIcon color='primary'></ArrowBackIcon>
                <Typography color={'primary'}>Back</Typography>
              </Button>
            </>
          )}
          {supplierFilterId === -1 && (
            <FormControlLabel
              sx={{ whiteSpace: 'nowrap' }}
              onChange={(e) => handleUngroupedChange(e)}
              control={<Switch checked={displayGroupedGrid} />}
              label='Group by supplier'
            />
          )}
          <TotalSumDetails
            handleOpenApproveAllModal={() => setOpenApproveAllModal(true)}
            selectedItems={selectedApIds}
          />
        </StyledBox>
        <ActionOptions
          handleOpenModal={() => setConfirmOpen(true)}
          handleOpenManagePaymentShedule={() => setManagePaymentSheduleOpen(true)}
          selectedItems={selectedApIds}
        />
        {!displayGroupedGrid && !creatingSmartPayment && !updatingFailedPaymentStatus && (
          <FplDataGrid
            setGridRef={setGrid}
            query={GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY}
            overwriteId={supplierGridId}
            isHideOldData={true}
            refetchWithDelay
            filter={ungroupedTableFilter}
            defaultOrder={descendingOrderByStatus}
            variableSortOrder={descendingOrderByStatus}
            checkboxSelection
            entityName='accountPayableRecords'
            toolbar={{
              caption: 'All',
            }}
            columns={ungroupedColumns}
            onRowClick={(e) => handleRowClickAllGrid(e)}
            onSelectionModelChange={(e) => setSelectedApIds(e)}
          />
        )}
        {displayGroupedGrid && (
          <FplDataGrid
            query={GET_ALL_GROUPED_ACCOUNTPAYABLE_RECORDS_QUERY}
            queryVariables={{
              status: accountPayableStatusCategory,
              dueDateStart: accountPayableDueDateStartCategory,
              dueDateEnd: accountPayableDueDateEndCategory,
              accountsPayableFilterCriteria: accountPayableFilters.dateSelection,
            }}
            isHideOldData={true}
            refetchWithDelay
            filter={groupedTableFilter}
            variableSortOrder={descendingOrderByInvoiceCount}
            defaultOrder={descendingOrderByInvoiceCount}
            checkboxSelection
            entityName='groupedAccountPayableRecords'
            toolbar={{
              caption: 'All',
            }}
            columns={groupedColumns}
            onRowClick={(e) => handleRowClickGroupedGrid(e)}
          />
        )}
      </Box>
      <ConfirmDialog
        open={confirmOpen}
        onConfirm={selectedApIds.length > 0 ? handleApprove : null}
        setOpen={setConfirmOpen}
        title={`Approve Account Payable Records`}
        message={`You chose to approve the selected Records: ${getFplsNumberForSelectedRows()}`}
        option1='Cancel'
        option2='Approve'
      />
      <ConfirmDialog
        open={openApproveAllModal}
        onConfirm={handleConfirm(selectedApIds, isFlagged, isPaymentFailed)}
        setOpen={setOpenApproveAllModal}
        title={`Approve Account Payable Records`}
        message={`You chose to approve all selected records which will be sent to Scheduled Payments
          `}
        option1='Cancel'
        option2='Confirm'
      />
      <ManagePaymentSheduleDialog
        open={managePaymentSheduleOpen}
        setOpen={setManagePaymentSheduleOpen}
        selectedIds={selectedApIds}
      />
    </Paper>
  )
}

export default AccountsPayable
