import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import {
  CreateAndUpdateQuoteLostReasonDtoInput,
  useGetQuoteLostReasonQuery,
} from 'generated/graphql'

import QuoteLostReasonForm from './QuoteLostReasonForm'

interface IProps {
  id: number
}

const UpdateQuoteLostReason = (props: IProps) => {
  const { id } = props
  const { reset } = useFormContext<CreateAndUpdateQuoteLostReasonDtoInput>()

  // queries
  const { data, loading } = useGetQuoteLostReasonQuery({
    variables: { id },
  })

  useEffect(() => {
    if (data) {
      reset({ ...data.quoteLostReason })
    }
  }, [data, reset])

  return (
    <>
      <QuoteLostReasonForm />
      <LoadingBackdrop loading={loading} />
    </>
  )
}

export default UpdateQuoteLostReason
