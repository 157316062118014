import { useEffect } from 'react'

import { Roles } from 'constants/index'
import { useGetDriverProfileByUserLazyQuery } from 'generated/graphql'
import { useUserRole } from 'hooks'

export const useGetCurrentDriver = () => {
  const userRole = useUserRole()

  const isDriver = userRole === Roles.driver

  const [getDriverProfile, { data }] = useGetDriverProfileByUserLazyQuery()

  useEffect(() => {
    if (isDriver) {
      getDriverProfile()
    }
  }, [getDriverProfile, isDriver])

  return {
    data,
    isDriver,
  }
}
