import { gql } from '@apollo/client'

import { ReportRowsFragment } from '../fragments'

export const GET_CUSTOMER_INCOME_REPORT = gql`
  query getCustomerIncomeReport($input: CustomerIncomeReportInputDtoInput!) {
    customerIncomeReport(input: $input) {
      ...ReportRows
    }
    ${ReportRowsFragment}
  }
`

export const GET_MY_INCOME_REPORT = gql`
  query getMyIncomeReport($input: MyIncomeReportInputDtoInput!) {
    myIncomeReport(input: $input) {
      ...ReportRows
    }
    ${ReportRowsFragment}
  }
`

export const GET_CUSTOMER_CONTACT_LIST_REPORT = gql`
  query getCustomerContactListReport($input: CustomerContactListReportInputDtoInput!) {
    customerContactListReport(input: $input) {
      ...ReportRows
    }
    ${ReportRowsFragment}  
  }
`

export const GET_DRIVER_LIST_REPORT = gql`
  query getDriverListReport($input: DriverListReportInputDtoInput!) {
    driverListReport(input: $input) {
      ...ReportRows
    }
    ${ReportRowsFragment}
  }
`

export const GET_SALES_COMMISSION_REPORT = gql`
  query getSalesCommissionReport($input: SalesCommissionReportInputDtoInput!) {
    salesCommissionReport(input: $input) {
      ...ReportRows
    }
    ${ReportRowsFragment}  
  }
`

export const GET_PROOF_OF_DELIVERY_REPORT = gql`
  query getProofOfDeliveryReport($input: ProofOfDeliveryReportInputDtoInput!) {
    proofOfDeliveryReport(input: $input) {
      ...ReportRows
    }
    ${ReportRowsFragment}
  }
`

export const GET_PAGED_EXTERNAL_DRIVER_INVOICES_QUERY = gql`
  query getPagedExternalDriverInvoices(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ExternalDriverInvoiceSortInput!]
    $where: ExternalDriverInvoiceFilterInput
  ) {
    pagedExternalDriverInvoices(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          reference
          description
          quantity
          bookingInvoiceId
          bookingInvoice {
            id
            bookingId
          }
          invoiceNumber
          fileId
          createdAt
          createdBy
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
