import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Typography } from '@mui/material'
import BatchDetailsGrid from 'components/bookingInvoiceBatchDetails/BatchDetailsGrid'
import { FplDataGrid } from 'components/common'
import { BookingInvoiceBatchFilterInput, InvoiceType } from 'generated/graphql'
import { GET_BOOKING_INVOICE_BATCHES } from 'graphql/queries'
import { useGetInvoiceBatchesColummns } from 'hooks/accountsPayable/useGetInvoiceBatchesColumns'

const invoiceBatchFilter: BookingInvoiceBatchFilterInput = {
  invoiceType: { eq: InvoiceType.Purchase },
}

const InvoiceBatches = () => {
  const [selectedBatchRow, setSelectedBatchRow] = useState<null | number>(null)

  const handleRowClickAllGrid = (row) => {
    setSelectedBatchRow(row.id)
  }

  const setSelectedApIds = (id) => {
    console.log(id)
  }

  const columns = useGetInvoiceBatchesColummns()

  return (
    <Box>
      {!selectedBatchRow && (
        <FplDataGrid
          query={GET_BOOKING_INVOICE_BATCHES}
          refetchWithDelay
          filter={invoiceBatchFilter}
          checkboxSelection
          entityName='bookingInvoiceBatches'
          toolbar={{
            caption: 'Invoice Batches',
          }}
          columns={columns}
          onRowClick={(e) => handleRowClickAllGrid(e)}
          onSelectionModelChange={(e) => setSelectedApIds(e)}
        />
      )}
      {selectedBatchRow && (
        <>
          <Button sx={{ marginRight: '15px' }} onClick={() => setSelectedBatchRow(null)}>
            <ArrowBackIcon color='primary'></ArrowBackIcon>
            <Typography color={'primary'}>Back</Typography>
          </Button>

          <BatchDetailsGrid batchId={selectedBatchRow} />
        </>
      )}
    </Box>
  )
}

export default InvoiceBatches
