import { BookingStatus, CalculatedStatus } from 'generated/graphql'
import { PermissionType } from 'providers/PermissionProvider'

enum StatusButtonsId {
  BookQuote = 0,
  MakeCompleted,
  MakeOnHold,
  Copy,
  MakeActive,
  MoveToInvoice,
  Archive,
  CancelBook,
  Restore,
  Delete,
  ConvertToScheduledTemplate,
  Runsheet,
  DisableOrEnable,
  QuoteLost,
  ConfirmQuickQuote,
}

function createButtons(
  permissions: PermissionType[],
  isAllowedTo: (permission: PermissionType) => boolean,
) {
  const buttons: Array<{ id: StatusButtonsId; label: string }> = []

  if (permissions.includes('ViewCancelBookingButton') && isAllowedTo('ViewCancelBookingButton')) {
    buttons.push({ id: StatusButtonsId.CancelBook, label: 'Cancel' })
  }

  if (permissions.includes('CompleteBooking') && isAllowedTo('CompleteBooking')) {
    buttons.push({ id: StatusButtonsId.MakeCompleted, label: 'Complete' })
  }

  if (permissions.includes('CreateBookingInvoice') && isAllowedTo('CreateBookingInvoice')) {
    buttons.push({ id: StatusButtonsId.MoveToInvoice, label: 'Move to Invoice' })
  }

  if (permissions.includes('ArchiveBooking') && isAllowedTo('ArchiveBooking')) {
    buttons.push({ id: StatusButtonsId.Archive, label: 'Archive' })
  }

  if (permissions.includes('SendDocketDetailsEmail') && isAllowedTo('SendDocketDetailsEmail')) {
    buttons.push({ id: StatusButtonsId.Runsheet, label: 'Send Docket Details' })
  }

  if (permissions.includes('MakeBookingOnHold') && isAllowedTo('MakeBookingOnHold')) {
    buttons.push({ id: StatusButtonsId.MakeOnHold, label: 'Make On Hold' })
  }

  if (permissions.includes('CopyBooking') && isAllowedTo('CopyBooking')) {
    buttons.push({ id: StatusButtonsId.Copy, label: 'Copy' })
  }

  if (
    permissions.includes('CreateScheduledBookingTemplate') &&
    isAllowedTo('CreateScheduledBookingTemplate')
  ) {
    buttons.push({
      id: StatusButtonsId.ConvertToScheduledTemplate,
      label: 'Make Scheduled Template',
    })
  }

  if (permissions.includes('DeleteBooking') && isAllowedTo('DeleteBooking')) {
    buttons.push({ id: StatusButtonsId.Delete, label: 'Delete' })
  }

  return buttons
}

function getStatusButtons(
  calculatedStatus: CalculatedStatus,
  status: BookingStatus,
  isAllowedTo: (permission: PermissionType) => boolean,
): Array<{ id: StatusButtonsId; label: string }> {
  // Quote
  const buttonsForQuote: Array<{ id: StatusButtonsId; label: string }> = []
  const buttonsForQuickQuote: Array<{ id: StatusButtonsId; label: string }> = []

  if (isAllowedTo('MarkQuoteWon')) {
    buttonsForQuote.push({ id: StatusButtonsId.BookQuote, label: 'Quote Won (Book)' })
    buttonsForQuickQuote.push({
      id: StatusButtonsId.ConfirmQuickQuote,
      label: 'Confirm Quick Quote',
    })
  } else if (isAllowedTo('BookQuote')) {
    buttonsForQuote.push({ id: StatusButtonsId.BookQuote, label: 'Book' })
    buttonsForQuickQuote.push({
      id: StatusButtonsId.ConfirmQuickQuote,
      label: 'Confirm Quick Quote',
    })
  }

  if (isAllowedTo('MarkQuoteLost')) {
    buttonsForQuote.push({ id: StatusButtonsId.QuoteLost, label: 'Quote Lost (or Cancelled)' })
    buttonsForQuickQuote.push({ id: StatusButtonsId.QuoteLost, label: 'Quote Lost (or Cancelled)' })
  } else if (isAllowedTo('ViewCancelQuoteButton')) {
    buttonsForQuote.push({ id: StatusButtonsId.CancelBook, label: 'Cancel' })
    buttonsForQuickQuote.push({ id: StatusButtonsId.CancelBook, label: 'Cancel' })
  }

  if (isAllowedTo('CopyBooking')) {
    buttonsForQuote.push({ id: StatusButtonsId.Copy, label: 'Copy' })
  }

  switch (calculatedStatus) {
    case CalculatedStatus.Quotation:
      return buttonsForQuote

    case CalculatedStatus.IsQuickQuote:
      return buttonsForQuickQuote

    case CalculatedStatus.IsPreBooked: {
      if (status === BookingStatus.Quotation) {
        return buttonsForQuote
      }

      const permissions: PermissionType = [
        'ViewCancelBookingButton',
        'MakeBookingOnHold',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
        'DeleteBooking',
      ]

      if (status === BookingStatus.Allocated) {
        permissions.push('SendDocketDetailsEmail')
      }

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.IsPending: {
      if (status === BookingStatus.Quotation) {
        return buttonsForQuote
      }

      const permissions: PermissionType = [
        'ViewCancelBookingButton',
        'MakeBookingOnHold',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
        'DeleteBooking',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.Waiting: {
      const permissions: PermissionType = [
        'ViewCancelBookingButton',
        'MakeBookingOnHold',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
        'DeleteBooking',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.Allocated: {
      const permissions: PermissionType = [
        'ViewCancelBookingButton',
        'SendDocketDetailsEmail',
        'MakeBookingOnHold',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
        'DeleteBooking',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.PackageOnBoardOnWay:
    case CalculatedStatus.PackageOnBoardPartial:
    case CalculatedStatus.PackageOnBoardArrival:
    case CalculatedStatus.PackageOnBoard:
    case CalculatedStatus.ProofOfDeliveryOnWay:
    case CalculatedStatus.ProofOfDeliveryPartial:
    case CalculatedStatus.ProofOfDeliveryArrival: {
      const permissions: PermissionType = [
        'SendDocketDetailsEmail',
        'MakeBookingOnHold',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
        'DeleteBooking',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.ProofOfDelivery: {
      const permissions: PermissionType = [
        'CompleteBooking',
        'SendDocketDetailsEmail',
        'MakeBookingOnHold',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
        'DeleteBooking',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.Complete: {
      const permissions: PermissionType = [
        'CreateBookingInvoice',
        'SendDocketDetailsEmail',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.Invoice: {
      const permissions: PermissionType = [
        'ArchiveBooking',
        'CopyBooking',
        'CreateScheduledBookingTemplate',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.IsArchived: {
      const permissions: PermissionType = ['CopyBooking', 'CreateScheduledBookingTemplate']
      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.IsCancelledOrQuoteLost: {
      const permissions: PermissionType = [
        'CopyBooking',
        'CreateScheduledBookingTemplate',
        'DeleteBooking',
      ]

      return createButtons(permissions, isAllowedTo)
    }

    case CalculatedStatus.OnHold: {
      const permissions: PermissionType = ['CopyBooking', 'CreateScheduledBookingTemplate']
      const buttons = createButtons(permissions, isAllowedTo)

      if (isAllowedTo('MakeBookingOnHold')) {
        buttons.unshift({ id: StatusButtonsId.MakeActive, label: 'Make Active' })
      }

      return buttons
    }

    case CalculatedStatus.ScheduledTemplate: {
      const permissions: PermissionType = ['DeleteBooking']
      return createButtons(permissions, isAllowedTo)
    }

    default:
      return []
  }
}

export { StatusButtonsId, getStatusButtons }
