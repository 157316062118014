import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const reactPlugin = new ReactPlugin()

let appInsights: ApplicationInsights | null = null

if (process.env.REACT_APP_INSIGHTS_KEY) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_INSIGHTS_KEY,
      enableAutoRouteTracking: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      extensions: [reactPlugin],
    },
  })
  appInsights.loadAppInsights()
}

export { reactPlugin, appInsights }
