import React from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'
import { EmailTemplate } from 'components/settings/CustomerEmails/EmailTemplate'
import { EmailTemplateType } from 'generated/graphql'

import { EmailBoxSx, EmailTabSx } from '../SettingsStyles'

enum TabId {
  DriverSignature = 0,
  DriverTerms,
  DriverDocket,
  DriverInvoice,
  DeleteBooking,
}

function commonProps(index: any) {
  return {
    id: `driver-emails-tab-${index}`,
    'aria-controls': `driver-emails-tabpanel-${index}`,
  }
}

const DriverEmails = () => {
  const [tabValue, setTabValue] = React.useState(0)

  const tabs = [
    {
      id: TabId.DriverSignature,
      label: 'Driver Signature',
      type: EmailTemplateType.SignatureDriver,
    },
    {
      id: TabId.DriverTerms,
      label: 'Driver Terms',
      type: EmailTemplateType.TermsDriver,
    },
    {
      id: TabId.DriverDocket,
      label: 'Driver Docket',
      type: EmailTemplateType.DriverDocket,
    },
    {
      id: TabId.DriverInvoice,
      label: 'Driver Invoice',
      type: EmailTemplateType.DriverInvoice,
    },
    {
      id: TabId.DeleteBooking,
      label: 'Delete Booking',
      type: EmailTemplateType.DriverDeleteBooking,
    },
  ]

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={EmailBoxSx}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='driver emails tabs'
        variant='scrollable'
        sx={EmailTabSx}>
        {tabs.map((tab) => (
          <Tab label={tab.label} key={tab.id} {...commonProps(tab.id)} />
        ))}
      </Tabs>

      {tabs.map((tab) => (
        <VerticalTabPanel value={tabValue} index={tab.id} key={tab.id} px={3}>
          <EmailTemplate type={tab.type} label={tab.label} />
        </VerticalTabPanel>
      ))}
    </Box>
  )
}

export default DriverEmails
