import { gql } from '@apollo/client'

import { UserProfileFragment } from 'graphql/fragments'

export const CREATE_USER_MUTATION = gql`
  mutation createUser($input: CreateUpdateUserDtoInput!) {
    createApplicationUser(input: $input) {
      id
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: CreateUpdateUserDtoInput!) {
    updateApplicationUser(input: $input) {
      id
    }
  }
`

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: Long!) {
    deleteApplicationUser(id: $id)
  }
`

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation updateUserProfile($input: UpdateProfileDtoInput!){
    updateMyProfile(input: $input) {
      id
      ...UserProfile
    }
    ${UserProfileFragment}
  }
`

export const UPDATE_USER_APPROVAL = gql`
  mutation updateUserApproval($input: UpdateUserApprovalDtoInput!) {
    updateUserApproval(input: $input)
  }
`
