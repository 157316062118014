import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Avatar, Box, Card, CardActions, CardContent, Divider, Typography } from '@mui/material'
import { DropzoneDialog } from 'react-mui-dropzone'
import { toast } from 'react-toastify'

import { FileConstants } from 'constants/index'
import { useGetProfileAvatarQuery } from 'generated/graphql'
import { ShowAxiosError, ShowDropzoneAlert } from 'helpers'
import { UploadProfileAvatar } from 'services'

const AccountAvatar = (props) => {
  const [open, setOpen] = useState(false)
  const [uploadingAvatar, setUploadingAvatar] = useState(false)

  // operations
  const { loading, data, refetch } = useGetProfileAvatarQuery()

  // handlers
  const handleUploadClick = () => setOpen(true)
  const handleCloseDialog = () => setOpen(false)

  const handleUploadAvatar = (files: File[]) => {
    setUploadingAvatar(true)
    try {
      if (files.length === 0) return
      const file = files[0]
      const formData = new FormData()
      formData.append('avatar', file)

      UploadProfileAvatar(formData)
        .then(async () => {
          toast.success('Avatar uploaded')
          await refetch()
        })
        .catch(ShowAxiosError)
    } finally {
      setOpen(false)
      setUploadingAvatar(false)
    }
  }

  if (loading) {
    return <Typography variant='h6'>Loading...</Typography>
  }

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            // marginBottom: 5,
          }}>
          <Avatar sx={{ height: '100px', width: '100px' }} src={data?.myProfile.avatarUri ?? ''} />

          <Typography color='text.secondary' variant='h5' gutterBottom>
            {`${data?.myProfile.firstName} ${data?.myProfile.lastName}`}
          </Typography>
          <Typography variant='body1' color='text.secondary'>{`${data?.myProfile.city ?? ''}  ${data?.myProfile.country ?? ''
            }`}</Typography>
        </Box>
      </CardContent>

      <Divider />

      <CardActions>
        <LoadingButton fullWidth onClick={handleUploadClick} loading={uploadingAvatar}>
          Upload picture
        </LoadingButton>
        <DropzoneDialog
          acceptedFiles={['image/*']}
          cancelButtonText={'cancel'}
          submitButtonText={'submit'}
          maxFileSize={FileConstants.maxFileSize}
          open={open}
          onClose={handleCloseDialog}
          onSave={handleUploadAvatar}
          showPreviews
          showFileNamesInPreview
          showAlerts={false}
          onAlert={ShowDropzoneAlert}
          filesLimit={1}
        />
      </CardActions>
    </Card>
  )
}

export default AccountAvatar
