import { Checkbox, TableCell, TableRow, Typography } from '@mui/material'
import { format, parseJSON } from 'date-fns'

import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/index'
import { GetCustomerIncomeReportQuery, ReportCell, ReportCellType } from 'generated/graphql'

interface IProps {
  row: GetCustomerIncomeReportQuery['customerIncomeReport']['rows'][0]
}

const ReportTableRow = (props: IProps) => {
  const { row } = props

  const cellContent = (cell: Partial<ReportCell> | null) => {
    switch (cell?.type) {
      case ReportCellType.Date:
        return cell?.value && format(parseJSON(cell.value), DATE_FORMAT)

      case ReportCellType.DateTime:
        return cell?.value && format(parseJSON(cell.value), DATE_TIME_FORMAT)

      case ReportCellType.Time:
        return cell?.value && format(parseJSON(cell.value), TIME_FORMAT)

      case ReportCellType.String:
        return cell?.value

      case ReportCellType.Numeric:
        return parseFloat(cell?.value || '0').toFixed(2)

      case ReportCellType.Boolean:
        return cell?.value && <Checkbox readOnly checked={cell.value === 'True'} disableRipple />

      case ReportCellType.Hyperlink:
        return (
          cell?.value && (
            <a
              href={cell.hyperlink ? cell.hyperlink : undefined}
              target='_blank'
              rel='noopener noreferrer'>
              {cell.value}
            </a>
          )
        )
      default:
        return cell?.value
    }
  }

  return (
    <TableRow>
      {row?.cells.map((cell, index) => (
        <TableCell key={index}>
          <Typography variant={cell?.isBold ? 'subtitle2' : 'body2'} noWrap>
            {cellContent(cell)}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default ReportTableRow
