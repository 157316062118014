import { useState } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { VerticalTabPanel } from 'components/common'

import TaskHistoryPanel from './TaskHistoryPanel'
import TasksPanel from './TasksPanel'

enum TabId {
  Tasks = 0,
  History,
}

function commonProps(index: TabId) {
  return {
    id: `setting-tasks-tab-${index}`,
    'aria-controls': `setting-tasks-tabpanel-${index}`,
  }
}

const TaskTabs = () => {
  const [tabValue, setTabValue] = useState(0)

  const tabs = {
    tasks: { id: TabId.Tasks, label: 'Tasks' },
    history: { id: TabId.History, label: 'History' },
  }

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        orientation='vertical'
        aria-label='settings exchange rate tabs'
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        sx={{ boxShadow: (theme) => `inset -2px 0 0 0 ${theme.palette.divider}` }}>
        <Tab label={tabs.tasks.label} key={tabs.tasks.id} {...commonProps(tabs.tasks.id)} />
        <Tab label={tabs.history.label} key={tabs.history.id} {...commonProps(tabs.history.id)} />
      </Tabs>

      <VerticalTabPanel value={tabValue} index={tabs.tasks.id} px={3}>
        <TasksPanel />
      </VerticalTabPanel>

      <VerticalTabPanel value={tabValue} index={tabs.history.id} px={3}>
        <TaskHistoryPanel />
      </VerticalTabPanel>
    </Box>
  )
}

export default TaskTabs
