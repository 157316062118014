import { format, set } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

import { XeroServiceType } from 'generated/graphql'
import {
  CollectionCutoffIntervalPair,
  DeliveryIntervalPair,
  ServiceTypeDescriptionPair,
  TransitInterval,
} from 'types'

import { TIME_FORMAT } from './DateFormats'

export const UK_TIMEZONE = 'Europe/London'

export const CollectionInterval = {
  start: zonedTimeToUtc(
    set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
    UK_TIMEZONE,
  ),
  end: zonedTimeToUtc(
    set(new Date(), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }),
    UK_TIMEZONE,
  ),
}

export const CutOffTimeDeadlines: CollectionCutoffIntervalPair = {
  DOMESTIC_PARCELS: zonedTimeToUtc(
    set(new Date(), { hours: 11, minutes: 0, seconds: 0, milliseconds: 0 }),
    UK_TIMEZONE,
  ),
  INTERNATIONAL_PARCELS: zonedTimeToUtc(
    set(new Date(), { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
    UK_TIMEZONE,
  ),
  DOMESTIC_PALLETS: zonedTimeToUtc(
    set(new Date(), { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
    UK_TIMEZONE,
  ),
}

export const DeliveryIntervals: DeliveryIntervalPair = {
  DOMESTIC_PARCELS: {
    start: zonedTimeToUtc(
      set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    end: zonedTimeToUtc(
      set(new Date(), { hours: 18, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    days: 1,
    workingPeriod: true,
  } as TransitInterval,
  INTERNATIONAL_PARCELS: {
    start: zonedTimeToUtc(
      set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    end: zonedTimeToUtc(
      set(new Date(), { hours: 18, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    days: 4,
    workingPeriod: true,
  } as TransitInterval,
  DOMESTIC_PALLETS: {
    start: zonedTimeToUtc(
      set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    end: zonedTimeToUtc(
      set(new Date(), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    days: 1,
    workingPeriod: true,
  } as TransitInterval,
  EUROPEAN_GROUPAGE: {
    start: zonedTimeToUtc(
      set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    end: zonedTimeToUtc(
      set(new Date(), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    days: 10,
    workingPeriod: true,
  } as TransitInterval,
  INTERNATIONAL_AIR_FREIGHT: {
    start: zonedTimeToUtc(
      set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    end: zonedTimeToUtc(
      set(new Date(), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    days: 10,
    workingPeriod: false,
  } as TransitInterval,
  SEA_FREIGHT: {
    start: zonedTimeToUtc(
      set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    end: zonedTimeToUtc(
      set(new Date(), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }),
      UK_TIMEZONE,
    ),
    days: 40,
    workingPeriod: true,
  } as TransitInterval,
}

export const IgnoredXeroServiceCodes = [
  XeroServiceType.DomesticDedicated,
  XeroServiceType.EuropeanDedicated,
]
const collectionIntervalDescription = `${format(CollectionInterval.start, TIME_FORMAT)} - ${format(
  CollectionInterval.end,
  TIME_FORMAT,
)}`

export const ServiceTypeDescriptions: ServiceTypeDescriptionPair = {
  [XeroServiceType.DomesticDedicated]:
    'Collection: Time stipulated by the client or Admin that booked job. Delivery: Use google API',
  [XeroServiceType.DomesticParcels]: `Collection: Collection should always be between ${collectionIntervalDescription} on the date entered for collection. Delivery: Next day between ${format(
    DeliveryIntervals.DOMESTIC_PARCELS.start,
    TIME_FORMAT,
  )} - ${format(DeliveryIntervals.DOMESTIC_PARCELS.end, TIME_FORMAT)}`,
  [XeroServiceType.InternationalParcels]: `Collection: Collection should always be between ${collectionIntervalDescription} on the date entered for collection. Delivery: ${
    DeliveryIntervals.INTERNATIONAL_PARCELS.days
  } (working) days after collection between ${format(
    DeliveryIntervals.INTERNATIONAL_PARCELS.start,
    TIME_FORMAT,
  )} - ${format(DeliveryIntervals.INTERNATIONAL_PARCELS.end, TIME_FORMAT)}`,
  [XeroServiceType.DomesticPallets]: `Collection: Collection should always be between ${collectionIntervalDescription} on the date entered for collection. Delivery: Next day between ${format(
    DeliveryIntervals.DOMESTIC_PALLETS.start,
    TIME_FORMAT,
  )} - ${format(DeliveryIntervals.DOMESTIC_PALLETS.end, TIME_FORMAT)}`,
  [XeroServiceType.EuropeanDedicated]:
    'Collection: Time stipulated by the client or Admin that booked job . Delivery: Use google API',
  [XeroServiceType.EuropeanGroupage]: `Collection: should always be between ${collectionIntervalDescription} on the date entered for collection.Delivery: ${
    DeliveryIntervals.EUROPEAN_GROUPAGE.days
  } working days after collection date - delivered between ${format(
    DeliveryIntervals.EUROPEAN_GROUPAGE.start,
    TIME_FORMAT,
  )} - ${format(DeliveryIntervals.EUROPEAN_GROUPAGE.end, TIME_FORMAT)}`,
  [XeroServiceType.InternationalAirFreight]: `Collection: should always be between ${collectionIntervalDescription} on the date entered for collection. Delivery: ${
    DeliveryIntervals.INTERNATIONAL_AIR_FREIGHT.days
  } days after collection date between ${format(
    DeliveryIntervals.INTERNATIONAL_AIR_FREIGHT.start,
    TIME_FORMAT,
  )} - ${format(DeliveryIntervals.INTERNATIONAL_AIR_FREIGHT.end, TIME_FORMAT)}`,
  [XeroServiceType.SeaFreight]: `Collection: should always be between ${collectionIntervalDescription} on the date entered for collection. Delivery: ${
    DeliveryIntervals.SEA_FREIGHT.days
  } working days after collection between ${format(
    DeliveryIntervals.SEA_FREIGHT.start,
    TIME_FORMAT,
  )} - ${format(DeliveryIntervals.SEA_FREIGHT.end, TIME_FORMAT)}`,
}
