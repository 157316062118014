import { useState, useMemo, useCallback } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid'
import { FplDataGrid, IGridColumn } from 'components/common'
import {
  BookingStatus,
  GetBookingDocument,
  SortEnumType,
  useDeleteBookingDriverMutation,
} from 'generated/graphql'
import { GET_BOOKING_ALLOCATED_DRIVERS, GET_PAGED_BOOKING_ALLOCATED_DRIVERS } from 'graphql/queries'
import { toast } from 'react-toastify'

import AddRatingDialog from '../AllocatedDriverDetails/AddRatingDialog/AddRatingDialog'
import { EditDriverDialog } from './EditDriverDialog'
import DriverNoteDialog from './EditDriverDialog/DriverNoteDialog'

interface IProps {
  bookingId?: string
  bookingStatus?: any
}

const AllocatedDriverDetails = (props: IProps) => {
  const { bookingId, bookingStatus } = props
  const [bookingDriverId, setBookingDriverId] = useState<number | null>(null)
  const [driverId, setDriverId] = useState<number | null>(null)
  const [openEditDriverDialog, setOpenEditDriverDialog] = useState(false)
  const [driverNoteDialog, setDriverNoteDialog] = useState<{ open: boolean; note: string }>({
    open: false,
    note: '',
  })
  const [openRatingDialog, setOpenRatingDialog] = useState(false)

  // mutations
  const [deleteBookingDriver, { loading: deleteBookingDriverProcessing }] =
    useDeleteBookingDriverMutation({
      refetchQueries: [
        { query: GetBookingDocument, variables: { bookingId: Number(bookingId) } },
        // ToDo: Inefficient. Use manual cache update.
        { query: GET_BOOKING_ALLOCATED_DRIVERS, variables: { bookingId: Number(bookingId) } },
        {
          query: GET_PAGED_BOOKING_ALLOCATED_DRIVERS,
          variables: {
            bookingId: Number(bookingId),
            order: { createdAt: SortEnumType.Desc },
            after: null,
            before: null,
            first: 5,
            last: null,
          },
        },
        {
          query: GET_PAGED_BOOKING_ALLOCATED_DRIVERS,
          variables: {
            bookingId: Number(bookingId),
            order: { createdAt: SortEnumType.Desc },
            after: null,
            before: null,
            first: 100,
            last: null,
          },
        },
      ],
      onCompleted: () => {
        toast.success('Driver was deleted with success.')
      },
    })

  // handlers
  const handleCloseDriverNoteDialog = () => {
    setDriverNoteDialog({
      note: '',
      open: false,
    })
  }

  const handleDriverNotes = useCallback(
    (note: string) => () => {
      setDriverNoteDialog({
        note,
        open: true,
      })
    },
    [],
  )

  // ToDo: add confirmation dialog
  const handleDeleteDriver = useCallback(
    (id: GridRowId) => () => {
      deleteBookingDriver({
        variables: { bookingDriverId: Number(id) },
      })
    },
    [deleteBookingDriver],
  )

  const handleEditAllocatedDriver = useCallback(
    (id: GridRowId) => () => {
      setBookingDriverId(Number(id))
      setOpenEditDriverDialog(true)
    },
    [],
  )
  const handleCloseEditDriverDialog = () => {
    setOpenEditDriverDialog(false)
  }

  const handleAddRating = useCallback(
    (id: GridRowId) => () => {
      setDriverId(Number(id))
      setOpenRatingDialog(true)
    },
    [],
  )

  const handelCloseAddRatingDialog = () => setOpenRatingDialog(false)

  const bookingStatusForRating =
    bookingStatus &&
    [BookingStatus.Invoice, BookingStatus.Complete, BookingStatus.ProofOfDelivery].includes(
      bookingStatus,
    )

  const bookingStatusForDeleteAllocation =
    bookingStatus &&
    [
      BookingStatus.ProofOfDelivery,
      BookingStatus.Complete,
      BookingStatus.Invoice,
      BookingStatus.ScheduledTemplate,
    ].includes(bookingStatus)

  const columns: IGridColumn[] = useMemo(
    () => [
      {
        field: 'driver',
        headerName: 'Name',
        minWidth: 150,
        flex: 1,
        sortPath: ['driver', 'name'],
        valueGetter: ({ value }) => value?.name,
      },
      { field: 'allocationStatus', headerName: 'Alloc. Status', width: 100 },
      {
        field: 'isUsingInternalApp',
        headerName: 'Using App?',
        width: 100,
        type: 'boolean',
        sortable: false,
        valueGetter: ({ row }) => row.driver?.isUsingInternalApp,
      },
      {
        field: 'reference',
        headerName: 'Reference',
        width: 100,
        type: 'string',
      },
      {
        field: 'vehicle',
        headerName: 'Vehicle',
        width: 120,
        sortPath: ['vehicle', 'vehicleType', 'name'],
        valueGetter: ({ value }) => value?.vehicleType?.name,
      },
      { field: 'charge', headerName: 'Charge', width: 80, type: 'number' },
      { field: 'extraPayment', headerName: 'Extra Pay', width: 80, type: 'number' },
      {
        field: 'currency',
        headerName: 'Ccy',
        width: 50,
        sortPath: ['currency', 'code'],
        valueGetter: ({ value }) => value?.code,
      },
      { field: 'xeroPurchaseCode', headerName: 'Purchase Code', width: 120, type: 'number' },
      { field: 'createdAt', headerName: 'Created At', hide: true },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 180,
        type: 'actions',
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={`note-${id}`}
            label='Driver note'
            size='medium'
            disabled={!row.driverNotes}
            icon={
              <Tooltip title={'Driver note'} arrow>
                <StickyNote2OutlinedIcon />
              </Tooltip>
            }
            onClick={handleDriverNotes(row.driverNotes)}
          />,
          <GridActionsCellItem
            key={`edit-${id}`}
            label='Edit'
            size='medium'
            disabled={deleteBookingDriverProcessing}
            icon={
              <Tooltip title={'Edit'} arrow>
                <EditIcon />
              </Tooltip>
            }
            onClick={handleEditAllocatedDriver(id)}
          />,
          <GridActionsCellItem
            sx={{ display: bookingStatusForRating ? 'block' : 'none' }}
            key={`rating-${id}`}
            label='Rating'
            size='medium'
            icon={
              <Tooltip title={'Rating'} arrow>
                {row.rating ? <StarIcon sx={{ color: '#faaf00' }} /> : <StarBorderIcon />}
              </Tooltip>
            }
            onClick={handleAddRating(row.driver.id)}
          />,
          <GridActionsCellItem
            key={`delete-${id}`}
            label='Delete'
            size='medium'
            disabled={deleteBookingDriverProcessing || bookingStatusForDeleteAllocation}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteDriver(id)}
          />,
        ],
      },
    ],
    [
      handleDriverNotes,
      deleteBookingDriverProcessing,
      handleEditAllocatedDriver,
      handleAddRating,
      handleDeleteDriver,
      bookingStatusForRating,
    ],
  )

  const queryVariables = useMemo(() => ({ bookingId: Number(bookingId) }), [bookingId])

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_BOOKING_ALLOCATED_DRIVERS}
        entityName='pagedBookingAllocatedDrivers'
        columns={columns}
        queryVariables={queryVariables}
        defaultOrder={{ field: 'createdAt', sort: 'desc' }}
        pageOptions={{ pageSize: 5, rowsPerPage: [5, 10] }}
        toolbar={{ caption: 'Allocated Drivers' }}
      />

      {openEditDriverDialog && bookingDriverId && (
        <EditDriverDialog
          bookingDriverId={bookingDriverId}
          openDialog={openEditDriverDialog}
          handleCloseDialog={handleCloseEditDriverDialog}
        />
      )}

      {driverId && bookingId && (
        <AddRatingDialog
          driverId={driverId}
          bookingId={Number(bookingId)}
          openDialog={openRatingDialog}
          handleCloseDialog={handelCloseAddRatingDialog}
        />
      )}

      <DriverNoteDialog {...driverNoteDialog} onClose={handleCloseDriverNoteDialog} />
    </>
  )
}

export default AllocatedDriverDetails
