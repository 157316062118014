import { useCallback, useMemo, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import { GET_POTENTIAL_BOOKING_FILES } from 'graphql/queries'

import PotentialBookingFileDialog from './PotentialBookingFileDialog'

const PotentialBookingFiles = () => {
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  // Mutations
  // const [deletePotentialBookingFile, { loading: deletePotentialBookingFileProcessing }] =
  //   useDeletePotentialBookingFileMutation({
  //     refetchQueries: [
  //       {
  //         query: GET_POTENTIAL_BOOKING_FILES,
  //         variables: {
  //           after: null,
  //           before: null,
  //           first: 10,
  //           last: null,
  //           order: { createdAt: SortEnumType.Desc },
  //         },
  //       },
  //     ],
  //   })

  // Handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedFileId(Number(id))
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleDeletePotentialBookingFile = useCallback(
    // eslint-disable-next-line
    (id: GridRowId) => () => {
      alert('To be implemented')
      // TODO: Write and call mutation
      // deleteCustomer({
      //   variables: {
      //     customerId: Number(id),
      //   },
      // }).then((response) => {
      //   if (response.data) {
      //     toast.success(`Customer ${id} was deleted with success!`)
      //   }
      // })
    },
    [],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      {
        field: 'file',
        headerName: 'File Name',
        minWidth: 200,
        flex: 1,
        sortPath: ['file', 'originalFileName'],
        valueGetter: ({ value }) => value?.originalFileName,
      },
      {
        field: 'uploadedByCustomer',
        headerName: 'Uploaded by Customer',
        minWidth: 200,
        flex: 1,
        sortPath: ['uploadedByCustomer', 'name'],
        valueGetter: ({ value }) => value?.name ?? 'N/A',
      },
      {
        field: 'isProcessed',
        headerName: 'Is processed?',
        width: 130,
        type: 'boolean',
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 200,
        valueFormatter: ({ value }) =>
          value && format(parseJSON(value as string), DATE_TIME_FORMAT),
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        minWidth: 200,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            // disabled={deletePotentialBookingFileProcessing}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeletePotentialBookingFile(id)}
          />,
        ],
      },
    ],
    [handleDeletePotentialBookingFile],
  )

  return (
    <>
      <FplDataGrid
        query={GET_POTENTIAL_BOOKING_FILES}
        entityName='potentialBookingFiles'
        columns={columns}
        defaultOrder={{ field: 'createdAt', sort: 'desc' }}
        toolbar={{
          caption: 'Potential Booking Files',
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <PotentialBookingFileDialog
          id={selectedFileId}
          openDialog={openDialog}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default PotentialBookingFiles
