import { Button, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { useGetFailedPaymentErrorMessageQuery } from 'generated/graphql'


function StatusMessagePanel() {
  const { id } = useParams<{ id?: string }>()

  const { data, loading } = useGetFailedPaymentErrorMessageQuery({
    variables: { accountPayableRecordId: Number(id) },
  })

  if (loading) {
    return <Skeleton />
  }
  if (!data?.paymentFailedErrorMessage) {
    return <></>
  }
  return (
    <Paper elevation={4}>
      <Stack direction='row' p={2} alignItems='center' justifyContent='space-between'>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {data?.paymentFailedErrorMessage}
        </Typography>
        <Button variant='outlined' disabled>
          Action TBD
        </Button>
      </Stack>
    </Paper>
  )
}

export default StatusMessagePanel
