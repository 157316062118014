import Joi from 'joi'

import { IMultipleAutocompleteOption } from 'components/common'
import { TStandardOperatingProcedureForm } from 'types'

const StandardOperatingProcedureDetailsValidation = Joi.object<TStandardOperatingProcedureForm>({
  name: Joi.string().empty(''),
  description: Joi.string().empty(''),
  steps: Joi.string().empty(''),
  frequentlyAskedQuestions: Joi.string().empty('').optional(),
  notes: Joi.string().empty('').optional(),
  driverInstructions: Joi.string().empty('').optional(),
  isAttachedToAllCustomers: Joi.boolean().default(false).label('All customers'),
  customerIds: Joi.when('isAttachedToAllCustomers', {
    is: false,
    then: Joi.array<IMultipleAutocompleteOption>().min(1).label('Customer(s)'),
  }),
})

export { StandardOperatingProcedureDetailsValidation }
