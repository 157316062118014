import { useMemo } from 'react'

import { Mode } from 'constants/index'
import { BookingAddressType } from 'generated/graphql'

const useIsBaseDisabled = (booking: any, mode: number | undefined | null): boolean => {
  const isBaseDisabled = useMemo(() => {
    if (!booking) {
      return true
    }

    const collectionAddress = booking.addresses.filter(
      (address) => address.type === BookingAddressType.Collection,
    )
    const deliveryAddress = booking.addresses.filter(
      (address) => address.type === BookingAddressType.Delivery,
    )
    return mode === Mode.Create || collectionAddress.length === 0 || deliveryAddress.length === 0
  }, [mode, booking])

  return isBaseDisabled
}

export { useIsBaseDisabled }
