import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'

import { FplDialogTitle } from '../Dialog'
import { UserNotifications } from './UserNotifications'

export interface INotificationsDialog {
  openDialog: boolean
  username: string
  onCloseDialog: () => void
}

export const NotificationsDialog = (props: INotificationsDialog) => {
  const { username, openDialog, onCloseDialog } = props

  const handleCloseDialog = () => onCloseDialog()

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='md'
      scroll='body'
      aria-labelledby='user-notifications-dialog'>
      <FplDialogTitle id='user-notifications-dialog' onClose={handleCloseDialog}>
        All Notifications
      </FplDialogTitle>

      <DialogContent>
        <UserNotifications username={username} />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' onClick={handleCloseDialog} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
