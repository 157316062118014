import { gql } from '@apollo/client'

import { StandardOperatingProcedureDetailsFragment } from 'graphql/fragments'

export const GET_PAGED_STANDARD_OPERATING_PROCEDURES = gql`
  query GetStandardOperatingProcedures(
    $customerId: Long
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: StandardOperatingProcedureFilterInput
    $order: [StandardOperatingProcedureSortInput!]
  ) {
    standardOperatingProcedures(
      customerId: $customerId
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      order: $order
    ) {
      edges {
        node {
          id
          name
          isActive
          createdBy
          lastModifiedAt
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_STANDARD_OPERATING_PROCEDURE = gql`
  query GetStandardOperatingProcedure($id: Long!) {
    standardOperatingProcedure(id: $id) {
      id
      ...StandardOperatingProcedureDetails
    }

    ${StandardOperatingProcedureDetailsFragment}
  }
`

export const GET_STANDARD_OPERATING_PROCEDURE_HISTORY = gql`
  query GetStandardOperatingProcedureHistory(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [StandardOperatingProcedureHistoryDtoSortInput!]
    $where: StandardOperatingProcedureHistoryDtoFilterInput
  ) {
    standardOperatingProcedureHistory(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          sopId
          name
          isActive
          description
          lastModifiedAt
          lastModifiedBy
          isAttachedToAllCustomers
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
