import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FplDialogTitle } from 'components/common'
import ContactForm from 'components/customers/Contacts/createUpdateContact/ContactForm'
import { JoiValidationOptions, Mode } from 'constants/index'
import { CreateAndUpdateContactDtoInput, useCreateContactMutation } from 'generated/graphql'
import { GET_CUSTOMER_CONTACTS } from 'graphql/queries'
import { useConfirmDialog } from 'providers'
import { CreateUpdateCustomerContactValidation } from 'validation-schemas'

interface IProps {
  customerId: number
  openDialog: boolean
  handleCloseDialog: () => any
}

const CreateContactDialog = (props: IProps) => {
  const { customerId, openDialog, handleCloseDialog } = props

  const methods = useForm<CreateAndUpdateContactDtoInput>({
    resolver: joiResolver(CreateUpdateCustomerContactValidation, JoiValidationOptions),
    shouldUnregister: true,
    defaultValues: {
      customerId,
      isDefault: false,
      name: '',
      telephoneNumber: '',
      email: '',
      additionalEmails: '',
      isActive: false,
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  // Mutations
  const [createContact, { loading: creatingContact }] = useCreateContactMutation({
    onCompleted: (response) => {
      if (!response) {
        toast.error('Something went wrong.')
        return
      }

      handleCloseDialog()
      toast.success(`Contact '${response.createContact.name}' was successfully added.`)
    },
    refetchQueries: [{ query: GET_CUSTOMER_CONTACTS, variables: { customerId } }],
  })

  const handleSave: SubmitHandler<CreateAndUpdateContactDtoInput> = (data) => {
    createContact({
      variables: {
        input: {
          ...data,
          customerId,
          isEnabled: true,
        } as CreateAndUpdateContactDtoInput,
      },
    })
  }

  const handleOnCancel = () => {
    if (isDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  return (
    <Dialog fullWidth maxWidth='md' open={openDialog} aria-labelledby='create-contact-dialog-title'>
      <FplDialogTitle id='create-contact-dialog-title' onClose={handleOnCancel}>
        <Typography paragraph variant='h4'>
          New Contact
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <form id='create-contact-form' noValidate>
            <ContactForm customerId={customerId} mode={Mode.Create} />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleOnCancel}>
          Cancel
        </Button>

        <LoadingButton
          form='create-contact-form'
          variant='contained'
          type='submit'
          loading={creatingContact}
          onClick={handleSubmit(handleSave)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateContactDialog
