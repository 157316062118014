import { gql } from '@apollo/client'

import { CongestionZoneDetailsFragment, CongestionZonePostcodeFragment } from 'graphql/fragments'

export const GET_PAGED_CONGESTION_ZONES_QUERY = gql`
  query GetPagedCongestionZones(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [CongestionZoneSortInput!]
    $where: CongestionZoneFilterInput
  ) {
    pagedCongestionZones(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          isAttachedToAllTariffs
          isAppliedAutomatically
          xeroServiceCode
          customerCharge
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_CONGESTION_ZONE_QUERY = gql`
  query GetCongestionZone($id: Long!) {
    congestionZone(id: $id) {
      id
      timeZoneId
      timeZone {
        id
        name
        description
      }
      is24Hours
      ...CongestionZoneDetails
      postcodes {
        id
      ...CongestionZonePostcodeFragment
      }
    }
    ${CongestionZoneDetailsFragment}
    ${CongestionZonePostcodeFragment}
  }
`

export const GET_TIME_ZONES_QUERY = gql`
  query GetTimeZones {
    timeZones {
      id
      description
    }
  }
`
