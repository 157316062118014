import { useEffect, useMemo } from 'react'

import { Box, TextField, styled } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { useGetReasonCodesLazyQuery, useGetUserReasonCodesLazyQuery } from 'generated/graphql'
import { usePermission } from 'providers'

const StyledOptions = styled(Box)(({ theme }) => ({
  '& > span': {
    marginRight: '10px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

interface IProps {
  control: Control<any, any>
  name?: string
  defaultValue?: any
  required?: boolean
  disabled?: boolean
  loading?: boolean
  error?: boolean
  helperText?: any
  autoComplete?: string
  customerId?: number
  isIncludeSubCustomers?: boolean
}

function AutocompleteWrapper({ value, onChange, reasonCodes, children, ...otherProps }) {
  const selectedReasonCode = useMemo(
    () => reasonCodes.find((reasonCode) => Number(reasonCode.id) === Number(value)),
    [reasonCodes, value],
  )

  return children({
    ...otherProps,
    onChange: (_, data) => {
      onChange(Number(data?.id))
    },
    value: selectedReasonCode || { value: '', name: '' },
  })
}

const ControlledReasonCodeAutocomplete = (props: IProps) => {
  const {
    control,
    name = 'reasonCodeId',
    defaultValue,
    required,
    disabled,
    loading,
    error,
    helperText,
    autoComplete,
    customerId,
    isIncludeSubCustomers,
  } = props

  const [isAllowedToViewReasonCodeList] = usePermission('ViewReasonCodeList')
  const [isAllowedToViewUserReasonCodeList] = usePermission('ViewUserReasonCodeList')

  const [getReasonCodes, { data: adminReasonCodes, loading: adminReasonCodesLoading }] =
    useGetReasonCodesLazyQuery()

  const [getUserReasonCodes, { data: userReasonCodes, loading: userReasonCodesLoading }] =
    useGetUserReasonCodesLazyQuery()

  useEffect(() => {
    if (isAllowedToViewReasonCodeList && customerId) {
      getReasonCodes({
        variables: {
          customerId: Number(customerId),
        },
      })
    } else if (isAllowedToViewUserReasonCodeList) {
      getUserReasonCodes({
        variables: {
          isIncludeSubCustomers: isIncludeSubCustomers ?? false,
        },
      })
    }
  }, [
    customerId,
    isIncludeSubCustomers,
    getReasonCodes,
    getUserReasonCodes,
    isAllowedToViewReasonCodeList,
    isAllowedToViewUserReasonCodeList,
  ])

  const reasonCodes = userReasonCodes?.userReasonCodes || adminReasonCodes?.reasonCodes || []
  const isLoading = adminReasonCodesLoading || userReasonCodesLoading

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...restField } }) => (
        <AutocompleteWrapper {...restField} reasonCodes={reasonCodes}>
          {(props: any) => (
            <Autocomplete
              {...props}
              options={reasonCodes}
              loading={loading || isLoading}
              disabled={disabled}
              disableClearable
              getOptionLabel={(option: any) => option?.code ?? ''}
              isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
              renderOption={(props, option: any) => (
                // @ts-ignore
                <StyledOptions component='li' {...props}>
                  <span>{option?.code}</span>
                  {option?.name}
                </StyledOptions>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Reason Code'
                  variant='outlined'
                  required={required}
                  fullWidth
                  error={error}
                  helperText={helperText}
                  autoComplete={autoComplete}
                />
              )}
            />
          )}
        </AutocompleteWrapper>
      )}
    />
  )
}

export default ControlledReasonCodeAutocomplete
