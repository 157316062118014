import React, { Dispatch, SetStateAction } from 'react'
interface IBaseOptions {
  onConfirmApprove: () => void
}

interface IDialogOptions {
  title?: string
  content?: React.ReactNode
}

const ConfirmDialogContext = React.createContext<{
  confirmOpen: boolean
  setConfirmOpen: Dispatch<SetStateAction<boolean>>
  onConfirmApprove: (() => void) | null
  setOnConfirmApprove: React.Dispatch<React.SetStateAction<() => void>>
  options: IDialogOptions | null
  setOptions: React.Dispatch<React.SetStateAction<IDialogOptions | undefined>>
}>({
  confirmOpen: false,
  setConfirmOpen: () => false,
  onConfirmApprove: null,
  setOnConfirmApprove: () => null,
  options: null,
  setOptions: () => null,
})

const useConfirmDialog = (baseOptions?: IBaseOptions) => {
  // ToDo: refactor to use 'onConfirmApprove' reference to function
  const context = React.useContext(ConfirmDialogContext)

  if (!context) {
    throw new Error('useConfirmDialog must be used within a ConfirmDialogProvider')
  }

  React.useEffect(() => {
    if (baseOptions) {
      const { onConfirmApprove } = baseOptions
      onConfirmApprove && context.setOnConfirmApprove(() => onConfirmApprove)
    } else {
      context.setOnConfirmApprove(() => () => {
        console.warn(
          'useConfirmDialog: You have to set `onConfirmApprove`' +
            'function on `useConfirmDialog` hook options.',
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    confirmOpen: (options?: IDialogOptions) => {
      context.setConfirmOpen(true)
      context.setOptions(options)
    },
  }
}

const ConfirmDialogProvider = (props) => {
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [options, setOptions] = React.useState(null)
  const [onConfirmApprove, setOnConfirmApprove] = React.useState(() => () => {
    console.warn(
      'useConfirmDialog: You have to set `onConfirmApprove`' +
        'function on `useConfirmDialog` hook options.',
    )
  })

  return (
    <ConfirmDialogContext.Provider
      value={{
        confirmOpen,
        setConfirmOpen,
        options,
        setOptions,
        onConfirmApprove,
        setOnConfirmApprove,
      }}
      {...props}
    />
  )
}

export { ConfirmDialogContext, ConfirmDialogProvider, useConfirmDialog }
