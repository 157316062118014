import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ControlledCheckbox, ControlledTextField, FplDialogTitle } from 'components/common'
import { EntityConstants, Paths } from 'constants/index'
import { DeleteOrDisableBookingDtoInput, useDeleteBookingMutation } from 'generated/graphql'

export enum OperationType {
  Delete,
  DisableEnable,
}

interface IProps {
  operationType: OperationType
  captionText: 'Delete' | 'Enable' | 'Disable'
  bookingId: number
  openDialog: boolean
  handleCloseDialog: () => void
}

const validationSchema = Joi.object({
  id: Joi.number().label('Booking').required(),
  notifyCustomer: Joi.boolean().label('Notify customer'),
  notifyDrivers: Joi.boolean().label('Notify drivers'),
  reason: Joi.string()
    .label('Reason')
    .max(EntityConstants.MAX_NOTE_LENGTH)
    .when('notifyCustomer', {
      is: true,
      then: Joi.string().label('Reason').max(EntityConstants.MAX_NOTE_LENGTH).required(),
      otherwise: Joi.string().label('Reason').max(EntityConstants.MAX_NOTE_LENGTH).optional(),
    })
    .when('notifyDrivers', {
      is: true,
      then: Joi.string().label('Reason').max(EntityConstants.MAX_NOTE_LENGTH).required(),
      otherwise: Joi.string().label('Reason').max(EntityConstants.MAX_NOTE_LENGTH).optional(),
    }),
})

export const DeleteBookingDialog = (props: IProps) => {
  const { operationType, captionText, openDialog, bookingId, handleCloseDialog } = props
  const history = useHistory()

  // hooks
  const [isFullScreen, setIsFullScreen] = useState(false)
  const methods = useForm<DeleteOrDisableBookingDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(validationSchema),
    defaultValues: {
      id: bookingId,
      notifyCustomer: false,
      notifyDrivers: false,
      reason: '',
    },
  })

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isValid, errors },
  } = methods

  const { notifyCustomer, notifyDrivers } = watch()

  // mutations
  const [deleteBooking, { loading: deletingBooking }] = useDeleteBookingMutation({
    onCompleted: (response) => {
      if (response.deleteBooking) {
        toast.success(`Booking ${captionText}d successfully`)

        if (operationType === OperationType.Delete) {
          history.push(Paths.bookings.all)
        } else {
          // TODO :Refresh booking
        }
        handleCloseDialog()
      } else {
        toast.error('Something went wrong')
      }
    },
  })

  // handlers
  const handleCancel = () => handleCloseDialog()

  const handleFullScreenChange = () => setIsFullScreen(!isFullScreen)
  const handleOnUpdate = (formData: DeleteOrDisableBookingDtoInput) => {
    deleteBooking({
      variables: {
        input: formData,
      },
    })
  }

  return (
    <Dialog
      maxWidth='lg'
      fullScreen={isFullScreen}
      open={openDialog}
      aria-labelledby='delete-booking-dialog'>
      <FplDialogTitle onFullScreen={handleFullScreenChange} onClose={handleCancel}>
        <Typography paragraph variant='h4'>
          {captionText} Booking
        </Typography>
      </FplDialogTitle>
      <DialogContent>
        {/* TODO: DELETE */}
        <Paper variant='elevation' elevation={0}>
          <Box padding={2}>
            <Grid container columnSpacing={4}>
              <Grid item xs={12} hidden>
                <ControlledTextField
                  name='id'
                  label='Booking id'
                  control={control}
                  required
                  error={!!errors.id}
                  helperText={errors.id?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name='reason'
                  label='Reason message'
                  multiline
                  rows={3}
                  required={notifyCustomer || notifyDrivers}
                  error={!!errors.reason}
                  helperText={errors.reason?.message}
                />
              </Grid>

              <Grid item hidden={operationType !== OperationType.Delete} xs={12} sm={4}>
                <ControlledCheckbox
                  control={control}
                  name='notifyCustomer'
                  label='Notify Customer'
                />
              </Grid>

              <Grid item hidden={operationType !== OperationType.Delete} xs={12} sm={4}>
                <ControlledCheckbox control={control} name='notifyDrivers' label='Notify Drivers' />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button disabled={deletingBooking} variant='contained' color='grey' onClick={handleCancel}>
          {isDirty ? 'Cancel' : 'Close'}
        </Button>

        <LoadingButton
          variant='contained'
          loading={deletingBooking}
          disabled={!isValid}
          onClick={handleSubmit(handleOnUpdate)}>
          {captionText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
