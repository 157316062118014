import { useState, useEffect, useMemo } from 'react'

import { useReactiveVar } from '@apollo/client'
import { Box, Paper, Theme, useMediaQuery } from '@mui/material'
import { GridSortItem } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { BookingFilter } from 'components/bookings/BookingFilter'
import { TrustpilotBox } from 'components/common'
import {
  DEBOUNCE_DELAY,
  CompleteFilterInput,
  InProgressFilterInput,
  PreBookedFilterInput,
  QuoteFilterInput,
  ScheduledTemplateFilterInput,
  StatusCategories,
} from 'constants/index'
import { BookingFilterInput } from 'generated/graphql'
import { GET_PAGED_BOOKINGS, GET_USER_BOOKINGS } from 'graphql/queries/BookingQueries'
import {
  bookingsFilterFormVar,
  bookingsStatusCategoryVar,
  isBookingsAutoUpdatedVar,
} from 'graphql/reactiveVariables'
import {
  getAutoUpdate,
  getCalculatedStatusByStatusId,
  getIntervalByPeriod,
  isNumber,
  setAutoUpdate,
} from 'helpers'
import useGetBookingsTableColumns from 'hooks/bookingTab/useGetBookingsTableColumns'
import { Restricted, usePermission } from 'providers/PermissionProvider'

import BookingsCarousel from './BookingsCarousel'
import BookingsDataTable from './BookingsDataTable/BookingsDataTable'

const descSortOrder: GridSortItem = { field: 'collectionEstimatedTimeOfArrival', sort: 'desc' }
const ascSortOrder: GridSortItem = { field: 'collectionEstimatedTimeOfArrival', sort: 'asc' }

const Bookings = () => {
  const [pollInterval, setPollInterval] = useState(0)

  const bookingsStatusCategory = useReactiveVar(bookingsStatusCategoryVar)
  const bookingsFilterForm = useReactiveVar(bookingsFilterFormVar)

  const [isAllowedToViewBookings] = usePermission('ViewBookingsList')
  const [isAllowedToViewUserBookingsList] = usePermission('ViewUserBookingsList')

  const isBreakpointDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const entityName = useMemo(() => {
    if (isAllowedToViewBookings) {
      return 'bookings'
    }
    if (isAllowedToViewUserBookingsList) {
      return 'userBookings'
    }
    return ''
  }, [isAllowedToViewBookings, isAllowedToViewUserBookingsList])

  const storageAutoUpdateValue = getAutoUpdate(entityName)

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      autoUpdate:
        storageAutoUpdateValue !== null ? storageAutoUpdateValue : isBookingsAutoUpdatedVar(),
      search: '',
    },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''
  const autoUpdateValue = watch('autoUpdate')

  useEffect(() => {
    if (autoUpdateValue !== undefined) setAutoUpdate(autoUpdateValue, entityName)

    if (autoUpdateValue === true) {
      setPollInterval(30000)
    }
    if (autoUpdateValue === false) {
      setPollInterval(0)
    }

    if (storageAutoUpdateValue !== null) {
      isBookingsAutoUpdatedVar(storageAutoUpdateValue)
    } else {
      isBookingsAutoUpdatedVar(autoUpdateValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoUpdateValue])

  const query = useMemo(() => {
    if (isAllowedToViewBookings) {
      return GET_PAGED_BOOKINGS
    }
    if (isAllowedToViewUserBookingsList) {
      return GET_USER_BOOKINGS
    }
  }, [isAllowedToViewBookings, isAllowedToViewUserBookingsList])

  // handlers

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const bookingsFilter: BookingFilterInput = useMemo(() => {
    let statusCategoryFilterInput
    switch (bookingsStatusCategory) {
      case StatusCategories.PreBooked:
        statusCategoryFilterInput = PreBookedFilterInput
        break
      case StatusCategories.InProgress:
        statusCategoryFilterInput = InProgressFilterInput
        break
      case StatusCategories.Complete:
        statusCategoryFilterInput = CompleteFilterInput
        break
      case StatusCategories.Quote:
        statusCategoryFilterInput = QuoteFilterInput
        break
      case StatusCategories.ScheduledTemplate:
        statusCategoryFilterInput = ScheduledTemplateFilterInput
        break
    }

    const {
      customerId,
      tariffId,
      xeroCodeId,
      statusId,
      isOnlyMultidrop,
      takenBy,
      period,
      dateFrom,
      dateTo,
    } = bookingsFilterForm

    const bookingFilterCriteria = {
      ...(customerId && { customerId: { eq: Number(customerId) } }),
      ...(tariffId && { tariffId: { eq: Number(tariffId) } }),
      ...(xeroCodeId &&
        xeroCodeId !== 'International' && { tariff: { xeroCodeId: { eq: Number(xeroCodeId) } } }),
      ...(xeroCodeId &&
        xeroCodeId === 'International' && {
          tariff: { xeroCode: { isInternational: { eq: true } } },
        }),
      ...(statusId && getCalculatedStatusByStatusId(statusId)),
      ...(isOnlyMultidrop && { addresses: { some: { sequenceOrder: { gt: 1 } } } }),
      ...(takenBy && { takenBy: { eq: takenBy } }),
      ...(period && {
        takenOn: getIntervalByPeriod(period, dateFrom, dateTo),
      }),
    }

    const searchFilterInput: Array<BookingFilterInput> = [
      ...(isNumber(Number(searchValue)) ? [{ id: { eq: Number(searchValue) } }] : []),
      {
        customer: {
          name: { contains: searchValue },
        },
      },
      { addresses: { some: { address: { postcode: { contains: searchValue } } } } },
      { contact: { name: { contains: searchValue } } },
    ]

    return {
      and: [
        {
          ...statusCategoryFilterInput,
          ...bookingFilterCriteria,
        },
      ],
      ...(searchValue && { or: searchFilterInput }),
    }
  }, [bookingsStatusCategory, bookingsFilterForm, searchValue])

  const variableSortOrder = useMemo(() => {
    if (bookingsStatusCategory === StatusCategories.PreBooked) {
      return ascSortOrder
    } else {
      return descSortOrder
    }
  }, [bookingsStatusCategory])

  const columns = useGetBookingsTableColumns()

  return (
    <>
      <BookingsCarousel />

      <Box mb={2}>
        <Paper elevation={3}>
          <BookingFilter />
        </Paper>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          {query && (
            <BookingsDataTable
              query={query}
              entityName={entityName}
              columns={columns}
              filter={bookingsFilter}
              pollInterval={pollInterval}
              variableSortOrder={variableSortOrder}
              refetchWithDelay
              showAppBarLoading={isBreakpointDownSm}
              control={control}
              searchInput={searchInput}
              handleSearchClearClick={handleSearchClearClick}
            />
          )}
        </Box>
      </Paper>

      <Restricted to='ViewTrustpilotWidgetOnBookingsPage'>
        <Box mt={2}>
          <Paper elevation={3}>
            <Box paddingY={3} paddingX={2}>
              <TrustpilotBox />
            </Box>
          </Paper>
        </Box>
      </Restricted>
    </>
  )
}

export default Bookings
