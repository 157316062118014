import { useMemo, useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { ExpandMore, Visibility, Download, DirectionsRun } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
import { colorBrewer } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { toast } from 'react-toastify'

import {
  FplDialogTitle,
  FilePreviewDialog,
  ControlledSelector,
  ISelectorOptions,
} from 'components/common'
import { JoiValidationOptions } from 'constants/index'
import {
  ProcessPotentialBookingFileDtoInput,
  SortEnumType,
  useGetPotentialBookingFileQuery,
  useGetPotentialBookingMappingsQuery,
  useProcessPotentialBookingFileMutation,
} from 'generated/graphql'
import { GET_POTENTIAL_BOOKING_FILES } from 'graphql/queries'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadFile } from 'services'
import { PotentialBookingFileValidation } from 'validation-schemas'

interface IProps {
  id: number | null
  openDialog: boolean
  onCloseDialog: () => void
}
const PotentialBookingFileDialog = (props: IProps) => {
  const { id, openDialog, onCloseDialog } = props

  const [extractedData, setExtractedData] = useState<string>('')
  const [previewFileId, setPreviewFileId] = useState<number | null>(null)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

  SyntaxHighlighter.registerLanguage('json', json)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ProcessPotentialBookingFileDtoInput>({
    resolver: joiResolver(PotentialBookingFileValidation, JoiValidationOptions),
    defaultValues: {
      potentialBookingFileId: id ?? 0,
      potentialBookingMappingId: 0,
    },
  })

  // Queries
  const { data: { potentialBookingFile } = {}, loading } = useGetPotentialBookingFileQuery({
    skip: !openDialog,
    variables: { id },
    onCompleted: (data) => {
      const json = JSON.parse(data?.potentialBookingFile?.extractedData ?? '{}')
      const text = JSON.stringify(json, null, 2)
      setExtractedData(text)
    },
  })

  const fileId = Number(potentialBookingFile?.file?.id)

  const { data: mappings, loading: mappingsLoading } = useGetPotentialBookingMappingsQuery()

  const mappingItems: ISelectorOptions = useMemo(() => {
    if (!mappingsLoading && mappings?.potentialBookingMappings) {
      return mappings.potentialBookingMappings.map((s) => ({
        value: s.id,
        label: s.name,
      }))
    }

    return []
  }, [mappingsLoading, mappings?.potentialBookingMappings])

  // Mutations
  const [processPotentialBookingFile, { loading: processing }] =
    useProcessPotentialBookingFileMutation({
      onCompleted: (data) => {
        if (data?.processPotentialBookingFile) {
          toast.success(`File processed successfully.`)
          onCloseDialog()
        }
      },
      refetchQueries: [
        {
          query: GET_POTENTIAL_BOOKING_FILES,
          variables: {
            after: null,
            before: null,
            first: 10,
            last: null,
            order: { createdAt: SortEnumType.Desc },
          },
        },
      ],
    })

  // Handlers
  const handleClose = () => {
    onCloseDialog()
  }

  const handlePreview = () => {
    if (!fileId) return

    setPreviewFileId(fileId)
    setOpenPreviewDialog(true)
  }

  const handleDownload = () => {
    if (!fileId) return

    DownloadFile(fileId)
      .then((response) => {
        let fileName = GetFileName(response)
        if (!fileName) {
          fileName = `file-${+new Date()}.dat`
        }

        SaveFile(response.data, fileName)
      })
      .catch((error) => {
        ShowRestError(error)
      })
  }

  const handleProcess = async (formData: ProcessPotentialBookingFileDtoInput) => {
    await processPotentialBookingFile({
      variables: {
        input: {
          ...formData,
        },
      },
    })
  }

  const onClosePreviewDialog = () => {
    setOpenPreviewDialog(false)
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        scroll='body'
        open={openDialog}
        aria-labelledby='potential-booking-file-dialog'>
        <FplDialogTitle id='potential-booking-file-dialog' onClose={handleClose}>
          <Typography paragraph variant='h4'>
            Potential Booking File ID {id}
          </Typography>
        </FplDialogTitle>

        <DialogContent>
          <Box mb={1}>
            <Typography paragraph>
              File Name: <strong>{potentialBookingFile?.file.originalFileName}</strong>
            </Typography>

            <Typography paragraph>
              Customer Name (uploaded by):{' '}
              <strong>
                {potentialBookingFile?.uploadedByCustomer?.name ??
                  'Not uploaded by a User connected to a Customer'}
              </strong>
            </Typography>
          </Box>

          <Accordion TransitionProps={{ unmountOnExit: true, timeout: 50 }} sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMore />} id='attachments-header'>
              Extracted Data (click here to view)
            </AccordionSummary>
            <AccordionDetails sx={{ alignItems: 'center', flexDirection: 'column' }}>
              {loading ? (
                <Box
                  component='div'
                  height={200}
                  width='100%'
                  display='flex'
                  justifyContent='center'>
                  <CircularProgress size={150} style={{ marginTop: 10 }} variant='indeterminate' />
                </Box>
              ) : (
                <SyntaxHighlighter language='json' style={colorBrewer}>
                  {extractedData}
                </SyntaxHighlighter>
              )}
            </AccordionDetails>
          </Accordion>

          <Box mt={3}>
            <Typography paragraph>To proceed, please select Mapping:</Typography>

            <ControlledSelector
              control={control}
              label='Mapping'
              name='potentialBookingMappingId'
              required
              options={mappingItems}
              error={!!errors.potentialBookingMappingId}
              helperText={errors.potentialBookingMappingId?.message}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='contained' color='grey' onClick={handleClose}>
            Close
          </Button>

          <Button
            variant='contained'
            color='grey'
            startIcon={<Visibility />}
            onClick={handlePreview}>
            Preview File
          </Button>

          <Button
            variant='contained'
            color='grey'
            startIcon={<Download />}
            onClick={handleDownload}>
            Download File
          </Button>

          <LoadingButton
            variant='contained'
            startIcon={<DirectionsRun />}
            loading={loading || processing}
            onClick={handleSubmit(handleProcess)}>
            Process
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <FilePreviewDialog
        fileId={previewFileId}
        openDialog={openPreviewDialog}
        handleCloseDialog={onClosePreviewDialog}
      />
    </>
  )
}

export default PotentialBookingFileDialog
