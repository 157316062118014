import { useEffect, useMemo, useState } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { fromUnixTime } from 'date-fns'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FplDialogTitle, LoadingBackdrop } from 'components/common'
import { Paths } from 'constants/index'
import { Mode } from 'constants/Mode'
import {
  CreateUpdateTaskDtoInput,
  TaskStatus,
  useCreateTaskMutation,
  useGetTaskQuery,
  useUpdateTaskMutation,
} from 'generated/graphql'
import { GET_TASKS_QUERY } from 'graphql/queries'

import TaskForm from './TaskForm'
import validationSchema from './ValidationSchema'

const schema = validationSchema()

interface IProps {
  id: number | null
  bookingId?: string | null
  openDialog: boolean
  onCloseDialog: () => void
}

const CreateUpdateTask = (props: IProps) => {
  const { id, bookingId, openDialog, onCloseDialog } = props
  const [isFullScreen, setIsFullScreen] = useState(false)
  const history = useHistory()
  const { idTokenPayload } = useOidcIdToken()
  const defaultTaskValues: CreateUpdateTaskDtoInput = useMemo(() => {
    return {
      bookingId,
      assignedUserName: idTokenPayload.name || '',
      description: '',
      endDate: null,
      time: null,
      categoryId: null,
      status: TaskStatus.New,
      additionalDetail: '',
    }
  }, [bookingId, idTokenPayload.name])

  const methods = useForm<CreateUpdateTaskDtoInput>({
    shouldUnregister: true,
    defaultValues: defaultTaskValues,
    resolver: joiResolver(schema),
  })

  const { reset, handleSubmit } = methods

  useEffect(() => {
    if (!id) {
      reset(defaultTaskValues)
    }
  }, [id, reset, defaultTaskValues])

  // queries
  const { data, loading } = useGetTaskQuery({
    variables: { id },
    skip: !id,
  })

  // mutations
  const [createTask, { loading: creatingTask }] = useCreateTaskMutation({
    onCompleted: (response) => {
      toast.success(`Task (${response.createTask?.id ?? 0}) created.`)
      onCloseDialog()
    },
    update(cache, { data }) {
      if (data?.createTask) {
        if (bookingId) {
          cache.updateQuery(
            {
              query: GET_TASKS_QUERY,
              variables: { where: { bookingId: { eq: Number(bookingId) } } },
            },
            (queryData) => {
              if (queryData) {
                const edges = [
                  ...queryData.tasks.edges,
                  { __typename: 'TasksEdge', node: data.createTask },
                ]
                const totalCount = queryData.tasks.totalCount + 1

                return {
                  tasks: { ...queryData.tasks, edges, totalCount },
                }
              }

              return null
            },
          )
        }

        cache.updateQuery(
          {
            query: GET_TASKS_QUERY,
            variables: { where: { assignedUserName: { eq: idTokenPayload.name } } },
          },
          (queryData) => {
            if (queryData) {
              const edges = [
                ...queryData.tasks.edges,
                { __typename: 'TasksEdge', node: data.createTask },
              ]
              const totalCount = queryData.tasks.totalCount + 1

              return {
                tasks: { ...queryData.tasks, edges, totalCount },
              }
            }

            return null
          },
        )
      }
    },
  })

  const [updateTask, { loading: updatingTask }] = useUpdateTaskMutation()

  useEffect(() => {
    if (data) {
      const time = data.task?.timeTotalSeconds ? fromUnixTime(data.task.timeTotalSeconds) : null

      reset({ ...data.task, time })
    }
  }, [data, reset])

  const mode = id ? Mode.Update : Mode.Create

  // handlers
  const handleSubmitTask: SubmitHandler<CreateUpdateTaskDtoInput> = (formData) => {
    if (mode === Mode.Create) {
      createTask({
        variables: {
          input: {
            ...formData,
            bookingId: bookingId ? Number(bookingId) : undefined,
            status: TaskStatus.New,
          },
        },
      })
    } else {
      if (data?.task) {
        updateTask({
          variables: {
            input: {
              ...formData,
              id,
              bookingId: bookingId ? Number(bookingId) : data.task.bookingId,
              status: data.task.status,
            },
          },
        }).then(({ data }) => {
          if (data?.updateTask) {
            toast.success(`Task (${data.updateTask.id}) updated.`)
            onCloseDialog()
          }
        })
      }
    }
  }

  const handleTaskStatus = () => {
    const taskData = data?.task

    let newStatus: TaskStatus | null = null

    if (data?.task?.status === TaskStatus.New) {
      newStatus = TaskStatus.Completed
    } else if (data?.task?.status === TaskStatus.Completed) {
      newStatus = TaskStatus.New
    }

    if (taskData && newStatus) {
      const time = taskData.timeTotalSeconds ? fromUnixTime(taskData.timeTotalSeconds) : null

      updateTask({
        variables: {
          input: {
            id,
            bookingId: bookingId ? Number(bookingId) : taskData.bookingId,
            assignedUserName: taskData.assignedUserName,
            description: taskData.description,
            endDate: taskData.endDate,
            time,
            categoryId: taskData.categoryId,
            status: newStatus,
            additionalDetail: taskData.additionalDetail,
          },
        },
      }).then(({ data }) => {
        if (data?.updateTask) {
          toast.success(`Task (${data.updateTask.id}) status updated.`)
          onCloseDialog()
        }
      })
    }
  }

  const handleFullScreenClick = () => setIsFullScreen(!isFullScreen)

  const handleOpenBooking = () => {
    history.push(Paths.bookings.updateWithId(data?.task?.bookingId))
    onCloseDialog()
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='sm'
      fullScreen={isFullScreen}
      aria-labelledby='create-update-task-dialog-title'>
      <FplDialogTitle
        id='create-update-task-dialog-title'
        onClose={handleCloseDialog}
        onFullScreen={handleFullScreenClick}
        isFullScreen={isFullScreen}>
        <Typography variant='h4' component='span'>
          {id ? 'Update' : 'Create'} Task
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <TaskForm />
          <LoadingBackdrop loading={loading} />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent='space-between'>
          <Grid item>
            {mode === Mode.Update && (
              <LoadingButton variant='outlined' loading={updatingTask} onClick={handleTaskStatus}>
                {data?.task?.status === TaskStatus.New && 'Complete Task'}
                {data?.task?.status === TaskStatus.Completed && 'Re-open Task'}
              </LoadingButton>
            )}
          </Grid>

          <Grid item>
            {data?.task?.bookingId && (
              <Button variant='contained' color='grey' onClick={handleOpenBooking}>
                Booking ({data.task.booking?.ourReference || data.task.bookingId})
              </Button>
            )}

            <Button
              variant='contained'
              color='grey'
              onClick={handleCloseDialog}
              sx={{ marginLeft: 1 }}>
              Cancel
            </Button>

            <LoadingButton
              variant='contained'
              sx={{ marginLeft: 1 }}
              loading={creatingTask || updatingTask}
              onClick={handleSubmit(handleSubmitTask)}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateTask
