import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { LoadingBackdrop } from 'components/common'
import { CreateAndUpdateTariffCategoryDtoInput, useGetTariffCategoryQuery } from 'generated/graphql'

import TariffCategoryForm from './TariffCategoryForm'

interface IProps {
  id: number
}

const UpdateTariffCategory = (props: IProps) => {
  const { id } = props

  const { reset } = useFormContext<CreateAndUpdateTariffCategoryDtoInput>()

  // Queries
  const { data: tariffCategoryData, loading: tariffCategoryLoading } = useGetTariffCategoryQuery({
    variables: { id },
  })

  useEffect(() => {
    if (tariffCategoryData) {
      reset({ ...tariffCategoryData.tariffCategory })
    }
  }, [tariffCategoryData, reset])

  return (
    <>
      <TariffCategoryForm />
      <LoadingBackdrop loading={tariffCategoryLoading} />
    </>
  )
}

export default UpdateTariffCategory
