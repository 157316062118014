import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { CardTitle, ControlledTextField } from 'components/common'
import { CreateAndUpdateStrategicPartnerDtoInput } from 'generated/graphql'

const OtherDetails = () => {
  const {
    control,

    formState: { errors },
  } = useFormContext<CreateAndUpdateStrategicPartnerDtoInput>()

  const spacing = 2

  return (
    <>
      <CardTitle>Other Details</CardTitle>
      <Grid container spacing={spacing}>
        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='VAT Number'
            name='vatIdNumber'
            error={!!errors.vatIdNumber}
            helperText={errors.vatIdNumber?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='EORI Number'
            name='eoriNumber'
            error={!!errors.eoriNumber}
            helperText={errors.eoriNumber?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            label='XERO Number'
            name='xeroPurchaseCode'
            error={!!errors.xeroPurchaseCode}
            helperText={errors.xeroPurchaseCode?.message}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OtherDetails
