import {
  AccountPayableStatusCategories,
  AccountPayableTabHash,
} from 'constants/AccountPayableStatusCategories'
import { StatusCategories } from 'constants/StatusCategories'

import { makeVar } from '@apollo/client'

export const isPageLoadingVar = makeVar(false)
export const isBookingProcessingVar = makeVar(false)
//TODO: Investigate whether we need React variables when using local storage.
export const isBookingsAutoUpdatedVar = makeVar<boolean>(true)
export const bookingsStatusCategoryVar = makeVar<StatusCategories>(StatusCategories.InProgress)
export const accountPayableStatusCategoryVar = makeVar<AccountPayableStatusCategories>(
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  setAccountPayableStatusTabCategoryVar(),
)
export const accountPayableFiltersFormVar = makeVar<any>({})
export const accountPayableFiltersDataVar = makeVar<any>({})
export const bookingsFilterFormVar = makeVar<any>({})
export const taskBoardAssignedUserNameVar = makeVar<string | null>(null)

function setAccountPayableStatusTabCategoryVar() {
  const tab = window.location.href.split('#')[1]
  switch (tab) {
    case AccountPayableTabHash.All:
      return AccountPayableStatusCategories.All

    case AccountPayableTabHash.UnderReview:
      return AccountPayableStatusCategories.UnderReview

    case AccountPayableTabHash.Flagged:
      return AccountPayableStatusCategories.Flagged

    case AccountPayableTabHash.PaymentSent:
      return AccountPayableStatusCategories.PaymentSent

    case AccountPayableTabHash.PaymentFailed:
      return AccountPayableStatusCategories.PaymentFailed

    case AccountPayableTabHash.Archive:
      return AccountPayableStatusCategories.Archived

    case AccountPayableTabHash.ScheduledPayment:
      return AccountPayableStatusCategories.ScheduledPayment

    case AccountPayableTabHash.Queries:
      return AccountPayableStatusCategories.OpenQueries
    default:
      return AccountPayableStatusCategories.All
  }
}

export const expandableGridClickedRowIdVar = makeVar<number>(0)
