import { useParams } from 'react-router-dom'

import { FplDataGrid } from 'components/common'
import { GET_ACCOUNTPAYABLE_RECORD_LOGS_QUERY } from 'graphql/queries'
import { useGetAccountsPayableDocumentsHistoryCollumns } from 'hooks/accountsPayable/useGetAccountsPayableDocumentsHistoryCollumns'

const AccountPayableDocumentsHistoryTab = () => {
  const { id } = useParams<{ id?: string }>()
  const [columns] = useGetAccountsPayableDocumentsHistoryCollumns()

  return (
    <FplDataGrid
      query={GET_ACCOUNTPAYABLE_RECORD_LOGS_QUERY}
      queryVariables={{
        accountPayableRecordId: Number(id),
      }}
      refetchWithDelay
      entityName='accountPayableRecordLogs'
      toolbar={{
        caption: 'History',
      }}
      columns={columns}
    />
  )
}

export default AccountPayableDocumentsHistoryTab
