import { useEffect, useMemo } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { DEBOUNCE_DELAY } from 'constants/index'
import { GetBookingQuery, useGetBookingDriverTotalChargeLazyQuery } from 'generated/graphql'
import {
  currencyFormatter,
  percentFormatter,
  getBookingMargin,
  getBookingRevenue,
  getOtherChargesValue,
} from 'helpers'
import { TBookingForm } from 'types/form-types'

interface IProps {
  booking: GetBookingQuery['booking']
}

const BookingInfoBar = (props: IProps) => {
  const { booking } = props

  const { watch } = useFormContext<TBookingForm>()
  const [consignmentFeeValue] = useDebounce(watch('consignmentFee', 0), DEBOUNCE_DELAY)
  const chargesValue = useWatch<TBookingForm>({ name: 'charges' })

  const [getTotalCharge, { data: totalChargeData, loading: loadingCharges }] =
    useGetBookingDriverTotalChargeLazyQuery()

  useEffect(() => {
    if (booking) {
      getTotalCharge({
        variables: {
          bookingId: Number(booking.id),
        },
      })
    }
  }, [getTotalCharge, booking, booking?.drivers])

  const totalDriverCharge: number = useMemo(() => {
    if (booking && totalChargeData?.booking?.totalDriverCharge) {
      return totalChargeData?.booking?.totalDriverCharge
    }

    return 0
  }, [booking, totalChargeData?.booking?.totalDriverCharge])

  const otherCharges = useMemo(() => getOtherChargesValue(chargesValue), [chargesValue])

  const revenue = useMemo(
    () => getBookingRevenue(Number(consignmentFeeValue), otherCharges),
    [consignmentFeeValue, otherCharges],
  )

  const margin = useMemo(
    () => getBookingMargin(revenue, totalDriverCharge),
    [revenue, totalDriverCharge],
  )

  const consignmentFeeFormatted = currencyFormatter.format(consignmentFeeValue)
  const revenueFormatted = currencyFormatter.format(revenue)
  const otherChargesFormatted = currencyFormatter.format(otherCharges)
  const totalDriverChargeFormatted = currencyFormatter.format(totalDriverCharge)
  const marginFormatted = percentFormatter.format(margin)

  return (
    <Grid container alignItems='center' justifyContent='center'>
      <Grid item>
        <Box display='flex' marginX={1}>
          <InfoIcon />
        </Box>
      </Grid>

      <Grid item>
        <Tooltip
          title={`${consignmentFeeFormatted} (Consignment Fee) + ${otherChargesFormatted} (Other Charges)`}>
          <Typography variant='h6'>{`${revenueFormatted} (Revenue) -`}&nbsp;</Typography>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title='Total Driver Charge'>
          <Typography variant='h6'>
            {`${loadingCharges ? 'Calculating...' : totalDriverChargeFormatted} (Cost) =`}&nbsp;
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item>
        <Typography variant='h6'>{`${marginFormatted} (Margin)`}</Typography>
      </Grid>
    </Grid>
  )
}

export default BookingInfoBar
