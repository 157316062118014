import { useCallback, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import GetAppIcon from '@mui/icons-material/GetApp'
import { Box, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { FormProvider, useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { GET_PAGED_CUSTOMER_INVOICE } from 'graphql/queries'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { DownloadInvoiceByInvoiceNumber } from 'services/RestClient'

import InvoiceBookingsDialog from './InvoiceBookingsDialog'
import InvoiceDetails from './InvoiceDetails'

interface IProps {
  customerId: number
}

const CustomerInvoices = (props: IProps) => {
  const { customerId } = props

  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState<number | null>(null)

  const methods = useForm({
    shouldUnregister: true,
    defaultValues: {
      generateSeparateInvoicePerDocket: false,
      creditLimit: '',
      credit: '0.00',
      search: '',
    },
  })
  const { control, watch, setValue } = methods

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedInvoiceNumber(Number(id))
  }

  const handleDownloadInvoice = useCallback(
    (id: GridRowId) => () => {
      DownloadInvoiceByInvoiceNumber(id)
        .then((response) => {
          let fileName = GetFileName(response)
          if (!fileName) {
            fileName = `invoice-${id}.csv`
          }

          SaveFile(response.data, fileName)
        })
        .catch((error) => ShowRestError(error))
    },
    [],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleCloseDialog = () => {
    setSelectedInvoiceNumber(null)
  }

  const filter = useMemo(() => {
    if (searchInput) {
      return {
        or: [
          { id: { eq: Number(searchInput) } },
          { ourReferences: { contains: searchInput } },
          { customerReferences: { contains: searchInput } },
        ],
      }
    } else {
      return undefined
    }
  }, [searchInput])

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'id', headerName: 'Invoice No', minWidth: 150, flex: 0.5 },
      { field: 'ourReferences', headerName: 'Our Reference(s)', minWidth: 150, flex: 1 },
      { field: 'customerReferences', headerName: 'Customer Reference(s)', minWidth: 150, flex: 1 },
      { field: 'bookingStatuses', headerName: 'Booking Status', minWidth: 150, flex: 1 },
      { field: 'bookingDate', headerName: 'Booking Date', minWidth: 150, flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Download'
            size='medium'
            icon={
              <Tooltip title={'Download'} arrow>
                <GetAppIcon />
              </Tooltip>
            }
            onClick={handleDownloadInvoice(id)}
          />,
        ],
      },
    ],
    [handleDownloadInvoice],
  )

  return (
    <>
      <FormProvider {...methods}>
        <InvoiceDetails />

        <Box marginTop={2}>
          <FplDataGrid
            query={GET_PAGED_CUSTOMER_INVOICE}
            queryVariables={{ customerId }}
            entityName='pagedCustomerInvoices'
            columns={columns}
            filter={filter}
            defaultOrder={{ field: 'id', sort: 'desc' }}
            toolbar={{
              caption: 'Invoices',
              rightSide: (
                <Grid item>
                  <Box width='210px'>
                    <ControlledTextField
                      control={control}
                      name='search'
                      label='Search'
                      defaultValue=''
                      size='small'
                      endAdornment={
                        searchInput && (
                          <IconButton size='small' onClick={handleSearchClearClick}>
                            <CloseIcon fontSize='small' />
                          </IconButton>
                        )
                      }
                    />
                  </Box>
                </Grid>
              ),
            }}
            onRowClick={handleRowClick}
          />
        </Box>
      </FormProvider>

      {selectedInvoiceNumber && (
        <InvoiceBookingsDialog
          invoiceNumber={selectedInvoiceNumber}
          open={Boolean(selectedInvoiceNumber)}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default CustomerInvoices
