import { useMemo } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { useGetTariffCategoryNameQuery } from 'generated/graphql'

interface IProps {
  open: boolean
  setOpen: any
}

const GenerateTariffNameDialog = (props: IProps) => {
  const { open, setOpen } = props

  const { getValues, setValue } = useFormContext()

  const customerIdsValue = getValues('customerIds')
  const tariffCategoryIdValue = getValues('tariffCategoryId')

  // queries
  const { data: tariffCategoryData } = useGetTariffCategoryNameQuery({
    variables: { id: Number(tariffCategoryIdValue) },
    skip: !tariffCategoryIdValue,
  })

  const generatedTariffName = useMemo(
    () => `${customerIdsValue?.[0].label} ${tariffCategoryData?.tariffCategory?.name}`,
    [customerIdsValue, tariffCategoryData?.tariffCategory?.name],
  )

  const handleConfirm = () => {
    setValue('name', generatedTariffName)
    setOpen(false)
  }

  const handleReject = () => {
    setOpen(false)
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={open} aria-labelledby='generate-tariff-name-dialog'>
      <DialogTitle id='generate-tariff-name-dialog'>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Would you like the name to be&nbsp;
          <strong>{generatedTariffName}</strong>?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleReject}>
          No
        </Button>
        <Button variant='contained' onClick={handleConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateTariffNameDialog
