import { gql } from '@apollo/client'

export const REFRESH_EXCHANGE_RATES_MUTATION = gql`
  mutation refreshExchangeRates {
    refreshExchangeRates {
      id
      sourceCurrencyId
      targetCurrencyId
    }
  }
`

export const RUN_EXCHANGE_RATE_SERVICE = gql`
  mutation runExchangeRateService {
    runExchangeRateService
  }
`
