import { gql } from '@apollo/client'

import { OtherChargeDetailsFragment } from 'graphql/fragments'

export const GET_OTHER_CHARGE_PAGED_QUERY = gql`
  query GetOtherChargePaged(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [OtherChargeSortInput!]
    $where: OtherChargeFilterInput
  ) {
    pagedCharges(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          description
          rate
          xeroServiceCode
          vatCategoryId
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_OTHER_CHARGE_QUERY = gql`
  query GetOtherCharge($id: Long!) {
    otherCharge(id: $id) {
      id
      ...OtherChargeDetails
    }
    ${OtherChargeDetailsFragment}
  }
`

export const GET_OTHER_CHARGES_QUERY = gql`
  query GetOtherCharges {
    otherCharges {
      id
      ...OtherChargeDetails
    }
    ${OtherChargeDetailsFragment}
  }
`

export const OTHER_CHARGE_NAME_IS_UNIQUE = gql`
  query OtherChargeNameIsUnique($name: String!, $id: Long) {
    otherChargeNameIsUnique(name: $name, id: $id)
  }
`
