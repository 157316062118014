import { useMemo } from 'react'

import { Button } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'
import { format } from 'date-fns'

import { IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/DateFormats'

interface IGridColumnExtended extends IGridColumn {
  valueOptions?: any[]
  getActions?: (params: GridCellParams<any>) => React.ReactNode
}

const useGetDriverBidsColumns = (
  handleAllocateClick,
  isRowSelectable,
  getGrossProfit,
  getGrossProfitPct,
) => {
  const columns: IGridColumnExtended[] = useMemo(() => [
    {
      field: 'name',
      headerName: 'Supplier Name',
      minWidth: 250,
      sortPath: ['driver', 'name'],
      renderCell: ({ row }) => row?.driver.name ?? 'Unknown',
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 100,
      sortPath: ['price'],
      renderCell: ({ row }) => row?.price ?? 'Unknown',
    },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 70,
      sortPath: ['currency', 'id'],
      renderCell: ({ row }) => row?.currency.symbol ?? 'Unknown',
    },
    {
      field: 'grossProfit',
      headerName: 'Gross profit (£)',
      width: 110,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }) => {
        return row?.price
          ? getGrossProfit({ totalPrice: row.price, currencyId: row.currency.id })
          : ''
      },
    },
    {
      field: 'grossProfitPct',
      headerName: 'Gross profit (%) (£)',
      width: 140,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }) => {
        return row?.price
          ? getGrossProfitPct({ totalPrice: row.price, currencyId: row.currency.id })
          : ''
      },
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 150,
      renderCell: ({ row }) => row?.eta && format(new Date(row?.eta), DATE_TIME_FORMAT),
    },
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      width: 100,
      editable: false,
      sortable: true,
      sortPath: ['vehicle', 'vehicleType', 'name'],
      renderCell: ({ row }) => row?.vehicle.vehicleType.name ?? 'Unknown',
    },
    {
      field: 'rating',
      headerName: 'Rating',
      width: 100,
      sortPath: ['driver', 'averageRating'],
      renderCell: ({ row }) => row?.driver.averageRating ?? '',
    },
    {
      field: 'createDate',
      headerName: 'Date/time of creation',
      width: 150,
      sortPath: ['createdAt'],
      renderCell: ({ row }) => row?.createdAt && format(new Date(row?.createdAt), DATE_TIME_FORMAT),
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 150,
      renderCell: ({ row }) => row?.notes ?? 'No data',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      type: 'actions',
      renderCell: ({ id, row }) => (
        <Button variant='contained' onClick={handleAllocateClick(id)}>
          {(row?.isAllocated ? isRowSelectable(id) : !isRowSelectable(id))
            ? 'Deallocate'
            : 'Allocate'}
        </Button>
      )
    },
  ], [getGrossProfit, getGrossProfitPct, handleAllocateClick, isRowSelectable])
  return columns
}

export default useGetDriverBidsColumns
