import { gql } from '@apollo/client'

export const GET_PAGED_NOTIFICATIONS = gql`
  query getNotifications(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: NotificationFilterInput
    $order: [NotificationSortInput!]
  ) {
    notifications(
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      order: $order
    ) {
      edges {
        node {
          id
          name
          description
          username
          isRead
          createdAt
          createdBy
          taskId
          priority
          date
          type

          deliveryStatus
          deliveryStatusLog
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_ALL_NOTIFICATION_HUB_REGISTRATION_INFO = gql`
  query AllNotificationHubRegistrationInfo {
    allNotificationHubRegistrationInfo {
      deviceType
      registrationCount
    }
  }
`

export const GET_ALL_NOTIFICATION_HUB_REGISTRATION = gql`
  query AllNotificationHubRegistration {
    allNotificationHubRegistration {
      registrationId
      tags
    }
  }
`

/*
export const GET_DEVICE_INSTALLATION = gql`
  query DeviceInstallation($installationId: String!) {
    deviceInstallation(installationId: $installationId) {
      installationId
      userId
      pushChannel
      pushChannelExpired
      platform
      expirationTime
      tags
    }
  }
`
*/
