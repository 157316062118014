import { useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { GridEventListener, GridRowHeightParams } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { FplDialogTitle, ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { DriverFilterInput, DriverType } from 'generated/graphql'
import { GET_DRIVERS } from 'graphql/queries'

const columns: IGridColumn[] = [
  { field: 'courierExchangeMemberId', headerName: 'CX Member Id', width: 120 },
  { field: 'name', headerName: 'Driver Name', minWidth: 250, flex: 1 },
  {
    field: 'addressLine1',
    headerName: 'Address',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      return (
        <div>
          <div>{row.addressLine1}</div>
          <div>{row.addressLine2}</div>
          <div>{`${row.city}, ${row.postcode}`}</div>
        </div>
      )
    },
  },
  { field: 'telephoneNumber', headerName: 'Mobile Number', width: 150 },
  { field: 'email', headerName: 'Email', width: 200 },
]

interface IProps {
  openDialog: boolean
  handleCloseDialog: () => void
  onSelectCxDriver: (id: number) => void
}

const SearchCxDriverDialog = (props: IProps) => {
  const { openDialog, handleCloseDialog, onSelectCxDriver } = props

  const { control, watch, setValue } = useForm({
    defaultValues: { search: '' },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectCxDriver(Number(id))
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleClose = () => {
    handleCloseDialog()
  }

  const handleGetRowHeight = useCallback(({ model, densityFactor }: GridRowHeightParams) => {
    if (model.addressLine2) {
      return 80 * densityFactor
    } else {
      return 65 * densityFactor
    }
  }, [])

  const filter: DriverFilterInput = {
    or: [
      { courierExchangeMemberId: { contains: searchValue } },
      { name: { contains: searchValue } },
      { addressLine1: { contains: searchValue } },
      { email: { contains: searchValue } },
    ],
    and: [
      {
        driverType: { eq: DriverType.Cx },
        isActive: { eq: true },
      },
    ],
  }

  return (
    <Dialog maxWidth='md' fullWidth scroll='body' open={openDialog}>
      <FplDialogTitle onClose={handleClose}>
        <Typography paragraph variant='h4'>
          CX Drivers
        </Typography>
      </FplDialogTitle>

      <DialogContent>
        <FplDataGrid
          query={GET_DRIVERS}
          entityName='drivers'
          columns={columns}
          filter={filter}
          defaultOrder={{ field: 'name', sort: 'asc' }}
          toolbar={{
            caption: 'CX Drivers',
            rightSide: (
              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            ),
          }}
          getRowHeight={handleGetRowHeight}
          onRowClick={handleRowClick}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SearchCxDriverDialog
