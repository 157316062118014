import { useEffect } from 'react'

import { Box, Button, FormHelperText, Grid, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledCheckbox, ControlledTextField, TextInput } from 'components/common'
import { Tariff } from 'generated/graphql'
import { emitter } from 'providers'
import { TBookingForm } from 'types/form-types'

interface IProps {
  tariffDetails?: Tariff
  isQuote: boolean
  disabled?: boolean
  onSelectTariffClick: () => void
}

const ExternalTariffForm = (props: IProps) => {
  const { tariffDetails, isQuote, disabled, onSelectTariffClick } = props

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<TBookingForm>()

  useEffect(() => {
    setValue('serviceType', tariffDetails?.xeroCode?.serviceType)
    emitter.emit('xero-service-type-change')
  }, [tariffDetails?.xeroCode?.serviceType, setValue])

  return (
    <Grid container spacing={1.5}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
        {tariffDetails?.tariffIcon && (
          <img
            src={tariffDetails?.tariffIcon?.file?.absoluteUri}
            alt={`${tariffDetails?.tariffIcon?.name} Icon`}
            style={{ maxHeight: '60px' }}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        {isQuote && (
          <Stack alignItems='center'>
            <Button
              variant='contained'
              color='grey'
              disabled={disabled}
              onClick={onSelectTariffClick}>
              Select Tariff
            </Button>

            {errors.tariffId && (
              <FormHelperText error required focused>
                {errors.tariffId.message}
              </FormHelperText>
            )}

            {/* //* Used to focus on form error */}
            <Box sx={{ width: 0, height: 0, overflow: 'hidden' }}>
              <ControlledTextField name='tariffId' label='Tariff Id' />
            </Box>
          </Stack>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput label='Name' value={tariffDetails?.name} disabled={disabled} readOnly />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ControlledCheckbox
          control={control}
          label='Wait &amp; Return'
          name='isWaitAndReturn'
          defaultValue={false}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          label='Category'
          value={tariffDetails?.tariffCategory?.name}
          disabled={disabled}
          readOnly
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput label='Type' value={tariffDetails?.type} disabled={disabled} readOnly />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          label='Vehicle Capacity'
          value={tariffDetails?.tariffIcon?.vehicleCapacity}
          disabled={disabled}
          readOnly
          multiline
        />
      </Grid>
    </Grid>
  )
}

export default ExternalTariffForm
