import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ControlledTextField, ControlledCheckbox, ControlledDatePicker } from 'components/common'
import { CardTitle } from 'components/common/controls'
import { CreateAndUpdateVehicleDtoInput } from 'generated/graphql'

function InsuranceDetails() {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateVehicleDtoInput>()

  return (
    <>
      <CardTitle>Insurance Details</CardTitle>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name='insuranceCompany'
            label='Insurance Co.'
            error={!!errors.insuranceCompany}
            helperText={errors.insuranceCompany?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            multiline
            name='policyOrCoverNote'
            label='Policy/Cover Note'
            error={!!errors.policyOrCoverNote}
            helperText={errors.policyOrCoverNote?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            control={control}
            name='insuranceExpiryDate'
            label='Ins. Exp. Date'
            error={!!errors.insuranceExpiryDate}
            helperText={errors.insuranceExpiryDate?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            control={control}
            name='motTestExpiryDate'
            label='MOT Exp. Date'
            error={!!errors.motTestExpiryDate}
            helperText={errors.motTestExpiryDate?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            control={control}
            name='goodsInTransitExpiryDate'
            label='GIT Exp. Date'
            error={!!errors.goodsInTransitExpiryDate}
            helperText={errors.goodsInTransitExpiryDate?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledCheckbox
            control={control}
            label='Enable Renewal Reminder'
            name='isRenewalReminder'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default InsuranceDetails
