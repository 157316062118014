import { FormControlLabel, Checkbox } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

interface IProps {
  control: Control<any, any>
  label: string
  name: string
  disabled?: boolean
  hidden?: boolean
  defaultValue?: any
  size?: 'small' | 'medium'
}

const ControlledCheckbox = (props: IProps) => {
  const { control, label, name, hidden, defaultValue, size, ...rest } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, name, value, onChange, onBlur } }) => (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              inputRef={ref}
              name={name}
              checked={value}
              onChange={(event) => onChange(event.target.checked)}
              onBlur={onBlur}
              size={size}
              hidden={hidden}
            />
          }
          {...rest}
        />
      )}
    />
  )
}

export default ControlledCheckbox
