import { gql } from '@apollo/client'

export const StrategicPartnerDetailsFragment = gql`
  fragment StrategicPartnerDetails on StrategicPartner {
    id
    name
    serviceType
    addressLine1
    addressLine2
    city
    postcode
    email
    countryId
    telephoneNumber
    vatIdNumber
    eoriNumber
    xeroPurchaseCode
  }
`
export const StrategicPartnerContactDetailsFragment = gql`
  fragment StrategicPartnerContactDetails on StrategicPartnerContact {
    id
    name
    isDefault
    telephoneNumber
    email
    additionalEmails
    department
    jobRole
    strategicPartnerId
  }
`
