import { useState, useEffect, useRef, Fragment } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'

interface IProps {
  options: Array<{ id: any; label: string }>
  disabled?: boolean
  loading?: boolean
  variant?: 'text' | 'contained' | 'outlined'
  size?: 'small' | 'medium' | 'large'
  onButtonClick: (buttonId: any) => void
}

const SplitButton = (props: IProps) => {
  const { options, loading, disabled, variant = 'contained', size, onButtonClick } = props

  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const anchorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelectedIndex(0)
  }, [options, setSelectedIndex])

  const handleClick = () => {
    onButtonClick(options[selectedIndex].id)
  }

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <Fragment>
      <ButtonGroup
        ref={anchorRef}
        variant={variant}
        disabled={disabled || loading}
        size={size}
        aria-label='split button'>
        <LoadingButton variant={variant} loading={loading} onClick={handleClick}>
          {options[selectedIndex]?.label}
        </LoadingButton>

        <Button
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='menu'
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1500 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export { SplitButton }
