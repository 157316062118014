import React from 'react'

import { Box, Grid, Tooltip } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'

import {
  ControlledDateTimePicker,
  ControlledSelector,
  ControlledTextField,
  ISelectorOptions,
  TextInput,
} from 'components/common'
import { GetBookingQuery } from 'generated/graphql'

import { IPoRow, PoTable } from '../PoTable'

interface IProps {
  collectionAddresses: NonNullable<GetBookingQuery['booking']>['addresses'] | undefined
  bookingPackages: NonNullable<GetBookingQuery['booking']>['packages'] | undefined
  selectedAddress: NonNullable<GetBookingQuery['booking']>['addresses'][0] | undefined
  onSelectedAddressIdChange: (addressId: string) => void
  control: any
  errors: any
  hoverText: string
  disabled: boolean
}

const WaitingTimeDetails = (props: IProps) => {
  const {
    collectionAddresses,
    bookingPackages,
    selectedAddress,
    onSelectedAddressIdChange,
    control,
    errors,
    hoverText,
    disabled,
  } = props

  const { fields } = useFieldArray({
    control,
    name: 'packages',
    keyName: 'packagesKey',
  })

  const {
    control: addressControl,
    watch: addressWatch,
    reset: addressReset,
  } = useForm({
    shouldUnregister: true,
    defaultValues: { selectedAddressId: '' },
  })

  const addressesOptions: ISelectorOptions = React.useMemo(
    () =>
      collectionAddresses?.map((ca, index) => ({
        value: ca.id,
        label: `Collection Address ${index + 1}`,
      })) || [],
    [collectionAddresses],
  )

  const addressDetails = React.useMemo(() => {
    const addressData = selectedAddress?.address
    const addressLine2 = addressData?.addressLine2 ? `${addressData?.addressLine2}, ` : ''

    return `${addressData?.name}, ${addressData?.addressLine1}, ${addressLine2}${addressData?.city}`
  }, [selectedAddress])

  const pobRows = React.useMemo(
    () =>
      collectionAddresses?.map((ca) => {
        const addressData = ca.address
        const addressLine2 = addressData?.addressLine2 ? `${addressData?.addressLine2}, ` : ''
        const address =
          `${addressData?.name}, ${addressData?.addressLine1},` +
          ` ${addressLine2}${addressData?.city}`
        const cxWeight = ca.courierServiceWeight
          ? `${ca.courierServiceWeight} (${ca.courierServiceWeightUnit})`
          : ''

        return {
          id: ca.id,
          address,
          dateTime: ca.signedAt,
          wtTime: ca.waitingTime,
          cxNumberOfItems: ca.courierServiceNumberOfItems,
          cxWeight,
          signedBy: ca.signedBy,
        } as IPoRow
      }) || [],
    [collectionAddresses],
  )

  React.useEffect(() => {
    if (selectedAddress?.id) {
      addressReset({ selectedAddressId: selectedAddress.id })
    }
  }, [selectedAddress?.id, addressReset])

  React.useEffect(() => {
    const subscription = addressWatch((data, { name }) => {
      if (name === 'selectedAddressId') {
        onSelectedAddressIdChange(String(data.selectedAddressId))
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [addressWatch, onSelectedAddressIdChange])

  const getPackageName = React.useCallback(
    (packageId: string) => {
      if (bookingPackages) {
        const packIndex = bookingPackages.findIndex(
          (bookingPackage) => String(bookingPackage.id) === String(packageId),
        )
        return `Pack ${packIndex !== -1 ? packIndex + 1 : 'unknown'}`
      } else {
        return 'Pack unknown'
      }
    },
    [bookingPackages],
  )

  return (
    <Tooltip title={disabled ? hoverText : ''} followCursor>
      <div>
        <Grid container columnSpacing={3} rowSpacing={1.5}>
          <Grid item xs={12} sm={6}>
            <ControlledSelector
              control={addressControl}
              label='Address'
              name='selectedAddressId'
              options={addressesOptions}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledDateTimePicker
              control={control}
              name='signedAt'
              label='Signed At'
              defaultValue={null}
              required
              disabled={disabled}
              error={!!errors.signedAt}
              helperText={errors.signedAt?.message}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledTextField
              control={control}
              name='waitingTime'
              label='Wt. Time'
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              sanitizedNumber
              endAdornment='mins'
              defaultValue=''
              min='0'
              disabled={disabled}
              error={!!errors.waitingTime}
              helperText={errors.waitingTime?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              name='addressDetails'
              label='Address Details'
              value={addressDetails}
              readOnly
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledTextField
              control={control}
              name='signedByFirstName'
              label='Signed By First name'
              defaultValue=''
              disabled={disabled}
              error={!!errors.signedByFirstName}
              helperText={errors.signedByFirstName?.message}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledTextField
              control={control}
              name='signedByLastName'
              label='Signed By Last name'
              defaultValue=''
              disabled={disabled}
              error={!!errors.signedByLastName}
              helperText={errors.signedByLastName?.message}
            />
          </Grid>

          {fields.length > 0 &&
            fields.map((field, index) => (
              <React.Fragment key={field.packagesKey}>
                <Grid item xs={12} sm={2}>
                  <TextInput
                    name='addressDetails'
                    label='Package'
                    // @ts-ignore
                    value={getPackageName(field.packageId)}
                    readOnly
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextInput
                    name='remainingPackages'
                    label='Remaining Packages'
                    // @ts-ignore
                    value={field.remainingPackages}
                    readOnly
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <ControlledTextField
                    control={control}
                    name={`packages[${index}].quantity`}
                    label='Processed Packages'
                    defaultValue='0'
                    type='text'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    sanitizedNumber
                    min='0'
                    required
                    disabled={disabled}
                    error={!!errors.packages?.[index].quantity}
                    helperText={errors.packages?.[index].quantity?.message}
                  />
                </Grid>

                {/* This is hidden elements for RHF registration */}
                <Box hidden>
                  <ControlledTextField
                    control={control}
                    name={`packages[${index}].packageId`}
                    label=''
                    // @ts-ignore
                    defaultValue={field.packageId}
                  />
                </Box>
              </React.Fragment>
            ))}

          <Grid item xs={12} sm={6}>
            <ControlledDateTimePicker
              control={control}
              name='arrivedAt'
              label='Arrived At'
              disabled={disabled}
              error={!!errors.arrivedAt}
              helperText={errors.arrivedAt?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              name='noteFromDriver'
              label='Notes'
              defaultValue=''
              multiline
              disabled={disabled}
              error={!!errors.noteFromDriver}
              helperText={errors.noteFromDriver?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Box my={2}>
              <PoTable rows={pobRows} selectedAddressId={selectedAddress?.id} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  )
}

export default WaitingTimeDetails
