import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { Mode } from 'constants/index'
import { useGetCustomerQuery, File, CreateAndUpdateCustomerDtoInput } from 'generated/graphql'

import CustomerFormControls from './CustomerFormControls'

interface IProps {
  customerId: number
  newLogo: any
  setNewLogo: React.Dispatch<React.SetStateAction<any>>
  uploadingLogo?: boolean
  isProcessing: boolean
  onUploadCustomerLogo: (file) => void
  onRemoveCustomerLogo: () => void
}

const UpdateCustomer = (props: IProps) => {
  const { customerId, newLogo, setNewLogo, uploadingLogo, isProcessing, onUploadCustomerLogo, onRemoveCustomerLogo } =
    props

  const { reset } = useFormContext<CreateAndUpdateCustomerDtoInput>()

  // Queries
  const { data: customerData, loading: customerLoading } = useGetCustomerQuery({
    fetchPolicy: 'network-only',
    variables: { customerId },
  })

  useEffect(() => {
    if (customerData) {
      reset({ ...customerData?.customer.customer })
    }
  }, [customerData, reset])

  return (
    <CustomerFormControls
      mode={Mode.Update}
      newLogo={newLogo}
      customerLoading={customerLoading}
      isProcessing={isProcessing}
      customerLogo={{
        onUploadCustomerLogo,
        onRemoveCustomerLogo,
        setNewLogo,
        file: customerData?.customer.logo as File,
        uploadingLogo,
      }}
    />
  )
}

export default UpdateCustomer
