import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
  name: string
  label: string
  defaultValue?: any
  variant?: 'standard' | 'filled' | 'outlined'
  required?: boolean
  fullWidth?: boolean
  disabled?: boolean
  autoComplete?: string
  disablePast?: boolean
  error?: any
  helperText?: any
}

const ControlledTimePicker = (props: IProps) => {
  const {
    control,
    name,
    defaultValue = '',
    variant,
    fullWidth = true,
    required,
    error,
    helperText,
    ...otherProps
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...restField } }) => (
        <TimePicker
          {...restField}
          inputRef={ref}
          value={restField.value ? new Date(restField.value) : null}
          timeSteps={{ minutes: 2 }}
          slotProps={{
            textField: {
              variant: variant,
              fullWidth: fullWidth,
              required: required,
              error: error,
              helperText: helperText,
            },
          }}
          {...otherProps}
        />
      )}
    />
  )
}

export default ControlledTimePicker
