import { useState } from 'react'


import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ControlledCountryAutocomplete, ControlledTextField } from 'components/common'
import { DEFAULT_COUNTRY_ID } from 'constants/index'
import {
  UpdateProfileDtoInput,
  useGetMyProfileQuery,
  useUpdateUserProfileMutation,
} from 'generated/graphql'

const validationSchema = Joi.object({
  firstName: Joi.string().label('First name').required(),
  lastName: Joi.string().label('Last name').required(),
  email: Joi.string()
    .label('Email')
    .email({ tlds: { allow: false } })
    .required(),
  phoneNumber: Joi.string().label('Phone number').required(),
  city: Joi.string().label('City').required(),
  countryId: Joi.number().label('Country').required(),
})

const ProfileDetailsForm = () => {
  const [notFound, setNotFound] = useState(false)
  const [profile, setProfile] = useState<UpdateProfileDtoInput>({
    city: '',
    countryId: DEFAULT_COUNTRY_ID,
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  })

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, errors },
  } = useForm<UpdateProfileDtoInput>({
    shouldUnregister: true,
    resolver: joiResolver(validationSchema),
    defaultValues: profile,
  })

  // operations
  const { loading: profileLoading, refetch: profileRefetch } = useGetMyProfileQuery({
    onError: () => {
      setNotFound(true)
    },
    onCompleted: (data) => {
      if (!data.myProfile) {
        setNotFound(true)
        return
      }

      const { __typename, userName, ...restData } = data.myProfile
      const countryId = restData.countryId ?? DEFAULT_COUNTRY_ID
      const city = restData.city ?? ''

      setProfile({ ...restData, city, countryId })
      reset({ ...restData, countryId, city })
    },
  })

  const [updateProfile, { loading: updatingProfile }] = useUpdateUserProfileMutation({
    onCompleted: (data) => {
      if (data.updateMyProfile) {
        const { __typename, ...restData } = data.updateMyProfile

        const city = restData.city ?? ''
        const countryId = Number(restData.countryId)
        setProfile({ ...restData, city, countryId })
        reset({ ...restData, city, countryId })

        profileRefetch()

        toast.success('Profile updated successfully!')
      }
    },
  })

  // handlers
  const handleCancelClick = () => reset({ ...profile })

  const onSubmit = (values: UpdateProfileDtoInput) => {
    updateProfile({
      variables: {
        input: {
          ...values,
        },
      },
    })
  }

  if (notFound) {
    return <Typography variant='h6'>Not found</Typography>
  }

  if (profileLoading) {
    return <Typography variant='h6'>Loading...</Typography>
  }

  return (
    <form noValidate onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Card>
        <CardHeader title='User details' subheader='The information can be edited ' />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {/* First name */}
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                name='firstName'
                label='First name'
                required
                fullWidth
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
              />
            </Grid>

            {/* Last name */}
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                name='lastName'
                label='Last name'
                required
                fullWidth
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                name='email'
                label='Email'
                required
                type='email'
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Grid>

            {/* Phone number */}
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                control={control}
                label='Phone number'
                name='phoneNumber'
                required
                type='tel'
                fullWidth
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>

            {/* City */}
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                label='City'
                name='city'
                control={control}
                required
                fullWidth
                error={Boolean(errors.city)}
                helperText={errors.city?.message}
              />
            </Grid>

            {/* Country */}
            <Grid item xs={12} sm={6}>
              <ControlledCountryAutocomplete
                control={control}
                name='countryId'
                required
                defaultValue={profile.countryId}
                error={Boolean(errors.countryId)}
                helperText={errors.countryId?.message}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        <Stack direction='row' spacing={1.5} justifyContent='flex-end' padding={1}>
          <Button disabled={!isDirty} onClick={handleCancelClick}>
            Cancel
          </Button>

          <LoadingButton
            loading={updatingProfile}
            disabled={!isDirty}
            type='submit'
            color='primary'
            variant='contained'>
            Submit
          </LoadingButton>
        </Stack>
      </Card>
    </form>
  )
}

export default ProfileDetailsForm
