import { gql } from '@apollo/client'

import { CommentaryDetailsFragment } from 'graphql/fragments'

export const GET_COMMENTARIES_QUERY = gql`
  query getCommentaries($where: CommentaryFilterInput, $order: [CommentarySortInput!]){
    commentaries(where: $where, order: $order){
      id

      ...CommentaryDetails
    }
    ${CommentaryDetailsFragment}
  }
`

export const GET_COMMENTARY_QUERY = gql`
  query getCommentary($id: Long!){
    commentary(id:$id){
      id
      
      ...CommentaryDetails
    }
    ${CommentaryDetailsFragment}
  }
`
