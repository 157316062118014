import { gql } from '@apollo/client'

export const EmailTemplateDetailsFragment = gql`
  fragment EmailTemplateDetails on EmailTemplate {
    id
    type
    isBlindCarbonCopyEnabled
    blindCarbonCopy
    subject
    message
    iconUri
    tokens
  }
`
