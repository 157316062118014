import { gql } from '@apollo/client'

import { BookingAllocatedDriverFragment } from 'graphql/fragments'

export const ALLOCATE_DRIVERS = gql`
    mutation createBookingDrivers($input: [CreateAndUpdateBookingDriverDtoInput!]!) {
        createBookingDrivers(input: $input) {
            id
            ...BookingAllocatedDriver

            booking {
                id
                takenBy
                takenOn
            }
        }
        ${BookingAllocatedDriverFragment}
    }
`

export const ALLOCATE_DRIVER_MUTATION = gql`
    mutation CreateBookingDriver($input: CreateAndUpdateBookingDriverDtoInput!) {
        createBookingDriver(input: $input) {
            id
            ...BookingAllocatedDriver
        },
        ${BookingAllocatedDriverFragment}

    }
`

export const UPDATE_DRIVER_MUTATION = gql`
    mutation UpdateBookingDriver($input: CreateAndUpdateBookingDriverDtoInput!) {
        updateBookingDriver(input: $input) {
            id
            ...BookingAllocatedDriver
        },
        ${BookingAllocatedDriverFragment}
    }
`

export const DEALLOCATE_DRIVER_MUTATION = gql`
    mutation DeleteBookingDriver($bookingDriverId: Long!) {
        deleteBookingDriver(bookingDriverId: $bookingDriverId) {
            id
            ...BookingAllocatedDriver
        }
        ${BookingAllocatedDriverFragment}
    }
`

export const DEALLOCATE_DRIVER_BY_BOOKING_AND_DRIVER_MUTATION = gql`
    mutation DeleteBookingDriverByBookingAndDriverId($bookingId: Long!, $driverId: Long!) {
        deleteBookingDriverByBookingAndDriverId(bookingId: $bookingId, driverId: $driverId) {
            id
            ...BookingAllocatedDriver
        }
        ${BookingAllocatedDriverFragment}
    }
`
