import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { Box, IconButton, DialogTitle } from '@mui/material'

interface IProps {
  id?: string
  children: React.ReactNode
  isFullScreen?: boolean
  onClose: () => void
  onFullScreen?: () => void
}

const FplDialogTitle = (props: IProps) => {
  const { children, isFullScreen, onClose, onFullScreen, ...other } = props

  return (
    <DialogTitle {...other}>
      {children}
      <Box
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
        }}>
        {onFullScreen && (
          <IconButton aria-label='full-screen' onClick={onFullScreen} size='large'>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        )}
        <IconButton aria-label='close' onClick={onClose} size='large'>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  )
}

export default FplDialogTitle
