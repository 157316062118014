import React from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'

import { useGetPermissionsQuery } from 'generated/graphql'

import PermissionContext from './PermissionContext'

interface IProps {
  children: React.ReactNode
}

// ToDo: Add memoization.
const PermissionProvider = (props: IProps) => {
  const { children } = props
  const { idTokenPayload } = useOidcIdToken()

  const { data: getPermissionsData, loading } = useGetPermissionsQuery({
    variables: {
      role: idTokenPayload.role,
    },
  })

  const isAllowedTo = (permission: string | Array<string>): boolean => {
    if (typeof permission === 'string') {
      if (getPermissionsData?.policyRoles) {
        return getPermissionsData.policyRoles
          .map((policyRole) => policyRole.policy.name)
          .includes(permission)
      } else return false
    } else if (Array.isArray(permission)) {
      if (getPermissionsData?.policyRoles) {
        return getPermissionsData.policyRoles
          .map((policyRole) => policyRole.policy.name)
          .some((value) => permission.includes(value))
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <PermissionContext.Provider value={{ isAllowedTo, loading }}>
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider
