import { useMemo } from 'react'

import { Button } from '@mui/material'
import { useWatch } from 'react-hook-form'

import { Restricted } from 'providers'

interface IProps {
  disabled: boolean
}
const SopsButton = (props: IProps) => {
  const { disabled } = props

  const customerIdValue = useWatch({ name: 'customerId' })

  const handleViewSops = () => {
    // See: https://stackoverflow.com/a/63627688/1578975
    const url = `/customer/${customerIdValue}#sop`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const isSopsButtonDisabled = useMemo(
    () => disabled || !customerIdValue || customerIdValue === '',
    [disabled, customerIdValue],
  )

  return (
    <Restricted to='ViewStandardOperatingProcedures'>
      <Button
        variant='contained'
        color='grey'
        disabled={isSopsButtonDisabled}
        onClick={handleViewSops}>
        View SOPs
      </Button>
    </Restricted>
  )
}

export default SopsButton
