import ContactsIcon from '@mui/icons-material/Contacts'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'
import PeopleIcon from '@mui/icons-material/People'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { SelectChangeEvent, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridCellParams } from '@mui/x-data-grid'
import { CustomEditSelect, IGridColumn } from 'components/common'
import { numberFormat } from 'helpers'
import { toast } from 'react-toastify'

interface IGridColumnExtended extends IGridColumn {
  valueOptions?: any[]
  getActions?: (params: GridCellParams<any>) => React.ReactNode
}

const useGetSelectDriverDetailsColumns = (
  bookingDistance,
  currencyItems,
  isRowSelectable,
  handleShowVehicles,
  handleShowSubDrivers,
  handleShowContacts,
  isInstructionEditable,
  handleShowInstruction,
) => {
  const columns: IGridColumnExtended[] = [
    {
      field: 'averageRating',
      headerName: 'Rating',
      minWidth: 40,
      valueGetter: ({ row }) => {
        return row.averageRating ? numberFormat(row.averageRating, 0, 1) : ''
      },
    },
    { field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
    {
      field: 'xeroCodeId',
      headerName: 'Service Code',
      width: 150,
      renderCell: ({ row }) => row?.xeroCode?.name ?? 'Unknown',
    },
    {
      field: 'minCharge',
      headerName: 'Charge',
      width: 90,
      type: 'number',
      editable: true,
      valueGetter: ({ row }) => {
        let charge = 0
        if (bookingDistance) {
          if (bookingDistance < row.minMiles) {
            charge = row.distanceRate * row.minMiles
          } else {
            charge = row.distanceRate * bookingDistance
          }
          if (charge < row.minCharge) {
            charge = row.minCharge
          }
        }

        return charge
      },
    },
    {
      field: 'extraPay',
      headerName: 'Extra Pay',
      width: 90,
      type: 'number',
      sortable: false,
      editable: true,
      valueGetter: () => 0,
    },
    {
      field: 'currencyId',
      headerName: 'Ccy',
      width: 50,
      type: 'singleSelect',
      editable: true,
      valueOptions: currencyItems,
      renderCell: ({ row }) => row?.currency?.code,
      renderEditCell: ({ value, id, field, row, api }) => (
        <CustomEditSelect
          items={currencyItems}
          value={value}
          handleChange={(event: SelectChangeEvent<string>) => {
            const newValue = event.target.value
            if (newValue) {
              api.setEditCellValue({ id, field, value: newValue })
              if (Number(newValue) !== Number(row.currency.id)) {
                toast.warning(
                  'The currency is already defined for the Supplier. Altering it could result in payment failure.',
                )
              }
            }
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 170,
      type: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          key={id}
          label='Vehicles'
          size='medium'
          icon={
            <Tooltip title={'Vehicles'} arrow>
              <DirectionsCarFilledIcon />
            </Tooltip>
          }
          disabled={!isRowSelectable({ id })}
          onClick={handleShowVehicles(id)}
        />,
        <GridActionsCellItem
          key={id}
          label='Sub-drivers'
          size='medium'
          icon={
            <Tooltip title={'Sub-drivers'} arrow>
              <PeopleIcon />
            </Tooltip>
          }
          disabled={!isRowSelectable({ id })}
          onClick={handleShowSubDrivers(id)}
        />,

        <GridActionsCellItem
          key={id}
          label='Contacts'
          size='medium'
          icon={
            <Tooltip title={'Contacts'} arrow>
              <ContactsIcon />
            </Tooltip>
          }
          disabled={!isRowSelectable({ id })}
          onClick={handleShowContacts(id)}
        />,

        <GridActionsCellItem
          key={id}
          label='Instruction'
          size='medium'
          icon={
            <Tooltip title={'Instruction'} arrow>
              <TextSnippetIcon />
            </Tooltip>
          }
          disabled={!isInstructionEditable({ id })}
          onClick={handleShowInstruction(id)}
        />,
      ],
    },
  ]
  return columns
}

export default useGetSelectDriverDetailsColumns
