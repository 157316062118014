import { DialogContentText } from '@mui/material'

import { ConfirmDialog } from 'components/common'

import { DeleteBookingDialog } from '../../DeleteBookingDialog'
import { ConfirmCancellationDialog } from '../ConfirmCancellationDialog'

interface IProps {
  bookingId: string | undefined
  openConfirmCancellation: boolean
  openConfirmQuoteLost: boolean
  openDeleteDialog: any
  openConfirmCompletion: any
  setOpenConfirmQuoteLost: React.Dispatch<React.SetStateAction<boolean>>
  setOpenConfirmCompletion: React.Dispatch<React.SetStateAction<boolean>>
  setOpenConfirmCancellation: React.Dispatch<React.SetStateAction<boolean>>
  handleConfirmQuoteLost: (quoteLostDetail: string, quoteLostReasonId?: string | undefined) => void
  handleConfirmCancellation: (cancellationReason: string) => void
  handleCloseDeleteDialog: () => void
  handleConfirmCompletion: () => void
}

const ConfirmationDialogs = ({
  bookingId,
  openDeleteDialog,
  handleCloseDeleteDialog,
  openConfirmCancellation,
  setOpenConfirmCancellation,
  handleConfirmCancellation,
  openConfirmQuoteLost,
  setOpenConfirmQuoteLost,
  handleConfirmQuoteLost,
  openConfirmCompletion,
  setOpenConfirmCompletion,
  handleConfirmCompletion,
}: IProps) => {
  return (
    <>
      {openDeleteDialog.open && bookingId && (
        <DeleteBookingDialog
          bookingId={Number(bookingId)}
          captionText={'Delete'}
          handleCloseDialog={handleCloseDeleteDialog}
          openDialog={openDeleteDialog.open}
          operationType={openDeleteDialog.operationType}
        />
      )}

      {openConfirmCancellation && (
        <ConfirmCancellationDialog
          open={openConfirmCancellation}
          isQuote={false}
          setOpen={setOpenConfirmCancellation}
          onConfirmCancellation={handleConfirmCancellation}
        />
      )}

      {openConfirmQuoteLost && (
        <ConfirmCancellationDialog
          open={openConfirmQuoteLost}
          isQuote
          setOpen={setOpenConfirmQuoteLost}
          onConfirmCancellation={handleConfirmQuoteLost}
        />
      )}

      {openConfirmCompletion && (
        <ConfirmDialog
          title='Confirm Completion'
          open={openConfirmCompletion}
          setOpen={setOpenConfirmCompletion}
          onConfirm={handleConfirmCompletion}>
          <DialogContentText>
            The profit margin is less than 20%. Complete anyway?
          </DialogContentText>
        </ConfirmDialog>
      )}
    </>
  )
}

export default ConfirmationDialogs
