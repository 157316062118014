import { gql } from '@apollo/client'

const typeDefs = gql`
  extend type Query {
    isThemeLight: Boolean!
  }

  extend type Mutation {
    toggleTheme: Boolean!
  }
`

export default typeDefs
