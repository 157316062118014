import { useMemo } from 'react'

import { Box } from '@mui/system'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'

interface IProps {
  data: string[]
  loading?: boolean
}

const ImportResult = (props: IProps) => {
  const { data } = props

  const columns: GridColDef[] = [{ field: 'logMessage', headerName: 'Log message', width: 900 }]
  const rows: GridRowsProp = useMemo(
    () => data.map((s, i) => ({ id: `log-${i}`, logMessage: s })),
    [data],
  )
  return (
    <Box sx={{ height: 300 }}>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={props.loading}
        // hideFooterPagination
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default ImportResult
