import { AccountPayableRecordStatus } from 'generated/graphql'
import { TOrderDetails } from 'types/orderDetails.types'

import OrderDetailsItem from './OrderDetailsItem'
import PurchaseCodeForm from './PurchaseCodeForm'

interface IProps {
  orderDetails: TOrderDetails
  accountPayableRecordId: number
  accountPayableRecordStatus?: AccountPayableRecordStatus
  driverId?: string
}

export const OrderDetailsLabel = {
  fplRef: 'Our Ref',
  customerRef: 'Customer Ref',
  loadId: 'Load ID',
  transactionId: 'Transaction ID',
  //agreedRate: 'TODO',
  totalPrice: 'Total Price',
  tariff: 'Tariff',
  tariffCategory: 'Tariff Category',
}

function OrderDetailsItems({
  orderDetails,
  accountPayableRecordId,
  accountPayableRecordStatus,
  driverId,
}: IProps) {
  return (
    <div>
      {Object.keys(OrderDetailsLabel).map((key) => (
        <>
          <OrderDetailsItem key={key} label={OrderDetailsLabel[key]} value={orderDetails[key]} />
          {key === 'loadId' && (
            <PurchaseCodeForm
              accountPayableRecordId={accountPayableRecordId}
              accountPayableRecordStatus={accountPayableRecordStatus}
              driverId={driverId}
            />
          )}
        </>
      ))}
    </div>
  )
}

export default OrderDetailsItems
