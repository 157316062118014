import { Fragment, useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { GridRowId } from '@mui/x-data-grid'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { format, parseJSON } from 'date-fns'
import { toast } from 'react-toastify'

import { DATE_FORMAT } from 'constants/DateFormats'
import {
  useGetAccountPayableRecordsByIdsQuery,
  useUpdateAccountPayablesPaymentDateMutation,
} from 'generated/graphql'

interface IProps {
  open: boolean
  setOpen: (value: boolean) => void
  selectedIds: GridRowId[]
}

const ManagePaymentSheduleDialog = (props: IProps) => {
  const { open, setOpen, selectedIds } = props
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [sanitizedInvoice, setSanitizedInvoice] = useState<any[] | undefined>([])
  const { data } = useGetAccountPayableRecordsByIdsQuery({
    variables: {
      accountPayableRecordsIds: selectedIds.map((id) => parseInt(id.toString(), 10)),
    },
    skip: !open,
  })

  const handleOnClose = () => {
    setOpen(false)
    setDueDate(null)
  }

  const [updatePaymentDate] = useUpdateAccountPayablesPaymentDateMutation({
    onCompleted: () => {
      handleOnClose()
      toast.success('Payment date updated successfully')
    },
  })

  const updateDate = () => {
    updatePaymentDate({
      variables: {
        input: {
          accountPayableIds: selectedIds.map((id) => parseInt(id.toString(), 10)),
          paymentDate: dueDate,
        },
      },
    })
  }

  useEffect(() => {
    const sanitizedInvoices = data?.accountPayableRecordsByIds.map((invoice) => {
      const invoiceDate = new Date(invoice.bookingInvoice.invoiceDate)
      const minPaymentDate = new Date(invoiceDate)
      minPaymentDate.setDate(invoiceDate.getDate() + 7)

      return {
        id: invoice.bookingInvoice.id,
        reference: invoice.bookingInvoice.driverInvoiceNumber,
        minPaymentDate: minPaymentDate,
        validation: dueDate && dueDate >= minPaymentDate,
      }
    })

    setSanitizedInvoice(sanitizedInvoices)
  }, [data, dueDate])

  const handleSetDueDateToEndOfDate = (date: Date | null) => {
    date?.setHours(23)
    date?.setMinutes(59)
    if (date) {
      setDueDate(new Date(date))
    }
  }

  return (
    <Dialog maxWidth='xs' open={open} onClose={handleOnClose}>
      <DialogTitle id='confirm-dialog'>Change due date</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You chose to modify the Due date of the selected Records:{' '}
          {sanitizedInvoice?.map((i, index) => (
            <Fragment key={index}>
              {i.reference}
              {index < sanitizedInvoice.length - 1 && ', '}
            </Fragment>
          ))}
        </DialogContentText>
        <StaticDatePicker
          sx={{
            border: '1px solid',
            borderColor: (theme) => theme.palette.primary.light,
            borderRadius: '5px',
            maxWidth: '320px',
            margin: '2rem auto 1rem',
          }}
          slotProps={{
            actionBar: { sx: { display: 'none' } },
          }}
          minDate={new Date()}
          onChange={(date: Date | null) => {
            handleSetDueDateToEndOfDate(date)
          }}
        />
        {sanitizedInvoice
          ?.filter((invoice) => invoice.validation === false)
          .map((invoice) => {
            return (
              <Typography color={'red'} fontSize={'small'} key={invoice.id}>
                Invoice No. {invoice.reference} has min payment date:{' '}
                {format(parseJSON(invoice.minPaymentDate as string), DATE_FORMAT)}{' '}
              </Typography>
            )
          })}
      </DialogContent>
      <DialogActions sx={{ width: '340px', margin: 'auto', marginBottom: '1rem' }}>
        <Button variant='outlined' fullWidth onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={dueDate == null || sanitizedInvoice?.some((invoice) => !invoice.validation)}
          fullWidth
          onClick={updateDate}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ManagePaymentSheduleDialog
