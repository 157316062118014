import { gql } from '@apollo/client'

export const DELETE_XERO_CODE = gql`
  mutation deleteXeroCode($id: Long!) {
    deleteXeroCode(id: $id)
  }
`

export const CREATE_XERO_CODE_MUTATION = gql`
  mutation CreateXeroCode($input: CreateAndUpdateXeroCodeDtoInput!) {
    createXeroCode(input: $input) {
      id
      name
      code
      description
      type
      serviceType
      isInternational
    }
  }
`

export const UPDATE_XERO_CODE_MUTATION = gql`
  mutation UpdateXeroCode($input: CreateAndUpdateXeroCodeDtoInput!) {
    updateXeroCode(input: $input) {
      id
      name
      code
      description
      type
      serviceType
      isInternational
    }
  }
`
