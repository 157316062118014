import React from 'react'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Divider, IconButton, Toolbar } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { grey } from '@mui/material/colors'
import MuiDrawer from '@mui/material/Drawer'
import { styled, Theme, CSSObject } from '@mui/material/styles'

import { Restricted } from 'providers/PermissionProvider'

import RouterOutlet from '../RouterOutlet'
import Buttons from './Buttons'
import DrawerContent from './DrawerContent'
import PageLoadingOverlay from './PageLoadingOverlay'
import { ExternalSearch, InternalSearch } from './Search'
import Title from './Title'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: grey[200],
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: grey[200],
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  minHeight: '100vh',
  width: `calc(100% - ${drawerWidth}px)`,
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}))

const Nav = () => {
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar color='default' position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerOpen}
            size='large'
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Title />
          <Restricted to='ViewSearchResults'>
            <InternalSearch />
          </Restricted>
          <Restricted to='ViewExternalSearchResults'>
            <ExternalSearch />
          </Restricted>
          <Box sx={{ flexGrow: 1 }} />
          <Buttons />
        </Toolbar>
        <PageLoadingOverlay />
      </AppBar>

      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton aria-label='close drawer' onClick={handleDrawerClose} size='large'>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <DrawerContent />
      </Drawer>
      <MainContent>
        <DrawerHeader />
        <Box sx={{ flexGrow: 1 }}>
          <RouterOutlet />
        </Box>
      </MainContent>
    </Box>
  )
}

export default Nav
