import { gql } from '@apollo/client'

import { ActivityDetailsFragment } from 'graphql/fragments'

export const CREATE_ACTIVITY_MUTATION = gql`
  mutation createActivity($input: CreateUpdateActivityDtoInput!){
    createActivity(input: $input) {
      id

      ...ActivityDetails
    }

    ${ActivityDetailsFragment}
  }
`

export const UPDATE_ACTIVITY_MUTATION = gql`
  mutation updateActivity($input: CreateUpdateActivityDtoInput!){
    updateActivity(input: $input) {
      id

      ...ActivityDetails
    }
    
    ${ActivityDetailsFragment}
  }
`

export const DELETE_ACTIVITY_MUTATION = gql`
  mutation deleteActivity($id: Long!) {
    deleteActivity(id: $id)
  }
`
