import Joi from 'joi'

import { ContactEntity, EntityConstants } from 'constants/EntityConstants'

const SuppliersContactValidation = Joi.object({
  name: Joi.string().empty('').max(EntityConstants.MAX_NAME_LENGTH),
  telephoneNumber: Joi.string().empty('').max(EntityConstants.MAX_TELEPHONE_NUMBER_LENGTH),
  email: Joi.string().email({ tlds: false }).empty('').max(EntityConstants.MAX_EMAIL_LENGTH),
  additionalEmails: Joi.string()
    .email({ multiple: true, separator: ';', tlds: false })
    .optional()
    .allow('')
    .max(ContactEntity.MAX_ADDITIONAL_EMAILS_LENGTH),
  department: Joi.string().optional().empty('').allow(null),
  jobRole: Joi.string().optional().empty('').allow(null),
})

export { SuppliersContactValidation }
