import { Grid } from '@mui/material'

import { TextInput } from 'components/common'
import { GetBookingQuery } from 'generated/graphql'

interface IProps {
  booking?: GetBookingQuery['booking']
}

const ChargesDetails = (props: IProps) => {
  const { booking } = props

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={4}>
          <TextInput
            label='Consignment Fee'
            value={booking?.consignmentFee}
            startAdornment='£'
            readOnly
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput label='Distance' value={booking?.distance} helperText='Miles' readOnly />
        </Grid>

        <Grid item xs={4}>
          <TextInput label='Rate' helperText='Pence per Mile' value={0} readOnly />
        </Grid>
      </Grid>
    </>
  )
}

export default ChargesDetails
