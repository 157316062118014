import { Box, Grid, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'

import { DriverOnboardingDialog } from 'components/suppliers/drivers/DriverOnboardingDialog'
import { Restricted } from 'providers/PermissionProvider'

import AccountAvatar from './AccountAvatar'
import ChangePassword from './ChangePassword'
import ProfileDetailsForm from './ProfileDetailsForm'

const Profile = () => {
  return (
    <>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth='lg'>
          <Stack spacing={3}>
            <div>
              <Typography variant='h4'>Account</Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <AccountAvatar />
                </Grid>

                <Grid container item spacing={3} xs={12} md={6} lg={8}>
                  <Grid item xs={12}>
                    <ProfileDetailsForm />
                  </Grid>

                  <Grid item xs={12}>
                    <ChangePassword />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
      <Restricted to='ViewOnboardingProcess'>
        <DriverOnboardingDialog />
      </Restricted>
    </>
  )
}

export default Profile
