import { AccountPayableRecordFilterInput, AccountPayableRecordStatus } from 'generated/graphql'

export const allStatusesFilter: AccountPayableRecordFilterInput = {
  status: {
    in: [
      AccountPayableRecordStatus.UnderReview,
      AccountPayableRecordStatus.Flagged,
      AccountPayableRecordStatus.ScheduledPayment,
      AccountPayableRecordStatus.OpenQuery,
      AccountPayableRecordStatus.PaymentSent,
      AccountPayableRecordStatus.PaymentFailed,
      AccountPayableRecordStatus.Archived,
    ],
  },
}

export const awaitingApprovalStatusFilter: AccountPayableRecordFilterInput = {
  status: {
    eq: AccountPayableRecordStatus.UnderReview,
  },
}

export const paymentSentStatusFilter: AccountPayableRecordFilterInput = {
  status: {
    eq: AccountPayableRecordStatus.PaymentSent,
  },
}

export const approvedStatusFilter: AccountPayableRecordFilterInput = {
  status: {
    eq: AccountPayableRecordStatus.Flagged,
  },
}

export const archivedStatusFilter: AccountPayableRecordFilterInput = {
  status: {
    eq: AccountPayableRecordStatus.Archived,
  },
}

export const scheduledPaymentStatusFilter: AccountPayableRecordFilterInput = {
  status: {
    eq: AccountPayableRecordStatus.ScheduledPayment,
  },
}

export const openQueriesStatusFilter: AccountPayableRecordFilterInput = {
  openQueries: {
    any: true,
  },
}

export const failedPaymentStatusFilter: AccountPayableRecordFilterInput = {
  status: {
    eq: AccountPayableRecordStatus.PaymentFailed,
  },
}
