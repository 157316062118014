import { ISelectorOptions } from 'components/common'

export const Roles = {
  admin: 'Admin',
  customer: 'Customer',
  driver: 'Driver',
  operator: 'Operator',
  sales: 'Sales',
}

export const AvailableRoles: ISelectorOptions = [
  { value: Roles.customer, label: Roles.customer },
  { value: Roles.admin, label: Roles.admin },
  { value: Roles.operator, label: Roles.operator, disabled: true },
  { value: Roles.driver, label: Roles.driver, disabled: true },
  { value: Roles.sales, label: Roles.sales },
]
