import React, { useMemo } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { DropzoneArea } from 'react-mui-dropzone'
import { toast } from 'react-toastify'

import {
  ControlledCheckbox,
  LoadingBackdrop,
  TextInput,
  FplDialogTitle,
  ControlledSelector,
} from 'components/common'
import { FileConstants, Roles } from 'constants/index'
import {
  BookingAddressFilterInput,
  BookingAddressType,
  UpdateBookingAttachmentDtoInput,
  useGetBookingAddressesDataQuery,
  useGetBookingAttachmentLazyQuery,
  useUpdateBookingAttachmentMutation,
} from 'generated/graphql'
import { DisableSubmit } from 'helpers'
import { GetFileName, SaveFile, ShowRestError } from 'helpers'
import { useConfirmDialog } from 'providers/ConfirmDialogProvider'
import { DownloadFile, UploadBookingAttachment } from 'services/RestClient'

import BookingAttachmentFormHeader from './BookingAttachmentFormHeader'
import validationSchema from './ValidationSchema'

interface IProps {
  bookingId: number
  fileId: number | null
  disabled?: boolean
  openBookingAttachmentDialog: boolean
  handleCloseDialog: () => void
}

type AttachmentForm = {
  isAvailableForDriver: boolean
  isAvailableForCustomer: boolean
  changeToPod: boolean
  bookingAddressId: number
}

const schema = validationSchema()

const CreateUpdateBookingAttachment = (props: IProps) => {
  const spacing = 2

  const { idTokenPayload } = useOidcIdToken()
  const { bookingId, fileId, openBookingAttachmentDialog, handleCloseDialog, disabled } = props
  const [downloadingFile, setDownloadingFile] = React.useState(false)
  const [notFound, setNotFound] = React.useState(false)
  const [uploadingFile, setUploadingFile] = React.useState(false)
  const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(null)

  const [attachment, setAttachment] = React.useState<UpdateBookingAttachmentDtoInput>({
    bookingId: bookingId.toString(),
    fileId: fileId?.toString() ?? '',
    isAvailableForDriver: false,
    isAvailableForCustomer: idTokenPayload.role === Roles.customer,
    changeToPod: false,
    bookingAddressId: 0,
  })

  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<AttachmentForm>({
    shouldUnregister: true,
    resolver: joiResolver(schema),
    defaultValues: {
      isAvailableForDriver: attachment.isAvailableForDriver,
      isAvailableForCustomer: attachment.isAvailableForCustomer,
    },
  })

  const filter: BookingAddressFilterInput = {
    and: [{ type: { eq: BookingAddressType.Delivery } }],
  }

  const showDeliveryAddresses = watch('changeToPod')

  const { data: deliveryAddresses } = useGetBookingAddressesDataQuery({
    variables: {
      bookingId: bookingId,
      where: filter,
    },
  })

  const deliveryAddressesOptions = useMemo(
    () =>
      deliveryAddresses?.bookingAddresses?.map((x) => ({
        value: x.id,
        label: x.address.name + ' ' + x.address.postcode,
      })) || [],
    [deliveryAddresses],
  )

  React.useEffect(() => {
    reset({
      isAvailableForDriver: attachment.isAvailableForDriver,
      isAvailableForCustomer: attachment.isAvailableForCustomer,
    })
  }, [reset, attachment])

  const [getAttachment, { loading: retrievingAttachment, data: attachmentData }] =
    useGetBookingAttachmentLazyQuery({
      onError: () => {
        setNotFound(true)
      },
      onCompleted: (data) => {
        if (data.bookingAttachment) {
          setAttachment({
            ...attachment,
            fileId: data.bookingAttachment.fileId,
            isAvailableForDriver: data.bookingAttachment.isAvailableForDriver,
            isAvailableForCustomer: data.bookingAttachment.isAvailableForCustomer,
          })
        } else {
          setNotFound(true)
        }
      },
    })

  React.useEffect(() => {
    if (bookingId && fileId) {
      getAttachment({
        variables: {
          bookingId,
          fileId,
        },
      })
    }
  }, [bookingId, fileId, getAttachment])

  const [updateAttachment, { loading: updatingAttachment }] = useUpdateBookingAttachmentMutation({
    onCompleted: (response) => {
      if (!response.updateBookingAttachment) {
        toast.error('Something went wrong with updating attachment data.')
        return
      }

      setAttachment({
        isAvailableForDriver: response.updateBookingAttachment.isAvailableForDriver,
        isAvailableForCustomer: response.updateBookingAttachment.isAvailableForCustomer,
        bookingId: response.updateBookingAttachment.bookingId,
        fileId: response.updateBookingAttachment.fileId,
        changeToPod: false,
        bookingAddressId: 0,
      })

      toast.success(
        `Attachment ${response.updateBookingAttachment.fileId} was successfully ${
          fileId ? 'updated' : 'uploaded'
        }.`,
      )

      handleCloseDialog()
    },
  })

  const { confirmOpen } = useConfirmDialog({
    onConfirmApprove: handleCloseDialog,
  })

  // handlers
  const handleDocumentClick = () => {
    if (!fileId) {
      return
    }

    setDownloadingFile(true)

    DownloadFile(fileId)
      .then((response) => {
        let fileName = GetFileName(response)
        if (!fileName) {
          fileName = `file-${+new Date()}.dat`
        }

        SaveFile(response.data, fileName)
      })
      .catch(ShowRestError)
      .finally(() => setDownloadingFile(false))
  }

  const handleCancel = () => {
    if (isDirty) {
      confirmOpen()
    } else {
      handleCloseDialog()
    }
  }

  const handleUpdateAttachment = (formData: AttachmentForm) => {
    if (!fileId && selectedFiles?.length === 0) {
      toast.error('Something went wrong with selected file')
    } else if (!fileId && selectedFiles) {
      setUploadingFile(true)
      const fileData = new FormData()
      const date = new Date().toISOString()
      selectedFiles.forEach((f, i) => {
        fileData.append(`booking-attachment-${i}-${date}`, f)
      })

      UploadBookingAttachment(bookingId, fileData)
        .then(async (response) => {
          await updateAttachment({
            variables: {
              input: {
                ...formData,
                bookingId: bookingId.toString(),
                fileId: response.data[0].toString(),
                changeToPod: false,
                bookingAddressId: 0,
              },
            },
          })
        })
        .catch((error) => {
          toast.error('Something went wrong with uploaded file.', error)
        })
        .finally(() => setUploadingFile(false))
    } else if (fileId) {
      updateAttachment({
        variables: {
          input: {
            ...formData,
            bookingId: bookingId.toString(),
            fileId: fileId.toString(),
            changeToPod: showDeliveryAddresses,
            bookingAddressId: Number(formData.bookingAddressId ?? 0),
          },
        },
      })
    }
  }

  const onFilesChanged = (files: File[]) => setSelectedFiles(files.length === 0 ? null : files)

  const isProcessing = updatingAttachment || uploadingFile

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openBookingAttachmentDialog}
      aria-label='form-booking-attachment-dialog'>
      <FplDialogTitle id='form-booking-attachment-dialog' onClose={handleCancel}>
        <BookingAttachmentFormHeader notFound={notFound} fileId={fileId} />
      </FplDialogTitle>

      <LoadingBackdrop loading={retrievingAttachment} />

      {!notFound && (
        <DialogContent>
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              {!fileId && (
                <DropzoneArea
                  onChange={onFilesChanged}
                  showAlerts={false}
                  maxFileSize={FileConstants.maxFileSize}
                />
              )}
            </Grid>
            <Grid container spacing={spacing} item xs={12}>
              <Grid container spacing={spacing} item xs={12}>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  {fileId && (
                    <TextInput
                      name='createdBy'
                      label='Uploaded by'
                      variant='outlined'
                      value={attachmentData?.bookingAttachment?.createdBy}
                      readOnly
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: { xs: '0px', sm: fileId ? '-56px' : '0px' } }}>
                <form
                  id='upload-update-booking-attachment-form'
                  autoComplete='off'
                  noValidate
                  onSubmit={DisableSubmit}>
                  <ControlledCheckbox
                    control={control}
                    name='isAvailableForDriver'
                    label='Available for driver'
                    defaultValue={false}
                    disabled={disabled}
                  />

                  <Box hidden={idTokenPayload.role === Roles.customer}>
                    <ControlledCheckbox
                      control={control}
                      name='isAvailableForCustomer'
                      label='Available for customer'
                      defaultValue={false}
                      disabled={disabled}
                    />
                  </Box>
                  {fileId && (
                    <Grid container spacing={spacing} item xs={12}>
                      <Grid item xs={12} sm={6}>
                        <ControlledCheckbox
                          control={control}
                          name='changeToPod'
                          label="Change this Attachment's category to POD"
                          defaultValue={false}
                          disabled={disabled}
                        />
                      </Grid>
                      {showDeliveryAddresses && (
                        <Grid item xs={12} sm={6}>
                          <ControlledSelector
                            control={control}
                            name='bookingAddressId'
                            label='Delivery Address'
                            required
                            options={deliveryAddressesOptions}
                            error={!!errors.bookingAddressId}
                            helperText={errors.bookingAddressId?.message}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </form>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCancel} disabled={isProcessing}>
          Cancel
        </Button>

        {fileId && (
          <LoadingButton
            variant='contained'
            loading={downloadingFile}
            onClick={handleDocumentClick}>
            Download
          </LoadingButton>
        )}

        <LoadingButton
          form='upload-update-booking-attachment-form'
          color='primary'
          loading={isProcessing}
          disabled={(!fileId && !selectedFiles) || (Boolean(fileId) && !isDirty)}
          variant='contained'
          onClick={handleSubmit(handleUpdateAttachment)}>
          {fileId ? 'Save' : 'Upload'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateBookingAttachment
