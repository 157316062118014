import Joi from 'joi'

import { CreateAndUpdateXeroCodeDtoInput } from 'generated/graphql'

export const ServiceCodeValidationSchema = Joi.object<CreateAndUpdateXeroCodeDtoInput>({
  id: Joi.number().empty(null).optional().label('Id'),
  name: Joi.string().required().empty('').label('Name'),
  code: Joi.string().required().empty('').label('Code'),
  description: Joi.string().optional().empty('').label('Description'),
  type: Joi.any().required().label('Type'),
  isInternational: Joi.bool().required().label('Is international'),
  serviceType: Joi.any().required().label('Service type'),
})
